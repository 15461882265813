// Assets LJL
const LJLTopLeftLogoSummer =
  "https://cdn.dekki.com/uploads/leagues/ljl_2019_spring/LJL-logo_topleft_Summer.png";

const LJLBanPickBackground =
  "https://dekki-production.s3.ap-northeast-1.amazonaws.com/uploads/leagues/ljl_2023_spring/banpick_bg.png";

const LJLBanPickBackgroundSummer =
"https://dekki-production.s3.ap-northeast-1.amazonaws.com/uploads/leagues/ljl_2023_summer/banpick_bg_no-sponsor.png";

const LJLBanPickV2 =
"https://dekki-production.s3.ap-northeast-1.amazonaws.com/uploads/leagues/ljl_2023_spring/banpick-v2.png";

const LJLBanPickV2Mouse =
"https://dekki-production.s3.ap-northeast-1.amazonaws.com/uploads/leagues/ljl_2023_spring/banpick-v2_mouse.png";

const LJLLogoRedGradient =
  "https://cdn.dekki.com/uploads/leagues/ljl_2020_spring/ljl_logo_gradient.svg";

const LJLLogoSolid = "https://cdn.dekki.com/uploads/leagues/ljl_2022_spring/ljl_logo_red.svg";

export default {
  id: "ljl2023",
  name: "LJL 2023",
  screens: {
    draftOrder: {
      styleTeamAssetContainer: `
      font-family: dharma-gothic-e-bold;
      p{ 
         font-size: 70px; color: #F5F5F5; 
      }
      img {}
      `, // due to how the "loser" is handled the font family is outside of  the <p>
      loser: `font-family: dharma-gothic-e-light;`,
      styleHeader: `font-family: aktiv-grotesk-ex-bold;font-size: 29px; color: #06090D;`,
      background:
        "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/backgrounds/draft-order_bg.png",
    },
    telopsV2: {
      cssTelops: `background-image: linear-gradient(0,#730041 0%,#E10600 100%);
    width: 390px;
    height: 100px;
      `,
      cssTwitter: "",
      cssName:
        "color: #F5F5F5; font-size: 40px;font-family:aktiv-grotesk-ex-bold;",
      cssJapaneseName: `color: #F5F5F5; font-size: 37px;font-family:Noto-Sans-JP-Black;`,
      StyleInfo: `background-image: linear-gradient(0,#1c2b2e 0%,#06090D 100%);`,
      StyleTelop: ``,
      StyleTwitterName: `color: #F5F5F5;font-family:aktiv-grotesk-ex-medium;font-size: 20px;`,
    },
    standingsV2: {
      mainColor: "",
      groupTitle: ``,
      css: ``,
      standingsResizeOverWrite: ``,
    },
    screenTitleV2: {
      StyleTitleContainer: ``,
      StyledLogo: ``,
      StyledBar: ``,
      StyleSubTitle: ``,
      StyledTitle: ``,
    },
    bottomBar: {
      borderColorBase: "#ead8b3",
      borderColor: "#795b18",
      backgroundColor: "#1a1a1a",
      cssTeamName: "",
      cssNextGameTitle:
        "color:#dba103; font-size: 26px; padding-top: 14px; letter-spacing: 2px;",
      cssTimer: "color:#dba103; font-size: 85px; margin-bottom: 7px; ",
    },
    matchPlayerV2: {},
    leaderBoard: {
      cssHeader:
        'background-color: #141720; font-family: futura-pt, "sans-serif"; font-size: 50px; font-weight: 500; height: 80px; letter-spacing: 1px;',
      cssTopRowRank:
        'background-color: #141720; font-family: futura-pt, "sans-serif"; font-size: 30px; font-weight: 400;',
      cssTopRowInfoScore:
        'color:  #f00020; font-family: futura-pt, "sans-serif"; font-size: 35px;   font-weight: 700;',
      cssTopRowInfo: "background: white;",
      cssTopRowInfoName:
        'background: white; color: #141720; font-family: futura-pt, "sans-serif"; font-size: 30px; text-transform: uppercase; font-weight: 400; letter-spacing: 1px;',
      cssRow: "background: white;",
      cssRowName:
        'color: #141720; font-family: futura-pt, "sans-serif"; font-size: 22px; text-transform: uppercase; letter-spacing: 1px; font-weight: 400;',
      cssRowScore:
        'color:  #141720; font-family: futura-pt, "sans-serif"; font-size: 25px;  font-weight: 600;',
    },
    schedule: {
      screenTitle: {
        Header: `
          margin-top: 70px;
        `,
        Bo5Header: `margin-top: 70px;`,
        textColor: "#F5F5F5",
        textColorSub: "#141720",
        textShadowLeft: "#dba10332",
        // underlineColor: 'linear-gradient(90deg,#f00020 0%,#540035 100%)',
        textShadowRight: "#1fe4cb32",
        cssTitle: `
          font-family: dharma-gothic-e-bold-italic; 
          font-size: 145px; 
          line-height: 118px;
          letter-spacing: 1.45px;
          text-shadow: none;
          font-weight: normal;
        `,
        cssTitleText: `position: relative; 
        font-family: dharma-gothic-e-bold-italic;
        font-size: 145px;`,
        cssSubtitle: `
          font-family:aktiv-grotesk-ex-medium; 
          font-size: 35px; 
          text-shadow: none;
          color: white;
          text-transform: uppercase;
          top: 180px;
        `,
      },
      StyledVSTime: `color: white; background: #F5F5F5; height: 100%;display: flex;
            flex-direction: column;
            width: 170px;
            align-items: center;
            justify-content: center;
            padding: 4px 0 0 0;
            `,
      container: `font-weight: normal; letter-spacing: normal;`,
      bigImages: false,
      outterImages: true,
      changeWinnerBorder: true,
      changeWinnerBorderColor: `
      border-image: linear-gradient( to bottom, #E10600, #730041 ) 1 100%;
      border-image-slice: 9;
      border-style: solid`,
      speedPanel: 200,
      cssRow:
        'font-family: futura-pt, "sans-serif"; color:#141720; max-height: 158px;margin-bottom: 0px; width: 100%;border-right: 10px solid #F5F5F5; border-left: 10px solid #F5F5F5; width: 1527px;',
      cssRowNext: `color:#141720; 
            border-right-style: solid;
            border-right-width: 10px;
            border-image: linear-gradient(
              to bottom, 
              #E10600, 
              #730041
            ) 1 100%;
              border-left: 10px solid white;
              `,
      cssRowFinished: "",
      cssDate: "background-color: #ffffff;",
      cssDateFinished: "color:#06090D;",
      cssDateNext: "background-color: #ffffff; padding-left: 0;",
      cssLogo: `background-color: #06090D; padding-right: 0px; width:170px;     justify-content: center;
      align-items: center;
      img {
        width: 110px;
        height: 110px;
      }`,
      cssLogoNext: "",
      cssLogoLoser: "img{opacity: 0.3;}",
      cssLogoLoserLeft: "border-left: 10px solid #F5F5F5; width: 180px;",
      cssLogoLoserRight: "border-right: 10px solid #F5F5F5; width: 180px;",
      cssLogoWinner: "",
      StyleTeamDetailContainer: `
          
            `,
      cssNameBo5: `
            // color:#F5F5F5;
            // display: flex;
            // flex-direction: column;
            // align-items: center;
            // padding: 0;
            // justify-content: center;
            // max-width: 100%;
            // font-family:aktiv-grotesk-bold;
            // font-size:30px;
            `,
      cssName: `
            color:#F5F5F5;
            background: #06090D;
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 0;
            justify-content: center;
            max-width: 197px;
            font-family:aktiv-grotesk-ex-medium;
            font-size:18px;
            width: 500px;
            max-width: 500px;
            `,
      StyleNameRightAdjust: `align-items: flex-end; padding-right: 30px;`,
      StyleNameLeftAdjust: `align-items: flex-start; padding-left: 30px;`,
      // cssNameNext: 'background: linear-gradient(0deg, #540035 0%, #f00020 100%); color:#ffffff;',
      cssNameLoser: "color:#F5F5F5; opacity: 0.3;",
      cssLittleScoreLoser: `opacity: 0.3;`,
      cssNameWinner: "background-color: black; color:#F5F5F5;",
      cssVersus:
        `
        span {
          font-family:aktiv-grotesk-medium;
          font-weight: 500;
          color: #FFFFFF;
        }
        `,
        VSTimeBo:` 
        `,
      cssVersusNext:
        "background: linear-gradient(0deg, #730041 0%, #E10600 100%); color:#ffffff; margin-right: 0; margin-left: 0;",
      StyleScoreDetail: "",
      StyleScoreSpan: ``,
      StyleScoreSpanWinner: `color: #E10600`,
      // cssScoreLoser: 'background-color: black; color: #540035;',
      cssScoreWinner: "color: #F00020;",
      trimColorNext: "transparent",
      rightBorderColor: "#ffffff;",
      rightBorderColorLoser: "",
      StyleVS: `color:#F5F5F5; font-family:dharma-gothic-e-bold; font-size:65px;`,
      StyleTime: `
      color: #06090D; font-family:dharma-gothic-e-bold; font-size:65px;font-weight: normal;`,
      StyleTriCodeName: `font-family:dharma-gothic-e-bold; font-size:58px;color:#F5F5F5;font-weight: normal;`,
      StyleLittleScore: `font-family:aktiv-grotesk-ex-medium; font-size:25px;color:#F5F5F5;font-weight: normal;`,
      bottom: `
              background: linear-gradient(to right,#E10600,#520e35);
              height: 10px;
            `,
            StyleScoreDetailContainer:`align-items: center; font-family:dharma-gothic-e-bold; font-weight: normal;`,
            background:`
              background-color: #06090D;
            `,
      cssBorders: `
            background: white;
            border: none; 
            :before {
                content: '';
                width: 10px;
                height: 100%;
                left: -10px;
                position: absolute;
                background: linear-gradient(0deg, #540035 0%, #f00020 100%);
            }
  `,
    },
    screenTitle: {
      Header: `background: blue;`,
      Bo5Header: ``,
      textColor: "white",
      textColorSub: "#141720",
      textShadowLeft: "#dba10332",
      // underlineColor: 'linear-gradient(90deg,#f00020 0%,#540035 100%)',
      textShadowRight: "#1fe4cb32",
      cssTitle: `
        font-family: dharma-gothic-e-bold-italic; 
        font-size: 92px; 
        lineHeight: 92px;
        letter-spacing: 0;
        text-shadow: none;
        margin-top: 20px;
        font-weight: normal;
      `,
      cssTitleText: `position: relative; 
      font-family: dharma-gothic-e-bold-italic;
      font-size: 145px;`,
      cssSubtitle: `
        font-family:aktiv-grotesk-ex-medium; 
        font-size: 35px; 
        text-shadow: none;
        color: white;
        text-transform: uppercase;
        top: 180px;
      `,
    },
    scheduleInfoSideBar: {
      logoWhite: false,
      logoSrc:
        "https://dekki-production.s3.ap-northeast-1.amazonaws.com/uploads/broadcast-projects/ljl2022/ljl_white.svg",
      StyleLogoImg: "max-width: 85px; width: 100%; height: auto;",
      StyleLogoImgBo5: "height: 75px; width: 75px;",
      StyleResultsGameText: `font-family:dharma-gothic-e-bold; font-size:160px; color:#F5F5F5; font-weight: normal;  padding-left: 10px;`,
      StyleLeagueLogoBo5Finish: `
      width: 170px;
      height: 180px;
      `, // moved to 180px for gouki's box
      StyleUpcomingGameContainerBo5Finish:`grid-template-columns: 147px 1fr;`,
      StyleSidebarSchedule: `background: rgb(15, 15, 15, 0.3);box-shadow: none; padding: 70px; width:640px; 
        :after {
        content: '';
        width: 250px; 
        height: 100%; 
        position: absolute;
        left: 640px;
        top: 0;
        background: linear-gradient(0.25turn, rgb(15, 15, 15, 0.3), rgb(15, 15, 15, 0));
      }`,
      StyleLeagueLogo: `height: 40px;
      width: 40px;margin-left: 40px;`,
      StyleUpcomingGameText:
        "font-size: 26px; padding:0; font-family: aktiv-grotesk-ex-bold;font-weight: normal;line-height: 45px;margin-bottom: 20px; white-space: nowrap;",
      StyleUpcomingGameTextOverwrite:`font-size: 30px; padding:0; font-family: aktiv-grotesk-ex-bold;font-weight: normal;line-height: 45px; margin-bottom: 20px; white-space: nowrap;`,
      StyleSidebarText:
      "font-size: 24px; padding:0; font-family: aktiv-grotesk-ex-regular;font-weight: normal;line-height: 45px;margin-bottom: 20px; white-space: nowrap;",
      lessThanOneHourStyle: `255px; position: fixed; left: 74px; font-family: dharma-gothic-e-bold;top: 120px;`,
      moreThanOneHourStyle:
        "255px; font-family: dharma-gothic-e-bold;top: 120px; left: 74px;",
      StyleTimer: "line-height: 200px;",
      timerOverwriteText:`font-family:dharma-gothic-e-bold; font-size:160px; color:#F5F5F5; font-weight: normal;`,
      StyleScheduleList: "background: transparent;padding:0;width: 450px;",
      StyleScheduleRowBo5: `border-bottom: 2px solid #F5F5F5; height: auto; opacity: 1;   
      :first-of-type {
        border-top: 2px solid #F5F5F5;
      }`,
      StyleScheduleRow: `border-bottom: 2px solid #F5F5F5; padding: 15px 0px; height: auto;   
      :first-of-type {
        border-top: 2px solid #F5F5F5;
      }`,
      StyleBof5ScoreSingleMatch: `
      font-size: 100px; font-family: dharma-gothic-e-bold; font-weight: normal; line-height: 81px; margin: 20px 0 10px 0; color: #f5f5f5;
      `,
      StyleBof5ScoreSingleMatchWinner: `color: #f5f5f5;`,
      StyleBof5GridSingleMatch: `margin: 0; border-top: 2px solid #F5F5F5;`,
      Styleof5GridSingleGamesContainer: `padding: 0;`,
      StyleGameTime: "font-size: 72px; font-family: dharma-gothic-e-bold; line-height: 62px; margin: 22px 0 17px 0;",
      StyleNextGame:
        "font-size: 72px; color:#d10101; font-family: dharma-gothic-e-bold; line-height: 62px; margin: 22px 0 17px 0;",
      StyleGameTimeBo5: "font-size: 72px; font-family: dharma-gothic-e-bold; line-height: 62px; margin: 22px 0 17px 0;",
      StyleNextGameBo5:
        "font-size: 72px; color:#d10101; font-family: dharma-gothic-e-bold; line-height: 62px; margin: 22px 0 17px 0;",
      StyleWeekTitle:
        "padding:0px 0px 20px 0px;font-size: 25px; font-family: aktiv-grotesk-ex-medium;color:#F5F5F5;font-weight: normal;",
      StyleUpcomingGameContainer: `
      display: grid;
      grid-template-columns: 95px 1fr;
      margin-left: -50px;
      padding: 0px;
      font-size: 26px;
      left: 0px;
      `,
      StyleUpcomingGameInfo: `position: inherit; font-size: 26px;`,
      StyleWinTextContainer: `font-size: 72px; font-family: dharma-gothic-e-bold; color: #F5F5F5; line-height: 62px; margin: 22px 0 17px 0;`,
    },
    cornerLogo: {
      cornerLogoImage: LJLTopLeftLogoSummer, //  Not used
      cssCornerLogo: "z-index:1;",
      cssCornerLabelText:
        "color:#8b681c; top:120px; left:95px; font-size:26px;",
      imageTop: "30px",
      imageLeft: "-96px",
      hideCornerLogo: true,
    },
    gameScreen: {
      backgroundImage:
        "https://cdn.dekki.com/uploads/broadcast-projects/general/test-lol-game-screen-bg-2022.png",
      disableBaronPowerPlaySponsor: true,
      disablePatchNumber: false,
      patchNumberSrc: LJLLogoSolid,
      ChampionsXP: {
        ChampionXP: {
          StyleLevel: `
          font-family: dharma-gothic-e-bold;
          font-size: 34px;
          `,
          StyleSummoner: `
          font-family: aktiv-grotesk-ex-bold;
          color: #F5F5F5;
          font-size: 16px;
          `,
        },
        StyleTitle: `
        font-family: aktiv-grotesk-ex-bold;
        color: #F5F5F5;
        font-size: 24px;
        `,
      },
      JungleRoute: {
        StyleHeader: `
        font-family: aktiv-grotesk-ex-bold;
        color: #F5F5F5;
        font-size: 35px;
        margin-top: 4px;
        text-align: inherit;
        `,
        StyleGameTime: `
        font-family: aktiv-grotesk-ex-medium;
        color: #F5F5F5;
        font-size: 32px;
        font-weight: normal;
        line-height: initial;
        margin: 0;
        span{
          font-weight: normal;
          font-size: 15px;
          margin-bottom: 5px;
          font-family: aktiv-grotesk-ex-regular;
        }
        `,
      },
      infoScreen: {
        logo: "fullColor", //fullColor or anything else
        BaronPowerPlay: {
          BuffBaronGoldDiff:`
          font-family: aktiv-grotesk-medium; 
          font-size:12px; 
          font-weight: normal; 
          line-height: 19px;
          `,
          StyleGoldDiffFinal:`
          font-family: aktiv-grotesk-medium;
          font-size: 12px; 
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          span {
            font-family: aktiv-grotesk-bold; 
            font-size: 40px;
            font-weight: normal;
            display: block;
            line-height: 0px;
            margin-top: 8px;
          }
          strong {
            font-family: aktiv-grotesk-bold; 
            font-size: 14px;
          }
          `,
        },
        InfoBoxTeamStatsSingle: {
          useImage: true,
          StyleInfoBoxTitle: `
          font-family: aktiv-grotesk-ex-bold;
          color: #F5F5F5;
          font-size: 35px;
          margin-top: 4px;
          left: 0;
    transform: none;
    text-align: center;
          `,
          StyleStatTitle: `
          font-family: dharma-gothic-e-bold;
          font-family: aktiv-grotesk-ex-regular;
          color: #F5F5F5;
          font-size: 23px;
          `,
          StyleChampionContainer: `
          font-family: aktiv-grotesk-ex-regular;
          color: #F5F5F5;
          font-size: 23px;
          `,
          StyleStatsHeader: `
          font-family: aktiv-grotesk-ex-medium;
          color: #F5F5F5;
          font-size: 13px;
          line-height: 45px;
          letter-spacing: 0px;
          `,
          StyleStatsValue: `
          font-family: dharma-gothic-e-bold;
          color: #F5F5F5;
          font-size: 70px;
          `,
        },
        InfoBoxPlayerStatsDual: {
          useImage: true,
          StyleInfoBoxBody: `
          grid-template-columns: 570px 200px 570px;
          `,
          StylePlayerContainer: `
          width: 570px;
          `,
          StyleStatsContainer: `
          width: 200px;
          `,
          StyleInfoBoxTitle: `
          font-family: aktiv-grotesk-ex-bold;
          color: #F5F5F5;
          font-size: 35px;
          margin-top: 4px;
          `,
          StylePlayerName: `
          font-family: aktiv-grotesk-ex-bold;
          color: #F5F5F5;
          font-size: 19px;
          line-height: 45px;
          `,
          StyleStatValue: `
          font-family: dharma-gothic-e-bold;
          color: #06090d;
          font-size: 54px;
          margin-top: 10px;
          `,
          StyleStatTitle: `
          font-family: aktiv-grotesk-ex-medium;
          color: #F5F5F5;
          font-size: 22px;
          `,
        },
        InfoBoxTeamStatsDual: {
          useImage: true,
          StyleInfoBoxBody: `
          grid-template-columns: 540px 260px 540px;
          `,
          StylePlayerContainer: `
          width: 540px;
          `,
          StyleStatsContainer: `
          width: 260px;
          `,
          StyleInfoBoxTitle: `
          font-family: aktiv-grotesk-ex-bold;
          color: #F5F5F5;
          font-size: 35px;
          margin-top: 4px;
          `,
          StylePlayerName: `
          font-family: aktiv-grotesk-ex-bold;
          color: #F5F5F5;
          font-size: 19px;
          `,
          StyleStatValue: `
          font-family: dharma-gothic-e-bold;
          color: #06090d;
          font-size: 54px;
          margin-top: 10px;
          `,
          StyleStatTitle: `
          font-family: aktiv-grotesk-ex-medium;
          color: #F5F5F5;
          font-size: 22px;
          `,
        },
        InfoBoxPlayerStatsSingle: {
          useImage: true,
          StyleChampionContainer: `
          font-family: aktiv-grotesk-ex-regular;
          color: #F5F5F5;
          font-size: 23px;
          span {
            line-height: 45px;
          }
          `,
          StyleStatsHeader: `
          font-family: aktiv-grotesk-ex-medium;
          color: #F5F5F5;
          font-size: 20px;
          line-height: 45px;
          `,
          StyleStatsValue: `
          font-family: aktiv-grotesk-ex-bold;
          color: #F5F5F5;
          font-size: 70px;
          margin-top: 10px;
          `,
          StyleInfoBoxTitle: `
          font-family: aktiv-grotesk-ex-bold;
          color: #F5F5F5;
          font-size: 35px;
          margin-top: 4px;
          `,
        },
        InfoBoxGoldDifference: {
          StyleInfoBoxTitle: `
          font-family: aktiv-grotesk-ex-bold;
          color: #F5F5F5;
          font-size: 35px;
          margin-top: 5px;
          `,
        },
        InfoBoxGoldPerPlayer: {
          StyleInfoBoxTitle: `
          font-family: aktiv-grotesk-ex-bold;
          color: #F5F5F5;
          font-size: 35px;
          margin-top: 5px;
          `,
          bars: {
            StyleScore: `
            font-family: aktiv-grotesk-medium;
            color: #F5F5F5;
            font-size: 19px;
            `,
          },
        },
        InfoBoxWhoWillWin: {
          StyleInfoBoxTitle: `
          font-family: aktiv-grotesk-ex-bold;
          color: #F5F5F5;
          font-size: 35px;
          margin-top: 4px;
          `,
          StyleWhoWillWinContent: `
          font-family: aktiv-grotesk-ex-bold;
          font-size: 35px;
          `,
          StyleHeaderContainer:`
          font-family: aktiv-grotesk-ex-bold;
          color: #F5F5F5;
          font-size: 35px;
          align-items: baseline;
          margin-top: 2px;
          `,
        },
        WhoWillWinAI: {
          StyleInfoBoxTitle: `
          font-family: aktiv-grotesk-ex-bold;
          color: #F5F5F5;
          font-size: 35px;
          margin-top: 4px;
          `,
          StyleScoreBar: `
          font-family: aktiv-grotesk-ex-bold;
          font-size: 40px;
          font-weight: normal;
          `,
          StyleScoreLeft: `margin-top: 5px;`,
          StyleScoreRight: `margin-top: 5px;`,
          StyleRosterChampions: `
            p {
              font-family: aktiv-grotesk-ex-medium;
              font-size: 18px;
              font-weight: normal;
              text-align: center;
              line-height: 40px;
              margin: auto;
            }
            img {
              width: 37px;
            }
          `,
        },
        InfoBoxDamageDealt: {
          StyleInfoBoxTitle: `
          font-family: aktiv-grotesk-ex-bold;
          color: #F5F5F5;
          font-size: 35px;
          margin-top: 7px;
          `,
          PlayerBarItem: {
            StyleScore: `
            font-family: aktiv-grotesk-ex-medium;
          color: #F5F5F5;
          font-size: 20px;
          margin-top: 3px;
            `,
          },
        },
        InfoBoxTurretPlate: {
          StyleTeamContainer:`
            
          `,
          StyleInfoBoxTitle: `
          font-family: aktiv-grotesk-ex-bold;
          color: #F5F5F5;
          font-size: 35px;
          margin-top: 7px;
          `,
          StyleTurretStatus: `
          font-family: aktiv-grotesk-ex-bold;
          color: #F5F5F5;
          font-size: 32px;
          // margin-top: 7px;
          line-height: 40px;
          img {
              width: 50px;
              height: 50px;
              margin-bottom: 2px;
          }
          p {
            font-family: aktiv-grotesk-ex-medium;
          }
          `,
          StyleStatusGoldPerTeam: `
          font-family: aktiv-grotesk-ex-bold;
          font-size: 44px;
          margin-top: 20px;
          overflow: visible;
          line-height: 25px;
          font-weight: normal;
          `,
        },
        PlayersMythicItem: {
          StyleInfoBoxTitle: `
          font-family: aktiv-grotesk-ex-bold;
          color: #F5F5F5;
          font-size: 35px;
          margin-top: 4px;
          `,
        },
        WhoWillWinAIGraph: {
          StyleInfoBoxTitle: `
          font-family: aktiv-grotesk-ex-bold;
          color: #F5F5F5;
          font-size: 35px;
          margin-top: 4px;
          `,
          StyleYTicksLabelContainer: `
          font-family: aktiv-grotesk-regular;
          `,
          StyleWinnerLabel: `
          height: 60px;
          position: absolute;
          bottom: 25px;
          right: 8px;
          width: 95%;
          div {
              width: 100px;
              height: 100%;
              padding: 8px 0 8px 10px;
              text-align: left;
              font-family: aktiv-grotesk-ex-bold;
          }
          span {
              width: 100%;
              font-size: 32px;
              line-height: 30px;
              font-weight: 700;
              text-align: left;
              display: inline;
          }    
          span:first-of-type {
                  display: block;
                  font-size: 12px;
                  line-height: 12px;
                  font-weight: 400;
                  margin-bottom: 4px;
                  font-family: aktiv-grotesk-medium;
    
          }
          span:last-of-type{
              font-size: 32px;
              }
          `,
        },
        PlayersRunes: {
          StyleInfoBoxTitle: `
          font-family: aktiv-grotesk-ex-bold;
          color: #F5F5F5;
          font-size: 35px;
          margin-top: 7px;
          `,
        },
        InfoBoxItemsPurchaseHistory: {
          StyleItem: `
          img{},
          p{margin-top: 6px;},
          `,
          StyleInfoBoxTitle: `
          font-family: aktiv-grotesk-ex-bold;
          color: #F5F5F5;
          font-size: 35px;
          margin-top: 4px;
          `,
        },
      },
      versus: {
        backgroundTexture: "",
        borderColor: "#795b18",
        borderColorBase: "#795b18",
        textColorTeams: "#ffffff",
        textColorVersus: "#dba103",
        textAlign: "logoCenter",
        cssTeamName:
          "font-size: 70px;letter-spacing:3px; font-family: dharma-gothic-e-bold-italic; color: white;",
        cssVersusOverlay: `
        display: grid; 
        grid-template-columns: 818px 1fr 814px; 
        border: none;    
        background: url(https://dekki-production.s3-ap-northeast-1.amazonaws.com/assets/broadcast/games/league-of-legends/game-overlay/ingame_vs_overlay_bg.png);
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
        `,
        cssVersus:
          "font-size: 153px; font-family: dharma-gothic-e-bold-italic; color: #06090d; width: 100%;",
      },
      teamInfo: {
        removeTeamInfo: false,
        showGamesWinLights: true,
        showTeamLogo: true,
        backgroundAsset:
          "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/team-info_scores.png",
        cssTeamInfoContainer: "",
        cssTeamName:
          "font-family: aktiv-grotesk-bold; font-size: 25px;top:3px;",
        cssTeamReccord:
          "font-family: aktiv-grotesk-ex-medium;font-size:11px;line-height: 17px;",
        cssScoreContainer:
          "background-image: url(https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/team-info_scores.png);",
        cssTeamLogo: "",
        hideScore: true,
        StyleSponsorsPatch: `font-family: aktiv-grotesk-medium; font-size:28px; line-height: normal; font-weight: normal; color: E10600; margin-right: 5px;`,
        BuffElderTimer: `font-family: aktiv-grotesk-ex-bold;font-size:26px;font-weight: normal;top: 3px;`,
        RightTimerLive: `font-family: aktiv-grotesk-ex-bold;font-size:24px;font-weight: normal;color:#06090d;font-weight: normal; top: 11px; left: 0px;`,
        RightTimer: `font-family: aktiv-grotesk-ex-bold;font-size:26px;font-weight: normal;color:#06090d;font-weight: normal; top: 11px; left: 0px;`,
        LeftTimer: `font-family: aktiv-grotesk-ex-bold;font-size:26px;color:#06090d;font-weight: normal; top: 11px; left: 0px;`,
        LeftTimerLive: `font-family: aktiv-grotesk-ex-bold;font-size:24px;color:#06090d;font-weight: normal;color:#06090d;font-weight: normal; top: 11px; left: 0px;`,
        BuffBaronTimer: `font-family: aktiv-grotesk-ex-bold;font-size:26px;font-weight: normal; top: 22px;`,
      },
      inhibitor: {
        cssInhibitorLeft:
          "font-family: aktiv-grotesk-ex-bold; font-size: 22px; line-height: 30px; color: #06090d;",
        cssInhibitorRight:
          "font-family: aktiv-grotesk-ex-bold; font-size: 22px;     line-height: 30px; color: #06090d;",
      },
    },
    banPick: {
      leagueLogo: LJLLogoRedGradient,
      backgroundImage: LJLBanPickBackground,
      // backgroundImageTeam: LJLBackgroundImage,
      cssScoreBox:
        "font-family: aktiv-grotesk-bold; font-size:54px;color: #06090D; top: 20px",
      backgroundColorCoach: "#FFFFFF00",
      cssCoachContainer:
        "font-family: aktiv-grotesk-ex-medium; font-size: 20px; color: #F5F5F5; ",
      cssPatch:
        "font-family:aktiv-grotesk-ex-medium; color: #06090D; font-size: 22px; top: 80px;",
      cssTeamName:`margin-left: 0px; margin-right: 0px; font-family: dharma-gothic-e-bold; font-size:90px; color: #06090D; text-shadow: unset;`,
      cssTeamReccord:
        "font-family:aktiv-grotesk-ex-medium; color: #06090D; font-size: 25px;",
      showTricode: true,
      showTeamBGVideo: true,
    },
    banPickV2: {
      leagueLogo: LJLLogoRedGradient,
      topImage: LJLBanPickV2Mouse,
      disableTeamReccord: false,
      StyleLogoContainer: `
      span {

      }
      h1 {
        
      }
      `,
      StyleIconContainer: `height: 220px; background-size: 35px;`,
    },
    standings: {
      showSubtext: false,
      showRank: true,
      rankBackground: {
        1: ["#fff", "#fff"],
        2: ["#fff", "#fff"],
        3: ["#fff", "#fff"],
        4: ["#fff", "#fff"],
        5: ["#fff", "#fff"],
        6: ["#fff", "#fff"],
        7: ["#fff", "#fff"],
        8: ["#fff", "#fff"],
      },
      cssLogoCell: `
        background-color: black;
        position: relative;
        margin-left: 20px;
        overflow: visible; 
        background-size: 60px; 
        flex-basis: 120px; 
        flex-grow: unset; 
        width: 120px;
        :before {
          content: '';
          width: 10px; 
          height: 100%; 
          position: absolute;
          left: 120px;
          height: 85px;
          width: 4px;
          z-index: 2;
          // background-color: #F5F5F5;
        }
        :after {
          content: '';
          width: 20px; 
          height: 100%; 
          position: absolute;
          left: 120px;
          background: black;
        }`,
      cssHeaderCell: `
        font-size: 18px; 
        // color: transparent;
        font-family: aktiv-grotesk-medium;
        justify-content: center;
        grid-template-columns: 120px 150px 840px 120px 120px;
        grid-gap: 0 10px;
        margin-left: unset;
        color: #F5F5F5;
        font-weight: normal;
        `,
      cssScoreCell:
        "background-color:white; color: black;font-size:60px; font-family: dharma-gothic-e-bold; flex-grow: unset; margin-left: unset; padding-top: 4px;",
      cssNameCell:
        "font-size:60px; color: white;font-family: dharma-gothic-e-bold; text-transform: uppercase; background-color: black;padding-left:10px;padding-top: 4px;     letter-spacing: normal;",
      cssCell: `
        // background-color: transparent;
        justify-content: center; 
        min-height:85px;
        grid-template-columns: 120px 150px 840px 120px 120px;
        grid-gap: 0 10px;
        max-height:85px;
        color: black;
        `,
      cssRankCell: `font-size:60px; font-family: dharma-gothic-e-bold; flex-grow: 0; flex-basis: 45px; left: 20px; padding-top: 4px;`,
      cssHeaderRank: `flex-basis: 45px; margin-left: 40px;`,
      cssStanding: "",
      cssHeaderName: `
      // color: transparent;
      padding-left: 12px;
      `,
    },
    startersV2: {
      logoWhite: true,
      StyleTeamContainer: `height: 110px;`,
      StylePlayerNameContainerPlayer: `
      height: 85px;
      width: 340px;
      margin-top: 20px;
      background: white;
      `,
      StyleMainContainerPlayer: `
      width: 340px;
      margin: 10px;
      `,
      StylePlayerImagesContainerPlayer: `height: 610px;`,
      StylePositionIconPlayer: `height: 50px; width: 50px; top: 20px;
      left: 20px;`,
      StylePlayerNamePlayer: `
      font-family: dharma-gothic-e-bold;
      font-size: 60px;
      color: #0f0f0f;
      padding-top: 5px;
      `,
      StylePlayersContainer: `height: 715px;`,
      StyleTeamName: `font-family: dharma-gothic-e-bold-italic;
      font-size: 145px;
      margin-left: 30px;
      color: white;
      margin-bottom: 3px;
      `,
      StyleTeamLogo: `height: 110px;margin-bottom:14px;`,
    },
    animatedBackground: {
      show: true,
      defaultBackground:
        // "https://dekki-production.s3.ap-northeast-1.amazonaws.com/uploads/broadcast-projects/ljl2023/ljl2023_livingholds.mp4",
        // "https://dekki-production.s3.ap-northeast-1.amazonaws.com/uploads/broadcast-projects/ljl2023/ljl2023_livingholds_40Mbps.mp4",
        // "https://dekki-production.s3.ap-northeast-1.amazonaws.com/uploads/broadcast-projects/ljl2023/ljl2023_livingholds_60Mbps.mp4",
        "https://dekki-production.s3.ap-northeast-1.amazonaws.com/uploads/broadcast-projects/ljl2023/ljl2023_livingholds_finals_40mbs.mp4",
      disableAnimationBackGround: true,
    },
    gameBreakdown: {
      backgroundImage: true,
      teamStats: {
        teamStatsBGColor: "#ffffffE6",
        teamStatsBGTexture: "none",
        showTeamBackgroundImageStatsContents: false,
        teamStatsHeaderBGColor: "#06090D",
        cssTeamName: {
          fontFamily: "dharma-gothic-e-bold",
          color: "#F5F5F5",
          fontSize: "70px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          whiteSpace: "nowrap",
          top: "5px",
        }, // Team names at the top
        item: {
          color: "#06090D",
          fontFamily: "aktiv-grotesk-ex-medium",
          fontSize: "25px",
          lineHeight: "45px",
          flexBasis: "40px",
        },
        cssTeamStats: "font-family: 'aktiv-grotesk-ex-medium';",
        cssWinTag: `
        font-family: aktiv-grotesk-ex-bold;
        font-size: 40px;
        color: rgb(6, 9, 13);
        padding-top: 8px;
        /* padding-bottom: 20px; */
        border-bottom: 2px solid black;
        width: 100%;
        -webkit-box-pack: center;
        justify-content: center;
        display: flex;
        height: 99px;
        align-items: center;
        `,
        cssLossTag: `
        font-family: aktiv-grotesk-ex-bold;
        font-size: 40px;
        color: rgb(6, 9, 13);
        padding-top: 8px;
        /* padding-bottom: 20px; */
        border-bottom: 2px solid black;
        width: 100%;
        -webkit-box-pack: center;
        justify-content: center;
        display: flex;
        height: 99px;
        align-items: center;
        `,
        cssBansPicks: {
          color: "#06090D",
          fontSize: "25px",
          fontFamily: "aktiv-grotesk-ex-medium",
        },
        dragons: { backgroundColor: "unset", border: "4px solid transparent" },
      },
      key: {
        gameTime: {
          fontFamily: "aktiv-grotesk-ex-bold",
          color: "#F5F5F5",
          fontSize: "50px",
          marginBottom: "0",
          paddingTop: "3px",
        }, // time
        gameTimeHeader: {
          fontFamily: "aktiv-grotesk-ex-medium",
          marginBottom: "0",
          fontSize: "20px",
          color: "#F5F5F5",
        }, // "Game Time"
        cssHeader: {
          fontFamily: "aktiv-grotesk-ex-medium",
          fontSize: "20px",
          backgroundColor: "#06090D",
        }, // no idea
        cssTitles: {
          fontFamily: "aktiv-grotesk-ex-medium",
          color: "#F5F5F5",
          fontSize: "19px",
        }, // kda, gold, etc
        content: { backgroundColor: "#06090D", backgroundImage: "unset" },
      },
      graph: {
        cssHeader: {
          fontFamily: "aktiv-grotesk-ex-bold",
          color: "#F5F5F5",
          fontSize: "31px",
          backgroundColor: "#06090D",
          backgroundImage: "unset",
          letterSpacing: "2px",
          paddingTop: "8px",
        },
        cssContent: {
          fontFamily: "aktiv-grotesk-ex-medium",
          color: "#06090D",
          backgroundImage: "unset",
          fontSize: "17px",
          backgroundColor: "transparent",
          borderBottom: "none",
        },
        cssKey: {
          borderBottom: "none",
          fontFamily: "aktiv-grotesk-ex-bold",
          fontSize: "30px",
          color: "#F5F5F5",
          backgroundImage: "unset",
          backgroundColor: "#06090D",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          paddingTop: "35px",
        },
        damageCountBackground: {
          ":before": {
            backgroundColor: "unset",
          },
        },
        indexesColor: "#F5F5F5",
      },
    },
    matchUp: {
      backgroundImage:
        "https://cdn.dekki.com/uploads/leagues/ljl_2019_summer/gameBreakdownBG.png",
      cssLane: "background-color: #16161288;",
      cssTeamNameRight: {
        fontFamily: "Refrigerator",
        textShadow: "0px 0px black",
      },
      cssTeamNameLeft: {
        fontFamily: "Refrigerator",
        textShadow: "0px 0px black",
      },
    },
    matchPlayer: {
      cssVoteImage: "margin-left: -80px;",
    },
    matchUpV2: {
      positionLogo: "ljlblack",
      cssProfileImg: "",
      cssPosition: `background: white; img{
        width: 100%;
        height: 45px;
      }`,
      cssName:
        "background: white; margin-top: 0px; margin-bottom: 0px;justify-content: center; padding:0;  p{opacity: 100%; margin-top: 0px;margin-bottom: 0px; font-family: dharma-gothic-e-bold; letter-spacing: 0; color:black; font-size: 42px; line-height: 32px;padding-top: 3px;}",
      team100: {
        cssName: "",
      },
      team200: {
        cssName: "",
      },
    },
    timer2: {
      cssTimerContainer: `
        background-color: #DDF2F5; 
        box-shadow: none; 
        width: 230px;
        height: 120px;
      `,
      cssTimerText: `
        color: #034051;
        font-family: bebas-neue-pro, sans-serif;
        font-weight: 400;
        font-size: 18px;
        padding: 15px 20px 0 20px;
      `,
      cssTime: `
        color: #034051;
        font-family: bebas-neue-pro, sans-serif;
        font-weight: 600; 
        font-size: 70px; 
        padding: 0 20px 5px 20px;
      `,
      cssBorderBottom: "background:#034051;",
    },
  },
};
