// REACT
import React, { Component } from 'react';
// INTERNAL
import { /*send,*/ subscribe } from '../../../services/api';
import ControlContainer from '../../Controller/ControlContainer';
import Button from '../../Button';
import validateDataKeyPlayers from '../../Data/DataKeyPlayers/validate';
import { i18n } from '../../i18n';
import allChamps from '../../../services/championService';
import { updateScreenStatus } from '../../../util/controllerUtils';
import PropTypes from 'prop-types';
class KeyPlayersControl extends Component {

    static propTypes = {
        cgpc: PropTypes.string,
        display: PropTypes.bool,
        screen1: PropTypes.bool,
      };
    state = {
        status: 'ready',
        champsVisible: false,
    }

    componentDidMount() {
        this.runSubscription();
        if (validateDataKeyPlayers()) {
            this.setState({status: 'ready'});
        } else {
            this.setState({status: 'invalid'});
        }
    }

    runSubscription = () => {
        subscribe('control-keyPlayers', null, data => {
            if (data.target === 'control-keyPlayers') {
                const resultState = {
                    ...data,
                    ...data.data,
                };
                delete resultState.data;
                this.setState(resultState);
            }
        }, this.props.cgpc);
    };

    getSendData = (toUpdate, value) => {
        // const schedule = window.MainData.schedule;
        // const league = schedule.league;
        // const season = schedule.season;
        // const split = schedule.split;
        const teams = window.MainData.teams;
        const keyPlayers = window.MainData.keyPlayers;

        // player100, player200 : name, team, position, stats
        const player100 = keyPlayers.player100;
        const teamInfo100 = teams[player100.team];
        const player200 = keyPlayers.player200;
        const teamInfo200 = teams[player200.team];

        const champ1001 = player100.stats.champs[0] ? allChamps[parseInt(player100.stats.champs[0], 10)] : null;
        const champ1002 = player100.stats.champs[1] ? allChamps[parseInt(player100.stats.champs[1], 10)] : null;
        const champ1003 = player100.stats.champs[2] ? allChamps[parseInt(player100.stats.champs[2], 10)] : null;

        const champ2001 = player200.stats.champs[0] ? allChamps[parseInt(player200.stats.champs[0], 10)] : null;
        const champ2002 = player200.stats.champs[1] ? allChamps[parseInt(player200.stats.champs[1], 10)] : null;
        const champ2003 = player200.stats.champs[2] ? allChamps[parseInt(player200.stats.champs[2], 10)] : null;

        const champions100 = [
            player100.stats.champs[0] ? {name: champ1001.englishName, image: `https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/${champ1001.id.toLowerCase()}.jpg`} : null,
            player100.stats.champs[1] ? {name: champ1002.englishName, image: `https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/${champ1002.id.toLowerCase()}.jpg`} : null,
            player100.stats.champs[2] ? {name: champ1003.englishName, image: `https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/${champ1003.id.toLowerCase()}.jpg`} : null,
        ];

        const champions200 = [
            player200.stats.champs[0] ? {name: champ2001.englishName, image: `https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/${champ2001.id.toLowerCase()}.jpg`} : null,
            player200.stats.champs[1] ? {name: champ2002.englishName, image: `https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/${champ2002.id.toLowerCase()}.jpg`} : null,
            player200.stats.champs[2] ? {name: champ2003.englishName, image: `https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/${champ2003.id.toLowerCase()}.jpg`} : null,
        ];

        let player100PositionIcon, player200PositionIcon;
        try {
            player100PositionIcon = `https://cdn.dekki.com/assets/broadcast/games/league-of-legends/positions/${player100.position.toLowerCase()}.svg`;
            player200PositionIcon = `https://cdn.dekki.com/assets/broadcast/games/league-of-legends/positions/${player200.position.toLowerCase()}.svg`;
        } catch (err) {
            console.log(err, 'playerPositionIcon Error, KeyPlayers.');
        }
        const player100TeamColor = teamInfo100.color.main;
        const player200TeamColor = teamInfo200.color.main;
        const player100TeamLogo = teamInfo100.images && teamInfo100.images.logoTeamColor;
        const player200TeamLogo = teamInfo200.images && teamInfo200.images.logoTeamColor;
        return {
            player100,
            player200,
            champions100,
            champions200,
            player100PositionIcon,
            player200PositionIcon,
            player100TeamColor,
            player200TeamColor,
            player100TeamLogo,
            player200TeamLogo,
            keyPlayers: window.MainData.keyPlayers,
            settings: window.MainData.settings,
            update: toUpdate,
            value,
            champsVisible: this.state.champsVisible || false,
            preloadImages: [player100TeamLogo, player100PositionIcon, player200TeamLogo, player200PositionIcon]
                .concat(champions100 ? champions100
                    .map(champ => (champ && champ.image) || null)
                    .filter(image => image) : [])
                .concat(champions200 ? champions200
                    .map(champ =>  (champ && champ.image) || null)
                    .filter(image => image) : []),
        }
    }

    onPlay = () => {
        if (validateDataKeyPlayers()) {
            // send('event', { target: 'view', action: 'open', screen: 'keyPlayers', data: this.getSendData() });
            updateScreenStatus('keyPlayers', 'open', this.getSendData(), null, this.props.cgpc);
        } else {
            this.setState({status: 'invalid'});
        }
    }

    onClose = () => {
        // send('event', { target: 'view', action: 'close', screen: 'keyPlayers' });
        updateScreenStatus('keyPlayers', 'close', null, null, this.props.cgpc);
    }

    onUpdate = (toUpdate, v) => {
        this.setState({ [toUpdate]: v }, () => {
            (this.state.status === 'open') && updateScreenStatus('keyPlayers', 'open', this.getSendData(), null, this.props.cgpc);
        });
        // send('event', { target: 'view', action: 'update', screen: 'keyPlayers', data: this.getSendData(toUpdate, v) });
    }

    render() {
        return (
            <ControlContainer style={{display: this.props.display ? 'none': 'block'}} className="keyPlayers--matchup" status={this.state.status}>
                <div className="control__panel">
                    <h2 className="control__title">
                        <i className="fas fa-star"></i> {i18n().pages.keyPlayers}
                    </h2>
                    <Button controllerSide={!this.props.screen1} action={this.onPlay} text={i18n().general.open} icon="fas fa-play-circle" disabled={
                        (this.state.status === 'open' || this.state.status === 'opening') && true }
                    />
                    <Button controllerSide={!this.props.screen1} action={this.onClose} text={i18n().general.close} icon="fas fa-stop-circle" disabled={
                        !(this.state.status === 'open' || this.state.status === 'opening') && true }
                    />
                </div>
                <div className="control-extra">
                    <div className="control-extra__fieldset">
                        <label className="control-extra__fieldset__label">Show Champs</label>
                        <div className="pill">
                            {
                                this.state.champsVisible ?
                                [
                                    <span key="1a" className="pill__span--highlighted">{i18n().controller.viewable.true}</span>,
                                    <Button controllerSide={!this.props.screen1} key="1b" text="Hide" action={() => this.onUpdate('champsVisible', false)} />,
                                ] :
                                [
                                    <span key="2a">{i18n().controller.viewable.false}</span>,
                                    <Button controllerSide={!this.props.screen1} key="2b" text="Show" action={() => this.onUpdate('champsVisible', true)} />,
                                ]
                            }
                        </div>
                    </div>
                </div>
            </ControlContainer>
        )
    }
}

export default KeyPlayersControl
