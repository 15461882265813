"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.TextFitChampionName = exports.StylePlayerNameContainer = exports.StylePlayerName = exports.StylePlayerImgContainer = exports.StylePlayerImg = exports.StylePlayer = exports.StyleMatchupBox = exports.StyleChampionImg = exports.StyleChampionFilter = exports.StyleChampionContainer = void 0;

var _styledComponents = _interopRequireDefault(require("styled-components"));

var _reactSpring = require("react-spring");

var _reactTextfit = require("react-textfit");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var StyleMatchupBox = (0, _styledComponents.default)(_reactSpring.animated.div).withConfig({
  displayName: "styles__StyleMatchupBox",
  componentId: "sc-1qunadf-0"
})(["width:287px;height:425px;display:flex;flex-direction:column;margin:10px 0 10px 10px;overflow:hidden;"]);
exports.StyleMatchupBox = StyleMatchupBox;

var StylePlayer = _styledComponents.default.div.withConfig({
  displayName: "styles__StylePlayer",
  componentId: "sc-1qunadf-1"
})(["display:grid;grid-template-columns:65px 1fr;order:", ";margin-top:", ";margin-bottom:", ";"], function (props) {
  return props.position == 'bottom' ? '2' : 'initial';
}, function (props) {
  return props.position == 'bottom' ? '10px' : '0';
}, function (props) {
  return props.position == 'top' ? '10px' : '0';
});

exports.StylePlayer = StylePlayer;

var StylePlayerImg = _styledComponents.default.img.withConfig({
  displayName: "styles__StylePlayerImg",
  componentId: "sc-1qunadf-2"
})(["height:100%;width:100%;object-fit:cover;"]);

exports.StylePlayerImg = StylePlayerImg;

var StyleChampionContainer = _styledComponents.default.div.withConfig({
  displayName: "styles__StyleChampionContainer",
  componentId: "sc-1qunadf-3"
})(["height:360px;position:relative;width:287px;overflow:hidden;"]);

exports.StyleChampionContainer = StyleChampionContainer;

var StyleChampionImg = _styledComponents.default.img.withConfig({
  displayName: "styles__StyleChampionImg",
  componentId: "sc-1qunadf-4"
})(["height:360px;width:287px;object-position:center top;object-fit:cover;"]);

exports.StyleChampionImg = StyleChampionImg;
var StyleChampionFilter = (0, _styledComponents.default)(_reactSpring.animated.div).withConfig({
  displayName: "styles__StyleChampionFilter",
  componentId: "sc-1qunadf-5"
})(["position:absolute;height:360px;width:287px;top:0;bottom:0;left:0;right:0;background-blend-mode:luminosity;background-image:url(", ");background-size:cover;background-position:center;background-color:", ";"], function (props) {
  return props.src;
}, function (props) {
  return props.colorFilter;
});
exports.StyleChampionFilter = StyleChampionFilter;
var TextFitChampionName = (0, _styledComponents.default)(_reactTextfit.Textfit).withConfig({
  displayName: "styles__TextFitChampionName",
  componentId: "sc-1qunadf-6"
})(["height:350px;width:287px;line-height:.7;position:absolute;top:0;font-weight:600;writing-mode:vertical-lr;text-orientation:mixed;text-transform:uppercase;color:#EFEFEF;opacity:0.3;"]);
exports.TextFitChampionName = TextFitChampionName;

var StylePlayerName = _styledComponents.default.p.withConfig({
  displayName: "styles__StylePlayerName",
  componentId: "sc-1qunadf-7"
})(["opacity:60%;font-size:23px;text-transform:uppercase;color:#ffffff;letter-spacing:3px;font-family:\"futura-pt\",sans-serif;"]);

exports.StylePlayerName = StylePlayerName;

var StylePlayerNameContainer = _styledComponents.default.div.withConfig({
  displayName: "styles__StylePlayerNameContainer",
  componentId: "sc-1qunadf-8"
})(["display:flex;align-items:center;padding-left:10px;margin-top:", ";margin-bottom:", ";background-color:#141720;", ";", ";"], function (props) {
  return props.position == 'top' ? '10px' : '0';
}, function (props) {
  return props.position == 'bottom' ? '10px' : '0';
}, function (props) {
  return props.cssName;
}, function (props) {
  return props.cssNameSide;
});

exports.StylePlayerNameContainer = StylePlayerNameContainer;

var StylePlayerImgContainer = _styledComponents.default.div.withConfig({
  displayName: "styles__StylePlayerImgContainer",
  componentId: "sc-1qunadf-9"
})(["width:65px;height:65px;background-color:", ";", ";", ";"], function (props) {
  return props.bgColor || '';
}, function (props) {
  return props.cssProfileImg;
}, function (props) {
  return props.cssProfileImgSide;
});

exports.StylePlayerImgContainer = StylePlayerImgContainer;