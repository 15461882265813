// Assets LJLAllStar
// const LJLAllstarTopLeftLogo = 'https://cdn.dekki.com/uploads/leagues/ljl_allstar_2019_spring/ljlallstar-logo.png';
const LJLAllstarTopLeftLogo = 'https://cdn.dekki.com/uploads/leagues/ljl_allstar_2019_summer/ljlallstar-logo.png';

// const LJLAllstarLogo = 'https://cdn.dekki.com/uploads/leagues/ljl_allstar_2019_spring/ljlallstar-logo-banpick.png';
const LJLAllstarLogo = 'https://cdn.dekki.com/uploads/leagues/ljl_allstar_2019_summer/ljlallstar-logo-banpick.png';

// const LJLAllstarBanPickBG = 'https://cdn.dekki.com/uploads/leagues/ljl_allstar_2019_spring/backgrounds/ljlallstar-banpick-bg.png';
const LJLAllstarBanPickBG = 'https://cdn.dekki.com/uploads/leagues/ljl_allstar_2019_summer/ljlallstar-banpick-bg.png';


export default {
  id: 'ljlallstar',
  name: 'LJL Allstar',
  screens: {
    bottomBar: {
      borderColor: 'teamMain',
      borderColorBase: '#020320',
      backgroundColor: '#020320',
      cssTeamName: 'font-family: Refrigerator; color:#ffffff;',
      cssNextGameTitle: 'font-family: Refrigerator; color:#ffffff;',
      cssTimer: 'font-family: Refrigerator; color:#ffffff;',
    },
    cornerLogo: {
      cornerLogoImage: LJLAllstarTopLeftLogo,
      cssCornerLabelText: 'display:none',
      startX: '-500px',
      endX: '0px',
      startY: '0px',
      endY: '0px',
    },
    matchUp: {
      cssLane: 'background-color: #050b44;',
    },
    schedule: {
      cssRow: 'font-family:Refrigerator, "sans-serif"; color:#ffffff; width: 105%!important; border-right: 8px solid #000095;',
      cssRowNext: 'color:#1b1b1b;border-right: unset !important;',
      cssRowFinished: 'opacity: 0.5;',

      cssDate: 'color:#ffffff;background-color: #0513f1;',
      cssDateFinished: 'opacity: 0.5;background-color: #0513f1 !important;',
      cssDateNext: 'color:#ffffff !important; background-color: #020320 !important;',

      cssLogo: '',
      cssLogoNext: 'background-color: #020320;',
      cssLogoLoser: 'opacity: 0.5; background-color: #000095;',
      cssLogoWinner: 'background-color: #020320;',

      cssName: 'color: #ffffff; background-color: #0513f1;',
      cssNameNext: 'color: #ffffff !important; background-color: #020320 !important;',
      cssNameLoser: 'opacity: 0.5;',
      cssNameWinner: '',

      cssVersus: 'color:#ffffff; background-color: #0513f1; margin:unset;',
      cssVersusNext: 'background-color: #020320 !important;',

      cssScore: 'color:#ffffff; background-color: #0513f1;',
      cssScoreLoser: 'opacity: 0.5; background-color: #0513f1;',
      cssScoreWinner: '',
      cssScoreNext: '',

      trimColorNext: '#0513f1',

      textColorDate: '#1b1b1b',
      textColorFeatureDate: '#dba103',

      backgroundColorRow: '#af8806',
      backgroundColorFeature: 'red',

      separatorColor: 'green',
      speedPanel: 200,
    },
    screenTitle: {
      textColor: '#ffffff',
      textShadowLeft: '#0011ff32',
      textShadowRight: '#0011ff32',
    },
    banPick: {
      leagueLogo: LJLAllstarLogo,
      backgroundImage: LJLAllstarBanPickBG,
      backgroundColorCoach: '#795b18',
    },
    gameScreen: {
      disableBaronPowerPlaySponsor: true,
      versus: {
        backgroundColor: '#020320',
      },
      teamInfo:{
        removeTeamInfo: true,
      },
      sponsors: [
        'https://cdn.dekki.com/uploads/eventSponsors/alienware.png',
        'https://cdn.dekki.com/uploads/eventSponsors/intel.png',
        'https://cdn.dekki.com/uploads/eventSponsors/riot.png',
        'https://cdn.dekki.com/uploads/eventSponsors/nishikawa.png',
      ],
    },
  },
};
