import PropTypes from "prop-types";
import React, { useEffect, useState, useContext } from "react";
import SidebarSchedule from "../../storybook-built/dist/components/Games/LJL/SidebarSchedule";
import Timer from "../../storybook-built/dist/components/Games/LJL/TopLevelComponents/Timer";
import { ThemeContext } from "../View/themes";
import { get } from "lodash";
import { preloadImages } from "../../util/loadImageUrl";
import validateDataTimer from "./../Data/DataTimer/validate";

const ScheduleInfoSideBar = (props) => {
  // const theme = (context.screens && context.screens.scheduleInfoSideBar) || {};
  const theme = useContext(ThemeContext);
  const [display, setDisplay] = useState(props.display);
  const [timer, setTimer] = useState(false);
  const [timerText, setTimerText] = useState("");
  const [timerOverwriteText, setTimerOverwriteText] = useState("");
  const [overwriteTimer, setOverwriteTimer] = useState(false);
  const [imagesLoaded, setImagesLoaded] = useState(false);

  useEffect(() => {
    // what even is this.
    const urls = [];
    if (urls.length > 0) {
      preloadImages(urls).then(() => setImagesLoaded(true));
    } else {
      setImagesLoaded(true);
    }
  }, []);

  useEffect(() => {
    if (props.timerActive) {
      props.statusCallback("timer", "open");
    } else {
      setTimer(false);
      setTimerOverwriteText("");
      props.statusCallback("timer", "closed");
    }
  }, [props.timerActive]);

  useEffect(() => {
    if (display !== props.display) {
      setDisplay(props.display);
    }
  }, [props.display]);

  useEffect(() => {
    if (
      get(props, "data.timer.timer.targetTime") &&
      validateDataTimer(get(props, "data.timer.timer.targetTime"))
    ) {
      setOverwriteTimer(false);
      setTimer(props.data.timer.timer.targetTime);
      setTimerText(props.data.timer.timer.timerText);
    } else if (get(props, "data.timer.timer.timerOverwriteText")) {
      setTimerOverwriteText(props.data.timer.timer.timerOverwriteText);
      setOverwriteTimer(true);
    }
  }, [props.data.timer]);
  console.log(props.data)
  return (
    (imagesLoaded &&
      props.data.scheduledMatches &&
      props.data.scheduledMatches.length && (
        <SidebarSchedule
          timerText={timerText}
          timer={
            timer && (
              <Timer
                lessThanOneHourStyle={get(
                  theme,
                  "screens.scheduleInfoSideBar.lessThanOneHourStyle",
                  ""
                )}
                moreThanOneHourStyle={get(
                  theme,
                  "screens.scheduleInfoSideBar.moreThanOneHourStyle",
                  ""
                )}
                targetTime={timer}
              />
            )
          }
          timerOverwriteText={timerOverwriteText || ""}
          overwriteTimer={overwriteTimer}
          isShowing={display}
          scheduledMatches={props.data.scheduledMatches}
          nextDayMatch={props.data.nextDayMatch}
          weekTitle={props.data.weekTitle}
          // weekTitle={'RUMBLE'}
          weekDate={props.data.weekDate}
          splitName={props.data.splitNameDisplay}
          sidebarMode={props.data.sidebarMode}
          showNextMatchup={props.data.showNextMatchup}
          theme={get(theme, "screens.scheduleInfoSideBar", {})}
          next={false} // change to true if you want the word 'NEXT' to go away.
        />
      )) ||
    null
  );
};

ScheduleInfoSideBar.propTypes = {
  display: PropTypes.bool.isRequired,
  data: PropTypes.object,
  timerActive: PropTypes.bool,
  statusCallback: PropTypes.func,
};

export default ScheduleInfoSideBar;
