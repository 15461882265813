// REACT
import React from 'react';
import styled from 'styled-components';
import {ThemeContext} from './themes';

export const StyleTitle = styled.h2`
    color: red;
    font-size: 4em;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 99999999;
`;

const ThemeLabel = () => {
    const theme = React.useContext(ThemeContext);
    return (
        <StyleTitle>{theme.name}</StyleTitle>
    );
}

export default ThemeLabel;