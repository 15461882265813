"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StylePositionLaneContainer = exports.StyleMatchupBarTop = exports.StyleMatchupBarBottom = exports.StyleMatchupBar = exports.StyleMainContainer = exports.StyleContent = exports.StyleContainerMatchupRow = void 0;

var _styledComponents = _interopRequireDefault(require("styled-components"));

var _reactSpring = require("react-spring");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var StyleMainContainer = _styledComponents.default.div.withConfig({
  displayName: "styles__StyleMainContainer",
  componentId: "sc-1lavwby-0"
})(["position:absolute;top:0;left:0;height:1080px;width:1920px;align-items:center;justify-content:center;display:flex;"]);

exports.StyleMainContainer = StyleMainContainer;

var StyleContent = _styledComponents.default.div.withConfig({
  displayName: "styles__StyleContent",
  componentId: "sc-1lavwby-1"
})(["overflow-x:hidden;display:block;"]);

exports.StyleContent = StyleContent;

var StyleContainerMatchupRow = _styledComponents.default.div.withConfig({
  displayName: "styles__StyleContainerMatchupRow",
  componentId: "sc-1lavwby-2"
})(["min-height:435px;"]);

exports.StyleContainerMatchupRow = StyleContainerMatchupRow;
var StyleMatchupBar = (0, _styledComponents.default)(_reactSpring.animated.div).withConfig({
  displayName: "styles__StyleMatchupBar",
  componentId: "sc-1lavwby-3"
})(["display:flex;justify-content:center;"]);
exports.StyleMatchupBar = StyleMatchupBar;
var StyleMatchupBarTop = (0, _styledComponents.default)(StyleMatchupBar).withConfig({
  displayName: "styles__StyleMatchupBarTop",
  componentId: "sc-1lavwby-4"
})([""]);
exports.StyleMatchupBarTop = StyleMatchupBarTop;
var StyleMatchupBarBottom = (0, _styledComponents.default)(StyleMatchupBar).withConfig({
  displayName: "styles__StyleMatchupBarBottom",
  componentId: "sc-1lavwby-5"
})([""]);
exports.StyleMatchupBarBottom = StyleMatchupBarBottom;
var StylePositionLaneContainer = (0, _styledComponents.default)(StyleMatchupBar).withConfig({
  displayName: "styles__StylePositionLaneContainer",
  componentId: "sc-1lavwby-6"
})([""]);
exports.StylePositionLaneContainer = StylePositionLaneContainer;