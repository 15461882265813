import { forEach, values, get } from 'lodash';
// REACT
import React, { Component } from 'react';
import PropTypes from 'prop-types';
// INTERNAL
import { /* send, */ subscribe } from '../../../services/api';
import ControlContainer from '../../Controller/ControlContainer';
import Button from '../../Button';
import validateDataStandings from '../../Data/DataStandings/validate';
import {validateDataScheduleHasRound} from '../../Data/DataSchedule/validate';
import { i18n } from '../../i18n';
import { getNextMatchTeams } from '../../../util/nextMatch';
import { sendPlayersLoopLayoutWidgetData } from '../../../util/widgetUtil';
import { updateScreenStatus } from '../../../util/controllerUtils';
import Picker from '../../Picker';
import { firebaseRealtimeDbSetValue } from '../../../util/firebase';


const PLAYER_POSITIONS = ['top', 'jungle', 'mid', 'ad', 'support'];
class StandingsControl extends Component {

    static propTypes = {
        cgpc: PropTypes.string,
        display: PropTypes.bool,
        screen1: PropTypes.bool,
      };

    state = {
        status: 'ready',
        selectedGroup: 'REGULAR', // ALL, REGULAR, Group1, Group2....
    }

    componentDidMount() {
        this.runSubscription();
        if (validateDataStandings() && validateDataScheduleHasRound()) {
            this.setState({status: 'ready'});
        } else {
            this.setState({status: 'invalid'});
        }
    }

    runSubscription = () => {
        subscribe('control-standings', null, data => {
            if (data.target === 'control-standings') {
                this.setState({status: data.status})
                this.setState({selectedGroup:  (data.data && data.data.selectedGroup) || this.state.selectedGroup});
            }
        }, this.props.cgpc);
    };

    onPlay = () => {
        if (validateDataStandings() && validateDataScheduleHasRound()) {
            if (window.MainData.standings && values(window.MainData.standings).length > 1) {
                if (!this.state.selectedGroup || this.state.selectedGroup === 'REGULAR') {
                    this.setState({status: 'invalid'});
                    return;
                }
            }
            const nextMatchTeams = getNextMatchTeams(window.MainData.schedule);
            const teamsInformation = this.getTeamsInformationAndFormat(nextMatchTeams);
            let formattedStandingsData = [];
            if(this.state.selectedGroup === 'ALL'){
                let groups = Object.keys(window.MainData.standings);
                groups.forEach(group => formattedStandingsData.push(this.formatStandingsData(window.MainData.standings, group)))
            }
            else {
                formattedStandingsData = this.formatStandingsData(window.MainData.standings, this.state.selectedGroup);
            }
            const sendData = {
                formattedStandingsData,
                hasTeams: window.MainData.teams && true,
                teamsInformation,
            };
            if (teamsInformation) {
                const preloadImages = [];
                teamsInformation.concat(formattedStandingsData).forEach(team => {
                    if (team.logo) {
                        if(!preloadImages.includes(team.logo)) preloadImages.push(team.logo);
                    }
                    if (team.roster) {
                        forEach(team.roster, player => {
                            if (player.image) {
                                if(!preloadImages.includes(team.logo)) preloadImages.push(team.logo);
                            }
                        });
                    }
                });
                sendData.preloadImages = preloadImages;
            }
            sendData.selectedGroup = this.state.selectedGroup || 'REGULAR';
            if (this.state.selectedGroup && this.state.selectedGroup !== 'REGULAR' && this.state.selectedGroup !== 'ALL') {
                sendData.groupNames = (window.MainData.standings_groups.find(item => item.id === this.state.selectedGroup) || {}).name;
            }
            else if(this.state.selectedGroup && this.state.selectedGroup === 'ALL'){
                sendData.groupNames = Object.keys(window.MainData.standings)
                .map(groupID => {
                    return (get(window, 'MainData.standings_groups',[]).find(item => item.id === groupID)||{}).name || '';
            });
            if(get(window, 'MainData.schedule.splitName')){
                sendData.titleExtra = window.MainData.schedule.splitName;
            }
            }
            sendPlayersLoopLayoutWidgetData().then(() => {
                // send('event', { target: 'view', action: 'open', screen: 'standings', data: sendData });
                updateScreenStatus('standings', 'open', sendData, null, this.props.cgpc);
            });
        } else {
            this.setState({status: 'invalid'});
        }
    }

    onClose = () => {
        // send('event', { target: 'view', action: 'close', screen: 'standings' });
        // this.setState({ selectedGroup: 'REGULAR' }, () => {
            updateScreenStatus('standings', 'close', null, null, this.props.cgpc);
        // });
    }

    formatStandingsData = (standingsData, group = 'REGULAR') => {
        const standingsDataArr = standingsData[group] || standingsData['REGULAR'];
        const standingDataFormated = [];
        standingsDataArr && standingsDataArr.forEach(team => {
            const teamInfo = {};
            teamInfo.score = {
                win: team.win,
                loss: team.loss,
            };
            teamInfo.rankLabel = team.rankLabel;
            teamInfo.rank = team.rank;
            teamInfo.name = team.name;
            teamInfo.color = window.MainData.teams[team.tricode].color;
            teamInfo.logo = window.MainData.teams[team.tricode].images && window.MainData.teams[team.tricode].images.logoFullColor;
            teamInfo.supertext = team.supertext;
            standingDataFormated.push(teamInfo);
        })
        return standingDataFormated;
    }

    getTeamsInformationAndFormat = incomingTeams => {
        const teams = [];
        if (incomingTeams) {
            Object.keys(incomingTeams).forEach(teamTricode => {
                const teamInfo = incomingTeams[teamTricode];
                const team = {};
                team.tricode = teamTricode.toLowerCase();
                team.name = teamInfo.name;
                team.color = teamInfo.color;
                team.logo = teamInfo.images && teamInfo.images.logoFullColor;
                team.roster = {};
                if (teamInfo.roster) {
                    Object.keys(teamInfo.roster).forEach(player => {
                        PLAYER_POSITIONS.forEach(position => {
                            if (!team.roster[position] && (teamInfo.roster[player].position === position || (position === 'ad' && teamInfo.roster[player].position === 'adc'))) {
                                const image = teamInfo.roster[player].images && teamInfo.roster[player].images.voting;
                                team.roster[position] = {
                                    player,
                                    image: image,
                                }
                            }
                        });
                    });
                }
                teams.push(team);
            });
        }
        return teams;
    }

    changeGroup = group => {
        firebaseRealtimeDbSetValue('control-standings/data/selectedGroup', group);
    }

    render() {
        return (
            <ControlContainer style={{display: this.props.display ? 'none': 'block'}} className="control--standings" status={this.state.status}>
                <div className="control__panel">
                    <h2 className="control__title">
                        <i className="fas fa-list-ul"></i> {i18n().pages.standings}
                    </h2>
                    <Button controllerSide={!this.props.screen1} action={this.onPlay} text={i18n().general.open} icon="fas fa-play-circle" disabled={
                        (this.state.status === 'open' || this.state.status === 'opening') && true }
                    />
                    <Button controllerSide={!this.props.screen1} action={this.onClose} text={i18n().general.close} icon="fas fa-stop-circle" disabled={
                        !(this.state.status === 'open' || this.state.status === 'opening') && true }
                    />
                </div>
                {
                    window.MainData.standings && values(window.MainData.standings).length > 1 &&
                    <div className="control-extra">
                        <div className="control-extra__fieldset">
                            {
                            <Picker preselect={this.state.selectedGroup}
                                    items={[
                                        ...Object.keys(window.MainData.standings).map(group => ({
                                            value: (window.MainData.standings_groups.find(item => item.id === group) || {}).id,
                                            name: (window.MainData.standings_groups.find(item => item.id === group) || {}).name,
                                        })),
                                        {
                                            value: 'ALL',
                                            name: 'ALL',
                                        }]
                                    }
                                onUpdate={this.changeGroup}
                            />
                            }
                        </div>
                    </div>
                }
            </ControlContainer>
        )
    }
}

export default StandingsControl
