import styled from 'styled-components';
import { animated } from 'react-spring';

export const StyleAssetBG = styled.div`
    position: absolute;
    background-image: url(${props => props.bg});
    width: 100%;
    height: 100%;
    z-index: 0;
`;

export const StyleKeyHeader = styled.div`
${props => props.css}
`;

export const StyleKeyTitles = styled.div`
${props => props.css}
`;

export const StyleGraphHeader = styled.div`
${props => props.css}
`;

export const StyleGraphKeys = styled.div`
${props => props.css}
`;

export const StyleGraphContent = styled.div`
${props => props.css}
`;

export const StyleDamageCount = styled.div`
  ${props => props.css}

`;


export const StyleSliderBackgroundContainer = styled.div`
  position: absolute;
  z-index: 0;
  width: 100%;
  height: 100%;
`;

export const StyleNewBreakdownWrapper = styled.div`
  background-color: rgba(6,9,13,0.5);
`;

export const StyleNewTitleContainer = styled(animated.div)`
  display: flex;
  align-items: center;
  height: 160px;
  background-color: #06090D;
`;

export const StyleNewTeamImage = styled.img`
  width: 474px;
`;

export const StyleTitleText = styled.div`
  display: flex;
  align-items: center;
`;

export const StyleTitleTeam = styled.p`
  width: 326px;
  text-transform: uppercase;
  font-family: ${props => props.win ? 'dharma-gothic-e-bold' : 'dharma-gothic-e-regular'};
  font-weight: ${props => props.win ? 700 : 400};
  color: #F5F5F5;
  font-size: 110px;
  margin-top: 16px;
  &:first-of-type {
    text-align: right;
  }
`;

export const StyleTitleGameTimeWrapper = styled.div`
  width: 319px;
  text-align: center;
  margin-top: 20px;
`;

export const StyleTitleGameTimeText = styled.p`
  text-transform: uppercase;
  font-size: 20px;
  font-family: aktiv-grotesk-ex-medium;
  font-weight: 500;
  color: #F5F5F5;
  height: 16px;
  margin-bottom: 5px;
`;

export const StyleTitleGameTime = styled.p`
  font-family: aktiv-grotesk-ex-bold;
  font-weight: 700;
  color: #F5F5F5;
  font-size: 54px;
`;

export const StyleNewStatsContainer = styled.div`
  display: flex;
  margin-top: 39px;
  padding: 0 100px;
  justify-content: space-between;
`;

export const StyleNewStatsColumn = styled.div`
  width: 820px;
`;

export const StyleNewStatsRow = styled.div`
  padding: 20px 26px;
  display: grid;
  grid-template-columns: ${props => props.withImages ? '4fr 1fr 4fr' : '3fr 3fr 3fr'};
  border-bottom: 2px solid #F5F5F5;
  &:first-of-type {
    padding-top: 0px;
    padding-bottom: 23px;
  }
  ${props => props.withImages ? `
    & p {
      text-align: center;
    }
  `:`
    & p:nth-of-type(2) {
      text-align: center;
    }
    & p:nth-of-type(3) {
      text-align: right;
    }
  `}
`;

export const StyleNewGameStatsTitle = styled.div`
  display: grid;
  grid-template-columns: 3fr 3fr 3fr;
  width: 100%;
  align-items: center;
  grid-column-start: 1;
  grid-column-end: 4;
  & img:last-of-type {
    margin-left: auto;
  }
  & p {
    text-align: center;
  }
`;

export const StyleNewGameStatsTeamLogo = styled.img`
  max-width: 63px;
  width: 100%;
  height: 51px;
  object-fit: cover;
`;

export const StyleNewStatsText = styled.p`
  text-transform: uppercase;
  font-family: aktiv-grotesk-ex-regular;
  font-weight: 400;
  font-size: ${props => props.small ? '17px' : '20px'};
  color: #F5F5F5;
  align-self: center;
  margin-top: 6px;
`;

export const StyleNewStatsNumber = styled.p`
  font-family: dharma-gothic-e-bold;
  font-weight: 700;
  font-size: 70px;
  letter-spacing: 0.7px;
  color: #F5F5F5;
  height: 62px;
`;

export const StyleNewStatsImageWrapper = styled.div`
  display: flex;
`;

export const StyleNewStatsDrakeImage = styled.img`
  width: 51px;
  height: auto;
  object-fit: contain;
  margin-right: 20px;
  &:last-of-type {
    margin-right: 0px;
  }
`;

export const StyleNewStatsBanImage = styled.img`
  width: 52px;
  height: 52px;
  object-fit: contain;
  margin-right: 10px;
  &:last-of-type {
    margin-right: 0px;
  }
`;

export const StyleNewStatsDamageDealtTitle = styled.div`
  display: grid;
  grid-template-columns: 1fr 7fr 1fr;
  margin-bottom: 22px;
  margin-top: 18px;
`;

export const StyleNewStatsTeamContainer = styled.div`
  display: grid;
  grid-template-columns: ${props => props.graph ? '15px auto' : props.rightSide ? 'auto 18px' : '18px auto'};
  grid-column-gap: ${props => props.graph ? '17px' : '13px'};
  align-items: center;
`;

export const StyleNewStatsTeamColor = styled.div`
  width: ${props => props.small ? '15px' : '18px'};
  height: ${props => props.small ? '15px' : '18px'};
  background-color: ${props => props.color ? props.color : 'transparent'};
`;

export const StyleNewStatsTeamTricode = styled.p`
  text-transform: uppercase;
  font-family: aktiv-grotesk-ex-bold;
  font-weight: 700;
  font-size: 20px;
  color: #FFFFFF;
  align-self: center;
  margin-top: 6px;
  text-align: ${props => props.rightSide ? 'right' : 'left'};
`;

export const StyleNewStatsDamageSection = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 20px;
  padding-bottom: 30px;
  border-bottom: 2px solid #F5F5F5;
`;

export const StyleNewStatsDamageTeam = styled.div`
  width: 100%;
  display: grid;
  grid-row-gap: 15px;
`;

export const StyleNewStatsGoldDiff = styled.div`
  padding-top: 30px;
`;

export const StyleNewStatsGraph = styled.div`
  padding: 20px 0 10px 0;
`;