const MainColors = {
  Primary1: '#56FFCC',
  Primary2: '#E8E8E8',
  Primary3: '#111111',
  Support1: '#FF4D65',
  Support2: '#FFD867',
  text: '#fe0000',
  textLight: '#fff2e5',
  textActive: '#8f9d55',
  background: '#101010'
}
export default {
  id: `msi2023`,
  name: `MSI 2023`,
  screens: {
    cornerLogo: {
      cssCornerLabelText:
        'display:none;',
      startX: '-400px',
      endX: '-400px',
      startY: '0px',
      endY: '0px',
      hideCornerLogo: false, // hide the CornerLogo by default on all screens
    },
    scheduleV2: {
      speedPanel: 200,
      logoWhite: true,
      bottom: `
        display: none;
      `,
      // background: `https://cdn.dekki.com/assets/broadcast/games/league-of-legends/backgrounds/MSI-2022_BG.png`, 
      StyleTeam: `
      font-family: TRY-Grtsk-Peta-Medium;
      font-size: 28px;
      margin-left: 35px;
      margin-right: 35px;
      `,
      StyleRes: `
      font-family: Akkurat-Mono-LL-bold;
      font-size: 21px;
      `,
      StyleRowDetailContainer: `
      background-color: ${MainColors.background};
      border-bottom: 5px solid ${MainColors.text};
      grid-template-columns: 3fr 0fr 3fr;
      `,
      StyleScheduleScreenBackground: `
      background-color: ${MainColors.background};
      margin-left: 230px;
      margin-right: 230px;
      li:last-child { border-bottom: none; }
      border-bottom: 14px solid ${MainColors.text};  
      // border-image: linear-gradient(to right, #6ae9a4, #ea8e5f) 1;
      `,
      mainColor: ` ${MainColors.Support1}`,
      secondaryColor: `${MainColors.text}`,
      StyleTeamDetailContainer: `
        display: flex;
        flex-direction: column;
        align-items: center;
        grid-column-start: auto;
        grid-column-end: auto;
        img {
          max-width: 300px;
          width: 100% !important;
          height: auto !important;
        }
      `,
      css5: `
      // top: 313px;
      top: 272px;
      position: relative;
      display: grid;
      grid-template-columns: 3fr 1fr 3fr;
      grid-template-rows: 1fr 15px;
      align-items: flex-start;
      text-transform: uppercase;
      overflow: hidden;
      background-color: white;
      width: 1460px;
      height: 617px;
      left: 231px; 
      font-family: TRY-Grtsk-Peta-Medium;
      font-size: 52px;
      line-height: normal;
      font-weight: normal;
      color: ${MainColors.text};
      // border-bottom: 15px solid;  
      // border-image: linear-gradient(to right, #6ae9a4, #ea8e5f) 1;
      img {
        width: 250px;
        height: 330px;
      }
      `,
      cssVersus: `color: ${MainColors.Support1}
      // display: grid;
      // grid-template-rows: 1fr 3fr 1fr; 
      // height: 70%;
      margin: auto 0;
      text-align: center;
      align-items: center;
      grid-column-start: auto;
      grid-column-end: auto;
      h1 {
        font-family: degular-text-semibold;
        font-size: 42px;
        color: ${MainColors.textActive}
      }
      span {
        font-family: degular-text-semibold;
        font-size: 42px;
        color: ${MainColors.textActive}
      }
      `,
      cssName: `
      font-family: degular-text-semibold;
      font-size: 36px;
      font-weight: 600;
      // width: 450px;
      color: ${MainColors.textLight};
      padding-left: 0px !important;
      margin-top: 0px;
      `,
      StyleGameDetail: `
        line-height: normal;
        font-family: Akkurat-Mono-LL-bold;
        font-size: 40px;
        font-weight: normal;
      `,
      StyleScoreDetailContainer: `
        color: ${MainColors.Support1};
        grid-column-start: auto;
        grid-column-end: auto;
      `,
      cssScore: `
        font-family: degular-text-semibold;
        font-size: 42px;
        color: ${MainColors.textActive};
        column-gap: 0px;
        display: flex;
        justify-content: center;
        span:first-of-type {
          margin-right: 15px;
        }
        span:last-of-type {
          margin-left: 15px;
        }
      `,
      VSTimeBo: `
        font-family: degular-text-semibold;
        font-size: 42px;
        color: ${MainColors.textActive}
      `,
      Bo5x2ScheduleV2Wrap: `
      max-height: none;
      width: 1340px;
      height: 100%;
      border-bottom: 1px solid ${MainColors.textLight};
      grid-template-columns: 32% 12% 12% 12% 32%;
      `,
      Bo5x2ScheduleV2Name: `
      font-family: degular-text-semibold;
      font-size: 34px;
      color: ${MainColors.textLight};
      margin-left: 55px;
      margin-right: 55px;
      `,
      Bo5x2ScheduleV2Logo: `
      `,
      Bo5x2ScheduleV2Score: `
      font-family: degular-text-semibold;
      font-size: 34px;
      color: ${MainColors.textActive};
      `,
      StyleWrapper: `
      grid-template-columns: 41% 5% 8% 5% 41%;
      `,
      StyleLogo: `
        height: 66px;
        width: 66px;
      `,
    },
    animatedBackground: {
      // defaultBackground: "https://cdn.dekki.com/uploads/broadcast-projects/msi2023/videos/MSI23_LivingHold.mp4",
      defaultBackground: "https://cdn.dekki.com/uploads/broadcast-projects/msi2023/videos/MSI23_LivingHold_GrandFinal.mp4",
      // disableAnimationBackGround: true,
      show: true,
    },
    gameBreakdown: {
      teamStats: {
        cssTeamName: "font-family: `United`; font-weight:800; color: #ffffff;",
        cssTeamStats:
          "font-family: `United`; background-color: #593d25; color: #ffffff;",
        cssWinTag: `background-color: #000000aa; color: #ffffff;`,
        cssLossTag: `background-color: #00000033; color: #ffffffcc;`,
        cssBansPicks: `color: #ffffff;`,
      },
      key: {
        cssHeader: "font-family: `United`;",
        cssTitles: "font-family: `United`;",
      },
      graph: {
        cssHeader:
          "font-family: `United`; font-weight: 400; color: #0a0b0c; background-color: #ab8652; background-image: unset;",
        cssContent:
          "font-family: `United`; font-weight: 400; background-color: #1f1d1acc; background-image: unset;",
        cssKey: "font-family: `United`; font-weight: 400;",
      },
    },
    scheduleInfoSideBar: {
      logoWhite: true,
      logoSrc: `https://dekki-production.s3-ap-northeast-1.amazonaws.com/uploads/leagues/msi_2023/msi-logo.png`, // top left logo
      StyleLeagueLogo: `
        width: 129px;
        height: 129px;
        `,
      StyleLeagueLogoBo5Finish: `
        width: 129px;
        height: 129px;
      `,
      StyleLogoImg: `
        width: 100%;
        height: auto;
      `,
      StyleSidebarSchedule: `
          background-color: #101010;
          background: #101010;
          border-right: 22px solid #fe0000;  
          width: 536px;
          padding-top: 94px;
          justify-content: flex-start;
        `,
      StyleUpcomingGameText: `
        font-family: degular-text-semibold;
        font-size: 24px;
        color: ${MainColors.textActive};
        padding-left: 15px;
        padding-bottom: 8px;
        `,
      StyleUpcomingGameTextOverwrite: `
        font-family: degular-text-semibold;
        font-size: 24px;
        color: ${MainColors.textActive};
        padding-left: 15px;
        padding-bottom: 8px;
      `,
      StyleUpcomingGameContainer: `
        margin-left: -22px;
        margin-bottom: 109px;
      `,
      StyleResultsGameText: `
        margin-left: -22px;
        font-family: aktiv-grotesk-black;
        font-size: 125px;
        letter-spacing: 1px;
        color: ${MainColors.textLight};
        transform: scaleX(0.5) translateX(-40%);
        font-weight: 900;
      `,
      StyleResultsGameTextWrapper: `
        margin-left: -22px;
      `,
      StyleUpcomingGameContainerBo5Finish: `
        padding: 0 25px;
        margin-left: -22px;
        margin-bottom: 109px;
      `,
      StyleTimer: `
        font-family: aktiv-grotesk-black;
        font-size: 125px;
        letter-spacing: 1px;
        color: ${MainColors.textLight};
        transform: scaleX(0.5) translateX(-40%);
        font-weight: 900;
        div {
          font-size: 125px;
        }
      `,
      timerOverwriteText: `
        font-family: aktiv-grotesk-black;
        font-size: 125px;
        letter-spacing: 1px;
        color: ${MainColors.textLight};
        transform: scaleX(0.5) translateX(-40%);
        font-weight: 900;
        margin-left: -10px;
      `,
      // cssTimerText: `color: #54cc4f; font-size: 22px; margin-left:20px; font-family: aeonik-medium; padding: 0px; overflow-wrap: anywhere; margin-bottom: 10px; font-weight: 400; white-space: nowrap; letter-spacing: 1px;`,
      // cssTimer: `color: white; font-size: 100px;  margin-left:20px; font-family: titling-gothic-comp-medium; letter-spacing: 3px; font-weight: 400;`,
      // cssBackground: `background: linear-gradient(#000000, #2e2e2e); border-right-color: #54cc4f;border-right-width: 22px; border-right-style: solid; letter-spacing: 3px;`,
      StyleWeekTitle: `
        font-family: degular-text-semibold;
        font-size: 26px;
        font-weight: 600;
        padding-left: 26px;
        padding-bottom: 20px;
        color: ${MainColors.text};
        `,
      // cssDayTitle: `color: white; font-size: 30px; font-family: aeonik-medium; font-weight: 400;`,
      // cssScheduleList: `color: white; font-size: 35px; font-family: titling-gothic-comp-medium; width:100%; font-weight: 400;`,
      // cssScheduleRow: `color: white; font-size: 35px; font-family: titling-gothic-comp-medium; font-weight: 400;`,
      StyleScheduleList: `
          padding: 0 23px;
            background-color: rgba(0,0,0,0.5);
            width: 100%;
            li, > div {
              border-bottom: 1px solid #fff2e5;
            } 
            li:last-child, div:last-child {
              border-bottom: none;
            }
        `,
      StyleScheduleRowBo5: `
        grid-template-columns: 1fr 2fr 1fr;
        height: 84px;
      `,
      Styleof5GridSingleGamesContainer: `
        li {
          border-bottom: 1px solid ${MainColors.textLight};
        } 
        li:last-child {
          border-bottom: none;
        }
        `,
      StyleUpcomingGameInfo: `
        bottom: 0px;
        `,
      StyleNextGame: `
        font-family: aktiv-grotesk-black;
        font-size: 45px;
        color: ${MainColors.textActive};
        // transform: scaleX(0.5);
        font-weight: 900;
        `,
      StyleWinTextContainer: `
        position: absolute;
        bottom: 0px;
        transform: translateY(50%);
      `,
      StyleNextGameBo5: `
        font-family: aktiv-grotesk-black;
        font-size: 45px;
        color: ${MainColors.textActive};
        // transform: scaleX(0.5);
        font-weight: 900;
        position: absolute;
        bottom: 0px;
        transform: translateY(50%);
        `,
      StyleGameTime: `
        font-family: aktiv-grotesk-black;
        font-size: 45px;
        color: ${MainColors.textLight};
        // transform: scaleX(0.5);
        font-weight: 900;
      `,
      StyleGameTimeBo5: `
        position: absolute;
        bottom: 0px;
        transform: translateY(50%);
      `,
      cssWin: `
        font-family: aktiv-grotesk-black;
        font-size: 45px;
        color: ${MainColors.textLight};
        // transform: scaleX(0.5);
        font-weight: 900;
        `,
      StyleCenterContainer: `
        font-family: aktiv-grotesk-black;
        font-size: 45px;
        color: ${MainColors.textLight};
        transform: scaleX(0.5);
        font-weight: 900;
        white-space: nowrap;
        `,
      cssScore: `
        font-family: aktiv-grotesk-black;
        font-size: 45px;
        color: ${MainColors.textLight};
        // transform: scaleX(0.5);
        font-weight: 900;
        `,
      StyleBof5Grid: `
        grid-template-columns: 2fr 1fr 2fr;
        margin: 0px;
      `,
      StyleBof5MatchContainer: `
        padding: 42px 0 50px 0;
      `,
      StyleBof5MatchName: `
        font-family: degular-text-semibold;
        font-weight: 600;
        font-size: 24px;
        font-style: normal;
        margin-top: 0px !important;
        color: ${MainColors.textLight};
      `,
      StyleBof5Logo: `
        max-width: 128px;
        height: auto;
        max-height: 128px;
        width: auto;
      `,
      StyleBof5Score: `
        font-family: aktiv-grotesk-black;
        font-weight: 900;
        font-size: 70px;
        color: ${MainColors.textActive};
        transform: scaleX(0.5);
      `,
      StyleBof5ScoreNotLive: `
        color: ${MainColors.textLight};
      `,
      StyleBof5ScoreSingleMatch: `        
        font-family: aktiv-grotesk-black;
        font-weight: 900;
        font-size: 90px;
        color: ${MainColors.textLight};
        transform: scaleX(0.5);
        width: 100%;
        margin: 58px 0 36px 0;
        &:first-of-type, &:last-of-type {
          width: 100%;
        }
      `,
      StyleBof5ScoreSingleMatchWinner: `
        color: ${MainColors.textActive};
        font-weight: 900;
        `,
      StyleBof5GridSingleMatch: `
        grid-template-columns: 2fr 2fr 2fr;
        margin: 0px;
        padding: 0px;
        border-bottom: none !important;
      `,
    },
    bottomBar: {
      borderColor: `teamMain`,
      borderColorBase: `#181818`,
      backgroundColor: `#181818`,
      cssTeamName: "font-family: `United`, sans-serif; color:#ffffff;",
      cssNextGameTitle: "font-family: `United`, sans-serif;",
      cssTimer: "font-family: `United`, sans-serif;",
    },
    screenTitle: {
      textColor: `#ffffff`,
      cssTitle:
        `font-family:United, sans-serif; font-weight: 400; text-shadow: unset;`,
    },
    screenTitleV2: {
      logoSrc: `https://dekki-production.s3-ap-northeast-1.amazonaws.com/uploads/leagues/msi_2023/msi-logo.png`,
      StyleTitleContainer: `
        // margin-left: 17px;
        // grid-template-columns: 1.07fr auto 1fr;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 1460px;
        left: 50%;
        transform: translateX(-50%);
        height: auto;
        padding: 30px 40px;
        background-color: ${MainColors.background};
        border-bottom: 2px solid ${MainColors.text};
      `,
      StyledLogo: `
      display: none;
      // background:
      // url('https://cdn.dekki.com/assets/broadcast/games/league-of-legends/backgrounds/MSI-2022_BG-Banner_logo.png') left center;
      // background-repeat: no-repeat;
      `,
      StyledBar: `
      background: ${MainColors.background};
      left: 0px;
      `,
      StyledBarResizeOverWrite: `
      background:
      url('https://cdn.dekki.com/assets/broadcast/games/league-of-legends/backgrounds/MSI-2022_BG-Banner_resized.png');
      `,
      StyleSubTitle: `
      font-family: degular-text-semibold;
      font-size: 30px;
      color: ${MainColors.textActive}; 
      letter-spacing: 0px;
      margin-left: 0px;
      margin-right: 0px;
      min-width: 195px;
      `,
      StyleSubTitleLeft: `
      `,
      StyleSubTitleRight: `
      `,
      StyleTeam200: `
        // margin-left: 46px;
      `,
      StyleTeam100: `
      // margin-right: 46px;
      `,
      StyledTitle: `
      font-family: aktiv-grotesk-black;
      font-weight: 900;
      font-size: 120px;
      color: ${MainColors.textLight}; 
      padding: 0px 57px;
      height: auto;
      max-height: 152px;
      min-width: auto;
      line-height: inherit;
      width: 460px;
      display: flex;
      justify-content: center;
      span {
        transform: scaleX(0.5);
        display: block;
      }
      `,
    },
    standingsV2: {
      scoreOpacityChange: false,
      showSubtext: true,
      showHeaders: true,
      showPos: true,
      animationThreeAtOnce: true,
      standingsResizeOverWrite: `
      background-image: url(https://cdn.dekki.com/assets/broadcast/games/league-of-legends/backgrounds/MSI-2022_BG_resized.png) !important; 
      background-repeat: no-repeat;
      background-size: cover;
      `,
      mainColor: MainColors.Primary1,
      StyleStandingsContainerSingle: `
        padding: 40px 65px 40px 65px !important;
        li { 
          padding-right: 0px !important;
          padding-left: 0px !important;
        }
      `,
      StyleStandingRowHeader: `width: 98%;`,
      StyleStandingRowHeaderResize: `width: 97%;`,
      StyleStandingRowHeaderSingle: `color:${MainColors.Support1};  border-bottom: 3px solid rgb(50, 50, 50, 0.2); width: 1330px; height:100%;`,
      StyleStandingRowHeaderSingleResize: `width: 1080px; color:${MainColors.Support1};  border-bottom: 3px solid rgb(50, 50, 50, 0.2);height:100%;`,
      StyleStandingRowSingle: `
        border-bottom: 3px solid rgb(50, 50, 50, 0.2);  height:100%; width: 1330px; 
      `,
      StyleStandingRowSingleResize: `
      border-bottom: 3px solid rgb(50, 50, 50, 0.2);  height:100%; width: 1080px;
    `,
      css: `
      color: ${MainColors.text};  
      font-family: TRY-Grtsk-Peta-Medium;
      font-weight: normal;
      font-size: 21px;
      background: ${MainColors.Primary2};
      border-bottom: 15px solid;  
      border-image: linear-gradient(to right, #6ae9a4, #ea8e5f) 1;
      background-image: url(https://cdn.dekki.com/assets/broadcast/games/league-of-legends/backgrounds/MSI-2022_BG.png);
      background-repeat: no-repeat;
      background-size: cover;
      grid-gap: 0%;
      padding: 60px 50px 60px 50px;
      li { border-right: 3px solid rgb(50, 50, 50, 0.2); border-left: 3px solid rgb(50, 50, 50, 0.2); 

      padding-right: 50px; padding-left: 50px;
    }
      li:first-child { border: none; }
      li:last-child { border: none; }
      `,
      StyleStandingSingle: `
        // ol { border-bottom: 3px solid rgb(50, 50, 50, 0.2); padding-bottom: 50px; }
        // ol:last-child { border-bottom: none; }
      `,
      StyleTeamPosHeader: `
      font-size: 21px;
      font-family: Akkurat-Mono-LL-bold;
      // margin-left: 10px;
      width: 85px;
      `,
      StyleTeamPosHeaderSingle: `
      font-size: 21px;
      font-family: Akkurat-Mono-LL-bold;
      margin-left: 50px;
      margin-right: 20px;
      `,
      StyleTeamPosSingle: `margin-left: 50px; margin-right: 20px;`,
      StyleTeamPos: `width: 70px;`,
      // StyleTeamLogoHeader:`width: 100px;`,
      // StyleTeamLogo:`width: 70px;`,
      StyleTeamNameHeader: `font-size: 21px; font-family: Akkurat-Mono-LL-bold;`,
      StyleTeamName: `margin-left: 15px;`,
      StyleTeamNameSingle: `margin-left: 80px;`,
      StyleTeamNameHeaderSingle: `margin-left: 80px; font-size: 21px; font-family: Akkurat-Mono-LL-bold;`,
      StyleTeamNameHeaderSingleResize: `font-size: 21px; font-family: Akkurat-Mono-LL-bold;`,
      StyleTeamReccordHeader: `font-size: 21px;letter-spacing: -6px; font-family: Akkurat-Mono-LL-bold;`,
      StyleTeamReccordHeaderSingle: `font-size: 21px; font-family: Akkurat-Mono-LL-bold; margin-right: 80px; letter-spacing: -3px;`,
      StyleTeamReccordSingle: `font-size: 28px;  font-family: TRY-Grtsk-Peta-Medium; margin-right: 80px; letter-spacing: -2px;`,
      StyleTeamReccord: `letter-spacing: -3px;  font-family: TRY-Grtsk-Peta-Medium;`,
      groupTitle: `
      color: ${MainColors.Support1};
      font-family: Akkurat-Mono-LL-bold;
      font-size: 28px;
      margin-top: 35px;
      `,
    },
  },
};
