import styled from 'styled-components';
import { prop, ifProp } from "styled-tools";
import posed from 'react-pose';
// There is no twin component in BCL

const StyleMatchPosed = posed.div({
    initialPose: 'start',
    start: {
        width: '100%',
        'clip-path': 'inset(0% 100% 0% 0%)',
        transition: { duration: 0 },
    },
    open: {
        width: '100%',
        'clip-path': 'inset(0% 0% 0% 0%)',
        transition: { duration: 700 },
    },
    end: {
        width: '100%',
        'clip-path': 'inset(0% 0% 0% 100%)',
        transition: { duration: 700 },
    },
});

export const StyleMatch = styled(StyleMatchPosed)`
    background-color: black;
    background-image: url(${prop("backgroundTexture", '')});
    border-width: 10px 0 10px 0;
    border-style: solid;
    border-image: linear-gradient(to right, 
        ${prop("borderGradientColorLeft", '#000000')}, 
        ${prop("borderGradientColorBase", '#000000')},
        ${prop("borderGradientColorRight", '#000000')}) 10;
    display: flex;
    height: 250px;
    left: 0;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    overflow: hidden;
    ${props => props.css}
`;

export const StyleTeam = styled.div`
    align-items: center;
    color: white;
    display: flex;
    font-size: 90px;
    height: 100%;
    justify-content: center;
    position: relative;
    clip-path: ${props => props.side === 'left' ? 'polygon(0 0, 92% 0, 100% 100%, 0% 100%)' : 'polygon(0 0, 100% 0, 100% 100%, 8% 100%)'} ;
    background-repeat: no-repeat;
    background-size: 122%;
    background-position: center;
    background-image: ${props => props.backgroundImage ? `url(${props.backgroundImage})` : `linear-gradient(to ${ifProp({side: 'right'}, 'left', 'right')}, ${prop("backgroundColor", '#999999')}, rgba(0,0,0,0))` };
    /* width: 850px; */
`;

export const StyleTeamLogo = styled.img`
    position: absolute;
    height: 260%;
    opacity: 0.7;
`;

export const StyleTeamName = styled.span`
    z-index: 2;
    text-shadow: 0px 5px 5px black;
    text-transform: uppercase;
    font-weight: 700;
    color: ${prop("textColor", '#ffffff')};
    ${props => props.css}
`;

export const StyleVersus = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 12%;
    color: ${prop("textColor", '#ffffff')};
    font-size: 105px;
    font-weight: 800;
    text-align: center;
    ${props => props.css}
`;
