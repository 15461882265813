const validateDataStandings = () => {
    if (!window.MainData.standings) { console.error('failed on standings object'); return false; } // eslint-disable-line no-console
    if(window.MainData.standings.length === 0) { console.log('validateDataStandings array was empty.'); return false }
    const firstObjKey = Object.keys(window.MainData.standings)[0];
    const firstObj = window.MainData.standings[firstObjKey];
    if (!firstObj) { console.error('failed having standings groups'); return false; } // eslint-disable-line no-console
    if (!firstObj[0]) { console.error('failed on having teams'); return false; } // eslint-disable-line no-console
    if (!firstObj[0].tricode) { console.error('failed on tricode'); return false; } // eslint-disable-line no-console
    if (!firstObj[0].name) { console.error('failed on name'); return false; } // eslint-disable-line no-console
    if (isNaN(firstObj[0].win)) { console.error('failed on win'); return false; } // eslint-disable-line no-console
    if (isNaN(firstObj[0].loss)) { console.error('failed on loss'); return false; } // eslint-disable-line no-console
    if (!firstObj[0].gameDiff && firstObj[0].gameDiff !== 0) { console.error('failed on gameDiff'); return false; } // eslint-disable-line no-console
    return true;
}

export default validateDataStandings