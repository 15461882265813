// REACT
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';

// ThemeContext
import { ThemeContext } from '../../View/themes';
// 3RD PARTY
// import { merge, isEqual } from 'lodash';

import {loadImageUrlChampSquare/* , preloadImages */} from '../../../util/loadImageUrl';
// import { activeTeams } from '../../../util/const';
import styled from 'styled-components';

class TeamStats extends Component {

  static propTypes = {
    backgroundImage: PropTypes.string,
    bans: PropTypes.array,
    dragons: PropTypes.array,
    elderDragonCount: PropTypes.number,
    isRight: PropTypes.bool,
    picks: PropTypes.array,
    statData: PropTypes.shape({
      win: PropTypes.bool,
      kills: PropTypes.any,
      deaths: PropTypes.any,
      assists: PropTypes.any,
      towerKills: PropTypes.any,
      baronKills: PropTypes.any,
      gold: PropTypes.any,
    }),
    team: PropTypes.object,
    // themeName: PropTypes.string,
    theme: PropTypes.object,
  };

  // constructor(){
  //   super();
  // }

  // Rerender only one time !!
  fisrtLauch = true;
  shouldComponentUpdate() {
    if (this.fisrtLauch) {
      this.fisrtLauch = false;
      return true;
    }
    return false;
  }

  renderPage = () => {
    const theme = this.props.theme || {};

    // let teamTricodeUpperCase = this.props.team.tricode.toUpperCase();
    const team = this.props.team;

    let contentStyle = {
      backgroundColor: `${theme.teamStatsBGColor ? theme.teamStatsBGColor : `${team.color.sub}96`}`,
    };
    contentStyle['backgroundImage'] = theme.teamStatsBGTexture === undefined ? "url('https://cdn.dekki.com/assets/broadcast/games/league-of-legends/view/leaderboard-texture.png')" : `url(${theme.teamStatsBGTexture})`;
      // contentStyle = merge( contentStyle, theme.cssTeamStats);

    const headerStyle = { background: `${theme.teamStatsHeaderBGColor || team.color.sub}`, justifyContent: 'center',
    alignItems: 'center',
    display: 'flex'};
    // if (!activeTeams.some(activeTeam => activeTeam.tricode.toUpperCase() === teamTricodeUpperCase)) {
    //   teamTricodeUpperCase = this.props.isRight ? 'team200' : 'team100';
    // }
    const bans = this.props.isRight ? this.props.bans.reverse() : this.props.bans;
    const teamName = team.name;

    const BackgroundImage = styled.div`
      background-image: ${props => props.background ? `url(${props.background})` : 'none'};
      background-repeat: no-repeat;
      width: 100%;
      height: 145%;
      top: 0;
      position: absolute;
      transform: ${props => props.isRight ? 'scale(2) translate(230px,-40px)' : 'scale(2) translate(60px,-40px)'};
      opacity: 0.3;
    `;

    const StyleWinLossTag = styled.span`
        // height: 60px;
        // padding: 0 20px;
        // display: flex;
        // align-items: center;
        // text-align: center;
        // justify-content: center;
        // margin-left: ${props => props.isRight ? '40px' : '0'};
        // margin-right: ${props => props.isRight? '0': '40px'};
        // font-size: 30px;
        // font-weight: 500;
        // background-color: #fff;
        // color: #000;
        ${props => props.win ? props.cssWin : props.cssLoss}
    `;

    const StyleTeamName = styled.p`
      position: relative;
      top: 3px;
      order: ${props => props.isRight? '2': 'unset'};
      ${props => props.css}
      ${props => props.cssSide}
    `;

    const StyleStatsContents = styled.div`
      ${props => props.css}
    `;

    const StyleBanPicks = styled.div`
  
      ${props => props.css}
    `;

    const StyleHeader = styled.div`
      display: grid;
      grid-template-columns: ${props => props.isRight ? '1fr 4fr' : '4fr 1fr'};
      height: 98px;
      align-items: center;
      color: white;
      
    `;

    return (
      <div className={`animated animated--fast game-breakdown__content__main__team-stats ${this.props.isRight ? 'game-breakdown__content__main__team-stats--right' : ''}`}>
        <StyleHeader
          // style={{backgroundColor: '#141720'}}
          style={headerStyle}
          isRight={this.props.isRight}
        >
          <StyleTeamName  isRight={this.props.isRight}
            css={theme.cssTeamName || ''}
            cssSide={this.props.isRight? theme.cssTeamNameRight : theme.cssTeamNameLeft}
          >
            {teamName}</StyleTeamName>
          {/* <StyleWinLossTag win={this.props.statData.win}
          isRight={this.props.isRight}
            cssWin={theme.cssWinTag || ''} cssLoss={theme.cssLossTag || ''}
          >
          {this.props.statData.win ? 'WIN' : 'LOSS'}
        </StyleWinLossTag> */}

        </StyleHeader>
        <StyleStatsContents className= 'mask' css={theme.cssTeamStats|| '' } >
          <div className={`animated animated--fast game-breakdown__content__main__team-stats__content ${this.props.isRight ? 'game-breakdown__content__main__team-stats__content--right' : ''}`} style={contentStyle}>
            {theme.showTeamBackgroundImageStatsContents && <BackgroundImage background={this.props.backgroundImage} isRight={this.props.isRight} />}
            <div className="game-breakdown__content__main__team-stats__content__champions">
              <div className="game-breakdown__content__main__team-stats__content__champions__picks">
                <StyleBanPicks className="game-breakdown__content__main__team-stats__content__champions__picks__title" css={theme.cssBansPicks||''}>picks</StyleBanPicks>
                {
                  this.props.picks.map((p, i) => {
                    const pickStyle = {backgroundImage: 'url(' + loadImageUrlChampSquare(p) + ')'};
                    return (<div key={i} className={`game-breakdown__content__main__team-stats__content__champions__picks__image`} style={pickStyle}></div>
                    )
                  })
                }
              </div>

              <div className="game-breakdown__content__main__team-stats__content__champions__bans">
                <div className="game-breakdown__content__main__team-stats__content__champions__bans__banned">
                  <StyleBanPicks className="game-breakdown__content__main__team-stats__content__champions__bans__banned__title" css={theme.cssBansPicks||''}>bans</StyleBanPicks>
                  <div className="game-breakdown__content__main__team-stats__content__champions__bans__banned__set-one">
                    {
                      bans.slice(0, 3).map((b, i) => {
                        const banStyle = {backgroundImage: 'url(' + loadImageUrlChampSquare(b) + ')'};
                        return (<div key={i} className={`game-breakdown__content__main__team-stats__content__champions__bans__banned__set-one__image banned-hero`} style={banStyle}></div>)
                      })
                    }
                  </div>
                  <div className="game-breakdown__content__main__team-stats__content__champions__bans__banned__set-two">
                  {
                      bans.slice(3, 5).map((b, i) => {
                        const banStyle = {backgroundImage: 'url(' + loadImageUrlChampSquare(b) + ')'};
                        return (<div key={i} className={`game-breakdown__content__main__team-stats__content__champions__bans__banned__set-one__image banned-hero`} style={banStyle}></div>)
                      })
                    }
                  </div>
                </div>
              </div>
            </div>
            <div style={{alignItems: 'center', display: 'flex', justifyContent: 'center', flexDirection: 'column', zIndex: 2, height: '100%'}}>
            <StyleWinLossTag win={this.props.statData.win}
          isRight={this.props.isRight}
            cssWin={theme.cssWinTag || ''} cssLoss={theme.cssLossTag || ''}
            >
          {this.props.statData.win ? 'WIN' : 'LOSS'}
        </StyleWinLossTag>
        <div className="game-breakdown__content__main__team-stats__content__result">
              <div
                style={(theme && theme.item) || {}}
                className="game-breakdown__content__main__team-stats__content__result__item"
              >{`${this.props.statData.kills}/${this.props.statData.deaths}/${this.props.statData.assists}`}</div>
              <div
                style={(theme && theme.item) || {}}
                className="game-breakdown__content__main__team-stats__content__result__item"
              >{get(this.props, 'statData.gold', '').toLocaleString()}</div>
              <div
                style={(theme && theme.item) || {}}
                className="game-breakdown__content__main__team-stats__content__result__item"
              >{this.props.statData.towerKills}</div>
              <div
                style={(theme && theme.item) || {}}
                className="game-breakdown__content__main__team-stats__content__result__item game-breakdown__content__main__team-stats__content__result__item--dragons"
              >
                {this.props.dragons.map((d, i) => <div key={i}
                  style={(theme && theme.dragons) || {}}
                  className={`game-breakdown__content__main__team-stats__content__result__item__dragon game-breakdown__content__main__team-stats__content__result__item__dragon--${d}`}
                                                  ></div>)}
              </div>
              <div
                style={(theme && theme.item) || {}}
                className="game-breakdown__content__main__team-stats__content__result__item"
              >{this.props.elderDragonCount}</div>
              <div
                style={(theme && theme.item) || {}}
                className="game-breakdown__content__main__team-stats__content__result__item"
              >{this.props.statData.baronKills}</div>
            </div>
            </div>
          </div>
        </StyleStatsContents>
      </div>
    );
  };

  render() {
    return this.renderPage();
  }

}

TeamStats.contextType = ThemeContext;

export default TeamStats
