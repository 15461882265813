"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StyleVideo = exports.StyleTotalPointsHeader = exports.StyleTotalPoints = exports.StyleTeamRow = exports.StyleTeamName = exports.StyleTeamData = exports.StyleTeamColumnContainer = exports.StyleTableHeader = exports.StyleTableContainer = exports.StyleScoreHeader = exports.StyleScore = exports.StyleRankHeader = exports.StyleRank = exports.StylePointsHeader = exports.StylePoints = exports.StyleNameSpan = exports.StyleNameHeader = exports.StyleLogoContainer = exports.StyleHeaderTitleSub = exports.StyleHeaderTitleMain = exports.StyleHeaderContainer = exports.StyleDataHeader = exports.StyleBaseResults = exports.StyleBase = void 0;

var _styledComponents = _interopRequireDefault(require("styled-components"));

var _reactPose = _interopRequireDefault(require("react-pose"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var mainScreenFading = _reactPose.default.div({
  initialPose: 'hide',
  show: {
    opacity: 1,
    duration: 1000
  },
  hide: {
    opacity: 0,
    duration: 1000,
    delay: 1700
  }
});

var StyleBase = (0, _styledComponents.default)(mainScreenFading).withConfig({
  displayName: "styles__StyleBase",
  componentId: "sc-b5zlzz-0"
})(["position:relative;color:white;font-weight:100;display:flex;flex-direction:row;justify-content:center;align-items:center;font-family:inherit;font-size:34px;letter-spacing:2px;overflow:hidden;width:1920px;height:1080px;background-color:gray;background-size:100% 100%;"]);
exports.StyleBase = StyleBase;

var StyleVideo = _styledComponents.default.video.withConfig({
  displayName: "styles__StyleVideo",
  componentId: "sc-b5zlzz-1"
})(["height:100%;width:100%;background-color:transparent;position:absolute;z-index:0;"]);

exports.StyleVideo = StyleVideo;

var StyleLogoContainer = _styledComponents.default.div.withConfig({
  displayName: "styles__StyleLogoContainer",
  componentId: "sc-b5zlzz-2"
})(["position:absolute;right:5%;top:0;z-index:2;width:200px;height:auto;img{width:100%;height:100%;}", ""], function (props) {
  return props.css;
});

exports.StyleLogoContainer = StyleLogoContainer;

var StyleBaseResults = _styledComponents.default.div.withConfig({
  displayName: "styles__StyleBaseResults",
  componentId: "sc-b5zlzz-3"
})(["posittion:relative;width:92%;height:87%;margin:auto;background:url(", ");background-size:100% 100%;z-index:1;"], function (props) {
  return props.BG;
}); // TITLE / HEADER


exports.StyleBaseResults = StyleBaseResults;

var StyleHeaderContainer = _styledComponents.default.div.withConfig({
  displayName: "styles__StyleHeaderContainer",
  componentId: "sc-b5zlzz-4"
})(["position:relative;top:0px;width:100%;height:15%;display:flex;"]);

exports.StyleHeaderContainer = StyleHeaderContainer;

var StyleHeaderTitleMain = _styledComponents.default.div.withConfig({
  displayName: "styles__StyleHeaderTitleMain",
  componentId: "sc-b5zlzz-5"
})(["display:flex;height:100%;min-width:30%;align-items:center;justify-content:center;color:#ffffff;font-family:AgencyFB;font-weight:800;font-size:110px;background:url(", ");background-position:left center;background-size:cover;border-right:10px solid black;", ""], function (props) {
  return props.BG;
}, function (props) {
  return props.css;
});

exports.StyleHeaderTitleMain = StyleHeaderTitleMain;

var StyleHeaderTitleSub = _styledComponents.default.div.withConfig({
  displayName: "styles__StyleHeaderTitleSub",
  componentId: "sc-b5zlzz-6"
})(["display:flex;height:100%;min-width:70%;align-items:center;padding:30px;color:#000000;font-family:AgencyFB;font-weight:800;font-size:50px;background:url(", ");background-size:102% 100%;text-transform:uppercase;background-position:right center;", ""], function (props) {
  return props.BG;
}, function (props) {
  return props.css;
}); // TABLE


exports.StyleHeaderTitleSub = StyleHeaderTitleSub;

var StyleTableContainer = _styledComponents.default.div.withConfig({
  displayName: "styles__StyleTableContainer",
  componentId: "sc-b5zlzz-7"
})(["width:100%;height:88%;display:flex;font-family:'AgencyFB','Noto Sans JP';font-weight:800;padding-bottom:50px;"]);

exports.StyleTableContainer = StyleTableContainer;

var ContainerAnimation = _reactPose.default.div({
  show: {
    staggerChildren: 200,
    staggerDirection: 1
  },
  hide: {
    staggerChildren: 200,
    staggerDirection: -1
  }
}); // TABLE


var StyleTeamColumnContainer = (0, _styledComponents.default)(ContainerAnimation).withConfig({
  displayName: "styles__StyleTeamColumnContainer",
  componentId: "sc-b5zlzz-8"
})(["width:50%;height:100%;padding:", ";display:flex;flex-direction:column;text-transform:uppercase;justify-content:flex-start;margin-top:25px;"], function (props) {
  return props.column === 'left' ? '2% 1% 2% 5%' : '2% 4% 2% 1%';
});
exports.StyleTeamColumnContainer = StyleTeamColumnContainer;

var RowContainerAnimation = _reactPose.default.div({
  initialPose: 'hide',
  hide: {
    y: 0,
    x: -500,
    z: 1,
    scale: 1,
    opacity: 0,
    transition: {
      duration: 400
    }
  },
  show: {
    y: 0,
    x: 0,
    z: 1,
    opacity: 1,
    transition: {
      duration: 400
    }
  }
}); // TABLE FIRST ROW


var StyleTableHeader = (0, _styledComponents.default)(RowContainerAnimation).withConfig({
  displayName: "styles__StyleTableHeader",
  componentId: "sc-b5zlzz-9"
})(["width:100%;height:50px;color:#ffffff;font-size:25px;fot-weight:400;align-items:center;display:flex;width:100%;", ""], function (props) {
  return props.css;
});
exports.StyleTableHeader = StyleTableHeader;

var StyleRankHeader = _styledComponents.default.div.withConfig({
  displayName: "styles__StyleRankHeader",
  componentId: "sc-b5zlzz-10"
})(["     min-width:12%;max-width:12%;margin-right:12px;padding-left:20px;"]);

exports.StyleRankHeader = StyleRankHeader;

var StyleNameHeader = _styledComponents.default.div.withConfig({
  displayName: "styles__StyleNameHeader",
  componentId: "sc-b5zlzz-11"
})(["width:465px;margin-right:12px;padding-left:20px;"]);

exports.StyleNameHeader = StyleNameHeader;

var StyleDataHeader = _styledComponents.default.div.withConfig({
  displayName: "styles__StyleDataHeader",
  componentId: "sc-b5zlzz-12"
})(["min-width:25%;max-width:25%;margin-right:12px;display:flex;"]);

exports.StyleDataHeader = StyleDataHeader;

var StyleScoreHeader = _styledComponents.default.div.withConfig({
  displayName: "styles__StyleScoreHeader",
  componentId: "sc-b5zlzz-13"
})(["min-width:45%;max-width:45%;margin-right:12px;text-align:center;"]);

exports.StyleScoreHeader = StyleScoreHeader;

var StylePointsHeader = _styledComponents.default.div.withConfig({
  displayName: "styles__StylePointsHeader",
  componentId: "sc-b5zlzz-14"
})(["min-width:45%;max-width:45%;margin-right:12px;text-align:center;"]);

exports.StylePointsHeader = StylePointsHeader;

var StyleTotalPointsHeader = _styledComponents.default.div.withConfig({
  displayName: "styles__StyleTotalPointsHeader",
  componentId: "sc-b5zlzz-15"
})(["align-items:center;display:flex;height:100%;justify-content:center;margin-right:10px;min-width:90%;max-width:90%;text-align:center;"]); // TABLE ROW


exports.StyleTotalPointsHeader = StyleTotalPointsHeader;
var StyleTeamRow = (0, _styledComponents.default)(RowContainerAnimation).withConfig({
  displayName: "styles__StyleTeamRow",
  componentId: "sc-b5zlzz-16"
})(["align-items:center;display:flex;width:100%;border:transparent 2px;margin:0 0 12px 0px;z-index:2;height:63px;"]);
exports.StyleTeamRow = StyleTeamRow;

var StyleRank = _styledComponents.default.div.withConfig({
  displayName: "styles__StyleRank",
  componentId: "sc-b5zlzz-17"
})(["align-items:center;display:flex;font-weight:800;height:100%;justify-content:center;flex:1;margin-right:12px;min-width:12%;max-width:12%;color:#ffffff;background-color:#942717;", ""], function (props) {
  return props.css;
});

exports.StyleRank = StyleRank;

var StyleTeamName = _styledComponents.default.div.withConfig({
  displayName: "styles__StyleTeamName",
  componentId: "sc-b5zlzz-18"
})(["width:460px;max-width:460px;height:65px;padding-left:20px;padding-right:10px;margin-right:10px;color:#000000;background-color:#f2a900;& > div{height:100%;display:flex;align-items:center;}", ""], function (props) {
  return props.css;
});

exports.StyleTeamName = StyleTeamName;

var StyleNameSpan = _styledComponents.default.span.withConfig({
  displayName: "styles__StyleNameSpan",
  componentId: "sc-b5zlzz-19"
})([""]);

exports.StyleNameSpan = StyleNameSpan;

var StyleTeamData = _styledComponents.default.div.withConfig({
  displayName: "styles__StyleTeamData",
  componentId: "sc-b5zlzz-20"
})(["display:flex;height:100%;margin-right:10px;min-width:25%;max-width:25%;color:#f2a900;"]);

exports.StyleTeamData = StyleTeamData;

var StyleScore = _styledComponents.default.div.withConfig({
  displayName: "styles__StyleScore",
  componentId: "sc-b5zlzz-21"
})(["align-items:center;display:flex;height:100%;justify-content:center;margin-right:12px;min-width:45%;max-width:45%;color:#f2a900;background-color:#000000;", ""], function (props) {
  return props.css;
});

exports.StyleScore = StyleScore;

var StylePoints = _styledComponents.default.div.withConfig({
  displayName: "styles__StylePoints",
  componentId: "sc-b5zlzz-22"
})(["align-items:center;display:flex;height:100%;justify-content:center;margin-right:10px;min-width:45%;max-width:45%;color:#f2a900;background-color:#000000;", ""], function (props) {
  return props.css;
});

exports.StylePoints = StylePoints;

var StyleTotalPoints = _styledComponents.default.div.withConfig({
  displayName: "styles__StyleTotalPoints",
  componentId: "sc-b5zlzz-23"
})(["align-items:center;display:flex;height:100%;justify-content:center;margin-right:10px;min-width:90%;max-width:90%;color:#f2a900;background-color:#000000;", ""], function (props) {
  return props.css;
});

exports.StyleTotalPoints = StyleTotalPoints;