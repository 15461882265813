// REACT
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
// internal
import {i18n} from '../../../i18n';
import Picker from '../../../Picker/PickerBase';

class DataStandingsTable extends Component {

    state = {
    };

    static propTypes = {
        group: PropTypes.string,
        roundQuery: PropTypes.string,
        standings: PropTypes.array,
        updateResult: PropTypes.func,
        onRankLabelChanged: PropTypes.func,
        moveUp: PropTypes.func,
        moveDown: PropTypes.func,
        showGroup: PropTypes.bool,
    }

    getRankingPickerItems() {
        const choices = new Array(this.props.standings.length).fill('x')
            .map((item, index) => {
                return {
                    name: `${index+1}`,
                    value: `${index+1}`,
                }
            });
        choices.push({ name: '-', value: '-' });
        return choices;
    }

    render() {
        // console.log("DataStandingsTable props", this.props);
        return (
            <div className="data-panel__fieldset__body">
                {this.props.showGroup && this.props.group && <div className="row"><div className="pill">{this.props.group}</div></div>}
                <div className="row">
                    <div className="pill" style={{}}>
                        <span className="span--x-short">Pos. Label</span>
                        <span className="span--x-wide">{i18n().general.team}</span>
                        <span className="span--wide">W</span>
                        <span className="span--wide">L</span>
                        <span className="span--wide">Reorder</span>
                    </div>
                </div>
                {
                    this.props.standings.map((team, i) =>
                        <StyleRow key={i} className="row">
                            <div className="pill">
                                <Picker
                                    service={this.getRankingPickerItems()}
                                    onChange={(value) => { this.props.onRankLabelChanged(i, value); }}
                                    showSelectOption={false}
                                    value={team.rankLabel}
                                />
                                <span className="span--x-wide">{team.name}</span>
                                <span className="span--wide">
                                    <div className="plusminus">
                                        <button className="plusminus__button plusminus__button--minus" onClick={() => this.props.updateResult(team.tricode, 'win', -1, this.props.roundQuery)}><i className="fas fa-minus-circle" /></button>
                                        <span>{(this.props.standings[this.props.group] && this.props.standings[this.props.group].win) || team.win || 0}</span>
                                        <button className="plusminus__button plusminus__button--plus"  onClick={() => this.props.updateResult(team.tricode, 'win', 1, this.props.roundQuery)}><i className="fas fa-plus-circle" /></button>
                                    </div>
                                </span>
                                <span className="span--wide">
                                    <div className="plusminus">
                                        <button className="plusminus__button plusminus__button--minus" onClick={() => this.props.updateResult(team.tricode, 'loss', -1, this.props.roundQuery)}><i className="fas fa-minus-circle" /></button>
                                        <span>{(this.props.standings[this.props.group] && this.props.standings[this.props.group].loss) || team.loss || 0}</span>
                                        <button className="plusminus__button plusminus__button--plus" onClick={() => this.props.updateResult(team.tricode, 'loss', 1, this.props.roundQuery)}><i className="fas fa-plus-circle" /></button>
                                    </div>
                                </span>
                                <span className="span--wide" >
                                    <StyleUpDownContainer>
                                        {((i !== 0) && <i className={'fas fa-chevron-up'} onClick={() => { this.props.moveUp(i) }} /> ) || <div />}
                                        {((i < this.props.standings.length -1) && <i className={'fas fa-chevron-down'} onClick={() => { this.props.moveDown(i) }} />) || null}
                                    </StyleUpDownContainer>
                                </span>

                            </div>
                        </StyleRow>)
                }
            </div>
        )
    }
}

const StyleUpDownContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    height: 100%;
    text-align: center;
    cursor: pointer;
    i {
        width: 25px;
        height: 25px;
        margin: auto;
        border-radius: 40%;
        box-shadow: 2px 1px 1px black;
        :active{
            box-shadow: 1px 1px 1px black;
        }
    }
    svg {
        vertical-align: baseline;
    }
`;

const StyleRow = styled.div`
    select {
        width: 75px;
        text-align-last: center;
    }
`;

export default DataStandingsTable
