// REACT
import React, { Component } from 'react';
import PropTypes from 'prop-types';
// internal
import {i18n} from '../../i18n';

class DataContainer extends Component {

    state = {
        open: false,
    };

    static propTypes = {
        children: PropTypes.any,
        componentSlug: PropTypes.string,
        icon: PropTypes.string,
        extra: PropTypes.string,
        style: PropTypes.object,
    };

    onClickHeader = () => {
        this.setState({open:!this.state.open});
    }

    render () {

        return (
            <div className={`data-panel data-panel--${this.props.componentSlug} ${this.state.open ? 'data-panel--open' : 'data-panel--closed'}`} style={this.props.style || {}}>
                <h2 className="data-panel__title" onClick={this.onClickHeader}><i className={this.props.icon}></i> {i18n().general[this.props.componentSlug]} <span className="data-panel__title__extra">{this.props.extra}</span></h2>
                {this.props.children}
            </div>
        );

    }

}

export default DataContainer