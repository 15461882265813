// REACT
import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
// import styled from 'styled-components';

import { Asset } from '../styles';
import {
  StyleBaronBuffContainer,
  StyleBaronAsset,
  BuffBaronTimer,
  BuffBaronGoldDiff,
  StyleGoldDiffFinal,
} from './styles';

const BaronPowerPlay = props => {
  const [showState, setShowState] = useState(false);
  const [goldDiffState, setGoldDiffState] = useState();

  const showStateTimeoutRef = useRef(null);

  useEffect(() => {
    if (props.show) {
      setShowState(true);
      setGoldDiffState(null);
    } else if (!props.show) {
      if (props.goldDifference && props.goldDifference > 0) {
        setGoldDiffState(props.goldDifference);
      } else {
        setShowState(false);
      }
    }
  }, [props.show]);

  useEffect(() => {
   if(!showState){
    //  Reset the goldDiffState when component is no more visible
    setTimeout(()=>{
      setGoldDiffState();
    }, 2000);
   }
  }, [showState]);

  useEffect(() => {
    if (goldDiffState) {
      //Delay the hide of the component to display the total goldDifference
      clearTimeout(showStateTimeoutRef.current);
      showStateTimeoutRef.current = setTimeout(() => {setShowState(false);}, 10000);
    } else if (props.show && !goldDiffState) {
      setShowState(true);
      return
    }

    return () => {
      clearTimeout(showStateTimeoutRef.current);
      showStateTimeoutRef.current = null;
    }
  }, [goldDiffState]);

  return (
    <div>
        <Asset show>
        {/* {showAssetState && <img alt="" src={props.baronAsset} />} */}
          <StyleBaronBuffContainer
            color={props.color}
            isGoldDiffActive={props.goldDifference || goldDiffState}
            show={showState && !props.isGamePaused}
          >
          <StyleBaronAsset
            src={props.baronAsset || 'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/baron_buff_timer_NonOverlay.png'}
            show={!goldDiffState}
          />
          <BuffBaronTimer
            BuffBaronTimer={props.BuffBaronTimer}
            color={props.color}
          >
            {props.timer}
          </BuffBaronTimer>
            {(props.goldDifference &&
              <BuffBaronGoldDiff
                BuffBaronGoldDiff={props.theme.BuffBaronGoldDiff || ''}
                show={props.goldDifference && !goldDiffState}
                color={props.color}
              >
                {`${props.goldDifference >= 0 ? '+' : ''}${props.goldDifference || '0'} GOLD`}
              </BuffBaronGoldDiff>) || null
            }
            <StyleGoldDiffFinal
              StyleGoldDiffFinal={props.theme.StyleGoldDiffFinal || ''}
              show={goldDiffState}
            >
              <div>
              {`BARON POWER PLAY \n`}
              </div>
              <span>
                {`${(goldDiffState && (goldDiffState>0))? '+':''}${goldDiffState || ''}`}
              </span>
              <strong>{`GOLD`}</strong>
            </StyleGoldDiffFinal>
          </StyleBaronBuffContainer>
        </Asset>
  </div>
);
}


BaronPowerPlay.propTypes = {
  theme: PropTypes.any,
  show: PropTypes.bool,
  isGamePaused: PropTypes.bool,
  color: PropTypes.string,
  baronAsset: PropTypes.string,
  goldDifference: PropTypes.number,
  timer: PropTypes.string,
  BuffBaronTimer: PropTypes.string,
};

export default BaronPowerPlay