// REACT
import React, { Component } from 'react';
import PropTypes from 'prop-types';
//ME
import Picker from '../../Picker';
import {tournamentRealms} from './../../../util/const'

class PickerBase extends Component {

  static propTypes = {
    onChange: PropTypes.func,
    MainDataID: PropTypes.string,
    defaultValue: PropTypes.string, // only used when MainDataID is null
    service: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })).isRequired,
    showSelectOption: PropTypes.bool,
    value: PropTypes.any,
  };

  state = {
    default: 'ESPORTSTMNT01',
  };

  setValue = (value) => {
    if (this.props.MainDataID) {
      window.MainData[this.props.MainDataID] = value;
    }
    this.setState({ standaloneValue: value }, this.onChange);
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    if ((this.props.value !== undefined) && (nextProps.value !== this.props.value)) {
      this.setState({ standaloneValue: nextProps.value });
    }
    if(nextProps.defaultValue !== this.state.default && tournamentRealms.includes(nextProps.defaultValue)) {
      // This is only for automatic setting of platform. e.g. ESPORTSTMNT01
      this.setValue(nextProps.defaultValue)
      this.setState({ default: nextProps.defaultValue });
    }
  }

  componentDidMount() {
    if (this.props.value !== undefined) {
      this.setState({ standaloneValue: this.props.value });
    } else if (this.props.MainDataID) {
      this.setState({ standaloneValue: window.MainData[this.props.MainDataID] });
    } else if (this.props.defaultValue) {
      this.setState({ standaloneValue: this.props.defaultValue, default: this.props.defaultValue });
    }
  };

  onChange = () => {
    if (this.props.onChange) {
      if (this.props.MainDataID) {
        this.props.onChange(window.MainData[this.props.MainDataID]);
      } else {
        this.props.onChange(this.state.standaloneValue);
      }
    }
  };

  render() {
    return (
      <Picker items={this.props.service}
        onUpdate={this.setValue}
        preselect={this.state.standaloneValue}
        showSelectOption={this.props.showSelectOption !== undefined? this.props.showSelectOption : true}
      />
    );
  };
}

export default PickerBase;
