// REACT
import React, { Component } from 'react';
import PropTypes from 'prop-types';
// internal
import {i18n} from '../../i18n';
import DataContainer from '../DataContainer';
import Picker from '../../Picker';
import Button from '../../Button';
import {getMatchupName, getRoundName} from '../../../util/const';
import {getInfoForPlayerInTimePeriod} from '../../../services/telopPlayerService';

class DataTelops extends Component {

    state = {
        caster: window.MainData.telops.caster,
        analyst: window.MainData.telops.analyst,
        interviewer: window.MainData.telops.interviewer,
        player: {
            name: '',
            team: {},
            position: '',
            kda: 0,
            kp: 0,
            dpm: 0,
        },
        layout: 'caster-analyst',
        playersList: [],
    };

    static propTypes = {
        schedule: PropTypes.object,
        teams: PropTypes.object,
    }

    componentDidMount() {
        this.selectablePlayers();
    }

    UNSAFE_componentWillReceiveProps() {
        this.selectablePlayers();
    }

    componentDidUpdate(prevProps) {
        if (this.props.schedule.round !== prevProps.schedule.round ||
            this.props.schedule.match !== prevProps.schedule.match ||
            this.props.schedule.game !== prevProps.schedule.game)
            this.selectablePlayers();
    }

    changeName = (name, role) => {
        window.MainData.telops[role] = name;
        this.setState({[role]: name});
    };

    selectablePlayers = () => {
        const schedule = this.props.schedule;
        const roundId = schedule.round;
        const matchId = schedule.match;
        const gameId = schedule.game;
        const match = schedule.rounds[roundId].matches[matchId];
        const selectedPlayers = [];
        if (schedule.rounds[roundId].matches[matchId].games) {
            const teams = schedule.rounds[roundId].matches[matchId].games[gameId].teams;
            const roster100 = teams[match.team100.tricode].roster;
            const roster200 = teams[match.team200.tricode].roster;
            Object.keys(roster100).map(position => {
                const player = roster100[position] ? {name: `${roster100[position].playerId} : ${position}`, value: roster100[position].playerId } : undefined;
                if (player) {
                    selectedPlayers.push(player);
                    return true;
                }
                return false;
            });
            Object.keys(roster200).map(position => {
                const player = roster200[position] ? {name: `${roster200[position].playerId} : ${position}`, value: roster200[position].playerId } : undefined;
                if (player) {
                    selectedPlayers.push(player);
                    return true;
                }
                return false;
            });
        }
        this.setState({playersList: selectedPlayers || []});
    }

    handleCasterEdit = e => this.changeName(e.target.value, 'caster');
    handleAnalystEdit = e => this.changeName(e.target.value, 'analyst');
    handleInterviewerEdit = e => this.changeName(e.target.value, 'interviewer');

    injectPlayerStats = () => {
        const summonerName = window.MainData.telops.player.name;
        const team = window.MainData.telops.player.team.tricode;
        const position = window.MainData.telops.player.position;
        const schedule = window.MainData.schedule;
        const season = schedule.season;
        const league = schedule.league;
        const split = schedule.split;
        const roundId = schedule.round;
        const round = getRoundName(roundId);
        const game = `Game ${schedule.game}`;
        const matchId = schedule.match;
        const matchup = getMatchupName([schedule.rounds[roundId].matches[matchId].team100.tricode, schedule.rounds[roundId].matches[matchId].team200.tricode]);

        getInfoForPlayerInTimePeriod(
            summonerName,
            team,
            position,
            matchup,
            season,
            league,
            split,
            round, // 2019 TODO: This probably isn't working right now.
            game,
        ).then(playerData => {
            if (!playerData || !playerData.stats) return;
            const kda = playerData.stats.kda;
            const kp = playerData.stats.killParticipation;
            const dpm = playerData.stats.damageToChampionsPerMin;

            window.MainData.telops.player.kda = kda;
            window.MainData.telops.player.kp = kp;
            window.MainData.telops.player.dpm = dpm;

            let player = this.state.player;
            player = {...this.state.player, kp, kda, dpm};

            this.setState({ player });
        });
    };

    setMatchPlayer = playerId => {
        //console.log('PLAYER', playerId, players, this.getPlayerFromData(playerId));
        const playerData = this.getPlayerFromData(playerId);

        //MSI stuff ?
        //if ((playerId && playerId.includes('-') && playerId.split('-')[1] === undefined || playerId.split('-')[1] === undefined) || !playerId) return;
        //const playerKey = playerId.includes('-') ? playerId.split('-')[0].toLowerCase() + playerId.split('-')[1].charAt(0).toUpperCase() + playerId.split('-')[1].slice(1).replace(' ', '').toLowerCase() : playerId;
        //const playerData = players[playerKey];

        if (!playerData) return;
        const player = {
            name: playerData.name,
            team: playerData.team,
            position: playerData.position,
            kda: 0,
            kp: 0,
            dpm: 0,
        }
        window.MainData.telops.player = player;
        this.setState({player});
    }

    updatePlayerStat = e => {
        const el = e.target;
        const value = el.value;
        const type = el.getAttribute('data-type');
        const player = window.MainData.telops.player;
        player[type] = value;
        window.MainData.telops.player = player;
        this.setState({player});
    };

    getPlayerFromData = playerName => {
        if (!playerName) return;
        const playerData = {};
        Object.keys(this.props.teams).forEach(team => {
            Object.keys(this.props.teams[team].roster).forEach(player => {
                if (player.toLowerCase() === playerName.toLowerCase()) {
                    playerData.name = player;
                    playerData.position = this.props.teams[team].roster[player].position;
                    playerData.team = team;
                }
            })
        });
        return playerData;
    }

    render () {
        return (
            <DataContainer componentSlug="telops" icon="fas fa-headset">
                <div className="data-panel__fieldset">
                    <label className="data-panel__fieldset__label">{i18n().general.caster}</label>
                    <div className="data-panel__fieldset__body">
                        <div className={`pill ${(this.state.layout.indexOf('caster') >= 0) && 'pill--highlighted'}`}>
                            <input className="input input--x-wide" onChange={this.handleCasterEdit} value={this.state.caster} />
                        </div>
                    </div>
                </div>
                <div className="data-panel__fieldset">
                    <label className="data-panel__fieldset__label">{i18n().general.analyst}</label>
                    <div className="data-panel__fieldset__body">
                        <div className={`pill ${(this.state.layout.indexOf('analyst') >= 0) && 'pill--highlighted'}`}>
                            <input className="input input--x-wide" onChange={this.handleAnalystEdit} value={this.state.analyst} />
                        </div>
                    </div>
                </div>
                <div className="data-panel__fieldset">
                    <label className="data-panel__fieldset__label">{i18n().general.interviewer}</label>
                    <div className="data-panel__fieldset__body">
                        <div className={`pill ${(this.state.layout.indexOf('interviewer') >= 0) && 'pill--highlighted'}`}>
                            <input className="input input--x-wide" onChange={this.handleInterviewerEdit} value={this.state.interviewer} />
                        </div>
                    </div>
                </div>
                <div className="data-panel__fieldset">
                    <label className="data-panel__fieldset__label">{i18n().general.player}</label>
                    <div className="data-panel__fieldset__body">
                        <div className={`pill ${(this.state.layout.indexOf('player') >= 0) && 'pill--highlighted'}`}>
                            <Picker items={this.state.playersList} onUpdate={this.setMatchPlayer} />
                            <Button action={this.injectPlayerStats} text={i18n().general.autoLoadPlayerStats} />
                        </div>
                        <div className={`pill ${(this.state.layout.indexOf('stats') >= 0) && 'pill--highlighted'}`}>
                            <span>DPM</span>
                            <input data-type="dpm" className="input input-short"
                                onChange={this.updatePlayerStat}
                                placeholder={i18n().general.DPM}
                                type="text"
                                value={this.state.player.dpm || 0}
                            />
                            <span>KP%</span>
                            <input data-type="kp" className="input input-short"
                                onChange={this.updatePlayerStat}
                                placeholder={i18n().general.KP}
                                type="text"
                                value={this.state.player.kp || 0}
                            />
                            <span>KDA</span>
                            <input data-type="kda" className="input input-short"
                                onChange={this.updatePlayerStat}
                                placeholder={i18n().general.KDA}
                                type="text"
                                value={this.state.player.kda || 0}
                            />
                        </div>
                    </div>
                </div>
            </DataContainer>
        );

    }

}

export default DataTelops
