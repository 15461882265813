import styled from 'styled-components';

export const StyleSelect = styled.select`
	border-radius: 5px;
	height: 30px;
	padding: 0 10px;
	font-size: 0.8em;
	margin: 3px;
	&:hover {
		cursor: pointer;
		-webkit-transform: scale(1.05, 1.05);
		transform: scale(1.05, 1.05);
		box-shadow: 0 0 5px rgb(0 0 0 / 30%);
	}
`