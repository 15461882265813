
import { dragons as dragonTypes } from '../util/const';

const processTimeline = (timelineData) => {
  const goldDiff = {
    teamOne: {},
    teamTwo: {},
    difference: {},
    dataSet: [],
  };

  const dragons = {
    teamOne: [],
    teamTwo: [],
    teamOneElderDragonsCount: 0,
    teamTwoElderDragonsCount: 0,
  };

  timelineData.frames.forEach((f, i) => {
    const participants = Object.values(f.participantFrames);

    goldDiff.teamOne[i] = participants.slice(0,5).reduce((sum, cur) => sum + cur.totalGold, 0);
    goldDiff.teamTwo[i] = participants.slice(5).reduce((sum, cur) => sum + cur.totalGold, 0);

    goldDiff.difference[i] = goldDiff.teamOne[i] - goldDiff.teamTwo[i];
    goldDiff.dataSet.push(goldDiff.difference[i] || 0);

    f.events.forEach(e => {
      if (e.type === 'ELITE_MONSTER_KILL' && e.monsterType === 'DRAGON') {
        const dragonType = dragonTypes[e.monsterSubType];
        if (dragonType) {
          const isTeamOne = e.killerId <= 5;
          const isElder = dragonType === dragonTypes.ELDER_DRAGON;

          if (isElder) {
            if (isTeamOne) dragons.teamOneElderDragonsCount += 1;
            else dragons.teamTwoElderDragonsCount += 1;
          }
          else {
            if (isTeamOne) dragons.teamOne.push(dragonType);
            else dragons.teamTwo.push(dragonType);
          }
        }
      }
    });
  });

  return {
    goldDiff,
    dragons,
  };
};

export {
  processTimeline,
};
