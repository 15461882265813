import React from 'react';
import styled from 'styled-components';
// import PropTypes from 'prop-types';

const StyleBlankContainer = styled.div`
    width: 1920px;
    height: 1080px;
    background-color: transparent;
`;

function BlankScreen() {
    return (
        <StyleBlankContainer/>
    )
}


BlankScreen.propTypes = {

}

export default BlankScreen

