// REACT
import React, { useEffect, useState } from 'react';
// libraries
import { delay, get } from 'lodash';
import moment from 'moment';
// internal
import Button from '../../Button';
import validateDataTimer from './validate';
import {getCountdownUntil} from '../../../util/countDown';
import {i18n} from '../../i18n';
import DataContainer from '../DataContainer';
import styled from 'styled-components';


const StyledTimerWrapper = styled.div`
display: flex;
flex-wrap: wrap;
`
const DataTimer = () => {
    const [targetTime, setTargetTime] = useState(window.MainData.timer.targetTime || 0);
    const [countdownTime, setCountdownTime] = useState(window.MainData.timer.countdownTime || 0);
    const [valid, setValid] = useState(false);
    const [inputTime, setInputTime] = useState(undefined);
    const [inputDate, setInputDate] = useState(undefined);
    const [inputCountdown, setInputCountdown] = useState(undefined);
    const [countdownMode, setCountdownMode] = useState('to'); /* 'to' (absolute time) or 'for' (period of time) */
    const [timerText, setTimerText] = useState('');
    const [timer2Text, setTimer2Text] = useState('');
    const [timerOverwriteText, setTimerOverwriteText] = useState('');

    const [sidebarMode, setSidebarMode] = useState(window.MainData.components.view.ScheduleInfoSideBar ? window.MainData.scheduleInfoSideBar.mode : null);

    if (window.MainData.components.view.ScheduleInfoSideBar) {
        useEffect(() => {
            setSidebarMode(window.MainData.scheduleInfoSideBar.mode)
        }, [window.MainData.scheduleInfoSideBar.mode])
    }

    useEffect(() => {
        if (validateDataTimer(targetTime)) {
            setValid(true);
            updateTime();
        } else {
            setValid(false);
        }
    }, [targetTime])

    useEffect(() => {
        // Setting the date today for convince and to stop the warning.
        const today = moment(moment()).format("YYYY-MM-DD")
        handleChangeTargetDate({
            target: {
                value: today,
            },
        })
    }, [])

    const settingTargetTime = async time => {
        const targetTime = time;
        window.MainData.timer.targetTime = targetTime;
        setTargetTime(targetTime);
    }

    const setTextTimer = (whichTimer, text) => {
        window.MainData.timer[whichTimer] = text;
    }

    const updateTime = () => {
        if (targetTime === 0) return;
        const countdownTime = getCountdownUntil(targetTime);
        setCountdownTime(countdownTime);
        delay(updateTime, 1000);
    }

    const handleInMinutesSelect = (e) => {
        const timePeriod = e.target.value;
        setInputCountdown(timePeriod);
    }

    const handleChangeTargetTime = e => {
        const time = e.target.value;
        setInputTime(time);
    }

    const handleChangeTargetDate = e => {
        const date = e.target.value;
        setInputDate(date);
    }

        return (
            <DataContainer componentSlug="timerData" icon="fas fa-stopwatch">
                {/* Curent Settings */}

                <StyledTimerWrapper>
                <div className="data-panel__fieldset">
                    <label className="data-panel__fieldset__label">{i18n().general.currentSetting}</label>
                    <div className="data-panel__fieldset__body">
                        <div className="pill">
                            <span>{targetTime || 'dd/mm/yyyyThh:mm'}</span>
                            <span>{countdownTime || '--:--'}</span>
                            <span>{valid ? 'VALID' : 'NOT VALID'}</span>
                        </div>
                    </div>
                </div>
                {/* Countdown To  */}
                <div className="data-panel__fieldset" style={{display: 'flex', flexWrap: 'wrap'}} >
                    <label className="data-panel__fieldset__label" >
                        <input
                            name=""
                            type="radio"
                            checked={countdownMode === 'to'}
                            onChange={() => {(countdownMode !== 'to') && setCountdownMode('to') }}
                        />
                        {i18n().general.timerCountdownTo}
                    </label>

                    <div className="data-panel__fieldset__body">
                        <div className="pill" >
                            <Button
                                disabled={(countdownMode !== 'to')}
                                action={() => {
                                    setInputTime(moment().format('HH:mm'));
                                    setInputDate(moment().format('YYYY-MM-DD'))
                                }}
                                text="NOW"
                            />
                            <input
                                className={`input input--x-wide ${(countdownMode !== 'to') && 'input--disabled'} input--small-text`} type="date"
                                onChange={handleChangeTargetDate}
                                value={inputDate ? inputDate : 'yyyy-MM-dd'}
                            />
                            <input
                                className={`input input--wide ${(countdownMode !== 'to') && 'input--disabled'} input--small-text`} type="time"
                                onChange={handleChangeTargetTime}
                                value={inputTime ? inputTime : ''}
                            />
                            <Button
                                disabled={countdownMode !== 'to'}
                                action={() => {
                                    settingTargetTime(`${inputDate}T${inputTime}`)
                                }}
                                text="submit"
                            />
                        </div>
                    </div>
                </div>
                {/* Countdown For */}
                <div className="data-panel__fieldset">
                    <label className="data-panel__fieldset__label" >
                        <input
                            name=""
                            type="radio"
                            checked={countdownMode === 'for'}
                            onChange={() => {(countdownMode !== 'for') && setCountdownMode('for') }}
                        />
                        {i18n().general.timerCountdownFor}
                    </label>

                    <div className="data-panel__fieldset__body">
                        <div className="pill">
                            <input
                                className={`input input--wide ${(countdownMode !== 'for' ) && 'input--disabled'} input--small-text`}
                                type="number"
                                placeholder="-- (in min)"
                                onChange={handleInMinutesSelect}
                                value={inputCountdown ? inputCountdown : ''}
                            />
                            <Button
                                disabled={countdownMode !== 'for'}
                                action={() => {
                                    settingTargetTime(moment().add(inputCountdown, 'm').format('YYYY-MM-DDTHH:mm:ss'))
                                }}
                                text="submit"
                            />
                        </div>
                    </div>

                </div>
                {/* Timers Text  */}
                <div className="data-panel__fieldset">
                    <label className="data-panel__fieldset__label" >{i18n().general.timerText}</label>
                    <div className="data-panel__fieldset__body">
                        {(get(window, ['MainData', 'components', 'view', 'ScheduleInfoSideBar'], undefined) && sidebarMode === 'old') || !window.MainData.components.view.ScheduleInfoSideBar &&
                            <div className="pill">
                                <span>{i18n().general.timer}</span>
                                <input className="input input--xx-wide input--small-text" type="text" onChange={(e)=>{setTimerText(e.target.value)}} value={timerText} />
                                <Button action={() => setTextTimer('timerText',timerText || null)} text="submit" />
                            </div>
                        }
                        {get(window, ['MainData', 'components', 'view', 'Timer2'], undefined) &&
                            <div className="pill">
                                <span>{i18n().general.timer2}</span>
                                <input className="input input--xx-wide input--small-text" type="text" onChange={(e) => { setTimer2Text(e.target.value) }} value={timer2Text} />
                                <Button action={() => setTextTimer('timer2Text', timer2Text || null)} text="submit" />
                            </div>
                        }
                        <div className="pill">
                            <span>{'RESULT'}</span>
                            <input className="input input--xx-wide input--small-text" type="text" onChange={(e)=>{setTimerOverwriteText(e.target.value)}} value={timerOverwriteText} />
                            <Button action={() => setTextTimer('timerOverwriteText',timerOverwriteText || null)} text="submit" />
                        </div>
                    </div>
                </div>
                </StyledTimerWrapper>
            </DataContainer>
        );

}

export default DataTimer
