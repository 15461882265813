import http from 'http';

export const doHttpGet = async (url, options, timeoutMs) =>
    await new Promise((resolve, reject) => {
        let rawData = '';
        console.log('OPTIONS', options);

        try {
            let timedOut = true;
            const req = http.get(
                url || options,
                res => {
                    timedOut = false;
                    if (res.statusCode >= 200 && res.statusCode < 300) {
                        res.setEncoding('utf8');
                        res.on('data', chunk => {
                            rawData += chunk;
                        });
                        res.on('end', () => {
                            let data;
                            try {
                                data = JSON.parse(rawData);
                            } catch (err) {
                                data = rawData;
                            }
                            resolve(data || rawData);
                        });
                    } else {
                        reject(
                            `error calling http get request for ${url || (options && options.host)} Received status code: ${
                            res.statusCode
                            }`,
                        );
                    }
                },
            );
            if (timeoutMs) {
                // None of the built-in timeout functions/callbacks are working for the 'req' so making my own - stevenm 2020-02-03
                setTimeout(() => {
                    if (timedOut) {
                        req.abort();
                        reject(`error calling http get request for ${url || (options && options.host)}. Timed Out.`);
                    }
                }, timeoutMs);
            }
            req.end();
        } catch (error) {
            reject(`error calling http get request for ${url || (options && options.host)}.`);
        }

    });
