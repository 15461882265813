"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StyleInfoBoxBody = exports.StyleImgContainer = void 0;

var _styledComponents = _interopRequireDefault(require("styled-components"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var StyleInfoBoxBody = _styledComponents.default.div.withConfig({
  displayName: "styles__StyleInfoBoxBody",
  componentId: "sc-4chti-0"
})(["color:white;display:grid;grid-template-columns:repeat(2,1fr);align-items:center;height:210px;position:absolute;width:1340px;overflow:visible;top:70px;bottom:0;"]);

exports.StyleInfoBoxBody = StyleInfoBoxBody;

var StyleImgContainer = _styledComponents.default.div.withConfig({
  displayName: "styles__StyleImgContainer",
  componentId: "sc-4chti-1"
})(["position:absolute;top:0;right:0;left:0;img{width:100%;}"]);

exports.StyleImgContainer = StyleImgContainer;