// REACT
import React, { Component } from 'react';
import PropTypes from 'prop-types';
// import styled from 'styled-components';
// ME
import {i18n} from '../i18n';

class Picker extends Component {

  static defaultProps = {
    showSelectOption: true,
  }

  static propTypes = {
    index: PropTypes.any,
    items: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    onUpdate: PropTypes.func,
    preselect: PropTypes.string,
    showSelectOption: PropTypes.bool,
    error: PropTypes.bool,
    styles: PropTypes.object,
  };

  select = null;
  render() {
    return (
      this.props.items.length > 0 ?
        <select style={this.props.styles || {}} className={this.props.error ? 'error' : ''} ref={(select) => { this.select = select; }} onChange={() => typeof this.props.index === 'number' ? this.props.onUpdate(this.props.index, this.select.value) : this.props.onUpdate(this.select.value, this.props.index)} value={this.props.preselect}>
        {this.props.showSelectOption && <option value="">{i18n().general.select}</option>}
        {
            this.props.items.map((item, i) => <option key={i} value={item.value} >
              {item.name}
            </option>)
        }
      </select>
      :
      <select styles={this.props.styles || {}} className='setup-menu__box__select--disabled'>
      </select>
    );
  }
}

export default Picker
