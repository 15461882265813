// REACT
import React, { Component } from 'react';
import PropTypes from 'prop-types';
// ME
import { ThemeContext } from '../View/themes';
import { delay } from 'lodash';
import ScreenTitle from '../../storybook-built/dist/components/Games/LJL/ScreenTitle';
import PlayerOfTheMatchComponent from '../../storybook-built/dist/components/Games/LJL/PlayerOfTheMatch';
// import { preloadImages } from '../../util/loadImageUrl';

class MatchPlayer extends Component {

    static propTypes = {
        active: PropTypes.bool,
        // action: PropTypes.string,
        data: PropTypes.any,
        statusCallback: PropTypes.func,
    }

    bound = false;

    state = {
        status: 'closed',
        displayTitle: undefined,
        animation: 0,
    };

    componentDidMount() {
        this.openScene();
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.active) {
            if (this.state.status === 'closed') {
                this.setState({ status: 'open' });
                this.openScene();
            }
            this.runUpdate(nextProps);
        } else {
            if (this.state.status !== 'closed') {
                this.setState({ status: 'closed' });
                this.clearPage();
            }
        }
    }

    runUpdate = () => {
    }

    openScene = async () => {
        await this.beginAnimation();
        this.props.statusCallback('matchPlayer', 'opening');
    }

    beginAnimation = () => {
        this.props.statusCallback('matchPlayer', 'opening');
        this.setState({displayTitle: true});
        delay(() => this.setState({animation: '1'}), 500);
        delay(() => this.setState({animation: '2'}), 1000); // header title
        delay(() => this.setState({animation: '3'}), 1500); // header round
        delay(() => this.setState({animation: 'open'}), 1800);
        delay(() => this.props.statusCallback('matchPlayer', 'open'), 1900);
    };

    clearPage = () => {
        this.props.statusCallback('matchPlayer', 'closing');
        this.setState({animation: '3'});
        delay(() => this.setState({animation: '2'}), 500);
        delay(() => this.setState({animation: '1'}), 1000);
        delay(() => this.setState({animation: '0'}), 1500);
        delay(() => this.setState({displayTitle: false}), 1800);
        delay(() => this.props.statusCallback('matchPlayer', 'closed'), 2400);
    };

    render() {
        const players = this.props.data.players;
        let theme = (this.context && this.context.screens && this.context.screens.matchPlayer) || {};
        return (
            <div className={`view-match-player view-match-player--animation-${this.state.animation} container`}>
                <ScreenTitle
                    title={'WEEK MVP NOMINATION'}
                    subtitle={null}
                    logo={null}
                    showTitle={this.state.displayTitle}
                    colors={{
                        main: '#dba10332',
                        sub: '#1fe4cb32',
                    }}
                    titleStyle={null}
                />

                <PlayerOfTheMatchComponent
                    animation={this.state.animation}
                    color={{
                        main: this.props.data.teamMainColor,
                        sub: this.props.data.teamSubColor,
                    }}
                    players={players}
                    theme = {theme || {}}
                />
            </div>
        );
    }

}

MatchPlayer.contextType = ThemeContext;

export default MatchPlayer
