import styled from 'styled-components';

export const StyleGoldItem = styled.div`
	align-items: center;
	color: white;
	display: flex;
	height: 34px;
	justify-content: space-between;
	width: 100%;
	flex-direction: ${props => props.side === 'left' ? 'row':'row-reverse'};
`;

export const StyleChampionContainer = styled.div`
	background-size: contain;
	flex: 1;
	height: 34px;
	max-width: 34px;
	background-image: url(${props => props.champ});
`;

export const StyleScore = styled.div`
	align-items: center;
	display: flex;
	flex: 1;
	font-size: 20px;
	height: 34px;
	justify-content: center;
	margin: 0 10px;
	max-width: ${props => props.scoreExtraWide? '125px':'90px'};
	color: '#ffffff';
	${props => props.StyleScore ? props.StyleScore : ''};
`;

export const StyleBarContainer = styled.div`
	flex: 1;
	height: 30px; 
`;

export const StyleGoldBar = styled.div`
	flex: 1;
	height: 30px;
	height: 100%; 
	width: 100%;
	background-color: ${props => `${props.color}99`};
	transform-origin: ${props => (props.side === 'left')? 'left':'right'} center;
	transform: scaleX(${props => props.scaling || 0});
`;

export const StyleInstantGold = styled.div`
	height: 30px;
	height: 100%;
	transform-origin: left center;
	width: 100%;
	background-color: ${props => props.color};
	transform-origin: ${props => (props.side === 'left')? 'right':'left'} center;
	transform: scaleX(${props => props.scaling || 0});
`;
