// REACT
import React, { useState, useEffect } from 'react';
import { get, cloneDeep } from 'lodash';
// internal
import { checkLang } from '../../i18n';
import {
  StyleMythicItemContainer,
} from './styles';

import { firebaseRealtimeDbSetValue } from '../../../util/firebase';
import { getCreatableStyles } from '../DataMatch/DataMatchLineup';
// import { itemsList } from '../../../services/itemService';
import { InGameCustomTips } from '../DataInGameTip/tips';

import PositionSelector from './PositionSelector';
import CustomItem from './CustomItem';
import Button from '../../Button';
import Select from 'react-select';
import DataContainer from '../DataContainer';

const BG_SIDE_POPOUT_LEFT = 'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/bg_side_popout_left.png';
const BG_SIDE_POPOUT_RIGHT = 'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/bg_side_popout_right.png';

const DataCustomInGameSide = () => {

  const [ mythicItemsState, setMythicItemsState ] = useState([]);

  const [ currentSideSelectionState, setCurrentSideSelectionState ] = useState('team100');
  const [ currentPositionSelectionState, setCurrentPositionSelectionState ] = useState('');
  const [ currentItemSelectionState, setCurrentItemSelectionState ] = useState({label: ' - ', value: ''})
  const [ currentTextSelectionState, setCurrentTextSelectionState ] = useState('');

  const [ customizedItemsState, setCustomizedItemsState ] = useState([]);

  const handlePlayerSelection = (teamId, position) => {
    setCurrentSideSelectionState(teamId);
    setCurrentPositionSelectionState(position);
  }

  useEffect(() => {
    setMythicItemsState(InGameCustomTips.map((item, index) => ({
      value: index,
      label: checkLang() === 'en'? item.labelEn : item.labelJa,
    })))
    const clearRemoteCustomItems = async () => {
      await firebaseRealtimeDbSetValue(`customInGameTips`, {});
    }
    clearRemoteCustomItems();
  }, [])

  const deleteItem = async (itemIndex) => {
    let newCustomTips = cloneDeep(customizedItemsState);
    newCustomTips.splice(itemIndex, 1);
    setCustomizedItemsState(newCustomTips);
    const newRemoteState = {team100 : {}, team200: {}};
    newCustomTips.forEach(tip => {
      newRemoteState[tip.side][tip.pos] = [...get(newRemoteState, `[${tip.side}][${tip.pos}]`,[]), tip]
    })
    await firebaseRealtimeDbSetValue(`customInGameTips`, newRemoteState);
  }


  const submit = async (url, side, pos, text) => {
    const newCustomTips = {
      team100: {...cloneDeep(get(window.MainData, 'customInGameTip.team100', {}))},
      team200: {...cloneDeep(get(window.MainData, 'customInGameTip.team200', {}))},
    }

    newCustomTips[side][pos] = [
      ...get(newCustomTips, `${side}.${pos}`,[]),
      {
        url,
        side,
        pos,
        text,
      }];
      window.MainData.customInGameTip = newCustomTips;
      await firebaseRealtimeDbSetValue(`customInGameTips`, newCustomTips);
      setCustomizedItemsState(currentState => {
        const newState = currentState;
        newState.push({
          url,
          side,
          pos,
          text,
        })
        return newState;
      });
      setCurrentTextSelectionState('team100');
      setCurrentPositionSelectionState('');
      setCurrentItemSelectionState({label: ' - ', value: ''});
      setCurrentTextSelectionState('');
  }

  const checkTextInput = (previousText, newText) => { // need to check that text fit in the in-game tips container
    const lines = newText.split('\n');
    if(lines.length >= 2){
      if((lines[0].length > 18 )|| (lines[1].length > 18) || (lines.length >2)){
        return previousText;
      }
    }
    if(newText.length > 36){
      return previousText;
    }
    return newText;
  }


  return (
    <DataContainer componentSlug="dataInGameTipsCustom" icon="fal fa-info" style={{ overflow: 'visible' }}>
      <div className="data-panel__fieldset" style={{ paddingTop: '10px', paddingBottom: '0' }}>
        <StyleMythicItemContainer>
          {customizedItemsState.map((item, index)=>
            <CustomItem
              key={`customItem${index}-${item.side}-${item.pos}-${item.text}`}
              url={item.url}
              side={item.side}
              text={item.text}
              item={item.pos}
              onClose={async()=>{
                await deleteItem(index);
              }}
              isDeletable={true}
              pos={item.pos}
            />)
          }
          <PositionSelector
            sideSelection={currentSideSelectionState}
            positionSelection={currentPositionSelectionState}
            onSelectPosition={(side, pos) => {handlePlayerSelection(side, pos)}}
          />
          <Select
            styles={getCreatableStyles('80%', {option: {fontSize: '11px'}})}
            options={[...mythicItemsState, {value: '', label: ' - '}]}
            value={currentItemSelectionState}
            onChange={(e)=>{ setCurrentItemSelectionState(e) }}
            defaultValue={{value: '', label: ' - '}}
            isSearchable
          />
        <textarea
          rows={2}
          cols={18}
          type={'text'}
          onChange={(e) => {
            setCurrentTextSelectionState(checkTextInput(currentTextSelectionState, e.target.value));
          }}
          maxLength={36}
          value={currentTextSelectionState}
        />
        <CustomItem
          url={get(InGameCustomTips, `[${currentItemSelectionState.value}][${currentSideSelectionState}]`, currentSideSelectionState === 'team100'? BG_SIDE_POPOUT_LEFT: BG_SIDE_POPOUT_RIGHT)}
          side={currentSideSelectionState}
          text={currentTextSelectionState}
        />
        <Button
          action={() => {
            submit(
              get(InGameCustomTips, `[${currentItemSelectionState.value}][${currentSideSelectionState}]`),
              currentSideSelectionState,
              currentPositionSelectionState,
              currentTextSelectionState)
        }}
          text={'submit'}
          disabled={
            !(currentSideSelectionState &&
            currentPositionSelectionState &&
            currentTextSelectionState &&
            get(InGameCustomTips, `[${currentItemSelectionState.value}][${currentSideSelectionState}]`))
          }
        />
        </StyleMythicItemContainer>
      </div>
    </DataContainer>
  );
};

export default DataCustomInGameSide;
