// REACT
import React, { Component } from 'react';
import PropTypes from 'prop-types';
// INTERNAL
import { updateScreenStatus } from '../../../util/controllerUtils';
import { subscribe } from '../../../services/api';
import ControlContainer from '../../Controller/ControlContainer';
import Button from '../../Button';
import validateDataTimer from '../../Data/DataTimer/validate';
import {i18n} from '../../i18n';

class Timer2Control extends Component {

    static propTypes = {
        cgpc: PropTypes.string,
        display: PropTypes.bool,
        screen1: PropTypes.bool,
      };

    state = {
        status: 'ready',
    }

    componentDidMount() {
        this.runSubscription();
        if (validateDataTimer()) {
            this.setState({status: 'ready'});
        } else {
            this.setState({status: 'invalid'});
        }
    }

    runSubscription = () => {
        subscribe('control-timer2', null, data => {
            if (data.target === 'control-timer2') {
                this.setState({status: data.status});
            }
        }, this.props.cgpc);
    };

    onPlay = () => {
        if (validateDataTimer()) {
            const sendData = {
                timer: {
                    targetTime: window.MainData.timer.targetTime,
                    timerText: window.MainData.timer.timer2Text,
                },
            };
            // send('event', { target: 'view', action: 'open', screen: 'timer', data: sendData });
            updateScreenStatus('timer2', 'open', sendData, null, this.props.cgpc);
        } else {
            this.setState({status: 'invalid'});
        }
    }

    onClose = () => {
        updateScreenStatus('timer2', 'close', null, null, this.props.cgpc);
    }

    render() {
        return (
            <ControlContainer style={{display: this.props.display ? 'none': 'block'}} className="control--timer" status={this.state.status}>
                <div className="control__panel">
                    <h2 className="control__title">
                        <i className="fas fa-stopwatch"></i> {i18n().general.timer2}
                    </h2>
                    <Button controllerSide={!this.props.screen1} action={this.onPlay} text={i18n().general.open} icon="fas fa-play-circle" disabled={
                        (this.state.status === 'open' || this.state.status === 'opening') && true
                        }
                    />
                    <Button controllerSide={!this.props.screen1} action={this.onClose} text={i18n().general.close} icon="fas fa-stop-circle" disabled={
                        !(this.state.status === 'open' || this.state.status === 'opening') && true
                        }
                    />
                </div>
            </ControlContainer>
        )
    }
}

export default Timer2Control
