// REACT
import React, { Component } from 'react';
import PropTypes from 'prop-types';

// INTERNAL
import { updateScreenStatus } from '../../../util/controllerUtils';
import { subscribe } from '../../../services/api';
import ControlContainer from '../../Controller/ControlContainer';
import Button from '../../Button';
import validateDataTimer from '../../Data/DataTimer/validate';
import {i18n} from '../../i18n';

class TimerControl extends Component {

    state = {
        status: 'ready',
    }

    static propTypes = {
        cgpc: PropTypes.string,
        display: PropTypes.bool,
        screen1: PropTypes.bool,
      };

    componentDidMount() {
        this.runSubscription();
        if (validateDataTimer()) {
            this.setState({status: 'ready'});
        } else {
            this.setState({status: 'invalid'});
        }
    }

    runSubscription = () => {
        subscribe('control-timer', null, data => {
            if (data.target === 'control-timer') {
                this.setState({status: data.status});
            }
        }, this.props.cgpc);
    };

    onPlay = () => {
        if (validateDataTimer() || window.MainData.timer.timerOverwriteText?.length > 0) {
            const sendData = { timer: window.MainData.timer };
            // send('event', { target: 'view', action: 'open', screen: 'timer', data: sendData });
            updateScreenStatus('timer', 'open', sendData, null, this.props.cgpc);
        } else {
            this.setState({status: 'invalid'});
        }
    }

    onClose = () => {
        // send('event', { target: 'view', action: 'close', screen: 'timer' });
        updateScreenStatus('timer', 'close', null, null, this.props.cgpc);
    }

    render() {
        return (
            <ControlContainer style={{display: this.props.display ? 'none': 'block'}} className="control--timer" status={this.state.status}>
                <div className="control__panel">
                    <h2 className="control__title">
                        <i className="fas fa-stopwatch"></i> {i18n().general.timer}
                    </h2>
                    <Button controllerSide={!this.props.screen1} action={this.onPlay} text={i18n().general.open} icon="fas fa-play-circle" disabled={
                        (this.state.status === 'open' || this.state.status === 'opening') && true
                        }
                    />
                    <Button controllerSide={!this.props.screen1} action={this.onClose} text={i18n().general.close} icon="fas fa-stop-circle" disabled={
                        !(this.state.status === 'open' || this.state.status === 'opening') && true
                        }
                    />
                </div>
            </ControlContainer>
        )
    }
}

export default TimerControl
