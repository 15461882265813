import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import { StyleMainContainer, StyleVideo } from './styles';
function PopupVideo(props) {
    const [playState, setPlayState] = useState(false);

    useEffect(() => {
        if(!props.show && !window.MainData.canGo) {
            setPlayState(false);
            const timer1 = setTimeout(function () {
                window.MainData.canGo = true;
                props.onVideoEnded();
                console.log("Timeout finished");
            }, 15000)
            return () => clearTimeout(timer1);
        }
        else if (props.show && window.MainData.canGo) {
            setPlayState(true);
            window.MainData.canGo = false;
        } else if (!props.show && window.MainData.canGo) {
            // delay the stop of the video until clearing animation is over
            setTimeout(() => {setPlayState(false);}, 600);
        }
    }, [props.show]);

    return (
        <StyleMainContainer show={playState}>
            <StyleVideo
                key={`video-status-${playState? 'play': 'stop'}`}
                autoPlay
                muted
                loop={false}
                onEnded={(()=>{
                    console.log("Video finished");
                    props.onVideoEnded();
                })}
            >
                <source src={props.src || 'https://dekki-production.s3-ap-northeast-1.amazonaws.com/assets/broadcast/games/league-of-legends/game-overlay/nissin_baron_video.webm'} />
            </StyleVideo>
        </StyleMainContainer>
    )
}

PopupVideo.propTypes = {
    show: PropTypes.bool,
    src: PropTypes.string,
    onVideoEnded: PropTypes.func,
}

export default PopupVideo

