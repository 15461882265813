"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StyleVisionControlContainer = exports.StyleTeamLogo = exports.StyleRightContainer = exports.StylePercentage = exports.StyleLinesContainer = exports.StyleLineNumber = exports.StyleLineContainer = exports.StyleLine = exports.StyleLeftContainer = exports.StyleImg = exports.StyleData = exports.StyleCenterContainer = exports.StyleBarContainer = exports.StyleBar = void 0;

var _styledComponents = _interopRequireDefault(require("styled-components"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var StyleVisionControlContainer = _styledComponents.default.div.withConfig({
  displayName: "styles__StyleVisionControlContainer",
  componentId: "sc-1wkdq1h-0"
})(["display:grid;grid-template-columns:373px 1fr 373px;width:1340px;height:210px;font-family:\"futura-pt\",sans-serif;"]);

exports.StyleVisionControlContainer = StyleVisionControlContainer;

var StyleLeftContainer = _styledComponents.default.div.withConfig({
  displayName: "styles__StyleLeftContainer",
  componentId: "sc-1wkdq1h-1"
})(["clip-path:polygon(0 0,86% 0,100% 100%,0% 100%);"]);

exports.StyleLeftContainer = StyleLeftContainer;

var StyleRightContainer = _styledComponents.default.div.withConfig({
  displayName: "styles__StyleRightContainer",
  componentId: "sc-1wkdq1h-2"
})(["clip-path:polygon(0 0,100% 0,100% 100%,14% 100%);"]);

exports.StyleRightContainer = StyleRightContainer;

var StyleCenterContainer = _styledComponents.default.div.withConfig({
  displayName: "styles__StyleCenterContainer",
  componentId: "sc-1wkdq1h-3"
})(["position:relative;display:grid;grid-template-columns:72px 1fr 50px 1fr 72px;z-index:1;"]);

exports.StyleCenterContainer = StyleCenterContainer;

var StyleLinesContainer = _styledComponents.default.div.withConfig({
  displayName: "styles__StyleLinesContainer",
  componentId: "sc-1wkdq1h-4"
})(["position:absolute;bottom:0;width:100%;height:100%;"]);

exports.StyleLinesContainer = StyleLinesContainer;

var StyleLineContainer = _styledComponents.default.div.withConfig({
  displayName: "styles__StyleLineContainer",
  componentId: "sc-1wkdq1h-5"
})(["position:absolute;right:", ";left:", ";bottom:", ";width:100%;display:flex;"], function (props) {
  return props.right && "".concat(props.right, "px");
}, function (props) {
  return props.left && "".concat(props.left, "px");
}, function (props) {
  return props.percentage && "".concat(props.percentage, "%");
});

exports.StyleLineContainer = StyleLineContainer;

var StyleLine = _styledComponents.default.span.withConfig({
  displayName: "styles__StyleLine",
  componentId: "sc-1wkdq1h-6"
})(["border-bottom:1px ", " white;width:529px;"], function (props) {
  return props.borderType && props.borderType;
});

exports.StyleLine = StyleLine;

var StyleLineNumber = _styledComponents.default.p.withConfig({
  displayName: "styles__StyleLineNumber",
  componentId: "sc-1wkdq1h-7"
})(["color:white;font-size:18px;line-height:12px;margin-left:", ";margin-right:", ";"], function (props) {
  return props.position !== 'left' && '8px';
}, function (props) {
  return props.position !== 'right' && '8px';
});

exports.StyleLineNumber = StyleLineNumber;

var StyleTeamLogo = _styledComponents.default.div.withConfig({
  displayName: "styles__StyleTeamLogo",
  componentId: "sc-1wkdq1h-8"
})(["width:375px;height:100%;overflow:hidden;position:relative;background:black;"]);

exports.StyleTeamLogo = StyleTeamLogo;

var StyleData = _styledComponents.default.div.withConfig({
  displayName: "styles__StyleData",
  componentId: "sc-1wkdq1h-9"
})(["position:relative;width:200px;"]);

exports.StyleData = StyleData;

var StyleBarContainer = _styledComponents.default.div.withConfig({
  displayName: "styles__StyleBarContainer",
  componentId: "sc-1wkdq1h-10"
})(["position:relative;height:100%;"]);

exports.StyleBarContainer = StyleBarContainer;

var StyleBar = _styledComponents.default.div.withConfig({
  displayName: "styles__StyleBar",
  componentId: "sc-1wkdq1h-11"
})(["position:absolute;bottom:0;width:100%;height:", ";background:", ";transition:height .2s ease;"], function (props) {
  return props.percentage && "".concat(props.percentage, "%");
}, function (props) {
  return props.barColor && "linear-gradient(0deg, ".concat(props.barColor.sub, " 0%, ").concat(props.barColor.main, " 100%)");
});

exports.StyleBar = StyleBar;

var StylePercentage = _styledComponents.default.p.withConfig({
  displayName: "styles__StylePercentage",
  componentId: "sc-1wkdq1h-12"
})(["height:35px;position:absolute;z-index:1;top:10px;transform:translate(50%,0);right:50%;color:white;font-weight:500;font-size:30px;text-align:center;letter-spacing:2px;"]);

exports.StylePercentage = StylePercentage;

var StyleImg = _styledComponents.default.img.withConfig({
  displayName: "styles__StyleImg",
  componentId: "sc-1wkdq1h-13"
})(["position:absolute;max-width:110%;object-position:", ";"], function (props) {
  return props.position === 'left' ? '-37px -7px' : '0px -7px';
});

exports.StyleImg = StyleImg;