// require('es6-promise').polyfill();
require('isomorphic-fetch');

const localURL = 'http://localhost:8082/stats';
// const devURL = 'https://ljl-broadcast.dev.jp.leagueoflegends.com/stats'; => Randomly works so switched to prod api for dev environment
// const devURL = 'https://ljl-broadcast.jp.leagueoflegends.com/stats';
const devURL = 'https://ljl-analytics-dev.jp.leagueoflegends.com/api/stats';
const prodURL = 'https://ljl-analytics.jp.leagueoflegends.com/api/stats';
// const prodURL = 'https://ljl-broadcast.jp.leagueoflegends.com/stats';
// https://ljl-analytics.jp.leagueoflegends.com
const devKey = 'e993561a-ee25-4151-8ae2-56b06cbf2a3b';
const prodKey = '8b40eadb-a972-4bae-bc69-17239e110e25';

// If you happen to want to add new queries for data, there's a new endpoint /stats/filter that the Analyst App uses that I find to be more straightforward than the endpoints you're using now. It maps one-to-one with what you'd see on the screen in terms of parameters and would give the same results.

// https://ljl-analytics-dev.jp.leagueoflegends.com/api/stats/filter?season=2022&league=ljl&split=spring&round=ALLROUNDS&matchup=ALLMATCHES&game=ALLGAMES&summonerName=yaharong&position=ALLPOSITIONS&team=ALLTEAMS&champion=Zoe&limit=500
// You can view the network requests on the Analyst App. Here's an example
// /stats/filter?summonerName=&champion=&position=JUNGLE&team=&season=2022&league=ljl&split=spring&round=&matchup=&game=&limit=500
// Generally speaking, you'll probably prefer to have aggregated data, so you'll want to specify the ALL* filters:
// /stats/filter?summonerName=&champion=&position=JUNGLE&team=&season=2022&league=ljl&split=spring&round=ALLROUNDS&matchup=ALLMATCHES&game=ALLGAMES&limit=500

// Michael Bailey (Riot)  34 minutes ago
// This is the list of consts for ALL*
// export const ALL_PLAYERS = 'ALLPLAYERS';
// export const ALL_CHAMPIONS = 'ALLCHAMPIONS';
// export const ALL_POSITIONS = 'ALLPOSITIONS';
// export const ALL_TEAMS = 'ALLTEAMS';
// export const ALL_SEASONS = 'ALLSEASONS';
// export const ALL_LEAGUES = 'ALLLEAGUES';
// export const ALL_SPLITS = 'ALLSPLITS';
// export const ALL_ROUNDS = 'ALLROUNDS';
// export const ALL_MATCHES = 'ALLMATCHES';
// export const ALL_GAMES = 'ALLGAMES';
// If you don't specify anything, it will return both aggregated and non-aggregated versions
// This was lies. It returns only non-aggregates if you don't specify the ALL* for an aggregation. (edited)

// Michael Bailey (Riot)  33 minutes ago
// The full list of query params is:
// season
// league
// split
// round
// matchup
// game
// team
// summonerName
// position
// champion
// limit (required, min: 1, max: 1000)
// At least one query parameter must be present, but any values not present will assume you want all non-aggregated values for that parameter. (edited)

// Michael Bailey (Riot)  17 minutes ago
// I believe this is the full set of types for playerStats[].stats

// const reply = await fetch(`${baseURL}?season=2022&league=ljl&split=spring&round=ALLROUNDS&matchup=ALLMATCHES&game=ALLGAMES&summonerName=yaharong&position=ALLPOSITIONS&team=ALLTEAMS&champion=Zoe&limit=5`, {
//   method: 'GET',
//   headers,
// })
// const reply2 = await reply.json()
// console.log(reply2)

const isProd = process.env.NODE_ENV === 'production';
const isLocal = false;

const baseURL = isLocal ? localURL : isProd ? prodURL : devURL;
const headers = new Headers({
  'x-api-key': isProd ? prodKey : devKey,
  'riot-api-key': isProd ? prodKey : devKey,
});

function queryStringFromParams(queryParams) {
  return Object.keys(queryParams)
       .map(k => `${encodeURIComponent(k)}=${encodeURIComponent(queryParams[k])}`)
       .join('&');
}

//
// **All-in-one solution to end-of-game updating. Be VERY CAREFUL using**
//
// This will download the game's JSON, update every stat in the DB related to this game,
// get timeline data, and return timeline and game JSON
//
// Note: Existing games are returned as-is without updating multiple times
function commitGameAndGetStats(season, league, split, round, platform, gameID) {
  console.log('data', season, league, split, round, platform, gameID);
  const queryParams = { season, league, split, round, platform, gameID };
  const queryString = queryStringFromParams(queryParams);
  return fetch(`${baseURL}/updateAfterGame?${queryString}`, {
            method: 'POST',
            headers,
          })
          .then(response => response.json());
}

function previewGameInfo (platform, gameID) {
  const queryParams = { platform, gameID };
  const queryString = queryStringFromParams(queryParams);
  return fetch(`${baseURL}/previewUpdateGame?${queryString}`, {
            method: 'GET',
            headers,
          })
          .then(response => response.json());
}

function getTimeline (platform, gameID) {
  const queryParams = { platform, gameID };
  const queryString = queryStringFromParams(queryParams);
  return fetch(`${baseURL}/timeline?${queryString}`, {
            method: 'GET',
            headers,
          })
          .then(response => response.json());
}

function getLeaderboard (season, league, split) {
  const queryParams = { season, league, split };
  const queryString = queryStringFromParams(queryParams);
  return fetch(`${baseURL}/leaderboard?${queryString}`, {
            method: 'GET',
            headers,
          })
          .then(response => response.json());
}

async function getStandings (season, league, split, round) {
  const queryParams = round === 'REGULAR' ? { season, league, split } : { season, league, split, round };
  const queryString = queryStringFromParams(queryParams);
  return fetch(`${baseURL}/standings?${queryString}`, {
            method: 'GET',
            headers,
          })
          .then(response => response.json());
}

// locale would be like en_US or ja_JP
function getChampionsForLocale (locale) {
  const queryParams = { locale };
  const queryString = queryStringFromParams(queryParams);
  return fetch(`${baseURL}/champions?${queryString}`, {
            method: 'GET',
            headers,
          })
          .then(response => response.json());
}


function getPlayerStatsForPosition (options) {
  const { season, league, split, round, matchup, game, summonerName, team, position, champion } = options;
  const queryParams = {
    season,
    league,
    split,
    round: round || 'ALLROUNDS',
    matchup: matchup || 'ALLMATCHES',
    game: game || 'ALLGAMES',
    summonerName: summonerName || 'ALLPLAYERS',
    position: position || 'ALLPOSITIONS',
    team: team || 'ALLTEAMS',
    champion: champion || 'ALLCHAMPIONS',
  };
  const queryString = queryStringFromParams(queryParams);
  return fetch(`${baseURL}/statInfo?${queryString}`, {
            method: 'GET',
            headers,
          })
          .then(response => {
            if (!response.ok) {
              return {};
            }
            return response.json();
          });
}

function getChampionsStats(options) {
  const { season, league, split } = options;
  const queryParams = {
    season,
    league,
    split,
  };
  const queryString = queryStringFromParams(queryParams);
  return fetch(`${baseURL}/teams?${queryString}`, {
            method: 'GET',
            headers,
          })
          .then(response => {
            if (!response.ok) {
              return {};
            }
            return response.json();
          });
}

export {
  getLeaderboard,
  getTimeline,
  getStandings,
  getPlayerStatsForPosition,
  getChampionsForLocale,
  getChampionsStats,
  commitGameAndGetStats,
  previewGameInfo,
}

