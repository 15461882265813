// Change me when adding a new component !

// REACT
import React, { Component } from 'react';
import { merge } from 'lodash';
// HOME
import _ from 'lodash';
import { subscribe } from '../../services/api';
// COMPONENTS
import Schedule from '../Schedule';
import ScheduleV2 from '../ScheduleV2';
import Leaderboard from '../Leaderboard';
import Standings from '../Standings';
import StandingsV2 from '../StandingsV2';
import Results from '../Results';
import Starters from '../Starters';
import StartersV2 from '../StartersV2';
import BanPick from '../BanPick';
import BanPickV2 from '../BanPickV2';
import GameScreen from '../GameScreen';
import Matchup from '../Matchup';
import MatchupV2 from '../MatchupV2';
import MatchPlayer from '../MatchPlayer';
import MatchPlayerV2 from '../MatchPlayerV2';
import Telops from '../Telops';
import TelopsV2 from '../TelopsV2';
import KeyPlayersComponent from '../KeyPlayers';
import KeyPlayersV2Component from '../KeyPlayersV2';
import GameBreakdown from '../GameBreakdown';
// import PlayersLoopLayout from '../PlayersLoopLayout';
import AnimatedBackground from '../../storybook-built/dist/components/Games/LJL/AnimatedBackground';
import CornerLogoContainer from '../CornerLogoContainer';
import Timer2Container from '../Timer2';
// import TournamentLogo from '../TournamentLogo';
import ScheduleInfoSideBar from '../ScheduleInfoSideBar';
import NextMatchInfoBottomBar from '../NextMatchInfoBottomBar';
import WaitingScreen from '../WaitingScreen';
import CastersPredictionsOverlay from '../CastersPredictionsOverlay';
import CastersPredictions from '../CastersPredictions';
import BlankScreen from '../BlankScreen';
import DraftOrder from '../DraftOrder';
import ChampionPool from '../ChampionPool';
import AssetsLoader from '../AssetsLoader';

// import TournamentLogoAsset from '../../images/leagues/ljl/LJL-logo_topleft.png';

import { ViewContainer } from './styles';
import {
    ThemeContext,
    themes,
    // LJLTopLeftLogo,
    LJLBanPickBackground,
    LJLLogo,
    // LJLAllstarTopLeftLogo,
    // LJLAllstarLogo,
    // LJLAllstarBanPickBG,
    // ScoutingGroundLogo,
    // ScoutingGroundBanPickBG
} from './themes';
import ThemeLabel from './ThemeLabel';
import { preloadImages } from '../../util/loadImageUrl';
import ScheduleTeamless from '../ScheduleTeamless';
import ResultsList from '../ResultsList';

const repeatableBrush = 'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/view/repeatable-brush.png';

const sidePlayersLoopScreens = ['standings', 'schedule', 'scheduleNext'];
class View extends Component {

      // const [display, setDisplay] = useState(false)
  // firebaseRealtimeGetValue(`control-telopsV2/data/telopsVisible`).then(value => {
  //   setDisplay(value)
  // }, reason => {
  //   console.log('TelopsV2Component: ', reason)
  // });

    preloadAssets = () => {
        const imageUrls = [
            // LJLTopLeftLogo,
            LJLBanPickBackground,
            LJLLogo,
            // LJLAllstarTopLeftLogo,
            // LJLAllstarLogo,
            // LJLAllstarBanPickBG,
            // ScoutingGroundLogo,
            // ScoutingGroundBanPickBG,
        ];
        _.forEach(themes.default.assets, url => {
            imageUrls.push(url);
        });
        preloadImages(imageUrls);
    }

    static propTypes = {
    };

    state = {
        screens: {
            banPick: {},
            banPickV2: {},
            bottomBar: {},
            castersPredictionsOverlay:{},
            castersPredictions:{},
            gameBreakdown: {},
            gameScreen: {},
            keyPlayers: {},
            keyPlayersV2: {},
            leaderboard: {},
            matchup: {},
            matchupV2: {},
            matchPlayer: {},
            matchPlayerV2: {},
            nextMatch: {},
            nextRound: {},
            results: {},
            resultsList: {},
            schedule: {},
            scheduleV2: {},
            scheduleNext: {},
            scheduleTeamless: {},
            starters: {},
            startersV2: {},
            standings: {},
            standingsV2: {},
            telops: {},
            telopsV2: {},
            timer: {},
            timer2: {},
            waitingScreen: {},
            blankScreen: {},
            draftOrder: {},
            championPool: {},
        },
        widgets: {
            nextMatchInfoBottomBar: {},
            scheduleInfoSideBar: {},
            playersLoopLayout: {},
        },
        logoSubtitle: '',
        showBottomContainer: false,
        showSideContainer: false,
        showSidePlayersInLoop: false,
        background: null,
        disableAnimationBackground: false,
        displayLogo: false,
        theme: themes.default,
    };

    componentDidMount() {
        this.preloadAssets();
        document.body.style.overflow = 'hidden';
        try {
            this.runSubscription();
        } catch (error) {
            console.log('ERROR', error);
        }
    }

    subscriptionHandler = data => {
        if (data.target && data.target !== "view") return;
        console.log('received data', data);
        //Set global view info for top components

        // if (data.screen) {
        //     const screens = this.state.screens;
        //     switch (data.action) {
        //         case 'open':
        //             if (data.screen !== 'timer') {
        //                 screens[data.screen].active = true;
        //             }
        //             screens[data.screen].action = 'open';
        //             screens[data.screen].data = data.data;
        //             if (data.screen !== 'timer') {
        //                 if (this.nonShowingLogoScreens.includes(data.screen)) {
        //                     this.setState({ displayLogo: false });
        //                 } else {
        //                     this.setState({ displayLogo: true });
        //                 }
        //             }
        //             break;
        //         case 'open2':
        //             if (data.screen !== 'timer') {
        //                 screens[data.screen].active = true;
        //             }
        //             screens[data.screen].action = 'open2';
        //             screens[data.screen].data = data.data;
        //             break;
        //         case 'update':
        //             screens[data.screen].action = 'update';
        //             screens[data.screen].data = data.data;
        //             break;
        //         case 'resize':
        //             if (data.screen !== 'timer') {
        //                 screens[data.screen].active = true;
        //             }
        //             screens[data.screen].action = 'resize';
        //             break;
        //         case 'close-resize':
        //             if (data.screen !== 'timer') {
        //                 screens[data.screen].active = true;
        //             }
        //             screens[data.screen].action = 'close-resize';
        //             break;
        //         case 'trigger':
        //             screens[data.screen].action = 'trigger';
        //             screens[data.screen].data = data.data;
        //             break;
        //         case 'close':
        //             screens[data.screen].action = 'close';
        //             break;
        //         default:
        //             break;
        //     }
        //     this.setState({ screens });
        // }

        // if (data.logoSubtitle) {
        //     this.setState({ logoSubtitle: data.logoSubtitle });
        // }

        // if (data.widget) {
        //     const widgets = this.state.widgets;
        //     widgets[data.widget] = { data: data.data }
        //     this.setState({ widgets });
        // }

        /*
            structure from firebase:
            {
                logoSubtitle: string,
                widgets: {
                    widgetName: {data: {...data}}
                },
                background: string,
                displayLogo: boolean,
                resized: boolean,
                showBottomContainer: boolean,
                theme: string,
                showDebugDisplay: boolean,
            }
        */
        this.setState({
            logoSubtitle: data.logoSubtitle || this.state.logoSubtitle || '',
            widgets: {
                nextMatchInfoBottomBar: (data.widgets && data.widgets.nextMatchInfoBottomBar) || {},
                scheduleInfoSideBar: (data.widgets && data.widgets.scheduleInfoSideBar) || {},
                playersLoopLayout: (data.widgets && data.widgets.playersLoopLayout) || {},
            },
            background: data.background || null,
            disableAnimationBackground: data.disableAnimationBackground || false,
            displayLogo: data.displayLogo || false,
            resized: data.resized || false,
            showBottomContainer: data.showBottomContainer || false,
            showSideContainer: data.showSideContainer || false,
            showSidePlayersInLoop: data.showBottomContainer && sidePlayersLoopScreens.reduce((acc, screenName) => acc || this.state.screens[screenName].active, false),
            theme: (data.theme && themes[data.theme] &&  merge({}, themes.default, themes[data.theme])) || themes.default,
            showDebugDisplay: data.showDebugDisplay || false,
            assetsList: data.assetsList,
        });

        // if (data.action === 'set-background') {
        //     this.setBackground(data.data);
        // }

        // if (data.action === 'clean') {
        //     this.state.displayLogo && this.setState({ displayLogo: false });
        // }

        // if (data.action === 'hideBottomBar') {
        //     this.setState({ showBottomContainer: false, showSidePlayersInLoop: false });
        // }

        // if (data.action === 'showBottomBar') {
        //     this.setState({ showBottomContainer: true });
        // }

        // if (data.action === 'reload') {
        //     window.location.reload();
        // }

        // if (data.action === 'theme' && data.theme) {
        //     // console.log('setting theme as ', data.theme, data.showThemeLabel);
        //     const showThemeLabel = data.showThemeLabel || false;
        //     this.setState({ theme: themes[data.theme], showThemeLabel });
        // }
    };

    viewStatusHandler = screenName => data => {
        try {
            const screens = this.state.screens;
            // console.log(screens[screenName].active, 'view received', screenName, data);
            const wasOpen = screens[screenName].active;
            screens[screenName].active = ((data.status === 'open' || data.status === 'resized') && true) || false;
            // screens[screenName].action = 'open';

            const processScreen = () => {
                if (data.data) {
                    screens[screenName].data = data.data;
                } else {
                    if (wasOpen) {
                        setTimeout(() => {
                            const screens = this.state.screens;
                            if (!screens[screenName].active) {
                                console.log('data set to null', screenName);
                                screens[screenName].data = null;
                                this.setState({screens});
                            }
                        }, 10000);
                    } else {
                        screens[screenName].data = null;
                    }
                }
                this.setState({ screens, logoSubtitle: data.logoSubtitle || '' });
            }

            if(!wasOpen && screens[screenName].active){
                // just opened
                if (data.data && data.data.preloadImages && data.data.preloadImages.length > 0) {
                    preloadImages(data.data.preloadImages).then(processScreen);
                }else{
                    processScreen();
                }
            } else {
                processScreen();
            }


            // if (screenName !== 'timer') {
            //     if (this.nonShowingLogoScreens.includes(screenName)) {
            //         this.setState({ displayLogo: false });
            //     } else {
            //         this.setState({ displayLogo: true });
            //     }
            // }
            if (data.action === 'theme' && data.theme) {
                // console.log('setting theme as ', data.theme, data.showThemeLabel);
                const showDebugDisplay = data.showDebugDisplay || false;
                const mergedTheme = merge({}, themes.default, themes[data.theme]);
                // console.log('Merged Theme', mergedTheme);
                this.setState({ theme: mergedTheme, showDebugDisplay });
            }
        } catch (error) {
            console.log('ERROR ', error);
        }
    }

    componentDidCatch(error, info) {
        console.log('Error during render ', error, info);
    }

    static getDerivedStateFromError() {
        console.log('returning has error');
        return {
            hasError: true,
        }
    }

    viewStatusWatcher = () => {
        ['control-banPick', 'control-banPickV2', 'control-gameBreakdown', 'control-gameScreen', 'control-keyPlayers', 'control-keyPlayersV2',
            'control-leaderboard', 'control-matchPlayer', 'control-matchPlayerV2', 'control-matchup','control-matchupV2',
            'control-nextMatch', 'control-standings', 'control-standingsV2', 'control-nextRound', 'control-results', 'control-resultsList',
            'control-schedule', 'control-scheduleV2', 'control-scheduleNext', 'control-scheduleTeamless', 'control-starters', 'control-startersV2',
            'control-telops',  'control-telopsV2', 'control-timer', 'control-waitingScreen', 'control-timer2', 'control-blankScreen',
            'control-castersPredictionsOverlay', 'control-castersPredictions', 'control-draftOrder', 'control-championPool'].forEach(target => {
                subscribe(target, null, this.viewStatusHandler(target.split('-').pop()), window.location.pathname.substring(1));
            });
    }

    runSubscription = () => {
        subscribe('view', null, this.subscriptionHandler, window.location.pathname.substring(1));
        this.viewStatusWatcher();
    };

    statusCallback = (screen, status) => {
        const screens = this.state.screens;
        this.setState({screens});
        // let returnStatus = status;
        if (status === 'closed') {
            // returnStatus = 'ready';
            screens[screen].reset = true;
            screens[screen].data = null;
            screens[screen].active = false;
            this.setState({screens});
        }
        // send('event', {
        //     target: `control-${screen}`,
        //     status: returnStatus,
        // });

    }

    setBackground = background => {
        this.setState({background: background});
    };

    showBottomContainer = () => {
        if (!this.state.showBottomContainer) {
            this.setState({showBottomContainer: true});
        }
    }

    hideBottomContainer = () => {
        if (this.state.showBottomContainer) {
            this.setState({showBottomContainer: false});
        }
    }

    showSidePlayers = () => {
        if (!this.state.showSidePlayersInLoop) {
            this.setState({showSidePlayersInLoop: true});
        }
    }

    hideSidePlayers = () => {
        if (this.state.showSidePlayersInLoop) {
            this.setState({showSidePlayersInLoop: false});
        }
    }

    render() {
        if (this.state.hasError) {
            return (
                <ThemeContext.Provider value={this.state.theme}>
                    {
                        this.state.showDebugDisplay &&
                        <ThemeLabel />
                    }
                    <ViewContainer
                        className="view"
                    >
                    </ViewContainer>
                </ThemeContext.Provider>
            )
        }
        try {
            const bottomContainerActions = {
                show: this.showBottomContainer,
                hide: this.hideBottomContainer,
            }
            const sidePlayersContainerActions = {
                show: this.showSidePlayers,
                hide: this.hideSidePlayers,
            }
            // 2019 TODO: check below comments
            return (
                <ThemeContext.Provider value={this.state.theme}>
                    {
                        this.state.showDebugDisplay &&
                        <ThemeLabel />
                    }

                    <AssetsLoader
                        assetsList={this.state.assetsList}
                    />

                    <ViewContainer
                    className="view"
                        background={!this.state.screens.keyPlayers.active &&
                                !this.state.screens.keyPlayersV2.active &&
                                !this.state.screens.banPick.active &&
                                !this.state.screens.gameScreen.active &&
                                this.state.background}
                    >
                        {
                            !this.state.screens.keyPlayers.active &&
                            !this.state.screens.keyPlayersV2.active &&
                            !this.state.screens.banPick.active &&
                            !this.state.screens.banPickV2.active &&
                            !this.state.screens.gameScreen.active &&
                            !this.state.screens.telops.active &&
                            !this.state.screens.telopsV2.active &&
                            !this.state.screens.draftOrder.active &&
                            !this.state.screens.blankScreen.active &&
                            !this.state.screens.championPool.active &&
                            this.state.background &&
                            this.state.theme.screens.animatedBackground.show &&
                            <AnimatedBackground // already in components
                                background={this.state.background}
                                brushPattern={repeatableBrush}
                                disableAnimationBG={this.state.disableAnimationBackground}
                            />
                        }
                        {
                            <CornerLogoContainer
                                showTitle={this.state.displayLogo}
                                title={this.state.logoSubtitle}
                            />
                        }
                        { this.state.screens.timer2.data &&
                            <Timer2Container
                                active={this.state.screens.timer2.active}
                                data={this.state.screens.timer2.data}
                                statusCallback={this.statusCallback}
                            />
                        }
                        {
                            // this.state.screens.schedule.active &&
                            this.state.screens.schedule.data &&
                            <Schedule // this looks good, ready to move into components
                                active={this.state.screens.schedule.active}
                                action={this.state.screens.schedule.action}
                                data={this.state.screens.schedule.data}
                                statusCallback={this.statusCallback}
                                bottomContainerActions={bottomContainerActions}
                                sidePlayersContainerActions={sidePlayersContainerActions}
                                isBottomContainerRender={this.state.showBottomContainer}
                                resized={this.state.resized}
                            />
                        }
                        {
                            // this.state.screens.schedule.active &&
                            this.state.screens.scheduleV2.data &&
                            <ScheduleV2 // this looks good, ready to move into components
                                active={this.state.screens.scheduleV2.active}
                                action={this.state.screens.scheduleV2.action}
                                data={this.state.screens.scheduleV2.data}
                                statusCallback={this.statusCallback}
                                // resized={this.state.resized}
                                isSideContainerRender={this.state.showSideContainer}
                            />
                        }
                        {
                            // this.state.screens.scheduleNext.active &&
                            this.state.screens.scheduleNext.data &&
                            <Schedule // this looks good, ready to move into components
                                active={this.state.screens.scheduleNext.active}
                                action={this.state.screens.scheduleNext.action}
                                data={this.state.screens.scheduleNext.data}
                                statusCallback={this.statusCallback}
                                bottomContainerActions={bottomContainerActions}
                                sidePlayersContainerActions={sidePlayersContainerActions}
                                isBottomContainerRender={this.state.showBottomContainer}
                                resized={this.state.resized}
                            />
                        }
                        {
                            // this.state.screens.schedule.active &&
                            this.state.screens.scheduleTeamless.data &&
                            <ScheduleTeamless // this looks good, ready to move into components
                                active={this.state.screens.scheduleTeamless.active}
                                action={this.state.screens.scheduleTeamless.action}
                                data={this.state.screens.scheduleTeamless.data}
                                statusCallback={this.statusCallback}
                                bottomContainerActions={bottomContainerActions}
                                isBottomContainerRender={this.state.showBottomContainer}
                                resized={this.state.resized}
                            />
                        }
                        {
                            // this.state.screens.leaderboard.active &&
                            this.state.screens.leaderboard.data &&
                            <Leaderboard // this looks good, ready to move into components
                                resized={this.state.resized}
                                active={this.state.screens.leaderboard.active}
                                action={this.state.screens.leaderboard.action}
                                data={this.state.screens.leaderboard.data}
                                statusCallback={this.statusCallback}
                                bottomContainerActions={bottomContainerActions}
                                sidePlayersContainerActions={sidePlayersContainerActions}
                                isBottomContainerRender={this.state.showBottomContainer}
                                isSideContainerRender={this.state.showSideContainer}
                            />
                        }
                        {
                            // this.state.screens.standings.active &&
                            this.state.screens.standings.data &&
                            <Standings // this looks good, ready to move into components
                                resized={this.state.resized}
                                active={this.state.screens.standings.active}
                                action={this.state.screens.standings.action}
                                data={this.state.screens.standings.data}
                                statusCallback={this.statusCallback}
                                bottomContainerActions={bottomContainerActions}
                                sidePlayersContainerActions={sidePlayersContainerActions}
                                isBottomContainerRender={this.state.showBottomContainer}
                                isSideContainerRender={this.state.showSideContainer}
                            />
                        }
                        {
                            // this.state.screens.standingsV2.active &&
                            this.state.screens.standingsV2.data &&
                            <StandingsV2 // this looks good, ready to move into components
                                resized={this.state.resized}
                                active={this.state.screens.standingsV2.active}
                                action={this.state.screens.standingsV2.action}
                                data={this.state.screens.standingsV2.data}
                                statusCallback={this.statusCallback}
                                isSideContainerRender={this.state.showSideContainer}
                            />
                        }
                        {
                            // this.state.screens.results.active &&
                            this.state.screens.results.data &&
                            <Results // this looks good, ready to move into components
                                active={this.state.screens.results.active}
                                action={this.state.screens.results.action}
                                data={this.state.screens.results.data}
                                statusCallback={this.statusCallback}
                                bottomContainerActions={bottomContainerActions}
                                isBottomContainerRender={this.state.showBottomContainer}
                            />
                        }
                        {
                            // this.state.screens.results.active &&
                            this.state.screens.resultsList.data &&
                            <ResultsList // this looks good, ready to move into components
                                active={this.state.screens.resultsList.active}
                                action={this.state.screens.resultsList.action}
                                data={this.state.screens.resultsList.data}
                                statusCallback={this.statusCallback}
                                bottomContainerActions={bottomContainerActions}
                                isBottomContainerRender={this.state.showBottomContainer}
                            />
                        }
                        {
                            // this.state.screens.starters.active &&
                            this.state.screens.starters.data &&  // this looks good, ready to move into components
                            <Starters action={this.state.screens.starters.action}
                                active={this.state.screens.starters.active}
                                data={this.state.screens.starters.data} statusCallback={this.statusCallback}
                            />
                        }
                        {
                            // this.state.screens.starters.active &&
                            this.state.screens.startersV2.data &&  // this looks good, ready to move into components
                            <StartersV2 action={this.state.screens.startersV2.action}
                                active={this.state.screens.startersV2.active}
                                data={this.state.screens.startersV2.data} statusCallback={this.statusCallback}
                            />
                        }
                        {
                            // this.state.screens.banPick.active &&
                            this.state.screens.banPick.data && // this looks good, ready to move into components
                            <BanPick action={this.state.screens.banPick.action}
                                active={this.state.screens.banPick.active}
                                data={this.state.screens.banPick.data} statusCallback={this.statusCallback}
                            />
                        }
                        {
                            // this.state.screens.banPickV2.active &&
                            this.state.screens.banPickV2.data &&
                            <BanPickV2 action={this.state.screens.banPickV2.action}
                                active={this.state.screens.banPickV2.active}
                                data={this.state.screens.banPickV2.data}
                                statusCallback={this.statusCallback}
                            />
                        }
                        {
                            // this.state.screens.gameScreen.active &&
                            this.state.screens.gameScreen.data && // this looks good, ready to move into components
                            <GameScreen active={this.state.screens.gameScreen.active}
                                data={{ ...this.state.screens.gameScreen.data }} statusCallback={this.statusCallback}
                            />
                        }
                        {
                            // this.state.screens.matchup.active &&
                            this.state.screens.matchup.data && // this looks good, ready to move into components
                            <Matchup action={this.state.screens.matchup.action}
                                active={this.state.screens.matchup.active}
                                data={this.state.screens.matchup.data} statusCallback={this.statusCallback}
                            />
                        }
                        {
                            // this.state.screens.matchup.active &&
                            this.state.screens.matchupV2.data && // this looks good, ready to move into components
                            <MatchupV2 action={this.state.screens.matchupV2.action}
                                active={this.state.screens.matchupV2.active}
                                data={this.state.screens.matchupV2.data} statusCallback={this.statusCallback}
                            />
                        }
                        {
                            // this.state.screens.matchPlayer.active &&
                            this.state.screens.matchPlayer.data && // this looks good, ready to move into components
                            <MatchPlayer action={this.state.screens.matchPlayer.action}
                                active={this.state.screens.matchPlayer.active}
                                data={this.state.screens.matchPlayer.data} statusCallback={this.statusCallback}
                            />
                        }
                        {
                            // this.state.screens.matchPlayer.active &&
                            this.state.screens.matchPlayerV2.data && // this looks good, ready to move into components
                            <MatchPlayerV2 action={this.state.screens.matchPlayerV2.action}
                                active={this.state.screens.matchPlayerV2.active}
                                data={this.state.screens.matchPlayerV2.data} statusCallback={this.statusCallback}
                            />
                        }
                        {
                            // this.state.screens.telops.active &&
                            this.state.screens.telops.data && // this looks good, ready to move into components
                            <Telops action={this.state.screens.telops.action}
                                active={this.state.screens.telops.active}
                                data={this.state.screens.telops.data} statusCallback={this.statusCallback}
                            />
                        }
                        {
                            // this.state.screens.telops.active &&
                            this.state.screens.telopsV2.data &&
                            <TelopsV2
                                active={this.state.screens.telopsV2.data.telopsVisible}
                                data={this.state.screens.telopsV2.data}
                            />
                        }
                        {
                            // this.state.screens.keyPlayers.active &&
                            this.state.screens.keyPlayers.data && // this looks good, ready to move into components
                            <KeyPlayersComponent
                                active={this.state.screens.keyPlayers.active}
                                action={this.state.screens.keyPlayers.action}
                                data={this.state.screens.keyPlayers.data}
                                statusCallback={this.statusCallback}
                            />
                        }
                        {
                            // this.state.screens.keyPlayers.active &&
                            this.state.screens.keyPlayersV2.data && // this looks good, ready to move into components
                            <KeyPlayersV2Component
                                active={this.state.screens.keyPlayersV2.active}
                                action={this.state.screens.keyPlayersV2.action}
                                data={this.state.screens.keyPlayersV2.data}
                                statusCallback={this.statusCallback}
                            />
                        }
                        {
                            // this.state.screens.gameBreakdown.active &&
                            this.state.screens.gameBreakdown.data &&
                            <GameBreakdown // this looks good, ready to move into components
                                action={this.state.screens.gameBreakdown.action}
                                active={this.state.screens.gameBreakdown.active}
                                data={this.state.screens.gameBreakdown.data}
                                statusCallback={this.statusCallback}
                                bottomContainerActions={bottomContainerActions}
                                isBottomContainerRender={this.state.showBottomContainer}
                            />
                        }
                        {
                            this.state.screens.waitingScreen.data &&
                            <WaitingScreen // this looks good, ready to move into components
                                active={this.state.screens.waitingScreen.active}
                                action={this.state.screens.waitingScreen.action}
                                data={{
                                    ...this.state.screens.waitingScreen.data,
                                    timer: this.state.screens.timer.data,
                                }}
                                statusCallback={this.statusCallback}
                                timerActive={this.state.screens.timer.active}
                            />
                        }
                        {
                            this.state.screens.blankScreen.data &&
                            <BlankScreen />
                        }
                        {
                            this.state.screens.castersPredictionsOverlay.data &&
                            <CastersPredictionsOverlay
                                active={this.state.screens.castersPredictionsOverlay.active}
                                action={this.state.screens.castersPredictionsOverlay.action}
                                data={this.state.screens.castersPredictionsOverlay.data}
                                statusCallback={this.statusCallback}
                            />
                        }
                        {
                            this.state.screens.castersPredictions.data &&
                            <CastersPredictions
                                active={this.state.screens.castersPredictions.active}
                                action={this.state.screens.castersPredictions.action}
                                data={this.state.screens.castersPredictions.data}
                                statusCallback={this.statusCallback}
                            />
                        }
                        <NextMatchInfoBottomBar
                            resize={this.state.showBottomContainer || false}
                            data={{
                                ...this.state.widgets.nextMatchInfoBottomBar.data,
                                timer: this.state.screens.timer.data,
                            }}
                            display={true}
                            timerActive={this.state.screens.timer.active}
                            statusCallback={this.statusCallback}
                        />
                        <ScheduleInfoSideBar
                            data={{
                                ...this.state.widgets.scheduleInfoSideBar.data,
                                timer: this.state.screens.timer.data,
                            }}
                            display={this.state.showSideContainer || false}
                            statusCallback={this.statusCallback}
                            timerActive={this.state.screens.timer.active}
                        />
                        {this.state.screens.draftOrder.data && <DraftOrder
                            active={this.state.screens.draftOrder.active}
                            action={this.state.screens.draftOrder.action}
                            data={this.state.screens.draftOrder.data}
                            statusCallback={this.statusCallback}
                                                               />}
                        {this.state.screens.championPool.data && <ChampionPool
                            active={this.state.screens.championPool.active}
                            action={this.state.screens.championPool.action}
                            data={this.state.screens.championPool.data}
                            statusCallback={this.statusCallback}
                                                                 />}

                        {/* {
                            <PlayersLoopLayout
                                resize={this.state.showSidePlayersInLoop}
                                data={this.state.widgets.playersLoopLayout.data}
                                display={
                                    this.state.screens.gameBreakdown.active ||
                                    this.state.screens.leaderboard.active ||
                                    this.state.screens.standings.active ||
                                    this.state.screens.schedule.active ||
                                    this.state.screens.scheduleNext.active ||
                                    this.state.showBottomContainer}
                            />
                        } */}
                    </ViewContainer>
                </ThemeContext.Provider>
            );
        } catch (error) {
            return <div/>
        }
    }

}

View.contextType = ThemeContext;

export default View
