"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StyleTeamLogoVideoContainer = exports.StyleTeamLogoImageContainer = exports.StyleStatsValue = exports.StyleStatsHeader = exports.StyleStatsContainer = exports.StylePlayerImg = exports.StyleInfoBoxTitle = exports.StyleInfoBoxBody = exports.StyleChampionContainer = void 0;

var _styledComponents = _interopRequireDefault(require("styled-components"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var StyleInfoBoxBody = _styledComponents.default.div.withConfig({
  displayName: "styles__StyleInfoBoxBody",
  componentId: "sc-al9mdt-0"
})(["display:grid;grid-template-columns:320px 155px 865px;font-family:'futura-pt',sans-serif;align-items:center;height:210px;position:absolute;width:1340px;overflow:visible;top:70px;bottom:0;color:#ffffff;left:7px;"]);

exports.StyleInfoBoxBody = StyleInfoBoxBody;

var StyleInfoBoxTitle = _styledComponents.default.div.withConfig({
  displayName: "styles__StyleInfoBoxTitle",
  componentId: "sc-al9mdt-1"
})(["font-family:'futura-pt',sans-serif;font-weight:700;letter-spacing:2px;color:white;font-size:40px;left:50%;position:absolute;text-align:center;top:5px;transform:translateX(-33%);width:100%;height:60px;line-height:60px;", ";"], function (props) {
  return props.StyleInfoBoxTitle ? props.StyleInfoBoxTitle : '';
});

exports.StyleInfoBoxTitle = StyleInfoBoxTitle;

var StylePlayerImg = _styledComponents.default.img.withConfig({
  displayName: "styles__StylePlayerImg",
  componentId: "sc-al9mdt-2"
})(["position:absolute;bottom:-260px;left:140px;width:340px;height:auto;"]);

exports.StylePlayerImg = StylePlayerImg;

var StyleTeamLogoImageContainer = _styledComponents.default.div.withConfig({
  displayName: "styles__StyleTeamLogoImageContainer",
  componentId: "sc-al9mdt-3"
})(["position:absolute;bottom:0;left:-100px;height:210px;", " img{height:210px;", "}"], function (props) {
  return props.mode === 'teams' ? "\n        clip-path: polygon(0% 0,114% 0,129% 100%,0% 100%);\n        left: -112px;\n    " : 'clip-path:inset(0 0% 0 27%);';
}, function (props) {
  return props.mode === 'teams' && 'transform: translateX(30%);';
});

exports.StyleTeamLogoImageContainer = StyleTeamLogoImageContainer;

var StyleTeamLogoVideoContainer = _styledComponents.default.div.withConfig({
  displayName: "styles__StyleTeamLogoVideoContainer",
  componentId: "sc-al9mdt-4"
})(["position:absolute;bottom:0;left:-229px;height:210px;", " video{height:210px;", "}"], function (props) {
  return props.mode === 'teams' ? "\n        clip-path: polygon(34% 0, 86% 0, 100% 100%, 34% 100%);\n    " : 'clip-path:inset(0 20% 0 34%);';
}, function (props) {
  return props.mode === 'teams' && 'transform: translateX(30%);';
});

exports.StyleTeamLogoVideoContainer = StyleTeamLogoVideoContainer;

var StyleStatsContainer = _styledComponents.default.div.withConfig({
  displayName: "styles__StyleStatsContainer",
  componentId: "sc-al9mdt-5"
})(["width:865px;display:grid;grid-template-columns:", ";position:absolute;right:0;height:210px;"], function (props) {
  return "repeat(".concat(props.statsLength, ", 1fr)");
});

exports.StyleStatsContainer = StyleStatsContainer;

var StyleChampionContainer = _styledComponents.default.div.withConfig({
  displayName: "styles__StyleChampionContainer",
  componentId: "sc-al9mdt-6"
})(["display:flex;justify-content:center;grid-column-start:1;grid-column-end:", ";text-align:center;height:40px;margin:15px;img{display:inline;}span{text-transform:uppercase;font-size:25px;font-weight:500;margin-left:10px;line-height:40px;letter-spacing:1px;}", ";"], function (props) {
  return props.statsLength + 1;
}, function (props) {
  return props.StyleChampionContainer ? props.StyleChampionContainer : '';
});

exports.StyleChampionContainer = StyleChampionContainer;

var StyleStatsHeader = _styledComponents.default.div.withConfig({
  displayName: "styles__StyleStatsHeader",
  componentId: "sc-al9mdt-7"
})(["height:40px;background:", ";text-align:center;color:#ffffff;font-weight:400;font-size:20px;line-height:40px;text-transform:uppercase;padding:0 5px;overflow:hidden;margin-right:", ";letter-spacing:1px;", ";"], function (props) {
  return "linear-gradient(0deg, ".concat(props.teamColor.sub, " 0%, ").concat(props.teamColor.main, " 100%)");
}, function (props) {
  return props.isLast ? '30px' : '24px';
}, function (props) {
  return props.StyleStatsHeader ? props.StyleStatsHeader : '';
});

exports.StyleStatsHeader = StyleStatsHeader;

var StyleStatsValue = _styledComponents.default.div.withConfig({
  displayName: "styles__StyleStatsValue",
  componentId: "sc-al9mdt-8"
})(["font-size:46px;font-weight:600;text-align:center;margin-right:24px;&:last-of-type{margin-right:30px;}", ";"], function (props) {
  return props.StyleStatsValue ? props.StyleStatsValue : '';
});

exports.StyleStatsValue = StyleStatsValue;