import React, {useState, useEffect} from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { get } from 'lodash';


const loadingStatus = {};

function AssetsLoader(props) {

    // const [loadedAssetsState, setLoadedAssetState] = useState([]);
    const [reloadCycleState, setReloadCycleState] = useState(0);

    // useEffect(() => {
    //     // console.log('AssetsLoader props', props);
    // },[])

    const loadImg = imgUrl => {
        loadingStatus[imgUrl] = 'loading';
        const newImg = new Image();
        newImg.onload= () => {
            console.log(imgUrl, " loaded");
            loadingStatus[imgUrl] = 'loaded';
            // setLoadedAssetState(current => {
            //     const newLoadedAsset = [...current];
            //     newLoadedAsset.push(imgUrl);
            //     return newLoadedAsset;
            // });
        }
        newImg.onerror= () => {
            console.log("issue loading", imgUrl);
            loadingStatus[imgUrl] = 'error';
            setTimeout(()=>{
                // console.log("need to reload", imgUrl)
                setReloadCycleState(current => (current+1));
            }, 2000)
        }
        newImg.src = imgUrl;
    }


    useEffect(() => {
        // console.log("prosp assetsList", get(props, 'assetsList', []))
        // get(props, 'assetsList', []).forEach(imgUrl => {
        //     if((!loadingStatus[imgUrl]) || (loadingStatus[imgUrl] === 'error')) {
        //         console.log("new asset to load", imgUrl);
        //         loadImg(imgUrl);
        //     }
        // });
    },[props.assetsList])

    useEffect(()=> {
        for(const urlKey in loadingStatus) {
            if(loadingStatus[urlKey] === 'error' && get(props, 'assetsList',[]).includes(urlKey)){
                console.log("RETRY to load", urlKey);
                loadImg(urlKey);
            }
        }
    }, [reloadCycleState])

    return (
        <StyleMain>
            {/* {
                loadedAssetsState
                .map(
                    url => <StyleInvisibleImg key={url} src={url} alt={'preloaded pics'} />
                )
            } */}
        </StyleMain>
    )
}

AssetsLoader.propTypes = {
    assetsList: PropTypes.arrayOf(
        PropTypes.string,
    ),
}

const StyleMain = styled.div`
    position: absolute;
    transform: translate(-100%, -100%);
`;

// const StyleInvisibleImg = styled.img`
//     opacity: 0;
// `;

export default AssetsLoader

