// REACT
import React, { Component } from 'react';
import PropTypes from 'prop-types';
//ME
import Picker from '../../Picker';
import {seasons} from '../../../util/const';

class SeasonPicker extends Component {

  static propTypes = {
    onUpdate: PropTypes.func,
    preselect: PropTypes.string,
    season: PropTypes.object,
    disableSubmitButton: PropTypes.func,
  };

  render() {
    const seasonsInfo = [];
    for (let i = 0; i < Object.keys(this.props.season).length; i++) {
      for (let k = 0; k < seasons.length; k++) {
        Object.keys(this.props.season)[i] === seasons[k].value && seasonsInfo.push({value: seasons[k].value, name: seasons[k].name});
      }
    }

    seasonsInfo.length <= 0 && this.props.disableSubmitButton('season');

    return (
      <Picker
        onUpdate={this.props.onUpdate}
        items={seasonsInfo}
        preselect={this.props.preselect}
        showSelectOption={false}
      />
    );
  };
}

export default SeasonPicker;
