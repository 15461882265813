// REACT
import React, { Component } from 'react';
import PropTypes from 'prop-types';
// 3RD PARTY
import { delay } from 'lodash';
// ME
import { ThemeContext } from '../View/themes';

// import ScreenTitle from '../../storybook-built/dist/components/Games/LJL/ScreenTitle';
import RankingsStandingsComponent from '../../storybook-built/dist/components/Games/LJL/Results_Rankings';


class ResultsList extends Component {

    static propTypes = {
        active: PropTypes.bool,
        // action: PropTypes.string,
        data: PropTypes.any,
        statusCallback: PropTypes.func,
        // isBottomContainerRender: PropTypes.bool,
    }

    state = {
        played: false,
        status: 'closed',
        callbackName: 'resultsList',
        // displayTitle: '',
    };

    bound = false;

    componentDidMount() {
        this.props.statusCallback('resultsList', 'ready');
        setTimeout(() => this.doAction(this.props), 1000);
    }

    componentWillUnmount() {
        console.log('unmount resultsList');
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.active) {
            if (this.state.status !== 'open') {
                this.setState({ status: 'open' }, () => {
                    this.checkSubscriptionData(nextProps.data);
                    this.processContainerRender(nextProps);
                });
            } else {
                this.processContainerRender(nextProps);
            }
        } else {
            if (this.state.status === 'open') {
                this.clearPage();
                this.setState({status: 'closed'});
            }
        }
        this.doAction(nextProps);
    }

    doAction = props => {
        if (props.active) {
            if (this.state.status !== 'open') {
                this.setState({ status: 'open' });
            }
        } else {
            if (this.state.status === 'open') {
                this.setState({status: 'closed'});
                setTimeout(() => this.props.statusCallback('resultsList', 'closed'), 1900);
            }
        }
    }

    processContainerRender = nextProps => {
        if (nextProps.isBottomContainerRender && this.state.animation === '2') {
            this.setState({animation: '3'});
        }
        if (nextProps.isBottomContainerRender && this.state.animation === '3') {
            this.setState({animation: '2'});
        }
    }

    checkSubscriptionData = async data => {
        this.setState({status: 'open'});
        if (!data) return;
        if (!this.state.played) this.setState({ played: true }, () => this.beginAnimation());
        await this.props.statusCallback('resultsList', 'opening');
    };

    beginAnimation = async () => {
        this.props.statusCallback(this.state.callbackName, 'opening');
        this.setState({animation: '0'}); // header base
        delay(() => this.setState({animation: '1'}), 400); // header slide
        delay(() => this.setState({animation: '2'}), 800); // header title
        delay(() => this.props.statusCallback(this.state.callbackName, 'open'), 2600);
    };

    clearPage = () => {
        this.props.statusCallback('results', 'closing');
        this.setState({animation: '2'});
        delay(() => this.setState({animation: '1'}), 400);
        delay(() => this.setState({animation: '0'}), 800);
        delay(() => this.props.statusCallback('resultsList', 'closed'), 2500);
    };

    render() {

        const theme =(this.context.screens && this.context.screens.resultsList) || {};


        return (
            <div className={`view-results view-results--animation-${this.state.animation} container`}>
                <RankingsStandingsComponent
                    data={this.props.data}

                    isShowing={this.state.status === "open"}
                    theme= {theme}
                />
            </div>
        );
    }

}
ResultsList.contextType = ThemeContext;

export default ResultsList
