
export default {
  id: 'worlds2021',
  name: 'Worlds',
  screens: {
    cornerLogo: {
      cssCornerLabelText:
        'display:none;',
      startX: '-400px',
      endX: '-400px',
      startY: '0px',
      endY: '0px',
      hideCornerLogo: true, // hide the CornerLogo by default on all screens
    },
    animatedBackground: {
      show: true,
      defaultBackground:
        'https://dekki-production.s3.ap-northeast-1.amazonaws.com/uploads/broadcast-projects/worlds/2021/videos/BG-LOOP_4-MIN.mp4',
      disableAnimationBackGround: false,
    },
    screenTitleV2: {
      StyleTitleContainer: `background: none`,
      StyledLogo: `background:
        url('https://cdn.dekki.com/assets/broadcast/games/league-of-legends/backgrounds/MSI-2021_Banner_crop-1.png') left center;
        background-repeat: no-repeat;`,
      StyledBar: `background: #0034f7;`,
      StyleSubTitle: `font-family: Nimbus, sans-serif; font-size: 28px;`,
      StyledTitle: `font-family: Presto; font-style: italic; font-size: 52px;`,
    },
    scheduleV2: {
      StyleRes: `font-family: Nimbus, sans-serif; font-size: 21px;`,
      StyleTeam: `font-family: Presto; font-style: italic; font-size: 28px;`,
      cssName: `color: black; font-size: 52px; font-family: Presto; font-style: italic; letter-spacing: 3px;`,
      speedPanel: 200,
      background: `https://cdn.dekki.com/assets/broadcast/games/league-of-legends/backgrounds/StandingsV2_BG_MSI2021.png`,
      main: `letter-spacing: 3px; color: black;`,
      css5: `background: #E0E1E3;`,
      mainColor: `#0034f7`,
      secondaryColor: "black",
      cssVersus: `color: #0034f7; 
        font-size: 50px; 
        font-family: Nimbus, sans-serif; 
        grid-template-rows: none; 
        h1{
          font-weight: 400;
          font-size: 50px; 
          font-family: Nimbus, sans-serif;
        }
          `,
      cssScore: `color: #0034f7; font-size: 50px; font-family: Nimbus, sans-serif;`,
      VSTimeBo: `display:none;`,
      bottom: `background: linear-gradient(to left, #0034f7, #0034f7);`,
      StyleScheduleScreenBackground: `background: #e0e1e3; border-bottom: 15px solid #0034f7;`,
    },
    standingsV2: {
      mainColor: "#0034f7;",
      groupTitle: `font-size: 30px; font-family: Nimbus, sans-serif; font-style: normal;`,
      css: `color: black; background: #E0E1E3; border-bottom: 15px solid #0034f7; font-family: Presto; font-style: italic; font-size: 24px;`,
      standingsResizeOverWrite: `background: #e0e1e3 !important;`,
    },
    bottomBar: {
      cssBottomBar: 'border-image:linear-gradient(to right,#ffffff99,#1b1b1b,#ffffff99) 10',
      teamLogoColorBG: '#a7a7a766',
      borderColor: 'teamMain',
      borderColorBase: '',
      backgroundColor: '#1b1b1b',
      cssTeamName: 'font-family: United; color:#ffffff;',
      cssNextGameTitle: 'font-family: United; font-size: 28px; color: #dba103; margin-top: 15px;',
      cssTimer: 'font-family: United; font-weight: 800; font-size: 85px; color: #dba103;',
    },
    scheduleInfoSideBar: {
      StyleCenterContainer: `color: black; font-family: Presto; font-style: italic; font-size: 24px;`,
      cssScore: `color: #0034f7; font-size: 70px; font-family: Presto; font-style: italic;`,
      logoSrc: 'https://cdn.dekki.com/uploads/leagues/worlds_2020/Worlds-2021_logo.svg',
      cssTimerText: 'color: #0034f7; font-weight: bold; font-family: Nimbus, sans-serif; font-weight: 700;font-size: 20px',
      cssTimer: 'color: #0034f7; div{ font-family: Presto; font-style: italic; font-weight: 700; font-size: 80px !important; letter-spacing: 2px; padding-left: 5px;}',
      cssBackground: 'background: linear-gradient(45deg ,#b9babc 0%,#e0e1e3 45%); border-right: 22px solid #0034f7;',
      cssDayTitle: 'color: black; margin-bottom: 10px; padding-bottom: 0; font-size: 30px; font-family: Nimbus, sans-serif; font-weight: 700; ',
      cssScheduleList: 'background-color: rgba(0,0,0,0.05); width: 430px;',
      cssScheduleRow: 'color: purple; font-family: Presto; font-style: italic; border-bottom: 1px solid #0034f7; font-size: 24px; border-bottom: 1px solid #0034f7;',
      cssNext: 'color: #0034f7; font-family: Presto; font-style: italic; font-weight: 400; font-size: 24px; letter-spacing: 1px;',
      cssWin: 'color: black; font-family: Presto; font-style: italic; font-weight: 400; font-size: 24px; letter-spacing: 1px;',
      cssGameTime: 'color: black; font-family: Presto; font-style: italic; font-weight: 400; font-size: 24px; letter-spacing: 1px;',
      cssLogoLoss: '0.5;',
    },
    telopsV2: {
      cssTelops: `
        border-right: 15px solid #0034f7;
        background: #0034f7;
        border-left: unset;
        div:first-of-type{
            background: linear-gradient(60deg, #000220 0%, #001642 100%);
            border: unset;
            left: 0;
            padding-top:0;
        }`,
      cssTwitter: 'p{font-family: proxima-nova, sans-serif; font-weight: 700; color: #0034f7; font-size: 20px;} margin-top: -2px;',
      cssName: 'top: 0; font-family: dharma-gothic-e, sans-serif; font-weight: 700; color: white; font-size: 60px; line-height: 57px;',
    },
    gameBreakdown: {
      backgroundImage: '',
      // backgroundImage: 'https://cdn.dekki.com/uploads/leagues/ljl_2019_summer/gameBreakdownBG.png',
      disableSliderBG: true,
      teamStats: {
        teamStatsBGColor: '#000220',
        teamStatsBGTexture: 'none',
        showTeamBackgroundImageStatsContents: false,
        teamStatsHeaderBGColor: '#000220',
        cssTeamName: { color: '#0034f7', fontFamily: 'proxima-nova, sans-serif', fontSize: '45px', fontWeight: '700', display: 'flex', justifyContent: 'center', alignItems: 'center' },
        cssTeamNameLeft: 'color: #0034f7;',
        cssTeamNameRight: 'color: #a070bb;',
        item: { color: '#ffffff', fontFamily: 'proxima-nova, sans-serif', fontWeight: '300', fontSize: '28px' },
        cssTeamStats: "font-family: 'proxima-nova, sans-serif'; font-weight: 300; ",
        cssWinTag: "font-family: proxima-nova, sans-serif; font-weight: 700;",
        cssLossTag: "background-color: rgba(255, 255, 255, 0.6); font-family: proxima-nova, sans-serif; font-weight: 700;",
        cssBansPicks: { color: '#ffffff', fontSize: '30px', fontFamily: 'proxima-nova, sans-serif', fontWeight: '300' },
        dragons: { backgroundColor: 'unset', border: '4px solid transparent' },
      },
      key: {
        gameTime: { fontFamily: 'dharma-gothic-e, sans-serif', fontWeight: '700', fontSize: '70px', letterSpacing: '2px', marginBottom: '0px' },
        gameTimeHeader: { marginBottom: '0', fontSize: '20px', color: '#ffffff' },
        cssHeader: { fontFamily: 'proxima-nova, sans-serif' },
        cssTitles: { fontFamily: 'proxima-nova, sans-serif', fontWeight: '300', color: '#ffffff', fontSize: '26px' },
        content: { backgroundColor: '#000220B3', backgroundImage: 'unset' },
      },
      graph: {
        cssHeader: { fontFamily: 'proxima-nova, sans-serif', backgroundColor: '#000220', backgroundImage: 'unset', fontWeight: '400', letterSpacing: '2px', fontSize: '32px' },
        cssContent: { fontFamily: 'proxima-nova, sans-serif', color: '#141720', backgroundImage: 'unset', fontWeight: '300', backgroundColor: '#000220', borderBottom: 'none' },
        teamLeftColor: '#0034f7', // blue initially
        teamRightColor: '#a070bb', // red initially
        cssKey: {
          borderBottom: 'none',
          fontFamily: 'proxima-nova, sans-serif', fontWeight: '700', fontSize: '26px',
          backgroundImage: 'unset', backgroundColor: '#000220B3',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          paddingTop: '35px',
        },
        damageCountBackground: {
          ':before': {
            backgroundColor: 'unset',
          },
        },
        indexesColor: "#ffffff",
      },
    },
    schedule: {
      speedPanel: 200,

      cssRow: 'font-family: futura-pt, "sans-serif"; color:#141720; overflow: visible; border-right: 10px #ffffff solid; max-height: 120px;',
      cssRowNext: 'color:#141720; background: linear-gradient(0deg, #15ace2 0%,  #0034f7 100%); border-left: unset; max-height: 120px;',
      cssRowFinished: '',

      cssDate: 'background-color: #000220; color: #ffffff; border-right: 10px #ffffff solid; font-family: dharma-gothic-e, sans-serif; font-weight: 700; font-size: 35px;',
      cssDateFinished: 'background-color: #000220; color: #ffffff;',
      cssDateNext: 'background-color: #000220; color: #0034f7;',

      cssLogo: 'background-color: #000220; margin-right: 0; border-right: unset; border-left: unset;',
      cssLogoNext: 'background-color: #000220;',
      cssLogoLoser: 'background-color: #000220; ',
      cssLogoWinner: 'background-color: #000220;',

      cssName: 'background-color: #000220; color: #ffffff; font-size: 28px; letter-spacing: 1px; font-weight:700; font-family: proxima-nova, sans-serif;                ',
      cssNameNext: 'background: linear-gradient(0deg, #15ace2 0%,  #0034f7 100%); color:#000220;',
      cssNameLoser: 'color:#ffffff4D; img{opacity: 0.3;}',
      cssNameWinner: '',

      cssVersus: 'font-size: 60px; background-color: #000220; color:#0034f7; margin-right: 0; margin-left: 0; font-weight:700; font-family: dharma-gothic-e, sans-serif;',
      cssVersusNext: 'background: linear-gradient(0deg, #15ace2 0%,  #0034f7 100%); color:#000220; margin-right: 0; margin-left: 0;',

      cssScore: 'color:#0034f7; background-color: #000220; font-size: 60px; font-weight:700; font-family: dharma-gothic-e, sans-serif;',
      cssScoreLoser: 'color:#0034f74d; font-size: 60px; ',
      cssScoreWinner: 'font-size: 60px; ',

      trimColorNext: 'transparent',
      rightBorderColor: '#ffffff;',
      rightBorderColorLoser: '',
      cssBorders: `
            border: none;
            :before {
                content: '';
                width: 13px;
                height: 100%;
                left: -13px;
                position: absolute;
                background: linear-gradient(0deg, #0034f7 0%, #15ace2 100%);
            }`,
    },
    screenTitle: {
      textColor: '#ffffff',
      cssTitle: "font-family: dharma-gothic-e, sans-serif; font-weight: 700; text-shadow: none; font-size: 130px; margin: auto; letter-spacing: 2px; ",
      textShadowLeft: '',
      textShadowRight: '',
    },
    standings: {
      showSubtext: false,
      displayWinLossSingleColumn: true,
      hideHeader: true,
      cssCell: '',
      cssRankCell: '',
      cssSubtextCell: '',
      cssLogoCell: '',
      cssNameCell: '',
      cssScoreCell: '',
      cssHeaderCell: '',
    },
  },
};
