// REACT
import React, { Component } from 'react';
// INTERNAL
// import _ from 'lodash';
import { subscribe } from '../../../services/api';
import ControlContainer from '../../Controller/ControlContainer';
import Button from '../../Button';
import validateDataSchedule from '../../Data/DataSchedule/validate';
import { i18n } from '../../i18n';
import { updateScreenStatus } from '../../../util/controllerUtils';
import PropTypes from 'prop-types';
class MatchPlayerControlV2 extends Component {
  state = {
    status: 'ready',
  };
  static propTypes = {
    cgpc: PropTypes.string,
    display: PropTypes.bool,
    screen1: PropTypes.bool,
  };

  componentDidMount() {
    this.runSubscription();
    if (validateDataSchedule()) {
      this.setState({ status: 'ready' });
    } else {
      this.setState({ status: 'invalid' });
    }
  }

  runSubscription = () => {
    subscribe('control-matchPlayerV2', null, data => {
      if (data.target === 'control-matchPlayerV2') {
        const resultState = {
          ...data,
          ...data.data,
        };
        delete resultState.data;
        this.setState(resultState);
      }
    }, this.props.cgpc);
  };

  onPlay = () => {
    // check data
    if (validateDataSchedule()) {
      const schedule = window.MainData.schedule;
      const teams = window.MainData.teams;

      const match = schedule.rounds[schedule.round].matches[schedule.match];
      const winner = match.team100.score > match.team200.score ? match.team100 : match.team200;
      const roster = match.games[schedule.game || 0].teams[winner.tricode].roster;

      const team = teams[winner.tricode];
      const imagePlayerArr = Object.values(winner.roster);
      const players = [
        {
          roster: {
            top: {
              sortIndex: 0,
              name: `${winner.tricode}${roster.top.name}`,
              image: imagePlayerArr.find(player => player.position === 'top').images.voting,
            },
            jungle: {
              sortIndex: 1,
              name: `${winner.tricode}${roster.jungle.name}`,
              image: imagePlayerArr.find(player => player.position === 'jungle').images.voting,
            },
            mid: {
              sortIndex: 2,
              name: `${winner.tricode}${roster.mid.name}`,
              image: imagePlayerArr.find(player => player.position === 'mid').images.voting,
            },
            adc: {
              sortIndex: 3,
              name: `${winner.tricode}${roster.adc.name}`,
              image: imagePlayerArr.find(player => player.position === 'adc').images.voting,
            },
            support: {
              sortIndex: 4,
              name: `${winner.tricode}${roster.support.name}`,
              image: imagePlayerArr.find(player => player.position === 'support').images.voting,
            },
          },
        },
      ];

      const sendData = {
        teamMainColor: team.color.main,
        teamSubColor: team.color.sub,
        BGTeamImage: team.images.logoBGPlayerOfTheMatch,
        players,
        preloadImages: players.map(player => player.image).filter(image => image),
      };
      // send('event', { target: 'view', action: 'open', screen: 'matchPlayer', data: sendData });
      updateScreenStatus('matchPlayerV2', 'open', sendData, null, this.props.cgpc);
    } else {
      this.setState({ status: 'invalid' });
    }
  };

  onClose = () => {
    // send('event', { target: 'view', action: 'close', screen: 'matchPlayer' });
    updateScreenStatus('matchPlayerV2', 'close', null, null, this.props.cgpc);
  };

  render() {
    return (
      <ControlContainer style={{display: this.props.display ? 'none': 'block'}} className="control--match-player" status={this.state.status}>
        <div className="control__panel">
          <h2 className="control__title">
            <i className="fas fa-child"></i> {i18n().pages.matchPlayerV2}
          </h2>
          <Button
          controllerSide={!this.props.screen1}
            action={this.onPlay}
            text={i18n().general.open}
            icon="fas fa-play-circle"
            disabled={(this.state.status === 'open' || this.state.status === 'opening') && true}
          />
          <Button
          controllerSide={!this.props.screen1}
            action={this.onClose}
            text={i18n().general.close}
            icon="fas fa-stop-circle"
            disabled={!(this.state.status === 'open' || this.state.status === 'opening') && true}
          />
        </div>
      </ControlContainer>
    );
  }
}

export default MatchPlayerControlV2;
