"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.VoteContainer = exports.Title = exports.StartersContainer = exports.Starters = exports.StarterContainer = exports.PositionsContainer = exports.PositionsBase = exports.PositionIcon = exports.PlayerImage = exports.PlayerContainer = exports.Player = exports.NameDisplayContainer = exports.HeaderTitle = exports.Header = exports.Container = exports.Base = void 0;

var _styledComponents = _interopRequireDefault(require("styled-components"));

var _reactPose = _interopRequireDefault(require("react-pose"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Base = _styledComponents.default.div.withConfig({
  displayName: "styles__Base",
  componentId: "sc-bt2hin-0"
})(["height:1080px;width:1920px;display:flex;align-content:center;background-size:contain;font-family:inherit;"]);

exports.Base = Base;

var Container = _styledComponents.default.div.withConfig({
  displayName: "styles__Container",
  componentId: "sc-bt2hin-1"
})(["overflow:hidden;font-weight:700;"]);

exports.Container = Container;

var TitleAnimation = _reactPose.default.span({
  normal: {
    'letter-spacing': '12px',
    'text-indent': '12px',
    transition: {
      duration: 400
    }
  },
  expanding: {
    'letter-spacing': '48px',
    'text-indent': '48px',
    transition: {
      duration: 2000
    }
  }
});

var Title = (0, _styledComponents.default)(TitleAnimation).withConfig({
  displayName: "styles__Title",
  componentId: "sc-bt2hin-2"
})(["display:inline-block;letter-spacing:4px;text-shadow:-6px 0px 1px ", ",6px 0px 1px ", ";"], function (props) {
  return props.colorMain;
}, function (props) {
  return props.colorSub;
});
exports.Title = Title;

var HeaderTitleAnimation = _reactPose.default.div({
  initialPose: 'hidden',
  visible: {
    opacity: 1,
    y: '0%',
    transition: {
      duration: 700
    }
  },
  hidden: {
    opacity: 1,
    y: '120%',
    transition: {
      duration: 700
    }
  }
});

var HeaderTitle = (0, _styledComponents.default)(HeaderTitleAnimation).withConfig({
  displayName: "styles__HeaderTitle",
  componentId: "sc-bt2hin-3"
})([""]);
exports.HeaderTitle = HeaderTitle;

var Header = _styledComponents.default.div.withConfig({
  displayName: "styles__Header",
  componentId: "sc-bt2hin-4"
})(["font-size:84pt;text-align:center;overflow:hidden;height:160px;display:flex;padding-top:0px;color:white;justify-content:center;align-items:center;"]);

exports.Header = Header;

var PositionsBase = _styledComponents.default.div.withConfig({
  displayName: "styles__PositionsBase",
  componentId: "sc-bt2hin-5"
})(["margin-bottom:30px;"]);

exports.PositionsBase = PositionsBase;

var PositionsContainerAnimation = _reactPose.default.div({
  initialPose: 'hidden',
  hidden: {
    opacity: 0,
    y: '150%'
  },
  visible: {
    opacity: 1,
    y: '0%',
    transition: {
      duration: 700
    }
  },
  display: {
    opacity: 0,
    y: '150%',
    transition: {
      duration: 700
    }
  }
});

var PositionsContainer = (0, _styledComponents.default)(PositionsContainerAnimation).withConfig({
  displayName: "styles__PositionsContainer",
  componentId: "sc-bt2hin-6"
})(["display:flex;flex-direction:row;"]);
exports.PositionsContainer = PositionsContainer;
var VoteContainer = (0, _styledComponents.default)(PositionsContainerAnimation).withConfig({
  displayName: "styles__VoteContainer",
  componentId: "sc-bt2hin-7"
})(["width:100%;font-size:55px;display:flex;justify-content:center;text-transform:uppercase;justify-content:center;align-items:center;margin-bottom:30px;color:#795b18;letter-spacing:8px;svg{margin-right:5px;}"]);
exports.VoteContainer = VoteContainer;

var StartersContainer = _styledComponents.default.div.withConfig({
  displayName: "styles__StartersContainer",
  componentId: "sc-bt2hin-8"
})(["position:relative;"]);

exports.StartersContainer = StartersContainer;

var Starters = _styledComponents.default.div.withConfig({
  displayName: "styles__Starters",
  componentId: "sc-bt2hin-9"
})(["display:flex;flex-direction:row;justify-content:flex-start;position:relative;"]);

exports.Starters = Starters;

var PositionIcon = _styledComponents.default.div.withConfig({
  displayName: "styles__PositionIcon",
  componentId: "sc-bt2hin-10"
})(["width:100%;text-align:center;display:flex;justify-content:center;height:67px;"]);

exports.PositionIcon = PositionIcon;

var PlayerContainerAnimation = _reactPose.default.div({
  initialPose: 'hidden',
  hidden: {
    width: '0%',
    transition: {
      duration: 500
    }
  },
  visible: {
    width: '100%',
    transition: {
      duration: 500
    }
  }
});

var PlayerContainer = (0, _styledComponents.default)(PlayerContainerAnimation).withConfig({
  displayName: "styles__PlayerContainer",
  componentId: "sc-bt2hin-11"
})(["position:relative;z-index:", ";max-width:385px;overflow:hidden;position:absolute;left:0;"], function (props) {
  return props.index * 10;
});
exports.PlayerContainer = PlayerContainer;

var Player = _styledComponents.default.div.withConfig({
  displayName: "styles__Player",
  componentId: "sc-bt2hin-12"
})(["background-image:", ";background-color:", ";background-blend-mode:multiply;border-left:", ";border-right:", ";background-position-x:", ";height:600px;overflow:hidden;"], function (props) {
  return props.backgroundImage ? "url(".concat(props.backgroundImage, ")") : 'none';
}, function (props) {
  return props.teamMainColor ? props.teamMainColor : 'none';
}, function (props) {
  return props.leftBorder ? 'solid 5px #0b1328' : 'none';
}, function (props) {
  return props.rightBorder ? 'solid 5px #0b1328' : 'none';
}, function (props) {
  return props.xPosition ? "".concat(props.xPosition, "px") : 'none';
});

exports.Player = Player;

var PlayerImage = _styledComponents.default.img.withConfig({
  displayName: "styles__PlayerImage",
  componentId: "sc-bt2hin-13"
})(["transform:translate(-155px,-400px);max-width:none;"]);

exports.PlayerImage = PlayerImage;

var StarterContainer = _styledComponents.default.div.withConfig({
  displayName: "styles__StarterContainer",
  componentId: "sc-bt2hin-14"
})(["width:385px;display:flex;justify-content:center;position:relative;"]);

exports.StarterContainer = StarterContainer;

var NameDisplayContainer = _styledComponents.default.div.withConfig({
  displayName: "styles__NameDisplayContainer",
  componentId: "sc-bt2hin-15"
})(["z-index:100;transform:translateY(330px);"]);

exports.NameDisplayContainer = NameDisplayContainer;