// REACT
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { get, round } from 'lodash';
// internal
import Button from '../../../Button';
import {i18n} from '../../../i18n';
import Picker from '../../../Picker';
import StatPicker from '../../../Picker/StatPicker'
import { getInfoForPlayerInPosition, getInfoForTeam, statsDictionary } from '../../../../services/keyPlayerService';
import { validLeaderboardStats, getPlayerRank } from '../../../../services/leaderboardService';
import ChampSelect from '../../../ChampSelect';
import { StyleSeparator } from '../../DataGameTimers/styles';
class DataKeyPlayersPlayer extends Component {

    initialState = () => {
        return ({
            name: '',
            stats: {
                champs: ['', '', ''],
                stat0Value: '',
                stat0Rank: '',
                stat0Name: 'damageToChampionsPerMin',
                stat1Value: '',
                stat1Rank: '',
                stat1Name: 'kda',
                stat2Value: '',
                stat2Rank: '',
                stat2Name: 'killParticipation',
                all: {},
            },
            teamStats: '',
            position: '',
        })
    };

    state = this.initialState();

    static propTypes = {
        team: PropTypes.string,
        side: PropTypes.string,
        // statsSelection: PropTypes.array,
        // onStatsSelectionChange: PropTypes.func,
    };

    componentDidMount(){
        window.MainData.keyPlayers[`player${this.props.side}`].stats = this.initialState().stats;
    }

   async UNSAFE_componentWillReceiveProps(nextProps) {
        if((nextProps.team !== this.props.team)){
            await this.resetState();
            await this.loadTeamStats(nextProps.team);
        }
        // if (!isEqual(nextProps.statsSelection, this.props.statsSelection)) {
        //     const stats = { ...this.state.stats };
        //     if (nextProps.statsSelection[0] !== this.props.statsSelection[0]) {
        //         stats.stat0 = await this.getStatValue(stats.all, nextProps.statsSelection[0]);
        //         stats.stat0Rank = await this.getRank(nextProps.statsSelection[0]);
        //     }
        //     if (nextProps.statsSelection[1] !== this.props.statsSelection[1]) {
        //         stats.stat1 = await this.getStatValue(stats.all, nextProps.statsSelection[1]);
        //         stats.stat1Rank = await this.getRank(nextProps.statsSelection[1]);
        //     }
        //     if (nextProps.statsSelection[2] !== this.props.statsSelection[2]) {
        //         stats.stat2 = await this.getStatValue(stats.all, nextProps.statsSelection[2]);
        //         stats.stat2Rank = await this.getRank(nextProps.statsSelection[2]);
        //     }
        //     this.setState({ stats }, ()=>{ window.MainData.keyPlayers[`player${this.props.side}`].stats = this.state.stats});
        // }
    }

    resetState = async () => {
        await this.setState(this.initialState());
        window.MainData.keyPlayers[`player${this.props.side}`] = await this.initialState();
    }

    handleStatSelection = async (statNumber, statsName) => {
        const stats = { ...this.state.stats };
        stats[`stat${statNumber}Name`] = statsName;
        stats[`stat${statNumber}Value`] = await this.getStatValue(stats.all, statsName);
        stats[`stat${statNumber}Rank`] = await this.getRank(statsName);
        this.setState({ stats }, ()=>{
            window.MainData.keyPlayers[`player${this.props.side}`].stats = this.state.stats
        });
    }

    getStatValue = async (allStats, requestedStats)=>{
        if(!allStats){return 0;}
        switch(requestedStats){
            case 'KP@15':
                if(this.state.teamStats){
                    return Math.round(((Number(allStats['assistsAt15']) + Number(allStats['killsAt15'])) / Number(this.state.teamStats['killsAt15']))*100);
                }
                break;
            case 'K+A@15':
                return (Number(allStats['assistsAt15PerGame']) + Number(allStats['killsAt15PerGame']));
            case 'VS/GAME':
                return round(Number(allStats['visionScore']) / allStats['gameCount'], 1);
            case 'VS/MIN':
                return round(Number(allStats['visionScore']) / (allStats['timePlayed']/ 60), 1);
            default:
                return get(allStats, `${requestedStats}`, 0);
        }
    }

    addOrdinalSuffix = (number) => {
        const lastDigit = `${number}`[`${number}`.length -1];
        if(['11','12','13'].includes(number)){
            return `${number}th`;
        }
        switch(lastDigit){
            case '1':
                return `${number}st`;
            case '2':
                return `${number}nd`;
            case '3':
                return `${number}rd`;
            default:
                return `${number}th`;
        }
    }

    getRank = async (stat) => {
        if(Object.keys(validLeaderboardStats).includes(stat)){
            let rank = await getPlayerRank(
                window.MainData.schedule.season,
                window.MainData.schedule.league,
                window.MainData.schedule.split,
                stat,
                this.state.name,
                this.state.position
            ).catch(e => {console.log(e)})
            if(rank === -1){
                return 0;
            }
            return this.addOrdinalSuffix(rank+1);
        }
        return 0;
    }

    loadTeamStats = async (team) => {
        return getInfoForTeam(
            window.MainData.schedule.season,
            window.MainData.schedule.league,
            window.MainData.schedule.split,
            team
        )
            .then(allData => {
                if(allData && allData.stats){
                    this.setState({teamStats: allData.stats});
                }
            })
            .catch(e => {console.log(e)});
    }

    loadPlayerData = () => {
        if (window.MainData.teams[this.props.team].roster[this.state.name]) {
            const position = window.MainData.teams[this.props.team].roster[this.state.name].position;
            const season = window.MainData.schedule.season;
            const league = window.MainData.schedule.league;
            const split = window.MainData.schedule.split;
            const team = this.props.team;
            const summonerName = this.state.name;
            getInfoForPlayerInPosition(season, league, split, summonerName, team, position)
                .then(async allData => {
                    if (!allData || !allData.stats) return;
                    console.log("Stats allData", allData);
                    if(!this.state.teamStats){
                        await this.loadTeamStats(this.props.team);
                    }
                    const stat0Value = await this.getStatValue(allData.stats, this.state.stats.stat0Name);
                    const stat0Rank = await this.getRank(this.state.stats.stat0Name);
                    const stat1Value = await this.getStatValue(allData.stats, this.state.stats.stat1Name);
                    const stat1Rank = await this.getRank(this.state.stats.stat1Name);
                    const stat2Value = await this.getStatValue(allData.stats, this.state.stats.stat2Name);
                    const stat2Rank = await this.getRank(this.state.stats.stat2Name);
                    const all = allData.stats;
                    const champs = Object.keys(allData.stats.championId).map(champ => ({ key: champ, count: allData.stats.championId[champ] })).sort((a, b) => b.count - a.count).map(c => c.key);
                    const stats = { all, stat0Name:this.state.stats.stat0Name, stat0Value, stat0Rank, stat1Name:this.state.stats.stat1Name, stat1Value, stat1Rank, stat2Name:this.state.stats.stat2Name, stat2Value, stat2Rank, champs };
                    this.setState({ stats });
                    window.MainData.keyPlayers[`player${this.props.side}`].stats = stats;
                })
                .catch(e => {console.log(e)});
        }
    }

    updatePlayer = player => {
        const stats = this.initialState().stats;
        stats.stat0Name = this.state.stats.stat0Name;
        stats.stat1Name = this.state.stats.stat1Name;
        stats.stat2Name = this.state.stats.stat2Name;
        const position = window.MainData.teams[this.props.team].roster[player].position;
        this.setState({name: player || '', stats, position});
        window.MainData.keyPlayers[`player${this.props.side}`].name = player;
        window.MainData.keyPlayers[`player${this.props.side}`].team = this.props.team;
        window.MainData.keyPlayers[`player${this.props.side}`].position = position;
    }

    updateStat = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        const stats = this.state.stats;
        stats[`${name}Value`] = value;
        this.setState({stats});
        window.MainData.keyPlayers[`player${this.props.side}`].stats[`${name}Value`] = value;
    }

    setChamp = (ref, id) => {
        const stats = Object.assign({}, this.state.stats);
        stats.champs[ref] = id;
        this.setState({stats});
        this.state.name && (window.MainData.keyPlayers[`player${this.props.side}`].stats.champs[ref] = id);
    }

    getStatsList = () => {
        return(
            Object.keys(statsDictionary)
            .map( statKey =>{
                return ({
                    value: statsDictionary[statKey],
                    name: statKey,
                    })
                })
        );
    }

    render () {
        const players = window.MainData.teams[this.props.team] && Object.keys(window.MainData.teams[this.props.team].roster).length ? window.MainData.teams[this.props.team].roster : {};
        const teamPlayers = Object.keys(players).map(player => ({ name: `${player} - ${players[player].position}`, value: player })) || [];
        const selected = this.state.name !== '' ? this.state.name : '';
        return (
            <div className={`game-sides game-sides--${this.props.side}`} style={{padding: '10px 5px', margin: '-20px 0'}}>
                <div className="row">
                    <div className="pill">
                        <span>{i18n().general.player}</span>
                        <Picker items={teamPlayers} preselect={selected} onUpdate={this.updatePlayer} />
                        <Button action={this.loadPlayerData} text={i18n().general.load} />
                    </div>
                </div>
                <StyleSeparator />
                <StyleTitle>Stats</StyleTitle>
                <StyleGridStats className="row">
                    <StyleColumn className="column">
                        <StyleStatsSelect className="pill">
                            {/* {this.props.onStatsSelectionChange ? */}
                                <StatPicker
                                    disabled
                                    onChange={(stat) => {
                                        // this.props.onStatsSelectionChange(0, stat);
                                        this.handleStatSelection(0, stat);
                                    }}
                                    defaultValue={this.state.stats.stat0Name}
                                    showSelectOption={false}
                                    statsList={this.getStatsList()}
                                />
                                {/* :
                                <span>{findKey(statsDictionary, val => (val===this.props.statsSelection[0])) || 'DPM'}</span> */}
                            {/* } */}
                        </StyleStatsSelect>
                        <StyleStatsInput className="pill">
                            <input
                            style={{width: '60px'}}
                                name="stat0"
                                className={`input input--large ${!this.state.name ? 'input--disabled' : ''}`}
                                value={this.state.stats.stat0Value || ''}
                                type="text"
                                onChange={
                                    (e) => {
                                        e.preventDefault();
                                        this.updateStat(e);
                                    }}
                            />
                        </StyleStatsInput>
                        {(this.state.stats.stat0Rank && <StyleStatsInput style={{width: '40px'}} className="pill">
                            <span style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>{this.state.stats.stat0Rank}</span>
                        </StyleStatsInput>) || null}
                    </StyleColumn>
                    <StyleColumn className="column" >
                        <StyleStatsSelect className="pill">
                            {/* {this.props.onStatsSelectionChange ? */}
                                <StatPicker
                                    onChange={(stat) => {
                                        // this.props.onStatsSelectionChange(1, stat);
                                        this.handleStatSelection(1, stat);
                                    }}
                                    defaultValue={this.state.stats.stat1Name}
                                    showSelectOption={false}
                                    statsList={this.getStatsList()}
                                />
                                {/* :
                                <span>{findKey(statsDictionary, val => (val===this.props.statsSelection[1])) || 'KDA'}</span>
                            } */}

                        </StyleStatsSelect>
                        <StyleStatsInput className="pill">
                            <input
                            style={{width: '60px'}}
                                name="stat1"
                                className={`input input--large ${!this.state.name ? 'input--disabled' : ''}`}
                                value={this.state.stats.stat1Value ||''}
                                type="text"
                                onChange={
                                    (e) => {
                                        e.preventDefault();
                                        this.updateStat(e);
                                    }}
                            />
                        </StyleStatsInput>
                        {(this.state.stats.stat1Rank && <StyleStatsInput style={{width: '40px'}} className="pill">
                            <span style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>{this.state.stats.stat1Rank}</span>
                        </StyleStatsInput>) || null}
                    </StyleColumn>
                    <StyleColumn className="column" >
                        <StyleStatsSelect className="pill">
                            {/* {this.props.onStatsSelectionChange ? */}
                                <StatPicker
                                    onChange={(stat) => {
                                        // this.props.onStatsSelectionChange(2, stat);
                                        this.handleStatSelection(2, stat);
                                    }}
                                    defaultValue={this.state.stats.stats2Name}
                                    showSelectOption={false}
                                    statsList={this.getStatsList()}
                                />
                                {/* :
                                <span>{findKey(statsDictionary, val => (val===this.props.statsSelection[2])) || 'KP%'}</span>
                            } */}
                        </StyleStatsSelect>
                        <StyleStatsInput className="pill">
                            <input
                                style={{width: '60px'}}
                                name="stat2"
                                className={`input input--large ${!this.state.name ? 'input--disabled' : ''}`}
                                value={this.state.stats.stat2Value ||''}
                                type="text"
                                onChange={
                                    (e) => {
                                        e.preventDefault();
                                        this.updateStat(e);
                                    }}
                            />
                        </StyleStatsInput>
                        {(this.state.stats.stat2Rank && <StyleStatsInput style={{width: '40px'}} className="pill">
                            <span style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>{this.state.stats.stat2Rank}</span>
                        </StyleStatsInput>) || null}
                    </StyleColumn>
                </StyleGridStats>
                <StyleSeparator />
                <StyleTitle>Champions</StyleTitle>
                <StyleGridStats className="row">
                    <div className="pill">
                        <ChampSelect assignRef={'0'} onChampSelect={this.setChamp} selected={this.state.stats.champs[0]} disabled={!this.state.name ? true : false}/>
                    </div>
                    <div className="pill">
                        <ChampSelect assignRef={'1'} onChampSelect={this.setChamp} selected={this.state.stats.champs[1]} disabled={!this.state.name ? true : false}/>
                    </div>
                    <div className="pill">
                        <ChampSelect assignRef={'2'} onChampSelect={this.setChamp} selected={this.state.stats.champs[2]} disabled={!this.state.name ? true : false}/>
                    </div>
                </StyleGridStats>
            </div>
        );

    }
}

const StyleTitle = styled.div`
    text-align: center;
    font-size: 17px;
    color: #EFEFEF;
    margin-top: -7px;
    margin-bottom: 5px;
`;

const StyleColumn = styled.div`
    width: 100%;
    input, span {
        width: 100%;
    }
`;

const StyleGridStats = styled.div`
    display: grid;
    // grid-template-columns: repeat(3, 1fr);
    text-align: center;
    display: flex;
    flex-wrap: wrap;
`;

const StyleStatsSelect = styled.div`
    select, span {
        white-space: wrap;
        padding: 0 4px;
        width: 100px;
        text-align-last: center;
        overflow: hidden;
    }
`;
const StyleStatsInput = styled.div`
    input {
        padding: 0;
        max-width: 90px;
        text-align: center;
    }
`;

export default DataKeyPlayersPlayer
