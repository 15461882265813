"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.profilewide_fallback = exports.profilesquare_fallback = exports.fullbody_fallback = void 0;
var fullbody_fallback = 'https://cdn.dekki.com/uploads/players/_default/fullbody.png';
exports.fullbody_fallback = fullbody_fallback;
var profilesquare_fallback = 'https://cdn.dekki.com/uploads/players/_default/profilesquare.png';
exports.profilesquare_fallback = profilesquare_fallback;
var profilewide_fallback = 'https://cdn.dekki.com/uploads/players/_default/profilewide.png';
exports.profilewide_fallback = profilewide_fallback;