// 3RD PARTY
import {
    filter,
    maxBy,
} from 'lodash';
import moment from 'moment';
// OURS
import allChamps from './championService';

const getGameTime = (timePlayed) => {
    const duration = moment.duration(timePlayed, 'seconds');
    const min = Math.floor(duration.asMinutes()); // including mins over 1 hour
    const sec = duration.seconds();

    const gameTime = `${min < 10 ? '0' : ''}${min}:${sec < 10 ? '0' : ''}${sec}`;

    return gameTime;
};

const isWinner = (p) => {
    return p && ((p.win === "Win") || (p.win === "LeaverWin"));
};

const getTeamFromSummonerName = playername => {
    return playername.split(' ')[0];
}

const getGameStatsFromJson = (jsonData) => {
    const {
        gameStats,
        participants,
    } = jsonData;
    const gameStatData = {};

    // Use timePlayed instead of absolute time to remove pauses, etc.
    // DC/AFK players would have less time played, so we want the longest available
    const timePlayed = maxBy(participants, (p) => {
        return p.timePlayed;
    }).timePlayed;
    const gameTime = getGameTime(timePlayed);

    gameStatData.gameTime = gameTime;

    const winners = filter(participants, p => isWinner(p));
    const losers = filter(participants, p => !isWinner(p));

    const winningTeamStats = filter(gameStats.teamStats, g => g.win === "Win");
    const losingTeamStats = filter(gameStats.teamStats, g => g.win !== "Win");

    const winningTeam = getTeamFromSummonerName(winners[0].summonerName).toLowerCase();
    const losingTeam = getTeamFromSummonerName(losers[0].summonerName).toLowerCase();

    const winningGold = winners.reduce((prev, curr) => {
        return prev + curr.goldEarned;
    }, 0);

    const winningKills = winners.reduce((prev, curr) => {
        return prev + curr.kills;
    }, 0);

    const winningDeaths = winners.reduce((prev, curr) => {
        return prev + curr.deaths;
    }, 0);

    const winningAssists = winners.reduce((prev, curr) => {
        return prev + curr.assists;
    }, 0);

    const winningDamage = winners.reduce((prev, curr) => {
        return prev + curr.totalDamageDealtToChampions;
    }, 0);

    const maxDamage = participants.reduce((prev, curr) => {
        return Math.max(prev, curr.totalDamageDealtToChampions);
    }, 0);

    const winningTowerKills = winningTeamStats[0].towerKills;
    const winningBaronKills = winningTeamStats[0].baronKills;
    const winningDragonKills = winningTeamStats[0].dragonKills;

    const losingGold = losers.reduce((prev, curr) => {
        return prev + curr.goldEarned;
    }, 0);

    const losingKills = losers.reduce((prev, curr) => {
        return prev + curr.kills;
    }, 0);

    const losingDeaths = losers.reduce((prev, curr) => {
        return prev + curr.deaths;
    }, 0);

    const losingAssists = losers.reduce((prev, curr) => {
        return prev + curr.assists;
    }, 0);

    const losingDamage = losers.reduce((prev, curr) => {
        return prev + curr.totalDamageDealtToChampions;
    }, 0);

    const losingTowerKills = losingTeamStats[0].towerKills;
    const losingBaronKills = losingTeamStats[0].baronKills;
    const losingDragonKills = losingTeamStats[0].dragonKills;

    gameStatData[winningTeam] = {};
    gameStatData[losingTeam] = {};

    gameStatData[winningTeam].players = winners.map(w => {
        return {
            player: w.summonerName.toLowerCase().split(' ').join('-'),
            damage: w.totalDamageDealtToChampions,
            championKey: w.championId,
            championId: allChamps[w.championId].id,
        }
    });
    gameStatData[winningTeam].gold = winningGold;
    gameStatData[winningTeam].kills = winningKills;
    gameStatData[winningTeam].deaths = winningDeaths;
    gameStatData[winningTeam].assists = winningAssists;
    gameStatData[winningTeam].towerKills = winningTowerKills;
    gameStatData[winningTeam].baronKills = winningBaronKills;
    gameStatData[winningTeam].dragonKills = winningDragonKills;
    gameStatData[winningTeam].totalDamage = winningDamage;
    gameStatData[winningTeam].maxDamage = maxDamage;
    gameStatData[winningTeam].win = true;

    gameStatData[losingTeam].players = losers.map(l => {
        return {
            player: l.summonerName.toLowerCase().split(' ').join('-'),
            damage: l.totalDamageDealtToChampions,
            championKey: l.championId,
            championId: allChamps[l.championId].id,
        }
    });
    gameStatData[losingTeam].gold = losingGold;
    gameStatData[losingTeam].kills = losingKills;
    gameStatData[losingTeam].deaths = losingDeaths;
    gameStatData[losingTeam].assists = losingAssists;
    gameStatData[losingTeam].towerKills = losingTowerKills;
    gameStatData[losingTeam].baronKills = losingBaronKills;
    gameStatData[losingTeam].dragonKills = losingDragonKills;
    gameStatData[losingTeam].totalDamage = losingDamage;
    gameStatData[losingTeam].maxDamage = maxDamage;
    gameStatData[losingTeam].win = false;

    return gameStatData;
};

const mapChampion = (champion, champList) => {
    const champId = champion.championId
    const championFromList = champList[champId];
    if (championFromList && championFromList.id) {
        return championFromList.id;
    } else {
        return null;
    }
};

const getBanInfo = (gameInfo, champList) => {
    const banList = gameInfo.bannedChampions;
    const banList100 = banList.filter(banInfo => banInfo.teamId === 100)
        .map(champion => mapChampion(champion, champList))
        .filter(c => c); // remove null
    const banList200 = banList.filter(banInfo => banInfo.teamId === 200)
        .map(champion => mapChampion(champion, champList))
        .filter(c => c); // remove null

    return {
        team100Bans: banList100,
        team200Bans: banList200,
    }
};

const getPickInfo = (participants, champList) => {
    const pickList100 = participants.filter(p => p.teamId === 100)
        .map(champion => mapChampion(champion, champList))
        .filter(c => c); // remove null
    const pickList200 = participants.filter(p => p.teamId === 200)
        .map(champion => mapChampion(champion, champList))
        .filter(c => c); // remove null

    return {
        team100Picks: pickList100,
        team200Picks: pickList200,
    }
};

const getTeamNames = (participants) => {
    const ret = {
        team100Name: '',
        team200Name: '',
    }
    if (!participants || !participants.length) return ret;

    const team100Participants = participants.filter(p => p.teamId === 100);
    const team200Participants = participants.filter(p => p.teamId === 200);

    if (!team100Participants.length || !team200Participants.length) return ret;

    ret.team100Name = getTeamFromSummonerName(team100Participants[0].summonerName);
    ret.team200Name = getTeamFromSummonerName(team200Participants[0].summonerName);

    return ret;
};

export {
    getGameStatsFromJson,
    getBanInfo,
    getPickInfo,
    getTeamNames,
};
