"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StyleMain = exports.StyleItemsListContainer = exports.StyleItem = exports.StyleDivider = exports.StyleChampionIcon = exports.StyleArrowItem = void 0;

var _styledComponents = _interopRequireDefault(require("styled-components"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var StyleMain = _styledComponents.default.div.withConfig({
  displayName: "styles__StyleMain",
  componentId: "sc-e3v6c0-0"
})(["display:grid;width:100%;height:100%;grid-template-columns:200px auto;font-family:'futura-pt, sans-serif';"]);

exports.StyleMain = StyleMain;

var StyleChampionIcon = _styledComponents.default.div.withConfig({
  displayName: "styles__StyleChampionIcon",
  componentId: "sc-e3v6c0-1"
})(["margin:auto;img{width:100px;height:100px;}"]);

exports.StyleChampionIcon = StyleChampionIcon;

var StyleItemsListContainer = _styledComponents.default.div.withConfig({
  displayName: "styles__StyleItemsListContainer",
  componentId: "sc-e3v6c0-2"
})(["display:grid;grid-template-columns:repeat(17,65px);align-items:center;padding:20px 0;"]);

exports.StyleItemsListContainer = StyleItemsListContainer;

var StyleItem = _styledComponents.default.div.withConfig({
  displayName: "styles__StyleItem",
  componentId: "sc-e3v6c0-3"
})(["display:grid;margin-right:15px;align-items:center;height:auto;img{width:50px;height:50px;}p{width:100%;text-align:center;font-size:14px;color:#FFFFFF;margin-top:3px;font-family:aktiv-grotesk-medium;}", ";"], function (props) {
  return props.StyleItem ? props.StyleItem : '';
});

exports.StyleItem = StyleItem;

var StyleArrowItem = _styledComponents.default.div.withConfig({
  displayName: "styles__StyleArrowItem",
  componentId: "sc-e3v6c0-4"
})(["margin-left:15px;img{width:25px;height:25px;}"]);

exports.StyleArrowItem = StyleArrowItem;

var StyleDivider = _styledComponents.default.div.withConfig({
  displayName: "styles__StyleDivider",
  componentId: "sc-e3v6c0-5"
})(["height:1px;width:100%;background-color:#FFFFFF;grid-column-start:1;grid-column-end:18;"]);

exports.StyleDivider = StyleDivider;