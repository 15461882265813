// REACT
import React, { useState, useEffect } from 'react';
import { isEqual, get, cloneDeep } from 'lodash';
// internal
import { i18n } from '../../i18n';
import {
  StyleTelops,
  StyleTelopsSingle,
  // StyleTelopsTitle,
  // StyleField,
  // StyleFieldName,
  StyleFieldInput,
  StyleInput,
  StyleLabels,
  StyleLabel,
  StyleTelopsContainer,
  StyleUpdateContainer,
} from './styles';
import CreatableSelect from 'react-select/creatable';
import { getCreatableStyles } from '../DataMatch/DataMatchLineup';
import DataContainer from '../DataContainer';
import Button from '../../Button';
// import { firebaseRealtimeGetValue } from '../../../util/firebase';

export const telopsInputOptions1 = [
  { value: 'タケト', label: 'タケト', twitter: 'bkousutaketo' },
  { value: '清川麗奈', label: '清川麗奈', twitter: 'kiyokawa_reina' },
  { value: 'katsudion', label: 'katsudion', twitter: 'katsudion' },
  { value: 'wataneko', label: 'wataneko', twitter: 'LoLwataneko' },
  { value: 'Nemoh', label: 'Nemoh', twitter: 'zyakusyanemou' },
  { value: 'Jaeger', label: 'Jaeger', twitter: 'Jaeger0446' },
  { value: 'Recruit', label: 'Recruit', twitter: 'recruit_09' },
  { value: 'eyes', label: 'eyes', twitter: 'eyes1015' },
  { value: 'Revol', label: 'Revol', twitter: 'krevol' },
  { value: 'syouryu', label: 'syouryu', twitter: 'syouryu0717' },
  { value: 'Shinmori', label: 'Shinmori', twitter: 'shinmori_lol' },
];

const initialState = [
  { name: 'syouryu', twitter: 'syouryu0717', isDisplayed: true, isJapaneseCharacter: false },
  { name: 'Shinmori', twitter: 'shinmori_lol', isDisplayed: true, isJapaneseCharacter: false },
  { name: '清川麗奈', twitter: 'kiyokawa_reina', isDisplayed: false, isJapaneseCharacter: true },
  { name: 'タケト', twitter: 'bkousutaketo', isDisplayed: false, isJapaneseCharacter: true },
  // { name: 'katsudion', twitter: 'katsudion', isDisplayed: true, isJapaneseCharacter: false },
  // { name: 'Recruit', twitter: 'recruit_09', isDisplayed: true, isJapaneseCharacter: false }
];

const DataTelopsV2 = () => {
  const [telopsState, setTelopsState] = useState(initialState);
  const [changeState, setChangeState] = useState(false);


  const injectTellopsInputs = () => {
    window.MainData.telopsV2 = cloneDeep(telopsState);
    setChangeState(checkIsUpToDate())
  };

   useEffect(() => {
    window.MainData.telopsV2 = initialState;
    //  async function getResponse () {
    //    const response = await firebaseRealtimeGetValue('control-telopsV2')
    //    setTelopsState(get(response, 'data.list', initialState));
    //    window.MainData.telopsV2 = get(response, 'data.list', initialState);
    //  }
    //  getResponse()
  }, []);

  useEffect(() => {
    setChangeState(checkIsUpToDate());
  }, [telopsState]);

  const handleDisplayChange = (isDisplayed, telopIndex) => {
    const telops_temp = [...telopsState];
    telops_temp[telopIndex] = {
      ...telops_temp[telopIndex],
      isDisplayed : isDisplayed,
    }
    setTelopsState(telops_temp);
  }

  const checkIsUpToDate = () => {
    return isEqual(telopsState, window.MainData.telopsV2);
  }

  return (
    <DataContainer componentSlug="telops" icon="fas fa-headset" style={{ overflow: 'visible' }}>
      <div className="data-panel__fieldset" style={{ paddingTop: '10px', paddingBottom: '0' }}>
        <StyleTelops>
          <StyleTelopsContainer>
            <StyleLabels>
              <StyleLabel> {i18n().general.show}</StyleLabel>
              <StyleLabel> {i18n().general.name}</StyleLabel>
              <StyleLabel>Twitter (@)</StyleLabel>
            </StyleLabels>
            {telopsState.map((telop, telopIndex) => {
              return (
                <React.Fragment key={`telopFields${telopIndex}`}>
                  <StyleTelopsSingle>
                    <StyleFieldInput
                      className={'pill'}
                      isDisabled={
                        !(((telopsState.filter(telop => telop.isDisplayed).length) === telopIndex) ||
                        ((telopsState.filter(telop => telop.isDisplayed).length) === (telopIndex+1))) }
                    >
                      {(telopsState[telopIndex].isDisplayed) ?
                        [
                          <span key="1a" className="pill__span--highlighted">
                            {i18n().controller.viewable.true}
                          </span>,
                          <Button key="1b" text={i18n().general.hide} action={() => { handleDisplayChange(false, telopIndex) }} />,
                        ]
                        :
                        [
                          <span key="2a">{i18n().controller.viewable.false}</span>,
                          <Button key="2b" text={i18n().general.show} action={() => { handleDisplayChange(true, telopIndex) }} />,
                        ]
                      }
                    </StyleFieldInput>
                    <StyleFieldInput>
                      <CreatableSelect
                        isDisabled={ (telopsState.filter(telop => telop.isDisplayed).length) < telopIndex }
                        value={
                          (telop.name && {
                            value: telop.name,
                            label: telop.name,
                          }) ||
                          {}
                        }
                        options={telopsInputOptions1}
                        onChange={e => {
                          const name = (e && e.value) || '';
                          const twitter = (e && e.twitter) || '';
                          setTelopsState(
                            telopsInput1 =>
                              telopsInput1 &&
                              telopsInput1.length &&
                              telopsInput1.map((telopInput1, telopInput1Index) =>
                                telopInput1Index === telopIndex ? { ...telopInput1, name, twitter } : telopInput1,
                              ),
                          );
                        }}
                        styles={getCreatableStyles()}
                      />
                    </StyleFieldInput>

                    <StyleFieldInput>
                      <StyleInput
                        type={"text"}
                        value={telopsState[telopIndex].twitter || ''}
                        onChange={(e)=>{
                          e.persist();
                          setTelopsState(
                            telopsInput => {
                              const newTelop = cloneDeep(telopsInput);
                              newTelop[telopIndex].twitter = get(e,'target.value','');
                              return newTelop;
                            }
                          )
                        }}
                      />
                      {/* <CreatableSelect
                        isDisabled={ (telopsState.filter(telop => telop.isDisplayed).length) < telopIndex }
                        options={[]}
                        value={
                          (telop &&
                            telop.twitter && {
                              value: telop.twitter,
                              label: telop.twitter,
                              name: telop.name || '',
                            }) ||
                          {}
                        }
                        onChange={e => {
                          const twitter = (e && e.value) || '';
                          setTelopsState(
                            telopsInput2 =>
                              telopsInput2 &&
                              telopsInput2.length &&
                              telopsInput2.map((telopInput2, telopInput2Index) =>
                                telopInput2Index === telopIndex ? { ...telopInput2, twitter } : telopInput2,
                              ),
                          );
                        }}
                      /> */}
                    </StyleFieldInput>
                    {/* <StyleFieldInput>
                      <input
                        type="checkbox"
                        checked={(telop && telop.isJapaneseCharacter) || false}
                        disabled={ (telopsState.filter(telop => telop.isDisplayed).length) < telopIndex }
                        onChange={e => {
                          const isJapaneseCharacter = (e && e.target.checked) || false;
                          setTelopsState(
                            telopsInput =>
                              telopsInput &&
                              telopsInput.length &&
                              telopsInput.map((telopInput, telopInputIndex) =>
                                telopInputIndex === telopIndex ? { ...telopInput, isJapaneseCharacter } : telopInput,
                              ),
                          );
                        }}
                      />
                      {i18n().general.japaneseCharacters}
                    </StyleFieldInput> */}
                  </StyleTelopsSingle>
                </React.Fragment>
              );
            })}
          </StyleTelopsContainer>
          <StyleUpdateContainer>
            <Button disabled={changeState} action={injectTellopsInputs} text={'Update'} />
          </StyleUpdateContainer>
        </StyleTelops>
      </div>
    </DataContainer>
  );
};

export default DataTelopsV2;
