export default {
  id: 'worlds',
  name: 'Worlds',
  screens: {
    animatedBackground: {
      show: true,
      defaultBackground:
        'https://cdn.dekki.com/uploads/broadcast-projects/worlds/2019/videos/worlds2019_20s.webm',
        disableAnimationBackGround: false,
    },
    bottomBar: {
      cssBottomBar: 'border-image:linear-gradient(to right,#ffffff99,#1b1b1b,#ffffff99) 10',
      teamLogoColorBG: '#a7a7a766',
      borderColor: 'teamMain',
      borderColorBase: '',
      backgroundColor: '#1b1b1b',
      cssTeamName: 'font-family: United; color:#ffffff;',
      cssNextGameTitle: 'font-family: United; font-size: 28px; color: #dba103; margin-top: 15px;',
      cssTimer: 'font-family: United; font-weight: 800; font-size: 85px; color: #dba103;',
    },
    cornerLogo: {
      cornerLogoImage: 'https://cdn.dekki.com/uploads/broadcast-projects/worlds/2019/worlds2019_logo_topleft.png',
      cssCornerLogo: '',
      cssCornerLabelText: 'display:none;',
      startX: '-400px',
      endX: '-100px',
      startY: '0px',
      endY: '0px',
    },
    gameBreakdown: {
      backgroundImage: 'https://cdn.dekki.com/uploads/broadcast-projects/worlds/2019/worlds2019_GameBreakdown_BG.jpg',
      teamStats: {
        cssTeamName: "font-family: 'United'; font-weight: 700; letter-spacing: 1px; color:#ab8652; background-color: #1b1b1b;",
        cssTeamStats: "font-family: 'United', sans-serif; ",
        teamStatsHeaderBGColor: "#1b1b1b",
        cssWinTag: 'background-color: #ab8652aa; color: #ffffff; ',
        cssLossTag: 'background-color: #ab865233; color: #ffffffcc; ',
        cssBansPicks: 'color: #ffffff;',
        teamStatsBGColor: "#1b1b1b99",
        teamStatsBGTexture: '',
      },
      key: {
        cssHeader: "font-family: 'United';",
        cssTitles: "font-family: 'United'; color:#ab8652; font-weight: 400;",
      },
      graph: {
        cssHeader:
          "font-family: 'United';  font-weight: 400; letter-spacing: 5px; color:#ab8652;  background-color: #1b1b1b; background-image: url('');",
        cssContent: "font-family: 'United'; background-color: #1b1b1b99; background-image: url('');",
        cssKey: "font-family: 'United'; color:#ab8652;",
      },
    },
    schedule: {
      cssRow: "font-family:'United';font-weight: 700; color:#1b1b1b; border-right: 8px solid #a7a7a7;",
      cssRowNext: 'color:#dba103; background-color:#a7a7a7; border-right: unset;',
      cssRowFinished: '',

      cssDate: 'background-color: #a7a7a7; padding-top: 10px; color: #ffffff;',
      cssDateFinished: 'background-color: #454545; color:#151515;',
      cssDateNext: 'background-color: #1b1b1b; color:#dba103;',

      cssLogo: 'background-color: #151515;',
      cssLogoNext: '',
      cssLogoLoser: '',
      cssLogoWinner: '',

      cssName: 'padding-top: 10px; font-size: 46px; background-color:#a7a7a7; color: #fff;',
      cssNameNext: 'background-color: #151515; color: #dba103;',
      cssNameLoser: 'background-color: #454545; color: #1b1b1b;',
      cssNameWinner: 'background-color: #a7a7a7; color: #1b1b1b;',

      cssVersus: 'padding-top: 10px; font-size: 50px; background-color: #a7a7a7; color: #ffffff;',
      cssVersusNext: 'padding-top: 10px; font-size: 50px; background-color: #1b1b1b; color: #dba103; margin-right: -1px; margin-left: -1px;',

      cssScore: ' padding-top: 10px; ',
      cssScoreLoser: 'background-color: #454545;',
      cssScoreWinner: 'background-color: #a7a7a7;',
      cssScoreNext: '',

      textColorDate: '#1b1b1b',
      textColorFeatureDate: '#dba103',
      trimColorNext: '#dba103',

      backgroundColorRow: '#af8806',
      backgroundColorFeature: '#1b1b1b',

      separatorColor: '#1b1b1b',
      speedPanel: 200,
    },
    screenTitle: {
      textColor: '#1b1b1b',
      cssTitle: "font-family:'United'; font-weight: bold; text-shadow: none;",
      textShadowLeft: '',
      textShadowRight: '',
    },
    standings: {
      showSubtext: true,
      displayWinLossSingleColumn: true,
      hideHeader: true,
      cssCell: {
        backgroundColor: 'transparent',
        fontFamily: 'United',
      },
      cssRankCell: {
        color: '#dba103',
        backgroundColor: '#1b1b1b',
        fontSize: '60px',
        fontWeight: '400',
        margin: 'auto',
        lineHeight: '70px',
        minHeight: '100%',
      },
      cssSubtextCell: {
        backgroundColor: '#a7a7a7',
        color: '#1b1b1b',
        fontSize: '50px',
        margin: 'auto',
        lineHeight: '70px',
        minHeight: '100%',
      },
      cssLogoCell: {
        backgroundColor: '#1b1b1b',
        marginRight: '-1px',
        minHeight: '100%',
      },
      cssNameCell: {
        backgroundColor: '#1b1b1b',
        margin: 'auto',
        lineHeight: '70px',
        minHeight: '100%',
      },
      cssScoreCell: {
        backgroundColor: '#a7a7a7',
        color: '#1b1b1b',
        fontWeight: '700',
        fontSize: '65px',
        margin: 'auto',
        lineHeight: '70px',
        minHeight: '100%',

      },
      cssHeaderCell: {
        backgroundColor: '#1b1b1b',
      },
    },
  },
};
