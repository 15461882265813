export const getQueryParam = name => {
  let path = window.location.pathname;
  if (!path.includes('?') && !path.includes('&') && !path.includes('=')) {
    // we need to do this weird path stuff to support both local and the special aws s3 redirect settings
    path = window.location.search;
  }
  if (path.startsWith('?') || path.startsWith('/')) {
    path = path.substr(1);
    if (path.startsWith('?') || path.startsWith('/')) {
      path = path.substr(1);
    }
  }
  let pairs = path.split('&');
  for (let i = 0; i < pairs.length; i++) {
    const pair = pairs[i].split('=');
    if (decodeURIComponent(pair[0]) === name) {
      return decodeURIComponent(pair[1]);
    }
  }
  return undefined;
};

export const getHashParam = name => {
  let path = window.location.hash.slice(1);
  let pairs = path.split('&');
  for (let i = 0; i < pairs.length; i++) {
    const pair = pairs[i].split('=');
    if (decodeURIComponent(pair[0]) === name) {
      return decodeURIComponent(pair[1]);
    }
  }
  return undefined;
};
