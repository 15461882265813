"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StyleTeamLogo = exports.StyleScorePrediction = exports.StylePredictionContainer = exports.StyleCasterName = void 0;

var _styledComponents = _interopRequireDefault(require("styled-components"));

var _reactSpring = require("react-spring");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var StylePredictionContainer = (0, _styledComponents.default)(_reactSpring.animated.div).withConfig({
  displayName: "styles__StylePredictionContainer",
  componentId: "sc-1bj49ud-0"
})(["height:", ";width:200px;margin-right:5px;background-color:#141720;"], function (props) {
  return props.predictionscore ? '170px' : '140px';
});
exports.StylePredictionContainer = StylePredictionContainer;

var StyleCasterName = _styledComponents.default.div.withConfig({
  displayName: "styles__StyleCasterName",
  componentId: "sc-1bj49ud-1"
})(["width:100%;height:30px;line-height:30px;background-color:#FFFFFF;color:#121720;font-size:24px;font-weight:500;text-align:center;"]);

exports.StyleCasterName = StyleCasterName;
var StyleTeamLogo = (0, _styledComponents.default)(_reactSpring.animated.div).withConfig({
  displayName: "styles__StyleTeamLogo",
  componentId: "sc-1bj49ud-2"
})(["background-image:url(", ");background-position:center;background-size:cover;width:100%;height:110px;"], function (props) {
  return props.img;
});
exports.StyleTeamLogo = StyleTeamLogo;
var StyleScorePrediction = (0, _styledComponents.default)(_reactSpring.animated.div).withConfig({
  displayName: "styles__StyleScorePrediction",
  componentId: "sc-1bj49ud-3"
})(["width:100%;height:30px;line-height:30px;background-color:#121720;color:#FFFFFF;font-size:24px;font-weight:400;text-align:center;overflow:hidden;"]);
exports.StyleScorePrediction = StyleScorePrediction;