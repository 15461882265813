// REACT
import React, { Component } from 'react';
import PropTypes from 'prop-types';

class ControlContainer extends Component {

    static propTypes = {
        className: PropTypes.string,
        status: PropTypes.string,
        children: PropTypes.any,
        style: PropTypes.object,
    };

    render() {
        const open = this.props.status === 'opening' || this.props.status === 'open' || this.props.status === 'closing';
        return (
            <div
                className={`control ${this.props.className ? this.props.className : ''} ${open ? 'control--open' : ''}  ${this.props.status === 'invalid' ? 'control--status-invalid' : ''}`}
                style={this.props.style}
            >
                {this.props.children}
                <span className={`control__status`}>STATUS: {this.props.status}</span>
            </div>
        )
    }
}

export default ControlContainer