"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StyleMainContainer = exports.StyleChampionImg = exports.StyleCanvas = void 0;

var _styledComponents = _interopRequireDefault(require("styled-components"));

var _reactSpring = require("react-spring");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var StyleMainContainer = _styledComponents.default.div.withConfig({
  displayName: "styles__StyleMainContainer",
  componentId: "sc-5vyl3k-0"
})(["height:360px;width:287px;overflow:hidden;"]);

exports.StyleMainContainer = StyleMainContainer;

var StyleChampionImg = _styledComponents.default.img.withConfig({
  displayName: "styles__StyleChampionImg",
  componentId: "sc-5vyl3k-1"
})(["position:absolute;height:360px;width:287px;object-position:center top;object-fit:cover;"]);

exports.StyleChampionImg = StyleChampionImg;
var StyleCanvas = (0, _styledComponents.default)(_reactSpring.animated.canvas).withConfig({
  displayName: "styles__StyleCanvas",
  componentId: "sc-5vyl3k-2"
})(["position:absolute;background-blend-mode:luminosity;background-image:url(", ");background-size:cover;background-position:center;background-color:", ";"], function (props) {
  return props.src;
}, function (props) {
  return "".concat(props.color_filter);
});
exports.StyleCanvas = StyleCanvas;