import styled from 'styled-components';

export const StyleSliderBackgroundContainer = styled.div`
position: absolute;
z-index: 0;
width: 100%;
height: 100%;
`;

export const StyleBackground = styled.div`
    background: url(${props => props.bgImg}) no-repeat;
  width: 100%;
  height: 1080px;
`;

