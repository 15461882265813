// REACT
import React, { useState } from 'react';
import { get, cloneDeep } from 'lodash';
import styled from 'styled-components';
// internal
import { i18n } from '../../i18n';
import championService from '../../../services/championService';
import Picker from '../../Picker';
import ChampSelect from '../../ChampSelect';
import { getInfoForPlayerInPosition } from '../../../services/keyPlayerService';
import DataContainer from '../DataContainer';

let teams = [];

const DataChampionPool = () => {

  const [ championsSelectionState, setChampionsSelectionState ] = useState([]);
  const [ playerSelectionState, setPlayerSelectionState ] = useState();
  const [ playersState, setPlayersState ] = useState([]);
  const [ teamSelectionState, setTeamSelectionState ] = useState();

  const handleChampionSelection = (position, championSelection) => {
    setChampionsSelectionState(currentState => {
      const newChampionSelection = cloneDeep(currentState);
      newChampionSelection[position] = {
        championId: championSelection,
        url: get(championService, `${championSelection}.imageLarge`),
      }
      return newChampionSelection;
    });
  }

  const loadPlayerData = () => {
    if (teamSelectionState && playerSelectionState) {
        const position = window.MainData.teams[teamSelectionState].roster[playerSelectionState].position;
        const season = window.MainData.schedule.season;
        const league = window.MainData.schedule.league;
        const split = window.MainData.schedule.split;
        getInfoForPlayerInPosition(season, league, split, playerSelectionState, teamSelectionState, position)
            .then(async allData => {
                if (!allData || !allData.stats) return;
                const champs = Object.keys(allData.stats.championId).map(champ => ({ key: champ, count: allData.stats.championId[champ] })).sort((a, b) => b.count - a.count).map(c => c.key);
                setChampionsSelectionState(champs.map(championId => ({championId, url: championService[championId].imageLarge})))
            })
            .catch(e => {
              console.log(e)
              setChampionsSelectionState([]);
            });
    }
}
  React.useEffect(() => {
    teams = Object.keys(get(window, 'MainData.teams', {})).map(team => ({name: team, value: team}))
  }, [])

  React.useEffect(() => {
    if(teamSelectionState){
      if(playerSelectionState){
        setPlayerSelectionState();
        setChampionsSelectionState([]);
      }
      const rosterTeamSelected = get(window, `MainData.teams[${teamSelectionState}]roster`);
      const teamSelectedColors = get(window, `MainData.teams[${teamSelectionState}].color`)
      setPlayersState(Object.keys(rosterTeamSelected || {}).map(playerName => ({name: playerName, value: playerName})));
      window.MainData.championPool= {
        ...window.MainData.championPool,
        teamTricode : teamSelectionState,
        teamColor: teamSelectedColors,
      }
    }
  }, [teamSelectionState]);

  React.useEffect(() => {
    if(playerSelectionState){
      loadPlayerData();
      window.MainData.championPool= {
        ...window.MainData.championPool,
        playerName : (playerSelectionState || '').toUpperCase(),
      }
    }
  },[playerSelectionState])

  React.useEffect(()=>{
    window.MainData.championPool = {
      ...window.MainData.championPool,
      championPool: championsSelectionState,
    }
  }, [championsSelectionState]);

  return (
    <DataContainer componentSlug="championPool" icon="fas fa-swimming-pool" style={{ overflow: 'visible' }}>
      <StyleSelectorsContainer className="data-panel__fieldset">
      <div className="pill">
          <span>{i18n().general.team}</span>
          <Picker preselect={teamSelectionState || ''} items={teams} onUpdate={(team)=> { team && setTeamSelectionState(team)}} />
        </div>
        <div className="pill">
          <span>{i18n().general.player}</span>
          <Picker preselect={playerSelectionState || ''} items={playersState} onUpdate={(player)=> { player && setPlayerSelectionState(player)}} />
        </div>
      </StyleSelectorsContainer>
      <div className="data-panel__fieldset" style={{ paddingTop: '10px', paddingBottom: '0' }}>
        <StyleGrid>
          {/* <div className="pill"> */}
              <ChampSelect
                assignRef={'0'}
                onChampSelect={(ref, id) => {handleChampionSelection(0, id)}}
                selected={get(championsSelectionState, `[${0}].championId`)}
                disabled={false}
              />
          {/* </div> */}
          {/* <div className="pill"> */}
              <ChampSelect
                assignRef={'1'}
                onChampSelect={(ref, id) => {handleChampionSelection(1, id)}}
                selected={get(championsSelectionState, `[${1}].championId`)}
                disabled={false}
              />
          {/* </div> */}
          {/* <div className="pill"> */}
              <ChampSelect
                assignRef={'2'}
                onChampSelect={(ref, id) => {handleChampionSelection(2, id)}}
                selected={get(championsSelectionState, `[${2}].championId`)}
                disabled={false}
              />
          {/* </div> */}
        </StyleGrid>
      </div>
    </DataContainer>
  );
};

export default DataChampionPool;

const StyleSelectorsContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const StyleGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  width: 100%;
  justify-items: center;
  margin-bottom: 20px;
  img {
    width: 50px;
    height: 50px;
  }
  span {
    height: 50px;
    font-size: 15px;
    line-height: 50px;
  }
`;
