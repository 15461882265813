// import _ from 'lodash';
// REACT
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ThemeContext } from '../View/themes';
// 3RD PARTY
import { delay, get } from 'lodash';
// ME
import StandingsComponent from '../../storybook-built/dist/components/Games/LJL/Standings';
import ResizableCompWrapper from '../../storybook-built/dist/components/Games/LJL/HOC/ResizableCompWrapper';
import ScreenTitle from '../../storybook-built/dist/components/Games/LJL/ScreenTitle';

class Standings extends Component {

    static propTypes = {
        active: PropTypes.bool,
        resized: PropTypes.bool,
        // action: PropTypes.string,
        data: PropTypes.any,
        statusCallback: PropTypes.func,
        isBottomContainerRender: PropTypes.bool,
        isSideContainerRender: PropTypes.bool,
        bottomContainerActions: PropTypes.object,
        sidePlayersContainerActions: PropTypes.object,
        titleExtra: PropTypes.string,
    }

    state = {
        displayTitle: false,
        displayTable: false,
        standingsData: [],
        status: 'closed',
        resized: false,
    };

    componentDidMount() {
        console.log('MOUNTED', this.props, this.state);
        this.props.statusCallback('standings', 'ready');
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        console.log('will receive props', nextProps);
        if (nextProps.active) {
            if (this.state.status !== 'open') {
                delay(() => {
                    this.checkSubscriptionData(nextProps.data);
                    this.props.isBottomContainerRender && this.props.sidePlayersContainerActions.show(); // Component is already resized
                    this.props.isBottomContainerRender && this.setState({ resized: true }, () => this.processSideContainers(nextProps));
                }, 100);
            } else {
                if (nextProps.resized && !this.state.resized) {
                    this.resize();
                } else {
                    this.processSideContainers(nextProps);
                }
            }
        } else {
            if (this.state.status === 'open') {
                this.clearPage();
                this.props.statusCallback('standings', 'closing');
                this.setState({status: 'closed'});
            }
        }
    }

    resize = () => {
        this.props.sidePlayersContainerActions.show();
        this.setState({ resize: true });
        if (!this.props.isBottomContainerRender) {
            this.props.bottomContainerActions.show();
        }
    }

    processSideContainers = nextProps => {
        if (nextProps.isBottomContainerRender && !this.state.resize) {
            this.setState({ resize: true });
            this.props.sidePlayersContainerActions.show();
        }

        if (!nextProps.isBottomContainerRender && this.state.resize) {
            this.setState({ resize: false });
            this.props.sidePlayersContainerActions.hide();
        }
    }

    checkSubscriptionData = async data => {
        this.setState({status: 'open'});
        if (!data) return;
        this.setState({ standingsData: data.formattedStandingsData, hasTeams: data.hasTeams }, () => {
            this.beginAnimation();
        });
    };

    beginAnimation = () => {
        this.props.statusCallback('standings', 'opening');
        delay(() => this.setState({displayTitle: true, displayTable: true}), 100);
        delay(() => this.props.statusCallback('standings', 'open'), 1500);
    };

    clearPage = () => {
        this.props.statusCallback('standings', 'closing');
        this.setState({displayTable: false});
        delay(() => this.setState({displayTitle: false}), 1200);
        delay(() => this.props.statusCallback('standings', 'closed'), 2000);
    };

    sanitizeSubtitle = subtitle => {
        if (subtitle.includes("-")) {
            return subtitle.split('-')[0];
        }
        return subtitle;
    }

    render() {
        if (!this.props.data) return <div />;
        console.log('STANDINGS render', this.props);
        const teamsStanding = this.state.standingsData;

        let theme = this.context;
        // console.log('this.props.isSideContainerRender', this.props.isSideContainerRender);


        return (
            this.state.hasTeams ?
                <div className={'container'}>
                    <ResizableCompWrapper
                        isShowing={true}
                        isResized={this.props.isBottomContainerRender}
                        isSideBarRender={this.props.isSideContainerRender}
                        scaleSidebar={.75}
                    >
                        <ScreenTitle
                            title={this.props.data.titleExtra ? `STANDINGS: ${this.props.data.titleExtra}` : 'STANDINGS'}
                            showTitle={this.state.displayTitle}
                            theme={get(theme, 'screens.screenTitle', {})}
                            // titleColor={'black'}
                            displayUnderline
                        />
                        {
                            teamsStanding && teamsStanding.length > 0 &&
                            <StandingsComponent
                                isShowing={this.state.displayTable}
                                teams={teamsStanding || []}
                                theme={get(theme, 'screens.standings', {})}
                                isResized={this.props.isSideContainerRender}
                                groupNames={this.props.data.groupNames}
                            />
                        }
                        </ResizableCompWrapper>
                </div>
            : <div></div>
        )
    }
}

Standings.contextType = ThemeContext;

export default Standings
