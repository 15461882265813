// REACT
import React, { Component } from 'react';
import PropTypes from 'prop-types';
// ME
// import { send, subscribe } from '../../services/api';
import {i18n} from '../i18n';
import Button from '../Button';
import LeaguePicker from '../Picker/LeaguePicker';
import SeasonPicker from '../Picker/SeasonPicker';
import SplitPicker from '../Picker/SplitPicker';
import Picker from '../Picker';
import {
    defaultSeason,
    defaultLeague,
    defaultSplit,
    splits,
} from '../../util/const';
import { loadLeaguesInformationFromFile, loadJSONFile } from '../../util/controllerUtils';
import { setCgpcKey } from '../../util/firebase';
// import {whosLoggedIn} from './../../router/index' // this is useful for when you want a specific league to show up when a spcific person is logged in, i.e. if LJL and Academy run at the same time.

// const cgpcs = ['cgpc1', 'cgpc2', 'msi1', 'msi2', 'pubgmjc1', 'pubgmjc2'];
const cgpcs = ['cgpc1', 'academy1', 'msi1', 'pubgmjc1'];

class SetupMenu extends Component {

    static propTypes = {
        submitSetup: PropTypes.func,
    };

    state = {
        league: defaultLeague,
        season: '',
        split: '',
        leaguesInformation: {},
        enableSubmitButton: true,
        cgpcSelection: cgpcs[0], // 0 = cgpc1, 1 = academy, 2 = msi
    };

    componentDidMount() {
        window.MainData.schedule.league = defaultLeague;
        window.MainData.schedule.season = defaultSeason;
        window.MainData.schedule.split = defaultSplit;
        setCgpcKey(cgpcs[0]); // 0 = cgpc1, 1 = academy, 2 = msi
        // window.addEventListener("keyup", this.loadSplitData);
        // this.runSubscription();
        // send('loadLeaguesInformation', {
        //     filePath: `../data/leagues/`,
        // });
        loadLeaguesInformationFromFile(`../data/leagues/`, this.processJsonInfo);
    }
    componentWillUnmount() {
        // window.removeEventListener("keyup", this.loadSplitData);
    }

    // runSubscription = () => {
    //     // subscribe(data => {
    //     //     if (data.target !== "file-load") return;
    //     //     this.loadData(data.data);
    //     // });
    //     // subscribe(data => {
    //     //     if (data.target !== "get-json-info") return;
    //     //     window.MainData.cgpcListener = data.firebaseListener;
    //     //     this.setState({leaguesInformation: data.data}, () => {
    //     //         const newState = Object.assign({}, this.state);
    //     //         const leagueInfo = Object.keys(newState.leaguesInformation)[0];
    //     //         const seasonInfo = Object.keys(newState.leaguesInformation[leagueInfo].seasons)[0]
    //     //         const splitInfo = newState.leaguesInformation[leagueInfo].seasons[seasonInfo][0];

    //     //         newState.league = leagueInfo;
    //     //         newState.season = seasonInfo;
    //     //         newState.split = splitInfo;

    //     //         this.setState(newState);
    //     //     });
    //     // });
    // }

    processFileLoad = data => {
        this.loadData(data.data);
    }

    processJsonInfo = data => {
        window.MainData.cgpcListener = data.firebaseListener;
        this.setState({leaguesInformation: data.data}, () => {
            const newState = Object.assign({}, this.state);
            const leagueInfo = defaultLeague || Object.keys(newState.leaguesInformation)[0];
            // const leagueInfo = Object.keys(newState.leaguesInformation)[0];
            const allSeasons = Object.keys(newState.leaguesInformation[leagueInfo].seasons)
            const seasonInfo = allSeasons.find(season => season === defaultSeason) ||  allSeasons[0]; //Set default season if exist
            const splitInfo = defaultSplit || newState.leaguesInformation[leagueInfo].seasons[seasonInfo][0];
            newState.league = leagueInfo;
            newState.season = seasonInfo;
            newState.split = splitInfo;
            this.setState(newState);
        });
    }

    // from read-package-json
    stripBOM = content => {
        content = content.toString()
        // Remove byte order marker. This catches EF BB BF (the UTF-8 BOM)
        // because the buffer-to-string conversion in `fs.readFileSync()`
        // translates it to FEFF, the UTF-16 BOM.
        if (content.charCodeAt(0) === 0xFEFF) {
        content = content.slice(1)
        }
        return content
    }

    loadData = data => {
        if (!data) return;
        const parsed = (typeof data === 'string') ? JSON.parse(this.stripBOM(data)) : data;
        this.updateTeams(parsed.teams);
        window.MainData.pointMapping = {};
        if(parsed.pointMapping){
            window.MainData.pointMapping = parsed.pointMapping;
        }
        window.MainData.components = {};
        window.MainData.components.data = parsed.components.data.reduce((acc, value) => {
            acc[value] = value;
            return acc;
        }, {});
        window.MainData.components.view = parsed.components.view.reduce((acc, value) => {
            acc[value] = value;
            return acc;
        }, {});
        window.MainData.components.gameScreen = (parsed.components.gameScreen && parsed.components.gameScreen.reduce((acc, value) => {
            acc[value] = value;
            return acc;
        }, {})) || {};
        window.MainData.sponsors = parsed.sponsors || [];
        if(data.split_name){
            window.MainData.schedule.splitName = data.split_name;
        }
        this.props.submitSetup(parsed, this.state.leaguesInformation);
    }


    submitSetup = () => {
        this.loadSplitData();
    }

    updateTeams = teams => {
        if (teams) {
            window.MainData.teams = teams;
        }
    }

    updateLeague = value => {
        if (value) {
            window.MainData.schedule.league = value;
            window.MainData.schedule.split = splits[value][0].value;

            const seasonsAvailable = Object.keys(this.state.leaguesInformation[value].seasons);
            let seasonInfo;
            if (seasonsAvailable.find(season => season === defaultSeason)) {
                seasonInfo = defaultSeason;
            } else {
                seasonInfo = seasonsAvailable[0];
            }
            const splitInfo = this.state.leaguesInformation[value].seasons[seasonInfo][0];

            const cgpcSelect = cgpcs.find(item => item.startsWith(value));
            setCgpcKey(cgpcSelect || cgpcs[0]);
            this.setState({
                enableSubmitButton: true,
                league: value,
                season: seasonInfo,
                split: splitInfo,
                cgpcSelection: cgpcSelect || cgpcs[0],
            });
        }
    }

    updateSeason = value => {
        window.MainData.schedule.season = value;
        this.setState({
            enableSubmitButton: true,
            season: value,
            split: this.state.leaguesInformation[this.state.league].seasons[value][0],
        });
    }

    updateSplit = value => {
        window.MainData.schedule.split = value;
        this.setState({enableSubmitButton: true, split: value});
    }

    loadSplitData = () => {
        // const filePath = `../data/leagues/${this.state.league}/${this.state.season}/${this.state.split}.json`;
        // console.log('filepath', filePath); // eslint-disable-line no-console
        // send('loadJSON', {
        //     filePath: filePath,
        // });
        loadJSONFile(this.state.league, this.state.season, this.state.split, this.processFileLoad);
    }

    disableSubmitButton = event => {
        this.state.enableSubmitButton &&
        this.setState({
            enableSubmitButton: false,
            [event]: '',
        })};

    render() {

        return (
            <div className="setup-menu">
                { this.state.leaguesInformation[this.state.league] &&
                <div className="setup-menu__box">
                    <h2>{i18n().general.setup}</h2>
                    <div className="setup-menu__box__group">
                        <h3>{i18n().general.league}</h3>
                        <LeaguePicker
                            leagues={this.state.leaguesInformation || {}}
                            preselect={this.state.league}
                            onUpdate={this.updateLeague}
                            disableSubmitButton={this.disableSubmitButton}
                        />
                    </div>

                    { this.state.league &&
                        <div className="setup-menu__box__group">
                            <h3>{i18n().general.season}</h3>
                            <SeasonPicker
                                season={this.state.leaguesInformation[this.state.league].seasons}
                                preselect={this.state.season || defaultSeason}
                                onUpdate={this.updateSeason}
                                disableSubmitButton={this.disableSubmitButton}
                            />

                        </div>
                    }

                    {
                        <div className="setup-menu__box__group">
                            <h3>{i18n().general.split}</h3>
                            {this.state.leaguesInformation[this.state.league].seasons[this.state.season] ?
                            <SplitPicker
                                splits={this.state.leaguesInformation[this.state.league].seasons[this.state.season].reduce((acc, cur) => {acc[cur] = cur; return acc}, {})}
                                league={this.state.league}
                                preselect={this.state.split}
                                onUpdate={this.updateSplit}
                                disableSubmitButton={this.disableSubmitButton}
                            />
                            :
                            <Picker
                                items={[]}
                                showSelectOption={false}
                            />
                            }
                        </div>
                    }

                    {
                        <div className="setup-menu__box__group">
                            <h3>CGPC</h3>
                            <Picker
                                preselect={this.state.cgpcSelection}
                                items={cgpcs.map(item => ({name: item, value: item}))}
                                showSelectOption={false}
                                onUpdate={val => {
                                    setCgpcKey(val);
                                    this.setState({ cgpcSelection: val });
                                }}
                            />
                        </div>
                    }

                    {   this.state.league &&
                        this.state.season &&
                        this.state.enableSubmitButton &&
                        this.state.leaguesInformation[this.state.league].seasons[this.state.season] &&
                        this.state.split ?
                        <Button text={i18n().controller.buttons.set} action={this.submitSetup} />
                        :
                        <Button text={i18n().controller.buttons.set} action={this.submitSetup}  disabled={true}/>
                    }
                </div>
                }
            </div>
        );
    }

}

export default SetupMenu
