"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ViewTimer = void 0;

var _styledComponents = _interopRequireDefault(require("styled-components"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var ViewTimer = _styledComponents.default.div.withConfig({
  displayName: "styles__ViewTimer",
  componentId: "sc-1qsjcfy-0"
})(["transition:font-size 2s;font-size:", ";", ""], function (props) {
  return props.moreThanOneHour ? props.moreThanOneHourStyle ? props.moreThanOneHourStyle : '73px' : props.lessThanOneHourStyle ? props.lessThanOneHourStyle : '100px';
}, function (props) {
  return props.cssTimer;
});

exports.ViewTimer = ViewTimer;