// REACT
import React, { Component } from 'react';
// INTERNAL
import _ from 'lodash';
import { /* send, */ subscribe } from '../../../services/api';
import ControlContainer from '../../Controller/ControlContainer';
import Button from '../../Button';
import validateDataLeaderboard from '../../Data/DataLeaderboard/validate';
import {i18n} from '../../i18n';
// import { sendPlayersLoopLayoutWidgetData } from '../../../util/widgetUtil';
import { updateScreenStatus } from '../../../util/controllerUtils';
import PropTypes from 'prop-types';
class LeaderboardControl extends Component {
    static propTypes = {
        cgpc: PropTypes.string,
        display: PropTypes.bool,
        screen1: PropTypes.bool,
      };
    state = {
        status: 'ready',
    }

    componentDidMount() {
        this.runSubscription();
        if (validateDataLeaderboard()) {
            this.setState({status: 'ready'});
        } else {
            this.setState({status: 'invalid'});
        }
    }

    runSubscription = () => {
        subscribe('control-leaderboard', null, data => {
            if (data.target === 'control-leaderboard') {
                const resultState = {
                    ...data,
                    ...data.data,
                };
                delete resultState.data;
                this.setState(resultState);
            }
        }, this.props.cgpc);
    };

    onPlay = () => {
        if (validateDataLeaderboard()) {
            const sendData = { leaderboard: window.MainData.leaderboard, league: window.MainData.schedule.league, teams: window.MainData.teams };
            // send('event', { target: 'view', action: 'open', screen: 'leaderboard', data: sendData });
            const moddedData = {};
            _.forEach(sendData.leaderboard.data, (value, key) => {
                moddedData[encodeURIComponent(decodeURIComponent(key))] = value;
            });
            sendData.leaderboard.data = moddedData;
            updateScreenStatus('leaderboard', 'open', sendData, null, this.props.cgpc);
        } else {
            this.setState({status: 'invalid'});
        }
    }

    onClose = () => {
        // send('event', {target: 'view', action: 'close', screen: 'leaderboard'});
        updateScreenStatus('leaderboard', 'close', null, null, this.props.cgpc);
    }

    render() {
        return (
            <ControlContainer style={{display: this.props.display ? 'none': 'block'}} className="control--leaderboard" status={this.state.status}>
                <div className="control__panel">
                    <h2 className="control__title">
                        <i className="fas fa-child"></i> {i18n().pages.leaderboard}
                    </h2>
                    <Button controllerSide={!this.props.screen1} action={this.onPlay} text={i18n().general.open} icon="fas fa-play-circle" disabled={
                        (this.state.status === 'open' || this.state.status === 'opening') && true }
                    />
                    <Button controllerSide={!this.props.screen1} action={this.onClose} text={i18n().general.close} icon="fas fa-stop-circle" disabled={
                        !(this.state.status === 'open' || this.state.status === 'opening') && true }
                    />
                </div>
            </ControlContainer>
        )
    }
}

export default LeaderboardControl
