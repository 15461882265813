import styled from 'styled-components';
// import { switchProp } from "styled-tools";

export const StyleMainContainer = styled.div`
    width: 100%;
    display: block;
    padding-bottom: 10px;
    p {
        text-align: center;
        font-weight: 800;
        font-size: 23px;
        padding-bottom: 10px;
    }
    ${props => props.disabled ?
    `opacity:0.3;
    pointer-events: none;`
    :
    `opacity: 1;
    pointer-events: all;`
    }
`;

export const StyleChampionGrid = styled.div`
    display: grid;
    width: 100%;
    grid-template-columns: repeat(2, 1fr);
    text-align: center;
    h3{
        color: #EFEFEF;
        font-weight: 700;
        font-size: 20px;
        padding: 5px 0;
    }
    select {
        margin: 10px;
    }
`;

export const StyleTimeFrameContainer = styled.div`
    padding: 10px 0;
    display: grid;
    width: 100%;
    // grid-template-columns: repeat(2, 1fr);
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: baseline;
`;

export const StyleActionContainer = styled.div`
    text-align: center;
    button {
        text-align: left;
        margin-left: 50ex;
    }
`;