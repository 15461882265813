const aiueo = [
    'アイウエオヴ',
    'カキクケコガギグゲゴ',
    'サシスセソザジズゼゾ',
    'タチツテトダヂヅデド',
    'ナニヌネノ',
    'ハヒフヘホバビブベボパピプペポ',
    'マミムメモ',
    'ヤユヨ',
    'ラリルレロ',
    'ワヲン',
];

const aiueoGroup = (checkChar) => {
    const allAiueo = aiueo.slice();
    const result = allAiueo.filter(a => {
        return (a.indexOf(checkChar) > -1);
    });
    return result[0].charAt(0) || false;
}

export {
    aiueo,
    aiueoGroup,
};