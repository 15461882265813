import { getNextMatchTeams } from './nextMatch.js';
import { firebaseRealtimeDbSetValue } from './firebase.js';

const PLAYER_POSITIONS = ['top', 'jungle', 'mid', 'ad', 'support'];

export const sendPlayersLoopLayoutWidgetData = async () => {
    const schedule = window.MainData.schedule;
    const nextMatchTeams = getNextMatchTeams(schedule);
    if (nextMatchTeams) {
        const teams = getTeamsInformationAndFormat(nextMatchTeams);
        await firebaseRealtimeDbSetValue('view/widgets/playersLoopLayout/data/teams', teams);
    }
}

const getTeamsInformationAndFormat = incomingTeams => {
    const schedule = window.MainData.schedule;
    const teams = [];
    Object.keys(incomingTeams).forEach(teamTricode => {
        const teamInfo = incomingTeams[teamTricode];
        if (teamInfo && teamInfo.roster) {
            const matchRoster =
            schedule.rounds[schedule.round].matches[schedule.match].games[schedule.game] &&
            schedule.rounds[schedule.round].matches[schedule.match].games[schedule.game].teams[teamTricode] &&
            schedule.rounds[schedule.round].matches[schedule.match].games[schedule.game].teams[teamTricode].roster ?
                schedule.rounds[schedule.round].matches[schedule.match].games[schedule.game].teams[teamTricode].roster
                :
                null;
            const team = {};
            team.tricode = teamTricode.toLowerCase();
            team.name = teamInfo.name;
            team.color = teamInfo.color;
            team.roster = {};

            if (teamInfo.roster) {
                Object.keys(teamInfo.roster).forEach(player => {
                    if (matchRoster) {
                        Object.keys(matchRoster).forEach(position => {
                            if (matchRoster[position].playerId === player) {
                                // const playerName = matchRoster[position].playerId.toLowerCase() === 'savage' ? 'zerost' : matchRoster[position].playerId.toLowerCase();
                                const image = teamInfo.roster[player].images && teamInfo.roster[player].images.voting;
                                team.roster[position] = {
                                    player,
                                    image: image,
                                }
                            }
                        })
                    } else {
                        PLAYER_POSITIONS.forEach(position => {
                            // const playerName = player.toLowerCase() === 'savage' ? 'zerost' : player.toLowerCase();
                            const image = teamInfo.roster[player].images && teamInfo.roster[player].images.voting;
                            team.roster[position] = {
                                player,
                                image: image,
                            }
                        })
                    }
                });
            }
            teams.push(team);
        }
    })
    return teams;
}
