// REACT
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components'
// 3RD PARTY
import { delay, keyBy, forEach, get } from 'lodash';
// CONTEXT THEME
import { ThemeContext } from '../View/themes';
// ME
// import { send } from '../../services/api';
import animationService from '../../services/animationService';
import BaronPowerPlaySponsor from './BaronPowerPlaySponsor';
import BaronPowerPlay from './BaronPowerPlay';
import GameScreenInfoBox from './InfoBox';
import GameScreenRoleCompare from './RoleCompare';
import GameScreenTeamInfo from './TeamInfo';
import GameScreenVersus from './Versus';
import TopBar from '../../storybook-built/dist/components/Games/LJL/GameScreen/TopBar';
import JungleRoute from '../../storybook-built/dist/components/Games/LJL/GameScreen/JungleRoute';
import PopupVideo from './PopupVideo';
import ChampionsXP from '../../storybook-built/dist/components/Games/LJL/GameScreen/ChampionsXP';
import InGameTips from '../../storybook-built/dist/components/Games/LJL/GameScreen/InGameTips';
import {
    SponsorContainerElement,
    Asset,
    // BGAsset,
    // BuffBaronTimer,
    // BuffBaronGoldDiff,
    BuffElderTimer,
    InhibitorBlueTopContainer,
    InhibitorBlueMidContainer,
    InhibitorBlueBotContainer,
    InhibitorRedTopContainer,
    InhibitorRedMidContainer,
    InhibitorRedBotContainer,
    RightTimer,
    LeftTimer,
    TokenContainer,
    SoulContainer,
    // StyleBaronBuffContainer,
    StyleSponsorsPatchContainer,
    StyleSponsorsLJLLogo,
    StyleSponsorsPatch,
    LeftTimerLive,
    RightTimerLive,
} from './styles';

import { getTheme } from '../View/themes';
// import { preloadImages } from '../../util/loadImageUrl';
import { updateScreenStatus } from '../../util/controllerUtils'
import championsDict from '../../services/championService';
import { defaultPatch } from '../../util/const';

const StyledSponsorBackground = styled.div`
    height: 100%;
    width: 100%;
    background-image: url(https://cdn.dekki.com/uploads/eventSponsors/ingame_sponsor_bg.png);
    opacity: ${props => props.show ? '1' : '0'};
`;

const riotWhite = 'https://cdn.dekki.com/uploads/eventSponsors/01riot.png'; // White Riot Logo
const riot = 'https://cdn.dekki.com/uploads/eventSponsors/01riot_red.png'; // Red Riot Logo

function imageError(ev) {
    ev.target.src = riot
}

//ASSETS 2019 TODO: support overriding these images.
const themeSettings = getTheme();
// const BaronLive = themeSettings.assets.baronLive;
const BaronOverlay = themeSettings.assets.baronOverlay;
// const DrakeOverlayElder = themeSettings.assets.drakeOverlayElder;

// Drakes Overlay
const DrakeOverlayInfernal = themeSettings.assets.drakeOverlayInfernal;
const DrakeOverlayInfernalRift = themeSettings.assets.drakeOverlayInfernalRift;
const DrakeOverlayInfernalElder = themeSettings.assets.drakeOverlayInfernalElder;

const DrakeOverlayMountain = themeSettings.assets.drakeOverlayMountain;
const DrakeOverlayMountainRift = themeSettings.assets.drakeOverlayMountainRift;
const DrakeOverlayMountainElder = themeSettings.assets.drakeOverlayMountainElder;

const DrakeOverlayOcean = themeSettings.assets.drakeOverlayOcean;
const DrakeOverlayOceanRift = themeSettings.assets.drakeOverlayOceanRift;
const DrakeOverlayOceanElder = themeSettings.assets.drakeOverlayOceanElder;

const DrakeOverlayCloud = themeSettings.assets.drakeOverlayCloud;
const DrakeOverlayCloudRift = themeSettings.assets.drakeOverlayCloudRift;
const DrakeOverlayCloudElder = themeSettings.assets.drakeOverlayCloudElder;

const DrakeOverlayChemtech = themeSettings.assets.drakeOverlayChemtech;
const DrakeOverlayChemtechRift = themeSettings.assets.drakeOverlayChemtechRift;
const DrakeOverlayChemtechElder = themeSettings.assets.drakeOverlayChemtechElder;

const DrakeOverlayHextech = themeSettings.assets.drakeOverlayHextech;
const DrakeOverlayHextechRift = themeSettings.assets.drakeOverlayHextechRift;
const DrakeOverlayHextechElder = themeSettings.assets.drakeOverlayHextechElder;

// Drakes Tokens/Soul
const DrakeOverlayTokensContainer = themeSettings.assets.drakeOverlayTokensContainer;
const DrakeTokens = themeSettings.assets.drakeTokens;
const DrakeTokensNew = themeSettings.assets.drakeTokensNew;

// const DrakeOverlayElderNormal = themeSettings.assets.drakeOverlayElderNormal;
// const DrakeOverlayElderLive = themeSettings.assets.drakeOverlayElderLive;

const dragonAssetMap = {
    Infernal: {
        rift: DrakeOverlayInfernalRift,
        elder: DrakeOverlayInfernalElder,
        normal: DrakeOverlayInfernal,
    },
    Mountain: {
        rift: DrakeOverlayMountainRift,
        elder: DrakeOverlayMountainElder,
        normal: DrakeOverlayMountain,
    },
    Ocean: {
        rift: DrakeOverlayOceanRift,
        elder: DrakeOverlayOceanElder,
        normal: DrakeOverlayOcean,
    },
    Cloud: {
        rift: DrakeOverlayCloudRift,
        elder: DrakeOverlayCloudElder,
        normal: DrakeOverlayCloud,
    },
    Hextech: {
        rift: DrakeOverlayHextechRift,
        elder: DrakeOverlayHextechElder,
        normal: DrakeOverlayHextech,
    },
    Chemtech: {
        rift: DrakeOverlayChemtechRift,
        elder: DrakeOverlayChemtechElder,
        normal: DrakeOverlayChemtech,
    },
}


const InhibitorBlue = themeSettings.assets.inhibitorBlue;
const InhibitorRed = themeSettings.assets.inhibitorRed;

const ElderBuffBlue = themeSettings.assets.elderBuffBlue;
// const BaronBuffBlue = themeSettings.assets.baronBuffBlue;
const ElderBuffRed = themeSettings.assets.elderBuffRed;
// const BaronBuffRed = themeSettings.assets.baronBuffRed;
// const BaronBuff = themeSettings.assets.baronBuff;
const HeraldOverlay = themeSettings.assets.heraldOverlay;
// const HeraldOverlayLive = themeSettings.assets.heraldOverlayLive;

// ELEMENTS
let container;
let fanTwitterBox;
let roleContainer;
let roleGradient;
let versus;

// ANIMATION HANDLERS
let containerAnimationHandler;
let roleGradientAnimationHandler;

let sponsorList = [
    riot,
];
const sponsorTimer = 10000;
const sponsorsIndexCache = {};

class GameScreen extends Component {

    static propTypes = {
        // active: PropTypes.bool,
        data: PropTypes.any,
        statusCallback: PropTypes.func,
    }

    state = {
        infoBoxChannel: 'whowillwin',
        sponsorVisible: true,
        headerVisible: false,
        scoreVisible: false,
        jungleRouteVisible: false,
        vsVisible: false,
        championsXPVisible: false,
        infoBoxVisible: false,
        dragonSelected: 'Infernal',
        dragonSubtypeSelected: 'normal',
        topBar: 'new',
        gameTimeVisible: false,


        playTweets: false,
        sponsorImage: sponsorList[0],
        sponsorIndex: 0,
        sponsorOn: true,
        tweets: [],
        versusBannerOpen: false,
        patch: defaultPatch,
        // gameScreenTimers: {
        //     inhibitor100Top: '5:00',
        //     inhibitor100Mid: '5:00',
        //     inhibitor100Bottom: '5:00',
        //     inhibitor200Top: '5:00',
        //     inhibitor200Mid: '5:00',
        //     inhibitor200Bottom: '5:00',
        //     buffElder100: '2:30',
        //     buffBaron100: '3:30',
        //     buffElder200: '2:30',
        //     buffBaron200: '3:30',
        // },
        timers: {},
        gameScreenAssets: {
            inhibitor100: false,
            inhibitor100Top: false,
            inhibitor100Mid: false,
            inhibitor100Bottom: false,
            inhibitor200: false,
            inhibitor200Top: false,
            inhibitor200Mid: false,
            inhibitor200Bottom: false,
            dragCloud: false,
            dragInfernal: false,
            dragMountain: false,
            dragOcean: false,
            dragHextech: false,
            dragChemtech: false,
            dragonElder: false,
            dragCloudNormal: false,
            dragInfernalNormal: false,
            dragInfernalRift: false,
            dragMountainNormal: false,
            dragHextechNormal: false,
            dragChemtechNormal: false,
            dragOceanNormal: false,
            dragonElderNormal: false,
            baron: false,
            buffElder100: false,
            buffBaron100: false,
            buffElder200: false,
            buffBaron200: false,
            baronLive: false,
        },
        goldDifference: 0,
    };

    isOpen = false;
    bound = false;
    UNSAFE_componentWillReceiveProps(nextProps) {
        if(nextProps.data && nextProps.data.settings && nextProps.data.settings.patch !== this.state.patch) {
            this.setState({patch: nextProps.data.settings.patch});
        }
        console.log('RECEIVED PROPS', nextProps);

        this.doAction(nextProps);
    }

    componentDidMount() {
    console.log(this.context.screens.gameScreen.teamInfo.StyleSponsorsPatch, 'Patch')
    if (this.props.data.league === "academy") {
        sponsorList = [
            riotWhite,
        ]
    } else {
        sponsorList = [
            riot,
        ]
    }
        this.setState({patch: this.props.data && this.props.data.settings && this.props.data.settings.patch}, () => {
            const sponsors = this.props.data.sponsors
                ||
                [
                    riot,
                ];
                if(!get(this.context, `screens.gameScreen.disablePatchNumber`, false)){
                    // sponsors.push(
                    //     <StyleSponsorsPatchContainer>
                    //         <StyleSponsorsLJLLogo src={get(this.context, `screens.gameScreen.patchNumberSrc`, 'https://cdn.dekki.com/uploads/leagues/ljl_2022_spring/ljl_logo_red.svg')} />
                    //         <StyleSponsorsPatch StyleSponsorsPatch={this.context.screens.gameScreen.teamInfo.StyleSponsorsPatch}>{`PATCH ${this.state.patch ? this.state.patch : defaultPatch}`}</StyleSponsorsPatch>
                    //     </StyleSponsorsPatchContainer>
                    // )
                    sponsors.splice(1, 0,
                        <StyleSponsorsPatchContainer>
                            <StyleSponsorsLJLLogo src={get(this.context, `screens.gameScreen.patchNumberSrc`, 'https://cdn.dekki.com/uploads/leagues/ljl_2022_spring/ljl_logo_red.svg')} />
                            <StyleSponsorsPatch StyleSponsorsPatch={this.context.screens.gameScreen.teamInfo.StyleSponsorsPatch}>{`PATCH ${this.state.patch ? this.state.patch : defaultPatch}`}</StyleSponsorsPatch>
                        </StyleSponsorsPatchContainer>
                    )
                }
            sponsorList = [...sponsors]
        });
        this.doAction(this.props);
        this.checkProps(this.props);
        this.rotateSponsorTimer = setTimeout(this.rotateSponsor, sponsorTimer);
        this.props.statusCallback('gameScreen', 'open');
    }
    componentWillUnmount () {
        clearTimeout(this.rotateSponsorTimer)
    }

    doAction = props => {
        if (props.active) {
            this.runUpdate(props);
        } else {
            this.props.statusCallback('gameScreen', 'closed');
        }

        // if (props.action === 'update' || this.props.action !== props.action) {
        //     switch (props.action) {
        //         case 'open':
        //             this.checkSubscriptionData(props.data);
        //             this.props.statusCallback('gameScreen', 'open');
        //             break;
        //         case 'close':
        //             this.props.statusCallback('gameScreen', 'closed');
        //             break;
        //         case 'update':
        //             this.runUpdate(props);
        //             break;
        //         default:
        //             break;
        //     }
        // }
    }

    runUpdate = props => {
        console.log(props)
        const newState = {
            ...props.data,
            gameTimeVisible: (props.data.visible && props.data.visible.gameTime) || false,
            vsVisible: (props.data.visible && props.data.visible.vs) || false,
            infoBoxVisible: (props.data.visible && props.data.visible.infoBox) || false,
            headerVisible: (props.data.visible && props.data.visible.header) || false,
            sponsorVisible: (props.data.visible && props.data.visible.sponsor) || false,
            scoreVisible: (props.data.visible && props.data.visible.score) || false,
            jungleRouteVisible: (props.data.visible && props.data.visible.jungleRoute) || false,
            championsXPVisible: (props.data.visible && props.data.visible.championsXP) || false,
        };
        this.setState(newState);
        const keep = {};
        forEach(props.data.gameScreenAssets, (value, timer) => {
            if (value) {
                // console.log("gameScreenAssets", value, timer)
                const runningTimersSet = keyBy(this.runningTimers, 'timer');
                if (props.data.timerData && props.data.timerData[timer] && props.data.timerData[timer].initialTimerTimeSeconds && props.data.timerData[timer].initialSystemTimeMs) {
                    if (!this.state.timers || !runningTimersSet[timer] || (props.data.timerData[timer].initialTimerTimeSeconds !== runningTimersSet[timer].initialTimerTimeSeconds || props.data.timerData[timer].initialSystemTimeMs !== runningTimersSet[timer].initialSystemTimeMs)) {
                        this.runTimer(props.data.timerData[timer].initialTimerTimeSeconds, timer, props.data.timerData[timer].initialSystemTimeMs);
                    }
                }
                keep[timer] = true;
            }
            else if (get(this.state, `timers[${timer}]`)){
                const timers = { ...(this.state.timers  || {})};
                delete timers[timer];
                this.setState({timers});
            }
        });

        this.runningTimers = this.runningTimers.filter(t => keep[t.timer]);

        // if (props.data && props.action === 'update') {
            // switch (props.data.update) {
            //     case 'sponsorVisible':
            //         this.setState({ sponsorVisible: props.data.value });
            //         break;
            //     case 'scoreVisible':
            //         this.setState({ scoreVisible: props.data.value });
            //         break;
            //     case 'vsVisible':
            //         this.setState({ vsVisible: props.data.value });
            //         break;
            //     case 'infoBoxVisible':
            //         this.setState({ infoBoxVisible: props.data.value });
            //         break;
            //     case 'headerVisible':
            //         this.setState({ headerVisible: props.data.value });
            //         break;
            //     case 'infoBoxChannel':
            //         this.setState({ infoBoxChannel: props.data.value });
            //         break;
            //     case 'gameScreenAssets':
            //         this.setState({ gameScreenAssets: props.data.value});
            //         break;
            //     case 'gameScreenTimers':
            //         this.setState({ gameScreenTimers: props.data.value});
            //         break;
            //     default:
            //         break;
            // }
        // }
    }

    checkProps = () => {
        this.getElements();
        this.setAnimationHandlers();
        // this.setCleanupHandlers();
        if (!this.bound) this.bindAnimationHandlers();
    };

    setAnimationHandlers = () => {
        containerAnimationHandler = () => {
            animationService.addClass(container, 'game-screen--roles-shown');
        };

        roleGradientAnimationHandler = () => {
            animationService.addClass(container, 'game-screen--gradient-shown');
        };

    };

    bindAnimationHandlers = () => {
        animationService.createAnimationTrigger(roleContainer, containerAnimationHandler);
        animationService.createAnimationTrigger(roleGradient, roleGradientAnimationHandler);
        this.bound = true;
    };

    getElements = () => {
        container = document.querySelector('.view-gamescreen .game-screen');
        fanTwitterBox = document.querySelector('.view-gamescreen .game-screen__info-box--twitter');
        roleContainer = document.querySelector('.view-gamescreen .game-screen__role-compare');
        roleGradient = document.querySelector('.game-screen__role-compare__gradient');
    };

    toggleFanTwitterBox = () => {
        if (this.state.tweets.length) fanTwitterBox.classList.toggle('game-screen__info-box--open');
        this.setState({ playTweets: !this.state.playTweets });
    };

    toggleBaron = () => {
        container.classList.toggle('game-screen--show-baron');
    };

    toggleRoles = () => {
        container.classList.toggle('game-screen--show-roles');
    };

    showVersus = () => {
        if (this.state.versusBannerOpen) return;
        this.setState({ versusBannerOpen: true }, () => {
            animationService.addClass(versus, 'game-screen__match--open');
        });
    };

    rotateSponsor = () => {
        if(sponsorList.length === 1){ return; }
        const nextIndex = (this.state.sponsorIndex + 1) >= sponsorList.length ? 0 : this.state.sponsorIndex + 1;

        this.setState({ sponsorOn: false, sponsorIndex: nextIndex }, () => {
            delay(() => {
                let newSponsorImage = sponsorList[this.state.sponsorIndex];

                // Handling the fact that the same sponsor might have more than 1 logo
                if (Array.isArray(sponsorList[this.state.sponsorIndex])) {
                    if (sponsorsIndexCache[this.state.sponsorIndex] === undefined) {
                        sponsorsIndexCache[this.state.sponsorIndex] = 0
                    }
                    newSponsorImage = sponsorList[this.state.sponsorIndex][sponsorsIndexCache[this.state.sponsorIndex]];
                    if ((sponsorList[this.state.sponsorIndex].length - 1) === sponsorsIndexCache[this.state.sponsorIndex]) {
                        sponsorsIndexCache[this.state.sponsorIndex] = 0;
                    }
                    else {
                        sponsorsIndexCache[this.state.sponsorIndex] = sponsorsIndexCache[this.state.sponsorIndex] + 1;
                    }
                }

                this.setState({ sponsorImage: newSponsorImage }, () => {
                    delay(() => {
                        this.setState({ sponsorOn: true });
                    }, 700);
                });
                delay(this.rotateSponsor, sponsorTimer);
            }, 700);
        });
    };

    formatTimer = (time, decimal = false) => {
        const mins = Math.floor(time/60);
        const secs = time % 60;
        return decimal ?
                time === '' ? '' :`${(mins < 10 ? '0' + mins : mins)}:${(secs < 10 ? '0' + secs : secs)}`
                :
                time === '' ? '' : `${mins}:${(secs < 10 ? '0' + secs : secs)}`;
    }

    updateRunningTimers = (timer, data) => {
        let processed = false;
        this.runningTimers.forEach(t => {
            if (t.timer === timer) {
                t.initialTimerTimeSeconds = data.initialTimerTimeSeconds;
                t.initialSystemTimeMs = data.initialSystemTimeMs;
                processed = true;
            }
        });
        if (!processed) {
            this.runningTimers.push(data);
        }
    }

    // dateSimulationOffsetMs = Date.parse('2019-09-16T04:41:02.400Z') - Date.now(); // for testing, also in components/Data/DataApiListener/index
    // dateSimulationOffsetMs = Date.parse('2019-09-16T04:33:32.400Z') - Date.now();
    // dateSimulationOffsetMs = Date.parse('2019-11-10T14:05:49.745Z') - Date.now(); // worlds 2019 final game 2, has a pause after 20 minutes
    // dateSimulationOffsetMs = Date.parse('2020-01-22T04:16:18.241Z') - Date.now(); // 2020-01-22 test game #2 dragon kill 3 ( event 1337 )
    // dateSimulationOffsetMs = Date.parse('2020-01-24T04:28:15.429Z') - Date.now(); // 2020-01-22 test game #2 dragon kill 2 ( event 860 )
    // dateSimulationOffsetMs = Date.parse('2020-02-08T08:28:36.320Z') - Date.now();
    // dateSimulationOffsetMs = Date.parse('2020-02-08T04:50:00.000Z') - Date.now(); // Baron killed 1294233 game1 week1
    // dateSimulationOffsetMs = Date.parse('2020-02-11T04:46:00.000Z') - Date.now(); // Baron killed 1295440 game1 week2
    // dateSimulationOffsetMs = Date.parse('2020-03-21T13:28:30.000Z') - Date.now(); // 1323953 game5 playoff round1 CGA RJ game5

    // dateSimulationOffsetMs = Date.parse('2021-01-23T08:16:00.000Z') - Date.now(); // 1720310 game4 week1 V3 vs SG 2021
    dateSimulationOffsetMs = 0;

    getCurrentTimestamp = () => Date.now() + this.dateSimulationOffsetMs;

    runningTimers = [];
    runTimer = (initialTimerTimeSeconds, timer, initialSystemTimeMs) => {
        // console.log('dateSimulationOffset', this.dateSimulationOffsetMs);

        // console.log('running timer', initialTimerTimeSeconds, timer, initialSystemTimeMs);
        const timers = this.state.timers || {};
        const trueTime = initialTimerTimeSeconds - ((this.getCurrentTimestamp() - initialSystemTimeMs)/1000);
        const time = trueTime < 0 ? '' : Math.round(trueTime);
        // console.log('timers', timers, timer, time);
        if (time > 0) {
            timers[timer] = this.formatTimer(time);
            this.setState({
                timers,
            }, () => {
                let startJob = false;
                if (this.runningTimers.length === 0) {
                    startJob = true;
                }
                this.updateRunningTimers(timer, {
                    initialTimerTimeSeconds,
                    initialSystemTimeMs,
                    timer,
                });
                if (startJob) {
                    this.runTimerRecursive();
                }
            });
        }
    }

    resumeTimers = timePausedMs => {
        this.runningTimers.forEach(timer => {
            timer.initialSystemTimeMs += timePausedMs;
        });
        this.runTimerRecursive();
    }

    runTimerRecursive = () => {
        const timers = { ...this.state.timers };
        // const visible =
        const timersToRemove = [];
        // console.log('running timers', this.runningTimers);
        this.runningTimers.forEach(timerData => {
            const timer = timerData.timer;
            const initialTimerTimeSeconds = timerData.initialTimerTimeSeconds;
            const initialSystemTimeMs = timerData.initialSystemTimeMs;

            const trueTime = initialTimerTimeSeconds - ((this.getCurrentTimestamp() - initialSystemTimeMs)/1000);
            const time = trueTime < 0 ? '' : Math.round(trueTime);
            // console.log('timers', timers, timer, time);
            if (time > 0 && timers[timer]) {
                timers[timer] = this.formatTimer(time);
            } else {
                // visible[timer] = false;
                timers[timer] = '0:00';
                timersToRemove.push(timer);
            }
        });
        timersToRemove.forEach(timer => {
            delete timers[timer];
        });
        this.setState({
            timers,
        }, () => {
            // console.log('timers', timers);
            if(timersToRemove.length > 0){
                this.runningTimers = this.runningTimers.filter(timer => !timersToRemove.includes(timer.timer));
            }
            if (this.runningTimers.length > 0 && !this.state.gameTimePause) {
                delay(() => {this.runTimerRecursive()}, 200);
            }
        });
    }

    render() {
        // console.log("Reredering the Gamscreen...");

        // const testing = this.props.data.settings.testing;

        const teamLeftStandings = this.props.data.standingsLeft;
        const teamRightStandings = this.props.data.standingsRight;

        const theme = this.context.screens.gameScreen || {};
        // let bg = theme.backgroundImage;

        // console.log("GameScreen props", this.props);
        // console.log("GameScreen state", this.state);

        if (this.props.data.dragonTokens && (!this.props.data.dragonTokens.teamLeft || !this.props.data.dragonTokens.teamRight)) {
            console.log("Dragon Tokens", this.props.data.dragonTokens)
        }

        return (
                <div className="view-gamescreen">
                {/* {
                    bg && <BGAsset><img alt="" src={bg} /></BGAsset> // For Testing with static BG
                } */}
{/* IN GAME OVERLAY ASSETS */}

{/* INHIBITOR TEAM LEFT*/}
                <Asset
                    show={ this.state.gameScreenAssets && (
                        (this.state.gameScreenAssets.inhibitor100Top && this.state.timers.inhibitor100Top) ||
                        (this.state.gameScreenAssets.inhibitor100Mid && this.state.timers.inhibitor100Mid) ||
                        (this.state.gameScreenAssets.inhibitor100Bottom && this.state.timers.inhibitor100Bottom)
                    )}
                >
                    <img alt="" src={InhibitorBlue} />
                    <InhibitorBlueTopContainer cssInhibitorLeft={theme.inhibitor.cssInhibitorLeft}>{get(this.state, 'timers.inhibitor100Top', ' - ')}</InhibitorBlueTopContainer>
                    <InhibitorBlueMidContainer cssInhibitorLeft={theme.inhibitor.cssInhibitorLeft}>{get(this.state, 'timers.inhibitor100Mid', ' - ')}</InhibitorBlueMidContainer>
                    <InhibitorBlueBotContainer cssInhibitorLeft={theme.inhibitor.cssInhibitorLeft}>{get(this.state, 'timers.inhibitor100Bottom', ' - ')}</InhibitorBlueBotContainer>
                </Asset>

{/* INHIBITOR TEAM RIGHT*/}
                <Asset
                    show={  this.state.gameScreenAssets && (
                            (this.state.gameScreenAssets.inhibitor200Top && this.state.timers.inhibitor200Top) ||
                            (this.state.gameScreenAssets.inhibitor200Mid && this.state.timers.inhibitor200Mid) ||
                            (this.state.gameScreenAssets.inhibitor200Bottom && this.state.timers.inhibitor200Bottom))
                        }
                >
                    <img alt="" src={InhibitorRed} />
                    <InhibitorRedTopContainer cssInhibitorRight={theme.inhibitor.cssInhibitorRight}>{get(this.state, 'timers.inhibitor200Top', ' - ')}</InhibitorRedTopContainer>
                    <InhibitorRedMidContainer cssInhibitorRight={theme.inhibitor.cssInhibitorRight}>{get(this.state, 'timers.inhibitor200Mid', ' - ')}</InhibitorRedMidContainer>
                    <InhibitorRedBotContainer cssInhibitorRight={theme.inhibitor.cssInhibitorRight}>{get(this.state, 'timers.inhibitor200Bottom', ' - ')}</InhibitorRedBotContainer>
                </Asset>


{/* BUFFER */}
                {
                    <Asset
                        show={this.state.gameScreenAssets && this.state.gameScreenAssets.buffElder100 && this.state.timers.buffElder100}
                    >
                        <img alt="" src={ElderBuffBlue} />
                        <BuffElderTimer BuffElderTimer={theme.teamInfo.BuffElderTimer} color={'#0099e0'}>{get(this.state, 'timers.buffElder100', ' - ')}</BuffElderTimer>
                    </Asset>
                }
                {
                    // this.state.gameScreenAssets && this.state.gameScreenAssets.buffElder200 && this.state.timers.buffElder200 &&
                    <Asset
                        show={this.state.gameScreenAssets && this.state.gameScreenAssets.buffElder200 && this.state.timers.buffElder200}
                    >
                        <img alt="" src={ElderBuffRed} />
                        <BuffElderTimer BuffElderTimer={theme.teamInfo.BuffElderTimer} color={'#e83d3d'}>{get(this.state, 'timers.buffElder200', ' - ')}</BuffElderTimer>
                    </Asset>
                }

{/* BARON POWER PLAY */}
                {theme.disableBaronPowerPlaySponsor ?
                    <BaronPowerPlay
                        theme={theme.infoScreen.BaronPowerPlay}
                        show={(this.state.gameScreenAssets && this.state.gameScreenAssets.buffBaron100 && this.state.timers.buffBaron100)? true: false}
                        color={'#0099e0'}
                        goldDifference={this.props.data.goldDifference}
                        timer={this.state.timers.buffBaron100}
                        isGamePaused={this.props.data.isGamePaused}
                        BuffBaronTimer={theme.teamInfo.BuffBaronTimer}
                    />
                    :
                    <BaronPowerPlaySponsor
                        show={(this.state.gameScreenAssets && this.state.gameScreenAssets.buffBaron100 && this.state.timers.buffBaron100)? true: false}
                        color={'#0099e0'}
                        goldDifference={this.props.data.goldDifference}
                        timer={this.state.timers.buffBaron100}
                        isGamePaused={this.props.data.isGamePaused}
                    />
                }
                {theme.disableBaronPowerPlaySponsor ?
                    <BaronPowerPlay
                        theme={theme.infoScreen.BaronPowerPlay}
                        show={(this.state.gameScreenAssets && this.state.gameScreenAssets.buffBaron200 && this.state.timers.buffBaron200)? true: false}
                        color={'#e83d3d'}
                        goldDifference={this.props.data.goldDifference}
                        timer={this.state.timers.buffBaron200}
                        BuffBaronTimer={theme.teamInfo.BuffBaronTimer}
                    />
                    :
                    <BaronPowerPlaySponsor
                        show={(this.state.gameScreenAssets && this.state.gameScreenAssets.buffBaron200 && this.state.timers.buffBaron200)? true: false}
                        color={'#e83d3d'}
                        goldDifference={this.props.data.goldDifference}
                        timer={this.state.timers.buffBaron200}
                    />
                }

            {/* DRAGONS */}
            {/* All Dragons **/}
                {(this.state.gameScreenAssets &&
                    <Asset
                        show={this.state.gameScreenAssets && (this.state.gameScreenAssets.dragonSelected || this.state.gameScreenAssets.dragonSelectedLive)}
                    >
                        <img alt="" src={dragonAssetMap[this.state.dragonSelected] && dragonAssetMap[this.state.dragonSelected][this.state.dragonSubtypeSelected]} />
                        {
                            this.state.gameScreenAssets.dragonSelected &&
                            <LeftTimer LeftTimer={theme.teamInfo.LeftTimer}>
                                {
                                this.state.timers.dragonSelected || ' - '
                                }
                            </LeftTimer>
                        }
                        {
                            this.state.gameScreenAssets.dragonSelectedLive &&
                            <LeftTimerLive LeftTimerLive={theme.teamInfo.LeftTimerLive}>
                                {'LIVE'}
                            </LeftTimerLive>
                        }
                    </Asset>) || null
                }


{/* DRAGONS TOKENS/SOULS */}
                {
                this.props.data.dragonTokens && this.state.topBar === 'old' &&
                    <Asset show={this.props.data.dragonTokens}>
                        <img alt="" src={DrakeOverlayTokensContainer} />
                        <SoulContainer side={'left'} soul={(this.props.data.dragonTokens.teamLeft && this.props.data.dragonTokens.teamLeft.soul) ? DrakeTokens.soul[this.props.data.dragonTokens.teamLeft.soul] : ''} />
                        <TokenContainer side={'left'} pos={1} token={(this.props.data.dragonTokens.teamLeft && this.props.data.dragonTokens.teamLeft[1]) ? DrakeTokens.token[this.props.data.dragonTokens.teamLeft[1]] : ''} />
                        <TokenContainer side={'left'} pos={2} token={(this.props.data.dragonTokens.teamLeft && this.props.data.dragonTokens.teamLeft[2]) ? DrakeTokens.token[this.props.data.dragonTokens.teamLeft[2]] : ''} />
                        <TokenContainer side={'left'} pos={3} token={(this.props.data.dragonTokens.teamLeft && this.props.data.dragonTokens.teamLeft[3]) ? DrakeTokens.token[this.props.data.dragonTokens.teamLeft[3]] : ''} />
                        <TokenContainer side={'left'} pos={4} token={(this.props.data.dragonTokens.teamLeft && this.props.data.dragonTokens.teamLeft[4]) ? DrakeTokens.token[this.props.data.dragonTokens.teamLeft[4]] : ''} />

                        <TokenContainer side={'right'} pos={1} token={(this.props.data.dragonTokens.teamRight && this.props.data.dragonTokens.teamRight[1]) ? DrakeTokens.token[this.props.data.dragonTokens.teamRight[1]] : ''} />
                        <TokenContainer side={'right'} pos={2} token={(this.props.data.dragonTokens.teamRight && this.props.data.dragonTokens.teamRight[2]) ? DrakeTokens.token[this.props.data.dragonTokens.teamRight[2]] : ''} />
                        <TokenContainer side={'right'} pos={3} token={(this.props.data.dragonTokens.teamRight && this.props.data.dragonTokens.teamRight[3]) ? DrakeTokens.token[this.props.data.dragonTokens.teamRight[3]] : ''} />
                        <TokenContainer side={'right'} pos={4} token={(this.props.data.dragonTokens.teamRight && this.props.data.dragonTokens.teamRight[4]) ? DrakeTokens.token[this.props.data.dragonTokens.teamRight[4]] : ''} />
                        <SoulContainer side={'right'} soul={(this.props.data.dragonTokens.teamRight && this.props.data.dragonTokens.teamRight.soul) ? DrakeTokens.soul[this.props.data.dragonTokens.teamRight.soul] : ''} />
                    </Asset>
                }

{/* HERALD */}
                    {(this.state.gameScreenAssets &&
                        <Asset
                            show={this.state.gameScreenAssets && (this.state.gameScreenAssets.heraldLive || (this.state.gameScreenAssets.herald && this.state.timers.herald))}
                        >
                            <img alt="" src={HeraldOverlay} />
                            {this.state.gameScreenAssets.heraldLive ?
                                <RightTimerLive RightTimerLive={theme.teamInfo.RightTimerLive}>{'LIVE'}</RightTimerLive>
                                :
                                <RightTimer RightTimer={theme.teamInfo.RightTimer}>{this.state.timers.herald || ' - '}</RightTimer>
                            }
                        </Asset>) || null
                    }
{/* BARON */}
                    {(this.state.gameScreenAssets &&
                        <Asset
                            show={this.state.gameScreenAssets && (this.state.gameScreenAssets.baronLive || (this.state.gameScreenAssets.baron && this.state.timers.baron))}
                        >
                            <img alt="" src={BaronOverlay} />
                            {this.state.gameScreenAssets.baronLive ?
                                <RightTimerLive RightTimerLive={theme.teamInfo.RightTimerLive}>{'LIVE'}</RightTimerLive>
                                :
                                <RightTimer RightTimer={theme.teamInfo.RightTimer}>{this.state.timers.baron || ' - '}</RightTimer>
                            }
                        </Asset>)|| null
                    }


                <div className={`game-screen container`}>
                    {/* HEADER */}
                    <div className={`animated animated--fast game-screen__header ${this.state.headerVisible ? '' : 'game-screen__header--off'}`}>
                        <p className="game-screen__header__text">LJL 2019 SPRING SPLIT</p>
                    </div>

                    {/* INFO BOX */}
                    <GameScreenInfoBox
                        theme={theme.infoScreen}
                        season={this.props.data.season}
                        split={this.props.data.split}
                        channel={this.state.infoBoxChannel}
                        votes={this.props.data.twitterVotes || {}}
                        team100Tricode={this.props.data.gameTeamLeft}
                        team200Tricode={this.props.data.gameTeamRight}
                        team100Color={this.props.data.gameTeamLeftColor}
                        team100ColorSub={this.props.data.gameTeamLeftColorSub}
                        team200Color={this.props.data.gameTeamRightColor}
                        team200ColorSub={this.props.data.gameTeamRightColorSub}
                        team100Logo={(theme.infoScreen && theme.infoScreen.logo === 'fullColor') ? this.props.data.gameTeamLeftLogoFullColor : this.props.data.gameTeamLeftLogo}
                        team200Logo={(theme.infoScreen && theme.infoScreen.logo === 'fullColor') ? this.props.data.gameTeamRightLogoFullColor : this.props.data.gameTeamRightLogo}
                        gameTeam100BGLogo={this.props.data.gameTeam100BGLogo}
                        gameTeam200BGLogo={this.props.data.gameTeam200BGLogo}
                        // team100PlayerImage={this.props.data.playerStatsLeftPlayerImage}
                        // team200PlayerImage={this.props.data.playerStatsRightPlayerImage}
                        visible={this.state.infoBoxVisible}
                        tweets={this.props.data.tweetlist || []}
                        playersData={this.props.data.playersStats || null}
                        teamsData={this.props.data.teamsStats || null}
                        playersRunes={this.props.data.playersRunes || null}
                        currentMatchTeams={this.props.data.currentMatchTeams || null}
                        visionControl={this.props.data.visionControl || null}
                        goldDifferenceGraph={this.props.data.goldDifferenceGraph || null}
                        goldPerPlayers={this.props.data.goldPerPlayers || null}
                        damageDealtToChampionsPerPlayers={this.props.data.damageDealtToChampionsPerPlayers || null}
                        turretsStatus={this.props.data.turretsStatus || null}
                        // damageDealtToTurretsPerPlayer={this.props.data.damageDealtToTurretsPerPlayers || null}
                        // totalGoldEarnedFromTurretPlateDestructionPerPlayer={this.props.data.totalGoldEarnedFromTurretPlateDestructionPerPlayer || null}
                        whoWillWinAI={this.props.data.whoWillWinAI || null}
                        whoWillWinAIGraphBlitzcrank={this.props.data.whoWillWinAIGraphBlitzcrank || null}
                        whoWillWinAIGraph={this.props.data.whoWillWinAIGraph || null}
                        whoWillWinAIGraphEvents={this.props.data.whoWillWinAIGraphEvents || null}
                        junglerProximity={this.props.data.junglerProximityData || null}
                        itemsPurchaseHistory={this.props.data.itemsPurchaseHistory || null}
                        mythicItemInfobox={this.props.data.mythicItemInfobox || null}
                        inGameTipInfobox={this.props.data.inGameTipInfobox || null}
                        playersMythicItem={this.props.data.playersMythicItem || null}
                        gameFlowPrediction={this.props.data.gameFlowPrediction || null}
                    />
                    {/* TEAM INFO */}
                    {(!get(theme, 'teamInfo.removeTeamInfo', false) && this.state.topBar === 'old' &&
                        <GameScreenTeamInfo
                            side="left"
                            score={this.props.data.gameTeamLeftScore}
                            team={this.props.data.gameTeamLeft}
                            teamLogo={this.props.data.gameTeamLeftLogoScore}
                            standings={teamLeftStandings}
                            scoreAsset={this.props.data.gameTeamLeftScoreAsset}
                            showScore={this.state.scoreVisible}
                            mainTeamColor={this.props.data.gameTeamLeftColor}
                            secondaryTeamColor={this.props.data.gameTeamLeftSubColor}
                            showGamesWinLights={theme.teamInfo.showGamesWinLights}
                            theme={theme.teamInfo || {}}
                            bestOf={this.props.data.bestOf}
                        />) || null}
                    {(!get(theme, 'teamInfo.removeTeamInfo', false) && this.state.topBar === 'old' &&
                    <GameScreenTeamInfo
                        side="right"
                        score={this.props.data.gameTeamRightScore}
                        team={this.props.data.gameTeamRight}
                        teamLogo={this.props.data.gameTeamRightLogoScore}
                        standings={teamRightStandings}
                        scoreAsset={this.props.data.gameTeamRightScoreAsset}
                        showScore={this.state.scoreVisible}
                        mainTeamColor={this.props.data.gameTeamRightColor}
                        secondaryTeamColor={this.props.data.gameTeamRightSubColor}
                        theme={theme.teamInfo || {}}
                        removeTeamInfo={theme.teamInfo.removeTeamInfo}
                        showGamesWinLights={theme.teamInfo.showGamesWinLights}
                        bestOf={this.props.data.bestOf}
                    />) || null}
                    {(!get(theme, 'teamInfo.removeTeamInfo', false) && this.state.topBar === 'new' &&
                    <TopBar
                        theme={theme.teamInfo || {}}
                        teamLeft={this.props.data.gameTeamLeft}
                        teamRight={this.props.data.gameTeamRight}
                        teamLeftLogo={this.props.data.gameTeamLeftLogoScore}
                        teamRightLogo={this.props.data.gameTeamRightLogoScore}
                        mainTeamLeftColor={this.props.data.gameTeamLeftColor}
                        mainTeamRightColor={this.props.data.gameTeamRightColor}
                        standingsLeft={teamLeftStandings}
                        standingsRight={teamRightStandings}
                        turretsDestroyedLeft={this.props.data.turretsDestroyed && this.props.data.turretsDestroyed.team100 || 0 }
                        turretsDestroyedRight={this.props.data.turretsDestroyed && this.props.data.turretsDestroyed.team200 || 0 }
                        teamGoldLeft={this.props.data.goldCount && this.props.data.goldCount[this.props.data.gameTeamLeft].gold || 0}
                        teamGoldRight={this.props.data.goldCount && this.props.data.goldCount[this.props.data.gameTeamRight].gold || 0}
                        killCountLeft={this.props.data.killCount && this.props.data.killCount.team100 || 0}
                        killCountRight={this.props.data.killCount && this.props.data.killCount.team200 || 0}
                        scoreLeft={this.props.data.gameTeamLeftScore}
                        scoreRight={this.props.data.gameTeamRightScore}
                        showScore={this.state.scoreVisible}
                        showGamesWinLights={theme.teamInfo.showGamesWinLights}
                        bestOf={this.props.data.bestOf}
                        initialGameTime={this.props.data.initialGameTime}
                        initialSystemTime={this.props.data.initialSystemTime}
                        isGamePaused={this.props.data.isGamePaused}
                        isGameStarted={this.props.data.isGameStarted}
                        liveStatusDelay={this.props.data.liveStatusDelay}
                        showGameTimer={this.state.gameTimeVisible}
                        showDragonTokens={this.props.data.dragonTokens}
                        dragonTokensLeft={[
                            (this.props.data.dragonTokens.teamLeft && this.props.data.dragonTokens.teamLeft[1]) ? DrakeTokensNew.token[this.props.data.dragonTokens.teamLeft[1]] : '',
                            (this.props.data.dragonTokens.teamLeft && this.props.data.dragonTokens.teamLeft[2]) ? DrakeTokensNew.token[this.props.data.dragonTokens.teamLeft[2]] : '' ,
                            (this.props.data.dragonTokens.teamLeft && this.props.data.dragonTokens.teamLeft[3]) ? DrakeTokensNew.token[this.props.data.dragonTokens.teamLeft[3]] : '',
                            (this.props.data.dragonTokens.teamLeft && this.props.data.dragonTokens.teamLeft[4]) ? DrakeTokensNew.token[this.props.data.dragonTokens.teamLeft[4]] : '',
                            (this.props.data.dragonTokens.teamLeft && this.props.data.dragonTokens.teamLeft.soul)  ? DrakeTokensNew.token[this.props.data.dragonTokens.teamLeft.soul] : '',
                        ]}
                        dragonTokensRight={[
                            (this.props.data.dragonTokens.teamRight && this.props.data.dragonTokens.teamRight[1]) ? DrakeTokensNew.token[this.props.data.dragonTokens.teamRight[1]] : '',
                            (this.props.data.dragonTokens.teamRight && this.props.data.dragonTokens.teamRight[2]) ? DrakeTokensNew.token[this.props.data.dragonTokens.teamRight[2]] : '' ,
                            (this.props.data.dragonTokens.teamRight && this.props.data.dragonTokens.teamRight[3]) ? DrakeTokensNew.token[this.props.data.dragonTokens.teamRight[3]] : '',
                            (this.props.data.dragonTokens.teamRight && this.props.data.dragonTokens.teamRight[4]) ? DrakeTokensNew.token[this.props.data.dragonTokens.teamRight[4]] : '',
                            (this.props.data.dragonTokens.teamRight && this.props.data.dragonTokens.teamRight.soul)  ? DrakeTokensNew.token[this.props.data.dragonTokens.teamRight.soul] : '',
                        ]}
                        soulTokenLeft={(this.props.data.dragonTokens.teamLeft && this.props.data.dragonTokens.teamLeft.soul) ? this.props.data.dragonTokens.teamLeft.soul : ''}
                        soulTokenRight={(this.props.data.dragonTokens.teamRight && this.props.data.dragonTokens.teamRight.soul) ? this.props.data.dragonTokens.teamRight.soul : ''}
                    />) || null}
                    {/* BARON PLAYS
                    <div className="game-screen__baron-container">
                        <GameScreenBaronPlay />
                        <GameScreenBaronPlay classes="game-screen__baron-container__baron-play--right" />
                    </div> */}

                    {/* ROLE COMPARISON */}
                    <GameScreenRoleCompare side="left" type="support" />
                    <GameScreenRoleCompare type="support" />

                    {/* VERSUS */}
                    <GameScreenVersus
                        gameTeam100BGLogoLeft={this.props.data.gameTeam100BGLogoLeft}
                        gameTeam200BGLogoRight={this.props.data.gameTeam200BGLogoRight}
                        roundCount={this.props.data.roundCount}
                        team100Tricode={this.props.data.gameTeamLeft}
                        team200Tricode={this.props.data.gameTeamRight}
                        team100Color={this.props.data.gameTeamLeftColor}
                        team200Color={this.props.data.gameTeamRightColor}
                        team100SubColor={this.props.data.gameTeamLeftSubColor}
                        team200SubColor={this.props.data.gameTeamRightSubColor}
                        team100Logo={this.props.data.gameTeamLeftLogo}
                        team200Logo={this.props.data.gameTeamRightLogo}
                        team100Name={this.props.data.gameTeamLeftName}
                        team200Name={this.props.data.gameTeamRightName}
                        opened={this.state.vsVisible}
                        gameTeam100BGLogo={this.props.data.gameTeam100BGLogo}
                        gameTeam200BGLogo={this.props.data.gameTeam200BGLogo}
                    />

                    {/* SPONSOR */}
                    <StyledSponsorBackground show={this.state.sponsorVisible}>
                    <div className={`animated animated--fast game-screen__sponsor ${this.state.sponsorVisible ? 'game-screen__sponsor--active' : ''}`}>
                        <div className={
                            this.state.sponsorOn ? "animated animated--slow game-screen__sponsor__item"
                            :
                            "animated animated--slow game-screen__sponsor__item game-screen__sponsor__item--off"}
                        >
                            {
                                React.isValidElement(this.state.sponsorImage) ?
                                    <SponsorContainerElement SponsorContainerElement={theme.SponsorContainerElement}>{this.state.sponsorImage}</SponsorContainerElement>
                                    :
                                    <img alt='' src={this.state.sponsorImage} onError={imageError} />
                            }
                        </div>
                    </div>
                    </StyledSponsorBackground>
                     {/* JUNGLE ROUTE */}
                    <JungleRoute
                        theme={theme.JungleRoute || {}}
                        show={this.state.jungleRouteVisible}
                        size={500}
                        maxUnit={15000}
                        data={this.props.data.jungleRouteData}
                        colors={['#0099e0', '#e83d3d']}
                        bgImg={'https://dekki-production.s3-ap-northeast-1.amazonaws.com/assets/broadcast/games/league-of-legends/game-overlay/jungle_route_overlay.png'}
                    />
                    {/* SPONSOR POPUP VIDEO  */}
                    {!theme.disableBaronPowerPlaySponsor && <PopupVideo
                        show={this.props.data.showPopupVideo}
                        onVideoEnded={async ()=>{
                            await updateScreenStatus('gameScreen', 'open', {
                                ...this.props.data,
                                showPopupVideo: false,
                            });
                        }}
                                                            />}
                    <InGameTips
                        data={get(this.props.data, 'inGameTips',{})}
                    />
                    <ChampionsXP
                        theme={theme.ChampionsXP || {}}
                        show={this.state.championsXPVisible}
                        team100Tricode={this.props.data.gameTeamLeft}
                        team200Tricode={this.props.data.gameTeamRight}
                        team100Roster={get(this.props, `data.currentMatchTeams.${this.props.data.gameTeamLeft}`, {})}
                        team200Roster={get(this.props, `data.currentMatchTeams.${this.props.data.gameTeamRight}`, {})}
                        championsDict={championsDict}
                        data={this.props.data.championsXP}
                    />
                </div>
            </div>
        );
    }

}
GameScreen.contextType = ThemeContext;


export default GameScreen
