// REACT
import React, { Component } from 'react';
// INTERNAL
import { subscribe } from '../../../services/api';
import ControlContainer from '../../Controller/ControlContainer';
import Button from '../../Button';
import validateDataSchedule from '../../Data/DataSchedule/validate';
import { i18n } from '../../i18n';
// import moment from 'moment';
import { updateScreenStatus } from '../../../util/controllerUtils';
import PropTypes from 'prop-types';
class ScheduleTeamlessControl extends Component {

    state = {
        status: 'ready',
    }

    static propTypes = {
        cgpc: PropTypes.string,
        display: PropTypes.bool,
        screen1: PropTypes.bool,
      };

    componentDidMount() {
        this.runSubscription();
        if (validateDataSchedule()) {
            this.setState({status: 'ready'});
        } else {
            this.setState({status: 'invalid'});
        }
    }

    runSubscription = () => {
        subscribe('control-scheduleTeamless', null, data => {
            if (data.target === 'control-scheduleTeamless') {
                this.setState({status: data.status});
            }
        }, this.props.cgpc);
    };

    getScheduledMatchesToSend = schedule => {
        if (!schedule) {
            schedule = {};
        }

        const roundId = Number(schedule.round || 0);
        if (!schedule.rounds[roundId]) return null;
        return schedule.rounds[roundId].matches.map(match => {
            console.log('mATCH', match);
            const resp = {
                name: match.name,
                games: match.gameTimes && match.gameTimes.map(gameTime => {
                    const date = new Date(gameTime);
                    console.log('DATE', date);
                    return {
                        date: {
                            month: date.getMonth() + 1,
                            year: date.getFullYear(),
                            day: date.getDate(),
                            hours: date.getHours(),
                            minutes: date.getMinutes(),
                            seconds: date.getSeconds(),
                        },
                    };
                }),
                winner: '',
            }
            console.log('RESP', resp);
            return resp;
        });
    }

    getSendData = () => {
        const schedule = window.MainData.schedule;
        const scheduledMatches = this.getScheduledMatchesToSend(schedule);
        const roundId = Number(schedule.round || 0);
        const sendData = {
            scheduledMatches,
            theme: '',
            next: false,
            matchCount: schedule.rounds[roundId] && schedule.rounds[roundId].matches && schedule.rounds[roundId].matches.length,
            currentMatch: window.MainData.schedule.match,
            currentGame: window.MainData.schedule.game,
        }
        // const firstMatch = schedule.rounds[roundId] && schedule.rounds[roundId].matches && schedule.rounds[roundId].matches[0];
        // if (firstMatch) {
        //     sendData.day = moment(firstMatch.datetime).format('MMM D');
        // } else {
        //     sendData.day = '';
        // }

        return sendData;
    }

    onPlay = () => {
        if (validateDataSchedule()) {
            updateScreenStatus('scheduleTeamless', 'open', this.getSendData(), null, this.props.cgpc);
        } else {
            this.setState({status: 'invalid'});
        }
    }

    onClose = () => {
        // send('event', { target: 'view', action: 'close', screen: 'schedule' });
        updateScreenStatus('scheduleTeamless', 'close', null, null, this.props.cgpc);
    }

    render() {
        return (
            <ControlContainer style={{display: this.props.display ? 'none': 'block'}} className="control--scheduleTeamless" status={this.state.status}>
                <div className="control__panel">
                    <h2 className="control__title">
                        <i className="fas fa-calendar-alt"></i> {i18n().pages.scheduleTeamless}
                    </h2>
                    <Button controllerSide={!this.props.screen1} action={this.onPlay} text={i18n().general.open} icon="fas fa-play-circle" disabled={
                        (this.state.status === 'open' || this.state.status === 'opening') && true
                        }
                    />
                    <Button controllerSide={!this.props.screen1} action={this.onClose} text={i18n().general.close} icon="fas fa-stop-circle" disabled={
                        !(this.state.status === 'open' || this.state.status === 'opening') && true
                        }
                    />
                </div>
            </ControlContainer>
        )
    }
}

export default ScheduleTeamlessControl
