// REACT
import React, { Component } from 'react';
import PropTypes from 'prop-types';

class RoleCompare extends Component {

  static propTypes = {
    side: PropTypes.string,
    type: PropTypes.string,
  };

  state = {

  };

  getLeftMarukup = () => {
    return (
      <div className="game-screen__role-compare__content">
        <div className="game-screen__role-compare__content__item">
          <div className="game-screen__role-compare__content__item__score">
            <div className="animated animated--fast game-screen__role-compare__content__item__text">1293</div>
          </div>
          <div className="game-screen__role-compare__content__item__bar">
            <div className="animated animated--fast game-screen__role-compare__content__item__bar__fill"></div>
          </div>
          <div className="game-screen__role-compare__content__item__title">
            <div className="animated animated--fast game-screen__role-compare__content__item__text">GOLD</div>
          </div>
        </div>

        <div className="game-screen__role-compare__content__item">
          <div className="game-screen__role-compare__content__item__score">
            <div className="animated animated--fast game-screen__role-compare__content__item__text">158</div>
          </div>
          <div className="game-screen__role-compare__content__item__bar">
            <div className="animated animated--fast game-screen__role-compare__content__item__bar__fill"></div>
          </div>
          <div className="game-screen__role-compare__content__item__title">
            <div className="animated animated--fast game-screen__role-compare__content__item__text">AD</div>
          </div>
        </div>

        <div className="game-screen__role-compare__content__item">
          <div className="game-screen__role-compare__content__item__score">
            <div className="animated animated--fast game-screen__role-compare__content__item__text">34</div>
          </div>
          <div className="game-screen__role-compare__content__item__bar">
            <div className="animated animated--fast game-screen__role-compare__content__item__bar__fill"></div>
          </div>
          <div className="game-screen__role-compare__content__item__title">
            <div className="animated animated--fast game-screen__role-compare__content__item__text">EXP</div>
          </div>
        </div>
      </div>
    );
  };

  getRightMarkup = () => {
    return (
      <div className="game-screen__role-compare__content">
        <div className="game-screen__role-compare__content__item">
          <div className="game-screen__role-compare__content__item__title">
            <div className="animated animated--fast game-screen__role-compare__content__item__text">GOLD</div>
          </div>
          <div className="game-screen__role-compare__content__item__bar">
            <div className="animated animated--fast game-screen__role-compare__content__item__bar__fill"></div>
          </div>
          <div className="game-screen__role-compare__content__item__score">
            <div className="animated animated--fast game-screen__role-compare__content__item__text">1293</div>
          </div>
        </div>

        <div className="game-screen__role-compare__content__item">
          <div className="game-screen__role-compare__content__item__title">
            <div className="animated animated--fast game-screen__role-compare__content__item__text">AD</div>
          </div>
          <div className="game-screen__role-compare__content__item__bar">
            <div className="animated animated--fast game-screen__role-compare__content__item__bar__fill"></div>
          </div>
          <div className="game-screen__role-compare__content__item__score">
            <div className="animated animated--fast game-screen__role-compare__content__item__text">158</div>
          </div>
        </div>

        <div className="game-screen__role-compare__content__item">
          <div className="game-screen__role-compare__content__item__title">
            <div className="animated animated--fast game-screen__role-compare__content__item__text">EXP</div>
          </div>
          <div className="game-screen__role-compare__content__item__bar">
            <div className="animated animated--fast game-screen__role-compare__content__item__bar__fill"></div>
          </div>
          <div className="game-screen__role-compare__content__item__score">
            <div className="animated animated--fast game-screen__role-compare__content__item__text">34</div>
          </div>
        </div>
      </div>
    );
  };

  render() {
    return (
      <div className={`animated animated--fast game-screen__role-compare game-screen__role-compare--${this.props.side} game-screen__role-compare--${this.props.type}`}>
        <div className="animated animated--fast game-screen__role-compare__gradient"></div>
        {this.props.side && this.props.side === 'left' ?
          this.getLeftMarukup()
        :
          this.getRightMarkup()
        }
      </div>
    );
  }

}

export default RoleCompare