import styled from 'styled-components';
// import { switchProp } from "styled-tools";

export const StyleMainContainer = styled.div`
    width: 100%;
    display: block;
    padding-bottom: 10px;
    p {
        text-align: center;
        font-weight: 800;
        font-size: 23px;
        padding-bottom: 10px;
    }
    ${props => props.disabled ?
    `opacity:0.3;
    pointer-events: none;`
    :
    `opacity: 1;
    pointer-events: all;`
    }
`;

export const StyleResultGrid = styled.div`
    display: grid;
    width: 100%;
    grid-template-columns: 3fr 1fr 3fr;
    text-align: center;
    margin: 10px 20px;
    h3{
        color: #EFEFEF;
        font-weight: 700;
        font-size: 20px;
        padding: 5px 0;
    }
    p{
        font-size: 23px;
        padding: 5px;
        font-weight: 800;
     }
   
`;

export const StylePositionColumnContainer = styled.div`
    img{
        width: 25px;
        height: 25px;
        margin: auto;
    }
`;

export const StyleIsEntireGame = styled.div`
    padding: 0 20px;
    label {
        padding: 0 10px;
        font-size: 23px;
    }
    input[type=checkbox]{
        width: 20px;
    }
`;

export const StyleTimeFrameContainer = styled.div`
    padding: 10px 0;
    // display: grid;
    width: 100%;
    // grid-template-columns: repeat(2, 1fr);
    text-align: center;
`;

export const StyleSeparator = styled.div`
    grid-column-start: 1;
    grid-column-end: 4;
    height: 2px;
    background-color: #CCCCCC;
`;

export const StyleActionContainer = styled.div`
    text-align: center;
    button {
        text-align: left;
        margin-left: 50ex;
    }
`;