import React, { useState, useEffect } from 'react';
// INTERNAL
import { updateScreenStatus } from '../../../util/controllerUtils';
import ControlContainer from '../../Controller/ControlContainer';
import { subscribe } from '../../../services/api';
import Button from '../../Button';
import { i18n } from '../../i18n';
import PropTypes from 'prop-types';

const TelopsV2Control = (props) => {
  const [isPlayState, setIsPlayState] = useState('close');
  const [isShowState, setShowState] = useState(false);
  const onPlay = () => {
    if (!window.MainData.telopsV2) {
      setIsPlayState('invalid');
    } else {
      setIsPlayState('open');
      updateScreenStatus('telopsV2', 'open', { list: window.MainData.telopsV2, telopsVisible: false }, null, props.cgpc);
    }
  };
  const onClose = async () => {
    setIsPlayState('close');
    setShowState(false);
    await updateScreenStatus('telopsV2', 'open', { list: window.MainData.telopsV2, telopsVisible: false }, null, props.cgpc);
    await updateScreenStatus('telopsV2', 'close', null, null, props.cgpc);
  };

  const onShow = () => {
    setShowState(true);
    updateScreenStatus('telopsV2', 'open', { list: window.MainData.telopsV2, telopsVisible: true }, null, props.cgpc);
  };
  const onHide = () => {
    setShowState(false);
    updateScreenStatus('telopsV2', 'open', { list: window.MainData.telopsV2, telopsVisible: false }, null, props.cgpc);
  };

  useEffect(() => {
    subscribe('control-telopsV2', null, response => {
      setIsPlayState(response.status);
      if (response.data) {
        setShowState(response.data.telopsVisible);
      };
    }, props.cgpc);
  }, []);

  return (
    <ControlContainer style={{display: props.display ? 'none': 'block'}} className="control--telops" status={isPlayState}>
      <div className="control__panel">
        <h2 className="control__title">
          <i className="fas fa-headset"></i> {i18n().pages.telops}V2
        </h2>
        <Button
        controllerSide={!props.screen1}
          action={onPlay}
          text={i18n().general.open}
          icon="fas fa-play-circle"
          disabled={isPlayState === 'open' && true}
        />
        <Button
        controllerSide={!props.screen1}
          action={onClose}
          text={i18n().general.close}
          icon="fas fa-stop-circle"
          disabled={!(isPlayState === 'open') && true}
        />
      </div>
      {isPlayState === 'open' && (
        <div className={'control-extra'}>
          <div className={'control-extra__fieldset'}>
            <label className="control-extra__fieldset__label">Telops</label>
            <div className="pill">
              {isShowState
                ? [
                    <span key="1a" className="pill__span--highlighted">
                      {i18n().controller.viewable.true}
                    </span>,
                    <Button controllerSide={!props.screen1} key="1b" text="Hide" action={onHide} />,
                  ]
                : [
                    <span key="2a">{i18n().controller.viewable.false}</span>,
                    <Button controllerSide={!props.screen1} key="2b" text="Show" action={onShow} />,
                  ]}
            </div>
          </div>
        </div>
      )}
    </ControlContainer>
  );
};

TelopsV2Control.propTypes = {
  cgpc: PropTypes.string,
  display: PropTypes.bool,
  screen1: PropTypes.bool,
};

export default TelopsV2Control;
