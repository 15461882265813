// REACT
import React, { Component } from 'react';
import PropTypes from 'prop-types';

// ME
import { getTimeline, commitGameAndGetStats, previewGameInfo } from '../../../lib/allstats-sdk/api';
// import { send } from '../../../services/api';
import PreviewGameInfoService from '../../../services/gamePreviewService';
import { processTimeline } from '../../../services/processTimelineJsonService';
import {
  getGameStatsFromJson,
  getTeamNames,
} from '../../../services/processGameJsonService';
import Button from '../../Button';
import {i18n} from '../../i18n';
import GamePreview from './GamePreview';
import DataContainer from '../DataContainer';
import TournamentRealmPicker from '../../Picker/TournamentRealmPicker';
import Picker from '../../Picker';


class DataGameBreakdown extends Component {

    state = {
        gameId: '1144206', // AXZ vs SG, same as 2019/06/15 Week 1 Game 1
        platform: 'ESPORTSTMNT01', // main LJL tournament server
        error: null,
        previewData: null,
        roundPickerValue: null,
    };
    static propTypes = {
        gameId: PropTypes.string,
        platform: PropTypes.string,
        distributeGameId: PropTypes.func,
    };
    UNSAFE_componentWillReceiveProps(nextProps) {
        if(nextProps.gameId) this.setState({ gameId: nextProps.gameId });
        // if(nextProps.platform) this.setState({ platform: nextProps.platform });
    }

    handleResults = async (jsonData) => {

        // Set General info for graph
        const gameStatData = await getGameStatsFromJson(jsonData);
        window.MainData.gameBreakdown.stats = gameStatData;
        window.MainData.gameBreakdown.gameTime = gameStatData.gameTime;

        // set sides and winners
        const winner = jsonData.gameStats.teamStats[0].win === 'Win' ? 'team100' : 'team200';
        window.MainData.gameBreakdown.winner = winner;
        const teamNames = getTeamNames(jsonData.participants);
        window.MainData.gameBreakdown.teams = teamNames;

        // add picks and bans
        window.MainData.gameBreakdown.picks = this.state.previewData.picks;
        window.MainData.gameBreakdown.bans = this.state.previewData.bans;
    };

    handleUpload = e => {
        const fileReader = new FileReader();
        fileReader.onload = function(e) {
        const jsonData = JSON.parse(e.target.result);
        this.handleResults(jsonData);
        };

        fileReader.readAsText(e.target.files[0]);
    };

    getTimelineData = () => {
        this.setError(i18n().general.loading);
        getTimeline(this.state.realm, this.state.gameId)
        .then(res => {
        this.setError('');
        this.parseTimeline(res);
        })
        .catch(error => {
        this.setError(error);
        });
    };

    getGamePreview = () => {
        this.props.distributeGameId(this.state.gameId)
        this.setError(i18n().general.loading);
        const gameId = this.state.gameId;
        const platform = this.state.platform;

        PreviewGameInfoService(platform, gameId)
        .then(previewData => {
            this.setError('');
            this.setState({ previewData });
        })
        .catch(error => {
            this.setError(error);
        });
    };

    setError = (error) => {
        this.setState({ error: `${error}` });
    };

    setRound = round => {
        this.setState({roundPickerValue: round});
    }

    formatRound = round => {
        switch(round) {
            case null: return;
            case 'Promotion Series': return 'ljlps';
            case 'Semi Final': return 'ljlsemifinal';
            case 'Final': return 'ljlfinal';
            case 'Tiebreaker': return 'ljltiebreak';
            default: return round.split(" ")[1] ? round.split(" ")[1] : null;
        }
    }

    updateApplicationBreakdown = () => {
        this.setError(i18n().general.loading);
        const gameId = this.state.gameId;
        const platform = this.state.platform;

        previewGameInfo(platform, gameId)
        .then(ret => {
            const timeline = ret.timeline;
            this.parseTimeline(timeline);

            const gameJsonData = ret.gameData;
            this.handleResults(gameJsonData);

            this.setError(i18n().general.ok);
        })
        .catch(error => {
            this.setError(error);
        })
    }

    updateAfterGameWithSettings = () => {
        this.setError(i18n().general.loading);
        const gameId = this.state.gameId;
        const platform = this.state.platform;
        const season = window.MainData.schedule.season;
        const league = window.MainData.schedule.league;
        const split = window.MainData.schedule.split;
        let round = this.formatRound(this.state.roundPickerValue || window.MainData.schedule.rounds[window.MainData.schedule.round].name)

        const roundNum = window.MainData.schedule.round;
        const matchNum = window.MainData.schedule.match;
        if (roundNum !== undefined && matchNum !== undefined) {
            const apiRoundName = window.MainData.schedule.rounds
              && window.MainData.schedule.rounds[roundNum]
              && window.MainData.schedule.rounds[roundNum].matches
              && window.MainData.schedule.rounds[roundNum].matches[matchNum]
              && window.MainData.schedule.rounds[roundNum].matches[matchNum].round_for_api;
            if (apiRoundName !== undefined && apiRoundName !== null) {
                round = apiRoundName;
            }
        }

        console.log('Commiting...', season, league, split, round, platform, gameId, window.MainData.schedule.rounds); // eslint-disable-line no-console

        commitGameAndGetStats(season, league, split, round, platform, gameId)
        .then(ret => {
            const timeline = ret.timeline;
            this.parseTimeline(timeline);

            const gameJsonData = ret.gameData;
            this.handleResults(gameJsonData);

            this.setError(i18n().general.ok);
        })
        .catch(error => {
            this.setError(error);
        })
    };

    parseTimeline = timelineData => {
        const { goldDiff, dragons } = processTimeline(timelineData);

        window.MainData.gameBreakdown.goldDiff = goldDiff;
        window.MainData.gameBreakdown.dragons = dragons;
    };

    onDataPass = () => {
        this.checkData();
    };

    handleGameId = e => {
        this.setState({gameId: e.target.value});
    }

    updateRealm = (platform) => {
        this.setState({platform});
    }

    render() {
        const indentLeft = {
        marginLeft: '10px',
        };
        const rounds = Object.keys(window.MainData.schedule.rounds)
                        .map(roundNum =>
                        ({ name: `${window.MainData.schedule.rounds[roundNum].name}`, value: window.MainData.schedule.rounds[roundNum].name})) || [];
                        return (
            <DataContainer componentSlug="gameBreakdown" icon="fas fa-chart-line">
                <div className="data-panel__fieldset row">
                    <div className="pill">
                        <span>Realm:</span>
                        <TournamentRealmPicker onChange={this.updateRealm} default={this.props.platform} />
                        <span>Round:</span>
                        <Picker
                            items={rounds}
                            preselect={this.state.roundPickerValue || `${window.MainData.schedule.rounds[window.MainData.schedule.round].name}`}
                            onUpdate={value => this.setRound(value)}
                        />
                        <span>GameID:</span>
                        <input type="text" className="input input--wide" onChange={this.handleGameId} placeholder={this.state.gameId} />
                        <Button action={this.getGamePreview} text={i18n().general.preview} />
                    </div>
                </div>
                {
                    this.state.previewData ? (
                        <div className="data-panel__fieldset row">
                            <div className="column">
                                <GamePreview bans={this.state.previewData.bans}
                                            picks={this.state.previewData.picks}
                                            summonerNames={this.state.previewData.summonerNames}
                                            winner={this.state.previewData.winner}
                                            gameCreatedAt={this.state.previewData.gameCreatedAt}
                                /><br />
                                <Button action={this.updateApplicationBreakdown} text={i18n().general.setMatchInCGPC} />
                                <Button action={this.updateAfterGameWithSettings} text={i18n().general.commitAndGetStats} />
                            </div>
                        </div>
                    ) : (
                        <div className="data-panel__fieldset row">
                            No game data loaded yet
                        </div>
                    )
                }
                <div className="data-panel__fieldset">
                    <div>
                        <p className="warning">{i18n().general.additionalDataWarning}</p>
                        <p className="warning">{i18n().general.additionalDataWarning2}</p>
                        {
                            this.state.error ? (
                                <p className="warning" style={indentLeft}><br/>{this.state.error}<br/></p>
                            ) : (
                                <div/>
                            )
                        }
                    </div>
                </div>
            </DataContainer>
        );
    }

}

export default DataGameBreakdown
