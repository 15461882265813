// REACT
import React, { Component } from 'react';
import PropTypes from 'prop-types';
// 3RD PARTY
import { delay } from 'lodash';
// ME
// import {preloadImages} from '../../util/loadImageUrl';
import KeyPlayers from '../../storybook-built/dist/components/Games/LJL/KeyPlayers';

class KeyPlayersComponent extends Component {

    static propTypes = {
        // active: PropTypes.bool,
        action: PropTypes.string, // eslint-disable-line react/no-unused-prop-types
        data: PropTypes.any,
        statusCallback: PropTypes.func,
    }

    state = {
        status: 'closed',
        animation: '0',
    };

    bound = false;

    componentDidMount() {
        this.props.statusCallback('keyPlayers', 'ready');
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        this.doAction(nextProps);
    }

    doAction = props => {
        if (props.active) {
            if (this.state.status !== 'open') {
                delay(() => this.beginAnimation(), 100);
            }
            this.runUpdate(props);
        } else {
            if (this.state.status === 'open') this.clearPage();
            // this.props.statusCallback('keyPlayers', 'closed');
        }
    }

    checkProps = async () => {
        this.beginAnimation();
    }

    runUpdate = props => {
        if (props.data) {
            if (props.data.champsVisible) {
                this.showChamps();
            } else {
                this.hideChamps();
            }
        }
    }

    beginAnimation = () => {
        this.setState({ animation: '1' });
        delay(() => this.setState({ animation: '2' }), 1000);
        delay(() => this.setState({ animation: '3' }), 1400);
        delay(() => { this.setState({ status: 'open' }); }, 1800);
        delay(() => { this.props.statusCallback('keyPlayers', 'open'); }, 1900)
    };

    showChamps = () => {
        this.setState({ animation: '4' })
    };

    hideChamps = () => {
        this.setState({ animation: '3' })
    };

    clearPage = () => {
        this.setState({ animation: '2' });
        delay(() => { this.setState({ animation: '1' }) }, 1200);
        delay(() => { this.setState({ animation: '0' }) }, 1800);
        delay(() => { this.setState({ status: 'closed' }); }, 1900);
        delay(() => { this.props.statusCallback('keyPlayers', 'closed'); }, 2000);
    };

    render() {
        const player100 = this.props.data.player100;
        const player200 = this.props.data.player200;
        const champions100 = this.props.data.champions100;
        const champions200 = this.props.data.champions200;
        const player100PositionIcon = this.props.data.player100PositionIcon;
        const player200PositionIcon = this.props.data.player200PositionIcon;

        return (
            this.state.animation === '0' ? <div></div> :
                <div className={'container'}>
                    <KeyPlayers
                        redTeam={{
                            mainColor: this.props.data.player100TeamColor,
                            teamLogo: this.props.data.player100TeamLogo,
                            player: {
                                name: player100.name,
                                positionIcon: player100PositionIcon,
                                stats: [player100.stats.dpm, player100.stats.kda, `${player100.stats.kp}%`],
                                selectedChampions: champions100,
                            },
                        }}
                        stats={['DPM', 'KDA', 'KP%']}
                        animation={this.state.animation}
                        blueTeam={{
                            mainColor: this.props.data.player200TeamColor,
                            teamLogo: this.props.data.player200TeamLogo,
                            player: {
                                name: player200.name,
                                positionIcon: player200PositionIcon,
                                stats: [player200.stats.dpm, player200.stats.kda, `${player200.stats.kp}%`],
                                selectedChampions: champions200,
                            },
                        }}
                    />
                </div>
        );
    }

}

export default KeyPlayersComponent
