// REACT
import React, { Component } from 'react';
import PropTypes from 'prop-types';
// 3RD PARTY
import { delay } from 'lodash';
// ME
import StartersScreen from '../../storybook-built/dist/components/Games/LJL/Starters';
import { preloadImage } from '../../util/loadImageUrl';

class Starters extends Component {

    static propTypes = {
        // active: PropTypes.bool,
        // action: PropTypes.string,
        data: PropTypes.any, // eslint-disable-line react/no-unused-prop-types
        statusCallback: PropTypes.func,
    }

    state = {
        status: 'closed',
        played: false,
        playedNext: false,
        teams: null,
    };

    playerContainer = null;
    bound = false;

    componentDidMount() {
        this.formatTeams(this.props);
        delay(() => {
            this.doAction(this.props);
        }, 200);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        this.formatTeams(nextProps);
        if (this.props !== nextProps) {
            this.doAction(nextProps);
        }
    }

    doAction = props => {
        if (props.active) {
            if (this.state.status !== 'open') {
                this.setState({ status: 'open' }, () => {
                    this.checkSubscriptionData(props.data);
                });
            } else {
                this.checkSubscriptionData(props.data);
            }
        } else {
            if (this.state.status === 'open') {
                this.clearPage();
                this.setState({status: 'closed'});
                setTimeout(() => this.props.statusCallback('starters', 'closed'), 1500);
            }
        }
    }

    formatTeams = (props) => {
        document.body.style.overflow = 'hidden';
        // this.setState({ teams: (props.data && props.data.startersData || []) });
        this.preloadImages((props.data && props.data.startersData) || []);
    }

    checkSubscriptionData = data => {
        if (!data) return;
        if (!this.state.played) this.setState({ played: true }, async () => {
            this.props.statusCallback('starters', 'opening');
        });
        if (data.playNext && !this.state.playedNext) this.setState({ playedNext: true }, () => {
            this.props.statusCallback('starters', 'opening2');
        });
    };

    preloadImages = async teams => {
        const promises = [];
        teams.forEach(team => {
            promises.push(preloadImage(team.roster.top.image));
            promises.push(preloadImage(team.roster.jungle.image));
            promises.push(preloadImage(team.roster.mid.image));
            promises.push(preloadImage(team.roster.ad.image));
            promises.push(preloadImage(team.roster.support.image));
        });

        await Promise.all(promises);
        this.setState({ teams });
    }

    clearPage = () => {
        console.log("CLOSING")
        this.props.statusCallback('starters', 'closing');
        this.setState({ played: false, playedNext: false });
    };

    componentWillUnmount(){
        console.log("unmount Starters")
    }

    render() {
        return (
            <div className="view-starters container">
                {this.state.teams &&
                    <StartersScreen
                        isShowing={this.state.played}
                        renderBlueTeam={this.props.data.playNext}
                        onClose={() => this.props.statusCallback('starters', 'closed')}
                        teams={this.state.teams}
                    />
                }
            </div>
        );
    }
}

export default Starters
