import styled from 'styled-components';
// import { switchProp } from "styled-tools";

export const StyleDrakeTokenTable = styled.div`
	display: grid;
	grid-template-columns: 20% repeat(4, 1fr);
	grid-template-rows: 30px 45px 45px;
	text-align: center;
	color: #fff;
	padding:  10px 5px;
	div {
		padding: 5px;
		border: 1px #fff solid;
		border-radius: unset;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	select {
		margin: 2px;
		width:100%;
		height: 100%;
	}
`;

export const StyleIconActive = styled.i`
    position: absolute;
	right: 45px;
	margin-top: 7px;
    font-size: 25px;
    color: #fff354;
	text-shadow: 0 0 10px #fff;
	z-index: 1;
`

export const StyleSelectToken = styled.div`
	select {
		${props =>
	props.isActive ?
		'background-color: #fff354; box - shadow: 0 0 10px #fff; ': ''}
	}
`;

export const StyleSelect = styled.select`
	border-radius: 5px;
	height: 30px;
	padding: 0 10px;
	font-size: 0.8em;
	margin: 3px;
	&:hover {
		cursor: pointer;
		-webkit-transform: scale(1.05, 1.05);
		transform: scale(1.05, 1.05);
		box-shadow: 0 0 5px rgb(0 0 0 / 30%);
	}
`

export const StyleReset = styled.div`
	color: #EFEFEF;
	cursor: pointer;
	text-align: center;
	max-width: 80px;
	margin-left: calc(100% - 80px);
	&:hover{
		color: black;
	}
`;

export const StyleInfoBoxContainer = styled.div`
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
	margin-bottom: 10px;
`;


export const StyleInfoBoxButton = styled.div`
	padding: 3px 5px;
	margin: 3px;
	text-align: center;
	width: 105px;
	border-radius: 7px;
	display: inline-block;
	color: #EFEFEF;
	font-size: 14px;
	min-height: 62px;
	div {
		display: flex;
		justify-content: center;
	}
	:hover {
		background: ${props => props.active? '#fff354': '#2a81d7'};
		-webkit-box-shadow: inset 0 1px 0 0 ${props => props.active? '#b1aa4c': '#62b1e9'};
		cursor: pointer;
	}
	${props => props.active ?
	`
		color: #000000;
		font-weight: bold;
		i {
			color: #000000;
			font-weight: bold;
		}
		background-color: #fff354;
	    box-shadow: 0 0 10px #fff;
		`
	:
	`
		background: #076FC1;
		border: 1px solid #154c8c;
		border-bottom: 1px solid #0e408e;
		-moz-box-shadow: inset 0 0 6px 3px #1657b5, 0 1px 0 0 #fff;
		-webkit-box-shadow: inset 0 0 6px 3px #1657b5, 0 1px 0 0 #fff;
		text-shadow: 0 -1px 1px #2361a4;
	`}
	${props => props.controllerSide && `
	background: #B026FF;
	border: 1px solid #B026FF;
	border-bottom: 1px solid #B026FF;
	-moz-box-shadow: inset 0 0 6px 3px #B026FF, 0 1px 0 0 #fff;
	-webkit-box-shadow: inset 0 0 6px 3px #B026FF, 0 1px 0 0 #fff;
	text-shadow: 0 -1px 1px #B026FF;
	:hover { background-color: #bb46ff; }
	`}
	${props => props.disabled && `
		opacity: 0.5;
		pointer-event: none;
		color: #999999;
	`}

`;

export const StyleInfoBoxButtonIcon = styled.i`
	display: block;
	margin-bottom: 4px;
	margin-right: 2px;
	font-size: 25px;
`;

export const StyleInvalidData = styled.p`
	text-align: center;
	color: #000000;
	margin-top: -2px;
    margin-left: -65px;
	font-size: 13px;
`;