import styled from 'styled-components';

export const StyleInGameTipsContainer = styled.div`
  padding: 0 5px 5px 5px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  img {
    padding: 10px;
    max-width: 60%;
  }
`;
