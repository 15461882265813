// REACT
import React from 'react';
import { Router } from 'react-router-dom';
// ME
import App from '../App';
import history from './history';
import Controller from '../components/Controller';
import View from '../components/View';
import { operationsAdmins } from '../util/constants';
import { showLogin, getUserInfo, isLoggedIn, getUserID, logout } from '../auth0';
import { getHashParam } from '../util/uri';
import storage from '../util/storage';
export let whosLoggedIn = '';

const RoutesWrapper = () => {
  // eslint-disable-next-line no-unused-vars
  const [isOperationsAdmin, setIsOperationsAdmin] = React.useState(null);
  const [loggedIn, setLoggedIn] = React.useState(null);

  React.useEffect(() => {
    (async () => {
      // because of the s3 routing setup, we need the auth callback here on the homepage.
      const idToken = getHashParam('id_token');
      if (idToken) {
        const accessToken = getHashParam('access_token');
        storage.set('cgpc-access-token', accessToken);
        storage.set('cgpc-id-token', idToken);
        window.location.href = decodeURIComponent(window.atob(getHashParam('state')));
      }

      const loggedIn = await isLoggedIn();
      if (loggedIn) {
        setLoggedIn(true);
        const userID = await getUserID();
        whosLoggedIn = userID
        const userInfo = await getUserInfo();
        if (
          !userInfo ||
          !userInfo.user_metadata ||
          !userInfo.user_metadata.role ||
          (userInfo.user_metadata.role !== 'admin' && userInfo.user_metadata.role !== 'superadmin')
        ) {
          if(operationsAdmins.includes(userID)) {
            setIsOperationsAdmin(true);
          } else {
            setIsOperationsAdmin(false);
          }
        } else {
          setIsOperationsAdmin(true);
        }
      } else {
        setLoggedIn(false);
        setIsOperationsAdmin(false);
      }
    })();
  }, []);

  return (
      <Router history={history}>
        <App>
          {!isOperationsAdmin &&
            <header className="App-header" style={{ display: 'flex', justifyContent: 'center', width: '100%', marginTop: '7%', flexWrap: 'wrap' }}>
              <h1>Welcome to CGPC</h1>
            </header>
          }
          {
            (!isOperationsAdmin || window.location.pathname === '/') && (!loggedIn ?
              <div style={{ display: 'flex', justifyContent: 'center', width: '100%', marginTop: '2%', flexWrap: 'wrap' }}>
                <p style={{ display: 'flex', justifyContent: 'center', width: '100%', marginTop: '2%', marginBottom: "4%", flexWrap: 'wrap' }}>
                  To get started, LOG IN
                </p>
                <button onClick={login}>LOGIN</button>
              </div>
              :
              <div>
                <p style={{ display: 'flex', justifyContent: 'center', width: '100%', marginTop: '2%', marginBottom: "4%", flexWrap: 'wrap' }}>Logged in!</p>
                <p style={{ display: 'flex', justifyContent: 'center', width: '100%', marginTop: '2%', marginBottom: "4%", flexWrap: 'wrap' }}><a href="/" onClick={logout}>Log Out</a></p>
              </div>)
          }
          { isOperationsAdmin && ['/cgpc1','/cgpc2','/msi1','msi2','/pubgmjc1','/pubgmjc2','/academy1','/academy2'].includes(window.location.pathname) &&
            <View/>
          }
          { isOperationsAdmin && window.location.pathname === '/controller' &&
            <Controller/>
          }
        </App>
      </Router>
  );
}
const login = async () => {
  showLogin();
}
export default RoutesWrapper

