import styled from 'styled-components';

export const StyleViewLeaderboard = styled.div`

`;

export const StyleLeaderboards = styled.div`
    height: 840px;
    width: 1920px;
    display: flex;
    justify-content: center;
    margin-top: 20px;
`;

