import React from 'react'
import PropTypes from 'prop-types'
import Select from 'react-select';
import { get, cloneDeep } from 'lodash';

import positions from './../../../storybook-built/dist/Constants/lol_positions';
import DataContainer from '../DataContainer';
import { StyleGridRow, StyleTeamName, StylePositionLogo, StyleSelect, StyleTipPreview } from './styles';
import { inGamesTips } from './tips';
import { firebaseRealtimeDbSetValue } from '../../../util/firebase';
import { subscribe } from '../../../services/api';
import Button from '../../Button';
import { checkLang } from '../../i18n';

const initialPick = {
    team100: {
        top: '',
        jungle: '',
        mid: '',
        adc: '',
        support: '',
    },
    team200: {
        top: '',
        jungle: '',
        mid: '',
        adc: '',
        support: '',
    },
}
function DataInGameTips(props) {
    const [team100State, setTeam100State] = React.useState({});
    const [team200State, setTeam200State] = React.useState({});
    const [currentPickState, setCurrentPickState] = React.useState(cloneDeep(initialPick))
    const [currentSelectionState, setCurrentSelectionState] = React.useState(cloneDeep(initialPick))
    const [customInGameTipsState, setCustomInGameTipsState] = React.useState(cloneDeep(initialPick));
    const [remoteState, setRemoteState] = React.useState({});

    const getCurrentGame = (schedule) => {
        return get(schedule, `rounds[${schedule.round}].matches[${schedule.match}].games[${schedule.game}]`, {});
    }

    const getChampionPick = (schedule, teamTricode, pos) => {
        const currentGame = getCurrentGame(schedule);
        return get(currentGame, `teams[${teamTricode}].roster[${pos}].championId`);
    }

    const getCurrentTeam = (teamId) => {
        const currentMatch = props.schedule.rounds[props.schedule.round].matches[props.schedule.match];
        const flipped = currentMatch.games[props.schedule.game].teams[currentMatch.team100.tricode].side !== '100';
        const gameTeam100 = flipped ? currentMatch.team200 : currentMatch.team100;
        const gameTeam200 = flipped ? currentMatch.team100 : currentMatch.team200;
        if(teamId === 'team100'){
            return gameTeam100;
        }
        else if(teamId === 'team200'){
            return gameTeam200;
        }
    }

    const getOptionsFromChampion = (championId, teamId, pos) => {
        let result = [];
        result = get(inGamesTips, `[${championId}].${checkLang() === 'en'? 'labelsEn':'labelsJa'}`, []).map((label, index) =>({
            label: label,
            value: inGamesTips[championId][teamId][index],
        }));
        result.push({
            label: ' - ',
            value: '',
        })
        result = result.concat((get(customInGameTipsState, `${teamId}.${pos}`, []) || []).map(item => ({label: item.text, value: item})))
        return result;
    }

    const handleTipSelection = async (teamId, position, event) => {
        setCurrentSelectionState( currentState => {
            const newState = cloneDeep(currentState);
            newState[teamId][position] = event;
            return newState;
        })
    }

    React.useEffect(() => {
        // It looks silly but only loads once.
        subscribe('customInGameTips/', null, response => {
            if(response.target === 'customInGameTips/') {
            setCustomInGameTipsState({
                team100: {...response.team100 || {}},
                team200: {...response.team200 || {}},
            });
            }
        });
        subscribe('control-gameScreen/', null, response => {
            if (response.data && response.data['inGameTips']) {
                setRemoteState(response.data['inGameTips']);
            }
    });
    }, [subscribe]);

    React.useEffect(() => {
        const newPickState = cloneDeep(currentPickState);
        for(const pos of positions){
            if(getChampionPick(props.schedule, team100State.tricode, pos) !== currentPickState.team100[pos]){
                newPickState.team100[pos] = getChampionPick(props.schedule, team100State.tricode, pos);
            }
            if(getChampionPick(props.schedule, team200State.tricode, pos)!== currentPickState.team200[pos]){
                newPickState.team200[pos] = getChampionPick(props.schedule, team200State.tricode, pos);
            }
        }
        setCurrentPickState(newPickState);
    }, [props.schedule, team200State, team100State])

    const clearAll = async () => {
        setCurrentSelectionState(cloneDeep(initialPick));
        await firebaseRealtimeDbSetValue(`control-gameScreen/data/inGameTips/`, cloneDeep(initialPick));
    }

    const submitChange = async () => {
        const newSelection = {team100:{}, team200: {}};
        for(const pos of positions){
            // team100
            if(get(currentSelectionState, `team100[${pos}].value.url`)){
                newSelection.team100[pos] = {custom :currentSelectionState.team100[pos].value};
            }
            else if( get(currentSelectionState, `team100[${pos}].value`)){
                newSelection.team100[pos] = {url: currentSelectionState.team100[pos].value};
            }
            else {
                newSelection.team100[pos] = '';
            }
            // team200
            if(get(currentSelectionState, `team200[${pos}].value.url`)){
                newSelection.team200[pos] = {custom : currentSelectionState.team200[pos].value};
            }
            else if(get(currentSelectionState, `team200[${pos}].value`)){
                newSelection.team200[pos] = {url: currentSelectionState.team200[pos].value};
            }
            else {
                newSelection.team200[pos] = '';
            }
        }
        await firebaseRealtimeDbSetValue(`control-gameScreen/data/inGameTips/`, newSelection);
    }

    const isChangePending = (teamId, pos) => {
        if(get(remoteState, `${teamId}.${pos}.custom.url`)){
            return get(remoteState, `${teamId}.${pos}.custom.url`) !== get(currentSelectionState, `${teamId}.${pos}.value.url`,'');
        }
        else if(get(remoteState, `${teamId}.${pos}.url`,'')){
            return get(remoteState, `${teamId}.${pos}.url`,'') !== get(currentSelectionState, `${teamId}.${pos}.value`,'')
        }
        return (get(currentSelectionState, `${teamId}.${pos}.value.url`) || get(currentSelectionState, `${teamId}.${pos}.value`));
    }

    const customStyles = {
        // This gives styles to the drop down menus
        menu: (provided) => ({
          ...provided,
          position: 'relative',
        }),
    }

    React.useEffect(() => {
        // console.log('Change in schedule', props.schedule)
        setTeam100State(getCurrentTeam('team100'));
        setTeam200State(getCurrentTeam('team200'));
    }, [props.schedule.match, props.schedule.round, props.schedule.game, props.schedule.isSideSwitched]);

    return (
        <DataContainer componentSlug="dataInGameTips" icon="fal fa-info">
            <div className="data-panel__fieldset" style={{ flexDirection: 'column' }}>
                <StyleGridRow>
                    <StyleTeamName>{get(team100State, 'tricode', '')}</StyleTeamName>
                    <div />
                    <StyleTeamName>{get(team200State, 'tricode', '')}</StyleTeamName>
                </StyleGridRow>
                {positions.map(pos =>
                    <StyleGridRow key={`row-${pos}`} disable={props.gameScreenStatus !== 'open'}>
                        <StyleSelect isChangePending={isChangePending('team100', pos)}>
                            <Select
                                styles={customStyles}
                                options={getOptionsFromChampion(currentPickState.team100[pos], 'team100', pos)}
                                value={currentSelectionState.team100[pos] || {value: '', label: ' - '}}
                                onChange={(e)=>{
                                    if (props.gameScreenStatus === 'open'){
                                        handleTipSelection('team100', pos, e);
                                    }
                                }}
                                defaultValue={{value: '', label: ' - '}}
                                isSearchable
                            />
                                {((get(currentSelectionState, `team100[${pos}].value.url`) || get(currentSelectionState, `team100[${pos}].value`)) &&
                                <StyleTipPreview
                                    src={get(currentSelectionState, `team100[${pos}].value.url`) || get(currentSelectionState, `team100[${pos}].value`)}
                                    alt={`preview-team100-${pos}`}
                                />) || null}
                        </StyleSelect>
                        <StylePositionLogo title={pos}>
                            <img
                                src={`https://cdn.dekki.com/assets/broadcast/games/league-of-legends/positions/black/${pos}.svg`}
                                alt={`${pos}-logo`}
                            />
                        </StylePositionLogo>
                        <StyleSelect isChangePending={isChangePending('team200', pos)}>
                                <Select
                                styles={customStyles}
                                    options={getOptionsFromChampion(currentPickState.team200[pos], 'team200', pos)}
                                    value={currentSelectionState.team200[pos] || {value: '', label: ' - '}}
                                    onChange={(e)=>{
                                        if (props.gameScreenStatus === 'open'){
                                            handleTipSelection('team200', pos, e);
                                        }
                                    }}
                                    defaultValue={{value: '', label: ' - '}}
                                    setValue={'create-option'}
                                    isSearchable
                                />
                                {((get(currentSelectionState, `team200[${pos}].value`) || get(currentSelectionState, `team200[${pos}].value.url`)) &&
                                <StyleTipPreview
                                    src={get(currentSelectionState, `team200[${pos}].value.url`) || get(currentSelectionState, `team200[${pos}].value`)}
                                    alt={`preview-team200-${pos}`}
                                />) || null}
                        </StyleSelect>
                    </StyleGridRow>
                )}
                <div className="control-extra__fieldset">
                    <label className="control-extra__fieldset__label"></label>
                    <StyleGridRow>
                        <div className="pill" style={{margin:'auto'}}>
                            <Button controllerSide={props.controllerSide} key="1b" text="Clear" action={clearAll} />
                        </div>
                        <div/>
                        <div className="pill" style={{margin:'auto'}}>
                            <Button controllerSide={props.controllerSide} key="1b" text="Show" action={submitChange} disabled={false}/>
                        </div>
                    </StyleGridRow>
                </div>
            </div>
        </DataContainer>
    )
}

DataInGameTips.propTypes = {
    schedule: PropTypes.object,
    gameScreenStatus: PropTypes.string,
    controllerSide: PropTypes.bool,
}

export default DataInGameTips

