// REACT
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
// internal
// import Picker from '../../../Picker';
import ChampSelect from '../../../ChampSelect';
import Button from '../../../Button';
import CreatableSelect from 'react-select/creatable';
// Styles
import { StylePill } from './styles.js'
// Database
import { firebaseRealtimeGetValue } from './../../../../util/firebase';


export const getCreatableStyles = (maxWidth, extraStyle) => (
    {
        container: (provided) => ({
            ...provided,
            width: maxWidth || '140px',
            maxHeight: '30px',
            ...get(extraStyle, 'container', {}),
        }),
        input: (provided) => ({
            ...provided,
            padding: 0,
            border: 'inset 1px red solid',
            fontSize: '14px',
            lineHeight: '27px',
            ...get(extraStyle, 'provided', {}),
        }),
        valueContainer: () => ({
            padding: '5px 3px 0 3px',
            maxHeight: '30px',
            ...get(extraStyle, 'valueContainer', {}),
        }),
        singleValue: (provided) => ({
            ...provided,
            fontSize: '12px',
            ...get(extraStyle, 'singleValue', {}),
        }),
        control: (provided) => ({
            ...provided,
            minHeight: '30px',
            borderRadius: 0,
            borderWidth: 0,
            ...get(extraStyle, 'control', {}),
        }),
        dropdownIndicator: (provided) => ({
            ...provided,
            padding: '5px 2px',
            ...get(extraStyle, 'dropdownIndicator', {}),
        }),
        indicatorsContainer: (provided) => ({
            ...provided,
            maxHeight: '30px',
            ...get(extraStyle, 'indicatorsContainer', {}),
        }),
        option: (provided) => ({
            ...provided,
            height: '30px',
            display: 'flex',
            margin: 'auto',
            alignItems: 'center',
            fontSize: '12px',
            ...get(extraStyle, 'option', {}),
        }),
        menu: (provided) => ({
            ...provided,
            marginTop: 0,
            ...get(extraStyle, 'menu', {}),
        }),
        menuList: (provided) => ({
            ...provided,
            maxHeight: 'unset',
            ...get(extraStyle, 'menuList', {}),
        }),
    }
)

class DataMatchLineup extends Component {

    state = {
        exchange: undefined,
        firebase: {
            team100: {
                adc: '',
                jungle: '',
                mid: '',
                support: '',
                top: '',
                coach: '',
            },
            team200: {
                adc: '',
                jungle: '',
                mid: '',
                support: '',
                top: '',
                coach: '',
            },
        },
        preselect: {
            adc: '',
            jungle: '',
            mid: '',
            support: '',
            top: '',
            coach: '',
        },
    };

    static propTypes = {
        side: PropTypes.string,
        team: PropTypes.any,
        teamTricode: PropTypes.string,
        screens: PropTypes.arrayOf(PropTypes.number),
        onChampSet: PropTypes.func,
        onPlayerSet: PropTypes.func,
        onSetCoach: PropTypes.func,
        onShowChampStats: PropTypes.func,
        swapChamps: PropTypes.func,
    };

    componentDidMount() {
        const preselect = Object.assign({}, this.state.preselect);
        preselect.coach = window.MainData.teams[this.props.teamTricode] && window.MainData.teams[this.props.teamTricode].coach;
        this.setState({ preselect });
        firebaseRealtimeGetValue(`${window.MainData.schedule.league}/${window.MainData.schedule.season}/${window.MainData.schedule.split}/${window.MainData.schedule.rounds[window.MainData.schedule.round].name}/${window.MainData.schedule.match}/${window.MainData.schedule.game}/banPick/picks`).then(value => {
            this.setState({
                firebase: value,
            })
          }, reason => {
            console.log('DataMatchLineUpError: ', reason)
          });
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        // console.log(nextProps)
        if (nextProps.teamTricode !== this.props.teamTricode) {
            const preselect = Object.assign({}, this.state.preselect);
            if (window.MainData.teams[nextProps.teamTricode]) {
                // if there is a swap active but this match is not swapped. Put it back
                preselect.coach = window.MainData.teams[nextProps.teamTricode].coach;
                this.setState({ preselect });
            }
        }
    }

    setPlayer = (player, position) => {
        const preselect = Object.assign({}, this.state.preselect);
        preselect[position] = player;

        this.setState({ preselect });
        this.props.onPlayerSet(player, position, this.props.teamTricode);
    }

    setChamp = (position, champ = '') => {
        this.props.onChampSet(champ, position, this.props.side);
    }

    swapChamps = (positionA, positionB) => {
        this.props.swapChamps(positionA, positionB, this.props.teamTricode);
    }

    setCoach = coachName => {
        const preselect = Object.assign({}, this.state.preselect);
        preselect.coach = coachName;
        this.props.onSetCoach(coachName, this.props.teamTricode);
    }

    showChampStats = async (pos, Side) => {
        const banPickDataHolder = await firebaseRealtimeGetValue(`control-banPick`)
        const v1 = get(banPickDataHolder, `status`, false) === 'open'
        if (v1) {
            let show = false
            let championId = 0
            const banPick = await firebaseRealtimeGetValue(`banPick`)
            const controlbanPick = await firebaseRealtimeGetValue(`control-banPick`)
            show = get(controlbanPick, `data.championsStats[team${Side}][${pos}].show`, false)
            championId = get(banPick, `picks[team${Side}][${pos}].championID`, false)
            this.props.onShowChampStats(pos, championId, !show, v1);
        } else {
            let show = false
            let championId = 0
            const banPick = await firebaseRealtimeGetValue(`banPick`)
            const controlbanPickV2 = await firebaseRealtimeGetValue(`control-banPickV2`)
            show = get(controlbanPickV2, `data.championsStats[team${Side}][${pos}].show`, false)
            championId = get(banPick, `picks[team${Side}][${pos}].championID`, false)
            this.props.onShowChampStats(pos, championId, !show);
        }
    }

    doesFireBaseMatch = (pos, Side) => {
        if(this.state.firebase[`team${Side}`][pos].championID === this.props.team.roster[pos].championId) {
            return true
        } else {
            return false
        }
    }

    handleChangeCoach = (e) => {
        const preselect = Object.assign({}, this.state.preselect);
        preselect.coach = e.target.value;
        this.setState({ preselect });
    }

    checkExchange = position => {
        if (this.state.exchange) {
            this.swapChamps(position, this.state.exchange);
            this.setState({ exchange: undefined });
        } else {
            this.setState({ exchange: position });
        }
    }

    cancelExchange = () => {
        this.setState({ exchange: undefined });
    }


    render() {
        const allTeamPlayers = window.MainData.teams[this.props.teamTricode] && Object.keys(window.MainData.teams[this.props.teamTricode].roster).length ? Object.keys(window.MainData.teams[this.props.teamTricode].roster).map(p => ({ value: p, name: p }))
            :
            [];

        const allChamps = [];
        return (
            <div className={`game-lineup game-sides game-sides--${this.props.side} ${this.state.exchange ? 'game-lineup--swapping' : ''}`}>
                <div className="game-sides__header">
                    {this.props.teamTricode} LINEUP
                </div>
                {
                    <div key={'coach-row'} className={'row'}>
                        <div className="pill">
                            <span className="game-lineup__position">{'Coach'}</span>
                            <input id={`coach-name-${this.props.teamTricode}`} className={'input input--x-wide'} value={this.state.preselect.coach} onChange={this.handleChangeCoach} />
                            <Button className={`button interactive `} action={() => this.setCoach(document.getElementById(`coach-name-${this.props.teamTricode}`).value)} text="SET" />
                        </div>
                    </div>
                }
                {
                    this.props.team && this.props.team.roster && Object.keys(this.props.team.roster).map((position, i) =>
                        position !== 'undefined' && position !== 'coach' &&
                        <div key={i} className={`row ${this.state.exchange === position && 'game-lineup__row--selected'}`} style={{ marginTop: '3px' }}>
                            <StylePill className="pill">
                                {this.props.screens && <span style={{ width: '69px', padding: '0 5px' }}>Screen {this.props.screens[i]}</span>}
                                <span className="game-lineup__position" style={{ width: '55px', padding: '0 5px' }}>{position}</span>
                                <CreatableSelect
                                    onChange={(choice) => {
                                        if (choice.value !== get(this.props, `team.roster[${position}].name`)) {
                                            this.setPlayer(choice.value, position)
                                        }
                                    }}
                                    value={{ value: get(this.props, `team.roster[${position}].name`, ''), label: get(this.props, `team.roster[${position}].name`, '') }}
                                    options={allTeamPlayers.map(option => ({ label: option.name, value: option.name }))}
                                    styles={getCreatableStyles()}
                                />
                                <ChampSelect items={allChamps} assignRef={position} onChampSelect={this.setChamp} selected={this.props.team.roster[position].championId} preselect={position} />
                                {
                                    this.state.exchange === position &&
                                    <Button className={`game-lineup__swap`} icon="fas fa-times" action={() => this.cancelExchange()} text="" title={"Cancel Swap"} />
                                }
                                {
                                    this.state.exchange !== position &&
                                    <Button className={`game-lineup__swap`} icon="fas fa-exchange-alt" action={() => this.checkExchange(position)} text="" title={"Select Champion to Swap"} />
                                }
                                <Button
                                    className={get(this, `props.champStatsStatus[${position}].show`, false) ? 'button--highlighted' : ''}
                                    icon="fas fa-percent"
                                    action={() => { this.showChampStats(position, this.props.team.side, false); }}
                                    title={"Show Champion Stat on Ban&Pick Screen"}
                                    text=""
                                />
                            </StylePill>
                        </div>
                    )
                }
            </div>
        )
    }

}
export default DataMatchLineup
