import styled from 'styled-components';

export const Base = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    height: 100%;
`;

export const StylePlayerSelectionGrid = styled.div`
    // display: grid;
    // grid-template-columns: ${props => `repeat(${props.columns === 'single'? '1':'2'}, 1fr)`};
    padding: 10px 20px;
`;

export const StylePlayerContainer = styled.div`
    display:flex;
    margin: 10px;
    text-align: center;
    div:first-child {
        margin: auto;
    }
    select {
        min-width: 120px;
    }
    span {
        min-width: 80px;
    }
    img, i {
        width: 100px;
        font-size: 50px;
    }
    h3 {
        margin:auto;
    }

    &:first-child {
        border-right: 1px #CCCCCC solid;
    }
`;

export const StyleStatsGrid = styled.div`
    // display: grid;
    // grid-template-columns: 75px repeat(5, 1fr);
    display: flex;
    flex-wrap: wrap;
    h3 {
        margin: auto;
        font-size: 13px;
        color: #EFEFEF;
        width: 100%;
        padding: 10px;
        border-right: 1px #EFEFEF solid;
        text-align: center;

    }
    select{
        max-width: 130px
    }
    div {
        margin-right: unset;
        text-align: center;
    }
    p {
        width: 100%;
        text-align: center;
        color: #666666;
        font-weight: 800;
        font-style: italic;
        background-color: #EFEFEF;
    }
`;

export const StyleStatsSeparator = styled.div`
    height:1px;
    background-color: #EFEFEF;
    grid-column-start: 1;
    grid-column-end: 7;
    margin: 10px;
`;

export const StyleStatsPickerContainer = styled.div`
    select {
        max-width: unset;
        width: calc(100% - 70px);
    }
    opacity: ${props => props.disabled? '0.3': '1'};
    pointer-events: ${props => props.disabled? 'none': 'all'};

`;

export const StatContainer = styled.div`
    width: 20%;
    opacity: ${props => props.disabled? '0.3': '1'};
`;
