export default {
  id: 'worlds2020',
  name: 'Worlds',
  screens: {
    animatedBackground: {
      show: true,
      defaultBackground:'',
        disableAnimationBackGround: true,
    },
    bottomBar: {
      cssBottomBar: 'border-image:linear-gradient(to right,#ffffff99,#1b1b1b,#ffffff99) 10',
      teamLogoColorBG: '#a7a7a766',
      borderColor: 'teamMain',
      borderColorBase: '',
      backgroundColor: '#1b1b1b',
      cssTeamName: 'font-family: United; color:#ffffff;',
      cssNextGameTitle: 'font-family: United; font-size: 28px; color: #dba103; margin-top: 15px;',
      cssTimer: 'font-family: United; font-weight: 800; font-size: 85px; color: #dba103;',
    },
    cornerLogo: {
      // cornerLogoImage: LJLTopLeftLogoSummer,
      cssCornerLogo: 'z-index:1;',
      cssCornerLabelText: 'color:#8b681c; top:120px; left:95px; font-size:26px;',
      imageTop: '30px',
      imageLeft: '-150px',
      hideCornerLogo: true,
    },
    scheduleInfoSideBar: {
      logoSrc: 'https://cdn.dekki.com/uploads/leagues/worlds_2020/logo_white.svg',
      cssTimerText: 'color: #8de8f9; font-weight: bold; font-family: proxima-nova, sans-serif; font-weight: 700;',
      cssTimer: 'div{ font-family: dharma-gothic-e, sans-serif; font-weight: 700; font-size: 120px !important; line-height: 120px; letter-spacing: 2px; padding-left: 5px;}',
      cssBackground: 'background: linear-gradient(60deg, #001642 0%, #000220  100%); border-right: 22px solid #8de8f9;',
      cssDayTitle: 'margin-bottom: 10px; padding-bottom: 0; font-size: 30px; font-family: proxima-nova, sans-serif; font-weight: 700; ',
      cssScheduleList: 'background-color: #FFFFF4D; width: 430px;',
      cssScheduleRow: 'color: purple;   border-bottom: 1px solid #ffffff33;',
      cssNext: 'color: #8de8f9; font-family: dharma-gothic-e, sans-serif; font-weight: 400; font-size: 40px;',
      cssWin: 'color: white; font-family: dharma-gothic-e, sans-serif; font-weight: 400; font-size: 40px;',
      cssGameTime: 'color: white; font-family: dharma-gothic-e, sans-serif; font-weight: 400; font-size: 40px;',
      cssLogoLoss: 'opacity: 0.3;',
  },
  telopsV2: {
      cssTelops: `
      border-right: 15px solid #8de8f9;
      background: #8de8f9;
      border-left: unset;
      div:first-of-type{
          background: linear-gradient(60deg, #000220 0%, #001642 100%);
          border: unset;
          left: 0;
          padding-top:0;
      }`,
      cssTwitter: 'p{font-family: proxima-nova, sans-serif; font-weight: 700; color: #8de8f9; font-size: 20px;} margin-top: -2px;',
      cssName: 'top: 0; font-family: dharma-gothic-e, sans-serif; font-weight: 700; color: white; font-size: 60px; line-height: 57px;',
  },
    gameBreakdown: {
      backgroundImage: '',
      // backgroundImage: 'https://cdn.dekki.com/uploads/leagues/ljl_2019_summer/gameBreakdownBG.png',
      disableSliderBG: true,
      teamStats: {
        teamStatsBGColor: '#000220',
        teamStatsBGTexture:'none',
        showTeamBackgroundImageStatsContents: false,
        teamStatsHeaderBGColor: '#000220',
        cssTeamName: { color: '#8de8f9',fontFamily: 'proxima-nova, sans-serif', fontSize: '45px', fontWeight: '700', display: 'flex', justifyContent: 'center', alignItems: 'center' },
        cssTeamNameLeft: 'color: #8de8f9;',
        cssTeamNameRight: 'color: #a070bb;',
        item: { color: '#ffffff',fontFamily: 'proxima-nova, sans-serif', fontWeight: '300', fontSize: '28px'},
        cssTeamStats: "font-family: 'proxima-nova, sans-serif'; font-weight: 300; ",
        cssWinTag: "font-family: proxima-nova, sans-serif; font-weight: 700;",
        cssLossTag: "background-color: rgba(255, 255, 255, 0.6); font-family: proxima-nova, sans-serif; font-weight: 700;",
        cssBansPicks: { color: '#ffffff', fontSize: '30px', fontFamily: 'proxima-nova, sans-serif', fontWeight: '300' },
        dragons: {backgroundColor: 'unset', border: '4px solid transparent'},
      },
      key: {
        gameTime: { fontFamily: 'dharma-gothic-e, sans-serif', fontWeight: '700', fontSize: '70px', letterSpacing: '2px', marginBottom: '0px' },
        gameTimeHeader: { marginBottom: '0', fontSize: '20px', color: '#ffffff'},
        cssHeader: { fontFamily: 'proxima-nova, sans-serif' },
        cssTitles: { fontFamily: 'proxima-nova, sans-serif', fontWeight: '300', color: '#ffffff', fontSize: '26px' },
        content: {backgroundColor: '#000220B3', backgroundImage: 'unset'},
      },
      graph: {
        cssHeader: { fontFamily: 'proxima-nova, sans-serif', backgroundColor: '#000220', backgroundImage: 'unset', fontWeight: '400', letterSpacing: '2px', fontSize: '32px' },
        cssContent: { fontFamily: 'proxima-nova, sans-serif', color: '#141720', backgroundImage: 'unset', fontWeight: '300', backgroundColor: '#000220', borderBottom:'none' },
        teamLeftColor: '#8de8f9', // blue initially
        teamRightColor: '#a070bb', // red initially
        cssKey: {
          borderBottom:'none',
          fontFamily: 'proxima-nova, sans-serif', fontWeight: '700', fontSize: '26px',
          backgroundImage: 'unset', backgroundColor: '#000220B3',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          paddingTop: '35px',
        },
        damageCountBackground: {
          ':before': {
            backgroundColor: 'unset',
          },
        },
        indexesColor: "#ffffff",
      },
    },
    schedule: {
      speedPanel: 200,

      cssRow: 'font-family: futura-pt, "sans-serif"; color:#141720; overflow: visible; border-right: 10px #ffffff solid; max-height: 120px;',
      cssRowNext: 'color:#141720; background: linear-gradient(0deg, #15ace2 0%,  #8de8f9 100%); border-left: unset; max-height: 120px;',
      cssRowFinished: '',

      cssDate: 'background-color: #000220; color: #ffffff; border-right: 10px #ffffff solid; font-family: dharma-gothic-e, sans-serif; font-weight: 700; font-size: 35px;',
      cssDateFinished: 'background-color: #000220; color: #ffffff;',
      cssDateNext: 'background-color: #000220; color: #8de8f9;',

      cssLogo: 'background-color: #000220; margin-right: 0; border-right: unset; border-left: unset;',
      cssLogoNext: 'background-color: #000220;',
      cssLogoLoser: 'background-color: #000220; ',
      cssLogoWinner: 'background-color: #000220;',

      cssName: 'background-color: #000220; color: #ffffff; font-size: 28px; letter-spacing: 1px; font-weight:700; font-family: proxima-nova, sans-serif;                ',
      cssNameNext: 'background: linear-gradient(0deg, #15ace2 0%,  #8de8f9 100%); color:#000220;',
      cssNameLoser: 'color:#ffffff4D; img{opacity: 0.3;}',
      cssNameWinner: '',

      cssVersus: 'font-size: 60px; background-color: #000220; color:#ffffff; margin-right: 0; margin-left: 0; font-weight:700; font-family: dharma-gothic-e, sans-serif;',
      cssVersusNext: 'background: linear-gradient(0deg, #15ace2 0%,  #8de8f9 100%); color:#000220; margin-right: 0; margin-left: 0;',

      cssScore: 'color:#8de8f9; background-color: #000220; font-size: 60px; font-weight:700; font-family: dharma-gothic-e, sans-serif;',
      cssScoreLoser: 'color:#8de8f94d; font-size: 60px; ',
      cssScoreWinner: 'font-size: 60px; ',

      trimColorNext: 'transparent',
      rightBorderColor: '#ffffff;',
      rightBorderColorLoser: '',
      cssBorders: `
          border: none;
          :before {
              content: '';
              width: 13px;
              height: 100%;
              left: -13px;
              position: absolute;
              background: linear-gradient(0deg, #8de8f9 0%, #15ace2 100%);
          }`,
    },
    screenTitle: {
      textColor: '#ffffff',
      cssTitle: "font-family: dharma-gothic-e, sans-serif; font-weight: 700; text-shadow: none; font-size: 130px; margin: auto; letter-spacing: 2px; ",
      textShadowLeft: '',
      textShadowRight: '',
    },
    standings: {
      showSubtext: false,
      displayWinLossSingleColumn: true,
      hideHeader: true,
      cssCell: '',
      cssRankCell: '',
      cssSubtextCell: '',
      cssLogoCell: '',
      cssNameCell: '',
      cssScoreCell: '',
      cssHeaderCell: '',
    },
  },
};
