// REACT
import React, { Component } from 'react';
// internal
import {i18n} from '../../i18n';
import Button from '../../Button';
import Picker from '../../Picker';
import SplitPicker from '../../Picker/SplitPicker';
import SeasonPicker from '../../Picker/SeasonPicker';
import StatInputContainer from './StatInput/Container';
import {leagues, seasons, splits} from '../../../util/const';
import { getTopPlayers, validLeaderboardStats } from '../../../services/leaderboardService';
import DataContainer from '../DataContainer';

class DataLeaderboard extends Component {

    state = {
        leaguesInformation: window.MainData.leaguesInformation,
    };

    componentDidMount = () => {
        this.setState({leaguesInformation: window.MainData.leaguesInformation}, async() => {
            const newState = Object.assign({}, this.state);
            const leagueInfo = window.MainData.schedule.league;
            const seasonInfo = window.MainData.schedule.season;
            const splitInfo = window.MainData.schedule.split;

            newState.league = leagueInfo;
            newState.season = seasonInfo;
            newState.split = splitInfo;

            this.setState(newState);
        });
    }

    updateLeague = value => {
        if (value) {
            this.setState({
                enableSubmitButton: true,
                league: value,
                season: Object.keys(this.state.leaguesInformation[value].seasons)[0],
                split: this.state.leaguesInformation[value].seasons[Object.keys(this.state.leaguesInformation[value].seasons)[0]][0]});
        }
    }

    updateSeason = value => {
        this.setState({
            enableSubmitButton: true,
            season: value,
            split: this.state.leaguesInformation[this.state.league].seasons[value][0],
        });
    }

    updateSplit = value => {
        this.setState({enableSubmitButton: true, split: value});
    }

    onDataSet = data => {
        const currentEntry = window.MainData.leaderboard.data[data.type][data.index - 1];
        const currentPlayer = currentEntry.player;
        const currentValue = currentEntry.data;
        const currentTeam = currentEntry.team;
        window.MainData.leaderboard.data[data.type][data.index - 1] = {
        player: data.player || currentPlayer,
        data: data.data || currentValue,
        team: data.team || currentTeam,
        };
        this.forceUpdate();
    };

    onUpdateChosenStat = (data) => {
        window.MainData.leaderboard.chosenStats[data.typeIndex - 1] = data.type;
    }

    injectLeaderboard = () => {
        const season = this.state.season;
        const league = this.state.league;
        const split = this.state.split;
        const numToReturn = 5;
        getTopPlayers(season, league, split, numToReturn)
            .then(topPlayerData => {
                Object.keys(validLeaderboardStats).forEach(statAccessor => {
                    const statUIName = validLeaderboardStats[statAccessor];
                    window.MainData.leaderboard.data[statUIName] = [];
                    for (let i = 0; i < topPlayerData[statAccessor].length; i += 1) {
                        const topStatEntry = topPlayerData[statAccessor][i];
                        window.MainData.leaderboard.data[statUIName][i] = {...topStatEntry, player: topStatEntry.summonerName, data: topStatEntry[statAccessor] };

                    }
                });

                window.MainData.leaderboard.league = leagues.filter(league => league.value === this.state.league)[0].value || null;
                window.MainData.leaderboard.season = seasons.filter(season => season.value === this.state.season)[0].value || null;
                window.MainData.leaderboard.split = splits[league].filter(split => split.value === this.state.split)[0].value || null;

                this.forceUpdate();
            });
    }

    checkLeaderboardDataValidation = leaderboardData => {
        var validtest = undefined;
        leaderboardData.forEach(stat => {
            if (!stat.player || !stat.data) validtest = false;
        });
        return validtest !== undefined ? validtest : undefined;
    }

    render () {
        return (

            <DataContainer componentSlug="leaderboard" icon="fas fa-child">
                <div className="data-panel__fieldset row">
                { this.state.leaguesInformation[this.state.league] &&
                    <div className="pill">
                        <Picker
                            items={[{name: this.state.league, value: this.state.league}] || {}}
                            preselect={this.state.league}
                            showSelectOption={false}
                        />
                        <SeasonPicker
                            season={this.state.leaguesInformation[this.state.league].seasons}
                            preselect={this.state.season}
                            onUpdate={this.updateSeason}
                        />
                        {this.state.leaguesInformation[this.state.league].seasons[this.state.season] ?
                            <SplitPicker
                                splits={this.state.leaguesInformation[this.state.league].seasons[this.state.season].reduce((acc, cur) => {acc[cur] = cur; return acc}, {})}
                                league={this.state.league}
                                preselect={this.state.split}
                                onUpdate={this.updateSplit}
                            />
                            :
                            <Picker
                                items={[]}
                                showSelectOption={false}
                            />
                        }
                        <Button icon="fas fa-download" action={this.injectLeaderboard} text={`${i18n().pages.leaderboard} ${i18n().general.autoLoad}`} />
                    </div>
                }
                </div>
                <div className="data-panel__fieldset row">
                    <StatInputContainer typeIndex={1} onChangeType={this.onUpdateChosenStat} onSet={this.onDataSet} defaultType="KP" stats={window.MainData.leaderboard.data} />
                    <StatInputContainer typeIndex={2} onChangeType={this.onUpdateChosenStat} onSet={this.onDataSet} defaultType="KILLS" stats={window.MainData.leaderboard.data} />
                    <StatInputContainer typeIndex={3} onChangeType={this.onUpdateChosenStat} onSet={this.onDataSet} defaultType="ASSISTS" stats={window.MainData.leaderboard.data} />
                </div>
            </DataContainer>
        );

    }

}

export default DataLeaderboard