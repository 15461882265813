// REACT
import React, { Component } from 'react';
// INTERNAL
import { /* send, */ subscribe } from '../../../services/api';
import ControlContainer from '../../Controller/ControlContainer';
import Button from '../../Button';
import validateDataSchedule from '../../Data/DataSchedule/validate';
import { i18n } from '../../i18n';
import {loadImageUrlPlayerResults} from '../../../util/loadImageUrl';
import { updateScreenStatus } from '../../../util/controllerUtils';
import PropTypes from 'prop-types';
class ResultsControl extends Component {

    state = {
        status: 'ready',
    }
    static propTypes = {
        cgpc: PropTypes.string,
        display: PropTypes.bool,
        screen1: PropTypes.bool,
      };
    componentDidMount() {
        this.runSubscription();
        if (validateDataSchedule()) {
            this.setState({status: 'ready'});
        } else {
            this.setState({status: 'invalid'});
        }
    }

    runSubscription = () => {
        subscribe('control-results', null, data => {
            if (data.target === 'control-results') {
                this.setState({status: data.status});
            }
        }, this.props.cgpc);
    };

    formatStandingsData = (standingsData, schedule, teams) => {
        const standingDataFormated = [];
        standingsData && standingsData.REGULAR && standingsData.REGULAR.forEach(team => {
            const teamInfo = {};
            const game = schedule.rounds[schedule.round].matches[schedule.match].games[schedule.game] || null
            if (Object.keys(game.teams).includes(team.tricode)) {
                teamInfo.lastMatchWin = game.winner && game.winner === team.tricode;
                const roster = {}
                roster.top = loadImageUrlPlayerResults(game.teams[team.tricode].roster.top.playerId.toLowerCase(), team, schedule.league, schedule.season, schedule.split);
                roster.jungle = loadImageUrlPlayerResults(game.teams[team.tricode].roster.jungle.playerId.toLowerCase(), team, schedule.league, schedule.season, schedule.split);
                roster.mid = loadImageUrlPlayerResults(game.teams[team.tricode].roster.mid.playerId.toLowerCase(), team, schedule.league, schedule.season, schedule.split);
                roster.adc = loadImageUrlPlayerResults(game.teams[team.tricode].roster.adc.playerId.toLowerCase(), team, schedule.league, schedule.season, schedule.split);
                roster.support = loadImageUrlPlayerResults(game.teams[team.tricode].roster.support.playerId.toLowerCase(), team, schedule.league, schedule.season, schedule.split);
                teamInfo.roster = roster;
                //
                teamInfo.standings = {
                    wins: team.win,
                    losses: team.loss,
                };
                teamInfo.tricode = team.tricode;
                teamInfo.color = teams[team.tricode].color;
                standingDataFormated.push(teamInfo);
            }
        })
        return standingDataFormated;
    }

    onPlay = () => {
        if (validateDataSchedule()) {
            const schedule = window.MainData.schedule;
            const gameId = schedule.game;
            const match = schedule.rounds[schedule.round].matches[schedule.match];
            const game = match.games[gameId];
            const gameTeam100 = Object.keys(game.teams).filter(t => { return game.teams[t].side === '100' })[0];
            const flipped = match.team100.tricode === gameTeam100 ? false : true;
            const output100 = flipped ? match.team200 : match.team100;
            const output200 = flipped ? match.team100 : match.team200;
            const teamsStanding = this.formatStandingsData(window.MainData.standings, window.MainData.schedule, window.MainData.teams);
            const sendData = { schedule: window.MainData.schedule, teams: window.MainData.teams, standings: window.MainData.standings, teamsStanding, output100, output200, flipped };
            // send('event', { target: 'view', action: 'open', screen: 'results', data: sendData });
            updateScreenStatus('results', 'open', sendData, null, this.props.cgpc);
        } else {
            this.setState({ status: 'invalid' });
        }
    }

    onClose = () => {
        // send('event', { target: 'view', action: 'close', screen: 'results' });
        updateScreenStatus('results', 'close', null, null, this.props.cgpc);
    }

    render() {
        return (
            <ControlContainer style={{display: this.props.display ? 'none': 'block'}} className="control--results" status={this.state.status}>
                <div className="control__panel">
                    <h2 className="control__title">
                        <i className="fas fa-trophy"></i> {i18n().pages.results}
                    </h2>
                    <Button controllerSide={!this.props.screen1} action={this.onPlay} text={i18n().general.open} icon="fas fa-play-circle" disabled={
                        (this.state.status === 'open' || this.state.status === 'opening') && true }
                    />
                    <Button controllerSide={!this.props.screen1} action={this.onClose} text={i18n().general.close} icon="fas fa-stop-circle" disabled={
                    !(this.state.status === 'open' || this.state.status === 'opening') && true }
                    />
                </div>
            </ControlContainer>
        )
    }
}

export default ResultsControl
