// REACT
import React, { Component } from 'react';
import PropTypes from 'prop-types';
// libraries
import { delay } from 'lodash';
// internal
// import { send, subscribe } from '../../../services/api';
import {i18n} from '../../i18n';
import DataContainer from '../DataContainer';
import Button from '../../Button';
import { getTweets, getPlayerTweets, getWinTweets } from '../../../util/controllerUtils';


class DataTwitter extends Component {

    state = {
        mvp: {
            team: '',
            players: {},
            timer: '03:00',
            time: 0,
        },
        teams: {
            matchup: {},
            votes: {},
        },
        tweets: {
            queries: [],
            response: [],
            selected: [],
        },
        tweetTeamEditable: false,
    };

    static propTypes = {
        schedule: PropTypes.object,
        teams: PropTypes.any,
    }

    componentDidMount() {
        // this.runSubscription();
        const teams = Object.assign({}, this.state.teams);
        teams.matchup.team100 = this.getMatchTeam('team100');
        teams.matchup.team200 = this.getMatchTeam('team200');
        this.setState({teams});
    }

    UNSAFE_componentWillReceiveProps() {
        const teams = Object.assign({}, this.state.teams);
        teams.matchup.team100 = this.getMatchTeam('team100');
        teams.matchup.team200 = this.getMatchTeam('team200');
        this.setState({teams});
    }

    // runSubscription = () => {
    //     subscribe(data => {
    //         if (!(data.target === 'data-twitter')) return;
    //         switch (data.type) {
    //             case 'playervotes':
    //                 this.updatePlayerVotes(data.data);
    //                 break;
    //             case 'teamvotes':
    //                 this.updateTeamVotes(data.data);
    //                 break;
    //             case 'tweets':
    //                 this.updateTweets(data.tweets);
    //                 break;
    //             default:
    //                 break;
    //         }
    //     });
    // }

    setMVPTeam = team => {
        const gameTeam = this.getGameRoster(team.tricode);
        const zeroPlayers = Object.keys(gameTeam).map(player => {
            return {id: gameTeam[player].playerId, name: gameTeam[player].name, hashtag: `${team.tricode}${gameTeam[player].name.replace(' ','')}`, tweets: 0}
        });
        const mvp = { team: team.tricode, players: zeroPlayers, timer: '03:00'};
        this.setState({mvp});
        window.MainData.twitter.mvp = mvp;
    }

    getGameRoster = team => {
        const schedule = this.props.schedule;
        const roundId = schedule.round;
        const matchId = schedule.match;
        const gameId = schedule.game;
        return schedule.rounds[roundId].matches[matchId].games ? schedule.rounds[roundId].matches[matchId].games[gameId].teams[team].roster : {};
    }

    getMatchTeam = side => {
        const schedule = this.props.schedule;
        const roundId = schedule.round;
        const matchId = schedule.match;
        const team = this.props.schedule.rounds[roundId].matches[matchId][side];
        return team;
    }

    startCollectingMVP = () => {
        // const hashtags = this.state.mvp.players.map(player => player.hashtag);
        // const data = { hashtags };
        // send('playervotes', { data });
        getPlayerTweets(this.state.mvp.players.map(player => player.hashtag), data => this.updatePlayerVotes(data));
        this.runTimer(180);
    }

    startCollectingTeam = () => {
        // const data = {matchup: [this.state.teams.matchup.team100.tricode, this.state.teams.matchup.team200.tricode]};
        // send('teamvotes', { data });
        if (this.state.tweetTeamEditable) {
            this.setState({ tweetTeamEditable: false });
        }
        getWinTweets([this.state.teams.matchup.team100.tricode, this.state.teams.matchup.team200.tricode], data => this.updateTeamVotes(data.data));
    }

    updatePlayerVotes = data => {
        const mvp = this.state.mvp;
        Object.keys(this.state.mvp.players).map(player =>
            mvp.players[player].tweets = data[mvp.players[player].hashtag] || 0
        );
        this.setState({mvp});
        window.MainData.twitter.mvp = mvp;
    }

    updateTeamVotes = data => {
        console.log("DATTA", data);
        const teams = this.state.teams;
        teams.votes = data;
        this.setState({teams});
        window.MainData.twitter.teams = teams;
    }

    runTimer = setTime => {
        const mvp = this.state.mvp;
        let time = (setTime) ? setTime : (mvp.time > 0) ? (mvp.time - 1) : 0;
        time = time < 0 ? 0 : time;
        mvp.time = time;
        mvp.timer = this.formatTimer(time);
        this.setState({mvp});

        if (time > 0) delay(() => {this.runTimer()}, 1000);
    }

    formatTimer = time => {
        const mins = Math.floor(time/60);
        const secs = time % 60;
        return `${(mins < 10 ? '0' + mins : mins)}:${(secs < 10 ? '0' + secs : secs)}`;
    }

    setTweetSearchTerm = (term, state) => {
        const tweets = this.state.tweets;
        tweets.queries[term] = state;
        this.setState({tweets});
    }

    fetchTweets = () => {
        const tweets = this.state.tweets;
        const queries = tweets.queries;
        const data = Object.keys(queries).filter(term => queries[term]).map(hashtag => (hashtag));
        // send('starttweets', { data });
        getTweets(data, data => this.updateTweets(data.data));
        tweets.selected = [];
        this.setState({tweets});
    }

    updateTweets = data => {
        const tweets = this.state.tweets;
        tweets.response = data;
        this.setState({tweets});
        window.MainData.twitter.tweets = tweets;
    }

    selectTweet = (id, addThis) => {
        const tweets = this.state.tweets;
        if (addThis) {
            tweets.selected.push(id);
        } else {
            const index = tweets.selected.indexOf(id);
            tweets.selected.splice(index, 1);
        }
        this.setState({tweets});
        window.MainData.twitter.tweets = tweets;
    }


    render() {
        let team100Hashtag = (this.getMatchTeam('team100') && this.getMatchTeam('team100').tricode  && `${this.getMatchTeam('team100').tricode.toUpperCase()}WIN`) || '';
        let team200Hashtag = (this.getMatchTeam('team100') && this.getMatchTeam('team200').tricode  && `${this.getMatchTeam('team200').tricode.toUpperCase()}WIN`)|| '';

        // const seasonTeams = Object.keys(this.props.teams).map(team => ({tricode: team, hashtag: `${team.toUpperCase()}WIN`}));
        return ([
            <DataContainer key={1} componentSlug="twitterTeams" icon="fab fa-twitter">
                {this.state.tweetTeamEditable && <p style={{ color: 'red' }}>{i18n().general.contentEditable}</p>}
                <div className="data-panel__fieldset">
                    <div className="row row--inset" style={{ border: `${this.state.tweetTeamEditable? '3px red solid': 'none' }` }}>
                        {
                            ['team100', 'team200'].map((side, i) => {
                                const teamHashtag= (side === 'team100') ? team100Hashtag: team200Hashtag;
                                if(!teamHashtag) return <div/>
                                return (<div key={i} className="column column--center">
                                    <p>#{teamHashtag}</p>
                                    <div className="box" suppressContentEditableWarning contentEditable={this.state.tweetTeamEditable?'true':'false'} id={`${side}-WinTweets`}>{this.state.teams.votes[teamHashtag] || 0}</div>
                                </div>)
                            })
                        }
                    </div>
                </div>
                <div className="data-panel__fieldset">
                    <div className="row">
                        <div className="pill">
                            <Button action={this.startCollectingTeam} text={i18n().general.tweetDownload} />
                        </div>
                        {this.state.tweetTeamEditable ?
                            <div className="pill">
                                <Button
                                    action={() => {
                                        this.setState({ tweetTeamEditable: false })
                                        let team100Votes = Number.parseInt(document.getElementById("team100-WinTweets").innerHTML, 10);
                                        let team200Votes = Number.parseInt(document.getElementById("team200-WinTweets").innerHTML, 10);

                                        if (Number.isNaN(team100Votes) || Number.isNaN(team200Votes)) {
                                            window.alert(i18n().general.invalidNumbers)
                                        }
                                        else {
                                            const data = {};
                                            data[team100Hashtag] = team100Votes;
                                            data[team200Hashtag] = team200Votes;
                                            this.updateTeamVotes(data)
                                        }
                                    }}
                                    text={i18n().general.tweetSaveNewNumber}
                                />
                            </div>
                            :
                            <div className="pill">
                                <Button action={() => { this.setState({ tweetTeamEditable: true }) }} text={i18n().general.tweetEditNumber} />
                            </div>
                        }
                    </div>
                </div>
            </DataContainer>,
            // <DataContainer key={2} componentSlug="twitterTweets" icon="fab fa-twitter">
            //     <div className="data-panel__fieldset">
            //         <label className="data-panel__fieldset__label">{i18n().general.searchFor}</label>
            //         <div className="data-panel__fieldset__body">
            //             <div className="pill">
            //                 {
            //                     seasonTeams.map((team, i) => {
            //                         const added = this.state.tweets.queries[team.hashtag];
            //                         return (
            //                             <Button key={i} className={added ? 'button--highlighted' : ''} action={() => this.setTweetSearchTerm(team.hashtag, (added ? false : true))} text={`#${team.hashtag}`} />
            //                         )
            //                     })
            //                 }
            //             </div><br />
            //             <div className="pill">
            //                 <Button action={this.fetchTweets} text={i18n().general.tweetDownload} />
            //             </div>
            //         </div>
            //     </div>
            //     <div className="data-panel__fieldset">
            //         {
            //             this.state.tweets.response && this.state.tweets.response.length > 0 &&
            //             <div className="tweet-list-container">
            //                 <div className="tweet-list-header row">
            //                     <div className="pill">
            //                         <span>{i18n().general.tweetCount}</span>
            //                         <span>{this.state.tweets.selected.length || 0}</span>
            //                     </div>
            //                 </div>

            //                 <div className="tweet-list">
            //                     {
            //                         this.state.tweets.response.map((tweet, i) => {
            //                             const selected = this.state.tweets.selected.includes(tweet.id);
            //                             return (
            //                                 <div key={i} className={`tweet-list__item ${selected ? 'tweet-list__item--selected' : ''}`} onClick={selected ? () => this.selectTweet(tweet.id, false) : () => this.selectTweet(tweet.id, true)}>
            //                                     <img className="tweet-list__item__icon" src={tweet.user.profile_image_url} alt={tweet.user.name} />
            //                                     <span className="tweet-list__item__name">{tweet.user.name}</span>
            //                                     <span className="tweet-list__item__followers">({tweet.user.followers_count} followers)</span>
            //                                     <span className="tweet-list__item__datetime">{tweet.created_at}</span>
            //                                     <span className="tweet-list__item__text">{tweet.text}</span>
            //                                 </div>
            //                             )
            //                         })
            //                     }
            //                 </div>
            //             </div>
            //         }
            //     </div>
            // </DataContainer>,
            // <DataContainer key={3} componentSlug="twitterPlayers" icon="fab fa-twitter">
            //     <div className="data-panel__fieldset">
            //         <label className="data-panel__fieldset__label">{i18n().general.mvpVote}</label>
            //         <div className="data-panel__fieldset__body">
            //             <div className="pill">
            //                 <span>{i18n().general.selectTeam}</span>
            //                 {
            //                     ['team100', 'team200'].map((side, i) => {
            //                         const team = this.getMatchTeam(side);
            //                         if(!team || !team.tricode) return <div/>
            //                         return (
            //                             <Button key={i} text={team.name} action={() => this.setMVPTeam(team)} className={(this.state.mvp.team === team.tricode) ? 'button--highlighted' : ''} />
            //                         );
            //                     })
            //                 }
            //             </div>
            //         </div>
            //     </div>
            //     <div className="data-panel__fieldset">
            //         <div className="row row--inset">
            //             {
            //                 Object.keys(this.state.mvp.players).map((player, i) => {
            //                     return (<div key={i} className="column column--center">
            //                         <p>#{this.state.mvp.players[player].hashtag}</p>
            //                         <div className="box">{this.state.mvp.players[player].tweets}</div>
            //                     </div>)
            //                 })
            //             }
            //         </div>
            //     </div>
            //     {
            //         Object.keys(this.state.mvp.players).length ?
            //         <div className="data-panel__fieldset">
            //             <div className="row">
            //                 <div className="pill">
            //                     <Button action={this.startCollectingMVP} text={i18n().general.collectTweets} />
            //                     <span>{this.state.mvp.timer}</span>
            //                 </div>
            //             </div>
            //         </div> : <div></div>
            //     }
            // </DataContainer>,
        ]);

    }

}

export default DataTwitter
