import _ from 'lodash';
import '@firebase/database';
import  firebase  from 'firebase/compat/app';
import 'firebase/compat/database'
import { getEnvironment, isLocal } from './constants';

export const config = {
    apiKey: 'AIzaSyAGo1M9n3uT8b9ZAZnDF9pmUsZYxuAwOAs',
    authDomain: 'spiritual-hour-122502.firebaseapp.com',
    databaseURL: 'https://spiritual-hour-122502.firebaseio.com',
    projectId: 'spiritual-hour-122502',
    storageBucket: 'spiritual-hour-122502.appspot.com',
    messagingSenderId: '230738871311',
};

let cgpcKey = 'cgpc1';
export const setCgpcKey = key => {
    window.MainData.key = key
    cgpcKey = key;
}

let firebaseInitialized = false;
let firebaseInitializedPromise = null;
export const initializeFirebase = async () => {
    if (!firebaseInitialized) {
        if (!firebaseInitializedPromise) {
            // eslint-disable-next-line no-async-promise-executor
            firebaseInitializedPromise = new Promise(async resolve => {
                await firebase.initializeApp(config);
                firebaseInitialized = true;
                resolve();
            });
        }
        await firebaseInitializedPromise;
    }
}

export const firebaseRealtimeDbSetValue = async (relativePath, value, key = cgpcKey) => {
    try {
        if (!firebaseInitialized) {
            await initializeFirebase();
        }
        const db = firebase.database();
        let ref;
        let developerId;

        // let refPath;

        if (isLocal()) {
            try{
                developerId = require('../developerId.json');
            } catch (err) {
                developerId = require('../developerId.example.json');
            }
            ref = db.ref(`local/ljl/data/${developerId.id}/${key}/${relativePath}`);
            // refPath = `local/ljl/data/${developerId.id}/${key}/${relativePath}`;
        } else {
            const env = getEnvironment();
            ref = db.ref(`${env}/ljl/data/${key}/${relativePath}`);
            // refPath = `${env}/ljl/data/${key}/${relativePath}`;
        }
        const newValue = _.cloneDeepWith(value, val => {
            if (val === undefined) {
                return null;
            }
        });
        // console.log(ref, `Ref in firebaseRealtimeDbSetValue, refPath: ${refPath}`, `newValue: ${newValue}`, `environment: ${getEnvironment()}`);
        await ref.set(newValue);
    } catch (error) {
        console.error('firebaseRealtimeDbSetValue Realtime DB Update Stream error', error, relativePath, value, key, getEnvironment()); // eslint-disable-line no-console
    }
    return {};
}

export const firebaseRealtimeDbSetData = async (relPath, updatedState) => {
    try {
        if (!firebaseInitialized) {
            await initializeFirebase();
        }
        const newState = _.cloneDeepWith(updatedState, value => {
            if (value === undefined) {
                return null;
            }
        });
        const db = firebase.database();
        const firebasePath = `${getEnvironment()}/ljl/gameEvents`;
        const dbRef = db.ref(`${firebasePath}/${relPath}`);
        await dbRef.set(newState);

    } catch (error) {
        console.log('firebaseRealtimeDbSetData error ', error, relPath, updatedState);
    }
};

export const firebaseRealtimeDbSetDataCgpc = async (relPath, newValue) => {
    try {
        if (!firebaseInitialized) {
            await initializeFirebase();
        }
        const db = firebase.database();
        const firebasePath = `${getEnvironment()}/ljl/cgpc`;
        const dbRef = db.ref(`${firebasePath}/${relPath}`);
        await dbRef.set(newValue);

    } catch (error) {
        console.log('error ', error, relPath, newValue);
    }
};

export const firebaseRealtimeListenToPath = async (relativePath, callback, key = cgpcKey) => {
    try {
        if (!firebaseInitialized) {
            await initializeFirebase();
        }
        const db = firebase.database();
        let ref;
        let developerId;
        if (isLocal()) {
            try{
                developerId = require('../developerId.json');
            } catch (err) {
                developerId = require('../developerId.example.json');
            }
            ref = db.ref(`local/ljl/data/${developerId.id}/${key}/${relativePath}`);
        } else {
            const env = getEnvironment();
            ref = db.ref(`${env}/ljl/data/${key}/${relativePath}`);
        }
        ref.on('value', snapshot => {
            const data = snapshot.val();
            if (data) {
                callback(data);
            }
        });
    } catch (error) {
        console.error('firebaseRealtimeListenToPath Realtime DB Update Stream error', error, relativePath, callback, key); // eslint-disable-line no-console
    }
    return {};
};

export const firebaseRealtimeGetValue= async (relativePath, key = cgpcKey) => {
    try {
        if (!firebaseInitialized) {
            await initializeFirebase();
        }
        const db = firebase.database();
        let ref;
        let developerId;
        if (isLocal()) {
            try{
                developerId = require('../developerId.json');
            } catch (err) {
                developerId = require('../developerId.example.json');
            }
            ref = db.ref(`local/ljl/data/${developerId.id}/${key}/${relativePath}`);
        } else {
            const env = getEnvironment();
            ref = db.ref(`${env}/ljl/data/${key}/${relativePath}`);
        }
        let data = await ref.once('value')
        return data.val();

    } catch (error) {
        console.error('firebaseRealtimeGetValue Realtime DB Update Stream error', error, relativePath, key); // eslint-disable-line no-console
    }
    return {};
};

let dbRef = {};

// screenNumber should be 1-10
export const firebaseRealtimeDbUpdateStream = async (screenNumber, updatedState) => {
    try {
        if (!firebaseInitialized) {
            await initializeFirebase();
        }
        if (!dbRef[screenNumber]) {
            const db = firebase.database();
            dbRef[screenNumber] = db.ref(`${getEnvironment()}/ljl/cgpc/${screenNumber - 1}`);
        }
        await dbRef[screenNumber].set(updatedState);
    } catch (error) {
        console.error('firebaseRealtimeDbUpdateStream Realtime DB Update Stream error', error, screenNumber, updatedState); // eslint-disable-line no-console
    }
};

export const firebaseRealtimeDbUpdateAllStreams = async updatedStates => {
    try {
        if (!firebaseInitialized) {
            await initializeFirebase();
        }
        updatedStates.forEach(state => {
            if (state.screenNumber) {
                firebaseRealtimeDbUpdateStream(state.screenNumber, state);
            } else {
                console.error('FirfirebaseRealtimeDbUpdateAllStreamsebase Realtime DB Update Stream error missing screenNumber'); // eslint-disable-line no-console
            }
        });
    } catch (error) {
        console.error('firebaseRealtimeDbUpdateAllStreams Realtime DB Update ALL Streams error ', error, updatedStates); // eslint-disable-line no-console
    }
}

const eventFields = ['epic_monster_kill', 'epic_monster_spawn', 'item_destroyed', 'building_destroyed', 'pause_started', 'pause_ended', 'queued_dragon_info'];

let processedLjlSequenceIds = {};

let eventSourceRef = {};
let stopNotifyHooks = {};

/**
 * Read game events from firebase.
 * Note: all events without this gameID will be deleted.
 */
export const readGameEvents = async (gameID, platformID, callback, stopNotifyHook, fieldsToListenOn = eventFields) => {
    try {
        if (!firebaseInitialized) {
            await initializeFirebase();
        }
        fieldsToListenOn.forEach(field => {
            if (!eventSourceRef[field]) {
                const db = firebase.database();
                eventSourceRef[field] = db.ref(`${getEnvironment()}/ljl/gameEvents/${platformID}/${gameID}/${field}`);
            }
            stopNotifyHooks[field] = (stopNotifyHooks[field] || []).concat([stopNotifyHook]);

            eventSourceRef[field].on('value', snapshot => {
                const data = snapshot.val();
                if (data) {
                    if (['teamOne', 'teamTwo'].includes(field)) {
                        data && callback(_.values(data).sort((a, b) => a.arrayIndex - b.arrayIndex));
                    } else if ('bannedChampions' === field) {
                        data && callback(data);
                    } else {
                        if (data) {
                            const events = _.map(data, (element, sequenceID) => Object.assign({
                                sequenceID,
                            }, element));
                            if (!processedLjlSequenceIds[field]) {
                                processedLjlSequenceIds[field] =[];
                            }
                            if (field === 'pause_ended') {
                                if (events.length > 1) {
                                    const lastResume = events.sort((a, b) => Date.parse(b.date) - Date.parse(a.date)).pop();
                                    callback(Object.assign({
                                        gameID,
                                        platformID,
                                        action: field,
                                    }, lastResume));
                                } else if (events[0]){
                                    callback(Object.assign({
                                        gameID,
                                        platformID,
                                        action: field,
                                    }, events[0]));
                                }
                            } else {
                                events.filter(event => !processedLjlSequenceIds[field].includes(event.sequenceID)).forEach(newEvent => {
                                    callback(Object.assign({
                                        gameID,
                                        platformID,
                                        action: field,
                                    }, newEvent));
                                    processedLjlSequenceIds[field].push(newEvent.sequenceID);
                                });
                            }

                        }
                    }
                }
            });
        });
    } catch (error) {
        console.error('readGameEvents Realtime DB Update Stream error', error, gameID, platformID, callback, stopNotifyHook, fieldsToListenOn); // eslint-disable-line no-console
    }
    return {};
};
export const stopListening = (fields = eventFields) => {
    if (fields && fields.length > 0) {
        fields.forEach(key => {
            if (eventSourceRef[key]) {
                eventSourceRef[key].off('value');
                delete eventSourceRef[key];
            }
            processedLjlSequenceIds[key] = [];
            if (stopNotifyHooks[key]) {
                stopNotifyHooks[key].forEach(hook => {
                    hook(key);
                });
                delete stopNotifyHooks[key];
            }
        })
    } else {
        Object.keys(eventSourceRef).forEach(key => {
            eventSourceRef[key].off('value');
            if (stopNotifyHooks[key]) {
                stopNotifyHooks[key].forEach(hook => {
                    hook(key);
                });
                delete stopNotifyHooks[key];
            }
        });
        processedLjlSequenceIds = {};
        eventSourceRef = {};
    }
}
