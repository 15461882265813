import React from 'react';
import PropTypes from 'prop-types';
import DataContainer from '../DataContainer';
import { delay, get } from 'lodash';
import { getTheme } from '../../View/themes';

import {
    Base,
    Teams,
    Block,
    Column,
    SubBlock,
    StyleInhibitorContainer,
    StyleDragonsChoicesContainer,
    StyleDragonTypeChoicesContainer,
    StyleDragonsChoice,
    StyleFieldGridTimerContainer,
    StyleFieldGridLiveContainer,
    StyleFieldGridBuffsContainer,
    StyleBuffLabel,
    StyleSeparator,
    StyleBaronVideoSponsor,
} from './styles';
import { i18n } from '../../i18n';
import Button from '../../Button';
import Picker from '../../Picker';
import { firebaseRealtimeDbSetValue } from '../../../util/firebase';
// import TournamentRealmPicker from '../../Picker/TournamentRealmPicker';
import { createIntegerRangeSet } from '../../../util/factory';

//Assets
const chemtechDragonIcon = 'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/chemtech_drake_icon_60x60.png';
const hextechDragonIcon = 'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/hextech_drake_icon_60x60.png';
const infernalDragonIcon = 'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/infernal_drake_icon_60x60.png';
const mountainDragonIcon = 'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/mountain_drake_icon_60x60.png';
const cloudDragonIcon = 'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/cloud_drake_icon_60x60.png';
const oceanDragonIcon = 'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/ocean_drake_icon_60x60.png';
const heraldIcon = 'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/herald_icon_60x60.png';
const baronIcon = 'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/baron_icon_60x60.png';


const timersItems = [
    { name: '1:00', value: '60' },
    { name: '2:00', value: '120' },
    { name: '3:00', value: '180' },
    { name: '4:00', value: '240' },
    { name: '5:00', value: '300' },
    { name: '6:00', value: '360' },
]

const initialVisible = {
    inhibitor100: false,
    inhibitor100Top: false,
    inhibitor100Mid: false,
    inhibitor100Bottom: false,
    inhibitor200: false,
    inhibitor200Top: false,
    inhibitor200Mid: false,
    inhibitor200Bottom: false,
    // dragCloud: false,
    // dragInfernal: false,
    // dragMountain: false,
    // dragOcean: false,
    // dragonElder: false,
    // dragCloudLive: false,
    // dragInfernalLive: false,
    // dragMountainLive: false,
    // dragOceanLive: false,
    // dragonElderLive: false,
    // dragCloudNormal: false,
    // dragInfernalNormal: false,
    // dragMountainNormal: false,
    // dragOceanNormal: false,
    // dragonElderNormal: false,
    baron: false,
    buffElder100: false,
    buffElderLong100: false,
    buffBaron100: false,
    buffElder200: false,
    buffElderLong200: false,
    buffBaron200: false,
    herald: false,
    heraldLive: false,
    dragonSelected: false,
    dragonSelectedLive: false,
};
class DataGameTimers extends React.Component {

    static propTypes = {
        filterRunningTimers: PropTypes.func,
        runningTimers: PropTypes.array,
        onUpdate: PropTypes.func.isRequired,
        // status: PropTypes.string,
        visibility: PropTypes.object,
        dragonSelected: PropTypes.string,
        dragon2Selected: PropTypes.string,
        dragonSubtypeSelected: PropTypes.string,
        showPopupVideo: PropTypes.bool,
        dragonTokens: PropTypes.object,
        controllerSide: PropTypes.bool,
    }

    constructor(props) {
        super(props);
        this.state = {
            dragonSelected: props.dragonSelected || 'Infernal',     // 'Infernal' (default), 'Mountain', 'Ocean' or 'Cloud'
            dragonSubtypeSelected: props.dragonSubtypeSelected || 'normal',           // 'normal' (default), 'rift', 'elder'
            dragon2Selected: props.dragon2Selected || 'herald',     // 'herald' (default) or 'baron'

            timerPeriodSelected: 120,
            realtimeListenerIsUpToDate: false,

            platform: 'ESPORTSTMNT01',
            nextDragonType: null, // 'Infernal', 'Mountain', 'Ocean' or 'Cloud'
            // eslint-disable-next-line react/no-unused-state
            nextDragonSubtype: null, // 'normal' (default), 'rift', 'elder'
            baronBuffTeam: '100', // 100 (Left/Blue) or 200 (Right/Red)
            elderBuffTeam: '100', // 100 (Left/Blue) or 200 (Right/Red)
            nextDragonSpawnGameTime: null,
            listeningGameLiveStats: false,
            elderDragonCounter: 0,
            dragonKills100: 0,
            dragonKills200: 0,
            goldDifferenceOffset: 0,
            goldDifference: 0,
            timers: {
                inhibitor100Top: '5:00',
                inhibitor100Mid: '5:00',
                inhibitor100Bottom: '5:00',
                inhibitor200Top: '5:00',
                inhibitor200Mid: '5:00',
                inhibitor200Bottom: '5:00',

                buffElder100: '2:30',
                buffElderLong100: '2:30',
                buffBaron100: '3:00',
                buffElder200: '2:30',
                buffElderLong200: '2:30',
                buffBaron200: '3:00',

                herald: '2:00',
                baron: '2:00',
                dragCloud: '2:00',
                dragInfernal: '2:00',
                dragMountain: '2:00',
                dragOcean: '2:00',
                dragChemtech: '2:00',
                dragHextech: '2:00',
                dragonElder: '2:00',
                dragonSelected: '2:00',
            },
            visible: this.props.visibility || {
                ...initialVisible,
            },
            // getApiContext: () => null,
            callbackIds: {},
            gameTimePause: false,
        };
        this.timers = {
            inhibitor100Top: '300',
            inhibitor100Mid: '300',
            inhibitor100Bottom: '300',
            inhibitor200Top: '300',
            inhibitor200Mid: '300',
            inhibitor200Bottom: '300',
            dragCloud: '120',
            dragInfernal: '120',
            dragMountain: '120',
            dragChemtech: '120',
            dragHextech: '120',
            dragOcean: '120',
            dragonElder: '120',
            dragonSelected: '120',
            buffElder100: '150',
            buffElderLong100: '150',
            buffBaron100: '180',
            buffElder200: '150',
            buffElderLong200: '150',
            buffBaron200: '180',
            herald: '120',
            baron: '120',
        };
        this.timersString = {
            inhibitor100Top: '5:00',
            inhibitor100Mid: '5:00',
            inhibitor100Bottom: '5:00',
            inhibitor200Top: '5:00',
            inhibitor200Mid: '5:00',
            inhibitor200Bottom: '5:00',
            dragCloud: '2:00',
            dragInfernal: '2:00',
            dragMountain: '2:00',
            dragChemtech: '2:00',
            dragHextech: '2:00',
            dragOcean: '2:00',
            dragonElder: '2:00',
            dragonSelected: '2:00',
            buffElder100: '3:00',
            buffElderLong100: '3:00',
            buffBaron100: '3:00',
            buffElder200: '3:00',
            buffElderLong200: '3:00',
            buffBaron200: '3:00',
            herald: '2:00',
            baron: '2:00',
        };
        window.MainData.dataGameTimers = {
            component: this,
        };
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        const changedState = {};
        let changed = false;
        if (nextProps.visibility && nextProps.visibility !== prevState.visible) {
            changedState.visible = nextProps.visibility;
            changed = true;
        }
        if (nextProps.dragonSelected && nextProps.dragonSelected !== prevState.dragonSelected) {
            changedState.dragonSelected = nextProps.dragonSelected;
            changed = true;
        }
        if (nextProps.dragonSubtypeSelected && nextProps.dragonSubtypeSelected !== prevState.dragonSubtypeSelected) {
            changedState.dragonSubtypeSelected = nextProps.dragonSubtypeSelected;
            changed = true;
        }
        if (changed) {
            return changedState;
        }
        else return prevState;
    }

    gameTimerJobId = null;

    timerIsRunning = timerName => {
        for (let i = 0; i < this.props.runningTimers.length; i++) {
            if (this.props.runningTimers[i].timer === timerName) {
                return true;
            }
        }
        return false;
    }

    // runningTimers = [];
    runTimer = (time, timer) => {
        this.timersString[timer] = this.formatTimer(time);

        let startJob = false;
        if (this.props.runningTimers.length === 0) {
            startJob = true;
        }
        this.props.runningTimers.push({
            initialTimerTimeSeconds: time,
            initialSystemTimeMs: this.getCurrentTimestamp(),
            timer,
        });
        if (startJob) {
            this.runTimerRecursive();
        }
        // reset all button fix.
        window.MainData.dataGameTimers = {
            component: this,
        };
    }

    pausedVisible = null;

    resumeTimers = timePausedMs => {
        this.props.runningTimers.forEach(timer => {
            timer.initialSystemTimeMs += timePausedMs;
            if (this.timerData[timer.timer]) {
                this.timerData[timer.timer].initialSystemTimeMs = timer.initialSystemTimeMs;
            }
        });
        this.props.onUpdate('timerData', this.timerData);
        this.runTimerRecursive();
        // const promises = [];
        // this.props.runningTimers.forEach(timer => {
        //     promises.push(firebaseRealtimeDbSetValue(`control-gameScreen/data/timerData/${timer.timer}`, timer));
        // });
        // Promise.all(promises).then(() => {
        this.props.onUpdate('gameScreenAssets', { ...this.pausedVisible });
        this.setState({ visible: { ...this.pausedVisible } });
        // });
    }

    runTimerRecursive = () => {
        const timers = { ...this.state.timers };
        const visible = { ...this.state.visible };
        const timersToRemove = [];
        this.props.runningTimers.forEach(timerData => {
            const timer = timerData.timer;
            const initialTimerTimeSeconds = timerData.initialTimerTimeSeconds;
            const initialSystemTimeMs = timerData.initialSystemTimeMs;

            const trueTime = initialTimerTimeSeconds - ((this.getCurrentTimestamp() - initialSystemTimeMs) / 1000);
            const time = trueTime < 0 ? '' : Math.round(trueTime);
            if (time > 0 && timers[timer]) {
                timers[timer] = this.formatTimer(time);
            } else {
                visible[timer] = false;
                timers[timer] = '0:00';
                timersToRemove.push(timer);
            }
        });
        this.setState({ timers, visible }, () => {
            // this.props.onUpdate('gameScreenTimers', {...this.state.timers});
            // this.props.onUpdate('gameScreenAssets', { ...this.state.visible });
            timersToRemove.forEach(timer => {
                timers[timer] = this.timersString[timer];
            });
            this.setState({ timers });
        });
        if (timersToRemove.length > 0) {
            // this.props.runningTimers = this.props.runningTimers.filter(timer => !timersToRemove.includes(timer.timer));
            this.props.filterRunningTimers(timersToRemove)
            this.props.onUpdate('gameScreenAssets', { ...visible });
        }
        if (this.props.runningTimers.length > 0 && !this.state.gameTimePause) {
            delay(() => { this.runTimerRecursive() }, 200);
        }
    }

    timerGameTimeTriggers = {
        herald: false,
        heraldLive: false,
        baron: false,
        baronLive: false,
        dragon: false,
        dragonLive: false,
        baronRespawn: false,
        baronRespawnLive: false,
    }

    runGameTimer = async (initialGameTimeMs, initialSystemTimeMs, resetTriggers = false) => {
        if (resetTriggers) {
            this.timerGameTimeTriggers = {
                herald: false,
                heraldLive: false,
                baron: false,
                baronLive: false,
                dragon: false,
                dragonLive: false,
                baronRespawn: false,
                baronRespawnLive: false,
            };
        }
        const trueTimeMs = initialGameTimeMs + (Date.now() - initialSystemTimeMs);
        let time = trueTimeMs < 0 ? -1 : Math.round(trueTimeMs / 1000);
        //Herald spawn: 590 | show timer: 470
        if (this.state.realtimeListenerIsUpToDate) {
            const heraldTriggerTime = 360 + window.MainData.realtimeApi.liveStatusDelay;
            const heraldLiveTriggerTime = 480 + window.MainData.realtimeApi.liveStatusDelay;
            const baronTriggerTime = 1080 + window.MainData.realtimeApi.liveStatusDelay;
            const baronLiveTriggerTime = 1200 + window.MainData.realtimeApi.liveStatusDelay;
            if (!this.timerGameTimeTriggers['herald'] && time >= heraldTriggerTime) {
                this.timerGameTimeTriggers['herald'] = true;
                await this.runTimerAndDisplay('herald', time - heraldTriggerTime);
            }
            if (!this.timerGameTimeTriggers['heraldLive'] && time >= heraldLiveTriggerTime) {
                this.timerGameTimeTriggers['heraldLive'] = true;
                await new Promise(resolve => this.setVisible('heraldLive', resolve));
            }
            if (!this.timerGameTimeTriggers['baron'] && time >= baronTriggerTime) {
                if (this.state.visible['herald']) {
                    await this.setHide('herald');
                }
                if (this.state.visible['heraldLive']) {
                    await this.setHide('heraldLive');
                }
                this.timerGameTimeTriggers['baron'] = true;
                await this.runTimerAndDisplay('baron', time - baronTriggerTime);

            }
            if (!this.timerGameTimeTriggers['baronLive'] && time >= baronLiveTriggerTime) {
                this.timerGameTimeTriggers['baronLive'] = true;
                await new Promise(resolve => this.setVisible('baronLive', resolve));
            }
            if (!this.timerGameTimeTriggers['dragon'] && this.state.nextDragonSpawnGameTime) {
                if (time < this.state.nextDragonSpawnGameTime + window.MainData.realtimeApi.liveStatusDelay) {
                    const previewDragonTimerLength = parseInt(this.timers['dragonSelected'], 10);
                    const dragonPreviewTriggerTime = this.state.nextDragonSpawnGameTime + window.MainData.realtimeApi.liveStatusDelay - previewDragonTimerLength;
                    if (time >= dragonPreviewTriggerTime) {
                        this.timerGameTimeTriggers['dragon'] = true;
                        // if (this.state.nextDragonType && this.state.nextDragonType !== this.state.dragonSelected) { // removing this now to support manual override -stevenm 2020-01-28
                        //     // this.setDragon(this.state.nextDragonType);
                        //     this.runTimerAndDisplay('dragonSelected', time - dragonPreviewTriggerTime);
                        // } else {
                        await this.runTimerAndDisplay('dragonSelected', time - dragonPreviewTriggerTime);
                        // }
                    }
                }
            }

            if (this.state.nextDragonSpawnGameTime && !this.timerGameTimeTriggers['dragonLive']) {
                if (time >= this.state.nextDragonSpawnGameTime + window.MainData.realtimeApi.liveStatusDelay) {
                    this.timerGameTimeTriggers['dragonLive'] = true;
                    await new Promise(resolve => this.setVisible('dragonSelectedLive', resolve));
                }
            }


            const nextBaronTime = this.state.baronKilledTime + 240 + window.MainData.realtimeApi.liveStatusDelay;
            const nextBaronLiveTime = this.state.baronKilledTime + 360 + window.MainData.realtimeApi.liveStatusDelay;
            if (this.state.baronKilledTime && !this.state.visible.baronLive) {
                if (!this.timerGameTimeTriggers['baronRespawn'] && time >= nextBaronTime) {
                    if (this.state.visible['herald']) {
                        await this.setHide('herald');
                    }
                    if (this.state.visible['heraldLive']) {
                        await this.setHide('heraldLive');
                    }
                    this.timerGameTimeTriggers['baronRespawn'] = true;
                    await this.runTimerAndDisplay('baron', time - nextBaronTime);
                }

                if (!this.timerGameTimeTriggers['baronRespawnLive'] && time >= nextBaronLiveTime) {
                    this.timerGameTimeTriggers['baronRespawnLive'] = true;
                    await new Promise(resolve => this.setVisible('baronLive', resolve));
                }
            }
            //Baron spawn (First kill)): 1200 | show timer: 1080
            //Baron After first kill: killTimeInSeconds + 360 | timer: killTimeInSeconds + 240

            if (this.state.heraldKilledTime && !this.state.visible.heraldLive && (this.state.heraldKilledTime + 240 < 960)) { // don't show herald timer if it will be cut off by baron
                const nextHeraldTime = this.state.heraldKilledTime + 240 + window.MainData.realtimeApi.liveStatusDelay;
                const nextHeraldLiveTime = this.state.heraldKilledTime + 360 + window.MainData.realtimeApi.liveStatusDelay;
                if (!this.timerGameTimeTriggers['heraldRespawn'] && this.state.heraldKilledTime && time >= nextHeraldTime) {
                    this.timerGameTimeTriggers['heraldRespawn'] = true;
                    await new Promise(resolve => this.setVisible('herald', resolve));
                    await this.runTimerAndDisplay('herald', time - nextHeraldTime);
                }
                if (!this.timerGameTimeTriggers['heraldRespawnLive'] && this.state.heraldKilledTime && time >= nextHeraldLiveTime) {
                    this.timerGameTimeTriggers['heraldRespawnLive'] = true;
                    await new Promise(resolve => this.setVisible('heraldLive', resolve));
                }
            }
        }

        if (time >= 0 && !this.state.gameTimePause) {
            time = this.formatTimer(time, true);
            this.setState({ gameTime: time }, () => {
                this.gameTimerJobId = delay(() => { this.runGameTimer(initialGameTimeMs, initialSystemTimeMs) }, 300);
            });
        }
    }

    resetMetadata = async () => {
        await new Promise(resolve => this.setState({
            baronKilledTime: null,
            heraldKilledTime: null,
        }, resolve));
    }

    formatTimer = (time, decimal = false) => {
        const mins = Math.floor(time / 60);
        const secs = time % 60;
        return decimal ?
            time === '' ? '' : `${(mins < 10 ? '0' + mins : mins)}:${(secs < 10 ? '0' + secs : secs)}`
            :
            time === '' ? '' : `${mins}:${(secs < 10 ? '0' + secs : secs)}`;
    }

    resetTimer = async (timer) => {
        if (this.timerIsRunning(timer)) {
            const timers = { ...this.state.timers };
            timers[timer] = '';
            await new Promise(resolve => this.setState({ timers }, resolve));
        }
    }

    resetTimers = async (timersArr) => {
        const timers = { ...this.state.timers };
        timersArr.forEach((timer) => {
            if (this.timerIsRunning(timer)) {
                timers[timer] = '';
            }
        });
        await new Promise(resolve => this.setState({ timers }, resolve));
    }

    setVisible = async (type, callback) => {
        const visible = { ...this.state.visible };
        visible[type] = true;
        // await new Promise(resolve => this.setState({ visible: this.turnOffOtherAssets(type, visible) }, () => {
        //     this.props.onUpdate('gameScreenAssets', { ...this.state.visible });
        //     resolve();
        // }));
        this.props.onUpdate('gameScreenAssets', this.turnOffOtherAssets(type, visible), callback);
        // this.props.onUpdate('gameScreenAssets', visible);
    }

    turnOffOtherAssets = (displayingItem, visible) => {
        const turnOffVisible = (key, keyNameSubstring) => {
            if (displayingItem.includes(keyNameSubstring) && key !== displayingItem && key.includes(keyNameSubstring)) {
                visible[key] = false;
                return true;
            }
            return false;
        }
        const keys = Object.keys(visible);
        keys.forEach(key => {
            turnOffVisible(key, 'drag') || turnOffVisible(key, 'buffElder') ||
                turnOffVisible(key, 'buffBaron') || turnOffVisible(key, 'baron') || turnOffVisible(key, 'herald');
        });
        return visible;
    }

    setHide = async (type) => {
        const visible = { ...this.state.visible };
        visible[type] = false;
        await new Promise(resolve => this.setState({ visible }, resolve));
        await new Promise(resolve => this.props.onUpdate('gameScreenAssets', visible, resolve));
    }

    setHideMany = async (typesArr) => {
        const visible = { ...this.state.visible };
        typesArr.forEach(type => {
            visible[type] = false;
        });
        await new Promise(resolve => this.setState({ visible, dragon2Selected: 'herald' }, resolve));
        await new Promise(resolve => this.props.onUpdate('gameScreenAssets', visible, resolve));
        await new Promise(resolve => this.props.onUpdate('dragonSelected', 'Infernal', resolve));
        await new Promise(resolve => this.props.onUpdate('dragonSubtypeSelected', 'normal', resolve));
    }

    timerData = {};
    setTimer = async (timer, offsetSeconds = 0, force = false) => {
        if (!this.timerIsRunning(timer) || force) {
            let remainingTime;
            if (timer === 'dragonSelected') {
                remainingTime = (parseInt(this.state.timerPeriodSelected, 10) - offsetSeconds);
            } else {
                remainingTime = (parseInt(this.timers[timer], 10) - offsetSeconds);
            }
            if (remainingTime > 0) {
                this.runTimer(remainingTime.toString(), timer);
                this.timerData[timer] = {
                    initialTimerTimeSeconds: remainingTime,
                    initialSystemTimeMs: this.getCurrentTimestamp(),
                }
                await firebaseRealtimeDbSetValue(`control-gameScreen/data/timerData/${timer}`, this.timerData[timer]);
                this.props.onUpdate('timerData', this.timerData);
                return true;
            } else {
                console.log('NO TIME REMAINING ON  TIMER', timer);
            }
        }
        return false;
    }

    runTimerAndDisplay = async (timer, offsetSeconds = 0, time, force) => {
        const isTimerValid = await this.setTimer(timer, offsetSeconds, force)
        isTimerValid && (await new Promise(resolve => this.setVisible(timer, resolve)));
        if (timer === 'buffBaron200') { this.setState({ baronBuffTeam: '200' }) }
        else if (timer === 'buffBaron100') { this.setState({ baronBuffTeam: '100' }) }
    }

    resetAndHide = async timer => {
        await this.setHide(timer);
        await new Promise(resolve => this.resetTimer(timer, resolve));
    }

    stopListeningGameLiveStats = () => {
        // stopListening();
        this.state.callbackIds && Object.keys(this.state.callbackIds).forEach(key => {
            window.MainData.dataApiListener.component.unregisterCallback(key, this.state.callbackIds[key]);
        });
        this.setState({ listeningGameLiveStats: false });
        this.setState({ gameStarted: false}, () => {
            firebaseRealtimeDbSetValue(`control-gameScreen/data/isGameStarted`, this.state.gameStarted);
        })
    }


    getCurrentTimestamp = () => {
        return Date.now() + window.MainData.dataApiListener.component.dateSimulationOffsetMs;
    }

    /**
     * If Current time is greater than timeMs, the diff will be positive.
     */
    getTimeDiff = timeMs => timeMs ? this.getCurrentTimestamp() - timeMs : this.getCurrentTimestamp();

    pauseStartTime = null;

    dragonMap = {
        air: 'dragCloud',
        earth: 'dragMountain',
        elder: 'dragonElder',
        fire: 'dragInfernal',
        chemtech: 'dragonChemtech',
        hextech: 'dragonHextech',
        water: 'dragOcean',
    };

    dragonSelectionMap = {
        air: 'Cloud',
        earth: 'Mountain',
        fire: 'Infernal',
        water: 'Ocean',
        chemtech: 'Chemtech',
        hextech: 'Hextech',
        elder: 'Infernal', // to prevent crashes.
    }

    processLiveStatsDelay = func => {
        if (window.MainData.realtimeApi.liveStatusDelay) {
            setTimeout(func, window.MainData.realtimeApi.liveStatusDelay * 1000);
        } else {
            func();
        }
    }

    getTimeDiffSec = liveStats => {
        let timeDiffMs = this.getTimeDiff(Date.parse(liveStats.date)) - (window.MainData.realtimeApi.liveStatusDelay * 1000);
        if (timeDiffMs < 0) {
            timeDiffMs = 0;
        }
        return Math.round(timeDiffMs / 1000);
    };

    pauseStartedFunction = async liveStats => {
        this.checkIfUpToDate(liveStats);
        this.addToProcessedSequences(liveStats);
        console.log('pause started', liveStats);
        this.pausedVisible = { ...this.state.visible };
        this.props.onUpdate('gameScreenAssets', { ...initialVisible });
        this.setState({
            gameTimePause: true,
            visible: { ...initialVisible },
        });
        this.pauseStartTime = this.getCurrentTimestamp() - this.getTimeDiff(Date.parse(liveStats.date));

        this.isGameStarted = false;
        this.props.onUpdate('isGameStarted', this.isGameStarted, () => firebaseRealtimeDbSetValue(`control-gameScreen/data/isGameStarted`, this.isGameStarted));

        this.props.onUpdate('isGamePaused', true, () => firebaseRealtimeDbSetValue(`control-gameScreen/data/isGamePaused`, true));
    };

    pauseEndedFunction = async liveStats => {
        this.checkIfUpToDate(liveStats);
        console.log('pause ended', liveStats, 'liveStatusDelay', window.MainData.realtimeApi.liveStatusDelay);
        const resume = async () => {
            await new Promise(resolve => this.setState({ gameTimePause: false }, () => {
                let timeDiffAfterDelay = this.getTimeDiff(Date.parse(liveStats.date));
                if (timeDiffAfterDelay < 0) {
                    timeDiffAfterDelay = 0;
                }

                this.isGameStarted = true;
                this.props.onUpdate('isGameStarted', this.isGameStarted, () => firebaseRealtimeDbSetValue(`control-gameScreen/data/isGameStarted`, this.isGameStarted))

                this.props.onUpdate('isGamePaused', false, () => firebaseRealtimeDbSetValue(`control-gameScreen/data/isGamePaused`, false));

                // this.initialGameTime = Math.round((liveStats.gameTime + timeDiffAfterDelay) - (window.MainData.realtimeApi.liveStatusDelay * 1000));
                // this.props.onUpdate('initialGameTime', this.initialGameTime, () => firebaseRealtimeDbSetValue(`control-gameScreen/data/initialGameTime`, this.initialGameTime))

                this.initialGameTime = Math.round(liveStats.gameTime + timeDiffAfterDelay);
                this.props.onUpdate('initialGameTime', this.initialGameTime, () => firebaseRealtimeDbSetValue(`control-gameScreen/data/initialGameTime`, this.initialGameTime))

                this.initialSystemTime = Date.now();
                this.props.onUpdate('initialSystemTime', this.initialSystemTime, () => firebaseRealtimeDbSetValue(`control-gameScreen/data/initialGameTime`, this.initialSystemTime))

                this.runGameTimer(Math.round(liveStats.gameTime + timeDiffAfterDelay), Date.now(), liveStats.gameTime === 0);
                this.resumeTimers(this.getTimeDiff(this.pauseStartTime) - (window.MainData.realtimeApi.liveStatusDelay * 1000));
                resolve();
            }));
        };
        if (!this.state.gameTimePause) {
            this.isGameStarted = true;
            this.props.onUpdate('isGameStarted', this.isGameStarted, () => firebaseRealtimeDbSetValue(`control-gameScreen/data/isGameStarted`, this.isGameStarted))

            this.gameTimerJobId && clearTimeout(this.gameTimerJobId);
            let timeDiffAfterDelay = this.getTimeDiff(Date.parse(liveStats.date));
            if (timeDiffAfterDelay < 0) {
                timeDiffAfterDelay = 0;
            }

            // this.initialGameTime = Math.round((liveStats.gameTime + timeDiffAfterDelay) - (window.MainData.realtimeApi.liveStatusDelay * 1000));
            // this.props.onUpdate('initialGameTime', this.initialGameTime, () => firebaseRealtimeDbSetValue(`control-gameScreen/data/initialGameTime`, this.initialGameTime))

            this.initialGameTime = Math.round(liveStats.gameTime + timeDiffAfterDelay);
            this.props.onUpdate('initialGameTime', this.initialGameTime, () => firebaseRealtimeDbSetValue(`control-gameScreen/data/initialGameTime`, this.initialGameTime))

            this.initialSystemTime = Date.now();
            this.props.onUpdate('initialSystemTime', this.initialSystemTime, () => firebaseRealtimeDbSetValue(`control-gameScreen/data/initialGameTime`, this.initialSystemTime))

            this.runGameTimer(Math.round(liveStats.gameTime + timeDiffAfterDelay), Date.now(), liveStats.gameTime === 0);
        } else {
            await resume();
        }
    };

    isGameStarted = false;
    resetIsGameStarted = () => {
        this.isGameStarted = false;
        this.props.onUpdate('isGameStarted', this.isGameStarted, () => firebaseRealtimeDbSetValue(`control-gameScreen/data/isGameStarted`, this.isGameStarted));
    }

    initialGameTime = 0;
    resetInitialGameTime = () => {
        this.initialGameTime = 0;
        this.props.onUpdate('initialGameTime', this.initialGameTime,  () => firebaseRealtimeDbSetValue(`control-gameScreen/data/initialGameTime`, this.initialGameTime));
    }

    initialSystemTime = 0;
    resetInitialSystemTime = () => {
        this.initialSystemTime = 0;
        this.props.onUpdate('initialSystemTime', this.initialSystemTime, () => firebaseRealtimeDbSetValue(`control-gameScreen/data/initialSystemTime`, this.initialSystemTime));
    }

    buildingDestroyedFunction = async liveStats => {
        this.checkIfUpToDate(liveStats);
        this.addToProcessedSequences(liveStats);
        const timeDiffSec = this.getTimeDiffSec(liveStats);
        if (timeDiffSec < 300 && liveStats.buildingType === 'inhibitor') {
            const inhibitorTime = Math.round(300 - timeDiffSec);
            if (inhibitorTime > 0) {
                let lane = liveStats.lane;
                if (liveStats.teamID === 100 && !this.state.visible.inhibitor100) {
                    this.setVisible('inhibitor100');
                }
                if (liveStats.teamID === 200 && !this.state.visible.inhibitor200) {
                    this.setVisible('inhibitor200');
                }
                if (lane === 'bot') {
                    this.timers[`inhibitor${liveStats.teamID}Bottom`] = inhibitorTime;
                    this.timersString[`inhibitor${liveStats.teamID}Bottom`] = this.formatTimer(inhibitorTime);
                    this.runTimerAndDisplay(`inhibitor${liveStats.teamID}Bottom`); // no need to pass time diff since its incorporated into inhibitorTime
                } else {
                    this.timers[`inhibitor${liveStats.teamID}${lane.charAt(0).toUpperCase()}${lane.slice(1)}`] = inhibitorTime;
                    this.timersString[`inhibitor${liveStats.teamID}${lane.charAt(0).toUpperCase()}${lane.slice(1)}`] = this.formatTimer(inhibitorTime);
                    this.runTimerAndDisplay(`inhibitor${liveStats.teamID}${lane.charAt(0).toUpperCase()}${lane.slice(1)}`); // no need to pass time diff since its incorporated into inhibitorTime
                }
            }
        }
    };

    queuedDragonInfoFunction = async liveStats => {
        this.checkIfUpToDate(liveStats);
        this.addToProcessedSequences(liveStats);

        if (liveStats.nextDragonName === 'elder') {
            await new Promise(resolve => this.setState({
                nextDragonSubType: 'elder',
                nextDragonSpawnGameTime: liveStats.nextDragonSpawnTime,
            }, resolve));
            this.setDragonSubtypeSelected('elder');
            console.log('state', this.state.nextDragonSubType, this.state.dragonSelected);
        } else {
            await new Promise(resolve => this.setState({
                nextDragonType: this.dragonSelectionMap[liveStats.nextDragonName],
                nextDragonSpawnGameTime: liveStats.nextDragonSpawnTime,
            }, resolve));
            this.setDragon(this.dragonSelectionMap[liveStats.nextDragonName]);
            if (this.state.dragonKills200 + this.state.dragonKills100 < 2) {
                this.setDragonSubtypeSelected('normal');
            }
            console.log('state', this.state.nextDragonType, this.state.dragonSelected);
        }
        this.timerGameTimeTriggers['dragon'] = false;
        this.timerGameTimeTriggers['dragonLive'] = false;
    };

    epicMonsterKillFunction = async liveStats => {
        this.checkIfUpToDate(liveStats);
        this.addToProcessedSequences(liveStats);
        const timeDiffSec = this.getTimeDiffSec(liveStats);
        switch (liveStats.monsterType) {
            case 'dragon':
                this.setHide('dragonSelectedLive');
                if (liveStats.dragonType === 'elder') {
                    let elderDragonCounter = this.state.elderDragonCounter;
                    // if (elderDragonCounter === 0) {
                    await new Promise(resolve => this.setState({ elderDragonCounter: elderDragonCounter + 1 }, () => {
                        const timerName = `buffElder${liveStats.killerTeamID}`;
                        if (timeDiffSec < this.timers[timerName]) {
                            this.runTimerAndDisplay(timerName, timeDiffSec);
                        }
                        resolve();
                    }));
                    // } else {
                    //     const timerName = `buffElderLong${liveStats.killerTeamID}`;
                    //     if (timeDiffSec < this.timers[timerName]) {
                    //         this.runTimerAndDisplay(timerName, timeDiffSec);
                    //     }
                    // }
                } else {
                    const dragonTokens = this.props.dragonTokens;
                    if (liveStats.killerTeamID === 100) {
                        const dragonKills = this.state.dragonKills100 + 1;
                        const update = {
                            dragonKills100: dragonKills,
                        };
                        if (liveStats.dragonType && dragonTokens) {
                            dragonTokens.teamLeft[dragonKills] = this.dragonSelectionMap[liveStats.dragonType];
                            if (dragonKills === 4) {
                                dragonTokens.teamLeft.soul = this.dragonSelectionMap[liveStats.dragonType];
                            }
                        }
                        if (this.state.dragonKills200 + dragonKills === 2) {
                            console.log('Next dragon is Rift');
                            update.dragonSubtypeSelected = 'rift';
                        }
                        this.setState(update, () => {
                            this.props.onUpdate('dragonTokens', dragonTokens);
                            if (update.dragonSubtypeSelected) {
                                this.props.onUpdate('dragonSubtypeSelected', 'rift');
                            }
                        });
                    } else {
                        const dragonKills = this.state.dragonKills200 + 1;
                        const update = {
                            dragonKills200: dragonKills,
                        };
                        if (liveStats.dragonType && dragonTokens) {
                            dragonTokens.teamRight[dragonKills] = this.dragonSelectionMap[liveStats.dragonType];
                            if (dragonKills === 4) {
                                dragonTokens.teamRight.soul = this.dragonSelectionMap[liveStats.dragonType];
                            }
                        }
                        if (this.state.dragonKills100 + dragonKills === 2) {
                            console.log('Next dragon is Rift');
                            update.dragonSubtypeSelected = 'rift';
                        }
                        this.setState(update, () => {
                            this.props.onUpdate('dragonTokens', dragonTokens);
                            if (update.dragonSubtypeSelected) {
                                this.props.onUpdate('dragonSubtypeSelected', 'rift');
                            }
                        });
                    }
                }
                break;
            case 'baron': {
                const timerName = `buffBaron${liveStats.killerTeamID}`;
                this.setHide('baronLive');
                console.log('baron died');
                this.props.onUpdate('showPopupVideo', true); //Show Sponsor Baron Video
                //get the goldDifference Offset as soon as the baron died to not miss the extra Gold earned
                await new Promise(resolve => this.setState({
                    goldDifferenceOffset: this.getGoldOffset(liveStats.killerTeamID),
                    goldDifference: 0,
                }, resolve));
                if (timeDiffSec < this.timers[timerName]) {
                    await this.runTimerAndDisplay(timerName, timeDiffSec);
                }
                this.setState({
                    baronKilledTime: Math.round((liveStats.gameTime / 1000)),
                }, () => {
                    this.timerGameTimeTriggers['baronRespawn'] = false;
                    this.timerGameTimeTriggers['baronRespawnLive'] = false;
                });
                setTimeout(() => { this.listenGoldDifference(); }, 500);
            }
                break;
            case 'riftHerald':
                this.setHide('heraldLive');
                this.setState({ heraldKilledTime: Math.round((liveStats.gameTime / 1000)) }, () => {
                    this.timerGameTimeTriggers['heraldRespawn'] = false;
                    this.timerGameTimeTriggers['heraldRespawnLive'] = false;
                });
                break;
            default:
                break;
        }
    };

    // Get the difference of gold between the two team when baron get killed
    getGoldOffset = (killerTeamID) => {
        const teamsGoldCount = window.MainData.dataApiListener.component.getTeamGoldCount();
        let currentGold = Object.values(teamsGoldCount);
        let currentGoldBuffTeam = 0;
        let currentGoldNonBuffTeam = 0;
        currentGold.forEach(team => {
            if (team.side === `${killerTeamID}`) { currentGoldBuffTeam = team.gold; }
            else { currentGoldNonBuffTeam = team.gold; }
        });
        this.props.onUpdate('goldDifference', 0);
        return ((currentGoldBuffTeam - currentGoldNonBuffTeam) || 0);
    }

    listenGoldDifference = () => {
        if (this.state.visible.buffBaron100 || this.state.visible.buffBaron200 || this.state.gameTimePause) {
            const teamsGoldCount = window.MainData.dataApiListener.component.getTeamGoldCount();
            let currentGold = Object.values(teamsGoldCount);
            let currentGoldBuffTeam = 0;
            let currentGoldNonBuffTeam = 0;
            currentGold.forEach(team => {
                if (team.side === this.state.baronBuffTeam) { currentGoldBuffTeam = team.gold; }
                else { currentGoldNonBuffTeam = team.gold; }
            });
            if (!this.state.goldDifferenceOffset) {
                this.setState({
                    goldDifferenceOffset: this.getGoldOffset(this.state.baronBuffTeam),
                    goldDifference: 0,
                }, () => {
                    this.props.onUpdate('goldDifference', 0);
                });
            }
            else {
                this.setState({
                    goldDifference: ((currentGoldBuffTeam - currentGoldNonBuffTeam) - this.state.goldDifferenceOffset),
                }, () => {
                    this.props.onUpdate('goldDifference', this.state.goldDifference);
                });
            }
            setTimeout(() => { this.listenGoldDifference() }, 1000);
        }
        else {
            this.setState({
                goldDifference: 0,
                goldDifferenceOffset: null,
            }, () => {
                this.props.onUpdate('goldDifference', 0)
            });
        }
    }

    processedSequenceIndexes = {};
    addToProcessedSequences = event => {
        this.processedSequenceIndexes[`${this.state.platform}_${this.state.gameId}`].add(parseInt(event.sequenceIndex, 10));
    }
    checkIfUpToDate = liveStatsEvent => {
        if (!this.state.realtimeListenerIsUpToDate && liveStatsEvent.date) {
            if (this.getCurrentTimestamp() - (Date.parse(liveStatsEvent.date) + (window.MainData.realtimeApi.liveStatusDelay * 1000)) <= 5000) { // don't process stuff from over 5s outdated
                console.log('realtime listener is up to date.', liveStatsEvent.action);
                this.setState({
                    realtimeListenerIsUpToDate: true,
                });
            }
        }
    }

    listenGameLiveStats = () => {

        // if (!this.state.gameId) {
        //     console.error('The game ID has not been set to listen livestats'); // eslint-disable-line no-console
        //     return null;
        // }
        this.resetKillCount();
        this.resetTurretsDestroyed();
        this.resetIsGameStarted();
        this.resetInitialGameTime();
        this.resetInitialSystemTime();

        this.setState({ listeningGameLiveStats: true, realtimeListenerIsUpToDate: false }, () => {

            if (!this.processedSequenceIndexes[`${this.state.platform}_${this.state.gameId}`]) {
                this.processedSequenceIndexes[`${this.state.platform}_${this.state.gameId}`] = createIntegerRangeSet();
            }

            const processedSequenceSet = this.processedSequenceIndexes[`${this.state.platform}_${this.state.gameId}`];

            const callbackIds = {};
            callbackIds['pause_started'] = window.MainData.dataApiListener.component.registerCallback('pause_started', this.pauseStartedFunction, processedSequenceSet);
            callbackIds['pause_ended'] = window.MainData.dataApiListener.component.registerCallback('pause_ended', this.pauseEndedFunction, processedSequenceSet);
            callbackIds['building_destroyed'] = window.MainData.dataApiListener.component.registerCallback('building_destroyed', this.anyBuildingDestroyedFunction, processedSequenceSet);
            callbackIds['queued_dragon_info'] = window.MainData.dataApiListener.component.registerCallback('queued_dragon_info', this.queuedDragonInfoFunction, processedSequenceSet);
            callbackIds['epic_monster_kill'] = window.MainData.dataApiListener.component.registerCallback('epic_monster_kill', this.epicMonsterKillFunction, processedSequenceSet);
            callbackIds['stats_update'] = window.MainData.dataApiListener.component.registerCallback('stats_update', this.checkIfUpToDate);
            callbackIds['champion_kill'] = window.MainData.dataApiListener.component.registerCallback('champion_kill', this.killCountFunction, processedSequenceSet);

            this.setState({
                // getApiContext: () => context,
                callbackIds,
            })
        });
    }

    anyBuildingDestroyedFunction = async liveStats => {
        if (liveStats.buildingType === 'inhibitor') {
            this.buildingDestroyedFunction(liveStats);
        }

        if (liveStats.buildingType === 'turret') {
            this.turretDestroyedFunction(liveStats);
        }
    }

    turretsDestroyed = {
        team100: 0,
        team200:0,
    }
    turretDestroyedFunction = async liveStats => {
        // this.addToProcessedSequences(liveStats);
        const destroyedTeam = `team${liveStats.teamID}`;
        let destroyerTeam;
        destroyerTeam = (destroyedTeam === 'team100') ? 'team200' : 'team100';

        this.turretsDestroyed[destroyerTeam] =  this.turretsDestroyed[destroyerTeam] + 1;
        this.props.onUpdate('turretsDestroyed', this.turretsDestroyed, () => firebaseRealtimeDbSetValue(`control-gameScreen/data/turretsDestroyed`, this.turretsDestroyed))
    }

    resetTurretsDestroyed = () => {
        this.turretsDestroyed = {
            team100: 0,
            team200:0,
        }
        this.props.onUpdate('turretsDestroyed', this.turretsDestroyed, () => firebaseRealtimeDbSetValue(`control-gameScreen/data/turretsDestroyed`, this.turretsDestroyed))
    }

    killCount = {
        team100: 0,
        team200: 0,
    }
    killCountFunction = async liveStats => {
        // this.addToProcessedSequences(liveStats);
        const killerTeam = `team${liveStats.killerTeamID}`;
        if (liveStats.killer >= 1 && liveStats.killer <= 10) {
            this.killCount[killerTeam] = this.killCount[killerTeam] + 1;
            this.props.onUpdate('killCount', this.killCount, () => firebaseRealtimeDbSetValue(`control-gameScreen/data/killCount`, this.killCount))
        }
    }

    resetKillCount = () => {
        this.killCount = {
            team100: 0,
            team200: 0,
        }
        this.props.onUpdate('killCount', this.killCount, () => firebaseRealtimeDbSetValue(`control-gameScreen/data/killCount`, this.killCount))
    }

    handleChangeGameId = (e) => this.setState({ gameId: e.target.value });

    setDragon = (dragonSelected) => {
        // this.props.onUpdate('dragonSelected', dragonSelected);
        (this.state.dragonSelected !== dragonSelected) && this.setState({
            dragonSelected,
        }, () => {
            this.props.onUpdate('dragonSelected', dragonSelected);
            // callback && callback();
        });
    }

    setDragonSubtypeSelected = dragonSubtypeSelected => {
        (this.state.dragonSubtypeSelected !== dragonSubtypeSelected) && this.setState({
            dragonSubtypeSelected,
        }, () => {
            this.props.onUpdate('dragonSubtypeSelected', dragonSubtypeSelected);
        });
    }

    isTimerDragonActive = () => {
        return (
            this.state.visible.dragonSelected
        );
    }

    isLiveDragonActive = () => {
        return (
            this.state.visible.dragonSelectedLive
        );
    }

    resetAllDragonKills = async () => {
        await new Promise(resolve => this.setState({
            dragonKills100: 0,
            dragonKills200: 0,
        }, resolve));
    }

    resetGameTime = async () => {
        clearTimeout(this.gameTimerJobId);
        await new Promise(resolve => this.setState({ gameTime: '00:00' }, resolve));
    }

    formatGameTimeWithDelay = time => {
        if (window.MainData.realtimeApi.liveStatusDelay && time) {
            const splitted = time.split(':');
            const currentGameTime = parseInt(splitted[0], 10) * 60 + parseInt(splitted[1], 10) - window.MainData.realtimeApi.liveStatusDelay;
            return this.formatTimer(currentGameTime);
        }
        return time
    }

    render() {
        const theme = getTheme(window.MainData.theme) || {};
        return (
            <DataContainer componentSlug="dataGameTimer" icon="fas fa-stopwatch">
                <div className="data-panel__fieldset" style={{ overflowX: 'auto', flexDirection: 'column' }}>
                    <div className="pill">
                        {/* <span>{`Game ID`}</span>
                            <input id="game-id-label" className="input input--x-wide" value={this.state.gameId} onChange={this.handleChangeGameId}></input> */}
                        {this.state.listeningGameLiveStats ?
                            <Button controllerSide={this.props.controllerSide} className={`button interactive `} action={() => this.stopListeningGameLiveStats()} text="STOP" />
                            :
                            <Button controllerSide={this.props.controllerSide} className={`button interactive `} action={() => this.listenGameLiveStats()} text="LISTEN" />}
                    </div>
                    <div className="pill">
                        <span>{`Game Time`}</span>
                        <span>{this.formatGameTimeWithDelay(this.state.gameTime)}</span>
                        <Button controllerSide={this.props.controllerSide} className={`button interactive `} action={this.resetGameTime} text="RESET" />
                        <Button controllerSide={this.props.controllerSide} className={`button interactive `} action={() => {
                            if (this.state.gameTimePause) {
                                this.setState({ gameTimePause: false }, () => {
                                    if (this.state.gameTime) {
                                        const splitted = this.state.gameTime.split(':');
                                        const currentGameTime = parseInt(splitted[0], 10) * 60 + parseInt(splitted[1], 10);
                                        this.runGameTimer(currentGameTime * 1000, Date.now(), false);
                                    }
                                    this.resumeTimers(this.getTimeDiff(this.pauseStartTime));
                                    this.props.onUpdate('isGamePaused', false, () => firebaseRealtimeDbSetValue(`control-gameScreen/data/isGamePaused`, false))

                                    this.isGameStarted = true;
                                    this.props.onUpdate('isGameStarted', this.isGameStarted, () => firebaseRealtimeDbSetValue(`control-gameScreen/data/isGameStarted`, this.isGameStarted));
                                });
                            } else {
                                this.pausedVisible = { ...this.state.visible };
                                this.props.onUpdate('gameScreenAssets', { ...initialVisible });
                                this.props.onUpdate('isGamePaused', true, () => firebaseRealtimeDbSetValue(`control-gameScreen/data/isGamePaused`, true))
                                this.setState({
                                    gameTimePause: true,
                                    visible: { ...initialVisible },
                                });
                                this.pauseStartTime = this.getCurrentTimestamp();

                                this.isGameStarted = false;
                                this.props.onUpdate('isGameStarted', this.isGameStarted, () => firebaseRealtimeDbSetValue(`control-gameScreen/data/isGameStarted`, this.isGameStarted));
                            }
                        }} text={`${this.state.gameTimePause ? 'UNPAUSE' : 'PAUSE'}`}
                        />
                    </div>
                    <div className="pill">
                        <span>{`BLUE DRAGON KILLS`}</span>
                        <span>{this.state.dragonKills100 || 0}</span>
                        <Button controllerSide={this.props.controllerSide} className={`button interactive `} action={() => this.setState({ dragonKills100: 0 })} text="RESET" />
                    </div>
                    <div className="pill">
                        <span>{`RED DRAGON KILLS`}</span>
                        <span>{this.state.dragonKills200 || 0}</span>
                        <Button controllerSide={this.props.controllerSide} className={`button interactive `} action={() => this.setState({ dragonKills200: 0 })} text="RESET" />
                    </div>

                    <div className="pill">
                        <span>{`LEFT TEAM KILL COUNT`}</span>
                        <span>{this.killCount.team100 || 0}</span>
                        <Button controllerSide={this.props.controllerSide} className={`button interactive `} action={() => {
                            this.killCount.team100 = 0;
                            this.props.onUpdate('killCount', this.killCount, () => firebaseRealtimeDbSetValue(`control-gameScreen/data/killCount`, this.killCount))
                        }} text="RESET"
                        />
                    </div>
                    <div className="pill">
                        <span>{`RIGHT TEAM KILL COUNT`}</span>
                        <span>{this.killCount.team200 || 0}</span>
                        <Button controllerSide={this.props.controllerSide} className={`button interactive `} action={() => {
                            this.killCount.team200 = 0;
                            this.props.onUpdate('killCount', this.killCount, () => firebaseRealtimeDbSetValue(`control-gameScreen/data/killCount`, this.killCount))
                        }} text="RESET"
                        />
                    </div>

                    <div className="pill">
                        <span>{`LEFT TEAM TURRETS DESTROYED`}</span>
                        <span>{this.turretsDestroyed.team100 || 0}</span>
                        <Button controllerSide={this.props.controllerSide} className={`button interactive `} action={() => {
                            this.turretsDestroyed.team100 = 0;
                            this.props.onUpdate('turretsDestroyed', this.turretsDestroyed, () => firebaseRealtimeDbSetValue(`control-gameScreen/data/turretsDestroyed`, this.turretsDestroyed))
                        }} text="RESET"
                        />
                    </div>
                    <div className="pill">
                        <span>{`RIGHT TEAM TURRETS DESTROYED`}</span>
                        <span>{this.turretsDestroyed.team200 || 0}</span>
                        <Button controllerSide={this.props.controllerSide} className={`button interactive `} action={() => {
                            this.turretsDestroyed.team200 = 0;
                            this.props.onUpdate('turretsDestroyed', this.turretsDestroyed, () => firebaseRealtimeDbSetValue(`control-gameScreen/data/turretsDestroyed`, this.turretsDestroyed))
                        }} text="RESET"
                        />
                    </div>

                    <Base>
                        <Block>
                            {/* INHIBITORS */}
                            <h1>Inhibitors</h1>
                            <SubBlock>
                                <Teams>
                                    <StyleInhibitorContainer>
                                        <div></div>
                                        {/* <label className="control-extra__fieldset__label">Team Left/Blue</label> */}
                                        {/* <label className="control-extra__fieldset__label">Team Right/Red</label> */}
                                        {/* <label className="control-extra__fieldset__label">Display</label>
                                        <div className={`pill game-sides--100`}>
                                             <span className={this.state.visible.inhibitor100 ? 'pill__span--highlighted' : ''}>
                                                {this.state.visible.inhibitor100 ? i18n().controller.viewable.true : i18n().controller.viewable.false}
                                            </span>
                                            {
                                                this.state.visible.inhibitor100 ?
                                                    <div className="button interactive" onClick={() => this.setHide('inhibitor100')}>Hide</div>
                                                    :
                                                    <div className="button interactive" onClick={() => this.setVisible('inhibitor100')}>Display</div>
                                            }
                                        </div>
                                        <div className={`pill game-sides--200`}>
                                             <span className={this.state.visible.inhibitor200 ? 'pill__span--highlighted' : ''}>
                                                {this.state.visible.inhibitor200 ? i18n().controller.viewable.true : i18n().controller.viewable.false}
                                            </span>
                                            {
                                                this.state.visible.inhibitor200 ?
                                                    <div className="button interactive" onClick={() => this.setHide('inhibitor200')}>Hide</div>
                                                    :
                                                    <div className="button interactive" onClick={() => this.setVisible('inhibitor200')}>Display</div>
                                            }
                                        </div> */}

                                        <label className="control-extra__fieldset__label">Top</label>
                                        <div className={`pill game-sides--100`}>
                                            <label className="control-extra__fieldset__label">Team Left/Blue</label>
                                            <span className={this.state.visible.inhibitor100Top ? 'pill__span--highlighted' : ''}>
                                                {this.state.visible.inhibitor100Top ? i18n().controller.viewable.true : i18n().controller.viewable.false}
                                            </span>
                                            <span>{this.state.timers.inhibitor100Top}</span>
                                            <Button controllerSide={this.props.controllerSide} className={`button interactive `} action={() => this.runTimerAndDisplay('inhibitor100Top')} text="Start" />
                                            <Button controllerSide={this.props.controllerSide} className={`button interactive `} action={() => this.resetAndHide('inhibitor100Top')} text="Reset" />

                                        </div>
                                        <div className={`pill game-sides--200`}>
                                            <label className="control-extra__fieldset__label">Team Right/Red</label>

                                            <span className={this.state.visible.inhibitor200Top ? 'pill__span--highlighted' : ''}>
                                                {this.state.visible.inhibitor200Top ? i18n().controller.viewable.true : i18n().controller.viewable.false}
                                            </span>
                                            <span>{this.state.timers.inhibitor200Top}</span>
                                            <Button controllerSide={this.props.controllerSide} className={`button interactive `} action={() => this.runTimerAndDisplay('inhibitor200Top')} text="Start" />
                                            <Button controllerSide={this.props.controllerSide} className={`button interactive `} action={() => this.resetAndHide('inhibitor200Top')} text="Reset" />
                                        </div>
                                        <label className="control-extra__fieldset__label">Mid</label>
                                        <div className={`pill game-sides--100`}>
                                            <label className="control-extra__fieldset__label">Team Left/Blue</label>

                                            <span className={this.state.visible.inhibitor100Mid ? 'pill__span--highlighted' : ''}>
                                                {this.state.visible.inhibitor100Mid ? i18n().controller.viewable.true : i18n().controller.viewable.false}
                                            </span>
                                            <span>{this.state.timers.inhibitor100Mid}</span>
                                            <Button controllerSide={this.props.controllerSide} className={`button interactive `} action={() => this.runTimerAndDisplay('inhibitor100Mid')} text="Start" />
                                            <Button controllerSide={this.props.controllerSide} className={`button interactive `} action={() => this.resetAndHide('inhibitor100Mid')} text="Reset" />
                                        </div>
                                        <div className={`pill game-sides--200`}>
                                            <label className="control-extra__fieldset__label">Team Right/Red</label>

                                            <span className={this.state.visible.inhibitor200Mid ? 'pill__span--highlighted' : ''}>
                                                {this.state.visible.inhibitor200Mid ? i18n().controller.viewable.true : i18n().controller.viewable.false}
                                            </span>
                                            <span>{this.state.timers.inhibitor200Mid}</span>
                                            <Button controllerSide={this.props.controllerSide} className={`button interactive `} action={() => this.runTimerAndDisplay('inhibitor200Mid')} text="Start" />
                                            <Button controllerSide={this.props.controllerSide} className={`button interactive `} action={() => this.resetAndHide('inhibitor200Mid')} text="Reset" />
                                        </div>
                                        <label className="control-extra__fieldset__label">Bottom</label>
                                        <div className={`pill game-sides--100`}>
                                            <label className="control-extra__fieldset__label">Team Left/Blue</label>

                                            <span className={this.state.visible.inhibitor100Bottom ? 'pill__span--highlighted' : ''}>
                                                {this.state.visible.inhibitor100Bottom ? i18n().controller.viewable.true : i18n().controller.viewable.false}
                                            </span>
                                            <span>{this.state.timers.inhibitor100Bottom}</span>
                                            <Button controllerSide={this.props.controllerSide} className={`button interactive `} action={() => this.runTimerAndDisplay('inhibitor100Bottom')} text="Start" />
                                            <Button controllerSide={this.props.controllerSide} className={`button interactive `} action={() => this.resetAndHide('inhibitor100Bottom')} text="Reset" />
                                        </div>
                                        <div className={`pill game-sides--200`}>
                                            <label className="control-extra__fieldset__label">Team Right/Red</label>

                                            <span className={this.state.visible.inhibitor200Bottom ? 'pill__span--highlighted' : ''}>
                                                {this.state.visible.inhibitor200Bottom ? i18n().controller.viewable.true : i18n().controller.viewable.false}
                                            </span>
                                            <span>{this.state.timers.inhibitor200Bottom}</span>
                                            <Button controllerSide={this.props.controllerSide} className={`button interactive `} action={() => this.runTimerAndDisplay('inhibitor200Bottom')} text="Start" />
                                            <Button controllerSide={this.props.controllerSide} className={`button interactive `} action={() => this.resetAndHide('inhibitor200Bottom')} text="Reset" />
                                        </div>
                                    </StyleInhibitorContainer>
                                    {/* <div className="game-sides-container">
                                        <div className={`game-sides game-sides--100`}> */}
                                    {/* LEFT INHIBITORS */}
                                    {/* <h1>Left/Blue Team</h1>
                                            <div className="pill">
                                                <span>Display inhibitors</span>
                                                <span className={this.state.visible.inhibitor100 ? 'pill__span--highlighted' : ''}>
                                                    {this.state.visible.inhibitor100 ? i18n().controller.viewable.true : i18n().controller.viewable.false}
                                                </span>
                                                {
                                                    this.state.visible.inhibitor100 ?
                                                        <div className="button interactive" onClick={() => this.setHide('inhibitor100')}>Hide</div>
                                                        :
                                                        <div className="button interactive" onClick={() => this.setVisible('inhibitor100')}>Display</div>
                                                }
                                            </div>
                                            <div className="pill">
                                                <span>Top</span>
                                                <span className={this.state.visible.inhibitor100Top ? 'pill__span--highlighted' : ''}>
                                                    {this.state.visible.inhibitor100Top ? i18n().controller.viewable.true : i18n().controller.viewable.false}
                                                </span>
                                                <span>{this.state.timers.inhibitor100Top}</span>
                                                <div className="button interactive" onClick={() => this.runTimerAndDisplay('inhibitor100Top')}>Start</div>
                                                <div className="button interactive" onClick={() => this.resetAndHide('inhibitor100Top')}>Reset</div>
                                            </div>
                                            <div className="pill">
                                                <span>Mid</span>
                                                <span className={this.state.visible.inhibitor100Mid ? 'pill__span--highlighted' : ''}>
                                                    {this.state.visible.inhibitor100Mid ? i18n().controller.viewable.true : i18n().controller.viewable.false}
                                                </span>
                                                <span>{this.state.timers.inhibitor100Mid}</span>
                                                <div className="button interactive" onClick={() => this.runTimerAndDisplay('inhibitor100Mid')}>Start</div>
                                                <div className="button interactive" onClick={() => this.resetAndHide('inhibitor100Mid')}>Reset</div>
                                            </div>
                                            <div className="pill">
                                                <span>Bottom</span>
                                                <span className={this.state.visible.inhibitor100Bottom ? 'pill__span--highlighted' : ''}>
                                                    {this.state.visible.inhibitor100Bottom ? i18n().controller.viewable.true : i18n().controller.viewable.false}
                                                </span>
                                                <span>{this.state.timers.inhibitor100Bottom}</span>
                                                <div className="button interactive" onClick={() => this.runTimerAndDisplay('inhibitor100Bottom')}>Start</div>
                                                <div className="button interactive" onClick={() => this.resetAndHide('inhibitor100Bottom')}>Reset</div>
                                        </div> */}
                                    {/* LEFT BUFFS */}
                                    {/* <Block>
                                                <h1>Buffs</h1>
                                                <div className="pill">
                                                    <span>Elder</span>
                                                    <span className={this.state.visible.buffElder100 ? 'pill__span--highlighted' : ''}>
                                                        {this.state.visible.buffElder100 ? i18n().controller.viewable.true : i18n().controller.viewable.false}
                                                    </span>
                                                    <span>{this.state.timers.buffElder100}</span>
                                                    <div className="button interactive" onClick={() => this.runTimerAndDisplay('buffElder100')}>Start</div>
                                                    <div className="button interactive" onClick={() => this.resetAndHide('buffElder100')}>Reset</div>
                                                </div>
                                                <div className="pill">
                                                    <span>Elder (Long)</span>
                                                    <span className={this.state.visible.buffElderLong100 ? 'pill__span--highlighted' : ''}>
                                                        {this.state.visible.buffElderLong100 ? i18n().controller.viewable.true : i18n().controller.viewable.false}
                                                    </span>
                                                    <span>{this.state.timers.buffElderLong100}</span>
                                                    <div className="button interactive" onClick={() => this.runTimerAndDisplay('buffElderLong100')}>Start</div>
                                                    <div className="button interactive" onClick={() => this.resetAndHide('buffElderLong100')}>Reset</div>
                                                </div>
                                                <div className="pill">
                                                    <span>Baron</span>
                                                    <span className={this.state.visible.buffBaron100 ? 'pill__span--highlighted' : ''}>
                                                        {this.state.visible.buffBaron100 ? i18n().controller.viewable.true : i18n().controller.viewable.false}
                                                    </span>
                                                    <span>{this.state.timers.buffBaron100}</span>
                                                    <div className="button interactive" onClick={() => this.runTimerAndDisplay('buffBaron100')}>Start</div>
                                                    <div className="button interactive" onClick={() => this.resetAndHide('buffBaron100')}>Reset</div>
                                                </div>
                                            </Block> */}
                                    {/* </div> */}
                                    {/* RIGHT INHIBITORS */}
                                    {/* <div className={`game-sides game-sides--200`}> */}
                                    {/* <h1>Right/Red Team</h1>
                                            <div className="pill">
                                                <span>Display inhibitors</span>
                                                <span className={this.state.visible.inhibitor200 ? 'pill__span--highlighted' : ''}>
                                                    {this.state.visible.inhibitor200 ? i18n().controller.viewable.true : i18n().controller.viewable.false}
                                                </span>
                                                {
                                                    this.state.visible.inhibitor200 ?
                                                        <div className="button interactive" onClick={() => this.setHide('inhibitor200')}>Hide</div>
                                                        :
                                                        <div className="button interactive" onClick={() => this.setVisible('inhibitor200')}>Display</div>
                                                }
                                            </div>
                                            <div className="pill">
                                                <span>Top</span>
                                                <span className={this.state.visible.inhibitor200Top ? 'pill__span--highlighted' : ''}>
                                                    {this.state.visible.inhibitor200Top ? i18n().controller.viewable.true : i18n().controller.viewable.false}
                                                </span>
                                                <span>{this.state.timers.inhibitor200Top}</span>
                                                <div className="button interactive" onClick={() => this.runTimerAndDisplay('inhibitor200Top')}>Start</div>
                                                <div className="button interactive" onClick={() => this.resetAndHide('inhibitor200Top')}>Reset</div>
                                            </div>
                                            <div className="pill">
                                                <span>Mid</span>
                                               <span className={this.state.visible.inhibitor100Mid ? 'pill__span--highlighted' : ''}>
                                                    {this.state.visible.inhibitor100Mid ? i18n().controller.viewable.true : i18n().controller.viewable.false}
                                                </span>
                                                <span>{this.state.timers.inhibitor100Mid}</span>
                                                <div className="button interactive" onClick={() => this.runTimerAndDisplay('inhibitor100Mid')}>Start</div>
                                                <div className="button interactive" onClick={() => this.resetAndHide('inhibitor100Mid')}>Reset</div>
                                            </div>
                                            <div className="pill">
                                                <span>Bottom</span>
                                                <span className={this.state.visible.inhibitor200Bottom ? 'pill__span--highlighted' : ''}>
                                                    {this.state.visible.inhibitor200Bottom ? i18n().controller.viewable.true : i18n().controller.viewable.false}
                                                </span>
                                                <span>{this.state.timers.inhibitor200Bottom}</span>
                                                <div className="button interactive" onClick={() => this.runTimerAndDisplay('inhibitor200Bottom')}>Start</div>
                                                <div className="button interactive" onClick={() => this.resetAndHide('inhibitor200Bottom')}>Reset</div>
                                        </div> */}
                                    {/* RIGHT BUFFS */}
                                    {/* <Block>
                                                <h1>Buffs</h1>
                                                <div className="pill">
                                                    <span>Elder</span>
                                                    <span className={this.state.visible.buffElder200 ? 'pill__span--highlighted' : ''}>
                                                        {this.state.visible.buffElder200 ? i18n().controller.viewable.true : i18n().controller.viewable.false}
                                                    </span>
                                                    <span>{this.state.timers.buffElder200}</span>
                                                    <div className="button interactive" onClick={() => this.runTimerAndDisplay('buffElder200')}>Start</div>
                                                    <div className="button interactive" onClick={() => this.resetAndHide('buffElder200')}>Reset</div>
                                                </div>
                                                {/* <div className="pill">
                                                    <span>Elder (Long)</span>
                                                    <span className={this.state.visible.buffElderLong200 ? 'pill__span--highlighted' : ''}>
                                                        {this.state.visible.buffElderLong200 ? i18n().controller.viewable.true : i18n().controller.viewable.false}
                                                    </span>
                                                    <span>{this.state.timers.buffElderLong200}</span>
                                                    <div className="button interactive" onClick={() => this.runTimerAndDisplay('buffElderLong200')}>Start</div>
                                                    <div className="button interactive" onClick={() => this.resetAndHide('buffElderLong200')}>Reset</div>
                                                </div>
                                                <div className="pill">
                                                    <span>Baron</span>
                                                    <span className={this.state.visible.buffBaron200 ? 'pill__span--highlighted' : ''}>
                                                        {this.state.visible.buffBaron200 ? i18n().controller.viewable.true : i18n().controller.viewable.false}
                                                    </span>
                                                    <span>{this.state.timers.buffBaron200}</span>
                                                    <div className="button interactive" onClick={() => this.runTimerAndDisplay('buffBaron200')}>Start</div>
                                                    <div className="button interactive" onClick={() => this.resetAndHide('buffBaron200')}>Reset</div>
                                                </div>
                                            </Block> */}
                                    {/* </div> */}
                                    {/* </div> */}
                                </Teams>
                            </SubBlock>
                        </Block>
                        <Block>
                            {/* DRAGONS */}
                            <h1>Dragons</h1>
                            {/*  */}
                            <SubBlock>
                                <StyleDragonsChoicesContainer col={'3'}>
                                    <StyleDragonTypeChoicesContainer
                                        onClick={() => { this.setDragonSubtypeSelected('normal') }}
                                        isChecked={this.state.dragonSubtypeSelected === 'normal'}
                                    >
                                        <input type={'radio'} checked={this.state.dragonSubtypeSelected === 'normal'} onChange={() => { }} />
                                        Normal
                                    </StyleDragonTypeChoicesContainer>
                                    <StyleDragonTypeChoicesContainer
                                        onClick={() => { this.setDragonSubtypeSelected('rift') }}
                                        isChecked={this.state.dragonSubtypeSelected === 'rift'}
                                    >
                                        <input type={'radio'} checked={this.state.dragonSubtypeSelected === 'rift'} onChange={() => { }} />
                                        Rift
                                    </StyleDragonTypeChoicesContainer>
                                    <StyleDragonTypeChoicesContainer
                                        onClick={() => { this.setDragonSubtypeSelected('elder') }}
                                        isChecked={this.state.dragonSubtypeSelected === 'elder'}
                                    >
                                        <input type={'radio'} checked={this.state.dragonSubtypeSelected === 'elder'} onChange={() => { }} />
                                        Elder
                                    </StyleDragonTypeChoicesContainer>
                                </StyleDragonsChoicesContainer>
                                <StyleSeparator />
                                <StyleDragonsChoicesContainer col={'4'}>
                                    <StyleDragonsChoice isSelected={this.state.dragonSelected === 'Infernal'} onClick={(() => { this.setDragon('Infernal') })}>
                                        <img src={infernalDragonIcon} alt={''} />
                                        Infernal
                                    </StyleDragonsChoice>
                                    <StyleDragonsChoice isSelected={this.state.dragonSelected === 'Mountain'} onClick={(() => { this.setDragon('Mountain') })}>
                                        <img src={mountainDragonIcon} alt={''} />
                                        Mountain
                                    </StyleDragonsChoice>
                                    <StyleDragonsChoice isSelected={this.state.dragonSelected === 'Cloud'} onClick={(() => { this.setDragon('Cloud') })}>
                                        <img src={cloudDragonIcon} alt={''} />
                                        Cloud
                                    </StyleDragonsChoice>
                                    <StyleDragonsChoice isSelected={this.state.dragonSelected === 'Ocean'} onClick={(() => { this.setDragon('Ocean') })}>
                                        <img src={oceanDragonIcon} alt={''} />
                                        Ocean
                                    </StyleDragonsChoice>
                                    <StyleDragonsChoice isSelected={this.state.dragonSelected === 'Chemtech'} onClick={(() => { this.setDragon('Chemtech') })}>
                                        <img src={chemtechDragonIcon} alt={''} />
                                        Chemtech
                                    </StyleDragonsChoice>
                                    <StyleDragonsChoice isSelected={this.state.dragonSelected === 'Hextech'} onClick={(() => { this.setDragon('Hextech') })}>
                                        <img src={hextechDragonIcon} alt={''} />
                                        Hextech
                                    </StyleDragonsChoice>
                                </StyleDragonsChoicesContainer>
                                <StyleSeparator />
                                <StyleFieldGridTimerContainer>
                                    <label className="control-extra__fieldset__label">Timer</label>
                                    <div className="pill">
                                        <span className={this.isTimerDragonActive() ? 'pill__span--highlighted' : ''}>
                                            {this.isTimerDragonActive() ? i18n().controller.viewable.true : i18n().controller.viewable.false}
                                        </span>
                                        {this.isTimerDragonActive() ? <Button controllerSide={this.props.controllerSide} className={`button interactive `} action={() => {
                                            this.setHide('dragonSelected')
                                        }} text="Hide"
                                                                      /> : <Button controllerSide={this.props.controllerSide} className={`button interactive `} action={() => {
                                            this.setVisible('dragonSelected')
                                        }} text="Visible"
                                                                           />}
                                    </div>
                                    <div className="pill">
                                        <span>{this.state.timers['dragonSelected']}</span>
                                        <Picker
                                            items={timersItems}
                                            onUpdate={(value) => {
                                                this.state.timerPeriodSelected !== value && this.setState({ timerPeriodSelected: value });
                                            }}
                                            preselect={`${this.state.timerPeriodSelected}`}
                                        />
                                        <Button controllerSide={this.props.controllerSide} className={`button interactive `} action={() => this.setTimer('dragonSelected')} text="Start" />
                                        <Button controllerSide={this.props.controllerSide} className={`button interactive `} action={() => this.resetTimer('dragonSelected')} text="Reset" />
                                    </div>
                                </StyleFieldGridTimerContainer>
                                <StyleFieldGridLiveContainer>
                                    <label className="control-extra__fieldset__label">Live</label>
                                    <div className="pill">
                                        <span className={this.isLiveDragonActive() ? 'pill__span--highlighted' : ''}>
                                            {this.isLiveDragonActive() ? i18n().controller.viewable.true : i18n().controller.viewable.false}
                                        </span>
                                        {this.isLiveDragonActive() ? <Button controllerSide={this.props.controllerSide} className={`button interactive `} action={() => this.setHide('dragonSelectedLive')} text="Hide" /> : <Button controllerSide={this.props.controllerSide} className={`button interactive `} action={() => this.setVisible('dragonSelectedLive')} text="Display" />}
                                    </div>
                                </StyleFieldGridLiveContainer>
                            </SubBlock>
                        </Block>
                        {/* BARON & HERALD */}
                        <Block>
                            <Column>
                                <h1>{'Baron & Herald'}</h1>
                                <SubBlock>
                                    <StyleDragonsChoicesContainer col={'2'}>
                                        <StyleDragonsChoice isSelected={this.state.dragon2Selected === 'herald'} onClick={(() => { this.setState({ dragon2Selected: 'herald' }) })}>
                                            <img src={heraldIcon} alt={''} />
                                            Herald
                                        </StyleDragonsChoice>
                                        <StyleDragonsChoice isSelected={this.state.dragon2Selected === 'baron'} onClick={(() => { this.setState({ dragon2Selected: 'baron' }) })}>
                                            <img src={baronIcon} alt={''} />
                                            Baron
                                        </StyleDragonsChoice>
                                    </StyleDragonsChoicesContainer>
                                    <StyleSeparator />
                                    <StyleFieldGridTimerContainer>
                                        <label className="control-extra__fieldset__label">Timer</label>
                                        <div className="pill">
                                            <span className={this.state.visible[this.state.dragon2Selected] ? 'pill__span--highlighted' : ''}>
                                                {this.state.visible[this.state.dragon2Selected] ? i18n().controller.viewable.true : i18n().controller.viewable.false}
                                            </span>
                                            <span>{this.state.timers[this.state.dragon2Selected]}</span>
                                            <Button controllerSide={this.props.controllerSide} className={`button interactive `} action={() => this.runTimerAndDisplay(this.state.dragon2Selected)} text="Start" />

                                            <Button controllerSide={this.props.controllerSide} className={`button interactive `} action={() => this.resetAndHide(this.state.dragon2Selected)} text="Reset" />
                                        </div>
                                    </StyleFieldGridTimerContainer>
                                    <StyleFieldGridLiveContainer>
                                        <label className="control-extra__fieldset__label">Live</label>
                                        <div className="pill">
                                            
                                            {
                                                this.state.dragon2Selected === "herald" ? 
                                                <span className={(this.state.visible.heraldLive) ? 'pill__span--highlighted' : ''}>
                                                    {(this.state.visible.heraldLive) ? i18n().controller.viewable.true : i18n().controller.viewable.false}
                                                </span>
                                                :
                                                <span className={(this.state.visible.baronLive) ? 'pill__span--highlighted' : ''}>
                                                    {(this.state.visible.baronLive) ? i18n().controller.viewable.true : i18n().controller.viewable.false}
                                                </span>
                                            }

                                            {
                                                this.state.dragon2Selected === "herald" && this.state.visible.heraldLive ?
                                                <Button controllerSide={this.props.controllerSide} className={`button interactive `} action={() => this.setHide(`${this.state.dragon2Selected}Live`)} text="Hide" />
                                                :
                                                this.state.dragon2Selected === "baron" && this.state.visible.baronLive ?
                                                <Button controllerSide={this.props.controllerSide} className={`button interactive `} action={() => this.setHide(`${this.state.dragon2Selected}Live`)} text="Hide" />
                                                :
                                                <Button controllerSide={this.props.controllerSide} className={`button interactive `} action={() => this.setVisible(`${this.state.dragon2Selected}Live`)} text="Display" />
                                            }
                                        </div>
                                    </StyleFieldGridLiveContainer>
                                    {/* <StyleSeparator />
                                    <span>Herald</span>
                                    <div className="pill">
                                        <span className={this.state.visible.herald ? 'pill__span--highlighted' : ''}>
                                            {this.state.visible.herald ? i18n().controller.viewable.true : i18n().controller.viewable.false}
                                        </span>
                                        <span>{this.state.timers.herald}</span>
                                        <div className="button interactive" onClick={() => this.runTimerAndDisplay('herald')}>Start</div>
                                        <div className="button interactive" onClick={() => this.resetAndHide('herald')}>Reset</div>
                                    </div>
                                    <div className="pill">
                                        <span>Herald Live</span>
                                        <span className={this.state.visible.heraldLive ? 'pill__span--highlighted' : ''}>
                                            {this.state.visible.heraldLive ? i18n().controller.viewable.true : i18n().controller.viewable.false}
                                        </span>
                                        {
                                            this.state.visible.heraldLive ?
                                                <div className="button interactive" onClick={() => this.setHide('heraldLive')}>Hide</div>
                                                :
                                                <div className="button interactive" onClick={() => this.setVisible('heraldLive')}>Display</div>
                                        }
                                    </div> */}
                                </SubBlock>
                            </Column>
                        </Block>
                        {/* BUFF */}
                        <Block>
                            <Column>
                                <h1>{'Buffs'}</h1>
                                <SubBlock>
                                    <StyleFieldGridBuffsContainer color={this.state.elderBuffTeam === '100' ? '#0099e0' : '#e83d3d'}>
                                        <StyleBuffLabel className="control-extra__fieldset__label">Elder</StyleBuffLabel>
                                        <div className="pill">
                                            <div
                                                className={`button interactive ${this.state.elderBuffTeam === '100' ? 'button--highlighted' : ''}`}
                                                onClick={() => {
                                                    this.resetAndHide(`buffElder100`);
                                                    this.resetAndHide(`buffElder200`);
                                                    this.setState({ elderBuffTeam: '100' });
                                                }}
                                            >
                                                Team Blue
                                            </div>
                                            <div
                                                className={`button interactive ${this.state.elderBuffTeam === '200' ? 'button--highlighted' : ''}`}
                                                onClick={() => {
                                                    this.resetAndHide(`buffElder100`);
                                                    this.resetAndHide(`buffElder200`);
                                                    this.setState({ elderBuffTeam: '200' });
                                                }}
                                            >
                                                Team Red
                                            </div>
                                        </div>
                                        <div className="pill">
                                            <span className={this.state.visible[`buffElder${this.state.elderBuffTeam}`] ? 'pill__span--highlighted' : ''}>
                                                {this.state.visible[`buffElder${this.state.elderBuffTeam}`] ? i18n().controller.viewable.true : i18n().controller.viewable.false}
                                            </span>
                                            <span>{this.state.timers[`buffElder${this.state.elderBuffTeam}`]}</span>
                                            <Button controllerSide={this.props.controllerSide} className={`button interactive `} action={() => this.runTimerAndDisplay(`buffElder${this.state.elderBuffTeam}`)} text="Start" />
                                            <Button controllerSide={this.props.controllerSide} className={`button interactive `} action={() => {
                                                // this.resetAndHide(`buffElder${this.state.elderBuffTeam}`)
                                                this.resetAndHide(`buffElder100`);
                                                this.resetAndHide(`buffElder200`);
                                            }} text="Reset"
                                            />
                                        </div>
                                    </StyleFieldGridBuffsContainer>
                                    <StyleSeparator />
                                    <StyleFieldGridBuffsContainer color={this.state.baronBuffTeam === '100' ? '#0099e0' : '#e83d3d'}>
                                        <StyleBuffLabel className="control-extra__fieldset__label">Baron</StyleBuffLabel>
                                        <div className="pill">
                                            <div
                                                className={`button interactive ${this.state.baronBuffTeam === '100' ? 'button--highlighted' : ''}`}
                                                onClick={() => {
                                                    this.resetAndHide(`buffBaron100`);
                                                    this.resetAndHide(`buffBaron200`);
                                                    this.setState({ baronBuffTeam: '100' });
                                                }}
                                            >
                                                Team Blue
                                            </div>
                                            <div
                                                className={`button interactive ${this.state.baronBuffTeam === '200' ? 'button--highlighted' : ''}`}
                                                onClick={() => {
                                                    this.resetAndHide(`buffBaron100`);
                                                    this.resetAndHide(`buffBaron200`);
                                                    this.setState({ baronBuffTeam: '200' });
                                                }}
                                            >
                                                Team Red
                                            </div>
                                        </div>
                                        <div className="pill">
                                            <span className={this.state.visible[`buffBaron${this.state.baronBuffTeam}`] ? 'pill__span--highlighted' : ''}>
                                                {this.state.visible[`buffBaron${this.state.baronBuffTeam}`] ? i18n().controller.viewable.true : i18n().controller.viewable.false}
                                            </span>
                                            <span>{this.state.timers[`buffBaron${this.state.baronBuffTeam}`]}</span>
                                            <Button controllerSide={this.props.controllerSide} className={`button interactive `} action={async () => {
                                                await this.runTimerAndDisplay(`buffBaron${this.state.baronBuffTeam}`);
                                                setTimeout(() => { this.listenGoldDifference(); }, 500);
                                            }} text="Start"
                                            />
                                            <Button controllerSide={this.props.controllerSide} className={`button interactive `} action={() => {
                                                // this.resetAndHide(`buffBaron${this.state.baronBuffTeam}`)
                                                this.resetAndHide(`buffBaron100`);
                                                this.resetAndHide(`buffBaron200`);
                                            }} text="Reset"
                                            />
                                        </div>
                                        {/* <div className="pill">
                                            <span className={this.state.visible.buffBaron200 ? 'pill__span--highlighted' : ''}>
                                                {this.state.visible.buffBaron200 ? i18n().controller.viewable.true : i18n().controller.viewable.false}
                                            </span>
                                            <span>{this.state.timers.buffBaron200}</span>
                                            <div className="button interactive" onClick={() => this.runTimerAndDisplay('buffBaron200')}>Start</div>
                                            <div className="button interactive" onClick={() => this.resetAndHide('buffBaron200')}>Reset</div>
                                        </div> */}
                                        {!get(theme, 'screens.gameScreen.disableBaronPowerPlaySponsor') && <StyleBaronVideoSponsor>
                                            Sponsor Baron Video
                                            <div className="pill">
                                                <span className={this.props.showPopupVideo ? 'pill__span--highlighted' : ''}>
                                                    {this.props.showPopupVideo ? i18n().controller.viewable.true : i18n().controller.viewable.false}
                                                </span>
                                                {
                                                    (this.props.showPopupVideo) ?
                                                        <Button controllerSide={this.props.controllerSide} className={`button interactive `} action={()=>this.props.onUpdate('showPopupVideo', false)} text="Hide" />
                                                        :
                                                        <Button controllerSide={this.props.controllerSide} className={`button interactive `} action={()=>this.props.onUpdate('showPopupVideo', true)} text="Display" />
                                                }
                                            </div>
                                        </StyleBaronVideoSponsor>}

                                    </StyleFieldGridBuffsContainer>
                                </SubBlock>
                            </Column>
                        </Block>
                    </Base>
                </div>
            </DataContainer>
        )
    }
}

export default DataGameTimers;
