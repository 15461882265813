// REACT
import React, { useState } from 'react';
// import { isEqual, get, cloneDeep } from 'lodash';
// internal
import { checkLang } from '../../i18n';
import {
  StyleInGameTipsContainer,
} from './styles';
import Select from 'react-select';
import { getCreatableStyles } from '../DataMatch/DataMatchLineup';
import DataContainer from '../DataContainer';
import { getInfoboxAssetChampions } from '../../../services/championService';

const infoboxOptions = getInfoboxAssetChampions().map((champion) => ({
  label: (checkLang() === 'en')? `${champion.englishName} - Passsive`:`${champion.name} - Passive`,
  value: champion.passiveInfoBoxAsset,
}))

const DataInGameTipInfobox = () => {

  const [ currentSelectionState, setCurrentSelectionState ] = useState({label: ' - ', value: ''})

  // useEffect(() => {
  //   setInGameTipsState(Object.values(inGameTipsList).map(item => ({
  //     value: item.infoBoxAsset,
  //     label: item.name,
  //   })))
  // }, [])

  // useEffect(() => {
  //   subscribe('control-telopsV2', null, response => {
  //     if (response.data) {
  //       console.log("modified the DataMythicItemInfobox state");
  //     }
  //   }
  //   );
  // }, [subscribe]);

  const handleInfoboxSelection = (selection) => {
    setCurrentSelectionState(selection);
    window.MainData.inGameTipInfobox = { url : selection.value};
  }

  return (
    <DataContainer componentSlug="inGameTipInfobox" icon="fal fa-info" style={{ overflow: 'visible' }}>
      <div className="data-panel__fieldset" style={{ paddingTop: '10px', paddingBottom: '0' }}>
        <StyleInGameTipsContainer>
        <Select
          styles={getCreatableStyles('300px')}
          options={[...infoboxOptions, {value: '', label: ' - '}]}
          value={currentSelectionState}
          onChange={(e)=>{
              handleInfoboxSelection(e);
          }}
          defaultValue={{value: '', label: ' - '}}
          isSearchable
        />
        {(currentSelectionState.value && <img src={currentSelectionState.value} alt={'InGame Tip Infobox Selection'} />) || null}
        </StyleInGameTipsContainer>
      </div>
    </DataContainer>
  );
};

export default DataInGameTipInfobox;
