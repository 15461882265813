"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StyleWrapper = exports.StyleTeam200 = exports.StyleTeam100 = exports.StyleScheduleScreenBackground = exports.StyleRowDetailContainer = exports.StyleRes = exports.StyleMiddle = exports.StyleMain = exports.StyleLogo = void 0;

var _styledComponents = _interopRequireDefault(require("styled-components"));

var _reactPose = _interopRequireDefault(require("react-pose"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var ContainerAnimation = _reactPose.default.div({
  hide: {
    y: 850,
    // place it stops
    z: 1,
    // ?
    // scale: 0.5, // Causes the item to become smaller
    opacity: 1,
    // become nothing!
    transition: {
      // duration: 400 // ms 
      opacity: {
        ease: 'easeIn',
        duration: 500
      },
      default: {
        ease: 'easeIn',
        duration: 500
      }
    },
    applyAtStart: {
      scale: 1
    } // weird

  },
  show: {
    y: 0,
    z: 1,
    scale: 1,
    opacity: 1,
    // https://popmotion.io/pose/learn/custom-transitions/
    transition: {
      // type: 'spring', stiffness: 10000, // https://popmotion.io/api/spring/
      opacity: {
        ease: 'easeOut',
        duration: 500
      },
      // https://popmotion.io/api/tween/
      default: {
        ease: 'easeOut',
        duration: 500
      }
    },
    delay: 100,
    delayChildren: 400,
    applyAtStart: {
      scale: 1
    } // makes it look bigger at the start (or smaller if you are into that)

  }
}); // the most outter style!


var StyleMain = (0, _styledComponents.default)(ContainerAnimation).withConfig({
  displayName: "style__StyleMain",
  componentId: "sc-1jtucln-0"
})(["display:flex;flex-direction:column;justify-content:space-between;width:100%;height:100%;z-index:2;*{z-index:2;}", ""], function (props) {
  return props.main || '';
}); // the background !!

exports.StyleMain = StyleMain;

var StyleScheduleScreenBackground = _styledComponents.default.div.withConfig({
  displayName: "style__StyleScheduleScreenBackground",
  componentId: "sc-1jtucln-1"
})(["background-image:url(", ");background-position:center;background-repeat:no-repeat;background-size:cover;padding:10px;display:grid;transition:transform 500ms ease-in-out,width 500ms ease-in-out,top 1s ease-in-out,clip-path 200ms ease-in;transform:", ";width:", ";margin-left:231px;margin-right:230px;height:100%;position:relative;top:313px;max-height:617px;", ";", ";"], function (props) {
  return props.background;
}, function (props) {
  return "\n        translateX(".concat(props.isResized ? '427px' : '0px', ") \n        ");
}, function (props) {
  return props.isResized ? '1180.8px' : '1459px';
}, function (props) {
  return props.showBo5x2Version ? 'align-items: center;' : '';
}, function (props) {
  return props.StyleScheduleScreenBackground || '';
}); // logo 


exports.StyleScheduleScreenBackground = StyleScheduleScreenBackground;

var StyleLogo = _styledComponents.default.img.withConfig({
  displayName: "style__StyleLogo",
  componentId: "sc-1jtucln-2"
})(["align-self:center;justify-self:center;opacity:", ";max-height:100%;max-width:100%;width:auto;height:auto;", ";", ";", ";", ";", ""], function (props) {
  return props.win ? '1' : '0.3';
}, function (props) {
  return props.StyleLogo ? props.StyleLogo : '';
}, function (props) {
  return props.Bo5x2ScheduleV2Logo ? props.Bo5x2ScheduleV2Logo : '';
}, function (props) {
  return props.Bo5x2ScheduleV2LogoLeft ? props.Bo5x2ScheduleV2LogoLeft : '';
}, function (props) {
  return props.Bo5x2ScheduleV2LogoRight ? props.Bo5x2ScheduleV2LogoRight : '';
}, function (props) {
  return props.isThreeMatches ? "\n    width: 120px;\n    height: 120px;\n    max-width: none;\n" : "";
}); // team 1


exports.StyleLogo = StyleLogo;

var StyleTeam100 = _styledComponents.default.div.withConfig({
  displayName: "style__StyleTeam100",
  componentId: "sc-1jtucln-3"
})(["font-size:28px;align-self:center;justify-self:center;justify-self:flex-end;text-align:right;margin-right:20px;color:", ";opacity:", ";font-family:'titling-gothic-comp-bold';text-transform:uppercase;", ";", ";", ";", ";", ""], function (props) {
  return props.playing ? props.mainColor : props.secondaryColor;
}, function (props) {
  return props.win ? '1' : '0.3';
}, function (props) {
  return props.StyleTeam || '';
}, function (props) {
  return props.Bo5x2ScheduleV2Name ? props.Bo5x2ScheduleV2Name : '';
}, function (props) {
  return props.StyleTeam100 || '';
}, function (props) {
  return props.Bo5x2ScheduleV2NameLeft ? props.Bo5x2ScheduleV2NameLeft : '';
}, function (props) {
  return props.isThreeMatches ? "\n    font-size: 28px;\n" : "";
}); // team 2


exports.StyleTeam100 = StyleTeam100;

var StyleTeam200 = _styledComponents.default.div.withConfig({
  displayName: "style__StyleTeam200",
  componentId: "sc-1jtucln-4"
})(["font-size:28px;align-self:center;justify-self:center;justify-self:flex-start;margin-left:20px;color:", ";opacity:", ";font-family:'titling-gothic-comp-bold';text-transform:uppercase;", ";", ";", ";", ";", ""], function (props) {
  return props.playing ? props.mainColor : props.secondaryColor;
}, function (props) {
  return props.win ? '1' : '0.3';
}, function (props) {
  return props.StyleTeam || '';
}, function (props) {
  return props.Bo5x2ScheduleV2Name ? props.Bo5x2ScheduleV2Name : '';
}, function (props) {
  return props.StyleTeam200 || '';
}, function (props) {
  return props.Bo5x2ScheduleV2NameRight ? props.Bo5x2ScheduleV2NameRight : '';
}, function (props) {
  return props.isThreeMatches ? "\n    font-size: 28px;\n" : "";
}); // wrapper for each match, both teams and the logos and the middle!


exports.StyleTeam200 = StyleTeam200;

var StyleWrapper = _styledComponents.default.li.withConfig({
  displayName: "style__StyleWrapper",
  componentId: "sc-1jtucln-5"
})(["transition:width 500ms ease-in-out,margin 500ms ease-in-out;width:", ";max-height:112px;display:grid;grid-auto-flow:column;grid-template-columns:38% 6% 12% 6% 38%;justify-self:center;", ";", ";", ""], function (props) {
  return props.isResizedAndBo5x2 ? '91.8% !important' : '66%';
}, function (props) {
  return props.StyleWrapper ? props.StyleWrapper : '';
}, function (props) {
  return props.Bo5x2ScheduleV2Wrap ? props.Bo5x2ScheduleV2Wrap : '';
}, function (props) {
  return props.isThreeMatches ? "\n    grid-template-columns: 4fr 2fr 1fr 2fr 4fr;\n" : "";
}); // W/L/VS


exports.StyleWrapper = StyleWrapper;

var StyleRes = _styledComponents.default.div.withConfig({
  displayName: "style__StyleRes",
  componentId: "sc-1jtucln-6"
})(["color:", ";font-size:21px;margin:0 10px;opacity:", ";font-family:'aeonik-medium';", " ", " ", ""], function (props) {
  return props.playing ? props.mainColor : props.played ? props.finishedColor || props.secondaryColor : props.secondaryColor;
}, function (props) {
  return props.win ? '1' : '0.3';
}, function (props) {
  return props.StyleRes || '';
}, function (props) {
  return props.Bo5x2ScheduleV2Score ? props.Bo5x2ScheduleV2Score : '';
}, function (props) {
  return props.isThreeMatches ? "\n    font-size: 24px;\n" : "";
}); // w/l/vs wrapper


exports.StyleRes = StyleRes;

var StyleMiddle = _styledComponents.default.div.withConfig({
  displayName: "style__StyleMiddle",
  componentId: "sc-1jtucln-7"
})(["align-self:center;display:flex;justify-content:center;"]);

exports.StyleMiddle = StyleMiddle;
var StyleRowDetailContainer = (0, _styledComponents.default)(ContainerAnimation).withConfig({
  displayName: "style__StyleRowDetailContainer",
  componentId: "sc-1jtucln-8"
})(["position:relative;display:grid;grid-template-columns:3fr 1fr 3fr;grid-template-rows:1fr 15px;height:", ";align-items:flex-start;z-index:", ";text-transform:uppercase;overflow:hidden;background-color:#000220;transition:margin 500ms ease-in-out,transform 500ms ease-in-out,width 500ms ease-in-out,top 1s ease-in-out,clip-path 200ms ease-in;width:", ";height:617px;left:231px;color:white;margin-left:", ";", ";", ";", ";background-position:center;background-repeat:no-repeat;background-size:cover;"], function (props) {
  return props.nextMatch ? '105%' : '100%';
}, function (props) {
  return props.nextMatch ? 30 : 2;
}, function (props) {
  return props.isResized ? '1180.8px' : '1459px';
}, function (props) {
  return props.isResized ? '427px' : '0px';
}, function (props) {
  return props.css5 || '';
}, function (props) {
  return props.StyleRowDetailContainer || '';
}, function (props) {
  return props.background ? "background-image: url(".concat(props.background, ")") : '';
});
exports.StyleRowDetailContainer = StyleRowDetailContainer;