// REACT
import React from 'react';
import PropTypes from 'prop-types';
// INTERNAL
import {loadImageUrlChampSquare} from '../../../util/loadImageUrl';
import champions from '../../../services/championService';

const MatchupBans = props => {
  return (
  <div className={`matchup__bans__item-container matchup__bans__item-container--${props.side}`}>
    <div className="matchup__bans__item-container__items matchup__bans__item-container__items--first">
      {props.bans.length ?
          [...props.bans.slice(0, 3)].map((b, i) => {
            if (!champions[b]) return (<div />);
          const champName = b !== '' ? champions[b].id : '';
          const banStyle = {backgroundImage: 'url(' + loadImageUrlChampSquare(champName) + ')'};
          return (<div key={i} className={`animated animated--super-fast matchup__bans__item-container__items__item matchup__bans__item-container__items__item--${props.side} banned-hero `} style={banStyle}></div>
          )}
        )
      : ''}
    </div>
    <div className="matchup__bans__item-container__items matchup__bans__item-container__items--second">
      {props.bans.length ?
          [...props.bans.slice(3)].map((b, i) => {
            if (!champions[b]) return (<div />);
          const champName = b !== '' ? champions[b].id : '';
          const banStyle = {backgroundImage: 'url(' + loadImageUrlChampSquare(champName) + ')'};
          return (
            <div key={i} className={`animated animated--super-fast matchup__bans__item-container__items__item matchup__bans__item-container__items__item--${props.side} banned-hero `} style={banStyle}></div>
          )}
        )
      : ''}
    </div>
  </div>
)};

MatchupBans.propTypes = {
  bans: PropTypes.arrayOf(PropTypes.string),
  side: PropTypes.string,
};

export default MatchupBans
