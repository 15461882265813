import styled from 'styled-components';

export const StyleMainContainer = styled.div`
    position: absolute;
    height: 220px;
    width: 1352px;
    left: 288px;
    right: 280px;
    bottom: ${props => props.show? '0px': '-515px'};
    transition: bottom 500ms ${props => props.show ?'ease-out' : 'ease-in'};
    z-index: 3;
`;

export const StyleVideo = styled.video`
    position: absolute;
    width: 1352px;
    bottom: 0;
    /* height: 220px; */
`;
