export const inGamesTips = {
    // all the in-game Tips assets are stored here:
    // https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/in-game_tips/right/kayle-lv6_side_right.png\

    // Gangplank
    41: {
        labelsEn:[
            'gangplank-death_s-daughter',
            'gangplank-fire-at-wall',
            'gangplank-raise-morale',
        ],
        labelsJa:[
            'ガングプランク - 死の女神',
            'ガングプランク - 乱れ撃ち',
            'ガングプランク - 士気上昇',
        ],
        // left
        team100:[
            'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/in-game_tips/left/gangplank-death_s-daughter_side_left.png',
            'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/in-game_tips/left/gangplank-fire-at-wall_side_left.png',
            'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/in-game_tips/left/gangplank-raise-morale_side_left.png',
        ],
        // right
        team200: [
            'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/in-game_tips/right/gangplank-death_s-daughter_side_right.png',
            'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/in-game_tips/right/gangplank-fire-at-wall_side_right.png',
            'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/in-game_tips/right/gangplank-raise-morale_side_right.png',
        ],
    },
    // Kaisa
    145:{
        labelsEn:[
            'kai_sa-icathian-rain',
            'kai_sa-supercharge',
            'kai_sa-void-seeker',
        ],
        labelsJa:[
            'カイ＝サ - Q',
            'カイ＝サ - E',
            'カイ＝サ - W',
        ],
         // left
         team100:[
            'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/in-game_tips/left/kai_sa-icathian-rain_side_left.png',
            'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/in-game_tips/left/kai_sa-supercharge_side_left.png',
            'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/in-game_tips/left/kai_sa-void-seeker_side_left.png',
        ],
        // right
        team200: [
            'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/in-game_tips/right/kai_sa-icathian-rain_side_right.png',
            'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/in-game_tips/right/kai_sa-supercharge_side_right.png',
            'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/in-game_tips/right/kai_sa-void-seeker_side_right.png',
        ],
    },
    // Kassadin
    38:{
        labelsEn:[
            'kassadin-lv16',
        ],
        labelsJa:[
            'カサディン - lv16',
        ],
        // left
        team100:[
            'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/in-game_tips/left/kassadin-lv16_side_left.png',
        ],
        // right
        team200: [
            'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/in-game_tips/right/kassadin-lv16_side_right.png',
        ],
    },
    // Kayle
    10:{
        labelsEn:[
            'kayle-lv6',
            'kayle-lv11',
            'kayle-lv16',
        ],
        labelsJa:[
            'ケイル - lv6',
            'ケイル - lv11',
            'ケイル - lv16',
        ],
        // left
        team100:[
            'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/in-game_tips/left/kayle-lv6_side_left.png',
            'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/in-game_tips/left/kayle-lv11_side_left.png',
            'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/in-game_tips/left/kayle-lv16_side_left.png',
        ],
        // right
        team200: [
            'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/in-game_tips/right/kayle-lv6_side_right.png',
            'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/in-game_tips/right/kayle-lv11_side_right.png',
            'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/in-game_tips/right/kayle-lv16_side_right.png',
        ],
    },
    // Kayn
    141:{
        labelsEn:[
            'kayn-darkin-rhaast',
            'kayn-shadow-assassin',
        ],
        labelsJa:[
            'ケイン - ダーキン',
            'ケイン - 影の暗殺者',
        ],
        // left
        team100:[
            'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/in-game_tips/left/kayn-darkin-rhaast_side_left.png',
            'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/in-game_tips/left/kayn-shadow-assassin_side_left.png',
        ],
        // right
        team200: [
            'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/in-game_tips/right/kayn-darkin-rhaast_side_right.png',
            'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/in-game_tips/right/kayn-shadow-assassin_side_right.png',
        ],
    },
    // Khazix
    121:{
        labelsEn:[
            'kha_zix-evolved-adaptive-cloaking',
            'kha_zix-evolved-reaper-claws',
            'kha_zix-evolved-spike-racks',
            'kha_zix-evolved-wings',
        ],
        labelsJa:[
            'カ＝ジックス - R',
            'カ＝ジックス - Q',
            'カ＝ジックス - W',
            'カ＝ジックス - E',
        ],
        // left
        team100:[
            'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/in-game_tips/left/kha_zix-evolved-adaptive-cloaking_side_left.png',
            'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/in-game_tips/left/kha_zix-evolved-reaper-claws_side_left.png',
            'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/in-game_tips/left/kha_zix-evolved-spike-racks_side_left.png',
            'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/in-game_tips/left/kha_zix-evolved-wings_side_left.png',
        ],
        // right
        team200: [
            'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/in-game_tips/right/kha_zix-evolved-adaptive-cloaking_side_right.png',
            'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/in-game_tips/right/kha_zix-evolved-reaper-claws_side_right.png',
            'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/in-game_tips/right/kha_zix-evolved-spike-racks_side_right.png',
            'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/in-game_tips/right/kha_zix-evolved-wings_side_right.png',
        ],
    },
    // Kindred
    203:{
        labelsEn:[
            'kindred-4marks',
            'kindred-7marks',
            'kindred-10marks',
        ],
        labelsJa:[
            'キンドレッド - 4マーク',
            'キンドレッド - 7マーク',
            'キンドレッド - 10マーク',
        ],
        // left
        team100:[
            'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/in-game_tips/left/kindred-4marks_side_left.png',
            'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/in-game_tips/left/kindred-7marks_side_left.png',
            'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/in-game_tips/left/kindred-10marks_side_left.png',
        ],
        // right
        team200: [
            'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/in-game_tips/right/kindred-4marks_side_right.png',
            'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/in-game_tips/right/kindred-7marks_side_right.png',
            'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/in-game_tips/right/kindred-10marks_side_right.png',
        ],
    },
    // Senna
    235:{
        labelsEn:[
            'senna-20mists',
            'senna-40mists',
            'senna-60mists',
            'senna-80mists',
            'senna-100mists',
        ],
        labelsJa:[
            'セナ　- 20ミスト',
            'セナ - 40ミスト',
            'セナ - 60ミスト',
            'セナ - 80ミスト',
            'セナ　- 100ミスト',
        ],
        // left
        team100:[
            'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/in-game_tips/left/senna-20mists_side_left.png',
            'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/in-game_tips/left/senna-40mists_side_left.png',
            'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/in-game_tips/left/senna-60mists_side_left.png',
            'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/in-game_tips/left/senna-80mists_side_left.png',
            'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/in-game_tips/left/senna-100mists_side_left.png',
        ],
        // right
        team200: [
            'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/in-game_tips/right/senna-20mists_side_right.png',
            'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/in-game_tips/right/senna-40mists_side_right.png',
            'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/in-game_tips/right/senna-60mists_side_right.png',
            'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/in-game_tips/right/senna-80mists_side_right.png',
            'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/in-game_tips/right/senna-100mists_side_right.png',
        ],
    },
    // Viktor
    112: {
        labelsEn:[
            'viktor-aftershock',
            'viktor-magnetize',
            'viktor-perfect-storm',
            'viktor-turbocharge',
        ],
        labelsJa:[
            'ビクター - E',
            'ビクター - W',
            'ビクター - P',
            'ビクター - Q',
        ],
        // left
        team100:[
            'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/in-game_tips/left/viktor-aftershock_side_left.png',
            'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/in-game_tips/left/viktor-magnetize_side_left.png',
            'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/in-game_tips/left/viktor-perfect-storm_side_left.png',
            'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/in-game_tips/left/viktor-turbocharge_side_left.png',
        ],
        // right
        team200: [
            'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/in-game_tips/right/viktor-aftershock_side_right.png',
            'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/in-game_tips/right/viktor-magnetize_side_right.png',
            'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/in-game_tips/right/viktor-perfect-storm_side_right.png',
            'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/in-game_tips/right/viktor-turbocharge_side_right.png',
        ],
    },
}

export const InGameCustomTips = [
    {
        labelEn: 'DARK HARVEST - SOUL STACKS & BONUS ADAPTIVE DAMAGE',
        labelJa: '魂の収穫',
        team100: 'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/in-game_tips/custom/left/dark-harvest_side_left.png',
        team200: 'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/in-game_tips/custom/right/dark-harvest_side_right.png',
    },
    {
        labelEn: 'TEAR OF THE GODDESS - Unique: MANA CHARGE - MANA CHARGE STACKS',
        labelJa: '女神の涙',
        team100: 'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/in-game_tips/custom/left/tear-of-the-goddess_side_left.png',
        team200: 'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/in-game_tips/custom/right/tear-of-the-goddess_side_right.png',
    },
    {
        labelEn: 'KINDRED - Passive: Mark of the Kindred - MARKS & BONUS ATTACK RANGE',
        labelJa: 'キンドレッド - Passive',
        team100: 'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/in-game_tips/custom/left/kindred-passive_side_left.png',
        team200: 'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/in-game_tips/custom/right/kindred-passive_side_right.png',
    },
    {
        labelEn: 'SENNA - Passive: Absolution',
        labelJa: 'セナ - Passive',
        team100: 'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/in-game_tips/custom/left/senna-passive_side_left.png',
        team200: 'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/in-game_tips/custom/right/senna-passive_side_right.png',
    },
    {
        labelEn: 'CHO’GATH - R: Feast - FEAST STACKS & BONUS HEALTH',
        labelJa: 'チョ＝ガス - R',
        team100: 'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/in-game_tips/custom/left/cho_gath-r-passive_side_left.png',
        team200: 'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/in-game_tips/custom/right/cho_gath-r-passive_side_right.png',
    },
    {
        labelEn: 'DRAVEN - Passive: League of Draven - BONUS GOLD & ADORATION STACKS',
        labelJa: 'ドレイヴン - Passive',
        team100: 'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/in-game_tips/custom/left/draven-passive_side_left.png',
        team200: 'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/in-game_tips/custom/right/draven-passive_side_right.png',
    },
    {
        labelEn: 'PYKE - R: Death from Below - BONUS GOLD',
        labelJa: 'パイク - R',
        team100: 'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/in-game_tips/custom/left/pyke-r-passive_side_left.png',
        team200: 'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/in-game_tips/custom/right/pyke-r-passive_side_right.png',
    },
    {
        labelEn: 'VEIGAR - Passive: Phenomenal Evil Power - STACKS & BONUS ABILITY POWER',
        labelJa: 'ベイガー - Passive',
        team100: 'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/in-game_tips/custom/left/veigar-passive_side_left.png',
        team200: 'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/in-game_tips/custom/right/veigar-passive_side_right.png',
    },
    {
        labelEn: 'RENGAR - Passive: Unseen Predator - TROPHY STACKS & BONUS ATTACK DAMAGE',
        labelJa: 'レンガー - Passive',
        team100: 'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/in-game_tips/custom/left/rengar-passive_side_left.png',
        team200: 'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/in-game_tips/custom/right/rengar-passive_side_right.png',
    },
    {
        labelEn: 'NASUS - Q: Siphoning Strike - STACKS & BONUS PHYSICAL DAMAGE',
        labelJa: 'ナサス - Q',
        team100: 'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/in-game_tips/custom/left/nasus-q-passive_side_left.png',
        team200: 'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/in-game_tips/custom/right/nasus-q-passive_side_right.png',
    },
]

