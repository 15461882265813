// REACT
import React, { Component } from 'react';
import { get } from 'lodash';
import PropTypes from 'prop-types';
// INTERNAL
import { /* send, */ subscribe } from '../../../services/api';
import ControlContainer from '../../Controller/ControlContainer';
import Button from '../../Button';
import validateDataSchedule from '../../Data/DataSchedule/validate';
import { i18n } from '../../i18n';
import moment from 'moment';
import { sendPlayersLoopLayoutWidgetData } from '../../../util/widgetUtil';
import { updateScreenStatus } from '../../../util/controllerUtils';
class ScheduleControl extends Component {

    static propTypes = {
        cgpc: PropTypes.string,
        display: PropTypes.bool,
        screen1: PropTypes.bool,
      };
    state = {
        status: 'ready',
    }

    componentDidMount() {
        this.runSubscription();
        if (validateDataSchedule()) {
            this.setState({status: 'ready'});
        } else {
            this.setState({status: 'invalid'});
        }
    }

    runSubscription = () => {
        subscribe('control-schedule', null, data => {
            if (data.target === 'control-schedule') {
                this.setState({status: data.status});
            }
        }, this.props.cgpc);
    };

    getScore = (tricode) => {
        const result = { win: 0, loss: 0 };
        const team = get(window.MainData.standings, 'REGULAR', []).find(team => team.tricode === tricode);
        if(team) {
          result.win = team.win || 0;
          result.loss = team.loss || 0;
        }
        return result;
      }

    getScheduledMatchesToSend = schedule => {
        if (!schedule) {
            schedule = {};
        }

        const roundId = Number(schedule.round || 0);
        if (!schedule.rounds[roundId]) return null;
        return schedule.rounds[roundId].matches.map(match => {
            const time = match.datetime.split('T')[1];
            const gameWinners = match.games && match.games.filter(game => game.winner);
            // console.log('match', match);

            let team100Score;
            let team200Score;

            if(match.team100){
                team100Score = gameWinners && gameWinners.filter(game => game.winner === match.team100.tricode).length;
                team200Score = gameWinners && gameWinners.filter(game => game.winner === match.team200.tricode).length;
            }
            const resp = {
                date: {
                    time: time,
                },
                team100: match.team100 && {
                    name: match.team100.name,
                    tricode: match.team100.tricode,
                    logo: (match.team100.images && match.team100.images.logoFullColor),
                    score: this.getScore(match.team100.tricode),
                    color: match.team100.color,
                    images: (match.team100.images && match.team100.images),
                },
                team200: match.team200 && {
                    name: match.team200.name,
                    tricode: match.team200.tricode,
                    logo: (match.team200.images && match.team200.images.logoFullColor),
                    score: this.getScore(match.team200.tricode),
                    color: match.team200.color,
                    images: (match.team200.images && match.team200.images),
                },
                hide: match.hide,
                skip: match.skip,
                bestOf: match.bestOf,
                games: match.games || [],
            }
            if (team100Score > match.bestOf / 2) {
                resp.winner = match.team100.tricode;
            } else if (team200Score > match.bestOf / 2) {
                resp.winner = match.team200.tricode;
            } else {
                resp.winner = '';
            }
            return resp;
        });
    }

    getSendData = () => {
        const schedule = window.MainData.schedule;
        const scheduledMatches = this.getScheduledMatchesToSend(schedule);
        const roundId = Number(schedule.round || 0);
        const sendData = {
            scheduledMatches,
            theme: '',
            next: false,
            matchCount: schedule.rounds[roundId] && schedule.rounds[roundId].matches && schedule.rounds[roundId].matches.length,
            roundName: get(window, `MainData.schedule.rounds[${window.MainData.schedule.round}].name`,''),
            splitName: get(window, `MainData.schedule.splitName`,''),
        }
        const firstMatch = schedule.rounds[roundId] && schedule.rounds[roundId].matches && schedule.rounds[roundId].matches[0];
        if (firstMatch) {
            sendData.day = moment(firstMatch.datetime).format('MMM Do');
        } else {
            sendData.day = '';
        }

        if (scheduledMatches) {
            const preloadImages = [];
            scheduledMatches.forEach(match => {
                if (match.team100 && match.team100.logo) {
                    preloadImages.push(match.team100.logo);
                }
                if (match.team200 && match.team200.logo) {
                    preloadImages.push(match.team200.logo);
                }
            });
            sendData.preloadImages = preloadImages;
        }
        return sendData;
    }

    onPlay = () => {
        if (validateDataSchedule()) {
            // const sendData = { schedule: window.MainData.schedule, teams: window.MainData.teams, leaderboard: window.MainData.leaderboard};
            sendPlayersLoopLayoutWidgetData().then(() => {
                // send('event', { target: 'view', action: 'open', screen: 'schedule', data: this.getSendData() });
                updateScreenStatus('schedule', 'open', this.getSendData(), null, this.props.cgpc);
            });
        } else {
            this.setState({status: 'invalid'});
        }
    }

    onClose = () => {
        // send('event', { target: 'view', action: 'close', screen: 'schedule' });
        updateScreenStatus('schedule', 'close', null, null, this.props.cgpc);
    }

    render() {

        return (
            <ControlContainer style={{display: this.props.display ? 'none': 'block'}} className="control--schedule" status={this.state.status}>
                <div className="control__panel">
                    <h2 className="control__title">
                        <i className="fas fa-calendar-alt"></i> {i18n().pages.schedule}
                    </h2>
                    <Button controllerSide={!this.props.screen1} action={this.onPlay} text={i18n().general.open} icon="fas fa-play-circle" disabled={
                        (this.state.status === 'open' || this.state.status === 'opening') && true
                        }
                    />
                    <Button controllerSide={!this.props.screen1} action={this.onClose} text={i18n().general.close} icon="fas fa-stop-circle" disabled={this.state.status !== 'open'}/>
                </div>
            </ControlContainer>
        )
    }
}

export default ScheduleControl
