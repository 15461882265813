// REACT
import React, { Component } from 'react';

import { get } from 'lodash';

// INTERNAL
import { /* send, */ subscribe } from '../../../services/api';
import ControlContainer from '../../Controller/ControlContainer';
import Button from '../../Button';
import validateDataSchedule from '../../Data/DataSchedule/validate';
import validateDataStandings from '../../Data/DataStandings/validate';
import { i18n } from '../../i18n';
import { updateScreenStatus } from '../../../util/controllerUtils';
import PropTypes from 'prop-types';
class BanPickControl extends Component {

    static propTypes = {
        cgpc: PropTypes.string,
        display: PropTypes.bool,
        screen1: PropTypes.bool,
      };

    state = {
        status: 'ready',
    }

    componentDidMount() {
        this.runSubscription();
        if (validateDataSchedule()) {
            this.setState({ status: 'ready' });
        } else {
            this.setState({ status: 'invalid' });
        }
    }

    runSubscription = () => {
        subscribe('control-banPick', null, data => {
            if (data.target === 'control-banPick') {
                const resultState = {
                    ...data,
                    ...data.data,
                };
                delete resultState.data;
                this.setState(resultState);
            }
        }, this.props.cgpc);
    };

    onPlay = () => {
        // check data
        if (validateDataSchedule() && validateDataStandings()) {
            const schedule = window.MainData.schedule;
            const gameId = schedule.game;
            const match = schedule.rounds[schedule.round].matches[schedule.match];
            const game = match.games[gameId];
            const gameTeam100 = Object.keys(game.teams).filter(t => { return game.teams[t].side === '100' })[0];
            const flipped = match.team100.tricode === gameTeam100 ? false : true;
            const output100 = flipped ? match.team200 : match.team100;
            const output200 = flipped ? match.team100 : match.team200;
            if (!window.MainData.standings) return <div></div>;
            const teamsBanPick = [];
            const teamsStanding = this.formatStandingsData(window.MainData.standings, schedule);
            const championsStats = {
                team100: {
                    top: {show: false, stats: {}},
                    jungle: {show: false, stats: {}},
                    mid: {show: false, stats: {}},
                    adc: {show: false, stats: {}},
                    support: {show: false, stats: {}},
                },
                team200: {
                    top: {show: false, stats: {}},
                    jungle: {show: false, stats: {}},
                    mid: {show: false, stats: {}},
                    adc: {show: false, stats: {}},
                    support: {show: false, stats: {}},
                },
            }

            teamsStanding.forEach(team => {
                if (team.tricode === output100.tricode) {
                    teamsBanPick.splice(0, 0, team);
                }
                if (team.tricode === output200.tricode) {
                    teamsBanPick.splice(1, 0, team);
                }
            });

            const sendData = {
                wonMatches: [this.getWonMatches(teamsBanPick[0].tricode, match), this.getWonMatches(teamsBanPick[1].tricode, match)],
                week: this.getWeek(schedule),
                bestOf: match.bestOf,
                teamsBanPick,
                patch: window.MainData.settings.patch,
                match: schedule.match + 1,
                preloadImages: teamsBanPick.map(team => team.logo).filter(logo => logo),
                championsStats,
            };
            updateScreenStatus('banPick', 'open', sendData, null, this.props.cgpc);
            // send('event', { target: 'control-banPick', screen: 'banPick', data: sendData });
        } else {
            this.setState({ status: 'invalid' });
        }
    }

    onClose = () => {
        // send('event', { target: 'view', action: 'close', screen: 'banPick' });
        updateScreenStatus('banPick', 'ready', null, null, this.props.cgpc);
    }

    getWeek = schedule => {
        let roundName = schedule.rounds[schedule.round].name.toUpperCase();
        if (schedule.rounds[schedule.round].name.includes('-')) {
            roundName = roundName.split('-')[0].toUpperCase();
        }
        return roundName;
    }

    getWonMatches = (tricode, match) => {
        if (!match || (match && !match.games)) return 0;
        return match.games.reduce((acc, game) => {
            if (game.winner && game.winner === tricode) {
                return acc + 1;
            }
            return acc;
        }, 0)
    }

    formatStandingsData = (standingsData, schedule) => {
        const standingDataFormated = [];
        standingsData && standingsData.REGULAR && standingsData.REGULAR.forEach(team => {
            const teamInfo = {};
            teamInfo.score = {
                win: team.win,
                loss: team.loss,
            };
            const game = schedule.rounds[schedule.round].matches[schedule.match].games[schedule.game] || null;
            if (Object.keys(game.teams).includes(team.tricode)) {
                teamInfo.coach = game && game.teams[team.tricode].roster && game.teams[team.tricode].roster.coach ? game.teams[team.tricode].roster.coach : window.MainData.teams[team.tricode].coach;
            } else {
                teamInfo.coach = window.MainData.teams[team.tricode].coach;
            }
            teamInfo.rank = 1;
            teamInfo.tricode = team.tricode;
            teamInfo.name = team.name;
            teamInfo.color = window.MainData.teams[team.tricode].color;
            teamInfo.logo = window.MainData.teams[team.tricode].images && window.MainData.teams[team.tricode].images.logoTeamColor;
            teamInfo.videoBGRight = get(window, `MainData.teams[${team.tricode}].videos.banPickRight`, 'nothing');
            teamInfo.videoBGLeft = get(window, `MainData.teams[${team.tricode}].videos.banPickLeft`, 'nothing');
            standingDataFormated.push(teamInfo);
        });
        return standingDataFormated;
    }

    render() {
        return (
            <ControlContainer style={{display: this.props.display ? 'none': 'block'}} className="control--banpick" status={this.state.status}>
                <div className="control__panel">
                    <h2 className="control__title">
                        <i className="fas fa-id-badge"></i> {i18n().pages.banpick}
                    </h2>
                    <Button controllerSide={!this.props.screen1} action={this.onPlay} text={i18n().general.open} icon="fas fa-play-circle" disabled={
                        (this.state.status === 'open' || this.state.status === 'opening') && true}
                    />
                    <Button controllerSide={!this.props.screen1} action={this.onClose} text={i18n().general.close} icon="fas fa-stop-circle" disabled={
                        !(this.state.status === 'open' || this.state.status === 'opening') && true}
                    />
                </div>
            </ControlContainer>
        )
    }
}

export default BanPickControl
