import React, { useEffect, useContext, useState } from 'react'
import PropTypes from 'prop-types'
import CastersPredictionsComponent from '../../storybook-built/dist/components/Games/LJL/CastersPredictions';
import ScreenTitle from '../../storybook-built/dist/components/Games/LJL/ScreenTitle';
import {ThemeContext } from '../../components/View/themes';
import {isEqual} from 'lodash'
function CastersPredictions(props) {

    const theme = useContext(ThemeContext);
    const [displayTitle, setDisplayTitle] = useState(false);
    const [displayPrediction, setDisplayPrediction] = useState(false);
    const [initialArray, setInitialArray] = useState([]);

    useEffect(()=>{
        if(props.active && !displayTitle){
            setDisplayTitle(true);
        }
        else if(!props.active && displayPrediction){
            setDisplayPrediction(false);
        }
    },[props.active])

    useEffect(()=>{
        if(displayTitle){
            setTimeout(()=>{setDisplayPrediction(true)}, 1000)
        }
    },[displayTitle]);

    useEffect(()=>{
        if(!displayPrediction && !props.active){
            setTimeout(()=>{setDisplayTitle(false)}, 500)
        }
    },[displayPrediction]);

    useEffect(()=>{
        if(!isEqual(props.data.castersPredictions, initialArray)) setInitialArray(props.data.castersPredictions)
    },[]);

    return (
        <div className={`container`} style={{zIndex: 3}}>
            <ScreenTitle
                title={'CASTER PREDICTION'}
                showTitle={displayTitle}
                theme={theme.screens.screenTitle || {}}
                displayUnderline
                subtitle={props.data.subtitle || ''}
            />
            <CastersPredictionsComponent
                show={displayPrediction}
                data={initialArray}
                indexPrediction={props.data.indexPrediction}
            />
        </div>
    )
}

CastersPredictions.propTypes = {
    data: PropTypes.object.isRequired,
    active: PropTypes.bool.isRequired,
}

export default CastersPredictions

