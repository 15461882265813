// REACT
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
// import styled from 'styled-components';

import { Asset } from '../styles';
import {
  StyleBaronBuffContainer,
  StyleBaronAsset,
  BuffBaronTimer,
  BuffBaronGoldDiff,
  StyleGoldDiffFinal,
  StyleBaronPowerPlaySponsor,
} from './styles';

const BaronPowerPlaySponsor = props => {
  const [showState, setShowState] = useState(false);
  const [goldDiffState, setGoldDiffState] = useState();

  useEffect(() => {
    if (!props.show) {
      if(props.goldDifference){
        setGoldDiffState(props.goldDifference);
      }
      else{
        setShowState(false);
      }
    }
    else {
      setShowState(true);
    }
  }, [props.show]);

  useEffect(() => {
   if(!showState){
    //  Reset the goldDiffState when component is no more visible
    setTimeout(()=>{
      setGoldDiffState();
    }, 2000);
   }
  }, [showState]);

  useEffect(() => {
    if(goldDiffState){
      //Delay the hide of the component to display the total goldDifference
      setTimeout(()=>{
        setShowState(false);
      }, 10000);
    }
  }, [goldDiffState]);

  return (
    <div>
        <Asset show>
        {/* {showAssetState && <img alt="" src={props.baronAsset} />} */}
          <StyleBaronBuffContainer
            color={props.color}
            isGoldDiffActive={props.goldDifference || goldDiffState}
            show={showState}
          >
          <StyleBaronAsset
            src={props.baronAsset || 'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/baron_buff_timer_NonOverlay.png'}
            show={!goldDiffState}
          />
          <BuffBaronTimer
            color={props.color}
          >
            {props.timer}
          </BuffBaronTimer>
            {(props.goldDifference &&
              <BuffBaronGoldDiff
                show={props.goldDifference && !goldDiffState}
                color={props.color}
              >
                {`${props.goldDifference > 0 ? '+' : ''}${props.goldDifference} GOLD`}
              </BuffBaronGoldDiff>) || null
          }
            <StyleGoldDiffFinal
            show={goldDiffState}
            >
              {`BARON POWER PLAY \n`}
              <span>
                {`${(goldDiffState>0)? '+':''}${goldDiffState}`}
              </span>
              <strong>{`GOLD`}</strong>
            </StyleGoldDiffFinal>
          </StyleBaronBuffContainer>

          <StyleBaronPowerPlaySponsor
            show={showState}
            alt=""
            src={'https://dekki-production.s3-ap-northeast-1.amazonaws.com/assets/broadcast/games/league-of-legends/game-overlay/nissin_cupnoodle.png'}
          />
        </Asset>
  </div>
);
}


BaronPowerPlaySponsor.propTypes = {
  show: PropTypes.bool,
  color: PropTypes.string,
  baronAsset: PropTypes.string,
  goldDifference: PropTypes.number,
  timer: PropTypes.string,
};

export default BaronPowerPlaySponsor;