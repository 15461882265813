"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StyleInfoBoxTitle = exports.StyleInfoBoxBody = void 0;

var _styledComponents = _interopRequireDefault(require("styled-components"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var StyleInfoBoxBody = _styledComponents.default.div.withConfig({
  displayName: "styles__StyleInfoBoxBody",
  componentId: "sc-1sfubw7-0"
})(["color:white;align-items:center;height:210px;position:absolute;width:1340px;overflow:visible;top:70px;bottom:0;", ";"], function (props) {
  return props.StyleInfoBoxBody ? props.StyleInfoBoxBody : '';
});

exports.StyleInfoBoxBody = StyleInfoBoxBody;

var StyleInfoBoxTitle = _styledComponents.default.div.withConfig({
  displayName: "styles__StyleInfoBoxTitle",
  componentId: "sc-1sfubw7-1"
})(["font-family:futura-pt,sans-serif;font-weight:700;letter-spacing:2px;color:white;font-size:40px;left:50%;position:absolute;text-align:center;top:5px;transform:translateX(-50%);width:100%;height:60px;line-height:60px;", ";"], function (props) {
  return props.StyleInfoBoxTitle ? props.StyleInfoBoxTitle : '';
});

exports.StyleInfoBoxTitle = StyleInfoBoxTitle;