import styled from 'styled-components';

export const ImageContainer = styled.div`
    background-image: ${props => props.backgroundImage ? `url(${props.backgroundImage})` : 'none'};
    background-color: ${props => props.teamMainColor ? props.teamMainColor : 'none'};
    background-blend-mode: multiply;
    background-position-x: 330px;
    height: 100%;
    position: relative;
    width: 160px;
    overflow: hidden;
    img {
        width: 245px;
        transform: translate(-44px,0px);
    }
`;

export const GradientContainer = styled.div`
    background-image: ${props => `linear-gradient(to ${props.side === 'left' ? 'right' : 'left'}, ${props.teamMainColor} 50% ,rgba(0,0,0,0))`};
    width: ${props => props.isFullSize ? '700px' :'450px'};
    height: 100%;
    position: absolute;
    transform:${props => props.isFullSize ? 'null' : props.side === 'left' ? 'translateX(160px)' : 'translateX(-160px)'};
    z-index: 5;
`;