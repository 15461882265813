import { validLeaderboardStats } from './leaderboardService';

const statsNameAndValue = Object.keys(validLeaderboardStats).map(statAccessor => {
  return {
    name: validLeaderboardStats[statAccessor],
    value: validLeaderboardStats[statAccessor],
  }
});

export default statsNameAndValue;
