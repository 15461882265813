// REACT
import React, { Component } from 'react';
// INTERNAL
import { /*send,*/ subscribe } from '../../../services/api';
import ControlContainer from '../../Controller/ControlContainer';
import Button from '../../Button';
import validateDataSchedule from '../../Data/DataSchedule/validate';
import { i18n } from '../../i18n';
import moment from 'moment';
import { sendPlayersLoopLayoutWidgetData } from '../../../util/widgetUtil';
import { updateScreenStatus } from '../../../util/controllerUtils';
import PropTypes from 'prop-types';
class ScheduleControlNext extends Component {

    static propTypes = {
        cgpc: PropTypes.string,
        display: PropTypes.bool,
        screen1: PropTypes.bool,
      };
    state = {
        status: 'ready',
    }

    componentDidMount() {
        this.runSubscription();
        if (validateDataSchedule()) {
            this.setState({status: 'ready'});
        } else {
            this.setState({status: 'invalid'});
        }
    }

    runSubscription = () => {
        subscribe('control-scheduleNext', null, data => {
            if (data.target === 'control-scheduleNext') {
                this.setState({status: data.status});
            }
        }, this.props.cgpc);
    };

    getScheduledMatchesToSend = schedule => {
        if (!schedule) {
            schedule = {};
        }
        // const league = schedule.league;
        // const season = schedule.season;
        // const split = schedule.split;

        const roundId = Number(schedule.round || 0) + 1;
        if (!schedule.rounds[roundId]) return null;
        return schedule.rounds[roundId].matches.map(match => {
            if (match.team100.tricode.toLowerCase() === 'tbd' || match.team200.tricode.toLowerCase() === 'tbd') {
                return null;
            }
            const time = match.datetime.split('T')[1];
            const gameWinners = match.games && match.games.filter(game => game.winner);

            const team100Score = gameWinners && gameWinners.filter(game => game.winner === match.team100.tricode).length;
            const team200Score = gameWinners && gameWinners.filter(game => game.winner === match.team200.tricode).length;

            const resp = {
                date: {
                    time: time,
                },
                team100: {
                    name: match.team100.name,
                    tricode: match.team100.tricode,
                    logo: (match.team100.images && match.team100.images.logoFullColor),
                    score: match.bestOf > 1 && (team100Score > 0 || team200Score > 0) ? team100Score : undefined,
                    color: match.team100.color,
                },
                team200: {
                    name: match.team200.name,
                    tricode: match.team200.tricode,
                    logo: (match.team200.images && match.team200.images.logoFullColor),
                    score: match.bestOf > 1 && (team100Score > 0 || team200Score > 0) ? team200Score : undefined,
                    color: match.team200.color,
                },
            }
            if (team100Score > match.bestOf / 2) {
                resp.winner = match.team100.tricode;
            } else if (team200Score > match.bestOf / 2) {
                resp.winner = match.team200.tricode;
            } else {
                resp.winner = '';
            }
            return resp;
        }).filter(match => match);
    }

    onPlay = () => {
        // check data
        if (validateDataSchedule()) {
            const schedule = window.MainData.schedule;
            const scheduledMatches = this.getScheduledMatchesToSend(schedule);
            const roundId = Number(schedule.round || 0) + 1;
            const sendData = {
                scheduledMatches,
                theme: '',
                next: true,
                matchCount: schedule.rounds[roundId] && schedule.rounds[roundId].matches && schedule.rounds[roundId].matches.length,
            }
            const firstMatch = schedule.rounds[roundId] && schedule.rounds[roundId].matches && schedule.rounds[roundId].matches[0];
            if (firstMatch) {
                sendData.day = moment(firstMatch.datetime).format('MMM D');
            } else {
                sendData.day = '';
            }

            if (scheduledMatches) {
                const preloadImages = [];
                scheduledMatches.forEach(match => {
                    if (match.team100 && match.team100.logo) {
                        preloadImages.push(match.team100.logo);
                    }
                    if (match.team200 && match.team200.logo) {
                        preloadImages.push(match.team200.logo);
                    }
                });
                sendData.preloadImages = preloadImages;
            }
            sendPlayersLoopLayoutWidgetData().then(() => {
                const logoSubtitle = window.MainData.schedule && window.MainData.schedule.rounds && window.MainData.schedule.rounds[window.MainData.schedule.round + 1] && window.MainData.schedule.rounds[window.MainData.schedule.round + 1].name;
                // send('event', { target: 'view', action: 'open', screen: 'scheduleNext', data: sendData, logoSubtitle });
                updateScreenStatus('scheduleNext', 'open', sendData, {logoSubtitle}, this.props.cgpc);
            });
        } else {
            this.setState({status: 'invalid'});
        }
    }

    onClose = () => {
        // send('event', { target: 'view', action: 'close', screen: 'scheduleNext' });
        updateScreenStatus('scheduleNext', 'close', null, null, this.props.cgpc);
    }

    render() {
        return (
            <ControlContainer style={{display: this.props.display ? 'none': 'block'}} className="control--schedule" status={this.state.status}>
                <div className="control__panel">
                    <h2 className="control__title">
                        <i className="fas fa-calendar-alt"></i> {i18n().pages.scheduleNext}
                    </h2>
                    <Button controllerSide={!this.props.screen1} action={this.onPlay} text={i18n().general.open} icon="fas fa-play-circle" disabled={
                        (this.state.status === 'open' || this.state.status === 'opening') && true
                        }
                    />
                    <Button controllerSide={!this.props.screen1} action={this.onClose} text={i18n().general.close} icon="fas fa-stop-circle" disabled={
                        !(this.state.status === 'open' || this.state.status === 'opening') && true
                        }
                    />
                </div>
            </ControlContainer>
        )
    }
}

export default ScheduleControlNext
