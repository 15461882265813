import styled from 'styled-components';


export const StyleCustomItem = styled.div`
  width: 284px;
  height: 69px;
  position: relative;
  background: ${props => `url(${props.bg})}`};
  margin-bottom: 10px;

  span {
      display: block;
      position: absolute;
      top: 35px;
      left: -60px;
      color: #EFEFEF;
      font-size: 15px;
      z-index: 1;
    }

  i {
    display: none;
  }

  &:hover{
    i {
      display: block;
      position: absolute;
      top: 2px;
      left: 2px;
      cursor: pointer;
      color: #EFEFEF;
      font-size: 18px;
      z-index: 1;
    }

  }


`;

export const StyleCustomItemText = styled.div`
  font-family: futura-pt, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  color: #ffffff;
  position: absolute;
  /* left: 65px;s */
  ${props => (props.side === 'team100')?
    `left: 65px;`
    :
    `right: 65px;`
  }
  height: 70px;
  width: 220px;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  /* border: 1px red solid; */
  white-space: pre-line;
  text-align: center;

  /* span {
    display: block;
    text-align: center;
  } */
`;