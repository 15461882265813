import React, { useContext, useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';

import { ThemeContext } from '../View/themes';
import StandingsV2Component from '../../storybook-built/dist/components/Games/LJL/StandingsV2';
import ScreenTitleV2 from '../../storybook-built/dist/components/Games/LJL/ScreenTitleV2';


const StandingsV2 = props => {
  const theme = useContext(ThemeContext)

  const [show, setShow] = useState(false)
// const interval = theme.speedPanel
const interval = 200

// Checks to see if this is the first load of the component
const isFirstRun = useRef(true);
useEffect (() => {
    if (isFirstRun.current && props.active) {
        // Sets as not the first run
        isFirstRun.current = false;
        // Show the animation AFTER the component is mounted to the DOM
        const timer1 = setTimeout(() => setShow(true), interval);
        return () => {
          clearTimeout(timer1);
      }
    }
    // If it is not the first run:
    if(show === true){
      setShow(false)
    } else if (show === false && props.active) {
      const timer2 = setTimeout(() => setShow(true), interval);
      return () => {
        clearTimeout(timer2);
    }
    }
}, [props.active]);

  return (
    <div className={'container'}>
      <ScreenTitleV2
        theme={theme.screens.screenTitleV2 || {}}
        showTitle={show}
        title={'STANDINGS'}
        isResized={get(props, `isSideContainerRender`, false)}
        // subTitleLeft={get(props, 'data.splitName', '')}
        // subTitleRight={get(props, `data.roundName`, '')}
      />
      <StandingsV2Component
        formattedStandingsData={get(props, 'data.formattedStandingsData', [])}
        isShowing={show}
        isResized={get(props, `isSideContainerRender`, false)}
        groupNames={get(props, 'data.groupNames', '')}
        theme={theme.screens.standingsV2 || {}}
      />
    </div>
  );
};

StandingsV2.propTypes = {
  active: PropTypes.bool,
  // isSideContainerRender: PropTypes.bool,
};

export default StandingsV2;
