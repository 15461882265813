// REACT
import React, { Component } from 'react';
// INTERNAL
import { /* send, */ subscribe } from '../../../services/api';
import ControlContainer from '../../Controller/ControlContainer';
import Button from '../../Button';
import {validateDataTelops} from '../../Data/DataSchedule/validate';
import {i18n} from '../../i18n';
import Picker from '../../Picker';
import _ from 'lodash';
import { updateScreenStatus } from '../../../util/controllerUtils';
import PropTypes from 'prop-types';
class TelopsControl extends Component {
    static propTypes = {
        cgpc: PropTypes.string,
        display: PropTypes.bool,
        screen1: PropTypes.bool,
      };
    state = {
        status: 'ready',
        leftTitle: 'caster',
        rightTitle: 'analyst',
        twitterVisible: false,
        telopsVisible: false,
    }

    componentDidMount() {
        this.runSubscription();
        if (validateDataTelops()) {
            this.setState({status: 'ready'});
        } else {
            this.setState({status: 'invalid'});
        }
    }

    runSubscription = () => {
        subscribe('control-telops', null, data => {
            if (data.target === 'control-telops') {
                const resultState = {
                    ...data,
                    ...data.data,
                };
                delete resultState.data;
                this.setState(resultState);
            }
        }, this.props.cgpc);
    };

    getSendData = (toUpdate, value) => {
        const teams = window.MainData.teams;
        const tweets = window.MainData.twitter.tweets;
        const player = window.MainData.telops.player;
        let teamName = '';
        if (player) {
            if (teams[player.team]) {
                teamName = teams[player.team].name;
            }
        }
        return {
            tweets,
            telops: window.MainData.telops,
            theme: window.MainData.schedule.league,
            teamName,
            update: toUpdate,
            value,
            leftTitle: this.state.leftTitle,
            rightTitle: this.state.rightTitle,
            twitterVisible: this.state.twitterVisible,
            telopsVisible: this.state.telopsVisible,
            statsVisible: this.state.statsVisible,
            tweetIndex: this.tweetIndex,
        }
    }

    listeningToTwitterChanges = false;
    lastTweets = null;
    startTwitterChangeListener = () => {
        if (this.listeningToTwitterChanges) {
            if (!_.isEqual(this.lastTweets, window.MainData.twitter.tweets)) {
                this.lastTweets = _.cloneDeep(window.MainData.twitter.tweets);
                // send('event', { target: 'view', action: 'update', screen: 'telops', data: this.getSendData() });
                updateScreenStatus('telops', 'open', this.getSendData(), null, this.props.cgpc);
            }
            setTimeout(() => {
                this.startTwitterChangeListener();
            }, 500);
        }
    }

    stopTwitterChangeListener = () => {
        this.listeningToTwitterChanges = false;
    }

    onPlay = () => {
        if (validateDataTelops()) {
            // send('event', { target: 'view', action: 'open', screen: 'telops', data: this.getSendData() });
            updateScreenStatus('telops', 'open', this.getSendData(), null, this.props.cgpc);
            this.listeningToTwitterChanges = true;
            this.startTwitterChangeListener();
        } else {
            this.setState({status: 'invalid'});
        }
    }

    onClose = () => {
        this.stopTwitterChangeListener();
        // send('event', { target: 'view', action: 'close', screen: 'telops' });
        updateScreenStatus('telops', 'close', null, null, this.props.cgpc);
    }

    changeLeftTitle = v => {
        this.setState({leftTitle: v});
    }

    changeRightTitle = v => {
        this.setState({rightTitle: v});
    }

    onUpdate = (toUpdate, v) => {
        if (toUpdate === 'twitterVisible' && v === true) {
            this.tweetIndex = 0;
        }
        window.MainData.telops.leftTitle = this.state.leftTitle;
        window.MainData.telops.rightTitle = this.state.rightTitle;
        this.setState({[toUpdate]: v }, () => {
            // send('event', { target: 'view', action: 'update', screen: 'telops', data: this.getSendData(toUpdate, v) });
            updateScreenStatus('telops', 'open', this.getSendData(), null, this.props.cgpc);
        });
    }

    tweetIndex = 0;
    onTrigger = (trigger) => {
        if (trigger === 'twitterNext' && window.MainData.twitter.tweets.selected) {
            this.tweetIndex++;
            const sendData = {
                ...this.getSendData(),
                tweetIndex: this.tweetIndex,
            }
            if (this.tweetIndex >= window.MainData.twitter.tweets.selected.length) {
                this.tweetIndex = 0;
                // sendData.tweetIndex = 0;
                sendData.twitterVisible = false;
                window.MainData.telops.leftTitle = this.state.leftTitle;
                window.MainData.telops.rightTitle = this.state.rightTitle;
                this.setState({twitterVisible: false });
                updateScreenStatus('telops', 'open', sendData, null, this.props.cgpc);
                return;
            }
            // send('event', { target: 'view', action: 'trigger', screen: 'telops', data: sendData });
            updateScreenStatus('telops', 'open', sendData, null, this.props.cgpc);
        }
    }

    render() {
        const leftPickerOptions = [
            {value: 'caster', name: i18n().general.caster},
            {value: 'analyst', name: i18n().general.analyst},
            {value: 'interviewer', name: i18n().general.interviewer},
        ];
        const rightPickerOptions = [
            {value: 'none', name: i18n().general.none},
            {value: 'caster', name: i18n().general.caster},
            {value: 'analyst', name: i18n().general.analyst},
            {value: 'player', name: i18n().general.player},
         ];

        return (
            <ControlContainer style={{display: this.props.display ? 'none': 'block'}} className="control--telops" status={this.state.status}>
                <div className="control__panel">
                    <h2 className="control__title">
                        <i className="fas fa-headset"></i> {i18n().pages.telops}
                    </h2>
                    <Button controllerSide={!this.props.screen1} action={this.onPlay} text={i18n().general.open} icon="fas fa-play-circle" disabled={
                        (this.state.status === 'open' || this.state.status === 'opening') && true }
                    />
                    <Button controllerSide={!this.props.screen1} action={this.onClose} text={i18n().general.close} icon="fas fa-stop-circle" disabled={
                        !(this.state.status === 'open' || this.state.status === 'opening') && true }
                    />
                </div>
                <div className="control-extra">
                    <div className="control-extra__fieldset">
                        <label className="control-extra__fieldset__label">Display Control</label>
                        <div className="pill">
                            <span>{i18n().general.leftShort}</span>
                            <Picker items={leftPickerOptions} preselect={this.state.leftTitle} onUpdate={this.changeLeftTitle} />
                            <span>{i18n().general.rightShort}</span>
                            <Picker items={rightPickerOptions} preselect={this.state.rightTitle} onUpdate={this.changeRightTitle} />
                        </div>
                    </div>
                    <div className="control-extra__fieldset">
                        <label className="control-extra__fieldset__label">Quick Set</label>
                        <div className="pill">
                            <Button text={`${i18n().general.caster} / ${i18n().general.analyst}`} action={() => this.setState({leftTitle: 'caster', rightTitle: 'analyst'})} />
                            <Button text={`${i18n().general.interviewer} / ${i18n().general.player}`} action={() => this.setState({leftTitle: 'interviewer', rightTitle: 'player'})} />
                        </div>
                    </div>
                    <div className="control-extra__fieldset">
                        <label className="control-extra__fieldset__label">Telops</label>
                        <div className="pill">
                            {
                                this.state.telopsVisible ?
                                [
                                    <span key="1a" className="pill__span--highlighted">{i18n().controller.viewable.true}</span>,
                                    <Button controllerSide={!this.props.screen1} key="1b" text="Hide" action={() => this.onUpdate('telopsVisible', false)} />,
                                ] :
                                [
                                    <span key="2a">{i18n().controller.viewable.false}</span>,
                                    <Button controllerSide={!this.props.screen1} key="2b" text="Show" action={() => this.onUpdate('telopsVisible', true)} />,
                                ]
                            }
                        </div>
                    </div>
                    <div className="control-extra__fieldset">
                        <label className="control-extra__fieldset__label">Stats</label>
                        <div className="pill">
                            {
                                this.state.statsVisible ?
                                [
                                    <span key="1a" className="pill__span--highlighted">{i18n().controller.viewable.true}</span>,
                                    <Button controllerSide={!this.props.screen1} key="1b" text="Hide" action={() => this.onUpdate('statsVisible', false)} />,
                                ] :
                                [
                                    <span key="2a">{i18n().controller.viewable.false}</span>,
                                    <Button controllerSide={!this.props.screen1} key="2b" text="Show" action={() => this.onUpdate('statsVisible', true)} />,
                                ]
                            }
                        </div>
                    </div>
                    <div className="control-extra__fieldset">
                        <label className="control-extra__fieldset__label">Twitter</label>
                        <div className="pill">
                            {
                                this.state.twitterVisible ?
                                [
                                    <span key="1a" className="pill__span--highlighted">{i18n().controller.viewable.true}</span>,
                                    <Button controllerSide={!this.props.screen1} key="1b" text="Hide" action={() => this.onUpdate('twitterVisible', false)} />,
                                ] :
                                [
                                    <span key="2a">{i18n().controller.viewable.false}</span>,
                                    <Button controllerSide={!this.props.screen1} key="2b" text="Show" action={() => this.onUpdate('twitterVisible', true)} />,
                                ]
                            }
                        </div>
                        <div className="pill">
                            <Button text="Next" action={() => this.onTrigger('twitterNext', true)} />
                        </div>
                    </div>
                </div>
            </ControlContainer>
        )
    }
}

export default TelopsControl
