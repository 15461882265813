// REACT
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { delay, get } from 'lodash';
// LIBRARIES
// internal
import allChamps from '../../services/championService';
import {loadImageUrlChampSquare} from '../../util/loadImageUrl';
import { checkLang } from '../i18n';
import {aiueoGroup} from '../../util/aiueo';

class ChampSelect extends Component {

    static propTypes = {
        disabled: PropTypes.bool,
        selected: PropTypes.any,
        onChampSelect: PropTypes.func,
        assignRef: PropTypes.string,
        sendChampID: PropTypes.func,
        position: PropTypes.any,
        side: PropTypes.string,
    };

    state = {
        open: false,
        filterKey: '',
    };

    handleOnInput = (e) => {
        this.setState({filterKey: e.target.value});
    }

    openModal = () => {
        this.setState({open: true, filterKey: ''});
        delay(() => ()=>{document.getElementById(`#champ-select-search-input-${this.props.assignRef}`).focus()}, 100);
    }

    selectChamp = (champId = '') => {
        this.props.onChampSelect(this.props.assignRef, champId);
        this.setState({open: false});
        if((this.props.sendChampID && this.props.position && this.props.side) || (this.props.sendChampID && (this.props.position === 0) && this.props.side)) {
            this.props.sendChampID(this.props.position, champId, this.props.side)
        }
    }

    closeModal = () => {
        this.setState({open: false});
    }

    render() {
        const champArray = Object.keys(allChamps).map((champKey) => {
            return { key: champKey, name: allChamps[champKey].name, englishName: allChamps[champKey].englishName, id: allChamps[champKey].id, aiueo: aiueoGroup(allChamps[champKey].name.charAt(0))}
        }).sort((a,b) => {
            return (a.name < b.name) ? -1 : 1;
        });
        return (
            <div
                className={`champ-select ${this.props.disabled ? 'champ-select--disabled' : ''}`}
                title={allChamps[this.props.selected] ? `${allChamps[this.props.selected][(checkLang() === 'en') ? 'englishName' : 'name']}(${this.props.selected})`:''}
            >
                <img className="champ-select__image" src={this.props.selected ? loadImageUrlChampSquare(get(allChamps, `${this.props.selected}.id`, null)) : 'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/square/nochamp.png'} alt={this.props.selected ? get(allChamps, `${this.props.selected}.name`, '') : ''} onClick={this.openModal} />
                <span className={`champ-select__name ${this.props.disabled ? 'champ-select__name--disabled' : ''}`} onClick={this.openModal}>
                    {this.props.selected ? get(allChamps, `${this.props.selected}.${(checkLang() === 'en') ? 'englishName' : 'id'}`, '') : ''}
                </span>
                {
                    this.state.open &&
                    <div className="champ-select__modal">
                        <SearchControlContainer>
                            {/* Close Button */}
                            <CloseBtn onClick={this.closeModal}>
                                {/* {i18n().general.close} */}
                                <i className="fa fa-times" />
                            </CloseBtn>

                            <SearchWrapper>
                                {/* Search Input */}
                                <SearchInput className="champ-select__modal__champ__image" title={'Click on the search icon and type a letter'}>
                                    <i className="fas fa-search"/>
                                    <input id={`champ-select-search-input-${this.props.assignRef}`} className="champ-select__modal__input" onInput={this.handleOnInput} defaultValue={this.state.filterKey} />
                                </SearchInput>
                                {/* No Champion Button */}
                                <StyleNoChampion className="champ-select__modal__champ__image" onClick={() => this.selectChamp()} title={'No champion'}>
                                    <i className="fas fa-user-slash" size={'x'} color={'#fffff'}></i>
                                </StyleNoChampion>
                            </SearchWrapper>
                        </SearchControlContainer>
                        {
                            champArray.map((champ, i) => {
                                if (this.state.filterKey === '' || champ.englishName.toLowerCase().startsWith(this.state.filterKey.toLowerCase()) || champ.name.toLowerCase().startsWith(this.state.filterKey.toLowerCase())) {
                                    return (<div className="champ-select__modal__champ" key={i} onClick={() => this.selectChamp(champ.key)} title={`${(checkLang() === 'en') ? champ.englishName : champ.name}(${champ.key})`}>
                                        <img className="champ-select__modal__champ__image" src={loadImageUrlChampSquare(champ.id)} alt={(checkLang() === 'en') ? champ.englishName : champ.name} />
                                        <p className="champ-select__modal__champ__name">{(checkLang() === 'en') ? champ.englishName : champ.name}</p>
                                    </div>)
                                }
                                return false;
                            })
                        }
                    </div>
                }
            </div>
        )
    }
}

const CloseBtn = styled.div`
    cursor: pointer;
    width: 42px;
    height: 42px;
    border: 1px solid #595858;
    transition: 0.5s ease;
    i {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        width: 100%;
    }
    svg {
        width: 60% !important;
        height: 60%;
        opacity: 0.8;
    }
    &:hover {
        background-color: #dbdbdb;
    }
`;
const StyleNoChampion = styled.div`
    display: flex;
    height: 42px;
    width: 42px;
    border: 2px #999999 solid;
    font-size: 45px;
    color: #000000;
    justify-content: center;
    align-items: center;
    margin-bottom: 4px;
    cursor: pointer;
    transition 0.5s ease;
    &:hover {
        background-color: #dbdbdb;
    }
    i {
        transform: scale(0.6);
        z-index: 1;
    }
`;

const SearchInput = styled.div`
    display: flex;
    height: 42px;
    width: 500px;
    border: 2px #999999 solid;
    font-size: 45px;
    color: #000000;
    justify-content: center;
    align-items: center;
    margin-bottom: 4px;
    margin-right: 25px;
    input {
        font-size: 30px;
        text-align: left;
        width: 100%;
        height: 100%;
        z-index: 2;
        background-color: transparent;
    }
    i {
        color: #CCCCCC;
        transform: scale(0.6);
        z-index: 1;
        transition: 0.5s ease;
    }
    &:hover i, &:focus-within i {
        color: #595858;
    }
`;

const SearchControlContainer = styled.div`
    width: 100%;
    display: flex;
    margin-bottom: 25px;
`;

const SearchWrapper = styled.div`
    display: flex;
    padding-right: 25px;
    padding-left: 25px;
`;

export default ChampSelect