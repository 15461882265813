// REACT
import React from 'react';
import PropTypes from 'prop-types';

const Interviewee = props => (
  <div className={`animated animated--fast telops__interviewee telops__interviewee--${props.teamTricode ? props.teamTricode.toUpperCase() : ''}`}>
    <div className="animated animated--fast telops__interviewee__content">
      <div className="telops__interviewee__content__title">
        <div className="animated animated--fast telops__interviewee__content__text">{props.team}</div>
      </div>
      <div className={`telops__interviewee__content__separator telops__interviewee__content__separator--${props.teamTricode ? props.teamTricode.toUpperCase() : ''}`}></div>
      <div className="telops__interviewee__content__name">
        <div className="animated animated--fast telops__interviewee__content__text">{props.name}</div>
      </div>
    </div>

    <div className="animated animated--fast telops__interviewee__stats">
      <div className="telops__interviewee__stats__stat">
        <div className="telops__interviewee__stats__stat__text"><span>DPM</span>{props.dpm}</div>
      </div>
      <div className="telops__interviewee__stats__stat">
        <div className="telops__interviewee__stats__stat__text"><span>KP</span>{props.kp}</div>
      </div>
      <div className="telops__interviewee__stats__stat">
        <div className="telops__interviewee__stats__stat__text"><span>KDA</span>{props.kda}</div>
      </div>
    </div>
  </div>
);

Interviewee.propTypes = {
  dpm: PropTypes.string,
  kda: PropTypes.string,
  kp: PropTypes.string,
  name: PropTypes.string,
  team: PropTypes.string,
  teamTricode: PropTypes.string,
};

export default Interviewee