// REACT
import React from 'react';
import PropTypes from 'prop-types';

const Button = props => (
  !props.controllerSide ?
  <div
    className={`button ${props.disabled ? 'button--disabled' : 'interactive'} ${props.className || ''}`}
    onClick={props.disabled ? () => false : props.action}
    style={props.style}
    title={props.title || ''}
  >
    {props.icon && <i className={props.icon}/>}
    {props.text}
  </div> :
  <div
  className={`button ${props.disabled ? 'button--disabled' : 'button--part2'} ${props.className || ''}`}
  onClick={props.disabled ? () => false : props.action}
  style={props.style}
  title={props.title || ''}
  >
  {props.icon && <i className={props.icon}/>}
  {props.text}
</div>
);

Button.propTypes = {
  action: PropTypes.func,
  disabled: PropTypes.bool,
  text: PropTypes.string,
  icon: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.object,
  title: PropTypes.string,
  controllerSide: PropTypes.bool,
};

export default Button