// REACT
import React, { Component } from 'react';
import PropTypes from 'prop-types';
// 3RD PARTY
import { delay } from 'lodash';
import moment from 'moment';
// ME
import './styles/index.css';
import { subscribe } from './services/api';
import { setCgpcKey } from './util/firebase';

class App extends Component {

  state = {
    startTime: window.ljlStartTime || 0,
    time: "00:00",
    viewScale: 100,
    isController: false,
  };

  static propTypes = {
    children: PropTypes.any,
  };

  componentDidMount() {
    this.getTime();
    // this.runSubscription();
    this.checkIsController();
    document.addEventListener("keyup",  e => {
      if (e.which === 49) {
        this.setState({viewScale: 100});
        //window.resizeTo(1920, 1080);
      }
      if (e.which === 50) {
        this.setState({viewScale: 50});
        //window.resizeTo(960, 540);
      }
      if (e.which === 51) {
        this.setState({viewScale: 25});
        //window.resizeTo(480, 270);
      }
    })

    if (window.location.pathname.startsWith('/cgpc1')) {
      setCgpcKey('cgpc1');
    } else if (window.location.pathname.startsWith('/cgpc2')) {
      setCgpcKey('cgpc2');
    } else if (window.location.pathname.startsWith('/msi1')) {
      setCgpcKey('msi1');
    } else if (window.location.pathname.startsWith('/msi2')) {
      setCgpcKey('msi2');
    } else if (window.location.pathname.startsWith('/pubgmjc1')) {
      setCgpcKey('pubgmjc1');
    } else if (window.location.pathname.startsWith('/pubgmjc2')) {
      setCgpcKey('pubgmjc2');
    } else if (window.location.pathname.startsWith('/academy1')) {
      setCgpcKey('academy1');
    } else if (window.location.pathname.startsWith('/academy2')) {
      setCgpcKey('academy2');
    }
  }

  UNSAFE_componentWillReceiveProps() {
    this.getTime();
  }

  runSubscription = () => {
    subscribe(data => {
      if (data.type === 'time' && data.data.startTime) return this.updateStartTime(data.data.startTime);
      if (data.type === 'toggletime') this.toggleTimer();
    });
  };

  getTime = () => {
    if (this.state.startTime === 0) return;

    const currDate = new Date();
    if (+moment(currDate) > moment(this.state.startTime)) return this.setState({startTime: 0});

    const until = Math.abs((+moment(currDate).diff(moment(this.state.startTime)) / 1000));
    let minutes = Math.floor(until / 60);
    let seconds = Math.floor(until % 60);

    minutes = minutes < 10 ? `0${minutes}` : minutes;
    seconds = seconds < 10 ? `0${seconds}` : seconds;

    const time = `${minutes}:${seconds}`;

    this.setState({time});

    delay(this.getTime, 1000);
  };

  checkIsController = () => {
    const path = window.location.pathname;
    const isController = path.startsWith('/controller');
    this.setState({isController});
  }

  updateStartTime = time => {
    if (time) this.setState({startTime: +moment(time)}, () => {
      window.ljlStartTime = this.state.startTime;
      this.getTime();
    });
    else this.setState({startTime: 0}, () => window.ljlStartTime = 0);
  };

  toggleTimer = () => {
    document.querySelector('.time-countdown').classList.toggle('time-countdown--active');
  };

  render() {
    return (
      <div className={`App ${this.state.isController ? 'is-controller' : ''} ${this.state.viewScale && this.state.viewScale !== 100 ? 'scale'+this.state.viewScale : ''} ${window.pageYOffset > 5 ? 'header--scrolled' : ''}`}>
        <div className="animated animated--fast time-countdown">{this.state.time}</div>
        {this.props.children}
        {
          this.state.viewScale !== 100 &&
          <div className="scale-crop-1"></div>
        }
        {
          this.state.viewScale !== 100 &&
          <div className="scale-crop-2"></div>
        }
      </div>
    );
  }
}

export default App;
