export default {
  id: 'msi2019',
  name: 'MSI 2019',
  screens: {
    animatedBackground: {
      show: true,
    },
    gameBreakdown: {
      teamStats: {
        cssTeamName: "font-family: 'United'; font-weight:800; color: #ffffff;",
        cssTeamStats:
          "font-family: 'United'; background-color: #593d25; color: #ffffff;",
        cssWinTag: 'background-color: #000000aa; color: #ffffff;',
        cssLossTag: 'background-color: #00000033; color: #ffffffcc;',
        cssBansPicks: 'color: #ffffff;',
      },
      key: {
        cssHeader: "font-family: 'United';",
        cssTitles: "font-family: 'United';",
      },
      graph: {
        cssHeader:
          "font-family: 'United'; font-weight: 400; color: #0a0b0c; background-color: #ab8652; background-image: unset;",
        cssContent:
          "font-family: 'United'; font-weight: 400; background-color: #1f1d1acc; background-image: unset;",
        cssKey: "font-family: 'United'; font-weight: 400;",
      },
    },

    schedule: {
      speedPanel: 200,
      cssRow: 'font-family:United, "sans-serif"; color:#ffffff;',
      cssRowNext: 'color:#dba103;',
      cssRowFinished: '',
      cssDate: 'background-color: #ab8652; padding-top: 10px;',
      cssDateFinished: 'background-color: #51422d; color:#151515;',
      cssDateNext: 'background-color: #151515;',
      cssLogo: 'background-color: #151515;',
      cssLogoNext: '',
      cssLogoLoser: '',
      cssLogoWinner: '',
      cssName: 'background-color: #ab8652; padding-top: 10px; font-size: 46px;',
      cssNameNext: 'background-color: #151515; ',
      cssNameLoser: 'background-color: #51422d;color:#151515;',
      cssNameWinner: 'color:#151515;',
      cssVersus:
        'padding-top: 10px; background-color: #ab8652; font-size: 50px;',
      cssVersusNext: 'background-color: #151515; color:#dba103;',
      cssScore: 'color:#151515; background-color: #ab8652; padding-top: 10px;',
      cssScoreLoser: 'background-color: #51422d;',
      cssScoreWinner: '',
      trimColorNext: '#dba103',
      rightBorderColor: '#ab8652;',
      rightBorderColorLoser: '#51422d;',
    },
    bottomBar: {
      borderColor: 'teamMain',
      borderColorBase: '#181818',
      backgroundColor: '#181818',
      cssTeamName: "font-family: 'United', sans-serif; color:#ffffff;",
      cssNextGameTitle: "font-family: 'United', sans-serif;",
      cssTimer: "font-family: 'United', sans-serif;",
    },
    screenTitle: {
      textColor: '#ffffff',
      cssTitle:
        'font-family:United, sans-serif; font-weight: 400; text-shadow: unset;',
    },
    cornerLogo: {
      cornerLogoImage:
        'https://cdn.dekki.com/uploads/broadcast-projects/msi2019/msi-logo_topleft.png',
      cssCornerLabelText: 'display:none;',
      startX: '-500px',
      endX: '-100px',
      startY: '0px',
      endY: '0px',
    },
    standings: {
      showSubtext: true,
      cssCell: {
        backgroundColor: '#151515',
        color: '#ab8652',
        fontFamily: 'United, sans-serif',
        fontWeight: '800',
        verticalAlign: 'sub',
        paddingTop: '10px',
      },
      cssRankCell: {},
      cssSubtextCell: {
        backgroundColor: '#ab8652',
        color: '#151515',
      },
      cssLogoCell: {
        backgroundColor: '#151515',
      },
      cssNameCell: {},
      cssScoreCell: {
        backgroundColor: '#ab8652',
        color: '#151515',
      },
      cssHeaderCell: {
        color: '#ffffff',
        fontFamily: 'United, sans-serif',
        fontWeight: '100',
      },
    },
  },
};
