import _ from 'lodash';
import styled from 'styled-components';
// REACT
import React, { Component } from 'react';
// internal
import DataContainer from '../DataContainer';
import Button from '../../Button';

import Picker from '../../Picker';
import { subscribe } from '../../../services/api';
import { firebaseRealtimeDbSetValue } from '../../../util/firebase';
import { i18n } from '../../i18n';

const dataroot = 'dataResultsList';
class DataResultsList extends Component {

    state = {
        selectedRound: 0,
        selectedMatch: 0,
        selectedGame: 0,
        roundName: '',
        matchName: '',
        gameName: '',
        gameNumbers: ['1'],
        msg: '',
        storedData: {},
    };

    static propTypes = {
    };

    runSubscription = () => {
        subscribe('data-resultsList', null, data => {
            if (data) {
                this.updateResultsList({ storedData: data }).then(() => {
                    this.populateFieldsForGame(this.state.selectedRound, this.state.selectedMatch, this.state.selectedGame);
                });
            }
        });
    };

    componentDidMount() {
        const schedule = window.MainData.schedule;

        const roundName = schedule.rounds[schedule.round].name || (schedule.round + 1).toString();
        const matchName = schedule.rounds[schedule.round].matches[schedule.match].name || (schedule.match + 1).toString();
        const gameName =  (schedule.game + 1).toString();
        const gameCount = schedule.rounds[schedule.round].matches[schedule.match].bestOf;
        const pointMapping = window.MainData.pointMapping;
        const gameNumbers = [];
        for (let i = 0; i < gameCount;i++){
            gameNumbers.push((i+1).toString())
        }

        this.updateResultsList({
            roundName,
            matchName,
            gameName,
            selectedRound: schedule.round || 0,
            selectedMatch: schedule.match || 0,
            selectedGame: schedule.game || 0,
            gameNumbers,
            pointMapping,
        });
        this.runSubscription();
    }

    updateResultsList = async newState => {
        await new Promise(resolve => {
            this.setState(newState, () => {
                window.MainData[dataroot] = this.state;
                resolve();
            });
            }
         );
    };

    componentDidUpdate(prevProps, prevState) {
        if (this.state.selectedRound !== prevState.selectedRound ||
            this.state.selectedMatch !== prevState.selectedMatch ||
            this.state.selectedGame !== prevState.selectedGame) {
                this.recalculateNames().then(() => {
                    this.populateFieldsForGame(this.state.selectedRound, this.state.selectedMatch, this.state.selectedGame);
                });
        }
    }

    recalculateNames = async () => {
        const schedule = window.MainData.schedule;

        const roundName = schedule.rounds[this.state.selectedRound].name || (parseInt(this.state.selectedRound, 10) + 1).toString();
        const matchName = schedule.rounds[this.state.selectedRound].matches[this.state.selectedMatch].name || (parseInt(this.state.selectedMatch, 10) + 1).toString();
        const gameName =  (parseInt(this.state.selectedGame, 10) + 1).toString();
        const gameCount = schedule.rounds[this.state.selectedRound].matches[this.state.selectedMatch].bestOf;
        const gameNumbers = [];
        for (let i = 0; i < gameCount; i++){
            gameNumbers.push((i + 1).toString());
        }

        await this.updateResultsList({
            roundName,
            matchName,
            gameName,
            gameNumbers,
        });
    }

    getTeams = () => {
        let teams = window.MainData.schedule.rounds[this.state.selectedRound] &&
            window.MainData.schedule.rounds[this.state.selectedRound].matches[this.state.selectedMatch] &&
            window.MainData.schedule.rounds[this.state.selectedRound].matches[this.state.selectedMatch].teams;
        if (!teams) {
            teams = _.values((window.MainData.schedule.rounds[this.state.selectedRound] &&
                window.MainData.schedule.rounds[this.state.selectedRound].matches[this.state.selectedMatch] &&
                window.MainData.schedule.rounds[this.state.selectedRound].matches[this.state.selectedMatch].games &&
                window.MainData.schedule.rounds[this.state.selectedRound].matches[this.state.selectedMatch].games[this.state.selectedGame] &&
                window.MainData.schedule.rounds[this.state.selectedRound].matches[this.state.selectedMatch].games[this.state.selectedGame].teams)|| {});
        }
        return teams;
    }

    changeRound = round => {
        this.updateResultsList({selectedRound: round || 0, selectedMatch: 0, selectedGame: 0})
    }

    changeMatch = match => {
        this.updateResultsList({selectedMatch: match || 0, selectedGame: 0})
    }

    changeGame = game => {
        this.updateResultsList({selectedGame: game || 0})
    }

    getSendData = () => {
        const resultsListData = this.getTeams().map(team => ({
            name: team.name,
            tricode: team.tricode,
            place: parseInt(document.getElementById(`${team.tricode}-place`).value, 10) || 0,
            kills: parseInt(document.getElementById(`${team.tricode}-kills`).value, 10) || 0,
            penalties: parseInt(document.getElementById(`${team.tricode}-penalties`).value, 10) || 0,
        }));
        console.log('resultsListData', resultsListData);
        return {
            round: parseInt(this.state.selectedRound, 10),
            match: parseInt(this.state.selectedMatch, 10),
            game: parseInt(this.state.selectedGame, 10),
            resultsListData,
        }
    }

    sendTheData = async sendData => {
        await firebaseRealtimeDbSetValue(`data-resultsList/${this.state.selectedRound}/${this.state.selectedMatch}/${this.state.selectedGame}`, sendData);
    }

    updateLocalData = sendData => {
        const localData = _.cloneDeep(this.state.storedData);
        if (!localData[this.state.selectedRound]) {
            localData[this.state.selectedRound] = {};
        }
        if (!localData[this.state.selectedRound][this.state.selectedMatch]) {
            localData[this.state.selectedRound][this.state.selectedMatch] = {};
        }

        localData[this.state.selectedRound][this.state.selectedMatch][this.state.selectedGame] = sendData;
        this.setState({ storedData: localData, msg: 'SAVED!' }, () => {
            setTimeout(() => {
                this.setState({ msg: '' });
            }, 5000);
        });
    }

    save = async () => {
        const sendData = this.getSendData();
        await this.sendTheData(sendData);
        this.updateLocalData(sendData);
    }

    clearFields = (ignoreTricodes = []) => {
        this.getTeams().forEach(team => {
            if (!ignoreTricodes.includes(team.tricode)) {
                document.getElementById(`${team.tricode}-place`).value = 0;
                document.getElementById(`${team.tricode}-kills`).value = 0;
                document.getElementById(`${team.tricode}-penalties`).value = 0;
            }
        });
    }


    clearData = () => {
        if (window.confirm('Are you sure?')) {
            this.setState({ storedData: {} }, async () => {
                await firebaseRealtimeDbSetValue('data-resultsList', {});
                this.clearFields();
            });
        }
    }

    populateFieldsForGame = (round, match, game) => {
        if (this.state.storedData) {
            if (this.state.storedData[round]) {
                if (this.state.storedData[round][match]) {
                    const gameData = this.state.storedData[round][match][game];
                    if (gameData) {
                        const processedTricodes = [];
                        const resultsArray = gameData.resultsListData || [];
                        resultsArray.forEach(result => {
                            processedTricodes.push(result.tricode);
                            const placeComponent = document.getElementById(`${result.tricode}-place`);
                            if (placeComponent) {
                                placeComponent.value = result.place;
                            }
                            const killsComponent = document.getElementById(`${result.tricode}-kills`);
                            if (killsComponent) {
                                killsComponent.value = result.kills;
                            }
                            const penaltiesComponent = document.getElementById(`${result.tricode}-penalties`);
                            if (penaltiesComponent) {
                                penaltiesComponent.value = result.penalties;
                            }
                        });
                        this.clearFields(processedTricodes);
                    } else {
                        this.clearFields();
                    }
                } else {
                    this.clearFields();
                }
            } else {
                this.clearFields();
            }
        } else {
            this.clearFields();
        }
    }

    render () {
        return (
            <DataContainer componentSlug="resultsList" extra={'Results List'} icon="fas fa-font">
                <div className="data-panel__fieldset">
                    <div className="data-panel__fieldset__body">
                        <Picker preselect={this.state.selectedRound.toString()}
                            items={(window.MainData.schedule.rounds || []).map((round, i) => ({
                                    value: i,
                                    name: round.name || `Round ${i+1}`,
                                }))}
                                onUpdate={this.changeRound}
                        />
                        <Picker preselect={this.state.selectedMatch.toString()}
                            items={(window.MainData.schedule.rounds[this.state.selectedRound].matches || []).map((match, i) => ({
                                value: i,
                                name: match.name || `Match ${i+1}`,
                            }))}
                            onUpdate={this.changeMatch}
                        />
                        <Picker preselect={this.state.selectedGame.toString()}
                            items={this.state.gameNumbers.map((game, i) => ({
                                value: i,
                                name: game.name || `Game ${i+1}`,
                            }))}
                            onUpdate={this.changeGame}
                        />
                        <button style={{float: 'right', color: 'white', backgroundColor: 'red'}} onClick={this.clearData}>CLEAR DATA FOR ALL ROUNDS</button>
                        <p>ROUND [{this.state.roundName}] MATCH [{this.state.matchName}] GAME [{this.state.gameName}] PLACEMENT:</p>
                        <StyleTable><tbody>
                        <StyleTr>
                            <StyleTh>Team</StyleTh>
                            <StyleTh><span title="Position of the Team in this Game (CANNOT be empty or '0')">Place&#9432;</span></StyleTh>
                            <StyleTh>Kills</StyleTh>
                            <StyleTh><span title="The number of penalty point to be removed from the total points of the team (always positive number)">Penalties&#9432;</span></StyleTh>
                        </StyleTr>
                        {
                            this.getTeams().map((team, i) => (
                                // <div key={`team${i}`}>
                                    <StyleTr key={`team${i}`}>
                                        <StyleTd>{team.name}</StyleTd>
                                        <StyleTdInput><StyleInput className='input' min="1" autocomplete="place" type="number" id={`${team.tricode}-place`}></StyleInput></StyleTdInput>
                                        <StyleTdInput><StyleInput className='input' min="0" autocomplete="kills" type="number" id={`${team.tricode}-kills`}></StyleInput></StyleTdInput>
                                        <StyleTdInput><StyleInput className='input' min="0" autocomplete="penalties" type="number" id={`${team.tricode}-penalties`}></StyleInput></StyleTdInput>
                                    </StyleTr>
                                // </div>
                            ))
                        }
                        </tbody></StyleTable>
                        <StyleButtonContainer>
                            <Button action={this.save} text={i18n().general.save}/>
                        </StyleButtonContainer>
                        <p style={{color: '#22FF00'}}>{this.state.msg}</p>
                    </div>
                </div>
            </DataContainer>
        );

    }

}

const StyleTable = styled.table`
    border: 1px solid black;
    margin: auto;
`;

const StyleTh = styled.th`
    border: 1px solid black;    
`;

const StyleTr = styled.tr`
    :nth-child(even){background-color: #f2f2f2;}
`;

const StyleInput = styled.input`
    text-align: center;
    padding: 0 5px;
    line-height: 30px;
    font-size: 18px;
    background-color:  ${props => props.error ? 'red':'transparent'};
`;

const StyleTdInput = styled.td`
    border: 1px solid black;
`;

const StyleTd = styled.td`
    padding: 3px;
    border: 1px solid black;    
`;

const StyleButtonContainer = styled.div`
    margin-top: 10px;
    text-align: center;
`;

export default DataResultsList;
