"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StyleTitle = exports.StyleMainContainer = exports.StyleChampionsList = void 0;

var _styledComponents = _interopRequireDefault(require("styled-components"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var StyleMainContainer = _styledComponents.default.div.withConfig({
  displayName: "styles__StyleMainContainer",
  componentId: "sc-6rz8z9-0"
})(["position:absolute;left:0;top:110px;width:305px;height:515px;transition:transform 500ms ease-in-out;transform:", ";background-image:url('https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/xp_levels_bg.png');background-size:contain;background-repeat:no-repeat;overflow:hidden;", ";"], function (props) {
  return props.show ? 'translateX(0)' : 'translateX(-100%)';
}, function (props) {
  return props.StyleMainContainer ? props.StyleMainContainer : '';
});

exports.StyleMainContainer = StyleMainContainer;

var StyleTitle = _styledComponents.default.div.withConfig({
  displayName: "styles__StyleTitle",
  componentId: "sc-6rz8z9-1"
})(["text-align:left;font-size:24px;font-family:aktiv-grotesk-ex-bold;color:#F5F5F5;padding:15px 20px 5px 15px;line-height:34px;", ";"], function (props) {
  return props.StyleTitle ? props.StyleTitle : '';
});

exports.StyleTitle = StyleTitle;

var StyleChampionsList = _styledComponents.default.div.withConfig({
  displayName: "styles__StyleChampionsList",
  componentId: "sc-6rz8z9-2"
})(["height:456px;display:flex;flex-direction:column;justify-content:space-evenly;overflow:hidden;", ";"], function (props) {
  return props.StyleChampionsList ? props.StyleChampionsList : '';
});

exports.StyleChampionsList = StyleChampionsList;