
export default {
  id: 'worlds2023',
  name: 'Worlds',
  // Go checkout MSI2022_theme if thing's seem off.
  screens: {
    cornerLogo: {
      cssCornerLabelText:
        'display:none;',
      startX: '-400px',
      endX: '-400px',
      startY: '0px',
      endY: '0px',
      hideCornerLogo: true, // hide the CornerLogo by default on all screens
    },
    animatedBackground: {
      show: true,
      defaultBackground:
        'https://dekki-production.s3.ap-northeast-1.amazonaws.com/uploads/broadcast-projects/worlds/2023/videos/worlds2023_livingholds_glory.mp4',
      disableAnimationBackGround: false,
    },
    screenTitleV2: {
      StyleTitleContainer: `margin-top: -25px;left: 220.5px; display: block; width: 1460px;`,
      StyledLogo: `display: none; margin-left:9.5px;max-width:1460px;height:102px;background:
        url('https://dekki-production.s3.ap-northeast-1.amazonaws.com/uploads/broadcast-projects/worlds/2022/worlds_2022_banner_icon_1_crop.png') left,
        url('https://dekki-production.s3.ap-northeast-1.amazonaws.com/uploads/broadcast-projects/worlds/2022/worlds_2022_banner_icon_2_crop.png') right;background-repeat: no-repeat;`,
      StyledLogoResized: 'margin-left:0px;',
      StyledBar: `background: transparent;height:102px;left:9.5px;`,
      StyledBarResizeOverWrite: 'left:0px;',
      StyleTitleWrapper: `
        left: 9.5px;
        position: relative;
        display: grid;
        grid-template-columns: 1fr auto 1fr;
      `,
      StyleSubTitle: `font-family: PPFormula-SemiExtendedBold; font-size: 24px;color: #FFFFFF;letter-spacing: 0px;`,
      StyleSubTitleLeft: `
        text-align: right;
      `,
      StyleSubTitleRight: `
        text-align: left;
      `,
      StyledTitle: `font-family: tempelgrotesk-wide; font-size: 120px; bottom: 3px; position: relative; color: #FFFFFF;padding-top:0px;padding-bottom:0px;letter-spacing: 0px;`,
    },
    scheduleV2: {
      StyleRowDetailContainer: `
        height:634px;
        max-height:634px;
        top: 299px;
        background-color: transparent;
        border: 1px solid #FFFFFF;
        display: flex;
        align-items: center;
        justify-content: center;
      `,
      StyleTeamDetailContainer: `
        margin-top:76px;
        display: block;
        margin: 0px;
        text-align: center;
        img {
          width: 247px;
          min-width: 247px;
          height: 247px;
          min-height: 247px;
        }
        p {
          padding-left: 0px;
          padding-right: 0px;
        }
      `,
      StyleTeamDetailContainerLeft: '',
      StyleTeamDetailContainerRight: '',
      StyleScoreDetailContainer: `font-family: PPFormula-SemiExtendedBold; font-size: 36px; color: #FFFFFF;letter-spacing: 0px;width: 160px;`,
      StyleTeam: `font-family: PPFormula-SemiExtendedBold; font-size: 20px;letter-spacing: 0px;`, // to make the names wrap, just change the width to 400px or something.
      cssName: `color: #FFFFFF; font-size: 31px; font-family: PPFormula-SemiExtendedBold; letter-spacing: 0px;margin-top:105px;`,
      speedPanel: 200,
      // background: `https://cdn.dekki.com/assets/broadcast/games/league-of-legends/backgrounds/StandingsV2_BG_MSI2021.png`,
      main: `letter-spacing: 3px; color: black;`,
      css5: `background: #181818;`,
      mainColor: `#FFFFFF`,
      ShowWL: false,
      secondaryColor: "rgba(255,255,255,1)",
      finishedColor: "#f2f2f2",
      cssVersus: `c
        olor: #FFFFFF;
        // padding-top:22px;
        width:160px;
        font-size: 36px; 
        font-family: PPFormula-SemiExtendedBold;
        grid-template-rows: none; 
        h1{
          font-weight: 700;
          font-size: 36px; 
          font-family: PPFormula-SemiExtendedBold;
        }
          `,
      cssScore: `color: #FFFFFF; font-size: 36px; font-family: PPFormula-SemiExtendedBold;width:160px;`,
      scoreOpacityChange: true,
      VSTimeBo: `display:none;`,
      bottom: `background: #D6B598;`,
      StyleScheduleScreenBackground: `background: transparent; border: 1px solid #FFFFFF;height:634px;max-height:634px;top: 299px;padding: 17px;`,
      StyleWrapper: `max-height: none; border-bottom: 1px solid #FFFFFF;width:94%;:last-of-type {
        border-bottom: none;
      };grid-template-columns: 40% 6.5% 7% 6.5% 40%`,
      Bo5x2ScheduleV2Wrap: `border-bottom: 1px solid #FFFFFF;grid-template-columns: 3fr 1fr 1fr 1fr 3fr;width:1340px;
      &:last-of-type {
        border-bottom: 0px solid #f2f2f2;
        margin-top:  16px;
      };padding-bottom: 216px;padding-top: 77px;`,
      StyleLogo: 'height:54px;width:54px;',
      Bo5x2ScheduleV2Logo: 'height:120px;width:120px;max-width:131px;margin-top:18px;',
      Bo5x2ScheduleV2LogoLeft: 'margin-right: 60px;',
      Bo5x2ScheduleV2LogoRight: 'margin-left: 60px;',
      Bo5x2ScheduleV2Name: `font-family: PPFormula-SemiExtendedBold; font-size: 28px;letter-spacing: 0px;margin-right:100px;margin-left:100px;margin-top:3px;width: 90%;`,
      Bo5x2ScheduleV2NameLeft: 'margin-right: 70px;',
      Bo5x2ScheduleV2NameRight: 'margin-left: 70px;',
      Bo5x2ScheduleV2Middle: 'width: 170px;',
      swapSubtitlePositions: true,
      StyleRes: 'font-family: PPFormula-SemiExtendedBold; font-size: 20px;letter-spacing: 0px;',
      Bo5x2ScheduleV2Score: `font-family: PPFormula-SemiExtendedBold; font-size: 24px !important; color: #FFFFFF; letter-spacing: 0px;margin-top:3px;`,
      forceVs: false,
    },
    standingsV2: {

      mainColor: "#D6B598;",
      groupTitle: `font-size: 25px; font-family: IBMPlexMono; font-style: normal;`,
      // standingsResizeOverWrite: ``,
      StyleStandingsContainerQuadrupleResizedOverWrite: 'padding-left: 59px !important;padding-right: 59px !important;',
      FAKEStyleStandingRow:`display: none;`,
      css: `
        color: #f2f2f2; background: #181818; border-bottom: 15px solid #D6B598; font-family: IBMPlexMono; font-size: 25px;
        height:634px;max-height:634px;
        font-weight: normal;
        grid-gap: 0%;
        padding-top: 25px;
        padding-bottom: 25px;
        padding-left: 101px;
        padding-right: 101px;
      `,

      scoreOpacityChange: false,
      showSubtext: false,
      showHeaders: true,
      hideMultiViewTitle: true,
      showPos: true,
      replaceTeamHeaderWithGroupNameInQuadView: true,
      animationThreeAtOnce: true,
      StyleStandingsContainerSingle:`
        padding: 40px 59.5px 40px 59.5px !important;
        li { 
          padding-right: 0px !important;
          padding-left: 0px !important;
        }
      `,
      StyleStandingRow:`height: 54px;padding-bottom:7px;padding-top:5px;border-bottom: 1px solid #f2f2f2;:last-of-type {
        border-bottom: 0px solid #f2f2f2;
      };`,
      StyleStandingRowHeader:`width: 100%;color: #D6B598;font-size: 20px;font-family: IBMPlexMono;padding-bottom:0px;padding-top:0px;border-bottom: 1px solid #f2f2f2;`,
      StyleStandingRowHeaderResize:`width: 100%;color: #D6B598;font-size: 20px;font-family: IBMPlexMono;padding-bottom:0px;padding-top:0px;border-bottom: 1px solid #f2f2f2;`,
      StyleStandingRowHeaderSingle:`font-size: 25px;border-bottom: 1px solid #f2f2f2; width: 100%; height:100%;padding-bottom:21px;padding-top:5px;`,
      StyleStandingRowHeaderSingleResize:`font-size: 25px;color: #D6B598;width: 100%; border-bottom: 1px solid #f2f2f2;height:100%;padding-bottom:21px;padding-top:5px;`,
      StyleStandingRowSingle:`
      border-bottom: 1px solid #f2f2f2; width: 100%; height:100%; padding-bottom:11px;padding-top:5px;
      `,
    //   StyleStandingRowSingleResize:`
    //   border-bottom: 1px solid #f2f2f2; width: 100%; height:100%;padding-bottom:11px;padding-top:5px;
    // `,
        StyleStanding:`
        color: #f2f2f2;font-size: 20px;font-family: IBMPlexMono;
      `,
      StyleStandingSingle:`
        color:font-size: 25px;
      `,
      StyleStandingSingle4RowOverride: 'height:615px;margin-top:-22px;transform: translateY(-22px);',
      StyleStandingRowHeaderSingle4RowOverride: 'align-items: end;',
      StyleTeamPosHeader:`
      font-size: 20px;
      font-family: IBMPlexMono;
      // margin-left: 10px;
      width: 85px;
      margin-bottom:2px;
      `,
      StyleTeamPosHeaderSingle:`
      font-size: 25px;
      font-family: IBMPlexMono;
      margin-left: 37px;
      margin-right: 20px;
      margin-bottom:2px;
      width: 200px;
      `,
      StyleTeamPosSingle:`margin-left: 82px; margin-right: 20px;margin-bottom:15px;margin-top:8px;font-size: 25px;`,
      StyleTeamPos:`width: 70px;margin-bottom:15px;margin-top:8px;font-size: 20px; font-family: IBMPlexMono;`,
      // StyleTeamLogoHeader:`width: 100px;`,
      StyleTeamLogo:`img{height: 35px;width:35px;};margin-left:23px;margin-bottom:4px;`,
      StyleTeamLogoSingle: 'img{height: 50px;width:50px;};height: 50px;margin-bottom:5px;margin-left:48px;',

      StyleTeamNameHeader:`font-size: 20px; font-family: IBMPlexMono;margin-left:-71px;`,
      StyleTeamNameHeaderResize:`margin-left: 9px;`,
      StyleTeamNameHeaderSingle:`margin-left: 27px; font-size: 25px; font-family: IBMPlexMono;margin-bottom:2px;`,
      StyleTeamNameHeaderSingleResize:`font-size: 25px; font-family: IBMPlexMono;margin-bottom:2px;`,

      StyleTeamName:`margin-left: 0px;margin-bottom:15px;margin-top:10px;font-size: 20px; font-family: IBMPlexMono;`,
      StyleTeamNameSingle:`margin-left: 34px;margin-bottom:15px;margin-top:8px;font-size: 25px;`,



      StyleTeamReccordHeader:`font-size: 20px;letter-spacing: 0px; font-family: IBMPlexMono;margin-bottom:2px;margin-right:10px;`,
      StyleTeamReccordHeaderSingle: `font-size: 25px; font-family: IBMPlexMono; margin-right: 99px; letter-spacing: 0px;margin-bottom:2px;margin-right:100px;`,
      StyleTeamReccordHeaderSingleResized: 'margin-right:80px;',

      StyleTeamReccordSingle: `font-size: 25px;  font-family: IBMPlexMono; margin-right: 100px; letter-spacing: 0px;margin-bottom:15px;margin-top:8px;`,
      StyleTeamReccordSingleResized: 'margin-right:80px;',
      StyleTeamReccord: `letter-spacing: 0px; font-size: 20px; font-family: IBMPlexMono; margin-right: 9px;margin-bottom:19px;margin-top:14px;`,

      Spacers: `line-height: 20px;gap:7.95%;:first-of-type {
           transform: translateY(-8px);
        };:last-of-type {
          transform: translateY(-1px);
       };`,
       SpacersResized: 'gap:5.8% !important',
      // Spacers: `transform: translateX(-16px);gap:8.27%;:first-of-type {
      //   transform: translate(-16px,-25px);
      // };`,
    },
    bottomBar: {
      cssBottomBar: 'border-image:linear-gradient(to right,#ffffff99,#1b1b1b,#ffffff99) 10',
      teamLogoColorBG: '#a7a7a766',
      borderColor: 'teamMain',
      borderColorBase: '',
      backgroundColor: '#1b1b1b',
      cssTeamName: 'font-family: IBMPlexMono; color:#ffffff;',
      cssNextGameTitle: 'font-family: IBMPlexMono; font-size: 28px; color: #dba103; margin-top: 15px;',
      cssTimer: 'font-family: IBMPlexMono; font-weight: 800; font-size: 85px; color: #dba103;',
    },
    scheduleInfoSideBar: {
      StyleCenterContainer: `color: #f2f2f2; font-family: Manuka; font-size: 45px;`,
      cssScore: `color: #FFFFFF; font-family: CompactaLTStd-Light;-webkit-font-smoothing: antialiased; font-weight: 300; font-size: 54px; letter-spacing: 0em;`,
      logoSrc: 'https://cdn.dekki.com/uploads/leagues/worlds_2022/worlds_2022_logo.svg',
      cssTimerText: 'color: #D6B598; font-weight: bold; font-family: Nimbus, sans-serif; font-weight: 700;font-size: 20px',
      cssTimer: 'color: #D6B598; div{ font-family: Manuka; font-style: italic; font-weight: 700; font-size: 80px !important; letter-spacing: 2px; padding-left: 5px;}',
      // StyleSidebarSchedule: 'background: linear-gradient(45deg ,#b9babc 0%,#e0e1e3 45%); border-right: 22px solid #D6B598;',
      StyleSidebarSchedule: 'background: none; border-right: 10px solid #FFFFFF;box-shadow:5px -6px 30px -20px black;',
      StyleWeekTitle: `
      color: #FFFFFF; 
      padding: 0; 
      font-size: 24px; 
      font-family: PPFormula-SemiExtendedBold; 
      font-weight: 400;
      // letter-spacing: 25px;
      margin-bottom: 20px;`,
      StyleScheduleList: `
      &::before {
        content: "";
        background: linear-gradient(45deg, rgba(170,170,170,0.3) 0%, rgba(255,255,255,0) 50%, rgba(255,255,255,0.3) 100%);
        width: 428px;
        height: 648px;
        position: absolute;
        top: 0px;
        left: 0px;
        z-index: -1;
      }
      position: relative;
      background: rgba(0,0,0,0.5); 
      border: 2px solid #FFFFFF;
      width: 428px;
      height: 648px;
      li {
        border-bottom: 1px solid #FFFFFF;
      }
      li:last-of-type{
        border-bottom: none;
      }`,
      StyleScheduleRow: 'color: purple; font-family: Manuka; font-size: 24px;',
      StyleLogoImg: 'margin-right: auto; margin-left: auto;',
      StyleScheduleRowBo5: 'font-family: Manuka; font-size: 24px;padding-bottom:20px;margin-top:10px;',
      StyleNextGame: 'color: #FFFFFF; font-family: CompactaLTStd-Light; -webkit-font-smoothing: antialiased;font-weight: 300; font-size: 54px; letter-spacing: 0em;',
      StyleNextGameBo5: 'color: #FFFFFF; font-family: CompactaLTStd-Light; -webkit-font-smoothing: antialiased;font-weight: 300; font-size: 54px; letter-spacing: 0em;',
      cssNext: 'color: #FFFFFF; font-family: CompactaLTStd-Light; -webkit-font-smoothing: antialiased;font-weight: 300; font-size: 54px; letter-spacing: 0em;',
      cssWin: 'color: #FFFFFF; font-family: CompactaLTStd-Light; -webkit-font-smoothing: antialiased;font-weight: 300; font-size: 54px; letter-spacing: 0em;',
      StyleWinTextContainer: 'color: #FFFFFF; font-family: CompactaLTStd-Light; -webkit-font-smoothing: antialiased;font-weight: 300; font-size: 54px; letter-spacing: 0em;',
      StyleGameTime: 'color: #FFFFFF; font-family: CompactaLTStd-Light; -webkit-font-smoothing: antialiased;font-weight: 300; font-size: 54px; letter-spacing: 0em;',
      StyleLeagueLogo: '',

    StyleUpcomingGameInfo: 'top: 0px; margin-left: 30px;',
    StyleResultsGameText: `
      top: 0px; margin-left: 30px; padding-left: 0px;
    `,
    StyleResultsGameTextSingle: `
      top: 0px; margin-left: 30px; padding-left: 0px;font-size: 165px; color: #FFFFFF; font-family: CompactaLTStd-Light;-webkit-font-smoothing: antialiased;letter-spacing: 0px; font-weight: 300;
    `,
    StyleUpcomingGameContainer: 'height: 130px;margin-left:0px;margin-top:34px;padding: 0px;',
    StyleUpcomingGameContainerBo5Finish: 'height: 130px;margin-left:0px;margin-top:34px;padding: 0px;',
    StyleUpcomingGameText:`color: #FFFFFF; font-family: PPFormula-SemiExtendedBold; font-size: 18px;padding-left: 0px; margin-bottom: 15px;letter-spacing: 0px;`,
    StyleUpcomingGameTextOverwrite:`color: #FFFFFF; font-family: PPFormula-SemiExtendedBold; font-size: 18px;padding-left: 0px; margin-bottom: 15px;letter-spacing: 0px;`,
    StyleTimer: 'font-size: 165px; color: #FFFFFF; font-family: CompactaLTStd-Light;-webkit-font-smoothing: antialiased;letter-spacing: 0px; font-weight: 300;',
    timerOverwriteText: 'font-size: 165px; color: #FFFFFF; font-family: CompactaLTStd-Light;letter-spacing: 0px; font-weight: 300;',
    lessThanOneHourStyle: `165px; color: #FFFFFF; font-family: CompactaLTStd-Light;-webkit-font-smoothing: antialiased;letter-spacing: 0px; font-weight: 300;`,
    moreThanOneHourStyle:`165px; color: #FFFFFF; font-family: CompactaLTStd-Light;-webkit-font-smoothing: antialiased;letter-spacing: 0px; font-weight: 300;`,

    StyleBof5ScoreSingleMatch: 'color: #FFFFFF; font-weight: 300; font-family: CompactaLTStd-Light;-webkit-font-smoothing: antialiased;font-size: 110px;',
    StyleBof5GridSingleMatch: 'margin-top:9px;',
    StyleBof5Grid: 'margin-left:12px;margin-right:12px;padding-left:0px;padding-right:0px;',


    StyleBof5MatchName: 'color: #FFFFFF; font-family: PPFormula-SemiExtendedBold;font-size: 20px;letter-spacing: 0px;margin-top: 0px',
    StyleBof5MatchContainer: `padding-top:9px;margin-bottom:15px;:first-of-type {
      border-bottom: 1px solid white;
    }`,
    StyleBof5Logo: 'width: 130px;height: 130px;',
    StyleBof5Score: 'color: #FFFFFF; font-family: CompactaLTStd-Light;-webkit-font-smoothing: antialiased;font-size: 76px;opacity:1; font-weight: 300;',
    StyleBof5ScoreNotLive: 'color: #FFFFFF; opacity: 0.3; font-family: CompactaLTStd-Light;-webkit-font-smoothing: antialiased;font-size: 76px; font-weight: 300;',
    StyleBof3MatchImage: `
      width: 80px;
      height: 80px;
    `,
    StyleBof3Score: `
      letter-spacing: 10px; margin-right: -10px;
    `,


      cssLogoLoss: '0.5;',
    },
    telopsV2: {
      cssTelops: `
        border-right: 15px solid #D6B598;
        background: #D6B598;
        border-left: unset;
        div:first-of-type{
            background: linear-gradient(60deg, #000220 0%, #001642 100%);
            border: unset;
            left: 0;
            padding-top:0;
        }`,
      cssTwitter: 'p{font-family: proxima-nova, sans-serif; font-weight: 700; color: #D6B598; font-size: 20px;} margin-top: -2px;',
      cssName: 'top: 0; font-family: dharma-gothic-e, sans-serif; font-weight: 700; color: white; font-size: 60px; line-height: 57px;',
    },
    gameBreakdown: {
      backgroundImage: '',
      // backgroundImage: 'https://cdn.dekki.com/uploads/leagues/ljl_2019_summer/gameBreakdownBG.png',
      disableSliderBG: true,
      teamStats: {
        teamStatsBGColor: '#000220',
        teamStatsBGTexture: 'none',
        showTeamBackgroundImageStatsContents: false,
        teamStatsHeaderBGColor: '#000220',
        cssTeamName: { color: '#D6B598', fontFamily: 'proxima-nova, sans-serif', fontSize: '45px', fontWeight: '700', display: 'flex', justifyContent: 'center', alignItems: 'center' },
        cssTeamNameLeft: 'color: #D6B598;',
        cssTeamNameRight: 'color: #a070bb;',
        item: { color: '#ffffff', fontFamily: 'proxima-nova, sans-serif', fontWeight: '300', fontSize: '28px' },
        cssTeamStats: "font-family: 'proxima-nova, sans-serif'; font-weight: 300; ",
        cssWinTag: "font-family: proxima-nova, sans-serif; font-weight: 700;",
        cssLossTag: "background-color: rgba(255, 255, 255, 0.6); font-family: proxima-nova, sans-serif; font-weight: 700;",
        cssBansPicks: { color: '#ffffff', fontSize: '30px', fontFamily: 'proxima-nova, sans-serif', fontWeight: '300' },
        dragons: { backgroundColor: 'unset', border: '4px solid transparent' },
      },
      key: {
        gameTime: { fontFamily: 'dharma-gothic-e, sans-serif', fontWeight: '700', fontSize: '70px', letterSpacing: '2px', marginBottom: '0px' },
        gameTimeHeader: { marginBottom: '0', fontSize: '20px', color: '#ffffff' },
        cssHeader: { fontFamily: 'proxima-nova, sans-serif' },
        cssTitles: { fontFamily: 'proxima-nova, sans-serif', fontWeight: '300', color: '#ffffff', fontSize: '26px' },
        content: { backgroundColor: '#000220B3', backgroundImage: 'unset' },
      },
      graph: {
        cssHeader: { fontFamily: 'proxima-nova, sans-serif', backgroundColor: '#000220', backgroundImage: 'unset', fontWeight: '400', letterSpacing: '2px', fontSize: '32px' },
        cssContent: { fontFamily: 'proxima-nova, sans-serif', color: '#141720', backgroundImage: 'unset', fontWeight: '300', backgroundColor: '#000220', borderBottom: 'none' },
        teamLeftColor: '#D6B598', // blue initially
        teamRightColor: '#a070bb', // red initially
        cssKey: {
          borderBottom: 'none',
          fontFamily: 'proxima-nova, sans-serif', fontWeight: '700', fontSize: '26px',
          backgroundImage: 'unset', backgroundColor: '#000220B3',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          paddingTop: '35px',
        },
        damageCountBackground: {
          ':before': {
            backgroundColor: 'unset',
          },
        },
        indexesColor: "#ffffff",
      },
    },
    schedule: {
      speedPanel: 200,

      cssRow: 'font-family: futura-pt, "sans-serif"; color:#141720; overflow: visible; border-right: 10px #ffffff solid; max-height: 120px;',
      cssRowNext: 'color:#141720; background: linear-gradient(0deg, #15ace2 0%,  #D6B598 100%); border-left: unset; max-height: 120px;',
      cssRowFinished: '',

      cssDate: 'background-color: #000220; color: #ffffff; border-right: 10px #ffffff solid; font-family: dharma-gothic-e, sans-serif; font-weight: 700; font-size: 35px;',
      cssDateFinished: 'background-color: #000220; color: #ffffff;',
      cssDateNext: 'background-color: #000220; color: #D6B598;',

      cssLogo: 'background-color: #000220; margin-right: 0; border-right: unset; border-left: unset;',
      cssLogoNext: 'background-color: #000220;',
      cssLogoLoser: 'background-color: #000220; ',
      cssLogoWinner: 'background-color: #000220;',

      cssName: 'background-color: #000220; color: #ffffff; font-size: 28px; letter-spacing: 1px; font-weight:700; font-family: proxima-nova, sans-serif;                ',
      cssNameNext: 'background: linear-gradient(0deg, #15ace2 0%,  #D6B598 100%); color:#000220;',
      cssNameLoser: 'color:#ffffff4D; img{opacity: 0.3;}',
      cssNameWinner: '',

      cssVersus: 'font-size: 60px; background-color: #000220; color:#D6B598; margin-right: 0; margin-left: 0; font-weight:700; font-family: dharma-gothic-e, sans-serif;',
      cssVersusNext: 'background: linear-gradient(0deg, #15ace2 0%,  #D6B598 100%); color:#000220; margin-right: 0; margin-left: 0;',

      cssScore: 'color:#D6B598; background-color: #000220; font-size: 60px; font-weight:700; font-family: dharma-gothic-e, sans-serif;',
      cssScoreLoser: 'color:#D6B5984d; font-size: 60px; ',
      cssScoreWinner: 'font-size: 60px; ',

      trimColorNext: 'transparent',
      rightBorderColor: '#ffffff;',
      rightBorderColorLoser: '',
      cssBorders: `
            border: none;
            :before {
                content: '';
                width: 13px;
                height: 100%;
                left: -13px;
                position: absolute;
                background: linear-gradient(0deg, #D6B598 0%, #15ace2 100%);
            }`,
    },
    screenTitle: {
      textColor: '#ffffff',
      cssTitle: "font-family: dharma-gothic-e, sans-serif; font-weight: 700; text-shadow: none; font-size: 130px; margin: auto; letter-spacing: 2px; ",
      textShadowLeft: '',
      textShadowRight: '',
    },
    standings: {
      showSubtext: false,
      displayWinLossSingleColumn: true,
      hideHeader: true,
      cssCell: '',
      cssRankCell: '',
      cssSubtextCell: '',
      cssLogoCell: '',
      cssNameCell: '',
      cssScoreCell: '',
      cssHeaderCell: '',
    },
    timer2: {
      cssTimerContainer: `
        background-color: #DDF2F5; 
        box-shadow: none; 
        width: 230px;
        height: 120px;
      `,
      cssTimerText: `
        color: #034051;
        font-family: bebas-neue-pro, sans-serif;
        font-weight: 400;
        font-size: 18px;
        padding: 15px 20px 0 20px;
      `,
      cssTime: `
        color: #034051;
        font-family: bebas-neue-pro, sans-serif;
        font-weight: 600; 
        font-size: 70px; 
        padding: 0 20px 5px 20px;
      `,
      cssBorderBottom: "background:#034051;",
    },
  },
};
