const XPLevels = [
    0,      // Level 1
    280,    // Level 2
    660,    // Level 3
    1140,   // Level 4
    1720,   // Level 5
    2400,   // Level 6
    3180,   // Level 7
    4060,   // Level 8
    5040,   // Level 9
    6120,   // Level 10
    7300,   // Level 11
    8580,   // Level 12
    9960,   // Level 13
    11440,  // Level 14
    13020,  // Level 15
    14700,  // Level 16
    16480,  // Level 17
    18360,   // Level 18
]

export const getXPLevelProgression = (CurrentXP, level) =>{
        if(!level) return 0;
        const minLevel = XPLevels[level-1];
        const maxLevel = XPLevels[level];
        const range = maxLevel - minLevel;
        const levelScore =  CurrentXP - minLevel;
        return Math.round((levelScore *100)/range) || 0;

}