// REACT
import React, { Component } from 'react';
// INTERNAL
import { subscribe } from '../../../services/api';
import ControlContainer from '../../Controller/ControlContainer';
import Button from '../../Button';
import { i18n } from '../../i18n';
// import { firebaseRealtimeDbSetValue } from '../../../util/firebase';
import { updateScreenStatus } from '../../../util/controllerUtils';
import PropTypes from 'prop-types';

class WaitingScreenControl extends Component {

    state = {
        status: 'close',
        // displayingScreen: '',
    }
    static propTypes = {
        cgpc: PropTypes.string,
        display: PropTypes.bool,
        screen1: PropTypes.bool,
      };

    componentDidMount() {
        this.runSubscription();
    }

    runSubscription = () => {
        subscribe('control-waitingScreen', null, data => {
            if (data.target === 'control-waitingScreen') {
                this.setState({
                    status: data.status,
                });
            }
        }, this.props.cgpc);
    };

    getSendData = () => {
        return {
            selectedRound: window.MainData.schedule.rounds[window.MainData.schedule.round].name,
            selectedMatch: window.MainData.schedule.rounds[window.MainData.schedule.round].matches[window.MainData.schedule.match || 0].name || '',
            selectedGame: window.MainData.schedule.game,
            currentRound: window.MainData.schedule.round,
            bestOf: window.MainData.schedule.rounds[window.MainData.schedule.round].matches[window.MainData.schedule.match].bestOf,
            messageName: window.MainData.waitingScreen && window.MainData.waitingScreen.message,
        };
    }

    onPlay = () => {
        const sendData = this.getSendData();
        updateScreenStatus('waitingScreen', 'open', sendData, null, this.props.cgpc);
        this.setState({status: 'open'});
    }

    onClose = () => {
        this.setState({status: 'close'});
        updateScreenStatus('waitingScreen', 'close', null, null, this.props.cgpc);
    }

    render() {
        return (
            <ControlContainer style={{display: this.props.display ? 'none': 'block'}} className="control--starters" status={this.state.status}>
                <div className="control__panel">
                    <h2 className="control__title">
                        <i className="fas fa-clock"></i> {i18n().pages.waitingScreen}
                    </h2>
                    <Button
                    controllerSide={!this.props.screen1}
                        action={this.onPlay}
                        text={`${i18n().general.open}`}
                        icon="fas fa-play-circle"
                        disabled={
                            (this.state.status === 'open')
                            }
                    />
                    <Button
                    controllerSide={!this.props.screen1}
                        action={this.onClose}
                        text={i18n().general.close}
                        icon="fas fa-stop-circle"
                        disabled={this.state.status === 'close'}
                    />
                </div>
            </ControlContainer>
        )
    }
}

export default WaitingScreenControl;
