import React, { useState, useEffect } from 'react';
import { get } from 'lodash';
// INTERNAL
import { updateScreenStatus } from '../../../util/controllerUtils';
import { subscribe } from '../../../services/api';
import { i18n } from '../../i18n';
import ControlContainer from '../../Controller/ControlContainer';
import Button from '../../Button';
import moment from 'moment';
import PropTypes from 'prop-types';
// Copy from funcitonal components!

const ScheduleV2 = (props) => {
  const [isPlayState, setIsPlayState] = useState('close');


  const onPlay = () => {
    setIsPlayState('open');
  };

  const onClose = async () => {
    setIsPlayState('close');
    await updateScreenStatus('scheduleV2', 'close', null, null, props.cgpc);
  };

 const getScheduledMatchesToSend = (schedule) => {
    if (!schedule) {
        schedule = {};
    }

    const roundId = Number(schedule.round || 0);
    if (!schedule.rounds[roundId]) return null;
    return schedule.rounds[roundId].matches.map(match => {
        const time = match.datetime.split('T')[1];
        const gameWinners = match.games && match.games.filter(game => game.winner);
        // console.log('match', match);

        let team100Score;
        let team200Score;
        if(match.team100){
            team100Score = gameWinners && gameWinners.filter(game => game.winner === match.team100.tricode).length;
            team200Score = gameWinners && gameWinners.filter(game => game.winner === match.team200.tricode).length;
        }

        const resp = {
            date: {
                time: time,
            },
            team100: match.team100 && {
                name: match.team100.name,
                tricode: match.team100.tricode,
                logo: (match.team100.images && match.team100.images.logoFullColor),
                score: match.bestOf > 1 && (team100Score > 0 || team200Score > 0) ? team100Score : undefined,
                color: match.team100.color,
                images: (match.team100.images && match.team100.images),
            },
            team200: match.team200 && {
                name: match.team200.name,
                tricode: match.team200.tricode,
                logo: (match.team200.images && match.team200.images.logoFullColor),
                score: match.bestOf > 1 && (team100Score > 0 || team200Score > 0) ? team200Score : undefined,
                color: match.team200.color,
                images: (match.team200.images && match.team200.images),
            },
            hide: match.hide,
            skip: match.skip,
            bestOf: match.bestOf,
            games: match.games || [],
        }
        if (team100Score > match.bestOf / 2) {
            resp.winner = match.team100.tricode;
        } else if (team200Score > match.bestOf / 2) {
            resp.winner = match.team200.tricode;
        } else {
            resp.winner = '';
        }
        return resp;
    });
}


  const getSendData = () => {
    const schedule = window.MainData.schedule;
    const scheduledMatches = getScheduledMatchesToSend(schedule);
    const roundId = Number(schedule.round || 0);
    const sendData = {
        scheduledMatches,
        theme: '',
        next: false,
        matchCount: schedule.rounds[roundId] && schedule.rounds[roundId].matches && schedule.rounds[roundId].matches.length,
        roundName: get(window, `MainData.schedule.rounds[${window.MainData.schedule.round}].name`,''),
        splitName: get(window, `MainData.schedule.splitName`,''),
    }
    const firstMatch = schedule.rounds[roundId] && schedule.rounds[roundId].matches && schedule.rounds[roundId].matches[0];
    if (firstMatch) {
        sendData.day = moment(firstMatch.datetime).format('MMM Do');
    } else {
        sendData.day = '';
    }

    if (scheduledMatches) {
        const preloadImages = [];
        scheduledMatches.forEach(match => {
            if (match.team100 && match.team100.logo) {
                preloadImages.push(match.team100.logo);
            }
            if (match.team200 && match.team200.logo) {
                preloadImages.push(match.team200.logo);
            }
        });
        sendData.preloadImages = preloadImages;
    }

    return sendData;
}

  useEffect(() => {
    subscribe('control-scheduleV2', null, response => {
      (response.status !== isPlayState) && setIsPlayState(response.status);
    }, props.cgpc);
  }, []);

  useEffect( ()=>{
    if(isPlayState === 'open'){
      updateScreenStatus('scheduleV2', 'open', getSendData(), null, props.cgpc);
    }
  }, [isPlayState])

  return (
    <ControlContainer style={{display: props.display ? 'none': 'block', overflow: 'visible'}} status={isPlayState} >
      <div className="control__panel">
        <h2 className="control__title">
          <i className="fas fa-history"></i> {i18n().pages.ScheduleV2}
        </h2>
        <Button
        controllerSide={!props.screen1}
          action={onPlay}
          text={i18n().general.open}
          icon="fas fa-play-circle"
          disabled={isPlayState === 'open'}
        />
        <Button
        controllerSide={!props.screen1}
          action={onClose}
          text={i18n().general.close}
          icon="fas fa-stop-circle"
          disabled={!(isPlayState === 'open')}
        />
      </div>
    </ControlContainer>
  );
};

ScheduleV2.propTypes = {
  cgpc: PropTypes.string,
  display: PropTypes.bool,
  screen1: PropTypes.bool,
};

export default ScheduleV2;
