// REACT
import React, { Component } from 'react';
import { get } from 'lodash';

// INTERNAL
import { /* send, */ subscribe } from '../../../services/api';
import ControlContainer from '../../Controller/ControlContainer';
import Button from '../../Button';
import { i18n } from '../../i18n';
import { updateScreenStatus } from '../../../util/controllerUtils';
import PropTypes from 'prop-types';
class CastersPredictionsControl extends Component {

    static propTypes = {
        cgpc: PropTypes.string,
        display: PropTypes.bool,
        screen1: PropTypes.bool,
      };
    state = {
        status: 'ready',
        indexPrediction: 0,
        castersPredictions: [],
    }

    componentDidMount() {
        this.runSubscription();
    }

    runSubscription = () => {
        subscribe('control-castersPredictions', null, data => {
            if (data.target === 'control-castersPredictions') {
                const resultState = {
                    ...data,
                    ...data.data,
                };
                delete resultState.data;
                this.setState(resultState);
            }
        }, this.props.cgpc);
    };

    getSubtitle = () =>{
        const match = get(window, `MainData.schedule.rounds[${get(window, `MainData.schedule.round`,0)}].matches[${get(window, `MainData.schedule.match`,0)}]`);
        let subtitle = `${match.team100.tricode} VS ${match.team200.tricode}`;
        if(get(match, 'games',[]).length > 1){
            subtitle = `GAME ${get(window, `MainData.schedule.game`,0) +1} ${subtitle}`
        }
        return subtitle;
    }

    onPlay = () => {
        const dataCasterPrediction = get(window, 'MainData.castersPredictions',[]);
        if (dataCasterPrediction.length) {
            this.setState({
                castersPredictions: dataCasterPrediction,
            },()=>{
                updateScreenStatus('castersPredictions', 'open', {
                    castersPredictions: this.state.castersPredictions,
                    indexPrediction: this.state.indexPrediction,
                    subtitle: this.getSubtitle(),
                }, null, this.props.cgpc);
            });
        } else {
            this.setState({ status: 'invalid' });
        }
    }

    onUpdate = (toUpdate, v) => {
        this.setState({[toUpdate]: v},
        ()=>{
            const sendData = {
                castersPredictions: this.state.castersPredictions,
                indexPrediction: this.state.indexPrediction,
                subtitle: this.getSubtitle(),
             };
             updateScreenStatus('castersPredictions', 'open', sendData, null, this.props.cgpc);
        })

    }

    onClose = () => {
        updateScreenStatus('castersPredictions', 'ready', null, null, this.props.cgpc);
        this.setState({
            indexPrediction: 0,
            castersPredictions: [],
        });
    }

    render() {
        return (
            <ControlContainer style={{display: this.props.display ? 'none': 'block'}} className="control--banpick" status={this.state.status}>
                <div className="control__panel">
                    <h2 className="control__title">
                        <i className="far fa-users-class"></i> {i18n().pages.castersPrediction}
                    </h2>
                    <Button controllerSide={!this.props.screen1} action={this.onPlay} text={i18n().general.open} icon="fas fa-play-circle" disabled={
                        (this.state.status === 'open' || this.state.status === 'opening') && true}
                    />
                    <Button controllerSide={!this.props.screen1} action={this.onClose} text={i18n().general.close} icon="fas fa-stop-circle" disabled={
                        !(this.state.status === 'open' || this.state.status === 'opening') && true}
                    />
                </div>
                <div className="control-extra">
                    <div className="control-extra__fieldset">
                        <div className="pill">
                            <Button
                                disabled={this.state.status!== 'open' || (this.state.indexPrediction) >= this.state.castersPredictions.length}
                                text={`Next Prediction`}
                                action={() => this.onUpdate('indexPrediction', this.state.indexPrediction +1)}
                            />,
                        </div>
                    </div>
                </div>
            </ControlContainer>
        )
    }
}

export default CastersPredictionsControl
