// REACT
import React from 'react';
import PropTypes from 'prop-types';
import {i18n} from '../../../i18n';
import {loadImageUrlChampSquare} from '../../../../util/loadImageUrl';

const commonStyle = {
  marginTop: '5px',
};
const team100Style = {
  color: 'red',
};
const team200Style = {
  color: 'blue',
};
const teamStyles = {
  team100: team100Style,
  team200: team200Style,
};

const getPickComponent = (i, pick) => {
  const pickStyle = {
    display: 'inline-block',
    width:'50px',
    height:'50px',
    backgroundSize: '100%',
    backgroundImage: 'url(' + loadImageUrlChampSquare(pick) + ')',
  };
  return (
    <div key={i} className={`champion`} style={pickStyle}></div>
  );
};

const getBanComponent = (i, ban) => {
  const banStyle = {
    display: 'inline-block',
    width:'50px',
    height:'50px',
    backgroundSize: '100%',
    backgroundImage: 'url(' + loadImageUrlChampSquare(ban) + ')',
  };
  return (
    <div key={i} className={`banned-hero`} style={banStyle}></div>
  );
};

const GamePreview = props => {
  const team100Champs = props.picks.length ? props.picks.slice(0,5) : [];
  const team200Champs = props.picks.length ? props.picks.slice(5) : [];
  const bans = props.bans.length ? props.bans : [];
  const team100SummonerNames = props.summonerNames.length  ? props.summonerNames.slice(0,5).join(', ') : '';
  const team200SummonerNames = props.summonerNames.length  ? props.summonerNames.slice(5).join(', ') : '';
  return (
  <div className='gamepreview'>
    <div className="gamepreview-createdat" style={commonStyle}>
      <label>{i18n().general.gamePlayedAt}:</label> {props.gameCreatedAt}
    </div>
    <div className="gamepreview-winner" style={commonStyle}>
      <label>{i18n().general.winner}:</label>
      <span style={teamStyles[props.winner]}>
        {i18n().general[props.winner]}
      </span>
    </div>
    <div className="gamepreview-summonernames">
      <div className="gamepreview-summonernames-team100" style={commonStyle}>
        <label style={team100Style}>{i18n().general.redSide}:</label>
        {team100SummonerNames}
      </div>
      <div className="gamepreview-summonernames-team200" style={commonStyle}>
      <label style={team200Style}>{i18n().general.blueSide}:</label>
        {team200SummonerNames}
      </div>
    </div>
    <div className="gamepreview-picks" style={commonStyle}>
      <label>{i18n().general.picks}:</label>
        {team100Champs.map((pick, i) => getPickComponent(i, pick))}
        <b> vs </b>
        {team200Champs.map((pick, i) => getPickComponent(i, pick))}
    </div>
    <div className="gamepreview-bans" style={commonStyle}>
      <label>{i18n().general.bans}:</label>
        {bans.map((ban, i) => getBanComponent(i, ban))}
    </div>
  </div>
)};

GamePreview.propTypes = {
  bans: PropTypes.arrayOf(PropTypes.string).isRequired,
  picks: PropTypes.arrayOf(PropTypes.string).isRequired,
  summonerNames: PropTypes.arrayOf(PropTypes.string).isRequired,
  winner: PropTypes.string.isRequired,
  gameCreatedAt: PropTypes.string.isRequired,
};

export default GamePreview