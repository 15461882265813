// REACT
import React, { Component } from 'react';
import PropTypes from 'prop-types';
// 3RD PARTY
import { delay } from 'lodash';
// ME
import animationService from '../../services/animationService';
import Caster from './Caster';
import Interviewee from './Interviewee';
import Interviewer from './Interviewer';
import Twitter from './Twitter';

// ELEMENTS
let container;
let casterTelop;
let casterTelopText;
let interviewerTelop;
let interviewerTelopText;
let stats;
let twitter;
let twitterText;
let twitterBrush;


// ANIMATION HANDLERS
let casterTelopAnimationHandler;
let casterTelopTextAnimationHandler;
let interviewerTelopAnimationHandler;
let interviewerTelopTextAnimationHandler;
let statsAnimationHandler;
let twitterAnimationHandler;
let twitterTextAnimationHandler;
// let twitterBrushAnimationHandler;

// CLEANUP HANDLERS
let casterTelopCleanupHandler;
let casterTelopTextCleanupHandler;
let interviewerTelopCleanupHandler;
let interviewerTelopTextCleanupHandler;
let statsCleanupHandler;
// let twitterCleanupHandler;
let twitterTextCleanupHandler;
let twitterBrushCleanupHandler;

class Telops extends Component {

    static propTypes = {
        data: PropTypes.any,
        statusCallback: PropTypes.func,
    }

    state = {
        tweetIndex: 0,
        nextIndex: 0,
        status: 'ready',
        twitterVisible: false,
        statsVisible: false,
    };

    bound = false;

    componentDidMount() {
        this.checkProps(this.props);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        console.log('receiving props');
        if (this.props !== nextProps) {
            if (this.bound) {
                this.doAction(nextProps);
            }
        }
    }

    checkProps = async props => {
        await this.getElements();
        await this.setAnimationHandlers();
        await this.setCleanupHandlers();
        if (!this.bound) await this.bindAnimationHandlers();
        this.doAction(props);
    };

    doAction = props => {
        if (props.active) {
            if (this.state.status !== 'open') {
                this.props.statusCallback('telops', 'open');
                this.setState({status: 'open'}, () => this.runUpdate(props));
            } else {
                this.runUpdate(props);
            }
        } else {
            if (this.state.status === 'open') {
                this.props.statusCallback('telops', 'closed');
            }
        }
        if (props.data.tweetIndex && props.data.tweetIndex !== this.state.tweetIndex) { // TODO: update this or it will keep spamming the trigger on each update.
            this.nextTweet(props.data.tweetIndex);
        }
    }

    telopsVisible = false;

    runUpdate = props => {
        if (props.data) {
            const telopsVisible = props.data.telopsVisible;
            if (props.data.telops.leftTitle === 'interviewer') {
                if (props.data.telops.rightTitle === 'player') {
                    if (telopsVisible && !this.telopsVisible) {
                        this.telopsVisible = true;
                        this.showInterviewer();
                        console.log('show interviewer');
                    } else if (!telopsVisible && this.telopsVisible) {
                        this.telopsVisible = false;
                        console.log('hiding interviewer');
                        this.hideInterviewer();
                    }
                } else {
                    if (telopsVisible && !this.telopsVisible) {
                        this.telopsVisible = true;
                        this.showInterviewerOnly();
                        console.log('show interviewer only');
                    } else if (!telopsVisible && this.telopsVisible) {
                        this.telopsVisible = false;
                        this.hideInterviewer();
                    }
                }
            } else {
                if (telopsVisible && !this.telopsVisible) {
                    this.telopsVisible = true;
                    this.showCasters();
                    console.log('show casters');
                } else if (!telopsVisible && this.telopsVisible) {
                    this.telopsVisible = false;
                    this.hideCasters()
                }
            }

            if (props.data.statsVisible && !this.state.statsVisible) {
                console.log('show stats');
                this.setState({ statsVisible: true }, () => this.showStats());
            } else if (!props.data.statsVisible && this.state.statsVisible) {
                this.setState({ statsVisible: false }, () => this.hideStats());
            }

            if (props.data.twitterVisible && !this.state.twitterVisible) {
                console.log('show twitter');
                this.setState({ twitterVisible: true, tweetIndex: 0 }, () => this.showTwitter());
            } else if (!props.data.twitterVisible && this.state.twitterVisible) {
                this.setState({ twitterVisible: false }, () => this.clearTwitter());
            }
        }
    }

    getElements = () => {
        container = document.querySelector('.view-telops');
        casterTelop = document.querySelector('.view-telops .telops__caster');
        casterTelopText = document.querySelector('.view-telops .telops__caster__content__text');
        interviewerTelop = document.querySelector('.view-telops .telops__interviewer');
        interviewerTelopText = document.querySelector('.view-telops .telops__interviewer__content__text');
        stats = document.querySelector('.view-telops .telops__interviewee__stats');
        twitter = document.querySelector('.view-telops .telops__twitter');
        twitterText = document.querySelector('.view-telops .telops__twitter__text');
        twitterBrush = document.querySelector('.view-telops .telops__twitter__brush');
    };

    // CASTER HANDLERS --------------------------------------------------------------------------
    setCasterAnimationHandlers = () => {
        casterTelopAnimationHandler = () => {
            animationService.addClass(container, 'telops--casters');
        };

        casterTelopTextAnimationHandler = () => {
            animationService.removeAnimationTrigger(casterTelop, casterTelopAnimationHandler);
            animationService.removeAnimationTrigger(casterTelopText, casterTelopTextAnimationHandler);
        };
    };

    setCasterCleanupHandlers = () => {
        casterTelopCleanupHandler = () => {
            animationService.removeAnimationTrigger(casterTelop, casterTelopAnimationHandler);
        };

        casterTelopTextCleanupHandler = () => {
            animationService.removeClass(container, 'telops--show-casters');
        };
    };

    bindCasterAnimationHandlers = () => {
        animationService.createAnimationTrigger(casterTelop, casterTelopAnimationHandler);
        animationService.createAnimationTrigger(casterTelopText, casterTelopTextAnimationHandler);
    };

    bindCasterCleanupHandlers = () => {
        animationService.createAnimationTrigger(casterTelop, casterTelopCleanupHandler);
        animationService.createAnimationTrigger(casterTelopText, casterTelopTextCleanupHandler);
    };

    showCasters = () => {
        animationService.removeAnimationTrigger(casterTelop, casterTelopCleanupHandler);
        animationService.removeAnimationTrigger(casterTelopText, casterTelopTextCleanupHandler);
        this.bindCasterAnimationHandlers();
        animationService.addClass(container, 'telops--show-casters');
    };

    hideCasters = () => {
        this.bindCasterCleanupHandlers();
        animationService.removeClass(container, 'telops--casters');
    };
    // END CASTERS ------------------------------------------------------------------------------

    // INTERVIEWER HANDLERS ---------------------------------------------------------------------
    setInterviewerAnimationHandlers = () => {
        interviewerTelopAnimationHandler = () => {
            animationService.addClass(container, 'telops--interviewer');
        };

        interviewerTelopTextAnimationHandler = () => {
            animationService.removeAnimationTrigger(interviewerTelop, interviewerTelopAnimationHandler);
            animationService.removeAnimationTrigger(interviewerTelopText, interviewerTelopTextAnimationHandler);
        };

        statsAnimationHandler = () => {
            animationService.addClass(container, 'telops--stats');
        };
    };
    setInterviewerCleanupHandlers = () => {
        interviewerTelopTextCleanupHandler = () => {
            animationService.removeClass(container, 'telops--show-interviewer');
            animationService.removeClass(container, 'telops--show-interviewer-only');
        };

        statsCleanupHandler = () => {
            animationService.removeClass(container, 'telops--interviewee-stats');
        };
    };

    bindInterviewerAnimationHandlers = () => {
        animationService.createAnimationTrigger(interviewerTelop, interviewerTelopAnimationHandler);
        animationService.createAnimationTrigger(interviewerTelopText, interviewerTelopTextAnimationHandler);
        animationService.createAnimationTrigger(stats, statsAnimationHandler);
    };

    bindInterviewerCleanupHandlers = () => {
        animationService.createAnimationTrigger(interviewerTelopText, interviewerTelopTextCleanupHandler);
    };

    showInterviewer = () => {
        animationService.removeAnimationTrigger(interviewerTelop, interviewerTelopCleanupHandler);
        animationService.removeAnimationTrigger(interviewerTelopText, interviewerTelopTextCleanupHandler);
        this.bindInterviewerAnimationHandlers();
        animationService.addClass(container, 'telops--show-interviewer');
    };

    showInterviewerOnly = () => {
        animationService.removeAnimationTrigger(interviewerTelop, interviewerTelopCleanupHandler);
        animationService.removeAnimationTrigger(interviewerTelopText, interviewerTelopTextCleanupHandler);
        this.bindInterviewerAnimationHandlers();
        animationService.addClass(container, 'telops--show-interviewer-only');
    };

    hideInterviewer = () => {
        this.bindInterviewerCleanupHandlers();
        animationService.removeClass(container, 'telops--interviewer');
    };

    showStats = () => {
        animationService.removeAnimationTrigger(stats, statsCleanupHandler);
        animationService.createAnimationTrigger(stats, statsAnimationHandler);
        animationService.addClass(container, 'telops--interviewee-stats');
    };

    hideStats = () => {
        animationService.removeAnimationTrigger(stats, statsAnimationHandler);
        animationService.createAnimationTrigger(stats, statsCleanupHandler);
        animationService.removeClass(container, 'telops--stats');
    };
    // END INTERVIEWER -------------------------------------------------------------------------------

    // TWITTER HANDLERS ------------------------------------------------------------------------------
    setTwitterAnimationHandlers = () => {
        twitterAnimationHandler = () => {
            animationService.addClass(container, 'telops--twitter');
        };

        twitterTextAnimationHandler = () => {
            this.setState({ tweetIndex: this.state.nextIndex }, this.regenTwitter);
        };
    };

    bindTwitterAnimationHandlers = () => {
        animationService.createAnimationTrigger(twitter, twitterAnimationHandler);
    };

    setTwitterCleanupHandlers = () => {
        twitterTextCleanupHandler = () => {
            animationService.removeClass(container, 'telops--twitter');
        };

        twitterBrushCleanupHandler = () => {
            animationService.removeClass(container, 'telops--show-twitter');
        };
    };

    showTwitter = () => {
        animationService.removeAnimationTrigger(twitterText, twitterTextCleanupHandler);
        animationService.removeAnimationTrigger(twitterBrush, twitterBrushCleanupHandler);
        animationService.createAnimationTrigger(twitter, twitterAnimationHandler);
        animationService.removeClass(twitterText, 'telops__twitter__text--close-tweet');
        animationService.addClass(container, 'telops--show-twitter');
        this.regenTwitter();
        console.log('tweetlist', this.tweetList());
    };

    nextTweet = (tweetIndex) => {
        let nextIndex = tweetIndex;
        if (nextIndex >= this.tweetList().length) {
            this.setState({ nextIndex, tweetIndex: 0 }, () => {
                this.clearTwitter();
            });
            return;
        }
        this.setState({nextIndex});
        animationService.createAnimationTrigger(twitterText, twitterTextAnimationHandler);
        animationService.addClass(twitterText, 'telops__twitter__text--close-tweet');
    };

    clearTwitter = () => {
        animationService.removeAnimationTrigger(twitter, twitterAnimationHandler);
        animationService.removeAnimationTrigger(twitterText, twitterTextAnimationHandler);
        animationService.createAnimationTrigger(twitterText, twitterTextCleanupHandler);
        animationService.createAnimationTrigger(twitterBrush, twitterBrushCleanupHandler);
        animationService.addClass(twitterText, 'telops__twitter__text--close-tweet');
    };

    regenTwitter = () => {
        const tweetList = this.tweetList();
        if (tweetList[this.state.tweetIndex]) {
            twitter.innerHTML = `<div class="telops__twitter__blue"></div>
        <div class="animated animated--fast telops__twitter__brush"></div>
        <i class="fab fa-twitter" aria-hidden="true"></i>
        <div class="animated animated--fast telops__twitter__text telops__twitter__text--closed">
          <span class="telops__twitter__text__name">@${tweetList[this.state.tweetIndex].name}</span>
          <span class="telops__twitter__text__tweet">${tweetList[this.state.tweetIndex].text}</span>
        </div>`;

            twitter = document.querySelector('.view-telops .telops__twitter');
            twitterText = document.querySelector('.view-telops .telops__twitter__text');
            twitterBrush = document.querySelector('.view-telops .telops__twitter__brush');
            delay(() => {
                animationService.removeClass(twitterText, 'telops__twitter__text--closed');
            }, 100);
        }
    };
    // END TWITTER ------------------------------------------------------------------------------------

    setAnimationHandlers = () => {
        this.setCasterAnimationHandlers();
        this.setInterviewerAnimationHandlers();
        this.setTwitterAnimationHandlers();
    };

    setCleanupHandlers = () => {
        this.setCasterCleanupHandlers();
        this.setInterviewerCleanupHandlers();
        this.setTwitterCleanupHandlers();
    };

    bindAnimationHandlers = () => {
        this.bindCasterAnimationHandlers();
        this.bindInterviewerAnimationHandlers();
        this.bindTwitterAnimationHandlers();
        this.bound = true;
    };

    tweetList = () => {
        const tweets = this.props.data.tweets;
        if (tweets && tweets.selected && tweets.response) {
            const list = [];
            tweets.response.map(tweet =>
                tweets.selected.includes(tweet.id) && list.push({ text: tweet.text, name: tweet.user.name, screen_name: tweet.user.screen_name })
            );
            return list
        }
        return [];
    }

    render() {
        const telops = this.props.data.telops;
        const leftName = telops.leftTitle === 'player' ? telops[telops.leftTitle].name : telops[telops.leftTitle];
        const leftTitle = telops.leftTitle;
        const rightName = telops.rightTitle === 'player' ? telops[telops.rightTitle].name : telops[telops.rightTitle];
        const rightTitle = telops.rightTitle;
        const interviewerName = telops.interviewer;
        const player = telops.player;

        console.log('tweetindex', this.state.tweetIndex, this.tweetList()[this.state.tweetIndex]);

        return (
            <div className={`view-telops view-telops--${this.props.data.theme} container`}>
                <Caster title={leftTitle} name={leftName} />
                <Caster title={rightTitle} name={rightName} side="right" />
                <Interviewer name={interviewerName} />
                <Interviewee
                    name={player.name}
                    team={this.props.data.teamName}
                    teamTricode={player.team}
                    dpm={String(player.dpm)}
                    kp={String(player.kp)}
                    kda={String(player.kda)}
                />
                <Twitter tweet={this.tweetList().length ? this.tweetList()[this.state.tweetIndex] : null} />
            </div>
        );
    }

}

export default Telops
