// REACT
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import MatchupBans from './Bans';
import MatchupBar from './Bar';
import { delay } from 'lodash';

//3RD PARTY
import styled from 'styled-components';

import { ThemeContext } from '../View/themes';

class Matchup extends Component {

    static propTypes = {
        // active: PropTypes.bool,
        // action: PropTypes.string,
        data: PropTypes.any,
        statusCallback: PropTypes.func,
    }

    state = {
        status: 'closed',
        twitterVisible: false,
        animation: 'closed',
        callbackName: 'matchup',
    };

    componentDidMount() {
        this.openScene();
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        this.doAction(nextProps);
    }

    doAction = async props => {
        // console.log("MATCHUP => Receiving new props: ", props);
        if (props.active) {
            if (this.state.status !== 'open') {
                this.setState({status: 'open'});
                this.openScene();
            }
            this.runUpdate(props);
        } else {
            if (this.state.status === 'open') {
                this.setState({status: 'closed'});
                this.props.statusCallback('matchup', 'closed');;
            }
        }
    }

    openScene = async () => {
        await this.beginAnimation();
        this.props.statusCallback('matchup', 'opening');
    }

    runUpdate = props => {
        if (props.data.twitterVisible !== this.state.twitterVisible) {
            this.setState({ twitterVisible: props.data.twitterVisible });
        }
    }

    beginAnimation = () => {
        this.props.statusCallback(this.state.callbackName, 'opening');
        delay(() => this.setState({animation: '1'}), 10);
        delay(() => this.setState({animation: '2'}), 400); // Header background
        delay(() => this.setState({animation: '3'}), 800); // Header names
        delay(() => this.setState({animation: '4'}), 1100); // Top lane
        delay(() => this.setState({animation: '5'}), 1500); // Jungler appear
        delay(() => this.setState({animation: '6'}), 1600); // Jungler down
        delay(() => this.setState({animation: '7'}), 2000); // Mid lane appear
        delay(() => this.setState({animation: '8'}), 2100); // Mid lane down
        delay(() => this.setState({animation: '9'}), 2500); // Down lane appear
        delay(() => this.setState({animation: '10'}), 2600); // Down lane down
        delay(() => this.setState({animation: '11'}), 3000); // Support appear
        delay(() => this.setState({animation: '12'}), 3100); // Support down
        delay(() => this.setState({animation: '13'}), 3500); // Ban pick appear
        delay(() => this.setState({animation: '14'}), 3700); // First ban lane - First champ
        delay(() => this.setState({animation: '15'}), 3900); // First ban lane - Second champ
        delay(() => this.setState({animation: '16'}), 4100); // First ban lane - Third champ
        delay(() => this.setState({animation: '17'}), 4300); // Second ban lane - First champ
        delay(() => this.setState({animation: '18'}), 4500); // Second ban lane - Second champ
        delay(() => this.props.statusCallback(this.state.callbackName, 'open'), 4700);
    };

    render() {
        const theme = this.context.screens.matchUp || {};
        const themeName = this.props.data.theme;


        const team100Tricode = this.props.data.teamLeftTricode;
        const team200Tricode = this.props.data.teamRightTricode;

        if(!team100Tricode || !team200Tricode){
            return <div/>;
        }

        const team100MainColor = this.props.data.teamLeftMainColor;
        const team200MainColor = this.props.data.teamRightMainColor;
        const team100SubColor = this.props.data.teamLeftSubColor;
        const team200SubColor = this.props.data.teamRightSubColor;
        const team100Name = this.props.data.teamLeftName;
        const team200Name = this.props.data.teamRightName;

        const team100Roster = this.props.data.teamLeftRoster;
        const team200Roster = this.props.data.teamRightRoster;
        const team100Bans = this.props.data.teamLeftBans;
        const team200Bans = this.props.data.teamRightBans;

        const styleTeam100= '-4px 0px 1px '+team100MainColor+', 4px 0px 1px '+team100SubColor;
        const styleTeam200= '-4px 0px 1px '+team200MainColor+', 4px 0px 1px '+team200SubColor;

        const StyleVersus = styled.div`
            ${props => props.css}
        `;

        const StyleAssetBG = styled.div`
            position: absolute;
            background-image: url(${props => props.bg});
            width: 100%;
            height: 100%;
            z-index: 0;
         `;


        return (
            <div className={`view-matchup view-matchup--${themeName} view-matchup--animation-${this.state.animation} container`}>
                {theme.backgroundImage && <StyleAssetBG bg={theme.backgroundImage}/>}
                <div className={`matchup__header ${this.state.twitterVisible ? 'matchup__header--twitter-active' : ''}`}>
                    <div style={{textShadow : styleTeam100}} className={`animated animated--fast matchup__header__team matchup__header__team--left matchup__header__team--${team100Tricode.toUpperCase()}--left`}>
                    <div style={theme.cssTeamNameLeft||{}} className="matchup__header__team__text-container" >
                            <span  className="animated animated--fast matchup__header__team__text-container__team-name">{team100Name.toUpperCase()}</span>
                            <span className="animated animated--fast matchup__header__team__text-container__twitter">
                                {/* <i className="fab fa-twitter" aria-hidden="true" style={{width: '60px', marginRight: '20px', transform: 'translateY(5px)', color: '#1DA1F2'}}></i> */}
                                VOTE
                                #{team100Tricode.toUpperCase()}
                                WIN
                            </span>
                        </div>
                    </div>
                    <StyleVersus css={theme.cssVersus || ''} className="animated animated--fast matchup__header__vs">VS</StyleVersus>
                    <div style={{textShadow : styleTeam200}} className={`animated animated--fast matchup__header__team matchup__header__team--right matchup__header__team--${team200Tricode.toUpperCase()}--right`}>
                    <div style={theme.cssTeamNameRight||{}} className="matchup__header__team__text-container" >
                            <span className="animated animated--fast matchup__header__team__text-container__team-name">{team200Name.toUpperCase()}</span>
                            <span className="animated animated--fast matchup__header__team__text-container__twitter">
                                {/* <i className="fab fa-twitter" aria-hidden="true" style={{width: '60px', marginRight: '20px', transform: 'translateY(5px)', color: '#1DA1F2'}}></i> */}
                                VOTE
                                #{team200Tricode.toUpperCase()}
                                WIN
                            </span>
                        </div>
                    </div>
                </div>

                <div className="matchup__container">
                    {
                        ['top', 'jungle', 'mid', 'adc', 'support'].map((row, i) => {
                            return (
                                    <MatchupBar
                                    key={i}
                                    index={i}
                                    player1={team100Roster[row]}
                                    player2={team200Roster[row]}
                                    team100Roster={team100Roster}
                                    team100MainColor={team100MainColor}
                                    team100SubColor={team100SubColor}
                                    team200Roster={team200Roster}
                                    team200MainColor={team200MainColor}
                                    team200SubColor={team200SubColor}
                                    lane={row}
                                    theme={theme || {}}
                                    />

                            )
                        })
                    }
                </div>

                <div className="animated animated--fast matchup__bans">
                    <MatchupBans
                        bans={team100Bans}
                        side="left"
                    />
                    <div className="matchup__bans__label">BANS</div>
                    <MatchupBans
                        bans={team200Bans}
                        side="right"
                    />
                </div>
            </div>
        );
    }

}

Matchup.contextType = ThemeContext;

export default Matchup
