// REACT
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
// ME
// import { ThemeContext } from '../View/themes';
// import { delay } from 'lodash';
import { StyleSliderBackgroundContainer, StyleBackground } from './styles'
import StartersV2Component from '../../storybook-built/dist/components/Games/LJL/Starters_V2';

// import { preloadImages } from '../../util/loadImageUrl';


MatchPlayerV2.propTypes = {
    active: PropTypes.bool,
    // action: PropTypes.string,
    data: PropTypes.any,
    statusCallback: PropTypes.func,
};

function MatchPlayerV2(props) {
    // const [showBGState, setShowBGState] = useState(false);
    const [showPlayerOfTheMatchState, setShowPlayerOfTheMatchState] = useState(false);

    useEffect(() => {
        if (props.active) {
            props.statusCallback('matchPlayerV2', 'opening');
            // setShowBGState(true);
        }
        else {
            props.statusCallback('matchPlayerV2', 'closing');
            setShowPlayerOfTheMatchState(false);
            // setTimeout(()=>{setShowBGState(false)}, 2000)
        }
    }, [props.active]);

    //  Don't use animations in CGPC, please use BCL
    // const BGSpring = useSpring({
    //     from: { opacity: 0 },
    //     to: { opacity: showBGState ? 1 : 0 },
    //     onRest: () => {
    //         props.active ?
    //             setShowPlayerOfTheMatchState(true)
    //             :
    //             !showBGState && props.statusCallback('matchPlayerV2', 'closed');
    //      },
    // })

    return (
        <div className={`view-match-player container`}>

            <StyleSliderBackgroundContainer>
                <StyleBackground bgImg={props.data.BGTeamImage}></StyleBackground>
            </StyleSliderBackgroundContainer>

            {props.data.players && props.data.players.length &&
                <StartersV2Component
                    isShowing={showPlayerOfTheMatchState}
                    isPlayerOfTheMatch // Convert the StartersV2 to PlayerOfTheMatchV2
                    teams={props.data.players}
                    header={['PLAYER OF THE MATCH', 'VOTE NOW!']}
                    onAnimationFinished={() => {
                        props.active && props.statusCallback('matchPlayerV2', 'open');
                    }}
                />
            }
        </div>
    );
}

export default MatchPlayerV2;