import styled from 'styled-components';
// import { switchProp } from "styled-tools";

export const StyleMainContainer = styled.div`
    width: 100%;
    display: block;
    padding-bottom: 10px;
`;

export const StyleTeamGrid = styled.div`
    display: grid;
    width: 100%;
    // grid-template-columns: repeat(2, 1fr);    
    text-align: center;
    h3 {
        color: #EFEFEF;
        font-weight: 700;
        font-size: 20px;
        padding: 5px 0;
    }
    
    input {
        margin: 20px;
    }
`;

export const StyleInputGold = styled.input`
    text-align: center;
    font-size: 27px;
    border-radius: 5px;
`;

export const StyleActionContainer = styled.div`
    text-align: center;
    margin: 10px 20px;
    .button {
        text-align: center;
    }
`;