import { getPlayerStatsForPosition } from '../lib/allstats-sdk/api';

const getInfoForPlayerInTimePeriod = (summonerName, team, position, matchup, season, league, split, round, game) => {
  return getPlayerStatsForPosition({
    season,
    league,
    split,
    round,
    matchup,
    game,
    team,
    position,
    summonerName,
    champion: 'ALLCHAMPIONS',
  })
    .then(allPlayerData => {
      return allPlayerData;
    });
}

export {
  getInfoForPlayerInTimePeriod,
};
