// REACT
import React, { Component } from 'react';
import PropTypes from 'prop-types';
// INTERNAL
import { /* send, */ subscribe } from '../../../services/api';
import ControlContainer from '../../Controller/ControlContainer';
import Button from '../../Button';
import { i18n } from '../../i18n';
import { getNextMatchTeams, getNextRoundWinnersInfo, getNextGameNumber, getNextMatchBestOf } from '../../../util/nextMatch';
import { firebaseRealtimeDbSetValue } from '../../../util/firebase';


const PLAYER_POSITIONS = ['top', 'jungle', 'mid', 'ad', 'support'];
class NextMatchInfoBottomBarControl extends Component {
    static propTypes = {
        cgpc: PropTypes.string,
        display: PropTypes.bool,
        screen1: PropTypes.bool,
      };
    state = {
        status: 'ready',
        // displayingScreen: '',
    }

    supportedControls = ['control-standings', 'control-gameBreakdown', 'control-leaderboard', 'control-schedule', 'control-scheduleNext', 'control-results'];

    componentDidMount() {
        this.runSubscription();
    }

    runSubscription = () => {
        subscribe('view', null, data => {
            if (data.target === 'view' && data.showBottomContainer) {
                         this.setState({status: 'open'});
                }
            if (this.state.status !== 'open' && this.supportedControls.includes(data.target) && (data.status === 'open' || data.status === 'opening')) {
                // TODO: redo this logic?
                this.setState({ status: 'ready' });
            }
        }, this.props.cgpc);
        // subscribe('view', 'resize', data => {
        //     if (data.screen) {
        //         this.setState({displayingScreen: data.screen});
        //     }
        //     if (data.target === 'view' && data.screen === this.state.displayingScreen && data.action === 'resize') {
        //         this.setState({status: 'open'});
        //     }
        //     if (this.state.status !== 'open' && this.supportedControls.includes(data.target) && (data.status === 'open' || data.status === 'opening')) {
        //         this.setState({status: 'ready'});
        //     }
        // });
    };


    getTeamsInformationAndFormat = incomingTeams => {
        const teams = [];
        Object.keys(incomingTeams).forEach(teamTricode => {
            const teamInfo = incomingTeams[teamTricode];
            if (teamInfo && teamInfo.roster) {
                const team = {};
                team.tricode = teamTricode.toLowerCase();
                team.name = teamInfo.name;
                team.color = teamInfo.color;
                team.roster = {};
                Object.keys(teamInfo.roster).forEach(player => {
                    PLAYER_POSITIONS.forEach(position => {
                        if (!team.roster[position] && (teamInfo.roster[player].position === position || (position === 'ad' && teamInfo.roster[player].position === 'adc'))) {
                            const image = teamInfo.roster[player].images && teamInfo.roster[player].images.voting;
                            team.roster[position] = {
                                player,
                                image: image,
                            }
                        }
                    })
                })
                teams.push(team);
            }
        })
        return teams;
    }

    formatWinnersData = (winners, teams) => {
        const formattedTeams = [];
        Object.keys(winners).forEach(winnerTeamTricode => {
            const team = teams.find(team => team.tricode.toUpperCase() === winnerTeamTricode);
            if (team) {
                team.score = winners[winnerTeamTricode];
                team.mainColor = team.color.main;
                team.subColor = team.color.sub;
                team.logo = window.MainData.teams[team.tricode.toUpperCase()].images && window.MainData.teams[team.tricode.toUpperCase()].images.logoTeamColor
                formattedTeams.push(team);
            }
        });
        return formattedTeams;
    }

    getSendData = () => {
        const schedule = window.MainData.schedule;
        const nextMatchTeams = getNextMatchTeams(schedule);
        console.log('nextMatchTeams', nextMatchTeams);
        if (nextMatchTeams) {
            const nextMatchWinnersStats = getNextRoundWinnersInfo(schedule);
            const teams = this.getTeamsInformationAndFormat(nextMatchTeams);
            const teamsFormattedForBottomBar = this.formatWinnersData(nextMatchWinnersStats, teams);
            const nextGameNumber = getNextGameNumber(schedule);
            const nextMatchBestOf = getNextMatchBestOf(schedule) || 0;
            const timerText = window.MainData.timer.timerText;
            return {
                nextGameNumber,
                teamsFormattedForBottomBar,
                nextMatchBestOf,
                timerText,
            };
        }
        return null;
    }

    onPlay = () => {
        const sendData = this.getSendData();
        // send('event', { target: 'view', action: 'resize', screen: this.state.displayingScreen });
        firebaseRealtimeDbSetValue('view/widgets/nextMatchInfoBottomBar', {data: sendData}, this.props.cgpc);
        firebaseRealtimeDbSetValue('view/resized', true, this.props.cgpc);
        firebaseRealtimeDbSetValue('view/showBottomContainer', true, this.props.cgpc);
        // send('event', {target: 'view', action: 'showBottomBar', widget: 'nextMatchInfoBottomBar', data: sendData});

    }

    onClose = () => {
        // send('event', { target: 'view', action: 'close-resize', screen: this.state.displayingScreen });
        firebaseRealtimeDbSetValue('view/resized', false, this.props.cgpc);
        firebaseRealtimeDbSetValue('view/showBottomContainer', false, this.props.cgpc);
        // send('event', {target: 'view', action: 'hideBottomBar'});
        this.setState({status: 'ready'});

    }

    render() {
        return (
            <ControlContainer style={{display: this.props.display ? 'none': 'block'}} className="control--starters" status={this.state.status}>
                <div className="control__panel">
                    <h2 className="control__title">
                        <i className="fas fa-users"></i> {i18n().pages.nextMatchInfo}
                    </h2>
                    <Button
                    controllerSide={!this.props.screen1}
                        action={this.onPlay}
                        text={`${i18n().general.open}`}
                        icon="fas fa-play-circle"
                        disabled={this.state.status !== 'ready'}
                    />
                    <Button
                    controllerSide={!this.props.screen1}
                        action={this.onClose}
                        text={i18n().general.close}
                        icon="fas fa-stop-circle"
                        disabled={this.state.status !== 'open'}
                    />
                </div>
            </ControlContainer>
        )
    }
}

export default NextMatchInfoBottomBarControl
