import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';

import Picker from "../../Picker";
import Button from "../../Button";
import DataContainer from '../DataContainer';
import ItemsPurchaseHistory from './../../../storybook-built/dist/components/Games/LJL/GameScreen/InfoBox/ItemsPurchaseHistory';
import positions from "./../../../storybook-built/dist/Constants/lol_positions";

import { Base, StylePlayerSelection, StylePreview, StyleActionContainer, StyleWarningMessage } from './styles';
import championService from '../../../services/championService';



function DataItemsPurchaseHistory(props) {
    const [currentGameState, setCurrentGameState] = useState();
    const [playersListState, setPlayersListState] = useState([]);
    const [playerSelectionState, setPlayerSelectionState] = useState();
    const [dataLoadedState, setDataLoadedState] = useState();
    const [warningMessage, setWarningMessage] = useState('');

    useEffect(() => {
        const currentGame = get(props, `schedule.rounds.${props.schedule.round}.matches.${props.schedule.match}.games.${props.schedule.game}`, undefined);
        if(Object.values(currentGame.teams).length !== 2) return; // Handling TBD teams
        if(currentGame){
            let team100 = {},
              team200 = {};
            let team100Name,
              team200Name = "";
            if (Object.values(currentGame.teams)[0].side === "100" && Object.values(currentGame.teams)[1].side === "200") {
              team100Name = Object.keys(currentGame.teams)[0];
              team200Name = Object.keys(currentGame.teams)[1];
              for (let pos of positions) {
                team100[pos] = {
                  ...Object.values(currentGame.teams)[0].roster[pos],
                  pos,
                };
                team200[pos] = {
                  ...Object.values(currentGame.teams)[1].roster[pos],
                  pos,
                };
              }
            } else {
              team100Name = Object.keys(currentGame.teams)[1];
              team200Name = Object.keys(currentGame.teams)[0];
              for (let pos of positions) {
                team100[pos] = {
                  ...Object.values(currentGame.teams)[1].roster[pos],
                  pos,
                };
                team200[pos] = {
                  ...Object.values(currentGame.teams)[0].roster[pos],
                  pos,
                };
              }
            }
            setCurrentGameState({ team100Name, team100, team200Name, team200 });
        }
      }, [props.schedule]);

    useEffect(()=> {
        currentGameState && setPlayersListState(getPlayers());
    },[currentGameState])

    const loadData = () => {
      // console.log("loadData selection",playerSelectionState);
      const dataLoaded = {
        data: window.MainData.dataApiListener.component.getPlayerPurchaseHistory(playerSelectionState.teamID, playerSelectionState.position),
        playerName: playerSelectionState.playerName,
        championId: playerSelectionState.championId,
        championIcon: `https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/square/${get(championService,`${playerSelectionState.championId}.id`)}.png`,
      }
      // console.log("loadData data",dataLoaded);
      window.MainData.dataItemsPurchaseHistory = dataLoaded;
      const numberOfElementToDisplay = dataLoaded.data.reduce((prev, currValue, currentIndex) => {
        if(dataLoaded.data[currentIndex+1] && currValue.gameTime+30000 < dataLoaded.data[currentIndex+1].gameTime){
          return Number(prev)+2;
        }
        return Number(prev)+1;
      },0);
      (numberOfElementToDisplay> 34)? setWarningMessage(`Purchased items number too big to be displayed entirely.`) : setWarningMessage('');

      setDataLoadedState(dataLoaded);
    }

    const getPlayers = () => {
        let players = [];
        ['team100', 'team200'].forEach(teamID=>{
            for(const pos in currentGameState[teamID]){
                if(currentGameState[teamID][pos].championId){
                    players.push({
                        name: `${currentGameState[`${teamID}Name`]} - ${currentGameState[teamID][pos].name}`,
                        value: players.length,
                        championId: currentGameState[teamID][pos].championId,
                        playerName: currentGameState[teamID][pos].name,
                        teamID,
                        position: pos,
                    })
                }
            }
        })
        // console.log('players', players);
        return players;
    };


    return (
        <DataContainer componentSlug="itemsPurchaseHistory" icon="fad fa-shopping-cart">
            <div className="data-panel__fieldset">
              <Base>
                <StylePlayerSelection>

                    <Picker
                        items={playersListState}
                        onUpdate={(selectionIndex) => {
                            setPlayerSelectionState(playersListState[selectionIndex]);
                            console.log('selection', playersListState[selectionIndex]);
                        }}
                    />
                    {!playersListState.length && <p>{'No Champion Selected'}</p>}
                </StylePlayerSelection>
                {(dataLoadedState && <StylePreview>
                  <ItemsPurchaseHistory
                    playerName={dataLoadedState.playerName || ''}
                    championIcon={dataLoadedState.championIcon}
                    championId={dataLoadedState.championId}
                    data={dataLoadedState.data}
                  />
                </StylePreview>) || null}
                <StyleActionContainer>
                    <Button
                        disabled={!(playerSelectionState &&
                          playerSelectionState.championId &&
                          playerSelectionState.playerName &&
                          playerSelectionState.teamID &&
                          playerSelectionState.position)}
                        action={() => {loadData()}}
                        text="Load Data"
                    />
                </StyleActionContainer>
                {warningMessage && <StyleWarningMessage>{warningMessage}</StyleWarningMessage>}

              </Base>
            </div>

        </DataContainer>
    )
}

DataItemsPurchaseHistory.propTypes = {
    schedule: PropTypes.object,

}

export default DataItemsPurchaseHistory

