"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.TimerTitle = exports.Timer = exports.StyleBorderBottom = exports.Container = void 0;

var _styledComponents = _interopRequireDefault(require("styled-components"));

var _reactPose = _interopRequireDefault(require("react-pose"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var TimerAnimation = _reactPose.default.div({
  TimerVisible: {
    opacity: 1,
    transition: {
      duration: 1000
    }
  },
  TimerHidden: {
    opacity: 0,
    transition: {
      duration: 1000
    }
  }
});

var Container = (0, _styledComponents.default)(TimerAnimation).withConfig({
  displayName: "styles__Container",
  componentId: "sc-1ckiy89-0"
})(["position:absolute;right:0;top:55px;width:270px;min-height:100px;background-color:#1a1a1a;color:#FFFFFF;z-index:10;transition:height 300ms linear;", ""], function (props) {
  return props.css;
});
exports.Container = Container;

var TimerTitle = _styledComponents.default.p.withConfig({
  displayName: "styles__TimerTitle",
  componentId: "sc-1ckiy89-1"
})(["font-family:'Refrigerator',sans-serif;font-weight:600;letter-spacing:2px;width:100%;margin:auto;text-align:center;padding:15px 20px 5px 20px;font-size:23px;color:#a1a1a1;", ""], function (props) {
  return props.css;
});

exports.TimerTitle = TimerTitle;

var Timer = _styledComponents.default.p.withConfig({
  displayName: "styles__Timer",
  componentId: "sc-1ckiy89-2"
})(["font-family:'Refrigerator',sans-serif;font-weight:bolder;letter-spacing:2px;width:100%;margin:auto;margin-bottom:10px;padding:0 20px 5px 20px;text-align:center;font-size:", ";transition:font-size 300ms linear;line-height:1;color:#ffffff;", ""], function (props) {
  return props.isLong ? '52px' : '65px';
}, function (props) {
  return props.css;
});

exports.Timer = Timer;

var StyleBorderBottom = _styledComponents.default.div.withConfig({
  displayName: "styles__StyleBorderBottom",
  componentId: "sc-1ckiy89-3"
})(["width:100%;position:absolute;bottom:0;height:10px;background-image:linear-gradient(to right,#f00020,#540035);", ""], function (props) {
  return props.cssBorderBottom;
});

exports.StyleBorderBottom = StyleBorderBottom;