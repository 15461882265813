"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StyleTeamContainer = exports.StyleTeamAssetContainer = exports.StylePick = exports.StyleMain = exports.StyleHeader = exports.StyleContent = exports.StyleBanPickContainer = exports.StyleBan = void 0;

var _styledComponents = _interopRequireWildcard(require("styled-components"));

var _styledTools = require("styled-tools");

var _colors = require("../../../../Constants/colors");

function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }

function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

var StyleMain = _styledComponents.default.div.withConfig({
  displayName: "styles__StyleMain",
  componentId: "sc-e3816f-0"
})(["position:absolute;bottom:0;left:0;right:0;height:300px;width:100%;background-color:#000000;background:", ";transition:transform 600ms ease-in-out;transform:", ";"], function (props) {
  return "url(".concat(props.bg, ")");
}, function (props) {
  return props.show ? 'translateY(0)' : 'translateY(100%)';
});

exports.StyleMain = StyleMain;

var StyleHeader = _styledComponents.default.div.withConfig({
  displayName: "styles__StyleHeader",
  componentId: "sc-e3816f-1"
})(["width:100%;height:55px;transition:height 400ms ease-in;background-color:transparent;color:#000000;text-transform:uppercase;font-family:futura-pt,sans-serif;font-style:normal;font-weight:700;font-size:25px;padding-left:100px;padding-top:17px;", ""], function (props) {
  return props.styleHeader ? props.styleHeader : '';
});

exports.StyleHeader = StyleHeader;

var StyleContent = _styledComponents.default.div.withConfig({
  displayName: "styles__StyleContent",
  componentId: "sc-e3816f-2"
})(["position:relative;display:grid;height:calc(100% - 55px);grid-template-rows:repeat(2,1fr);"]);

exports.StyleContent = StyleContent;

var StyleTeamContainer = _styledComponents.default.div.withConfig({
  displayName: "styles__StyleTeamContainer",
  componentId: "sc-e3816f-3"
})(["display:grid;grid-template-columns:400px auto;"]);

exports.StyleTeamContainer = StyleTeamContainer;

var StyleTeamAssetContainer = _styledComponents.default.div.withConfig({
  displayName: "styles__StyleTeamAssetContainer",
  componentId: "sc-e3816f-4"
})(["display:grid;grid-template-columns:90px auto;margin:auto;align-items:center;width:300px;img{height:70px;width:70px;margin:0 10px;}p{font-size:38px;margin:0 10px;color:#FFFFFF;width:100%;text-align:left;padding-left:15px;opacity:", ";", ";}", ""], function (props) {
  return props.isWinner ? '1' : '0.3';
}, function (props) {
  return props.isWinner ? "" : props.loser;
}, function (props) {
  return props.styleTeamAssetContainer ? props.styleTeamAssetContainer : '';
});

exports.StyleTeamAssetContainer = StyleTeamAssetContainer;

var StyleBanPickContainer = _styledComponents.default.div.withConfig({
  displayName: "styles__StyleBanPickContainer",
  componentId: "sc-e3816f-5"
})([""]);

exports.StyleBanPickContainer = StyleBanPickContainer;

var StyleBan = _styledComponents.default.div.withConfig({
  displayName: "styles__StyleBan",
  componentId: "sc-e3816f-6"
})(["height:50px;position:absolute;filter:grayscale(100%);", " img{width:50px;}"], (0, _styledTools.switchProp)("side_banId", {
  "100_1": (0, _styledComponents.css)(["left:468px;top:54px;"]),
  "100_2": (0, _styledComponents.css)(["left:533px;top:54px;"]),
  "100_3": (0, _styledComponents.css)(["left:598px;top:54px;"]),
  "100_4": (0, _styledComponents.css)(["left:1329px;top:54px;"]),
  "100_5": (0, _styledComponents.css)(["left:1394px;top:54px;"]),
  "200_1": (0, _styledComponents.css)(["left:503px;top:141px;"]),
  "200_2": (0, _styledComponents.css)(["left:568px;top:141px;"]),
  "200_3": (0, _styledComponents.css)(["left:633px;top:141px;"]),
  "200_4": (0, _styledComponents.css)(["left:1294px;top:141px;"]),
  "200_5": (0, _styledComponents.css)(["left:1359px;top:141px;"])
}));

exports.StyleBan = StyleBan;

var StylePick = _styledComponents.default.div.withConfig({
  displayName: "styles__StylePick",
  componentId: "sc-e3816f-7"
})(["position:absolute;background:url('https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/square/dummy.png');height:80px;", " img{width:80px;}"], (0, _styledTools.switchProp)("side_pos", {
  "100_top": (0, _styledComponents.css)(["left:756px;top:24px;"]),
  "100_jungle": (0, _styledComponents.css)(["left:981px;top:24px;"]),
  "100_mid": (0, _styledComponents.css)(["left:1076px;top:24px;"]),
  "100_adc": (0, _styledComponents.css)(["left:1582px;top:24px;"]),
  "100_support": (0, _styledComponents.css)(["left:1677px;top:24px;"]),
  "200_top": (0, _styledComponents.css)(["left:821px;top:141px;"]),
  "200_jungle": (0, _styledComponents.css)(["left:916px;top:141px;"]),
  "200_mid": (0, _styledComponents.css)(["left:1141px;top:141px;"]),
  "200_adc": (0, _styledComponents.css)(["left:1517px;top:141px;"]),
  "200_support": (0, _styledComponents.css)(["left:1742px;top:141px;"])
}));

exports.StylePick = StylePick;