// REACT
import Chart from 'chart.js';
import TwoTonePlugin from '../../../lib/Chart.js/Chart.TwoTone';
import TriggerPlugin from '../../../lib/Chart.js/Chart.Trigger';

Chart.pluginService.register(new TriggerPlugin());
Chart.pluginService.register(new TwoTonePlugin());

const getTestSettings = function() {
  return {
    dataSet: [0, 2, -2, -10, -5, -1, 1, 5, 10],
    labels: [0,'',2,'',4,'',6,'',8],
    targetElement: document.getElementById('chart'),
    trigger: new Promise((resolve) => {
      setTimeout(() => resolve(), 10);
    }),
  };
};

const createChart = (targetElement, dataSet, labels, trigger, theme, customOptions, customColors) => {
  if (!targetElement && !dataSet) {
    const defaults = getTestSettings();
    targetElement = defaults.targetElement;
    dataSet = defaults.dataSet;
    labels = defaults.labels;
    trigger = trigger || defaults.trigger;
  }
  const redSizeBackgroundColor = customColors?.redBgColor || "rgba(72,131,171, 0.3)";
  const redSizeBorderColor = customColors?.redBorderColor || "#4883ab";
  const blueSizeBackgroundColor = customColors?.blueBgColor || "rgba(221,65,66, 0.3)";
  const blueSizeBorderColor = customColors?.blueBorderColor || "#dd4142";
  const xAxisColor = (theme && theme.indexesColor) ? `${theme.indexesColor}22` : "rgba(255, 255, 255, 0.1)";
  const yAxisColor = (theme && theme.indexesColor) ? `${theme.indexesColor}55` : "rgba(255, 255, 255, 0.3)";

  const ctx = targetElement.getContext('2d');
  const myNewChart = new Chart(ctx, {
      type: 'line',
      data: {
        labels: labels ? labels : [],
        datasets: [
          {
            backgroundColor: redSizeBackgroundColor,
            borderColor: redSizeBorderColor,
            borderWidth: 2,
            data: dataSet,
            pointRadius: 0,
          },
        ],
      },
    options: customOptions || {
        animation: {
          duration: 800,
        },
        twoTone: {
          yValue: 0,
          twoToneLine: {
              stroke: 0.2,
              colour: yAxisColor,
          },
          belowThresholdBackgroundColor: [blueSizeBackgroundColor],
          belowThresholdBorderColor: [blueSizeBorderColor],
        },
        trigger: {
          blockingPromise: Promise.resolve(),
        },
        elements: {
          line: {
            tension: 0.1, // disable bezier curves,
          },
        },
        scales: {
          xAxes: [{
              gridLines: {
                color: xAxisColor,
                drawTicks: false,
              },
              ticks: {
                fontSize: 17,
                fontColor: (theme && theme.indexesColor) || '#FFFFFF',
                padding: 12,
                maxRotation: 0,
                minRotation: 0,
                paddingBackdrop: 0,
              },
          }],
          yAxes: [{
              gridLines: {
                  color: yAxisColor,
                  drawTicks: false,
              },
              ticks: {
                fontSize: 17,
                fontColor: (theme && theme.indexesColor) || '#FFFFFF',
                suggestedMin: -1000,
                suggestedMax:  1000,
                callback: function(value, index) {
                  if (index % 2 === 0) {
                    return Math.abs((value/1000).toFixed(0)) + 'K';
                  }
                },
                padding: 10,
              },
          }],
        },
        legend: { display: false },
        tooltips: {
            callbacks: {
              label: function(tooltipItem) {
                return tooltipItem.yLabel || '';
              },
            },
        },
      },
  });
  return myNewChart;
}

export default createChart;
