import styled from 'styled-components';

export const StyleTelops = styled.div`
  padding: 0 5px 5px 5px;
  width: 100%;
`;

const StyleRows = styled.div`
  display: grid;
  grid-template-rows: 40px 40px 40px 40px;
  grid-row-gap: 5px;
  align-items: center;
`;

export const StyleTelopsSingle = styled(StyleRows)``;

export const StyleTelopsTitle = styled.p`
  margin-bottom: 4px;
  text-align: center;
  text-transform: capitalize;
`;

export const StyleField = styled.div``;

export const StyleFieldName = styled.div``;

export const StyleFieldInput = styled.div`
  text-align: center;
  opacity : ${ props => props.isDisabled ? 0.5 : 1};
  pointer-events: ${ props => props.isDisabled ? 'none' : 'all'};
  align-self: center;
  justify-self: center;
  div {
    overflow: visible;
  }
`;

export const StyleInput = styled.input`
  width: 90%;
  padding: 5px;
  border-radius: 5px;
  border: 1px #EFEFEF solid;
`;

export const StyleLabels = styled(StyleRows)``;

export const StyleLabel = styled.p`
  text-align: right;
  padding-right: 10px;
`;

export const StyleTelopsContainer = styled.div`
  display: grid;
  grid-column-gap: 10px;
  grid-template-columns: 1fr repeat(4, 2fr);
`;

export const StyleUpdateContainer = styled.div`
  margin: 10px 0 10px 10px;
    div{
      width: 100px;
      text-align: center
    }
`;