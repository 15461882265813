// REACT
import React, { Component } from 'react';
import PropTypes from 'prop-types';
// import { findKey } from 'lodash';
// internal
import DataContainer from '../DataContainer';
import DataKeyPlayersPlayer from './Player';
// import { statsDictionary } from '../../../services/keyPlayerService';

class DataKeyPlayers extends Component {

    static propTypes = {
        schedule: PropTypes.object,
    }

    state = {
        // statsSelection: [
        //     'damageToChampionsPerMin',
        //     "kda",
        //     'killParticipation',
        // ],
    }

    // handleStatsChange = (pos, newStat) => {
    //     this.setState({
    //         statsSelection: this.state.statsSelection.map((stat, index) => (index === pos) ? newStat : stat),
    //     },
    //     ()=>{
    //         window.MainData.keyPlayers.statsSelected = this.state.statsSelection.map(stat => findKey(statsDictionary, val => (val === stat)) || stat);
    //     });
    // }

    componentDidMount(){
        // window.MainData.keyPlayers.statsSelected = this.state.statsSelection.map(stat => findKey(statsDictionary, val => (val === stat)) || stat);
    }

    render () {
        const schedule = this.props.schedule;
        const roundId = schedule.round;
        const matchId = schedule.match;
        const team100 = schedule.rounds[roundId].matches[matchId].team100;
        const team200 = schedule.rounds[roundId].matches[matchId].team200;

        return (
            <DataContainer componentSlug="keyPlayers" icon="fas fa-star">
                <div className="data-panel__fieldset">
                    <div className="game-sides-container">
                        <DataKeyPlayersPlayer
                            team={team100.tricode}
                            side="100"
                            // statsSelection={this.state.statsSelection}
                            // onStatsSelectionChange={this.handleStatsChange}
                        />
                        <DataKeyPlayersPlayer
                            team={team200.tricode}
                            side="200"
                            // statsSelection={this.state.statsSelection}
                        />
                    </div>
                </div>
            </DataContainer>
        );

    }

}

export default DataKeyPlayers