import { get } from 'lodash';
import { getLeaderboard, getPlayerStatsForPosition } from '../lib/allstats-sdk/api';

const validLeaderboardStats = {
  killParticipation: 'KP',
  kills: 'KILLS',
  killsPerGame: 'KILLS/G',
  assists: 'ASSISTS',
  assistsPerGame: 'ASSISTS/G',
  cs: 'CS',
  csPerGame: 'CS/G',
  csPerMin: 'CS/M',
  damageToChampionsPerMin: 'DPM',
  goldEarnedPerMin: 'GOLD/M',
  kda: 'KDA',
  wardsKilled: 'WARD KILLS',
  wardsKilledPerGame: 'WARD KILLS/G',
  wardsKilledPerMin: 'WARD KILLS/M',
  wardsPlacedPerGame: 'WARDS/G',
  wardsPlacedPerMin: 'WARDS/M',
  visionWardsBoughtInGamePerGame: 'VISION WARDS/G',
};

const getPlayerStats = (season, league, split, playerName, champion) => {
  if(champion){
    console.log("Fetching stats for:",season, league, split, playerName, champion);
    return getPlayerStatsForPosition({season, league, split, summonerName: playerName, champion })
    .then(response =>{
      if(response.error){
        console.log("Error", response.error);
        return null;
      }else{
        console.log("player stats found: ", response.stats);
        return response.stats;
      }
    }).catch(e => {console.log(e)});
  }
  else{
    return getLeaderboard(season, league, split)
      .then(allPlayerData => {
        if (!allPlayerData) return null;
        const activePlayerData = allPlayerData;
        if (activePlayerData.length === 0) return null;
        const playerInfo = activePlayerData.find(playerStats => playerStats.summonerName.toLowerCase() === playerName.toLowerCase());
        console.log("player found", playerInfo);
        return playerInfo;
      });
  }
}


const getTopPlayers = (season, league, split, maxReturn) => {
  return getLeaderboard(season, league, split)
    .then(allPlayerData => {
      // calculate top 5 for each stat
      const topForEachStat = Object.keys(validLeaderboardStats).reduce((obj, key) => {
        obj[key] = [];
        return obj;
      }, {});
      const decimalsForEachStat = {
        killParticipation: 2,
        killsPerGame: 2,
        assistsPerGame: 2,
        kda: 2,
        csPerMin: 2,
        damageToChampionsPerMin: 1,
        goldEarnedPerMin: 1,
        wardsKilledPerGame: 2,
        wardsKilledPerMin: 2,
        wardsPlacedPerGame: 2,
        wardsPlacePerMin: 2,
        visionWardsBoughtInGamePerGame: 2,
      };

      if (!allPlayerData) return topForEachStat;

      // DON'T filter for active players since we can't pull all sources
      const activePlayerData = allPlayerData;
      if (!activePlayerData.length) return topForEachStat;
      Object.keys(topForEachStat).forEach(statName => {
        activePlayerData.forEach(player => player[statName] = Number.parseFloat(player[statName]));
        topForEachStat[statName] = activePlayerData
          .sort((p1, p2) => {
            return p2[statName] - p1[statName];
          })
          .slice(0, maxReturn)
          .map(player => {
            const ret = {
              id: player.id,
              summonerName: player.summonerName,
              team: player.team,
            };
            if (decimalsForEachStat[statName]) {
              ret[statName] = player[statName].toFixed(decimalsForEachStat[statName]);
            } else {
              ret[statName] = player[statName];
            }
            return ret;
          });
      });
      return topForEachStat;
    });
}


const getAllPlayersForPosition = (position) => {
  const players = {};
  const teams = Object.values(get(window, 'MainData.teams',{}));
  teams.forEach(team => {
    for(const player in team.roster){
      if(team.roster[player].position === position){
        players[player] = (players[player] || 0) +1;
      }
    }
  });
  // console.log("Players for ", position, players);
  return players;
}

const getPlayerRank = async (season, league, split, stat, summonerName, position) => {
  const playersOnSamePosition = getAllPlayersForPosition(position);
  if(Object.keys(validLeaderboardStats).includes(stat)){
    return getLeaderboard(season, league, split).then(allPlayerData => {
      const allPlayerDataSorted = [...allPlayerData]
        .filter(player => playersOnSamePosition[player.summonerName])
        .sort((playerA, playerB) => Number(playerB[stat])-Number(playerA[stat]))
      // console.log('allPlayerDataSorted',allPlayerDataSorted);
        return allPlayerDataSorted.findIndex((player)=> player.summonerName === summonerName);
    });
  }
}

export {
  getTopPlayers,
  getPlayerStats,
  getPlayerRank,
  validLeaderboardStats,
}