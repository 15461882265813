// REACT
import React, { Component } from 'react';
// INTERNAL
import { subscribe } from '../../../services/api';
import ControlContainer from '../../Controller/ControlContainer';
import Button from '../../Button';
import validateDataSchedule from '../../Data/DataSchedule/validate';
import { i18n } from '../../i18n';
import { updateScreenStatus } from '../../../util/controllerUtils';
import Picker from '../../Picker';
import PropTypes from 'prop-types';
class ResultsListControl extends Component {

    state = {
        status: 'ready',
        displayMatch: false,
        selectedMatch: 0,
    }
    static propTypes = {
        cgpc: PropTypes.string,
        display: PropTypes.bool,
        screen1: PropTypes.bool,
      };

    componentDidMount() {
        this.runSubscription();
        if (validateDataSchedule()) {
            this.setState({status: 'ready'});
        } else {
            this.setState({status: 'invalid'});
        }
    }

    runSubscription = () => {
        subscribe('control-resultsList', null, data => {
            if (data.target === 'control-resultsList') {
                console.log('SETTING');
                this.setState({
                    status: data.status || 'ready',
                });
                // if(data.data){
                //     this.setState({
                //         displayMatch: data.data.displayMatch || false,
                //         selectedMatch: data.data.selectedMatch || window.MainData.schedule.match,
                //     })
                // }
            }
        }, this.props.cgpc);
    };

    getSendData = (displayMatch) => {
        const selectedMatch = this.state.displayMatch? window.MainData.schedule.rounds[window.MainData.schedule.round].matches[this.state.selectedMatch].name || '' : window.MainData.schedule.rounds[window.MainData.schedule.round].matches[window.MainData.schedule.match || 0].name || ''
        return {
            displayMatch: this.state.displayMatch,
            selectedMatch,
            selectedGame: window.MainData.schedule.game,
            matchResults: displayMatch ?
                (window.MainData.dataResultsList.storedData[window.MainData.schedule.round] || {})[this.state.selectedMatch]
                : null,
            gameResults: displayMatch ? null : ((window.MainData.dataResultsList.storedData[window.MainData.schedule.round] || {})[window.MainData.schedule.match] || {})[window.MainData.schedule.game],
            pointMapping: window.MainData.pointMapping || {},
            roundName: window.MainData.schedule.rounds[window.MainData.schedule.round].name,

        };
    };

    onPlay = () => {
        if (validateDataSchedule()) {
            // send('event', { target: 'view', action: 'open', screen: 'results', data: sendData });
            updateScreenStatus('resultsList', 'open', this.getSendData(this.state.displayMatch), null, this.props.cgpc);
        } else {
            this.setState({ status: 'invalid' });
        }
    }

    onClose = () => {
        // send('event', { target: 'view', action: 'close', screen: 'results' });
        updateScreenStatus('resultsList', 'close', null, null, this.props.cgpc);
    }

    toggleDisplay = () => {
        this.setState({
            displayMatch: !this.state.displayMatch,
        });
        // const displayMatch = this.state.displayMatch ? false : true;
        // updateScreenStatus('resultsList', this.state.status, {
        //     ...this.getSendData(displayMatch),
        //     displayMatch,
        // });
        // this.setState({ displayMatch: this.state.displayMatch ? false : true }, () => {
        //     updateScreenStatus('resultsList', this.state.status, this.getSendData());
        // });
    }

    changeMatch = match => {
        this.setState({ selectedMatch: match });
            // , () => {
            // updateScreenStatus('resultsList', this.state.status, {
            //     ...this.getSendData(this.state.displayMatch),
            // });
        // });
    }

    render() {
        return (
            <ControlContainer style={{display: this.props.display ? 'none': 'block'}} className="control--resultsList" status={this.state.status}>
                <div className="control__panel">
                    <h2 className="control__title">
                        <i className="fas fa-trophy"></i> {i18n().pages.resultsList}
                    </h2>
                    <Button controllerSide={!this.props.screen1} action={this.onPlay} text={i18n().general.open} icon="fas fa-play-circle" disabled={
                        (this.state.status === 'open' || this.state.status === 'opening') && true }
                    />
                    <Button controllerSide={!this.props.screen1} action={this.onClose} text={i18n().general.close} icon="fas fa-stop-circle" disabled={
                    !(this.state.status === 'open' || this.state.status === 'opening') && true }
                    />
                </div>
                <div className="control-extra">
                    <div className="control-extra__fieldset">
                        <label className="control-extra__fieldset__label"></label>
                        <div className="pill">
                            {
                                this.state.displayMatch ?
                                [
                                    <span key="1a" className="pill__span--highlighted">{i18n().controller.viewable.true}</span>,
                                        <Button controllerSide={!this.props.screen1} key="1b" text="Display: MATCH" action={this.toggleDisplay} disabled={!this.state.displayMatch}/>,
                                ] :
                                [
                                    <span key="2a">{i18n().controller.viewable.false}</span>,
                                    <Button controllerSide={!this.props.screen1} key="2b" text="Display: MATCH" action={this.toggleDisplay} />,
                                ]
                            }
                        </div>
                        <div className="pill">
                            {
                                this.state.displayMatch ?
                                [
                                    <span key="2a">{i18n().controller.viewable.false}</span>,
                                    <Button controllerSide={!this.props.screen1} key="2b" text="Display: GAME" action={this.toggleDisplay} />,
                                ] :
                                [
                                    <span key="1a" className="pill__span--highlighted">{i18n().controller.viewable.true}</span>,
                                        <Button controllerSide={!this.props.screen1} key="1b" text="Display: GAME" action={this.toggleDisplay} disabled={this.state.displayMatch}/>,
                                ]
                            }
                        </div>
                        <div>
                            {
                                this.state.displayMatch && <Picker preselect={this.state.selectedMatch}
                                    items={((window.MainData.schedule.rounds[window.MainData.schedule.round] &&
                                        window.MainData.schedule.rounds[window.MainData.schedule.round].matches) || []).map((match, i) => ({
                                    value: i,
                                    name: match.name || `Match ${i+1}`,
                                }))}
                                onUpdate={this.changeMatch}
                                                           />
                            }
                        </div>
                    </div>
                </div>
            </ControlContainer>
        )
    }
}

export default ResultsListControl
