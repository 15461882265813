// Assets ScoutingGrounds
const ScoutingGroundLogo = 'https://cdn.dekki.com/uploads/leagues/scoutinggrounds/scoutingground-logo.png';
const ScoutingGroundBanPickBG = 'https://cdn.dekki.com/uploads/leagues/scoutinggrounds/scoutingground-banpick-bg.png';

export default {
  id: 'scoutinggrounds',
  name: 'Scouting Grounds',
  screens: {
    animatedBackground: {
      show: false,
    },
    cornerLogo: {
      cornerLogoImage:
        'https://cdn.dekki.com/uploads/broadcast-projects/scoutinggrounds/scoutinggrounds-logo.png',
      cssCornerLabelText: 'display:none',
      startX: '1930px',
      endX: '1750px',
      startY: '20px',
      endY: '20px',
    },
    screenTitle: {
      cssTitle: 'font-family:United; font-weight: 800; padding-top:15px;',
    },
    bottomBar: {
      cssTeamName: 'font-family:United; font-weight: 800;',
      cssNextGameTitle:
        'font-family:United; font-weight: 700; color:#dba103; padding-top: 35px;',
      cssTimer: 'font-family:United; font-weight: 800; color:#dba103;',
      backgroundColor: 'black',
    },
    schedule: {
      cssRow: 'max-height: 115px; width:1650px; img {height:85%;} font-family:United; color:#1b1b1b; width: 105%!important; border-right: 8px solid #dba103; ',
      cssRowNext: 'max-height: 115px; width:1650px; img {height:85%;} color:#ffffff !important;border-right: unset !important;background-color:#1b1b1b !important;',
      cssRowFinished: 'opacity: 0.5;',

      cssDate: 'font-family:United; font-weight: 800; background-color:#dba103;',
      cssDateFinished: 'color:#1b1b1b88 !important;background-color: #dba103 !important; ',
      cssDateNext: 'color:#dba103 !important; background-color: #1b1b1b !important;',

      cssVersus: 'color:#1b1b1b;background-color:#dba103;',
      cssVersusNext: 'color: #dba103 !important; background-color: #1b1b1b !important;',

      cssScore: 'color:#1b1b1b; background-color: #dba103;',
      cssScoreLoser: 'color:#1b1b1b88 !important; ;background-color: #dba103;',

      cssName: 'color: #1b1b1b; background-color: #dba103;',
      cssNameNext: 'color: #ffffff !important; background-color: #1b1b1b !important;',
      cssNameLoser: 'color: #1b1b1b88 !important;background-color: #dba103;',

      cssLogoLoser: 'opacity: 0.5; background-color: #dba10388;',

      textColorName: '#1b1b1b',
      textColorFeatureName: '#ffffff',

      textColorDate: '#1b1b1b',
      textColorFeatureDate: '#dba103',

      textColorVs: '#1b1b1b',
      textColorFeatureVs: '#dba103',

      backgroundColorRowLogo: '#1b1b1b',
      backgroundColorLoserRowLogo: '#1b1b1b50',

      backgroundColorRow: '#af8806',
      backgroundColorFeature: '#1b1b1b',
      backgroundTexttureRow: '',

      separatorColor: '#1b1b1b',

      trimColorNext: '#dba103',
      trimColorFeature: '#dba103',
      speedPanel: 200,
    },
    banPick: {
      leagueLogo: ScoutingGroundLogo,
      backgroundImage: ScoutingGroundBanPickBG,
      cssTeamInfoContainer: 'height:167px;',
      cssTeamName:
        'font-family:United; font-weight: 800; text-shadow: 0px 0px; padding-top:20px; height:166px;',
      cssPatch:
        'font-family:United; font-weight: 700; color:#dba103; top:unset; margin-top:-5px; bottom:0px;',
      cssScoreBox:
        'font-family:United; font-weight: 800; color:#dba103; padding-top:15px;',
      backgroundColorCoach: '#dba103',
      showEmptyCoachBar: false,
      showPhase: false,
      titleDisplay: 'game', // 'week' to show Week X, match to show MATCH X,...
    },
    matchUp: {
      cssTeamNameRight: {fontFamily: 'United', fontWeight: '700', textShadow: '0px 0px black', marginLeft: '-300px' },
      cssTeamNameLeft: { fontFamily: 'United', fontWeight: '700', textShadow: '0px 0px black', marginRight: '-300px' },
      cssVersus: "font-family: 'United'; font-weight: 700;",
      cssPlayerId: "font-family: 'United'; font-weight: 400",
      cssTopBar: 'border-color: #dba103;',
      cssLane: 'background-color: #000000;',
    },
    gameScreen: {
      versus: {
        cssTeamName:
          'font-family: United; font-weight: 800; text-shadow: 0px 0px;',
        cssVersus: 'font-family: United; font-weight: 700; color: #dba103;',
        cssVersusOverlay: 'border-width: 0px;',
      },
      teamInfo: {
        cssTeamName: 'font-family:United; font-weight: 800; padding-top: 5px;',
        cssScoreContainer:
          'background-image: unset; background-color: #000000; border-left-width: 5px; border-left-style: solid;',
      },

    },
    gameBreakdown: {
      teamStats: {
        cssTeamName:
          "font-family: 'United'; font-weight:700; padding-top: 15px;",
        cssTeamStats:
          "font-family: 'United'; font-weight: 400; padding-top: 5px",
      },
      key: {
        cssHeader: "font-family: 'United'; font-weight: 400;",
        cssTitles: "font-family: 'United'; font-weight: 400;",
      },
      graph: {
        cssHeader:
          "font-family: 'United'; font-weight: 400; padding-top: 10px;",
        cssContent: "font-family: 'United'; font-weight: 400;",
        cssKey: "font-family: 'United'; font-weight: 400;",
      },
    },
  },
};
