import styled from 'styled-components';

export const StyleHeaderTitle = styled.p`
  font-family: futura-pt, sans-serif;
  letter-spacing: 2px;
  ${props => props.StyleHeaderTitle ? props.StyleHeaderTitle : ''};
`;

export const StyleVisionControlContent = styled.div`
 
`;

export const StyleInfoBoxBody = styled.div`
  color: white;
  display: flex;
  align-items: center;
  height: 210px;
  left: 6px;
  overflow: hidden;
  position: absolute;
  top: 70px;
  width: 1340px;
  ${props => props.StyleInfoBoxBody ? props.StyleInfoBoxBody : ''};

`;

export const StyleInfoBoxTitle = styled.div`
  font-family: futura-pt, sans-serif;
  font-weight: 700;
  letter-spacing: 2px;
  color: white;
  font-size: 40px;
  left: 50%;
  position: absolute;
  text-align: center;
  top: 15px;
  transform: translateX(-50%);
  width: 100%;
  ${props => props.StyleInfoBoxTitle ? props.StyleInfoBoxTitle : ''};
`;


export const StyleBarTeamContainer = styled.div`
  position: relative;
  padding: 6px;
  display: grid;
  grid-row-gap: 6px;
  height: 100%;
  box-sizing: border-box;
  flex: 1 1;
`;