// REACT
import React from 'react';
import PropTypes from 'prop-types';
import {loadImageUrlPosition, preloadImage} from '../../../../util/loadImageUrl';
import {ImageContainer} from './styles';

const MatchupBarLane = props => {
  const [isLoaded, setLoaded] = React.useState(false);
  React.useEffect(() => {
    setLoaded(false);
  }, [props.position]);
  React.useEffect(() => {
    if (!isLoaded) {
      preloadImage(loadImageUrlPosition(props.position)).then(() => {
        setLoaded(true);
      });
    }
  }, [isLoaded]);
  const positionImage = loadImageUrlPosition(props.position);
  return (
      isLoaded ?
      <div className="matchup__container__bar__lane">
        <ImageContainer css={props.theme.cssLane || ''}>
          <img alt='' src={positionImage} />
        </ImageContainer>
      </div>
      : <div/>
    )
  };

MatchupBarLane.propTypes = {
  position: PropTypes.string,
  theme: PropTypes.object,
};

export default MatchupBarLane
