import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import WaitingScreenComponent from '../../storybook-built/dist/components/Games/LJL/WaitingScreen';
import Timer from '../../storybook-built/dist/components/Games/LJL/TopLevelComponents/Timer';
import { ThemeContext } from '../View/themes';


class WaitingScreen extends React.Component {

    static propTypes = {
        // active: PropTypes.bool,
        data: PropTypes.object,
        statusCallback: PropTypes.func,
        selectedRound: PropTypes.string,
        selectedMatch: PropTypes.string,
        selectedGame: PropTypes.number,
    }

    constructor(props) {
        super(props);
        this.state = {
            status: 'closed',
        }
    }

    componentDidMount() {
        setTimeout(() => this.doAction(this.props), 1000);
    }

    componentWillUnmount() {
        console.log('unmount waitingScreen');
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        console.log("Received new Props from Waiting!!! =>", nextProps)
        this.doAction(nextProps);
    }

    doAction = props => {
        if (props.active) {
            if (this.state.status !== 'open') {
                this.setState({ status: 'open' });
            }
        } else {
            if (this.state.status === 'open') {
                this.setState({status: 'closed'});
                setTimeout(() => this.props.statusCallback('waitingScreen', 'closed'), 1000);
            }
        }
    }

    formatMessageRound(message){
        let result = [];
        if(message && (message.length >  0)){
            const currentRound = (this.props.data.selectedRound && this.props.data.selectedRound.toUpperCase()) || "";
            const currentMatch = (this.props.data.selectedMatch && this.props.data.selectedMatch.toUpperCase()) || "";
            const currentGame = (this.props.data.selectedGame+1) || "";
            for(const line of message){
                let newLine = _.cloneDeep(line);
                if(!line.timer){
                    newLine.text = newLine.text.replace("%CURRENT_ROUND%", currentRound);
                    newLine.text = newLine.text.replace("%CURRENT_MATCH%", currentMatch);
                    newLine.text = newLine.text.replace("%CURRENT_GAME%", currentGame);
                }
                result.push(newLine);
            }
        }
        return result;
    }

    render() {
        if (!this.props.data) return <div />;
        // console.log("WaitingScreen Data: ", this.props.data);
        const theme = (this.context.screens && this.context.screens.waitingScreen) || {};
        if (Object.keys(this.props.data).length === 0 && this.props.data.constructor === Object) return <div />;

        return (
            <div>
                <WaitingScreenComponent
                    timer={(this.props.data.timer && this.props.data.timer.timer && <Timer targetTime={this.props.data.timer.timer.targetTime}/>) || ""}
                    logoSrc={theme.logoSrc}
                    videoSrc={theme.videoSrc}
                    backgroundSrc={theme.backgroundSrc}
                    textArray={(theme.messages && this.formatMessageRound(theme.messages[this.props.data.messageName])) || []}
                    isShowing={this.state.status === "open"}
                    theme={theme || {}}
                />
            </div>
        );
    }
}

WaitingScreen.contextType = ThemeContext;

export default WaitingScreen;

