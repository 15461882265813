// REACT
import React, { Component } from 'react';
import PropTypes from 'prop-types';
//ME
import Picker from '../../Picker';
import {leagues} from '../../../util/const';

class LeaguePicker extends Component {

  static propTypes = {
    onUpdate: PropTypes.func,
    preselect: PropTypes.string,
    leagues: PropTypes.object,
    disableSubmitButton: PropTypes.func,
  };

  render() {
    const leaguesInfo = [];
    for (let i = 0; i < Object.keys(this.props.leagues).length; i++) {
      for (let k = 0; k < leagues.length; k++) {
        Object.keys(this.props.leagues)[i] === leagues[k].value && leaguesInfo.push({value: leagues[k].value, name: leagues[k].name});
      }
    }

    leaguesInfo.length <= 0 && this.props.disableSubmitButton && this.props.disableSubmitButton('league');

    return (
      <Picker
        items={leaguesInfo}
        onUpdate={this.props.onUpdate}
        preselect={this.props.preselect}
        showSelectOption={false}
      />
    );
  };
}

export default LeaguePicker;