// REACT
import React from 'react';
import PropTypes from 'prop-types';

// ME
import Lane from './Lane';
import Player from './Player';

class MatchupBar extends React.Component {

  shouldComponentUpdate(){
    return false;
  }

  render(){
    const props = this.props;
    return (
      <div className="animated animated--fast matchup__container__bar">
    <Player
      index={props.index}
      player={props.player1}
      side="right"
      teamRoster={props.team100Roster}
      teamMainColor={props.team100MainColor}
      teamSubColor={props.team100SubColor}
    />
    <Lane position={props.lane} theme={props.theme || {}}/>
    <Player
      index={props.index}
      player={props.player2}
      side="left"
      teamRoster={props.team200Roster}
      teamMainColor={props.team200MainColor}
      teamSubColor={props.team200SubColor}
    />
  </div>
  );
  }
};

MatchupBar.propTypes = {
  player1: PropTypes.object,
  player2: PropTypes.object,
  team100Roster: PropTypes.any,
  team100MainColor: PropTypes.string,
  team100SubColor: PropTypes.string,
  team200Roster: PropTypes.any,
  team200MainColor: PropTypes.string,
  team200SubColor: PropTypes.string,
  lane: PropTypes.string,
  index: PropTypes.number,
  theme: PropTypes.object,
};

export default MatchupBar;
