import React from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash';

import { ThemeContext } from '../View/themes';

import ChampionPoolComponent from '../../storybook-built/dist/components/Games/LJL/ChampionPool'


function ChampionPool(props) {
    const theme = React.useContext(ThemeContext)

    return (
        <div>
            <ChampionPoolComponent
                data={props.data}
                show={get(props.data, 'championPoolVisible', false)}
                theme={theme.screens.championPool || {}}
            />
        </div>
    )
}

ChampionPool.propTypes = {
    data: PropTypes.object,
    // active: PropTypes.bool,
    // statusCallback: PropTypes.func,
}

export default ChampionPool

