// REACT
import React, { Component } from 'react';
import PropTypes from 'prop-types';
// 3RD PARTY
import { delay } from 'lodash';
// ME
import ScreenTitle from '../../storybook-built/dist/components/Games/LJL/ScreenTitle';
import * as ResultsComponent from '../../storybook-built/dist/components/Games/LJL/Results';

class Results extends Component {

    static propTypes = {
        active: PropTypes.bool,
        // action: PropTypes.string,
        data: PropTypes.any,
        statusCallback: PropTypes.func,
        // isBottomContainerRender: PropTypes.bool,
    }

    state = {
        played: false,
        status: 'closed',
        callbackName: 'results',
    };

    bound = false;

    componentDidMount() {
        this.props.statusCallback('results', 'ready');
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.active) {
            if (this.state.status !== 'open') {
                this.setState({ status: 'open' }, () => {
                    this.checkSubscriptionData(nextProps.data);
                    this.processContainerRender(nextProps);
                });
            } else {
                this.processContainerRender(nextProps);
            }
        } else {
            if (this.state.status === 'open') {
                this.clearPage();
                this.setState({status: 'closed'});
            }
        }
    }

    processContainerRender = nextProps => {
        if (nextProps.isBottomContainerRender && this.state.animation === '2') {
            this.setState({animation: '3'});
        }
        if (nextProps.isBottomContainerRender && this.state.animation === '3') {
            this.setState({animation: '2'});
        }
    }

    checkSubscriptionData = async data => {
        this.setState({status: 'open'});
        if (!data) return;
        if (!this.state.played) this.setState({ played: true }, () => this.beginAnimation());
        await this.props.statusCallback('results', 'opening');
    };

    beginAnimation = async () => {
        this.props.statusCallback(this.state.callbackName, 'opening');
        this.setState({animation: '0'}); // header base
        delay(() => this.setState({animation: '1'}), 400); // header slide
        delay(() => this.setState({animation: '2', displayTitle: true}), 800); // header title
        delay(() => this.props.statusCallback(this.state.callbackName, 'open'), 2600);
    };

    clearPage = () => {
        this.props.statusCallback('results', 'closing');
        this.setState({animation: '2'});
        delay(() => this.setState({animation: '1', displayTitle: false}), 400);
        delay(() => this.setState({animation: '0'}), 800);
        delay(() => this.props.statusCallback('results', 'closed'), 2500);
    };

    render() {
        const output100 = this.props.data.output100;
        const output200 = this.props.data.output200;
        const teamsStanding = this.props.data.teamsStanding;
        const teamsResults = [];
        teamsStanding.forEach(team => {
            if (team.tricode === output100.tricode) {
                teamsResults.splice(0, 0, team);
            }
            if (team.tricode === output200.tricode) {
                teamsResults.splice(1, 0, team);
            }
        });
        return (
            <div className={`view-results view-results--animation-${this.state.animation} container`}>
                <ScreenTitle
                    title={`RESULT`}
                    subtitle={null}
                    logo={null}
                    showTitle={this.state.displayTitle}
                    colors={{
                        main: '#dba10332',
                        sub: '#1fe4cb32',
                    }}
                    containerStyle={{'zIndex': '10'}}
                />
                <ResultsComponent
                    animation={this.state.animation}
                    team100={this.props.data.flipped ? teamsStanding[0] : teamsStanding[1]}
                    team200={this.props.data.flipped ? teamsStanding[1] : teamsStanding[0]}
                />
            </div>
        );
    }

}

export default Results
