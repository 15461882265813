// Assets ScoutingGrounds
const ScoutingGroundLogo = 'https://cdn.dekki.com/uploads/leagues/scouting_grounds_2020/sgg-2020_logo_white.svg';
const ScoutingGroundBanPickBG = 'https://cdn.dekki.com/uploads/leagues/scoutinggrounds/scoutingground-banpick-bg.png';

export default {
  id: 'scoutinggrounds',
  name: 'Scouting Grounds',
  screens: {
    animatedBackground: {
      show: true,
      defaultBackground: "",
      disableAnimationBackGround: true,
    },
    banPick: {
      leagueLogo: ScoutingGroundLogo,
      backgroundImage: ScoutingGroundBanPickBG,
      cssTeamInfoContainer: 'height:167px;',
      cssTeamName:
        'font-family:United; font-weight: 800; text-shadow: 0px 0px; padding-top:20px; height:166px;',
      cssPatch:
        'font-family:United; font-weight: 700; color:#dba103; top:unset; margin-top:-5px; bottom:0px;',
      cssScoreBox:
        'font-family:United; font-weight: 800; color:#dba103; padding-top:15px;',
      backgroundColorCoach: '#edb20f',
      showEmptyCoachBar: false,
      showPhase: false,
      titleDisplay: 'game', // 'week' to show Week X, match to show MATCH X,...
    },
    cornerLogo: {
      hideCornerLogo: true,
    },
    bottomBar: {
      cssTeamName: 'font-family:United; font-weight: 800;',
      cssNextGameTitle:
        'font-family:United; font-weight: 700; color:#edb20f; padding-top: 35px;',
      cssTimer: 'font-family:United; font-weight: 800; color:#edb20f;',
      backgroundColor: 'black',
    },
    gameBreakdown: {
      teamStats: {
        cssTeamName:
          "font-family: 'United'; font-weight:700; padding-top: 15px;",
        cssTeamStats:
          "font-family: 'United'; font-weight: 400; padding-top: 5px",
      },
      key: {
        cssHeader: "font-family: 'United'; font-weight: 400;",
        cssTitles: "font-family: 'United'; font-weight: 400;",
      },
      graph: {
        cssHeader:
          "font-family: 'United'; font-weight: 400; padding-top: 10px;",
        cssContent: "font-family: 'United'; font-weight: 400;",
        cssKey: "font-family: 'United'; font-weight: 400;",
      },
    },
    gameScreen: {
      versus: {
        cssTeamName:
          'font-family: United; font-weight: 800; text-shadow: 0px 0px;',
        cssVersus: 'font-family: United; font-weight: 700; color: #dba103;',
        cssVersusOverlay: 'border-width: 0px;',
      },
      teamInfo: {
        cssTeamName: 'font-family:United; font-weight: 800; padding-top: 5px;',
        cssScoreContainer:
          'background-image: unset; background-color: #000000; border-left-width: 5px; border-left-style: solid;',
      },
    },
    matchUp: {
      cssTeamNameRight: {fontFamily: 'United', fontWeight: '700', textShadow: '0px 0px black', marginLeft: '-300px' },
      cssTeamNameLeft: { fontFamily: 'United', fontWeight: '700', textShadow: '0px 0px black', marginRight: '-300px' },
      cssVersus: "font-family: 'United'; font-weight: 700;",
      cssPlayerId: "font-family: 'United'; font-weight: 400; display: none;",
      cssTopBar: 'border-color: #edb20f;',
      cssLane: 'background-color: #000000;',
    },
    matchUpV2: {
      cssProfileImg: '',
      cssName: "background: #181818; p{ font-family: 'United'; font-weight: 700; font-size: 32px; line-height: 32px; padding-top: 5px; letter-spacing: 1px;}",
      cssPosition: "background-color: #ffffff;",
      positionLogo: 'black',
      team100: {
          cssProfileImg: 'background-color: #2aa7d0;',
          cssName: '',
      },
      team200: {
          cssProfileImg: 'background-color: #d51f3b;',
          cssName: '',
      },
  },
    schedule: {
      cssRow: 'max-height: 115px; width:1650px; img {height:85%;} font-family:United; color:#1b1b1b; width: 105%!important; border-right: 8px solid #dba103; ',
      cssRowNext: 'max-height: 115px; width:1650px; img {height:85%;} color:#ffffff !important;border-right: unset !important;background-color:#1b1b1b !important;',
      cssRowFinished: 'opacity: 0.5;',

      cssDate: 'font-family:United; font-weight: 800; background-color:#dba103;',
      cssDateFinished: 'color:#1b1b1b88 !important;background-color: #dba103 !important; ',
      cssDateNext: 'color:#edb20f !important; background-color: #1b1b1b !important;',

      cssVersus: 'color:#1b1b1b;background-color:#edb20f;',
      cssVersusNext: 'color: #edb20f !important; background-color: #1b1b1b !important;',

      cssScore: 'color:#1b1b1b; background-color: #edb20f;',
      cssScoreLoser: 'color:#1b1b1b88 !important; ;background-color: #dba103;',

      cssName: 'color: #1b1b1b; background-color: #edb20f;',
      cssNameNext: 'color: #ffffff !important; background-color: #1b1b1b !important;',
      cssNameLoser: 'color: #1b1b1b88 !important;background-color: #dba103;',

      cssLogoLoser: 'opacity: 0.5; background-color: #edb20f;',
      cssLogo: 'background-color: #000; margin-right: 0; border-right: unset; border-left: unset;',
      cssLogoNext: 'background-color: #000;',
      cssLogoWinner: 'background-color: #000;',

      textColorName: '#1b1b1b',
      textColorFeatureName: '#ffffff',

      textColorDate: '#1b1b1b',
      textColorFeatureDate: '#edb20f',

      textColorVs: '#1b1b1b',
      textColorFeatureVs: '#edb20f',

      backgroundColorRowLogo: '#1b1b1b',
      backgroundColorLoserRowLogo: '#1b1b1b50',

      backgroundColorRow: '#af8806',
      backgroundColorFeature: '#1b1b1b',
      backgroundTexttureRow: '',

      separatorColor: '#1b1b1b',

      trimColorNext: '#edb20f',
      trimColorFeature: '#edb20f',
      speedPanel: 200,
    },
    scheduleInfoSideBar: {
      logoSrc: ScoutingGroundLogo,
      cssTimerText: "font-size: 22px; color: #edb20f; font-family: 'UnitedReg'; font-weight: 400; margin-top: 10px;",
      cssTimer: "line-height: 120px; div{ font-family: 'United'; font-weight: 800; font-size: 120px; letter-spacing: 1px;}",
      cssBackground: 'background: #181818; border-right: 15px solid #edb20f;',
      cssDayTitle: "color: #ffff; font-family: 'UnitedReg'; font-weight: 400; font-size: 30px; letter-spacing: 2px;",
      cssScheduleList: 'min-height: 600px; display: flex; flex-direction: column; justify-content: space-around; background-color: #00000044;',
      cssScheduleRow: "height: 200px; color: #ffff; div{ font-family: 'United'; font-weight: 700; font-size: 40px;} img{width: 80px; height: 80px;}",
      cssNext: "color: #edb20f; font-family: 'United'; font-weight: 700; font-size: 40px;",
      cssWinner: "font-family: 'United'; font-weight: 700; font-size: 40px;",
      cssScore: "font-family: 'United'; font-weight: 700; font-size: 40px;",
    },
    screenTitle: {
      cssTitle: 'font-family:United; font-weight: 800; padding-top:15px; font-size: 130px; color: #edb20f;',
    },
    standings: {
      showSubtext: false,
      showRank: true,
      hideHeader: false,
      cssStanding: 'padding: 230px 140px 120px 140px;',
      cssLogoCell: `background-color: #181818;position: relative;margin-left: 20px;overflow: visible; 
      :before {content: '';width: 10px; height: 100%; position: absolute;left: -10px; background-color: #dba103 !important;}`,
      cssHeaderCell: "font-size:23px; color: white;font-family: 'futura-pt'; font-weight: 400; div:first-of-type {color: transparent;}",
      cssScoreCell: "background-color:#141720; color: white;font-size:45px; font-family: 'UnitedReg'; font-weight: 400; padding-top: 10px;",
      cssNameCell: "font-size:50px; color: #181818;font-family: 'UnitedReg'; font-weight: 800; text-transform: uppercase; background-color: white; padding-top: 10px; padding-left: 30px;",
      cssCell: 'background-color: transparent;',
      cssRankCell: `height:65px; color: #181818; font-size:35px; line-height: 35px; font-family: 'United'; font-weight: 800;
      flex-grow: 0; flex-basis: 45px; background-color: white; padding-top: 10px;`,
      cssHeaderRank: `flex-basis: 45px;`,
      cssTeamRowsContainer: 'background: none;',
    },
    telopsV2: {
      cssTelops: `
      border-right: 15px solid #edb20f;
      background: #edb20f;
      border-left: unset;
      height: 100px;
      div:first-of-type{
        background: #181818;
        border: unset;
          left: 0;
          padding-top:0;
      }`,
      cssTwitter: 'p{font-family: UnitedReg, sans-serif; font-weight: 400; color: #ffffff; font-size: 20px;} margin-top: -2px;',
      cssName: 'top: 0; font-family: united, sans-serif; font-weight: 800; color: #ffffff; font-size: 60px; line-height: 57px;',
  },
  },
};
