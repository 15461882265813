// REACT
import React, { Component } from 'react';
import PropTypes from 'prop-types';
// import { send } from '../../../../src/services/api';
// internal
import Button from '../../Button';
import {i18n, checkLang, setLang} from '../../i18n';
import DataContainer from '../DataContainer';
import Picker from '../../Picker';
import { themes, ThemeContext} from '../../View/themes';
import { firebaseRealtimeDbSetValue } from '../../../util/firebase';
import { defaultPatch } from '../../../util/const';

class DataSettings extends Component {

    static propTypes = {
        secondKeys: PropTypes.object,
        cgpc: PropTypes.string,
      };

    constructor(props) {
        super(props);
        this.state = {
            language: checkLang(),
            testing: window.MainData.settings.testing || false,
            patch: window.MainData.settings.patch || defaultPatch,
            theme: themes[window.MainData.theme] || themes.default,
            showThemeLabel: false,
        };

        this.handleShowThemeChange = this.handleShowThemeChange.bind(this);
      }

    setLanguage = lang => {
        this.setState({language: lang});
        setLang(lang);
        window.location.reload();
    }

    setTestMode = setting => {
        this.setState({testing: setting});
        window.MainData.settings.testing = setting;
    }

    setPatch = patchNo => {
        window.MainData.settings.patch = patchNo;
    }

    handlePatchEdit = e => {
        this.setState({patch: e.target.value});
    }

    setTheme = (newTheme, showThemeLabel) => {
        // console.log('set theme as ', setTheme, show);
        const theme = newTheme || 'default';
        showThemeLabel = showThemeLabel || this.state.showThemeLabel;
        this.setState({ theme: themes[theme], showThemeLabel}, () => {
            // send('event', { target: 'view', action: 'theme', theme: theme, showThemeLabel: showThemeLabel });
            firebaseRealtimeDbSetValue('view/theme', theme);
            firebaseRealtimeDbSetValue('view/theme', theme, this.props.secondKeys[this.props.cgpc]);
            firebaseRealtimeDbSetValue('view/showDebugDisplay', showThemeLabel);
            firebaseRealtimeDbSetValue('view/showDebugDisplay', showThemeLabel, this.props.secondKeys[this.props.cgpc]);
        });
    }

    handleShowThemeChange(event) {
        const target = event.target;
        this.setState({ showThemeLabel: target.checked }, () => {
            this.setTheme((this.state.theme && this.state.theme.id) || 'default', target.checked);
            // send('event', {
            //     target: 'view',
            //     action: 'theme',
            //     theme: this.state.theme && this.state.theme.id || 'default',
            //     showThemeLabel: target.checked,
            // });
        });
    }

    componentDidMount() {
        this.setTheme( window.MainData.theme || this.context.id, false );
    }

    render () {

        const themeList = Object.keys(themes).sort().map(theme => {
            return {
                name: themes[theme].name,
                value: theme,
            }
        });

        return (
            <DataContainer componentSlug="settings" icon="fas fa-cogs">
                <div className="data-panel__fieldset">
                    <label className="data-panel__fieldset__label">{i18n().general.language}</label>
                    <div className="data-panel__fieldset__body">
                        <div className="pill">
                            {
                                this.state.language === 'en' ?
                                <Button action={() => this.setLanguage('ja')} text="日本語をセット" />
                                :
                                <Button action={() => this.setLanguage('en')} text="Set English" />
                            }
                        </div>
                    </div>
                </div>
                <div className="data-panel__fieldset">
                    <label className="data-panel__fieldset__label">{i18n().general.testMode}</label>
                    <div className="data-panel__fieldset__body">
                        <div className="pill">
                            {
                                this.state.testing ?
                                <Button action={() => this.setTestMode(false)} text="Off" />
                                :
                                <Button action={() => this.setTestMode(true)} text="On" />
                            }
                        </div>
                    </div>
                </div>
                <div className="data-panel__fieldset">
                    <label className="data-panel__fieldset__label">{i18n().general.patch}</label>
                    <div className="data-panel__fieldset__body">
                        <div className="pill">
                            <input type="text" className="input input--short" onChange={this.handlePatchEdit} value={this.state.patch} />
                            <Button action={() => this.setPatch(this.state.patch)} text={i18n().general.set} />
                        </div>
                    </div>
                </div>
                <div className="data-panel__fieldset">
                    <label className="data-panel__fieldset__label">{i18n().general.theme}</label>
                    <div className="data-panel__fieldset__body">
                        <div className="pill">
                            <Picker
                                items={themeList}
                                onUpdate={this.setTheme}
                                preselect={this.state.theme && this.state.theme.id}
                            />
                        </div>
                            <input
                                name="showThemeLabel"
                                type="checkbox"
                                checked={this.state.showThemeLabel}
                                onChange={this.handleShowThemeChange}
                            />
                            Show Theme Label
                    </div>
                </div>
            </DataContainer>
        );

    }

}

DataSettings.contextType = ThemeContext;

export default DataSettings
