import styled from 'styled-components';

export const StyleBaronBuffContainer = styled.div`
    position: absolute;
    width: 155px;
    opacity: ${props => props.show ? 1 : 0};
    overflow: hidden;
    top: 32px;
    right: 36px;
    height: ${props => props.isGoldDiffActive ? '72px' : '52px'};
    background-color: ${props => props.color};
    transition: height 200ms ease-in, opacity 600ms ease-in;
    z-index: -1;
`;

export const StyleBaronAsset = styled.img`
    position: absolute;
    transition: opacity 200ms ease-in;
    opacity: ${props => props.show ? 1 : 0};
    height: 52px;
    width: auto;
    right: 0;
    top:  0;
`;

export const StyleBaronPowerPlaySponsor = styled.img`
        transform: ${props => props.show ? 'translateX(-183px)': 'translateX(72px)'};
		transition: transform 600ms ease-in;
        position: absolute;
        right: 0;
        top: 27px;
        height: 80px;
        width: auto;
        z-index: 1;
`;

export const BuffBaronGoldDiff = styled.div`
    position: absolute;
    font-weight: 500;
    font-family: 'futura-pt', "sans-serif";
    color: white;
    font-size: 15px;
    width: 153px;
    padding: 2px 0;
    right: 0; 
    bottom: 0;
    height: ${props => props.show? '20' : '0'}px;
    transition: height .5s ease-in;
    text-align: center;
    ${props => props.BuffBaronGoldDiff ? props.BuffBaronGoldDiff : ''};

`;

export const BuffBaronTimer = styled.div`
    position: absolute;
    height: 35px;
    font-size: 28px;
    font-weight: 500;
    font-family: 'futura-pt', "sans-serif";
    color: ${props => props.color || 'white'};
    width: 68px;
    right: 15px;
    top: 20px;
    ${props => props.BuffBaronTimer ? props.BuffBaronTimer : ''};
`;

export const StyleGoldDiffFinal = styled.div`
	position: absolute;
    transition: opacity 300ms ease-in;
    transition-delay: 400ms;
    opacity: ${props => (props.show) ? 1 : 0}; 
    right: 5px;
    font-weight: 400;
    font-family: 'futura-pt';
    color: white;
    font-size: 13px;
    width: 153px;
	height: 72px;
    padding: 6px;
	text-align: right;
    span{
        font-size: 42px;
        font-weight: 600;
        display: block;
        line-height: 36px;
    }
    strong {
        font-weight: 700;
    }
    ${props => props.StyleGoldDiffFinal ? props.StyleGoldDiffFinal : ''};
`;