// Assets LJL
const LJLTopLeftLogoSummer = 'https://cdn.dekki.com/uploads/leagues/ljl_2019_spring/LJL-logo_topleft_Summer.png';

const LJLBanPickBackground = 'https://cdn.dekki.com/uploads/leagues/ljl_2020_spring/backgrounds/banandpickBG.png';
const LJLLogoRedGradient = 'https://cdn.dekki.com/uploads/leagues/academyleague/ljl-al_logo_gradient.svg';


export default {
  id: 'ljl2020',
  name: 'LJL 2020',
  screens: {
    bottomBar: {
      borderColorBase: '#ead8b3',
      borderColor: '#795b18',
      backgroundColor: '#1a1a1a',
      cssTeamName: '',
      cssNextGameTitle: 'color:#dba103; font-size: 26px; padding-top: 14px; letter-spacing: 2px;',
      cssTimer: 'color:#dba103; font-size: 85px; margin-bottom: 7px; ',
    },
    matchPlayerV2: {},
    leaderBoard: {
      cssHeader: 'background-color: #141720; font-family: futura-pt, "sans-serif"; font-size: 50px; font-weight: 500; height: 80px; letter-spacing: 1px;',
      cssTopRowRank: 'background-color: #141720; font-family: futura-pt, "sans-serif"; font-size: 30px; font-weight: 400;',
      cssTopRowInfoScore: 'color:  #f00020; font-family: futura-pt, "sans-serif"; font-size: 35px;   font-weight: 700;',
      cssTopRowInfo: 'background: white;',
      cssTopRowInfoName: 'background: white; color: #141720; font-family: futura-pt, "sans-serif"; font-size: 30px; text-transform: uppercase; font-weight: 400; letter-spacing: 1px;',
      cssRow: 'background: white;',
      cssRowName: 'color: #141720; font-family: futura-pt, "sans-serif"; font-size: 22px; text-transform: uppercase; letter-spacing: 1px; font-weight: 400;',
      cssRowScore: 'color:  #141720; font-family: futura-pt, "sans-serif"; font-size: 25px;  font-weight: 600;',
    },
    schedule: {
      speedPanel: 200,
      cssRow: 'font-family: futura-pt, "sans-serif"; color:#141720;',
      cssRowNext: 'color:#141720;',
      cssRowFinished: '',
      cssDate: 'background-color: #ffffff;',
      cssDateFinished: 'color:#151515;',
      cssDateNext: 'background-color: #ffffff;    padding-left: 0;',
      cssLogo: 'background-color: #151515; padding-right: 0px;',
      cssLogoNext: '',
      cssLogoLoser: '',
      cssLogoWinner: '',
      cssName: 'background-color: #ffffff; font-size: 28px; letter-spacing: 1px; font-weight:600;',
      cssNameNext: 'background: linear-gradient(0deg, #141720 0%, #00a88e 100%); color:#ffffff;',
      cssNameLoser: 'background-color: #d7d7d7;color:#141720;',
      cssNameWinner: 'background-color: #ffffff; color:#141720;',
      cssVersus: 'font-size: 50px; background-color: #ffffff; color:#00a88e; margin-right: 0; margin-left: 0;',
      cssVersusNext: 'background: linear-gradient(0deg, #141720 0%, #00a88e 100%); color:#ffffff; margin-right: 0; margin-left: 0;',
      cssScore: 'color:#151515;background-color: #ffffff; font-size: 50px;',
      cssScoreLoser: 'background-color: #d7d7d7; color: #540035;',
      cssScoreWinner: 'color: #F00020;',
      trimColorNext: 'transparent',
      rightBorderColor: '#ffffff;',
      rightBorderColorLoser: '',
      cssBorders: `
      border: none; 
      :before {
          content: '';
          width: 10px;
          height: 100%;
          left: -10px;
          position: absolute;
          background: linear-gradient(0deg, #141720 0%, #00a88e 100%);
      }
  `,
    },
    screenTitle: {
      textColor: '#141720',
      textColorSub: '#141720',
      textShadowLeft: '#dba10332',
      underlineColor: 'linear-gradient(90deg,#00a88e 0%,#141720 100%)',
      textShadowRight: '#1fe4cb32',
      cssTitle: `
        font-family: "futura-pt"; font-weight: 700; font-size: 92px; 
        lineHeight: 92px;
        letter-spacing: 0;
        text-shadow: none;
      `,
      cssTitleText: `position: relative;`,
      cssSubtitle: `
        font-family: "futura-pt"; font-weight: 700; font-size: 50px; 
        text-shadow: none;
        color: #141720;
      `,
    },
    scheduleInfoSideBar: {
      logoSrc: '',
      cssTimerText: 'font-family: "futura-pt"; font-weight: 400; font-size: 22px;',
      cssTimer: 'font-family: "futura-pt"; font-weight: 700; font-size: 100px; ',
      cssBackground: 'background: linear-gradient(0deg, #141720 0%, #00a88e 100%)',
      cssDayTitle: '',
      cssScheduleList: 'font-family: "futura-pt-condensed";',
      cssScheduleRow: 'font-family: "futura-pt-condensed"; font-weight: 700; font-size: 35px; color: #ffffff',
      cssNext: 'color: #00d2b2;',
      cssWinner: '',
      cssScore: 'font-family: "futura-pt-condensed"; color: #ffffff;',
    },
    cornerLogo: {
      cornerLogoImage: LJLTopLeftLogoSummer, //  Not used
      cssCornerLogo: 'z-index:1;',
      cssCornerLabelText: 'color:#8b681c; top:120px; left:95px; font-size:26px;',
      imageTop: '30px',
      imageLeft: '-96px',
      hideCornerLogo: true,
    },
    gameScreen: {
      backgroundImage: 'https://cdn.dekki.com/uploads/broadcast-projects/general/test-lol-game-screen-bg.png',
      disableBaronPowerPlaySponsor: true,
      disablePatchNumber: false,
      infoScreen: {
        logo: 'fullColor', //fullColor or anything else
      },
      versus: {
        backgroundTexture: '',
        borderColor: '#795b18',
        borderColorBase: '#795b18',
        textColorTeams: '#ffffff',
        textColorVersus: '#dba103',
        textAlign: 'logoCenter',
        cssTeamName: 'font-size: 50px;letter-spacing:3px; font-family: "futura-pt-bold",sans-serif; color: white;',
        cssVersusOverlay: `
        display: grid; 
        grid-template-columns: 818px 1fr 814px; 
        border: none;    
        background: url(https://dekki-production.s3-ap-northeast-1.amazonaws.com/uploads/broadcast-projects/ljl2020/videos/vs_bg.png);
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;`,
        cssVersus: 'font-size: 152px; font-family: "futura-pt-bold",sans-serif; color: black; width: 100%; font-style: italic;',
      },
      teamInfo: {
        removeTeamInfo: false,
        showGamesWinLights: true,
        showTeamLogo: true,
        backgroundAsset: 'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/team-info_scores.png',
        cssTeamInfoContainer: '',
        cssTeamName: 'font-family:"futura-pt"; font-weight: 600; font-size: 25px;letter-spacing: 1px; top:-1px; line-height: 25px;',
        cssTeamReccord: '',
        cssScoreContainer: 'background-image: url(https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/team-info_scores.png);',
        cssTeamLogo: '',
        hideScore: true,
      },
      inhibitor: {
        cssInhibitorLeft: 'font-family: futura-pt, sans-serif; font-weight: 600; font-size: 24px;',
        cssInhibitorRight: 'font-family: futura-pt, sans-serif; font-weight: 600; font-size: 24px;',
      },
    },
    banPick: {
      leagueLogo: LJLLogoRedGradient,
      backgroundImage: LJLBanPickBackground,
      // backgroundImageTeam: LJLBackgroundImage,
      cssScoreBox: 'font-family: "futura-pt", sans-serif; font-size:55px; font-weight: 600;letter-spacing: 1px; color: #000000; position: relative; top: -2px',
      backgroundColorCoach: '#FFFFFF00',
      cssCoachContainer: "font-family:'futura-pt'; font-weight: 500; font-size: 20px; color: #000000; letter-spacing: 1px; ",
      cssPatch: "font-family:futura-pt, sans-serif; color: #000000; font-weight: 400; font-size: 20px; font-weight: 100; margin-top: -5px; top: 48%",
      cssTeamName: "margin-top:25px; font-family:futura-pt, sans-serif; font-size:75px; letter-spacing: 3px; font-weight: bold; color: #000000; text-shadow: unset;",
      cssLeagueLogo: "height: 200px; bottom: 10px; width: 200px;",
      cssTeamReccord: "font-family:futura-pt, sans-serif; font-weight: 400; color: #141720; margin-top: 15px;",
      showTricode: true,
      showTeamBGVideo: true,
    },
    banPickV2: {
      leagueLogo: LJLLogoRedGradient,
    },
    standings: {
      showSubtext: false,
      showRank: false,
      rankBackground: {
        1: ['#00a88e', '#00a78d'],
        2: ['#01a289', '#029c85'],
        3: ['#03947e', '#048c78'],
        4: ['#058271', '#07786e'],
        5: ['#086d61', '#09645a'],
        6: ['#0b5851', '#0c4e4a'],
        7: ['#0e4342', '#0f3a3b'],
        8: ['#103133', '#12292e'],
      },
      cssLogoCell: `
        background-color: #141720;
        position: relative;
        margin-left: 20px;
        overflow: visible; 
        background-size: 50%; 
        flex-basis: 130px; 
        flex-grow: unset; 
        :before {
          content: '';
          width: 10px; 
          height: 100%; 
          position: absolute;
          left: -10px;
        }`,
      cssHeaderCell: `
        font-size:23px; 
        color: #141720;
        font-family: 'futura-pt'; 
        font-weight: 400; 
        justify-content: center;
        grid-template-columns: 45px 150px 950px 130px 130px;
        grid-gap: 0 10px;
        margin-left: unset;
        `,
      cssScoreCell: "background-color:#141720; color: white;font-size:45px; font-family: 'futura-pt'; font-weight: 400; flex-grow: unset; margin-left: unset;",
      cssNameCell: "font-size:45px; color: #141720;font-family: 'futura-pt'; font-weight: 600; text-transform: uppercase; background-color: white;",
      cssCell: `
        background-color: transparent;
        justify-content: center; 
        min-height:85px;
        grid-template-columns: 45px 150px 950px 130px 130px;
        grid-gap: 0 10px;
        max-height:85px;
        `,
      cssRankCell: `height:45px; font-size:35px; font-family: 'futura-pt'; font-weight: 400; flex-grow: 0; flex-basis: 45px; `,
      cssHeaderRank: `flex-basis: 45px;`,
      cssStanding: '',
    },
    animatedBackground: {
      show: true,
      defaultBackground: "https://cdn.dekki.com/uploads/broadcast-projects/academy2021/videos/academy2021_bg_video.webm",
      // defaultBackground: "https://cdn.dekki.com/uploads/broadcast-projects/ljl2021/videos/ljl2021_spr_bg_video.webm",
      disableAnimationBackGround: true,
    },
    gameBreakdown: {
      backgroundImage: '',
      // backgroundImage: 'https://cdn.dekki.com/uploads/leagues/ljl_2019_summer/gameBreakdownBG.png',
      teamStats: {
        teamStatsBGColor: '#ffffffE6',
        teamStatsBGTexture: 'none',
        showTeamBackgroundImageStatsContents: false,
        teamStatsHeaderBGColor: '#141720',
        cssTeamName: { fontFamily: 'futura-pt', fontSize: '40px', fontWeight: '700', display: 'flex', justifyContent: 'center', alignItems: 'center' },
        item: { color: '#141720', fontFamily: 'futura-pt', fontSize: '28px', fontWeight: '400' },
        cssTeamStats: "font-family: 'futura-pt';",
        cssWinTag: ' font-family: futura-pt; ',
        cssLossTag: 'background-color: rgba(255, 255, 255, 0.7); font-family: futura-pt;',
        cssBansPicks: { color: '#141720', fontSize: '30px', fontFamily: 'futura-pt', fontWeight: '400' },
        dragons: { backgroundColor: 'unset', border: '4px solid transparent' },

      },
      key: {
        gameTime: { fontFamily: 'futura-pt-condensed', fontWeight: '600' },
        gameTimeHeader: { marginBottom: '0', fontSize: '20px' },
        cssHeader: { fontFamily: 'futura-pt', backgroundColor: '#141720B3' },
        cssTitles: { fontFamily: 'futura-pt', fontWeight: '400', color: '#ffffff', fontSize: '26px' },
        content: { backgroundColor: '#141720B3', backgroundImage: 'unset' },
      },
      graph: {
        cssHeader: { fontFamily: 'futura-pt', backgroundColor: '#141720', backgroundImage: 'unset', fontWeight: '400', letterSpacing: '2px' },
        cssContent: { fontFamily: 'futura-pt', color: '#141720', backgroundImage: 'unset', fontWeight: '400', backgroundColor: '#ffffffE6', borderBottom: 'none' },
        cssKey: {
          borderBottom: 'none',
          fontFamily: 'futura-pt', fontWeight: '700',
          backgroundImage: 'unset', backgroundColor: '#141720B3',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          paddingTop: '35px',
        },
        damageCountBackground: {
          ':before': {
            backgroundColor: 'unset',
          },
        },
        indexesColor: "#141720",
      },
    },
    matchUp: {
      backgroundImage: 'https://cdn.dekki.com/uploads/leagues/ljl_2019_summer/gameBreakdownBG.png',
      cssLane: 'background-color: #16161288;',
      cssTeamNameRight: { fontFamily: 'Refrigerator', textShadow: '0px 0px black' },
      cssTeamNameLeft: { fontFamily: 'Refrigerator', textShadow: '0px 0px black' },
    },
    matchPlayer: {
      cssVoteImage: 'margin-left: -80px;',
    },
    // LJL TIMER2
    // timer2:{
    //   cssTimerContainer: `
    //     font-family: "futura-pt";
    //     color: #141720;
    //     background-color: white;
    //     box-shadow: none;
    //     border-bottom-width: 5px;
    //     `,
    //   cssTimerText: 'font-weight: 400; font-size: 18px; font-family: "futura-pt";',
    //   cssTime: 'font-weight: 700; color: #141720; font-family: "futura-pt";',
    // },
    // MSS TIMER2
    timer2: {
      cssTimerContainer: `
        background-color: #DDF2F5; 
        box-shadow: none; 
        width: 230px;
        height: 120px;
      `,
      cssTimerText: `
        color: #034051;
        font-family: bebas-neue-pro, sans-serif;
        font-weight: 400;
        font-size: 18px;
        padding: 15px 20px 0 20px;
      `,
      cssTime: `
        color: #034051;
        font-family: bebas-neue-pro, sans-serif;
        font-weight: 600; 
        font-size: 70px; 
        padding: 0 20px 5px 20px;
      `,
      cssBorderBottom: 'background:#034051;',
    },
  },
};
