// REACT
import React, { Component } from 'react';
// INTERNAL
import _ from 'lodash';
import { subscribe } from '../../../services/api';
import ControlContainer from '../../Controller/ControlContainer';
import Button from '../../Button';
import validateDataSchedule from '../../Data/DataSchedule/validate';
import {i18n} from '../../i18n';
import { updateScreenStatus } from '../../../util/controllerUtils';
import PropTypes from 'prop-types';
class MatchPlayerControl extends Component {

    state = {
        status: 'ready',
    }
    static propTypes = {
        cgpc: PropTypes.string,
        display: PropTypes.bool,
        screen1: PropTypes.bool,
      };

    componentDidMount() {
        this.runSubscription();
        if (validateDataSchedule()) {
            this.setState({status: 'ready'});
        } else {
            this.setState({status: 'invalid'});
        }
    }

    runSubscription = () => {
        subscribe('control-matchPlayer', null, data => {
            if (data.target === 'control-matchPlayer') {
                const resultState = {
                    ...data,
                    ...data.data,
                };
                delete resultState.data;
                this.setState(resultState);
            }
        }, this.props.cgpc);
    };

    onPlay = () => {
        // check data
        if (validateDataSchedule()) {
            const schedule = window.MainData.schedule;
            const teams = window.MainData.teams;

            const match = schedule.rounds[schedule.round].matches[schedule.match];
            const winner = match.team100.score > match.team200.score ? match.team100 : match.team200;
            const roster = match.games[(schedule.game || 0)].teams[winner.tricode].roster;

            const team = teams[winner.tricode];

            const players = _.map(roster, (player) => {
                if (typeof player === 'string') {
                    return null;
                }
                return {
                    name: `${winner.tricode.toUpperCase()}${player.name.toLowerCase()}`,
                    image: team.roster[player.playerId].images && team.roster[player.playerId].images.mvp,
                }
            }).filter(item => item);

            const sendData = {
                teamMainColor: team.color.main,
                teamSubColor: team.color.sub,
                players,
                preloadImages: players.map(player => player.image).filter(image => image),
            };
            // send('event', { target: 'view', action: 'open', screen: 'matchPlayer', data: sendData });
            updateScreenStatus('matchPlayer', 'open', sendData, null, this.props.cgpc);
        } else {
            this.setState({status: 'invalid'});
        }
    }

    onClose = () => {
        // send('event', { target: 'view', action: 'close', screen: 'matchPlayer' });
        updateScreenStatus('matchPlayer', 'close', null, null, this.props.cgpc);
    }

    render() {
        return (
            <ControlContainer style={{display: this.props.display ? 'none': 'block'}} className="control--match-player" status={this.state.status}>
                <div className="control__panel">
                    <h2 className="control__title">
                        <i className="fas fa-child"></i> {i18n().pages.matchPlayer}
                    </h2>
                    <Button controllerSide={!this.props.screen1} action={this.onPlay} text={i18n().general.open} icon="fas fa-play-circle" disabled={
                        (this.state.status === 'open' || this.state.status === 'opening') && true }
                    />
                    <Button controllerSide={!this.props.screen1} action={this.onClose} text={i18n().general.close} icon="fas fa-stop-circle" disabled={
                        !(this.state.status === 'open' || this.state.status === 'opening') && true }
                    />
                </div>
            </ControlContainer>
        )
    }
}

export default MatchPlayerControl
