// REACT
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
// CONTEXT THEME
import { ThemeContext } from '../View/themes';
import { getTheme } from '../View/themes';

// 3RD PARTY
import { delay } from 'lodash';

// ME
import DamageItem from '../GameScreen/InfoBox/DamageItem';
import TeamStats from './TeamStats';
import CreateGoldChart from './GoldDiffChart';
import ScreenTitle from '../../storybook-built/dist/components/Games/LJL/ScreenTitle';
import SliderBackground from '../../storybook-built/dist/components/Games/LJL/SliderBackground';

// IMAGES
import {loadImageUrlChampSquare/* , preloadImages */} from '../../util/loadImageUrl';

import {
  // StyleAssetBG,
  StyleKeyHeader,
  StyleKeyTitles,
  StyleGraphHeader,
  StyleGraphContent,
  StyleGraphKeys,
  StyleDamageCount,
  StyleSliderBackgroundContainer,
  // StyleBackgroundWrap,
  // StyleBackgroundLeft,
  // StyleBackgroundRight,
  StyleNewTitleContainer,
  StyleNewTeamImage,
  StyleTitleText,
  StyleTitleTeam,
  StyleTitleGameTimeWrapper,
  StyleTitleGameTimeText,
  StyleTitleGameTime,
  StyleNewStatsContainer,
  StyleNewStatsColumn,
  StyleNewStatsRow,
  StyleNewGameStatsTitle,
  StyleNewGameStatsTeamLogo,
  StyleNewStatsText,
  StyleNewStatsNumber,
  StyleNewStatsImageWrapper,
  StyleNewStatsDrakeImage,
  StyleNewStatsBanImage,
  StyleNewStatsDamageDealtTitle,
  StyleNewStatsTeamContainer,
  StyleNewStatsTeamColor,
  StyleNewStatsTeamTricode,
  StyleNewStatsDamageSection,
  StyleNewStatsDamageTeam,
  StyleNewStatsGoldDiff,
  StyleNewStatsGraph,
  StyleNewBreakdownWrapper,
} from './styles';


// Getting Drake Token Assets for New Postgame Breakdown
const themeSettings = getTheme();
const DrakeTokensNew = themeSettings.assets.drakeTokensNew;

String.prototype.toTitleCase = function () {
  return this.replace(/\w\S*/g, function(txt){return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();});
};


class GameBreakdown extends Component {
  static propTypes = {
    // active: PropTypes.bool,
    action: PropTypes.string, // eslint-disable-line react/no-unused-prop-types
    data: PropTypes.any,
    statusCallback: PropTypes.func,
    bottomContainerActions: PropTypes.object,
    isBottomContainerRender: PropTypes.bool,
  };

  state = {
    status: 'ready',
    animation: 'closed',
    displayTitle: false,
    displayBg: false,
  };

  bound = false;
  resolveChart = () => {};

  componentDidMount() {
    delay(() => {
      this.doAction(this.props);
    }, 100);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.doAction(nextProps);
    if (nextProps.isBottomContainerRender && this.state.status !== 'resize') {
      this.setState({ status: 'resize' });
      this.beginResizeAnimation();
    }
    if (!nextProps.isBottomContainerRender && this.state.status === 'resize') {
      this.clearResize();
      this.setState({ status: 'open' });
    }
  }

  doAction = props => {
    if (props.active) {
      if (this.state.status !== 'open') {
        this.setState({ status: 'open' });
        this.beginAnimation();
        this.createChart();
      }
    } else {
      if (this.state.status === 'open') {
        this.setState({ status: 'closing' });
        this.clearPage();
      }
      if (this.state.status === 'resize') {
        this.setState({ status: 'closing' });
        this.clearResize();
        delay(() => this.clearPage(), 2500);
      }
    }
  };

  createChart() {
    // prevent broken page from appearing
    const hasValidData = this.props.data.gameBreakdown.goldDiff && this.props.data.gameBreakdown.goldDiff.difference;
    if (!hasValidData) return;

    const goldDiff = this.props.data.gameBreakdown.goldDiff;
    const dataSet = goldDiff.dataSet;
    const labels = Object.keys(goldDiff.difference).map(val => (val % 10 === 0 ? val : ''));
    const targetElement = document.getElementById('chart');
    const self = this;
    const trigger = new Promise(resolve => {
      self.resolveChart = resolve;
    });
    CreateGoldChart(
      targetElement,
      dataSet,
      labels,
      trigger,
      get(this.context, ['screens', 'gameBreakdown', 'graph'],undefined),
      null,
      this.props.data.breakdownMode === 'new' &&
      {
        redBgColor: `${this.props.data.team100.color.main}70`,
        redBorderColor: this.props.data.team100.color.main,
        blueBgColor: `${this.props.data.team200.color.main}70`,
        blueBorderColor: this.props.data.team200.color.main,
      },
      {
        leftTeam : this.context.screens.gameBreakdown.graph.teamLeftColor || '',  // graph line color
        rightTeam: this.context.screens.gameBreakdown.graph.teamRightColor || '', // graph line color
      });
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextState.animation !== this.state.animation || nextState.displayTitle !== this.state.displayTitle) {
      return true;
    }
    return false;
  }

  beginAnimation = () => {
    this.props.statusCallback('gameBreakdown', 'opening');
    this.setState({
      displayTitle: true,
      displayBg: true,
    });
    delay(() => this.setState({ animation: '1' }), 300); // header title
    delay(() => this.setState({ animation: '2' }), 600); // header title
    delay(() => this.setState({ animation: '3' }), 900); // header round
    delay(() => this.resolveChart(), 1200); // resolve chart
    delay(() => this.setState({ animation: 'open' }), 1500); // teams titles
    delay(() => this.props.statusCallback('gameBreakdown', 'open'), 1800);
  };

  beginResizeAnimation = () => {
    this.setState({ animation: 'resize-1' }); // Scale breakdown container to 0.8
    delay(() => this.setState({ animation: 'resize-2' }), 600); // Move breakdown container up
    delay(() => this.props.bottomContainerActions.show(), 600);
  };

  clearResize = () => {
    this.props.bottomContainerActions.hide();
    this.setState({ animation: 'resize-1' });
    delay(() => this.setState({ animation: 'open' }), 600);
  };

  clearPage = () => {
    if (this.props.data.breakdownMode === 'old') {
      this.props.statusCallback('gameBreakdown', 'closing');
      this.setState({ animation: '3' });
      delay(() => this.setState({ animation: '2' }), 300);
      delay(() => this.setState({ animation: '1' }), 600);
      delay(() => this.setState({ animation: 'closed' }), 900);
      delay(() => this.setState({ displayTitle: false, displayBg: false }), 1200);
      delay(() => this.props.statusCallback('gameBreakdown', 'closed'), 1700);
    } else {
      this.props.statusCallback('gameBreakdown', 'closed');
    }
  };

  render() {
    console.log('breakdown', this.props);

    const theme = this.context || {};

    const themeName = this.props.data.theme;
    const b = this.props.data.gameBreakdown;
    const team100 = this.props.data.team100;
    const team200 = this.props.data.team200;

    const picks100 = b.picks.slice(0, 5) || [];
    const picks200 = b.picks.slice(5) || [];
    const bans100 = b.bans.slice(0, 5) || [];
    const bans200 = b.bans.slice(5).reverse() || [];
    // [1, 3, 5, 8, 10].map(ban => bans100.push(b.bans[ban - 1]));
    // [9, 7, 6, 4, 2].map(ban => bans200.push(b.bans[ban - 1]));
    return (<>
      {this.props.data.breakdownMode === 'old' ?
        <div
          className={`view-game-breakdown view-game-breakdown--${themeName} view-game-breakdown--animation-${this.state.animation} container`}
        >
          {get(theme, ['screens', 'gameBreakdown', 'backgroundImage'], undefined)
            ? (
              // <StyleAssetBG bg={theme.screens.gameBreakdown.backgroundImage} />
              <StyleSliderBackgroundContainer>
              {
                  ((!get(theme, 'screens.gameBreakdown.disableSliderBG')) &&
                  <SliderBackground
                    backgroundLeft={get(team100, 'images.logoBGFullLeft')}
                    backgroundRight={get(team200, 'images.logoBGFullRight')}
                    isShowing={this.state.displayBg}
                    isImage={true}
                  />) || null
              }
              </StyleSliderBackgroundContainer>
              // Made left and right images.
              )
            :
              <StyleSliderBackgroundContainer>
              {
                  ((!get(theme, 'screens.gameBreakdown.disableSliderBG')) &&
                  <SliderBackground
                    backgroundLeft={get(team100, 'videos.fullBg')}
                    backgroundRight={get(team200, 'videos.fullBg')}
                    isShowing={this.state.displayBg}
                  />) || null
              }
              </StyleSliderBackgroundContainer>
          }
          <ScreenTitle
            title={'POSTGAME BREAKDOWN'}
            showTitle={this.state.displayTitle}
            colors={{
              main: '#dba10332',
              sub: '#1fe4cb32',
            }}
            titleStyle={null}
            theme={theme.screens.screenTitle || {}}
          />
          {/* FIRST ROW TEAM STATS */}
          <div className="game-breakdown__base">
            <div className="game-breakdown__content">
              <div className="game-breakdown__content__main animated animated--fast">
                <TeamStats
                  team={team100}
                  isRight={false}
                  picks={picks100 || []}
                  bans={bans100 || []}
                  statData={b.stats[team100.tricode.toLowerCase()] || {}}
                  dragons={b.dragons.teamOne || []}
                  elderDragonCount={b.dragons.teamOneElderDragonsCount}
                  themeName={themeName}
                  theme={theme.screens.gameBreakdown.teamStats || {}}
                  backgroundImage={this.props.data.team100StatsBackgroundImage}
                />

                <div
                  className="animated animated--fast game-breakdown__content__main__key"
                  style={(theme && theme.screens.gameBreakdown.key.content) || {}}
                >
                  <StyleKeyHeader
                    className="game-breakdown__content__main__key__header"
                    css={theme.screens.gameBreakdown.key.cssHeader || ''}
                  >
                    <div
                      style={(theme && theme.screens.gameBreakdown.key.gameTimeHeader) || {}}
                      className="game-breakdown__content__main__key__header__text"
                    >game time</div>
                    <div
                      style={(theme && theme.screens.gameBreakdown.key.gameTime) || {}}
                      className="game-breakdown__content__main__key__header__text game-breakdown__content__main__key__header__text--large"
                    >
                      {b.gameTime}
                    </div>
                  </StyleKeyHeader>

                  <div style={{height: '70px'}}></div>
                  <StyleKeyTitles
                    className="game-breakdown__content__main__key__titles"
                    css={theme.screens.gameBreakdown.key.cssTitles || ''}
                  >
                    <div className="game-breakdown__content__main__key__titles__item">
                      <div className="text"></div>
                      k/d/a
                    </div>
                    <div className="game-breakdown__content__main__key__titles__item">gold</div>
                    <div className="game-breakdown__content__main__key__titles__item">towers</div>
                    <div className="game-breakdown__content__main__key__titles__item">drakes</div>
                    <div className="game-breakdown__content__main__key__titles__item">elder dragons</div>
                    <div className="game-breakdown__content__main__key__titles__item">barons</div>
                  </StyleKeyTitles>
                </div>

                <TeamStats
                  team={team200}
                  isRight={true}
                  picks={picks200 || []}
                  bans={bans200 || []}
                  statData={b.stats[team200.tricode.toLowerCase()] || {}}
                  dragons={b.dragons.teamTwo || []}
                  elderDragonCount={b.dragons.teamTwoElderDragonsCount}
                  themeName={themeName}
                  theme={theme.screens.gameBreakdown.teamStats || {}}
                  backgroundImage={this.props.data.team200StatsBackgroundImage}
                />
              </div>

              {/* SECOND ROW GRAPHS */}
              <div className="game-breakdown__content__graphs animated animated--fast">
                <div className="animated animated--fast game-breakdown__content__graphs__item">
                  <StyleGraphHeader
                    className="game-breakdown__content__graphs__item__header"
                    css={theme.screens.gameBreakdown.graph.cssHeader || ''}
                  >
                    gold difference over time
                  </StyleGraphHeader>
                  <div className="mask">
                    <StyleGraphContent
                      className="animated animated--fast game-breakdown__content__graphs__item__content game-breakdown__content__graphs__item__content--padded"
                      css={theme.screens.gameBreakdown.graph.cssContent || ''}
                    >
                      <canvas id="chart" height="250" width="790"></canvas>
                    </StyleGraphContent>
                  </div>
                </div>

                <StyleGraphKeys
                  className="animated animated--fast game-breakdown__content__graphs__key"
                  css={theme.screens.gameBreakdown.graph.cssKey || ''}
                >
                  <div className="game-breakdown__content__graphs__key__block" style={{width: 'auto'}}>
                    {team200.tricode.toUpperCase()}
                    <div style={{width: '54px', marginLeft: 'auto', marginRight: 'auto', backgroundColor:theme.screens.gameBreakdown.graph.teamRightColor? theme.screens.gameBreakdown.graph.teamRightColor: '#dd4142'}} className="game-breakdown__content__graphs__key__block__cube"></div>
                  </div>

                  <div className="game-breakdown__content__graphs__key__block" style={{width: 'auto'}}>
                    {team100.tricode.toUpperCase()}
                    <div style={{ width: '54px', marginLeft: 'auto', marginRight: 'auto', backgroundColor:theme.screens.gameBreakdown.graph.teamLeftColor? theme.screens.gameBreakdown.graph.teamLeftColor: '#4883ab'}} className="game-breakdown__content__graphs__key__block__cube game-breakdown__content__graphs__key__block__cube--blue"></div>
                  </div>
                </StyleGraphKeys>

                <div className="animated animated--fast game-breakdown__content__graphs__item game-breakdown__content__graphs__item--right">
                  <StyleGraphHeader
                    className="game-breakdown__content__graphs__item__header"
                    css={theme.screens.gameBreakdown.graph.cssHeader || ''}
                  >
                    damage dealt to champions
                  </StyleGraphHeader>
                  <div className="mask">
                    <StyleGraphContent
                      className="animated animated--fast game-breakdown__content__graphs__item__damage-cont game-breakdown__content__graphs__item__content"
                      css={theme.screens.gameBreakdown.graph.cssContent || ''}
                    >
                      <StyleDamageCount
                        className="game-breakdown__content__graphs__item__damage-cont__team game-breakdown__content__graphs__item__damage-cont__team--blue"
                        css={(theme && theme.screens.gameBreakdown.graph.damageCountBackground) || ''}
                      >
                        {[0, 1, 2, 3, 4].map((di, i) => {
                          const thisPlayer = get(this.props, `data.gameBreakdown.stats[${team100.tricode.toLowerCase()}].players[${di}]`, {});

                          return (
                            <DamageItem
                              key={i}
                              mode={this.props.data.breakdownMode}
                              player={thisPlayer.player?.split('-')[1]}
                              champ={thisPlayer.championId}
                              maxVal={
                                this.props.data.gameBreakdown.stats[team100.tricode.toLowerCase()]
                                  ? this.props.data.gameBreakdown.stats[team100.tricode.toLowerCase()].maxDamage
                                  : 0
                              }
                              score={thisPlayer ? thisPlayer.damage : 0}
                              color={get(theme, 'screens.gameBreakdown.graph.teamRightColor')}
                              textColor={get(theme, 'screens.gameBreakdown.graph.indexesColor')}
                            />
                          );
                        })}
                      </StyleDamageCount>
                      <StyleDamageCount
                        className="game-breakdown__content__graphs__item__damage-cont__team game-breakdown__content__graphs__item__damage-cont__team--red"
                        css={(theme && theme.screens.gameBreakdown.graph.damageCountBackground) || ''}
                      >
                        {[0, 1, 2, 3, 4].map((di, i) => {
                          const thisPlayer = get(this.props, `data.gameBreakdown.stats[${team200.tricode.toLowerCase()}].players[${di}]`, {});
                          return (
                            <DamageItem
                              key={i}
                              mode={this.props.data.breakdownMode}
                              player={thisPlayer.player?.split('-')[1]}
                              champ={thisPlayer.championId}
                              maxVal={
                                this.props.data.gameBreakdown.stats[team200.tricode.toLowerCase()]
                                  ? this.props.data.gameBreakdown.stats[team200.tricode.toLowerCase()].maxDamage
                                  : 0
                              }
                              score={thisPlayer ? thisPlayer.damage : 0}
                              isRed={true}
                              color={get(theme, 'screens.gameBreakdown.graph.teamLeftColor')}
                              textColor={get(theme, 'screens.gameBreakdown.graph.indexesColor')}
                            />
                          );
                        })}
                      </StyleDamageCount>
                    </StyleGraphContent>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        :
        <StyleNewBreakdownWrapper
          // className={`view-game-breakdown view-game-breakdown--${themeName} view-game-breakdown--animation-${this.state.animation} container`}
          className={`view-game-breakdown view-game-breakdown--${themeName} container`}
        >
          {/* Title Bar */}
          <StyleNewTitleContainer>
            <StyleNewTeamImage
              src={this.props.data.team100.images.postgameLeft}
            />
            <StyleTitleText>
              
              <StyleTitleTeam win={b.stats[team100.tricode.toLowerCase()]?.win || false}>
                {team100.tricode} {b.stats[team100.tricode.toLowerCase()]?.win ? 'WIN' : 'LOSE'}
              </StyleTitleTeam>
              <StyleTitleGameTimeWrapper>
                <StyleTitleGameTimeText>
                  Game Time
                </StyleTitleGameTimeText>
                <StyleTitleGameTime>
                  {b.gameTime}
                </StyleTitleGameTime>
              </StyleTitleGameTimeWrapper>
              <StyleTitleTeam win={b.stats[team200.tricode.toLowerCase()]?.win || false}>
                {team200.tricode} {b.stats[team200.tricode.toLowerCase()]?.win ? 'WIN' : 'LOSE'}
              </StyleTitleTeam>
            </StyleTitleText>
            <StyleNewTeamImage
              src={this.props.data.team200.images.postgameRight}
            />
          </StyleNewTitleContainer>

          <StyleNewStatsContainer>
            {/* Left Side Stats */}
            <StyleNewStatsColumn>
              {/* Game Stats Title */}
              <StyleNewStatsRow>
                <StyleNewGameStatsTitle>
                  <StyleNewGameStatsTeamLogo
                    src={team100.images.logoFullColor}
                  />
                  <StyleNewStatsText>
                    Game Stats
                  </StyleNewStatsText>
                  <StyleNewGameStatsTeamLogo
                    src={team200.images.logoFullColor}
                  />
                </StyleNewGameStatsTitle>
              </StyleNewStatsRow>

              {/* KDA */}
              <StyleNewStatsRow>
                <StyleNewStatsNumber>
                  {`${b.stats[team100.tricode.toLowerCase()]?.kills || "0"}/${b.stats[team100.tricode.toLowerCase()]?.deaths || "0"}/${b.stats[team100.tricode.toLowerCase()]?.assists || "0"}`}
                </StyleNewStatsNumber>
                <StyleNewStatsText>
                  K/D/A
                </StyleNewStatsText>
                <StyleNewStatsNumber>
                  {`${b.stats[team200.tricode.toLowerCase()]?.kills || "0"}/${b.stats[team200.tricode.toLowerCase()]?.deaths || "0"}/${b.stats[team200.tricode.toLowerCase()]?.assists || "0"}`}
                </StyleNewStatsNumber>
              </StyleNewStatsRow>

              {/* Gold */}
              <StyleNewStatsRow>
                <StyleNewStatsNumber>
                  {((b.stats[team100.tricode.toLowerCase()]?.gold || 0)/1000).toFixed(1)}K
                </StyleNewStatsNumber>
                <StyleNewStatsText>
                  Gold
                </StyleNewStatsText>
                <StyleNewStatsNumber>
                  {((b.stats[team200.tricode.toLowerCase()]?.gold || 0)/1000).toFixed(1)}K
                </StyleNewStatsNumber>
              </StyleNewStatsRow>

              {/* Towers */}
              <StyleNewStatsRow>
                <StyleNewStatsNumber>
                  {b.stats[team100.tricode.toLowerCase()]?.towerKills || "0"}
                </StyleNewStatsNumber>
                <StyleNewStatsText>
                  Towers
                </StyleNewStatsText>
                <StyleNewStatsNumber>
                  {b.stats[team200.tricode.toLowerCase()]?.towerKills || "0"}
                </StyleNewStatsNumber>
              </StyleNewStatsRow>

              {/* Drakes */}
              <StyleNewStatsRow withImages>
                <StyleNewStatsImageWrapper>
                  {b.dragons.teamOne && b.dragons.teamOne.map((d, i) => <StyleNewStatsDrakeImage key={i} src={DrakeTokensNew.token[d.toTitleCase()]} />)}
                </StyleNewStatsImageWrapper>
                <StyleNewStatsText>
                  Drakes
                </StyleNewStatsText>
                <StyleNewStatsImageWrapper style={{marginLeft: 'auto'}}>
                  {b.dragons.teamTwo && b.dragons.teamTwo.map((d, i) => <StyleNewStatsDrakeImage key={i} src={DrakeTokensNew.token[d.toTitleCase()]} />)}
                </StyleNewStatsImageWrapper>
              </StyleNewStatsRow>

              {/* Elder Dragons */}
              <StyleNewStatsRow>
                <StyleNewStatsNumber>
                  {b.dragons.teamOneElderDragonsCount}
                </StyleNewStatsNumber>
                <StyleNewStatsText>
                  Elder Dragons
                </StyleNewStatsText>
                <StyleNewStatsNumber>
                  {b.dragons.teamTwoElderDragonsCount}
                </StyleNewStatsNumber>
              </StyleNewStatsRow>

              {/* Barons */}
              <StyleNewStatsRow>
                <StyleNewStatsNumber>
                  {b.stats[team100.tricode.toLowerCase()]?.baronKills || "0"}
                </StyleNewStatsNumber>
                <StyleNewStatsText>
                  Barons
                </StyleNewStatsText>
                <StyleNewStatsNumber>
                  {b.stats[team200.tricode.toLowerCase()]?.baronKills || "0"}
                </StyleNewStatsNumber>
              </StyleNewStatsRow>

              {/* Bans */}
              <StyleNewStatsRow withImages>
                <StyleNewStatsImageWrapper>
                  {bans100.map((b, i) => {
                    return (
                      <StyleNewStatsBanImage key={i} src={loadImageUrlChampSquare(b)} />
                    )
                  })}
                </StyleNewStatsImageWrapper>
                <StyleNewStatsText>
                  Bans
                </StyleNewStatsText>
                <StyleNewStatsImageWrapper style={{marginLeft: 'auto'}}>
                  {bans200.map((b, i) => {
                    return (
                      <StyleNewStatsBanImage key={i} src={loadImageUrlChampSquare(b)} />
                    )
                  })}
                </StyleNewStatsImageWrapper>
              </StyleNewStatsRow>
            </StyleNewStatsColumn>

            {/* Right Side */}
            <StyleNewStatsColumn>
              {/* Damage Dealt */}
              <StyleNewStatsDamageDealtTitle>
                <StyleNewStatsTeamContainer>
                  <StyleNewStatsTeamColor color={team100.color.main}></StyleNewStatsTeamColor>
                  <StyleNewStatsTeamTricode>{team100.tricode.toUpperCase()}</StyleNewStatsTeamTricode>
                </StyleNewStatsTeamContainer>
                <StyleNewStatsText style={{marginTop: '2px', textAlign: 'center'}}>
                  Total Damage Dealt
                </StyleNewStatsText>
                <StyleNewStatsTeamContainer rightSide>
                  <StyleNewStatsTeamTricode rightSide>{team200.tricode.toUpperCase()}</StyleNewStatsTeamTricode>
                  <StyleNewStatsTeamColor color={team200.color.main}></StyleNewStatsTeamColor>
                </StyleNewStatsTeamContainer>
              </StyleNewStatsDamageDealtTitle>

              <StyleNewStatsDamageSection>
                <StyleNewStatsDamageTeam>
                  {[0, 1, 2, 3, 4].map((di, i) => {
                    const thisPlayer = get(this.props, `data.gameBreakdown.stats[${team100.tricode.toLowerCase()}].players[${di}]`, {});
                    return (
                      <DamageItem
                        key={i}
                        mode={this.props.data.breakdownMode}
                        player={thisPlayer.player?.split('-')[1]}
                        champ={thisPlayer.championId}
                        maxVal={
                          this.props.data.gameBreakdown.stats[team100.tricode.toLowerCase()]
                            ? this.props.data.gameBreakdown.stats[team100.tricode.toLowerCase()].maxDamage
                            : 0
                        }
                        score={thisPlayer ? thisPlayer.damage : 0}
                        color={team100.color.main}
                        textColor={get(theme, 'screens.gameBreakdown.graph.indexesColor')}
                      />
                    );
                  })}
                </StyleNewStatsDamageTeam>
                <StyleNewStatsDamageTeam>
                  {[0, 1, 2, 3, 4].map((di, i) => {
                    const thisPlayer = get(this.props, `data.gameBreakdown.stats[${team200.tricode.toLowerCase()}].players[${di}]`, {});
                    return (
                      <DamageItem
                        key={i}
                        mode={this.props.data.breakdownMode}
                        player={thisPlayer.player?.split('-')[1]}
                        champ={thisPlayer.championId}
                        maxVal={
                          this.props.data.gameBreakdown.stats[team200.tricode.toLowerCase()]
                            ? this.props.data.gameBreakdown.stats[team200.tricode.toLowerCase()].maxDamage
                            : 0
                        }
                        score={thisPlayer ? thisPlayer.damage : 0}
                        color={team200.color.main}
                        textColor={get(theme, 'screens.gameBreakdown.graph.indexesColor')}
                        rightSide={true}
                      />
                    );
                  })}
                </StyleNewStatsDamageTeam>
              </StyleNewStatsDamageSection>

              <StyleNewStatsGoldDiff>
                <StyleNewStatsTeamContainer graph>
                  <StyleNewStatsTeamColor color={team100.color.main} small></StyleNewStatsTeamColor>
                  <StyleNewStatsText small>{team100.name}</StyleNewStatsText>
                </StyleNewStatsTeamContainer>

                <StyleNewStatsGraph>
                  <canvas id="chart" height="222" width="817"></canvas>
                </StyleNewStatsGraph>


                <StyleNewStatsTeamContainer graph>
                  <StyleNewStatsTeamColor color={team200.color.main} small></StyleNewStatsTeamColor>
                  <StyleNewStatsText small>{team200.name}</StyleNewStatsText>
                </StyleNewStatsTeamContainer>
              </StyleNewStatsGoldDiff>


            </StyleNewStatsColumn>
          </StyleNewStatsContainer>
        </StyleNewBreakdownWrapper>
      }
    </>);
  }
}

GameBreakdown.contextType = ThemeContext;

export default GameBreakdown;
