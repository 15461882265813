import styled from 'styled-components';

export const StyleGridRow = styled.div`
    display: grid;
    grid-template-columns: 3fr 1fr 3fr;
    opacity: ${props => props.disable ? '0.3': '1'};
    pointer-events: ${props => props.disable ? 'none' : 'all'};
`;

export const StyleTeamName = styled.div`
    text-align: center;
    font-size: 20px;
    padding: 3px 0;
`;

export const StylePositionLogo = styled.div`
    display: flex;
    justify-content: center;
    img{
        width: 30px;
    }
`;

export const StyleSelect = styled.div`
    margin: 0 5px;
    border: ${props => props.isChangePending? '2px red solid': 'none'};
`;

export const StyleTipPreview = styled.img`
    width: 100%;
`;
