import _ from 'lodash';
import { send } from '../services/api';
import { doHttpsGet } from './request';
// import { firebaseRealtimeDbSetValue, firebaseRealtimeGetValue } from './firebase';
// import { getTheme } from '../components/View/themes';
import { JSON_location } from '../util/const';

// TODO: Way to figure out if prod or not
const isProd = process.env.NODE_ENV === 'production';

const apiEndpointGet = isProd ? 'https://dekki.com/api/query' : 'https://dev.dekki.com/api/query';

// const nonShowingLogoScreens = [
//     'results', 'starters', 'startersV2', 'banPick', 'gameScreen', 'matchPlayer',
//     'telops', 'keyPlayers', 'matchup', 'matchupV2', 'timer2'];

export const updateScreenStatus = async (screen, status, sendData, extraData = {}, key) => {
    // let currentTheme = await firebaseRealtimeGetValue('view/theme');
    // let theme = getTheme(currentTheme);
    // if (status === 'open') {
    //     const displayLogo = !theme.screens.cornerLogo.hideCornerLogo;
    //     if (!nonShowingLogoScreens.includes(screen) && displayLogo) {
    //         firebaseRealtimeDbSetValue('view/displayLogo', true, key);
    //     } else {
    //         firebaseRealtimeDbSetValue('view/displayLogo', false, key);
    //     }
    // }
    send('event', {
        ...extraData,
        target: `control-${screen}`,
        status: status,
        data: sendData,
        screen,
    }, key);
};

export const getTweets = (hashtags, callback) => {
    console.log('getTweets', hashtags); // eslint-disable-line no-console
    // client.get('search/tweets', { q: hashtags.join(' OR '), count: 100 }, function (error, tweets, res) {
    //     if (tweets && tweets.statuses) {
    //         const filteredTweets = tweets.statuses.filter(t => !t.retweeted_status);
    //         // io.sockets.emit('receive', { target: 'data-twitter', type: 'tweets', tweets: filteredTweets });
    //         callback({ target: 'data-twitter', type: 'tweets', tweets: filteredTweets });
    //         return;
    //     }
    //     console.error('There was a problem fetching tweets', error); // eslint-disable-line no-console
    // });

    const hashtagString = encodeURIComponent(hashtags.map(hashtag => `${hashtag.replace(/#/g, '')}`).join(','));
    doHttpsGet(`${apiEndpointGet}?resource=twitter&operation=getTweets&hashtags=${hashtagString}&code=ynymjP7kxS4nc71qSv8AJsFwWGaHlSJlVwelQyn0`).then(result => {
        callback({ target: 'data-twitter', type: 'tweets', data: result.data });
    }).catch(function (error) {
        throw error;
    });
};

export const getWinTweets = (matchup, callback) => {
    if (matchup.length < 2 || !matchup[0] || !matchup[1]) {
        console.error('Must supply 2 teams. No teams specified'); // eslint-disable-line no-console
        callback({ target: 'data-twitter', type: 'teamvotes', data: {} });
        return;
    }
    const term1 = `${matchup[0].toUpperCase()}WIN`;
    const term2 = `${matchup[1].toUpperCase()}WIN`;

    const startTime = new Date();
    startTime.setHours(startTime.getHours() - 1);

    doHttpsGet(`${apiEndpointGet}?resource=twitter&operation=getCountsTweetsSinceDate&hashtags=${encodeURIComponent(`${term1},${term2}`)}&since=${startTime.toISOString()}&code=ynymjP7kxS4nc71qSv8AJsFwWGaHlSJlVwelQyn0`).then(result => {
        callback({ target: 'data-twitter', type: 'teamvotes', data: result });
    }).catch(function (error) {
        throw error;
    });
};

export const getPlayerTweets = (hashtags, callback) => {
    if (!hashtags) return;
    const intervals = [30000, 60000, 90000, 120000, 150000, 180000];

    const hashtagString = encodeURIComponent(hashtags.map(hashtag => `${hashtag.replace(/#/g, '')}`).join(','));

    let sinceId = 0;
    doHttpsGet(`${apiEndpointGet}?resource=twitter&operation=getCountsTweetsSinceId&hashtags=${hashtagString}&code=ynymjP7kxS4nc71qSv8AJsFwWGaHlSJlVwelQyn0`).then(result => {
        sinceId = result && result.sinceId;
        if (!sinceId || sinceId === 0) {
            return;
        }
        intervals.forEach(delay => setTimeout(() => {
            doHttpsGet(`${apiEndpointGet}?resource=twitter&operation=getCountsTweetsSinceId&sinceId=${sinceId}&hashtags=${hashtagString}&code=ynymjP7kxS4nc71qSv8AJsFwWGaHlSJlVwelQyn0`).then(result => {
                callback((result && result.data) || {});
            }).catch(function (error) {
                throw error;
            });
        }, delay));
    }).catch(function (error) {
        throw error;
    });
};

export const loadLeaguesInformationFromFile = (filePathInc, callback) => {
    const selections = {};
    _.forEach(JSON_location, (leagueData, leagueKey) => {
        _.forEach(leagueData.seasons, (seasonsArray, season) => {
            if (!selections[leagueKey]) {
                selections[leagueKey] = { seasons: {}};
            }
            selections[leagueKey].seasons[season] = seasonsArray.map(seasonData => seasonData.name);
        });
    });

    console.log('EMITING', process.env.FIREBASE_KEY);
    callback({ target: 'get-json-info', data: selections, firebaseListener: process.env.FIREBASE_KEY });
}

export const loadJSONFile = async (league, season, split, callback) => {
    console.log('loadJSONFile', league, season, split);

    let response = {};
    const splitData = JSON_location[league] && JSON_location[league].seasons[season] && JSON_location[league].seasons[season].find(item => item.name === split);
    if (splitData && splitData.url) {
        const resp = await doHttpsGet(splitData.url);
        if (resp) {
            response = resp;
        }
    } else {
        console.log('No split data found for ', league, season, split);
    }

    callback({ target: 'file-load', data: response})
}
