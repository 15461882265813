// Assets ScoutingGrounds
const ScoutingGroundLogo = 'https://dekki-production.s3.ap-northeast-1.amazonaws.com/uploads/leagues/scouting_grounds_2021/ljl-2021-sgg_logo_white.svg';
const ScoutingGroundBanPickBG = 'https://cdn.dekki.com/uploads/leagues/scoutinggrounds/scoutingground-banpick-bg.png';

export default {
  id: 'scoutinggrounds',
  name: 'Scouting Grounds',
  screens: {
    animatedBackground: {
      show: true,
      defaultBackground: "https://dekki-production.s3.ap-northeast-1.amazonaws.com/uploads/broadcast-projects/scoutinggrounds/LJL-2021-SGG_BG-Loop_Full-Screen.mp4",
      disableAnimationBackGround: false,
    },
    banPick: {
      leagueLogo: ScoutingGroundLogo,
      backgroundImage: ScoutingGroundBanPickBG,
      cssTeamInfoContainer: 'height:167px;',
      cssTeamName:
        'font-family:United; font-weight: 800; text-shadow: 0px 0px; padding-top:20px; height:166px;',
      cssPatch:
        'font-family:United; font-weight: 700; color:#dba103; top:unset; margin-top:-5px; bottom:0px;',
      cssScoreBox:
        'font-family:United; font-weight: 800; color:#dba103; padding-top:15px;',
      backgroundColorCoach: '#edb20f',
      showEmptyCoachBar: false,
      showPhase: false,
      titleDisplay: 'game', // 'week' to show Week X, match to show MATCH X,...
    },
    cornerLogo: {
      hideCornerLogo: true,
    },
    matchUpV2: {
      cssProfileImg: '',
      cssName: `color: #ffffff; background: #181818; justify-content: center; padding-right: 10px; padding-left: 0px;
      p{ font-family: 'United'; font-weight: 700; font-size: 32px; line-height: 32px; padding-top: 5px; letter-spacing: 1px; opacity: 100%;}`,
      cssPosition: "background-color: #181818;",
      positionLogo: 'white',
      team100: {
        cssProfileImg: 'background-color: #181818;',
        cssName: '',
      },
      team200: {
        cssProfileImg: 'background-color: #181818;',
        cssName: '',
      },
    },
    scheduleInfoSideBar: {
      logoSrc: ScoutingGroundLogo,
      cssTimerText: "font-size: 22px; color: #edb20f; font-family: 'UnitedReg'; font-weight: 400; margin-top: 10px;",
      cssTimer: "line-height: 120px; div{ font-family: 'United'; font-weight: 800; font-size: 120px; letter-spacing: 1px;}",
      cssBackground: 'background: #181818; border-right: 15px solid #edb20f;',
      cssDayTitle: "color: #ffff; font-family: 'UnitedReg'; font-weight: 400; font-size: 30px; letter-spacing: 2px;",
      cssScheduleList: 'min-height: 600px; display: flex; flex-direction: column; justify-content: space-around; background-color: #00000044;',
      cssScheduleRow: "border-bottom: 2px solid #FFF; height: 200px; color: #ffff; div{ font-family: 'United'; font-weight: 700; font-size: 40px;} img{width: 80px; height: 80px;}",
      cssNext: "color: #edb20f; font-family: 'United'; font-weight: 700; font-size: 40px;",
      cssWinner: "font-family: 'United'; font-weight: 700; font-size: 40px;",
      cssScore: "font-family: 'United'; font-weight: 700; font-size: 40px;",
      StyleUpcomingGameContainer: "margin-left: -41px;",
      StyleUpcomingGameInfo: "padding-left: 20px;",
    },
    screenTitle: {
      cssTitle: 'font-family:United; font-weight: 800; padding-top:15px; font-size: 130px; color: #edb20f;',
      Header: `height: 350px;`,
    },
    standings: {
      showSubtext: false,
      showRank: true,
      hideHeader: false,
      cssStanding: 'padding: 230px 140px 120px 140px;',
      cssLogoCell: `background-color: #181818;position: relative;margin-left: 20px;overflow: visible; 
      :before {content: '';width: 10px; height: 100%; position: absolute;left: -10px; background-color: #dba103 !important;}`,
      cssHeaderCell: "margin-bottom: 15px; font-size:25px; color: #181818; font-family: 'UnitedReg'; font-weight: 400; div:first-of-type {color: transparent;}",
      cssScoreCell: "background-color:#181818; color: white; font-size:45px; font-family: 'UnitedReg'; font-weight: 400;",
      cssNameCell: "font-size:50px; background: #181818;font-family: 'UnitedReg'; font-weight: 800; text-transform: uppercase; padding-top: 10px; padding-left: 30px;",
      cssCell: 'background-color: transparent;',
      cssRankCell: `height:65px; color: #181818; font-size:35px; line-height: 35px; font-family: 'United'; font-weight: 800;
      flex-grow: 0; flex-basis: 45px; background-color: white; padding-top: 10px;`,
      cssHeaderRank: `flex-basis: 45px;`,
      cssTeamRowsContainer: 'background: none;',
    },
  },
};
