"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StyleSvgRoute = exports.StyleMainContainer = exports.StyleHeader = exports.StyleGameTime = exports.StyleChampionIcon = exports.StyleChampionContainer = exports.StyleCanvas = void 0;

var _styledComponents = _interopRequireDefault(require("styled-components"));

var _reactSpring = require("react-spring");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var StyleMainContainer = (0, _styledComponents.default)(_reactSpring.animated.div).withConfig({
  displayName: "styles__StyleMainContainer",
  componentId: "sc-niyffv-0"
})(["bottom:0px;right:0px;height:612px;width:610px;position:absolute;background-image:url('", "');background-size:contain;overflow:hidden;"], function (props) {
  return props.img || 'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/minimap.png';
});
exports.StyleMainContainer = StyleMainContainer;

var StyleHeader = _styledComponents.default.div.withConfig({
  displayName: "styles__StyleHeader",
  componentId: "sc-niyffv-1"
})(["width:100%;height:68px;top:0;display:flex;justify-content:center;align-items:center;font-size:45px;color:#EFEFEF;", ";"], function (props) {
  return props.StyleHeader ? props.StyleHeader : '';
});

exports.StyleHeader = StyleHeader;

var StyleGameTime = _styledComponents.default.div.withConfig({
  displayName: "styles__StyleGameTime",
  componentId: "sc-niyffv-2"
})(["position:absolute;top:80px;left:20px;font-family:\"futura-pt\",sans-serif;font-weight:700;font-size:33px;color:#efefef;transition:opacity 200ms linear;opacity:", ";text-align:left;span{font-size:16px;display:block;font-weight:400;}", ";"], function (props) {
  return props.show ? 1 : 0;
}, function (props) {
  return props.StyleGameTime ? props.StyleGameTime : '';
});

exports.StyleGameTime = StyleGameTime;

var StyleCanvas = _styledComponents.default.canvas.withConfig({
  displayName: "styles__StyleCanvas",
  componentId: "sc-niyffv-3"
})(["position:absolute;right:45px;bottom:20px;"]);

exports.StyleCanvas = StyleCanvas;

var StyleChampionContainer = _styledComponents.default.div.withConfig({
  displayName: "styles__StyleChampionContainer",
  componentId: "sc-niyffv-4"
})(["position:absolute;z-index:1;right:45px;bottom:20px;width:", "px;height:", "px;"], function (props) {
  return props.size;
}, function (props) {
  return props.size;
});

exports.StyleChampionContainer = StyleChampionContainer;

var StyleChampionIcon = _styledComponents.default.img.withConfig({
  displayName: "styles__StyleChampionIcon",
  componentId: "sc-niyffv-5"
})(["position:absolute;width:35px;height:35px;border:2px ", " solid;transition:all ", "ms linear;bottom:", "px;left:", "px;display:", ";"], function (props) {
  return props.color || 'transparent';
}, function (props) {
  return props.animationDuration;
}, function (props) {
  return props.bottom - 17 || 0;
}, function (props) {
  return props.left - 17 || 0;
}, function (props) {
  return props.alive ? 'block' : 'none';
});

exports.StyleChampionIcon = StyleChampionIcon;

var StyleSvgRoute = _styledComponents.default.svg.withConfig({
  displayName: "styles__StyleSvgRoute",
  componentId: "sc-niyffv-6"
})(["position:absolute;right:62px;bottom:38px;width:475px;height:475px;stroke-dasharray:1000;stroke-dashoffset:1000;animation:dash 10s linear;animation-delay:1500;@keyframes dash{to{stroke-dashoffset:0;}}"]);

exports.StyleSvgRoute = StyleSvgRoute;