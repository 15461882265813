import React from 'react'
import PropTypes from 'prop-types'

import positions from './../../../../storybook-built/dist/Constants/lol_positions';

import {
    StyleLayoutGamescreen,
    StyleLayoutGamescreenPosition,
    StyleLayoutGamescreenPositionButton,
} from './styles';

function PositionSelector(props) {

    const handlePlayerSelection = (side, pos) => {
        props.onSelectPosition(side, pos);
    }

    return (
        <StyleLayoutGamescreen>
        <StyleLayoutGamescreenPosition>
          <span>{"Team left"}</span>
          <div />
          <span>{"Team right"}</span>
        </StyleLayoutGamescreenPosition>
        {positions.map(pos =>
        <StyleLayoutGamescreenPosition key={pos}>
          <StyleLayoutGamescreenPositionButton
             isSelected={(props.sideSelection === 'team100') && (props.positionSelection === pos)}
             onClick={() => {handlePlayerSelection('team100', pos)}}
             title={pos}
          >
            <img
              src={`https://cdn.dekki.com/assets/broadcast/games/league-of-legends/positions/black/${pos}.svg`}
              alt={`team100-${pos}`}
            />
          </StyleLayoutGamescreenPositionButton>
          <div/>
          <StyleLayoutGamescreenPositionButton
            isSelected={(props.sideSelection === 'team200') && (props.positionSelection === pos)}
            onClick={() => {handlePlayerSelection('team200', pos)}}
            title={pos}
          >
            <img
              src={`https://cdn.dekki.com/assets/broadcast/games/league-of-legends/positions/black/${pos}.svg`}
              alt={`team200-${pos}`}
            />
          </StyleLayoutGamescreenPositionButton>
        </StyleLayoutGamescreenPosition>
        )}
      </StyleLayoutGamescreen>
    )
}

PositionSelector.propTypes = {
    onSelectPosition: PropTypes.func,
    sideSelection: PropTypes.string,
    positionSelection: PropTypes.string,
}

export default PositionSelector

