// REACT
import React, { useState, useEffect } from 'react';
// import { isEqual, get, cloneDeep } from 'lodash';
// internal
import { checkLang } from '../../i18n';
import {
  StyleMythicItemContainer,
} from './styles';
import Select from 'react-select';
import { getCreatableStyles } from '../DataMatch/DataMatchLineup';
import DataContainer from '../DataContainer';
import { getInfoboxItems } from '../../../services/itemService';

const DataMythicItemInfobox = () => {

  const [ mythicItemsState, setMythicItemsState ] = useState([]);
  const [ currentSelectionState, setCurrentSelectionState ] = useState({label: ' - ', value: ''})


  useEffect(() => {
    setMythicItemsState(getInfoboxItems().map(item => ({
      value: item.infoboxAsset,
      label: (checkLang() === 'en')? item.englishName : item.name,
    })))
  }, [])

  const handleInfoboxSelection = (selection) => {
    setCurrentSelectionState(selection);
    window.MainData.mythicItemInfobox = { url : selection.value};
  }

  return (
    <DataContainer componentSlug="mythicItemInfobox" icon="far fa-tag" style={{ overflow: 'visible' }}>
      <div className="data-panel__fieldset" style={{ paddingTop: '10px', paddingBottom: '0' }}>
        <StyleMythicItemContainer>
        <Select
          styles={getCreatableStyles('300px')}
          options={[...mythicItemsState, {value: '', label: ' - '}]}
          value={currentSelectionState}
          onChange={(e)=>{
              handleInfoboxSelection(e);
          }}
          defaultValue={{value: '', label: ' - '}}
          isSearchable
        />
        {(currentSelectionState.value && <img src={currentSelectionState.value} alt={'MythicItem Infobox Selection'} />) || null}
        </StyleMythicItemContainer>
      </div>
    </DataContainer>
  );
};

export default DataMythicItemInfobox;
