export const operationsAdmins = [
    // Original Old
    '5a24aa40f917b97c4b790369', // stevenm+dev@playbrain.jp
    '5a15376bf391697c5b5c4d71', // stevenm@playbrain.jp
    '5a2119654428a207507844a7', // stevenm+prod@playbrain.jp
    '58954f82f7b5312a57f81e19', // official@dekki.com
    '5864c0965edf7a5098f6d33d', // mike@playbrain.jp
    '5c36b1337e5da976e1eb8a6e', // operations@playbrain.jp,
    '605800e268e8c40071ee620c', // Shaun
    '629da2fdb5b7c60068e4a399', // Gouki
    // Prod
    '624a8f2e4b4da100698f0007', // stevenm@playbrain.jp
    '624a8f2404357f006aa009e1', // mike@playbrain.jp
    '624a8f1bc564e0006988e0ca', // operations@playbrain.jp,
    '624a8f22aa9000006f20905d', // shaun@playbrain.jp
    '626f6f1be7dd8a006868d735', // yusuke.shimoda@playbrain.com
    '624a8f1f4eb9ff0069111a14', // roy@playbrain.jp
    '629da2fdb5b7c60068e4a399', // Gouki
    // Dev/Staging
    '624a8f83c323e5006a4fd446', // stevenm@playbrain.jp
    '624a8f7afd7c6500708b54e4', // mike@playbrain.jp
    '624a8f6fc323e5006a4fd435', // operations@playbrain.jp,
    '624a8f785277e10070b66908', // shaun@playbrain.jp
    '6279d709c51062006709652e', // yusuke.shimoda@playbrain.com
    '624a8f74f1cdcb007070accc', // roy@playbrain.jp
    '629da50ce6dfba006f7b6e45', // Gouki
];

export const getEnvironment = () => {
    return process.env.REACT_APP_ENVIRONMENT || 'local';
};
export const isLocal = () => {
    return getEnvironment() === 'local';
}