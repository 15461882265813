// REACT
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ThemeContext } from '../View/themes';

import Timer2 from '../../storybook-built/dist/components/Games/LJL/Timer2';
class Timer2Container extends Component {

    state = {

    };

    static propTypes = {
        active: PropTypes.bool,
        data: PropTypes.object,
    }

    render() {
        let theme = this.context;
        return (
            <Timer2
                showTimer={this.props.active}
                theme={(theme.screens && theme.screens.timer2) || {}}
                targetTime={this.props.data.timer.targetTime}
                timerText={this.props.data.timer.timerText}
            />
        );
    }
}

Timer2Container.contextType = ThemeContext;

export default Timer2Container
