import React, { useState, useEffect } from 'react';
import { get } from 'lodash';
// INTERNAL
import { updateScreenStatus } from '../../../util/controllerUtils';
import { subscribe } from '../../../services/api';
import { i18n } from '../../i18n';
// import { getCreatableStyles } from '../../Data/DataMatch/DataMatchLineup';
import ControlContainer from '../../Controller/ControlContainer';
import Button from '../../Button';
// import Select from 'react-select';
import PropTypes from 'prop-types';

const DraftOrderControl = (props) => {
  const [isPlayState, setIsPlayState] = useState('close');
  const [isShowState, setShowState] = useState(false);

  const [currentDraftState, setCurrentDraftState] = useState();

  const onPlay = () => {
      let newDraft = getDraft();
      if(newDraft){
        updateScreenStatus('draftOrder', 'open', { draftOrder: newDraft, draftOrderVisible: false }, null, props.cgpc);
      }
  };

  // const checkDraftData = (draft) => {
  //   return draft && draft.team100 && draft.team100.roster && draft.team100.bans && draft.team200 && draft.team200.roster && draft.team100.bans;
  // }

  const onClose = async () => {
    setIsPlayState('close');
    setShowState(false);
    setCurrentDraftState();
    await updateScreenStatus('draftOrder', 'close', null, null, props.cgpc);
  };

  const onShow = () => {
      setShowState(true);
      updateScreenStatus('draftOrder', 'open', { draftOrder: currentDraftState, draftOrderVisible: true}, null, props.cgpc);
  };

  const onHide = () => {
    setShowState(false);
    updateScreenStatus('draftOrder', 'open', { draftOrder: currentDraftState, draftOrderVisible: false }, null, props.cgpc);
  };

  const getDraft = () => {
    let currentGame;
    const currentDraft = get(window.MainData, `draftOrder`);
    if(currentDraft){
      currentGame = get(window.MainData, `schedule.rounds[${currentDraft.round}].matches[${currentDraft.match}].games[${currentDraft.game}]`);
    }
    else{
      setIsPlayState('invalid')
      return;
    }
    const draft = {
      winner : get(currentGame, 'winner'),
      gameTitle: currentDraft.title,
    };

    if(currentDraft && currentDraft.team100 && currentDraft.team200 &&
      currentDraft.team100.roster && currentDraft.team200.roster &&
      currentDraft.team100.bans && currentDraft.team200.bans){
      for(const teamTricode in currentGame.teams){
        draft[`team${currentGame.teams[teamTricode].side}`] = {
          tricode: teamTricode,
          icon: get(window, `MainData.teams[${teamTricode}].images.logoFullColor`,''),
          roster: {
            ...currentDraft[`team${currentGame.teams[teamTricode].side}`].roster,
          },
          bans: [
            ...currentDraft[`team${currentGame.teams[teamTricode].side}`].bans,
          ],
        }
      }
    }
    setCurrentDraftState(draft);
    return draft;
  }

  useEffect(() => {
    subscribe('control-draftOrder', null, response => {
      setIsPlayState(response.status);
      if (response.data) {
        setShowState(response.data.draftOrderVisible);
      };
    }, props.cgpc);
  }, []);

  return (
    <ControlContainer style={{display: props.display ? 'none': 'block', overflow: 'visible'}} status={isPlayState} >
      <div className="control__panel">
        <h2 className="control__title">
          <i className="fas fa-history"></i> {i18n().pages.draftOrder}
        </h2>
        <Button
        controllerSide={!props.screen1}
          action={onPlay}
          text={i18n().general.open}
          icon="fas fa-play-circle"
          disabled={isPlayState === 'open' && true}
        />
        <Button
        controllerSide={!props.screen1}
          action={onClose}
          text={i18n().general.close}
          icon="fas fa-stop-circle"
          disabled={!(isPlayState === 'open') && true}
        />
      </div>

      {isPlayState === 'open' && (
        <div className={'control-extra'}>
          <div className={'control-extra__fieldset'}>
            <div className="pill">
              {isShowState
                ? [
                    <span key="1a" className="pill__span--highlighted">
                      {i18n().controller.viewable.true}
                    </span>,
                    <Button controllerSide={!props.screen1} key="1b" text="Hide" action={onHide} />,
                  ]
                : [
                    <span key="2a">{i18n().controller.viewable.false}</span>,
                    <Button controllerSide={!props.screen1} key="2b" text="Show" action={onShow} />,
                  ]}
            </div>
          </div>
        </div>
      )}
    </ControlContainer>
  );
};

DraftOrderControl.propTypes = {
  cgpc: PropTypes.string,
  display: PropTypes.bool,
  screen1: PropTypes.bool,
};

export default DraftOrderControl;
