import moment from 'moment';

const getCountdownUntil = targetTime => {
    if (targetTime === 0) return;

    if (moment() > moment(targetTime)) return 0;

    const until = Math.abs((moment().diff(moment(targetTime)) / 1000));
    let minutes = Math.floor(until / 60);
    let seconds = Math.floor(until % 60);

    minutes = minutes < 10 ? `0${minutes}` : minutes;
    seconds = seconds < 10 ? `0${seconds}` : seconds;

    return `${minutes}:${seconds}`;
}

export {
    getCountdownUntil,
}
