"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.TeamName = exports.StyledVSTime = exports.StyledLogoImg = exports.StyleVersusDetailContainer = exports.StyleVS = exports.StyleTriCodeName = exports.StyleTime = exports.StyleTeamDetailContainer = exports.StyleScoreSpan = exports.StyleScoreDetailContainer = exports.StyleScoreDetail = exports.StyleScoreDash = exports.StyleRowDetailContainer = exports.StyleRowContainer = exports.StyleName = exports.StyleLogoWrapper = exports.StyleLogoContainer = exports.StyleLittleScore = exports.StyleGradientBar = exports.StyleGameDetail = exports.StyleDay = exports.StyleDate = exports.StyleContainer = exports.StyleBorders = exports.StyleBase = exports.SpanVS = exports.H1VS = void 0;

var _styledComponents = _interopRequireWildcard(require("styled-components"));

var _reactPose = _interopRequireDefault(require("react-pose"));

var _styledTools = require("styled-tools");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }

function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

var StyleLogoWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__StyleLogoWrapper",
  componentId: "sc-6n2kuj-0"
})(["display:flex;align-items:center;height:100%;width:30%;overflow:hidden;margin-right:-2px;z-index:2;border-right:", ";border-left:", ";border-right:", ";border-left:", ";", " ", ";", ";", ""], function (props) {
  return props.borderRight && "5px solid ".concat(props.borderRight);
}, function (props) {
  return props.borderLeft && "5px solid ".concat(props.borderLeft);
}, function (props) {
  return props.loserLeft && props.borderRight && "5px solid ".concat(props.borderRight, "4D");
}, function (props) {
  return props.loserRight && props.borderLeft && "5px solid ".concat(props.borderLeft, "4D");
}, function (props) {
  return props.cssLogo;
}, function (props) {
  return props.loserLeft && props.cssLogoLoserLeft ? props.cssLogoLoserLeft : '';
}, function (props) {
  return props.loserRight && props.cssLogoLoserRight ? props.cssLogoLoserRight : '';
}, function (props) {
  return props.cssAdjust;
});

exports.StyleLogoWrapper = StyleLogoWrapper;

var StyledLogoImg = _styledComponents.default.img.withConfig({
  displayName: "styles__StyledLogoImg",
  componentId: "sc-6n2kuj-1"
})(["width:100%;height:100%;", ""], function (props) {
  return props.StyledLogoImg || '';
});

exports.StyledLogoImg = StyledLogoImg;

var StyleTriCodeName = _styledComponents.default.div.withConfig({
  displayName: "styles__StyleTriCodeName",
  componentId: "sc-6n2kuj-2"
})(["", " ", ""], function (props) {
  return props.StyleTriCodeName || '';
}, function (props) {
  return props.cssAdjust;
});

exports.StyleTriCodeName = StyleTriCodeName;

var StyleLittleScore = _styledComponents.default.div.withConfig({
  displayName: "styles__StyleLittleScore",
  componentId: "sc-6n2kuj-3"
})(["", " ", ""], function (props) {
  return props.StyleLittleScore || '';
}, function (props) {
  return props.cssAdjust;
});

exports.StyleLittleScore = StyleLittleScore;

var StyleVS = _styledComponents.default.div.withConfig({
  displayName: "styles__StyleVS",
  componentId: "sc-6n2kuj-4"
})(["", ""], function (props) {
  return props.StyleVS || '';
});

exports.StyleVS = StyleVS;

var StyledVSTime = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledVSTime",
  componentId: "sc-6n2kuj-5"
})(["", " ", " ", ""], function (props) {
  return props.StyledVSTime || '';
}, function (props) {
  return props.cssVersusNext || '';
}, function (props) {
  return props.cssAdjust;
});

exports.StyledVSTime = StyledVSTime;

var TeamName = _styledComponents.default.p.withConfig({
  displayName: "styles__TeamName",
  componentId: "sc-6n2kuj-6"
})(["font-family:dharma-gothic-e-bold-italic;", ";", ";", ";"], function (props) {
  return props.oneMatch ? "\n    font-size: 64px;\n    letter-spacing: 0.64px;\n    color: #FFFFF;\n    margin-top: 60px;\n  " : "\n    font-size: 120px;\n    color: #F5F5F5;\n    margin-top: 16px;\n  ";
}, function (props) {
  return props.cssVS || '';
}, function (props) {
  return props.cssVSBo5 || '';
});

exports.TeamName = TeamName;

var SpanVS = _styledComponents.default.span.withConfig({
  displayName: "styles__SpanVS",
  componentId: "sc-6n2kuj-7"
})(["font-family:aktiv-grotesk-medium;font-weight:500;color:#ffffff;", " ", ""], function (props) {
  return props.oneMatch ? "\n        font-size: 41px;\n        letter-spacing: 1.23px;\n    " : "\n        font-size: 30px;\n        margin-top: 10px;\n    ";
}, function (props) {
  return props.cssVS || '';
});

exports.SpanVS = SpanVS;

var H1VS = _styledComponents.default.h1.withConfig({
  displayName: "styles__H1VS",
  componentId: "sc-6n2kuj-8"
})(["", " font-family:dharma-gothic-e-bold,sans-serif;font-weight:700;color:#F5F5F5;", ""], function (props) {
  return props.oneMatch ? "\n    font-size: 180px;\n    line-height: 150px;\n    padding: 34px 0 25px 0;\n  " : "\n    font-size: 120px;\n    line-height: 100px;\n    padding: 25px 0 6px 0;\n  ";
}, function (props) {
  return props.cssVS || '';
});

exports.H1VS = H1VS;

var StyleBase = _styledComponents.default.div.withConfig({
  displayName: "styles__StyleBase",
  componentId: "sc-6n2kuj-9"
})(["position:relative;color:white;font-weight:100;align-items:center;font-family:inherit;font-size:40px;letter-spacing:2px;", " ", ""], function (props) {
  return !props.isDetailed ? "\n        overflow: hidden;\n        margin-top: 20px;\n        width: 100%;\n        display: flex;\n        flex-direction: row;\n        justify-content: center;\n        height: 860px;\n    " : "\n        width: 1540px;\n        margin-top: 82px;\n        margin-left: auto;\n        margin-right: auto;\n    ";
}, function (props) {
  return props.cssVS || '';
});

exports.StyleBase = StyleBase;

var ContainerAnimation = _reactPose.default.div({
  show: {
    y: function y(_ref) {
      var resize = _ref.resize;
      return resize ? -75 : 0;
    },
    scale: function scale(_ref2) {
      var resize = _ref2.resize;
      return resize ? 0.8 : 1;
    },
    transition: function transition(_ref3) {
      var speedPanel = _ref3.speedPanel;
      return {
        duration: speedPanel
      };
    },
    staggerChildren: function staggerChildren(_ref4) {
      var speedPanel = _ref4.speedPanel;
      return speedPanel;
    },
    staggerDirection: 1
  },
  hide: {
    y: function y(_ref5) {
      var resize = _ref5.resize;
      return resize ? -75 : 0;
    },
    scale: function scale(_ref6) {
      var resize = _ref6.resize;
      return resize ? 0.8 : 1;
    },
    transition: function transition(_ref7) {
      var speedPanel = _ref7.speedPanel;
      return {
        duration: speedPanel
      };
    },
    staggerChildren: function staggerChildren(_ref8) {
      var speedPanel = _ref8.speedPanel;
      return speedPanel;
    },
    staggerDirection: -1
  }
});

var StyleContainer = (0, _styledComponents.default)(ContainerAnimation).withConfig({
  displayName: "styles__StyleContainer",
  componentId: "sc-6n2kuj-10"
})(["position:relative;", " ", ""], function (props) {
  return !props.isDetailed ? "\n        display: flex;\n        flex-direction: column;\n        align-items: center;\n        justify-content: center;\n        width: 80%;\n        height: 100%;\n    " : '';
}, function (props) {
  return props.cssVS || '';
});
exports.StyleContainer = StyleContainer;

var RowContainerAnimation = _reactPose.default.div({
  initialPose: 'hide',
  hide: {
    y: -200,
    z: 1,
    scale: 1,
    opacity: 0,
    transition: {
      duration: 400
    },
    applyAtStart: {
      scale: 1
    }
  },
  show: {
    y: 0,
    z: 1,
    // scale: ({nextMatch}) => nextMatch ? 1.03 : 1,
    opacity: 1,
    transition: {
      duration: 400
    },
    delay: 100,
    delayChildren: 400
  }
});

var StyleRowContainer = (0, _styledComponents.default)(RowContainerAnimation).withConfig({
  displayName: "styles__StyleRowContainer",
  componentId: "sc-6n2kuj-11"
})(["position:relative;display:flex;flex-direction:row;justify-content:center;align-items:flex-start;margin-bottom:10px;z-index:", ";width:100%;flex:1 1 110px;max-height:160px;height:0%;background:", ";text-transform:uppercase;overflow:'visible';overflow:", ";", ";", ";", " ", " ", ";", ";", ";", ";", ";"], function (props) {
  return props.nextMatch ? 30 : 2;
}, function (props) {
  return props.nextMatch ? 'linear-gradient(0deg, #540035 0%, #f00020 100%)' : '';
}, function (props) {
  return props.nextMatch ? 'visible' : 'hidden';
}, function (props) {
  return props.team100Color ? "border-left: 10px solid ".concat(props.team100Color, ";") : '';
}, function (props) {
  return props.team200Color ? "border-right: 10px solid ".concat(props.team200Color, ";") : '';
}, function (props) {
  return props.cssRow;
}, function (props) {
  return props.cssAdjust;
}, function (props) {
  return props.changeWinnerBorder && props.cssWinner === 'right' && props.changeWinnerBorderColor ? props.changeWinnerBorderColor : '';
}, function (props) {
  return props.changeWinnerBorder && props.cssWinner === 'left' && props.changeWinnerBorderColor ? props.changeWinnerBorderColor : '';
}, function (props) {
  return props.changeWinnerBorder && props.cssWinner === 'right' && props.changeWinnerBorderColor ? "border-width: 0px 10px 0px 0px;" : '';
}, function (props) {
  return props.changeWinnerBorder && props.cssWinner === 'left' && props.changeWinnerBorderColor ? "border-width: 0px 0px 0px 10px;" : '';
}, (0, _styledTools.ifProp)("skip", (0, _styledComponents.css)(["opacity:0.3;color:#999DA0;background-color:lightgray;"])));
exports.StyleRowContainer = StyleRowContainer;

var StyleDate = _styledComponents.default.div.withConfig({
  displayName: "styles__StyleDate",
  componentId: "sc-6n2kuj-12"
})(["position:relative;flex:1 1 100px;align-items:center;justify-content:center;display:flex;flex-direction:column;height:100%;text-transform:uppercase;font-size:25px;line-height:35px;font-size:35px;z-index:4;padding-left:", ";", " ", " ", ";"], function (props) {
  return props.nextMatch ? '20px' : '0px';
}, function (props) {
  return props.cssDate;
}, function (props) {
  return props.cssAdjust;
}, (0, _styledTools.ifProp)("skip", (0, _styledComponents.css)(["opacity:0.3;color:#999DA0;"])));

exports.StyleDate = StyleDate;

var StyleDay = _styledComponents.default.span.withConfig({
  displayName: "styles__StyleDay",
  componentId: "sc-6n2kuj-13"
})(["z-index:4;"]);

exports.StyleDay = StyleDay;

var StyleTime = _styledComponents.default.span.withConfig({
  displayName: "styles__StyleTime",
  componentId: "sc-6n2kuj-14"
})(["z-index:4;", ""], function (props) {
  return props.StyleTime || '';
});

exports.StyleTime = StyleTime;

var StyleName = _styledComponents.default.div.withConfig({
  displayName: "styles__StyleName",
  componentId: "sc-6n2kuj-15"
})(["flex:5;display:flex;justify-content:", ";text-align:", ";align-items:center;padding-left:", ";padding-right:", ";height:100%;", ";", ";", ";", ";", ";"], function (props) {
  return props.position === 'left' ? 'flex-start' : 'flex-end';
}, function (props) {
  return props.position === 'left' ? 'left' : 'right';
}, function (props) {
  return props.position === 'left' ? '20px' : 'none';
}, function (props) {
  return props.position === 'right' ? '20px' : 'none';
}, function (props) {
  return props.cssName;
}, function (props) {
  return props.cssAdjust;
}, function (props) {
  return props.position === 'left' && props.StyleNameLeftAdjust ? props.StyleNameLeftAdjust : '';
}, function (props) {
  return props.position === 'right' && props.StyleNameRightAdjust ? props.StyleNameRightAdjust : '';
}, (0, _styledTools.ifProp)("skip", (0, _styledComponents.css)(["opacity:0.3;color:#999DA0;"])));

exports.StyleName = StyleName;

var StyleLogoContainer = _styledComponents.default.div.withConfig({
  displayName: "styles__StyleLogoContainer",
  componentId: "sc-6n2kuj-16"
})(["display:flex;align-items:center;height:100%;width:12.3%;overflow:hidden;margin-right:-2px;z-index:2;padding-right:", ";border-right:", ";border-left:", ";", " ", " ", ";img{opacity:", ";padding:'0px';max-width:100%;max-height:80%;margin:auto;}"], function (props) {
  return props.nextMatch && props.last ? '20px' : '0px';
}, function (props) {
  return props.borderRight && "8px solid ".concat(props.borderRight);
}, function (props) {
  return props.borderLeft && "8px solid ".concat(props.borderLeft);
}, function (props) {
  return props.cssLogo;
}, function (props) {
  return props.cssAdjust;
}, (0, _styledTools.ifProp)("skip", (0, _styledComponents.css)(["opacity:0.5;"])), function (props) {
  return props.isLoser ? '0.3' : '1';
});

exports.StyleLogoContainer = StyleLogoContainer;

var BordersAnimation = _reactPose.default.div({
  initialPose: 'hide',
  showNext: {
    width: '100%',
    x: '-50%',
    opacity: 1,
    transition: {
      duration: 400
    }
  },
  hide: {
    width: '0%',
    x: '-50%',
    opacity: 0,
    transition: {
      duration: 200
    }
  }
});

var StyleBorders = (0, _styledComponents.default)(BordersAnimation).withConfig({
  displayName: "styles__StyleBorders",
  componentId: "sc-6n2kuj-17"
})(["position:absolute;left:50%;top:0;height:100%;border:10px solid ", ";z-index:100;", ""], function (props) {
  return props.trimColor || '#ffffff';
}, function (props) {
  return props.cssBorders;
});
exports.StyleBorders = StyleBorders;
var StyleRowDetailContainer = (0, _styledComponents.default)(RowContainerAnimation).withConfig({
  displayName: "styles__StyleRowDetailContainer",
  componentId: "sc-6n2kuj-18"
})(["top:0;position:relative;width:100%;height:", ";align-items:flex-start;margin-bottom:0px;z-index:", ";text-transform:uppercase;overflow:visible;background-color:#000220;", ";", " &::after{height:10px;width:100%;background:linear-gradient(to right,#E10600,#730041);display:block;content:\"\";grid-column-start:1;grid-column-end:15;position:absolute;bottom:-10px;}", ""], function (props) {
  return props.nextMatch ? '105%' : '100%';
}, function (props) {
  return props.nextMatch ? 30 : 2;
}, (0, _styledTools.ifProp)("skip", (0, _styledComponents.css)(["opacity:0.3;color:#999DA0;background-color:lightgray;"])), function (props) {
  return props.oneMatch ? "\n        height: 670px;\n        display: flex;\n    " : "\n        display: grid;\n        grid-template-columns: repeat(14, 1fr);\n        height: 334px;\n        &:first-of-type {\n            border-bottom: 2px solid #F5F5F5;\n        }\n        &:first-of-type::after {\n            content: none;\n        }\n    ";
}, function (props) {
  return props.cssVS || '';
});
exports.StyleRowDetailContainer = StyleRowDetailContainer;
var StyleGradientBar = (0, _styledComponents.default)(RowContainerAnimation).withConfig({
  displayName: "styles__StyleGradientBar",
  componentId: "sc-6n2kuj-19"
})(["position:absolute;bottom:-10px;left:0;right:0;height:10px;z-index:2;background:linear-gradient(to left,#15ace2,#8de8f9);", ""], function (props) {
  return props.cssVS || '';
});
exports.StyleGradientBar = StyleGradientBar;

var StyleTeamDetailContainer = _styledComponents.default.div.withConfig({
  displayName: "styles__StyleTeamDetailContainer",
  componentId: "sc-6n2kuj-20"
})(["", " justify-items:center;justify-content:center;align-items:center;align-content:center;margin:auto;width:100%;p{text-align:center;}", " opacity:", ";", ";", ";", ";"], function (props) {
  return props.oneMatch ? "\n        display: flex;\n        flex-direction: column;\n        p {\n            order: ".concat(props.first ? 1 : 0, ";\n        }\n        img {\n            min-width: 370px;\n            width: 370px;\n            height: 370px;\n            min-height: 370px;\n            max-height: 100%;\n            object-fit: contain;\n        }\n    ") : "\n        display: grid;\n        // grid-template-rows: 4fr 1fr;\n        grid-template-columns: repeat(6,1fr);\n        grid-column-start: ".concat(props.first ? 1 : 9, ";\n        grid-column-end: ").concat(props.first ? 7 : 15, ";\n        img {\n            min-width: 221px;\n            width: 221px;\n            height: 221px;\n            min-height: 221px;\n            max-height: 100%;\n            object-fit: contain;\n        }\n    ");
}, function (props) {
  return props.first && !props.oneMatch ? "\n        p {\n            grid-column-start: 2;\n            grid-column-end: 4;\n            justify-self: end;\n            padding-right: 30px;\n        }\n        img {\n            grid-column-start: 4;\n            grid-column-end: 7;\n        }\n    " : !props.oneMatch ? "\n        p {\n            grid-column-start: 4;\n            grid-column-end: 6;\n            justify-self: start;\n            padding-left: 30px;\n        }\n        img {\n            grid-column-start: 1;\n            grid-column-end: 4;\n        }\n    " : '';
}, function (props) {
  return props.isLoser ? '0.3' : '1';
}, function (props) {
  return props.StyleTeamDetailContainer ? props.StyleTeamDetailContainer : '';
}, function (props) {
  return props.StyleTeamDetailContainerLeft ? props.StyleTeamDetailContainerLeft : '';
}, function (props) {
  return props.StyleTeamDetailContainerRight ? props.StyleTeamDetailContainerRight : '';
});

exports.StyleTeamDetailContainer = StyleTeamDetailContainer;

var StyleVersusDetailContainer = _styledComponents.default.div.withConfig({
  displayName: "styles__StyleVersusDetailContainer",
  componentId: "sc-6n2kuj-21"
})(["margin:auto 0;text-align:center;align-items:center;grid-column-start:7;grid-column-end:9;", ""], function (props) {
  return props.cssVS || '';
});

exports.StyleVersusDetailContainer = StyleVersusDetailContainer;

var StyleScoreDetailContainer = _styledComponents.default.div.withConfig({
  displayName: "styles__StyleScoreDetailContainer",
  componentId: "sc-6n2kuj-22"
})(["display:flex;flex-direction:column;color:#FFF;margin:auto 0;grid-column-start:7;grid-column-end:9;", ";"], function (props) {
  return props.StyleScoreDetailContainer || '';
});

exports.StyleScoreDetailContainer = StyleScoreDetailContainer;

var StyleScoreDetail = _styledComponents.default.div.withConfig({
  displayName: "styles__StyleScoreDetail",
  componentId: "sc-6n2kuj-23"
})(["display:grid;grid-template-columns:repeat(3,1fr);column-gap:20px;font-family:dharma-gothic-e-bold,sans-serif;font-weight:700;justify-items:center;color:#F5F5F5;", " ", " ", ""], function (props) {
  return props.oneMatch ? "\n        font-size: 180px;\n        line-height: 150px;\n        padding: 34px 0 25px 0;\n    " : "\n        font-size: 120px;\n        line-height: 100px;\n        padding: 20px 0 6px 0;\n    ";
}, function (props) {
  return props.StyleScoreDetail || '';
}, function (props) {
  return props.cssVS || '';
});

exports.StyleScoreDetail = StyleScoreDetail;

var StyleScoreSpan = _styledComponents.default.span.withConfig({
  displayName: "styles__StyleScoreSpan",
  componentId: "sc-6n2kuj-24"
})(["min-width:41px;text-align:center;", ";", ""], function (props) {
  return props.StyleScoreSpan || '';
}, function (props) {
  return props.winner && props.StyleScoreSpanWinner ? props.StyleScoreSpanWinner : '';
});

exports.StyleScoreSpan = StyleScoreSpan;

var StyleScoreDash = _styledComponents.default.span.withConfig({
  displayName: "styles__StyleScoreDash",
  componentId: "sc-6n2kuj-25"
})(["position:relative;bottom:9px;"]); // export const StyleGamesDetail = styled.div`
//     display: grid;
//     font-size: 32px;
//     font-family: proxima-nova, sans-serif;
//     font-weight: 700;
// `;


exports.StyleScoreDash = StyleScoreDash;

var StyleGameDetail = _styledComponents.default.div.withConfig({
  displayName: "styles__StyleGameDetail",
  componentId: "sc-6n2kuj-26"
})(["display:grid;grid-template-columns:repeat(3,1fr);font-size:32px;line-height:32px;font-family:proxima-nova,sans-serif;font-weight:700;margin:5px auto;justify-items:center;", ";"], function (props) {
  return props.StyleGameDetail || '';
});

exports.StyleGameDetail = StyleGameDetail;