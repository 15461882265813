import styled from 'styled-components';

export const StylePlayersRunes = styled.div`
 display: grid;
  grid-template-columns: 1fr 1px 1fr;
  grid-column-gap: 12px;
  height: 100%;
  width: 100%;
  align-items: center;
`;

export const StyleHeader = styled.div``;



const StyleContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
`;

export const StyleLeftContainer = styled(StyleContainer)``;

export const StyleRightContainer = styled(StyleContainer)``;

export const StyleCenterContainer = styled.div`
  height: 100%;
  width: 1px;
  background: #777777;
`;

export const StylePrimaryRunes = styled.div``;

export const StyleSecondaryRunes = styled.div``;

export const StylePositionColumn = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  justify-content: center;
  grid-row-gap: 10px;
  text-align: center;
`;

export const StyleKeyStonesImg = styled.img`
  width: 50px;
  margin: 0 auto;
`;

export const StyleRuneImg = styled.img`
  width: 25px;
  margin: 0 3px;
`;

export const StyleChampionImg = styled.img`
  width: 45px;
  height: 45px;
  object-fit: cover;
  margin: 0 auto;
`;
