// REACT
import React, { Component } from 'react';
import PropTypes from 'prop-types';
// 3RD PARTY
import { delay } from 'lodash';
// ME
// import {preloadImages} from '../../util/loadImageUrl';
import KeyPlayersV2 from '../../storybook-built/dist/components/Games/LJL/KeyPlayersV2';
import { statsDictionary } from '../../services/keyPlayerService';
class KeyPlayersV2Component extends Component {

    static propTypes = {
        // active: PropTypes.bool,
        action: PropTypes.string, // eslint-disable-line react/no-unused-prop-types
        data: PropTypes.any,
        statusCallback: PropTypes.func,
    }

    state = {
        status: 'closed',
        animation: '0',
    };

    bound = false;

    componentDidMount() {
        this.props.statusCallback('keyPlayersV2', 'ready');
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        this.doAction(nextProps);
    }

    doAction = props => {
        if (props.active) {
            // if (this.state.status !== 'open' && !this.props.data.keyPlayerVisible && props.data.keyPlayerVisible) {
            //     delay(() => this.beginAnimation(), 100);
            // }
            this.runUpdate(props);
        } else {
            // this.props.statusCallback('keyPlayersV2', 'ready');

            if (this.state.status === 'open') this.clearPage();
        }
    }

    checkProps = async () => {
        this.beginAnimation();
    }

    runUpdate = props => {
        if (props.data) {
            if (props.data.keyPlayerVisible && !this.props.data.keyPlayerVisible) {
                this.beginAnimation(() => {
                    if (props.data.champsVisible) {
                        this.showChamps()
                    }
                });
            }
            else if (!props.data.keyPlayerVisible && this.props.data.keyPlayerVisible) {
                this.clearPage();
            }
            else if (this.props.data.keyPlayerVisible && props.data.champsVisible) {
                this.showChamps();
            }
            else if (this.props.data.keyPlayerVisible && !props.data.champsVisible) {
                this.hideChamps();
            }
        }
    }

    beginAnimation = (onFinish) => {
        this.setState({ animation: '1' });
        delay(() => this.setState({ animation: '2' }), 1000);
        delay(() => this.setState({ animation: '3' }), 1400);
        delay(() => { this.setState({ status: 'open' }); }, 1800);
        delay(() => { this.props.statusCallback('keyPlayersV2', 'open'); onFinish(); }, 1900)
    };

    showChamps = () => {
        this.setState({ animation: '4' })
    };

    hideChamps = () => {
        this.setState({ animation: '3' })
    };

    clearPage = () => {
        this.setState({ animation: '2' });
        delay(() => { this.setState({ animation: '1' }) }, 700);
        delay(() => { this.setState({ animation: '0' }) }, 1300);
        delay(() => { this.setState({ status: 'closed' }); }, 1400);
        // delay(() => { this.props.statusCallback('keyPlayersV2', 'closed'); }, 1500);
    };

    getStatDisplayName = (stat) => {
        for(const key in statsDictionary){
            if(statsDictionary[key] === stat){
                return key
            }
        }
        return '';
    }

    render() {
        console.log("KeyPlayerV2 props ", this.props);
        let player100, player200, champions100, champions200, player100PositionIcon, player200PositionIcon;
        if (this.props.data.switchScreen) {
            player100 = this.props.data.player200;
            player200 = this.props.data.player100
            champions100 = this.props.data.champions200;
            champions200 = this.props.data.champions100;
            player100PositionIcon = this.props.data.player200PositionIcon;
            player200PositionIcon = this.props.data.player100PositionIcon;
        } else {
            player100 = this.props.data.player100;
            player200 = this.props.data.player200;
            champions100 = this.props.data.champions100;
            champions200 = this.props.data.champions200;
            player100PositionIcon = this.props.data.player100PositionIcon;
            player200PositionIcon = this.props.data.player200PositionIcon;
        }

        return (
            this.state.animation === '0' ? <div></div> :
                <div className={'container'}>
                    <KeyPlayersV2
                        redTeam={{
                            mainColor: this.props.data.switchScreen? this.props.data.player200TeamColor : this.props.data.player100TeamColor,
                            teamLogo: this.props.data.switchScreen? this.props.data.player200TeamLogo : this.props.data.player100TeamLogo,
                            player: {
                                name: player100.name,
                                positionIcon: player100PositionIcon,
                                stats: [
                                    {name: this.getStatDisplayName(player100.stats.stat0Name), value: player100.stats.stat0Value, rank:player100.stats.stat0Rank},
                                    {name: this.getStatDisplayName(player100.stats.stat1Name), value: player100.stats.stat1Value, rank:player100.stats.stat1Rank},
                                    {name: this.getStatDisplayName(player100.stats.stat2Name), value: player100.stats.stat2Value, rank:player100.stats.stat2Rank},
                                ],
                                selectedChampions: champions100,
                            },
                            videos: this.props.data.switchScreen? { ...this.props.data.player200TeamVideos } : { ...this.props.data.player100TeamVideos },
                        }}
                        // stats={this.props.data.keyPlayers.statsSelected}
                        animation={this.state.animation}
                        blueTeam={{
                            mainColor: this.props.data.player200TeamColor,
                            teamLogo: this.props.data.player200TeamLogo,
                            player: {
                                name: player200.name,
                                positionIcon: player200PositionIcon,
                                stats: [
                                    {name: this.getStatDisplayName(player200.stats.stat0Name), value: player200.stats.stat0Value, rank:player200.stats.stat0Rank},
                                    {name: this.getStatDisplayName(player200.stats.stat1Name), value: player200.stats.stat1Value, rank:player200.stats.stat1Rank},
                                    {name: this.getStatDisplayName(player200.stats.stat2Name), value: player200.stats.stat2Value, rank:player200.stats.stat2Rank},
                                ],
                                selectedChampions: champions200,
                            },
                            videos:  this.props.data.switchScreen? { ...this.props.data.player100TeamVideos } : { ...this.props.data.player200TeamVideos },
                        }}
                    />
                </div>
        );
    }

}

export default KeyPlayersV2Component
