// REACT
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { get } from 'lodash';
import PropTypes from 'prop-types';
// INTERNAL
import { /* send, */ subscribe } from '../../../services/api';
import ControlContainer from '../../Controller/ControlContainer';
import Button from '../../Button';
import { i18n } from '../../i18n';
// import { getNextMatchTeams, getNextRoundWinnersInfo, getNextGameNumber, getNextMatchBestOf } from '../../../util/nextMatch';
import { firebaseRealtimeDbSetValue } from '../../../util/firebase'
import {splitName} from '../../../util/const';
import {
    StyleSelect,
} from './styles';

// const PLAYER_POSITIONS = ['top', 'jungle', 'mid', 'ad', 'support'];
// class ScheduleInfoSideBarControl extends Component {
const ScheduleInfoSideBarControl = (props) => {
    const [status, setStatus] = useState('ready');
    const [sidebarMode, setSidebarMode] = useState((window.MainData.schedule.league === 'ljl' || window.MainData.schedule.league === 'academy') ? 'new' : 'old');
    const [showNextMatchup, setShowNextMatchup] = useState((window.MainData.schedule.league === 'ljl' || window.MainData.schedule.league === 'academy') ? true : false);

    window.MainData.scheduleInfoSideBar = {
        mode: sidebarMode,
    }

    useEffect(() => {
        window.MainData.scheduleInfoSideBar.mode = sidebarMode
    }, [sidebarMode])

    const supportedControls = ['control-standings',  'control-leaderboard'];

    const onChangeNextMatchup = (value) => {
        setShowNextMatchup(value);
        firebaseRealtimeDbSetValue('view/widgets/scheduleInfoSideBar/data/showNextMatchup', value);
    }

    const onChangeSidebarMode = event => {
        setSidebarMode(event.target.value);
        firebaseRealtimeDbSetValue('view/widgets/scheduleInfoSideBar/data/sidebarMode', event.target.value);
    }

    const runSubscription = () => {
        subscribe('view', null, data => {
            if (data.target === 'view' && data.showSideContainer) {
                setStatus('open');
                }
            if (status !== 'open' && supportedControls.includes(data.target) && (data.status === 'open' || data.status === 'opening')) {
                // TODO: redo this logic?
                setStatus('ready');
            }
        }, props.cgpc);
    };


    // const getTeamsInformationAndFormat = incomingTeams => {
    //     const teams = [];
    //     Object.keys(incomingTeams).forEach(teamTricode => {
    //         const teamInfo = incomingTeams[teamTricode];
    //         if (teamInfo && teamInfo.roster) {
    //             const team = {};
    //             team.tricode = teamTricode.toLowerCase();
    //             team.name = teamInfo.name;
    //             team.color = teamInfo.color;
    //             team.roster = {};
    //             Object.keys(teamInfo.roster).forEach(player => {
    //                 PLAYER_POSITIONS.forEach(position => {
    //                     if (!team.roster[position] && (teamInfo.roster[player].position === position || (position === 'ad' && teamInfo.roster[player].position === 'adc'))) {
    //                         const image = teamInfo.roster[player].images && teamInfo.roster[player].images.voting;
    //                         team.roster[position] = {
    //                             player,
    //                             image: image,
    //                         }
    //                     }
    //                 })
    //             })
    //             teams.push(team);
    //         }
    //     })
    //     return teams;
    // }

    // const formatWinnersData = (winners, teams) => {
    //     const formatedTeams = [];
    //     Object.keys(winners).forEach(winnerTeamTricode => {
    //         const team = teams.find(team => team.tricode.toUpperCase() === winnerTeamTricode);
    //         if (team) {
    //             team.score = winners[winnerTeamTricode];
    //             team.mainColor = team.color.main;
    //             team.subColor = team.color.sub;
    //             team.logo = window.MainData.teams[team.tricode.toUpperCase()].images && window.MainData.teams[team.tricode.toUpperCase()].images.logoTeamColor
    //             formatedTeams.push(team);
    //         }
    //     });
    //     return formatedTeams;
    // }

    const getSendData = () => {
        // const schedule = window.MainData.schedule;
        const roundId = window.MainData.schedule.round || 0;
        const round = window.MainData.schedule.rounds[roundId];

        const nextDayMatch = window.MainData.schedule.rounds[roundId + 1]?.matches[0];
        // console.log(window.MainData.standings, window.MainData.standings_groups)
        const nextDayMatchData = nextDayMatch && {
            bestOf: nextDayMatch.bestOf,
            team100: {
                ...nextDayMatch.team100,
                standing: (window.MainData.standings && window.MainData.standings.REGULAR) ? window.MainData.standings.REGULAR.find(team => team.name === nextDayMatch.team100.name) : null,
            },
            team200: {
                ...nextDayMatch.team200,
                standing: (window.MainData.standings && window.MainData.standings.REGULAR) ? window.MainData.standings.REGULAR.find(team => team.name === nextDayMatch.team200.name) : null,
            },
        }

        let matches = (round.matches.length && round.matches.map((match) => {
            const day = match.datetime.split('T')[0] || 'N/A';
            const time = match.datetime.split('T')[1] || 'N/A';
            const datetime = match.datetime;
            const gameWinners = (match.games && match.games.length && match.games.filter(game => game.winner)) || [];
            const team100 = {
                ...match.team100,
                logo: get(match, 'team100.images.logoFullColor',''),
                score: gameWinners.filter(game => game.winner === match.team100.tricode).length,
                standing: (window.MainData.standings && window.MainData.standings.REGULAR) ? window.MainData.standings.REGULAR.find(team => team.name === match.team100.name) : null,
            }
            const team200 = {
                ...match.team200,
                logo: get(match, 'team200.images.logoFullColor',''),
                score: gameWinners.filter(game => game.winner === match.team200.tricode).length,
                standing: (window.MainData.standings && window.MainData.standings.REGULAR) ? window.MainData.standings.REGULAR.find(team => team.name === match.team200.name) : null,
            }


            let winner = undefined;
            if(team100.score >= ((match.bestOf || 1)/2)){
                winner = team100.tricode;
            }
            else if(team200.score >= ((match.bestOf || 1)/2)){
                winner = team200.tricode;
            }
            const data= {
                games: match.games || [],
                date: {
                    day,
                    time,
                },
                bestOf: match.bestOf,
                isPlayoffs: match.isPlayoffs,
                datetime,
                team100,
                team200,
                winner,
                hide: match.hide,
                skip: match.skip,
                timerText: window.MainData.timer.timerText || '',
            }
            // console.log("scheduleInfoSidebar", data)
            return data;
        })) || [];




        // const nextMatchWinnersStats = getNextRoundWinnersInfo(schedule);
        // const teams = getTeamsInformationAndFormat(nextMatchTeams);
        // const teamsFormatedForBottomBar = formatWinnersData(nextMatchWinnersStats, teams);
        // const nextGameNumber = getNextGameNumber(schedule);
        // const nextMatchBestOf = getNextMatchBestOf(schedule) || 0;
        const weekTitle = round.name;
        const timerText = window.MainData.timer.timerText;
        const splitNameDisplay = splitName(window.MainData.schedule.league, window.MainData.schedule.split);

        let weekDate = '';
        if (matches[0]) {
            weekDate = moment(matches.filter(match => !match.hide)[0].datetime).format('MMM Do ddd');
        }
        console.log("matched", matches);
        return {
            // nextGameNumber,
            // teamsFormatedForBottomBar,
            // nextMatchBestOf,
            scheduledMatches: matches,
            nextDayMatch: nextDayMatchData || [],
            weekTitle,
            weekDate,
            timerText,
            splitNameDisplay,
            sidebarMode,
            showNextMatchup,
        };
    }

    const onPlay = () => {
        const sendData = getSendData();
        // send('event', { target: 'view', action: 'resize', screen: state.displayingScreen });
        firebaseRealtimeDbSetValue('view/widgets/scheduleInfoSideBar', {data: sendData}, props.cgpc);
        // firebaseRealtimeDbSetValue('view/resized', true);
        firebaseRealtimeDbSetValue('view/showSideContainer', true, props.cgpc);
        // send('event', {target: 'view', action: 'showBottomBar', widget: 'nextMatchInfoBottomBar', data: sendData});
    }

    const onClose = () => {
        // send('event', { target: 'view', action: 'close-resize', screen: state.displayingScreen });
        // firebaseRealtimeDbSetValue('view/resized', false);
        firebaseRealtimeDbSetValue('view/showSideContainer', false, props.cgpc);
        // firebaseRealtimeDbSetValue('view/widgets/scheduleInfoSideBar', {});
        // send('event', {target: 'view', action: 'hideBottomBar'});
        setStatus('ready');
    }

    useEffect(() => {
        runSubscription()
        return () => {
            // cleanup
        }
    }, [])

        return (
            <ControlContainer style={{display: props.display ? 'none': 'block'}} className="control--starters" status={status}>
                <div className="control__panel">
                    <h2 className="control__title">
                        <i className="fas fa-users"></i> {i18n().pages.scheduleInfoSideBar}
                    </h2>
                    <Button
                        controllerSide={!props.screen1}
                        action={onPlay}
                        text={`${i18n().general.open}`}
                        icon="fas fa-play-circle"
                        disabled={status !== 'ready'}
                    />
                    <Button
                    controllerSide={!props.screen1}
                        action={onClose}
                        text={i18n().general.close}
                        icon="fas fa-stop-circle"
                        disabled={status !== 'open'}
                    />
                </div>
                {(window.MainData.schedule.league === "ljl" || window.MainData.schedule.league === "academy") && 
                <div className="control-extra">
                    <div className="control-extra__fieldset">
                        <label className="control-extra__fieldset__label">{i18n().general.sidebarMode}</label>
                        <StyleSelect onChange={onChangeSidebarMode} value={sidebarMode}>
                            <option value="old">{i18n().general.old}</option>
                            <option value="new">{i18n().general.new}</option>
                        </StyleSelect>
                    </div>
                    {sidebarMode === 'new' &&
                        <div className="control-extra__fieldset">
                            <label className="control-extra__fieldset__label">{i18n().general.nextMatchUp}</label>
                            <div className="pill">
                                {
                                    showNextMatchup ?
                                        [
                                            <span key="1a" className="pill__span--highlighted">{i18n().controller.viewable.true}</span>,
                                            <Button controllerSide={!props.screen1} key="1b" text="Hide" action={() => onChangeNextMatchup(false)} />,
                                        ] :
                                        [
                                            <span key="2a">{i18n().controller.viewable.false}</span>,
                                            <Button controllerSide={!props.screen1} key="2b" text="Show" action={() => onChangeNextMatchup(true)} />,
                                        ]
                                }
                            </div>
                        </div>
                    }
                </div>
                }
            </ControlContainer>
        )
}
ScheduleInfoSideBarControl.propTypes = {
    cgpc: PropTypes.string,
    display: PropTypes.bool,
    screen1: PropTypes.bool,
  };
export default ScheduleInfoSideBarControl
