"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StyleTeamLogoVideoContainer = exports.StyleTeamLogoImageRight = exports.StyleTeamLogoImageContainer = exports.StyleStatsValueContainer = exports.StyleStatsContainer = exports.StyleStatValue = exports.StyleStatTitle = exports.StylePlayerName = exports.StylePlayerImg = exports.StylePlayerContainer = exports.StyleInfoBoxBody = void 0;

var _styledComponents = _interopRequireDefault(require("styled-components"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var StyleInfoBoxBody = _styledComponents.default.div.withConfig({
  displayName: "styles__StyleInfoBoxBody",
  componentId: "sc-1fryj4z-0"
})(["display:grid;grid-template-columns:535px 270px 535px;font-family:'futura-pt',sans-serif;align-items:center;height:210px;position:absolute;width:1340px;overflow:visible;top:70px;bottom:0;color:#ffffff;left:7px;", ";"], function (props) {
  return props.StyleInfoBoxBody ? props.StyleInfoBoxBody : '';
});

exports.StyleInfoBoxBody = StyleInfoBoxBody;

var StylePlayerContainer = _styledComponents.default.div.withConfig({
  displayName: "styles__StylePlayerContainer",
  componentId: "sc-1fryj4z-1"
})(["position:relative;width:535px;height:210px;", " ", ";"], function (props) {
  return props.side === 'left' ? "left: 0px;" : "right: 0px;";
}, function (props) {
  return props.StylePlayerContainer ? props.StylePlayerContainer : '';
});

exports.StylePlayerContainer = StylePlayerContainer;

var StylePlayerName = _styledComponents.default.div.withConfig({
  displayName: "styles__StylePlayerName",
  componentId: "sc-1fryj4z-2"
})(["width:200px;height:40px;bottom:10px;position:absolute;display:flex;justify-content:center;", " background-color:#000000;font-size:20px;line-height:40px;z-index:1;text-transform:uppercase;", ";"], function (props) {
  return props.side === 'left' ? "left: 0;" : "right: -5px;";
}, function (props) {
  return props.StylePlayerName ? props.StylePlayerName : '';
});

exports.StylePlayerName = StylePlayerName;

var StylePlayerImg = _styledComponents.default.img.withConfig({
  displayName: "styles__StylePlayerImg",
  componentId: "sc-1fryj4z-3"
})(["position:absolute;bottom:-250px;", " width:330px;height:auto;", ";"], function (props) {
  return props.side === 'left' ? "left: 85px;" : "right: 85px;";
}, function (props) {
  return props.StylePlayerImg ? props.StylePlayerImg : '';
});

exports.StylePlayerImg = StylePlayerImg;

var StyleTeamLogoImageContainer = _styledComponents.default.div.withConfig({
  displayName: "styles__StyleTeamLogoImageContainer",
  componentId: "sc-1fryj4z-4"
})(["position:absolute;bottom:-4px;width:100%;height:100%;background:", ";img{max-width:none;height:210px;", "}", ";"], function (props) {
  return props.backgroundColor || '#F5F5F5';
}, function (props) {
  return props.side === 'left' ? "   \n            transform: ".concat(props.mode === 'teams' ? 'translateX(0px)' : 'translateX(-108px)', ";\n            clip-path: ").concat(props.mode === 'teams' ? 'inset(0 0px 0 0px)' : 'inset(0 0px 0 108px)', ";\n        ") : "\n            transform: ".concat(props.mode === 'teams' ? 'translateX(160px)' : 'translateX(331px)', ";\n            clip-path: ").concat(props.mode === 'teams' ? 'inset(0 0px 0 0px)' : 'inset(0 136px 0 0)', ";\n        ");
}, function (props) {
  return props.StyleTeamLogoImageContainer ? props.StyleTeamLogoImageContainer : '';
});

exports.StyleTeamLogoImageContainer = StyleTeamLogoImageContainer;

var StyleTeamLogoVideoContainer = _styledComponents.default.div.withConfig({
  displayName: "styles__StyleTeamLogoVideoContainer",
  componentId: "sc-1fryj4z-5"
})(["position:absolute;bottom:-4px;video{max-width:none;height:210px;", "}", ";"], function (props) {
  return props.side === 'left' ? "   \n            transform: ".concat(props.mode === 'teams' ? 'translateX(-40px)' : 'translateX(-108px)', ";\n            clip-path: ").concat(props.mode === 'teams' ? 'inset(0 100px 0 40px)' : 'inset(0 28px 0 108px)', ";\n        ") : "\n            transform: ".concat(props.mode === 'teams' ? 'translateX(-50px)' : 'translateX(0)', ";\n            clip-path: ").concat(props.mode === 'teams' ? 'inset(0 90px 0 50px)' : 'inset(0 136px 0 0)', ";\n        ");
}, function (props) {
  return props.StyleTeamLogoVideoContainer ? props.StyleTeamLogoVideoContainer : '';
});

exports.StyleTeamLogoVideoContainer = StyleTeamLogoVideoContainer;

var StyleTeamLogoImageRight = _styledComponents.default.img.withConfig({
  displayName: "styles__StyleTeamLogoImageRight",
  componentId: "sc-1fryj4z-6"
})(["", ";"], function (props) {
  return props.StyleTeamLogoImageRight ? props.StyleTeamLogoImageRight : '';
});

exports.StyleTeamLogoImageRight = StyleTeamLogoImageRight;

var StyleStatsContainer = _styledComponents.default.div.withConfig({
  displayName: "styles__StyleStatsContainer",
  componentId: "sc-1fryj4z-7"
})(["display:grid;grid-template-rows:repeat(3,1fr);justify-content:center;width:280px;height:210px;padding:10px 0;z-index:1;text-transform:uppercase;", ";"], function (props) {
  return props.StyleStatsContainer ? props.StyleStatsContainer : '';
});

exports.StyleStatsContainer = StyleStatsContainer;

var StyleStatsValueContainer = _styledComponents.default.div.withConfig({
  displayName: "styles__StyleStatsValueContainer",
  componentId: "sc-1fryj4z-8"
})(["position:absolute;", " display:grid;grid-template-rows:repeat(3,1fr);justify-content:center;width:200px;height:210px;padding:10px 0;color:#000000;", ";"], function (props) {
  return props.side === 'left' ? "right: 0px;" : "left: 0px;";
}, function (props) {
  return props.StyleStatsValueContainer ? props.StyleStatsValueContainer : '';
});

exports.StyleStatsValueContainer = StyleStatsValueContainer;

var StyleStatTitle = _styledComponents.default.div.withConfig({
  displayName: "styles__StyleStatTitle",
  componentId: "sc-1fryj4z-9"
})(["display:flex;justify-content:center;margin:auto;font-size:28px;font-weight:400;text-align:center;color:#ffffff;", ";"], function (props) {
  return props.StyleStatTitle ? props.StyleStatTitle : '';
});

exports.StyleStatTitle = StyleStatTitle;

var StyleStatValue = _styledComponents.default.div.withConfig({
  displayName: "styles__StyleStatValue",
  componentId: "sc-1fryj4z-10"
})(["margin:auto;font-size:40px;width:200px;padding:0 25px;text-align:", ";color:#141720;", ";"], function (props) {
  return props.side === 'left' ? 'right' : 'left';
}, function (props) {
  return props.StyleStatValue ? props.StyleStatValue : '';
});

exports.StyleStatValue = StyleStatValue;