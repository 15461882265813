export default {
  controller: {
    buttons: {
        update: 'Update',
        toggle: 'Toggle',
        toggleVisibility: 'Toggle Visibility',
        set: 'Set',
        reset: 'Reset Firebase',
        fetch: 'Fetch Firebase',
        unlock: 'Unlock Debug',
        lock: 'Lock Debug',
    },
    countdown: {
        default: 'Default',
    },
    viewable: {
        true: 'Visible',
        false: 'Not Visible',
    },
    gamescreen: {
      topBar: 'Top Bar',
      inGameTimer: 'In-Game Timer',
      vsOverlay: 'VS Overlay',
      sponsor: 'Sponsors',
      playersRunes: 'Player’s Runes',
      score: 'Score',
      jungleRoute: 'Jungle Route',
      goldPerPlayer: 'Gold Per Player',
      goldDifference: 'Gold Difference',
      visionControl: 'Vision Control',
      drakeTokens: 'Drake Tokens',
      damageDealt: 'Damage Dealt',
      turretsPlate: 'Towers Plate Gold',
      junglerProximity: 'Jungler Proximity',
      championsXP: 'Champions XP Level',
      infoBox: 'InfoBox',
    },
  },
  errors: {
    dataMalformed: 'Data is malformed',
    gameNotComplete: 'Game is not completed!',
    gameNotFound: 'Game not found!',
    noChampList: 'No champion list found. Check internet connection.',
    fujitsuAPIError: "Fujitsu API connection error.",
    RiotRealTimeAPIError: "Riot Realtime API connection error.",
    listEmpty: 'No Games Found.',
  },
  general: {
    FinishedGames: 'Finished Games',
    additionalData: 'additional data',
    additionalDataLoad: 'load additional data',
    additionalDataWarning: '※Do not commit lightly. Games are permanently added to stats.',
    additionalDataWarning2: '※Update takes ~20s to process.',
    ads: 'ads',
    all: 'all',
    analyst: 'Analyst',
    ASSISTS: 'ASSISTS',
    auto: 'auto',
    autoLoad: 'auto load',
    autoLoadSchedule: 'load schedule from file',
    autoLoadPlayerStats: 'Load Player Stats',
    baron: 'baron',
    ban: 'ban',
    banner: 'banner',
    bans: 'bans',
    behind: 'behind',
    blankScreen: 'Blank Screen',
    blue: 'blue',
    blueSide: 'blue side',
    blueSideCoach: 'blue side coach',
    blueSideRecord: 'blue side record',
    blueSideScore: 'blue side score',
    box: 'Box',
    caster: 'Caster',
    casters: 'Casters',
    castersPrediction: "Caster's Predictions",
    center: 'center',
    centerHeader: 'Central Header',
    champion: 'champion',
    championPool: 'Champion Pool',
    champions: 'champions',
    clear: 'Clear',
    clearTwitter: 'clear twitter',
    close: 'Close',
    coach: 'coach',
    collectTweets: 'Collect Tweets',
    commitAndGetStats: 'commit game and get stats',
    comparison: 'comparison',
    controller: 'controller',
    contentEditable:'Content Editable',
    count: 'count',
    countdown: 'Countdown',
    counts: 'counts',
    current: 'current',
    currentController: 'current controller',
    currentMatch: 'current match',
    currentPage: 'current page',
    currentSetting: 'Current Setting',
    damageDealt: 'Damage Dealt to Champions',
    data: 'data',
    dataWarning: '※do not press (live data currently unavailable)',
    dataApiListener: 'Realtime Api Listener',
    dataInGameTips: 'In-Game Tips (Side)',
    dataInGameTipsCustom: 'Custom In-Game Tips (Side)',
    dataGameTimer: 'Game Screen Timers',
    dataGameDrakeTokens: 'Game Screen Drake Tokens',
    dataBackground: 'Application Background',
    dataWaitingMessage: "Waiting Message",
    dataDraftOrder: "Draft Order",
    DisplayPhotos: "Display Photos",
    dummyVideo: 'Dummy Video (for test)',
    DPM: 'DPM',
    enter: '',
    fan: 'fan',
    fanTweetWarning: '* only press after loading and selecting tweets',
    fight: 'fight',
    file: 'file',
    final: 'final',
    flip: 'flip',
    flipMatchup: 'flip matchup',
    game: 'Game',
    gameBreakdown: 'Game Breakdown',
    gameFlowPrediction: 'AI Future Prediction (AI)',
    gameId: 'Game ID',
    gameList: 'Game List',
    gameRealm: 'Realm',
    games: 'Games',
    gamesBehind: 'Games Behind',
    gamePlayedAt: 'Game At',
    get: 'get',
    getTweets: 'Get Tweets',
    getFanTweets: 'get fan tweets',
    getVoteCounts: 'get vote counts',
    header: 'Header',
    hide: 'Hide',
    hideCasters: 'Hide Casters',
    hideFanTweets: 'Hide Tweets',
    hideInterviewer: 'Hide Interviewer',
    hideStats: 'Hide stats',
    information: 'Information',
    inGameTipInfobox: 'In-Game Tip (infobox)',
    interview: 'Interview',
    interviewee: 'Interviewee',
    interviewer: 'Interviewer',
    invalidNumbers : 'Invalid Numbers!',
    itemsPurchaseHistory: 'Items Purchase History',
    json: 'JSON file',
    japaneseCharacters: 'Does the name have any Japanese Characters?',
    jungleRoute: 'Jungle Route data',
    KDA: 'KDA',
    keyPlayers: 'Key Players',
    keyPlayersGameScreen: 'Game Screen - Key Players',
    keyTeamsGameScreen: 'Game Screen - Key Teams',
    KILLS: 'KILLS',
    KP: 'KP',
    language: 'Language',
    layout: 'Layout',
    leaderboard: 'Leaderboard',
    league: 'League',
    left: 'Left',
    leftShort: 'L',
    leftTeam: 'Left team',
    leftCaster: 'Left caster',
    load: 'load',
    loadBanpick: 'load ban pick',
    loadControllerData: 'load controller data',
    loadDetails: 'Load Details',
    loadGameBreakdown: 'load game breakdown',
    loadGameScreen: 'load game screen',
    loadHome: 'load home',
    loading: 'loading...',
    loadKeyPlayers: 'load key players',
    loadLeaderboard: 'load leaderboard',
    loadMatchup: 'load matchup',
    loadNextMatch: 'load next match',
    loadNextRound: 'load next round',
    loadPlayerMatch: 'load player of the match',
    loadResults: 'load results',
    loadSchedule: 'load schedule',
    loadStandings: 'load standings',
    loadStarters: 'load starters',
    loadTelops: 'load telops',
    loadCurrent: 'Load Current Values',
    loss: 'loss',
    lose: 'lose',
    loser: 'loser',
    match: 'Match',
    matches: 'Matches',
    matchup: 'Matchup',
    menu: 'Menu',
    mode: 'Mode',
    mythicItemInfobox: 'Mythic Item Infobox',
    mythicItemSide: 'Mythic Item Side Popout',
    playersMythicItem: 'Players Mythic Item',
    mvpVote: 'MVP Vote',
    name: 'Name',
    new: 'New',
    next: 'Next',
    nextMatch: 'next match',
    nextMatchUp: 'Next Matchup',
    nextRound: 'next round',
    nextTweet: 'next tweet',
    none: 'None',
    ok: 'OK',
    old: 'Old',
    one: 'one',
    open: 'Open',
    overlays: 'Overlays',
    page: 'page',
    patch: 'patch',
    picks: 'Picks',
    play: 'play',
    playBlueSide: 'play blue side',
    playRedSide: 'play red side',
    player: 'Player',
    players: 'Players',
    playerTweetsWarning: '* This function will count Tweets for the player hashtags for 3 minutes. Make sure you add players before starting the count. No count will be kept before pressing the button.',
    platform: 'PlatformId',
    preview: 'Load Preview',
    recalculate: 'Recalculate',
    record: 'record',
    red: 'red',
    redSide: 'red side',
    redSideCoach: 'red side coach',
    redSideRecord: 'red side record',
    redSideScore: 'red side score',
    reload: 'reload',
    reloadData: 'reload data',
    reset: 'Reset',
    result: 'Result',
    results: 'Results',
    resultsList: 'Results List',
    resync: 'resync',
    refresh: 'Refresh',
    retry: 'retry',
    retryPlayerTweets: 'Restart Twitter count',
    right: 'right',
    rightShort: 'R',
    rightTeam: 'Right team',
    rightCaster: 'right caster',
    role: 'Role',
    roleComparison: 'role comparison',
    round: 'Round',
    roundMatches: 'This Round\'s Matches',
    roundMatchesNext: 'Next Round\'s Matches',
    save: 'Save',
    saveControllerData: 'save controller data',
    schedule: 'Schedule',
    scheduleTeamless: 'Schedule Teamless',
    score: 'Score',
    scores: 'Scores',
    searchFor: 'Search for',
    season: 'Season',
    select: 'Select',
    selectTeam: 'Select Team',
    set: 'Set',
    setAllData: 'Set All Data',
    setFanTweets: 'set fan tweets',
    setInfoBoxRunes: 'info box: runes',
    setInfoBoxTeamFight: 'info box: team fight',
    setInfoBoxTwitter: 'info box: twitter',
    setGame: 'set game',
    setMatch: 'set match',
    setMatchInCGPC: 'Set Match Data',
    setMatchScore: 'set score',
    setMatchup: 'set matchup',
    setNextMatch: 'set next match',
    setNextRound: 'set next round',
    setPatch: 'set Patch',
    setRound: 'set round',
    setSchedule: 'set schedule',
    setScope: 'Set Data Scope',
    setScore: 'set score',
    setScores: 'set scores',
    setStarters: 'set starters',
    setTerm: 'set term',
    setTime: 'set time',
    settings: 'Settings',
    setTweets: 'set tweets',
    setup: 'Setup',
    show: 'Show',
    showCasters: 'show casters',
    showChamps: 'Show Champions',
    showFanTweets: 'show tweets',
    showInterviewer: 'show interviewer & player',
    showInterviewerOnly: 'show interviewer only',
    showMatchupTwitter: 'show twitter',
    showTwitter: 'show twitter',
    sidebarMode: 'Sidebar Mode',
    split: 'split',
    sponsors: 'Sponsors',
    standings: 'Standings',
    starters: 'Starters',
    startTwitterCount: 'Start Twitter Count',
    startTime: 'Start Time',
    stats: 'Stats',
    switchSides: 'Switch Sides',
    switchScreens: 'Switch Screens',
    team: 'Team',
    team100: 'Red Side',
    team200: 'Blue Side',
    teamFight: 'Team Fight',
    telops: 'Telops',
    testMode: 'Test Mode',
    theme: 'Theme',
    time: 'Time',
    timeline: 'Timeline',
    timerData: 'Timer',
    timer: 'Timer',
    timer2: 'Timer2 (Side)',
    timerCurrentSetting: 'Current Setting',
    timerCountdownFor: 'Countdown for',
    timerCountdownTo: 'Countdown to',
    timerText: 'Timer text',
    title: 'Title',
    toggle: 'Toggle',
    toggleBaron: 'toggle baron power play',
    toggleFanTweets: 'toggle fan tweets',
    toggleInfoBox: 'toggle info box',
    toggleHeader: 'toggle box header',
    toggleSponsors: 'toggle sponsors',
    toggleTime: 'toggle time',
    turretsPlate: 'Towers Plate Gold',
    twitter: 'twitter',
    twitterPlayers: 'Twitter - Player',
    twitterTeams: 'Twitter - Team',
    twitterTerm: 'twitter term',
    twitterTweets: 'Twitter - Tweets',
    tweet: 'tweet',
    tweetCount: 'Selected Tweets',
    tweets: 'tweets',
    tweetDownload: 'Download Tweets',
    tweetEditNumber: 'Edit Tweet Number',
    tweetSaveNewNumber: 'Save New Tweet Number',
    two: 'two',
    useTimePeriod: 'use time period for load',
    versus: 'versus',
    version: 'version',
    vs: 'VS',
    vsBanner: 'VS banner',
    vote: 'vote',
    votes: 'votes',
    who: 'who',
    whoWillWin: 'who will win?',
    whoWillWinAI: 'Who will win (AI)',
    whoWillWinAIGraph: 'Who will win Graph (AI)',
    will: 'will',
    win: 'win',
    winLoss: 'W-L',
    winner: 'winner',
    winning: 'winning',
    winningTeam: 'winning team',
  },
  pages: {
    banPickV2: "Ban & Pick (V2)",
    castersPrediction: "Caster's Predictions",
    castersPredictionOverlay: "Caster's Predictions Overlay",
    championPool: "Champion Pool",
    draftOrder: "Draft Order",
    home: 'Home',
    starters: 'Starters',
    starters_v2: 'Starters (V2)',
    standings: 'Standings',
    standingsV2: 'Standings (V2)',
    leaderboard: 'Leaderboard',
    schedule: 'Schedule',
    ScheduleV2: 'Schedule (V2)',
    scheduleNext: 'Schedule - Next',
    scheduleTeamless: 'Schedule Teamless',
    keyPlayers: 'Key Players',
    keyPlayersV2: 'Key Players (V2)',
    matchup: 'Matchup',
    matchupV2: 'Matchup (V2)',
    matchPlayer: 'Player of the Match',
    matchPlayerV2: 'Player of the Match (V2)',
    nextMatchInfo: 'Bottom Bar - Next Match Information',
    nextRound: 'Next Round',
    nextMatch: 'Next Match',
    playermatch: 'Player of the Match',
    results: 'Results',
    resultsList: 'Results List',
    scheduleInfoSideBar: 'Side Bar - Schedule Information',
    gamescreen: 'Game Screen',
    telops: 'Telops',
    banpick: 'Ban & Pick',
    gameBreakdown: 'Game Breakdown',
    waitingScreen: 'Waiting Screen',
    reset: 'Reset',
  },
  roles: {
    TOP: 'TOP',
    JUNGLE: 'JUNGLE',
    MID: 'MID',
    ADC: 'ADC',
    SUPPORT: 'SUPPORT',
  },

  runes: {
    title: 'runes',
    primary: 'primary',
    secondary: 'secondary',
    keystone: 'Keystone',
    rune: 'rune',
    runes: {
      precision: {
        title: 'precision',
        keystones: {
          presstheattack: 'press the attack',
          lethaltempo: 'lethal tempo',
          fleetfootwork: 'fleet footwork',
        },
        runes: {
          set1: {
            overheal: 'overheal',
            triumph: 'triumph',
            presenceofmind: 'presence of mind',
          },
          set2: {
            legendalacrity: 'legend: alacrity',
            legendtenacity: 'legend: tenacity',
            legendbloodline: 'legend: bloodline',
          },
          set3: {
            coupdegrace: 'coup de grace',
            cutdown: 'cut down',
            laststand: 'last stand',
          },
          setAll: {
            overheal: 'overheal',
            triumph: 'triumph',
            presenceofmind: 'presence of mind',
            legendalacrity: 'legend: alacrity',
            legendtenacity: 'legend: tenacity',
            legendbloodline: 'legend: bloodline',
            coupdegrace: 'coup de grace',
            cutdown: 'cut down',
            laststand: 'last stand',
          },
        },
      },
      domination: {
        title: 'domination',
        keystones: {
          electrocute: 'electrocute',
          predator: 'predator',
          darkharvest: 'dark harvest',
        },
        runes: {
          set1: {
            cheapshot: 'cheap shot',
            tasteofblood: 'taste of blood',
            suddenimpact: 'sudden impact',
          },
          set2: {
            zombieward: 'zombie ward',
            ghostporo: 'ghost poro',
            eyeballcollection: 'eyeball collection',
          },
          set3: {
            treasurehunter: 'treasure hunter',
            ingenioushunter: 'ingenious hunter',
            relentlesshunter: 'relentless hunter',
          },
          setAll: {
            cheapshot: 'cheap shot',
            tasteofblood: 'taste of blood',
            suddenimpact: 'sudden impact',
            zombieward: 'zombie ward',
            ghostporo: 'ghost poro',
            eyeballcollection: 'eyeball collection',
            treasurehunter: 'treasure hunter',
            ingenioushunter: 'ingenious hunter',
            relentlesshunter: 'relentless hunter',
          },
        },
      },
      sorcery: {
        title: 'sorcery',
        keystones: {
          summonaery: 'summon aery',
          arcanecomet: 'arcane comet',
          phaserush: 'phase rush',
        },
        runes: {
          set1: {
            nullifyingorb: 'nullifying orb',
            manaflowband: 'manaflow band',
            theultimatehat: 'the ultimate hat',
          },
          set2: {
            transcendence: 'transcendence',
            celerity: 'celerity',
            absolutefocus: 'absolute focus',
          },
          set3: {
            scorch: 'scorch',
            waterwalking: 'waterwalking',
            gatheringstorm: 'gathering storm',
          },
          setAll: {
            nullifyingorb: 'nullifying orb',
            manaflowband: 'manaflow band',
            theultimatehat: 'the ultimate hat',
            transcendence: 'transcendence',
            celerity: 'celerity',
            absolutefocus: 'absolute focus',
            scorch: 'scorch',
            waterwalking: 'waterwalking',
            gatheringstorm: 'gathering storm',
          },
        },
      },
      resolve: {
        title: 'resolve',
        keystones: {
          graspoftheundying: 'grasp of the undying',
          aftershock: 'aftershock',
          guardian: 'guardian',
        },
        runes: {
          set1: {
            unflinching: 'unflinching',
            demolish: 'demolish',
            fontoflife: 'font of life',
          },
          set2: {
            ironskin: 'iron skin',
            mirrorshell: 'mirror shell',
            conditioning: 'conditioning',
          },
          set3: {
            overgrowth: 'overgrowth',
            revitalize: 'revitalize',
            secondwind: 'second wind',
          },
          setAll: {
            unflinching: 'unflinching',
            demolish: 'demolish',
            fontoflife: 'font of life',
            ironskin: 'iron skin',
            mirrorshell: 'mirror shell',
            conditioning: 'conditioning',
            overgrowth: 'overgrowth',
            revitalize: 'revitalize',
            secondwind: 'second wind',
          },
        },
      },
      inspiration: {
        title: 'inspiration',
        keystones: {
          unsealedspellbook: 'unsealed spellbook',
          glacialaugment: 'glacial augment',
          kleptomancy: 'kleptomancy',
        },
        runes: {
          set1: {
            hextechflashtraption: 'hextech flashtraption',
            biscuitdelivery: 'biscuitdelivery',
            perfecttiming: 'perfect timing',
          },
          set2: {
            magicalfootwear: 'magical footwear',
            futuresmarket: "future's market",
            miniondematerializer: 'minion dematerializer',
          },
          set3: {
            cosmicinsight: 'cosmic insight',
            approachvelocity: 'approach velocity',
            celestialbody: 'celestial body',
          },
          setAll: {
            hextechflashtraption: 'hextech flashtraption',
            biscuitdelivery: 'biscuitdelivery',
            perfecttiming: 'perfect timing',
            magicalfootwear: 'magical footwear',
            futuresmarket: "future's market",
            miniondematerializer: 'minion dematerializer',
            cosmicinsight: 'cosmic insight',
            approachvelocity: 'approach velocity',
            celestialbody: 'celestial body',
          },
        },
      },
    },
  },
}
