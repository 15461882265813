import React from 'react';
import { merge } from 'lodash';

// Theme import
import default_theme from "./themes/default_theme";
import ljl2019_theme from "./themes/ljl2019_theme";
import ljl2020_theme from "./themes/ljl2020_theme";
import ljl2021_theme from "./themes/ljl2021_theme";
import ljl2022_theme from "./themes/ljl2022_theme";
import ljl2023_theme from "./themes/ljl2023_theme";
import ljlallstar_theme from "./themes/ljlallstar_theme";
import scoutingground_theme from "./themes/scoutinggrounds_theme";
import scoutingground2020_theme from "./themes/scoutinggrounds2020_theme";
import scoutingground2021_theme from "./themes/scoutinggrounds2021_theme";
import msi2019_theme from "./themes/msi2019_theme";
import pubg2019_theme from "./themes/pubg2019_theme";
import worlds_theme from "./themes/worlds_theme";
import worlds2020_theme from "./themes/worlds2020_theme";
import msi2021_theme from "./themes/msi2021_theme";
import msi2022_theme from "./themes/msi2022_theme";
import msi2023_theme from "./themes/msi2023_theme";
import worlds2021_theme from "./themes/worlds2021_theme";
import worlds2022_theme from "./themes/worlds2022_theme";
import worlds2023_theme from "./themes/worlds2023_theme";
import academy2021_theme from "./themes/academy2021_theme"
import academy2022_theme from "./themes/academy2022_theme";
import academy2023_theme from "./themes/academy2023_theme";

// Assets LJL
export const LJLTopLeftLogo = 'https://cdn.dekki.com/uploads/leagues/ljl_2019_spring/LJL-logo_topleft.png';
export const LJLBanPickBackground = 'https://cdn.dekki.com/uploads/leagues/ljl_2019_spring/backgrounds/banandpickBG.png';
export const LJLLogo = 'https://cdn.dekki.com/uploads/leagues/ljl_2019_spring/ljl-logo.png';
// Assets LJLAllStar
export const LJLAllstarTopLeftLogo = 'https://cdn.dekki.com/uploads/leagues/ljl_allstar_2019_spring/ljlallstar-logo.png';
export const LJLAllstarLogo = 'https://cdn.dekki.com/uploads/leagues/ljl_allstar_2019_spring/ljlallstar-logo-banpick.png';
export const LJLAllstarBanPickBG = 'https://cdn.dekki.com/uploads/leagues/ljl_allstar_2019_spring/backgrounds/ljlallstar-banpick-bg.png';
// Assets ScoutingGrounds
export const ScoutingGroundLogo = 'https://cdn.dekki.com/uploads/leagues/scoutinggrounds/scoutingground-logo.png';
export const ScoutingGroundBanPickBG = 'https://cdn.dekki.com/uploads/leagues/scoutinggrounds/scoutingground-banpick-bg.png';

export const getTheme = (themeName = 'default') => (themeName && themes[themeName] && merge({}, themes.default, themes[themeName])) || themes.default;

export const themes = {
    default: default_theme,
    ljl2019: ljl2019_theme,
    ljl2020: ljl2020_theme,
    ljl2021: ljl2021_theme,
    ljl2022: ljl2022_theme,
    ljl2023: ljl2023_theme,
    academy2021: academy2021_theme,
    academy2022: academy2022_theme,
    academy2023: academy2023_theme,
    ljlallstar: ljlallstar_theme,
    scoutinggrounds: scoutingground_theme,
    scoutinggrounds2020: scoutingground2020_theme,
    scoutinggrounds2021: scoutingground2021_theme,
    msi2019: msi2019_theme,
    msi2021: msi2021_theme,
    msi2022: msi2022_theme,
    msi2023: msi2023_theme,
    pubg2019: pubg2019_theme,
    worlds: worlds_theme,
    worlds2020: worlds2020_theme,
    worlds2021: worlds2021_theme,
    worlds2022: worlds2022_theme,
    worlds2023: worlds2023_theme,
};

export const ThemeContext = React.createContext(themes.default);
