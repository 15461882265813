import styled from 'styled-components';

export const Base = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    height: 100%;
`;

export const StylePlayerSelectionGrid = styled.div`
    // display: grid;
    // grid-template-columns: ${props => `repeat(${props.columns}, 1fr)`};
    padding: 10px 20px;
`;

export const StylePlayerContainer = styled.div`
    display:flex;
    margin: 10px;
    text-align: center;
    div:first-child {
        margin: auto;
    }
    select {
        min-width: 120px;
    }
    span {
        min-width: 80px;
    }
    img, i {
        width: 100px;
        font-size: 50px;
    }
    h3 {
        margin:auto;
    }

    &:first-child {
        border-right: 1px #CCCCCC solid;
    }
`;


export const StylePlayerImg = styled.img`
    width: 100px;
    margin: 10px 20px;
`;

export const StyleStatsGrid = styled.div`
    display: flex;
    flex-wrap: wrap;
    // grid-template-columns: 75px repeat(4, 1fr);
    h3 {
        margin: auto;
        font-size: 13px;
        color: #EFEFEF;
        width: 100%;
        padding: 10px;
        border-right: 1px #EFEFEF solid;
        text-align: center;

    }
    select{
        max-width: 130px
    }
    div {
        margin-right: unset;
        text-align: center;
    }
    p {
        width: 100%;
        text-align: center;
        color: #666666;
        font-weight: 800;
        font-style: italic;
        background-color: #EFEFEF;
    }
`;

export const StyleStatsSeparator = styled.div`
    height:1px;
    background-color: #EFEFEF;
    grid-column-start: 1;
    grid-column-end: 6;
    margin: 10px;
`;

export const StyleChampionPicker = styled.div`
    img.champ-select__image {
        width: 30px;
    }
    span.champ-select__name {
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px
    }
`;

export const StyleStatsPickerContainer = styled.div`
    select {
        max-width: unset;
        width: calc(100% - 70px);
    }
`;