import { getPlayerStatsForPosition } from '../lib/allstats-sdk/api';

const statsDictionary = {
  'KP': 'killParticipation',
  'KP@15' : 'KP@15', //new need processing killsAt15 + assistsAt15 / killAt15 (team stat)
  'KILLS': 'kills',
  'KILLS/G': 'killsPerGame',
  'ASSISTS': 'assists',
  'ASSISTS/G': 'assistsPerGame',
  'CS': 'cs',
  'CSD@10': 'csDiffAt10PerGame', // new
  'CSD@15': 'csDiffAtLaningEndPerGame', // new
  'CS/G': 'csPerGame',
  'CS/M' : 'csPerMin',
  'DPM': 'damageToChampionsPerMin',
  'GOLD/M': 'goldEarnedPerMin',
  'GD@10': 'goldDiffAt10PerGame', //new
  'GD@15': 'goldDiffAtLaningEndPerGame', //new
  'KDA' : 'kda',
  'K+A@15': 'K+A@15', //new need processing killsAt15PerGame + assistsAt15PerGame
  'WARD KILLS': 'wardsKilled',
  'WARD KILLS/G' : 'wardsKilledPerGame',
  'WARD KILLS/M' : 'wardsKilledPerMin',
  'WARDS/G' : 'wardsPlacedPerGame',
  'WARDS/M': 'wardsPlacedPerMin',
  'VISION WARDS/G' : 'visionWardsBoughtInGamePerGame',
  'VS/G': 'VS/GAME', //new need processing visionScore / gameCount
  'VS/MIN': 'VS/MIN',
  'XPD@10': 'xpAt10DiffPerGame', // new
  'XPD@15': 'xpAtLaningEndDiffPerGame', // new
  'DMG%': 'teamDamageDealtToChampionsShare',
  'GOLD%': 'teamGoldEarnedShare',
  'SOLO KILLS': 'soloKills',
};

const statsDictionaryForTeams = {
  'KILLS': 'kills',
  'KILLS/G': 'killsPerGame',
  'ASSISTS': 'assists',
  'ASSISTS/G': 'assistsPerGame',
  'CS': 'cs',
  'CSD@10': 'csDiffAt10', // new
  'CSD@15': 'csAtLaningEndPerGame', // new
  'CS/G': 'csPerGame',
  'CS/M' : 'csPerMin',
  'DPM': 'damageToChampionsPerMin',
  'GOLD/M': 'goldEarnedPerMin',
  'GD@10': 'goldDiffAt10', //new
  'GD@15': 'goldDiffAtLaningEndPerGame', //new
  'KDA' : 'kda',
  'K+A@15': 'K+A@15', //new need processing killsAt15 + assistsAt15
  'WARD KILLS': 'wardsKilled',
  'WARD KILLS/G' : 'wardsKilledPerGame',
  'WARD KILLS/M' : 'wardsKilledPerMin',
  'WARDS/G' : 'wardsPlacedPerGame',
  'WARDS/M': 'wardsPlacedPerMin',
  'VISION WARDS/G' : 'visionWardsBoughtInGamePerGame',
  'VS/G': 'VS/GAME', //new need processing visionScore / gameCount
  'VS/MIN': 'VS/MIN',
  'XPD@10': 'xpAt10Diff', // new
  'XPD@15': 'xpAtLaningEndDiffPerGame', // new
  'SOLO KILLS': 'soloKills',
  'FIRST TOWER KILLS': 'firstTowerKill',
  'BARONS': 'teamBaronKills',
  'BARONS/G':'teamBaronKillsPerGame',
  'RIFT HERALDS':'teamRiftHeraldKills',
  'RIFT HERALDS/G':'teamRiftHeraldKillsPerGame',
  'CONVERTED K/A':'convertedKillAndAssists',
};


const getInfoForPlayerInPosition = (season, league, split, summonerName, team, position) => {
  return getPlayerStatsForPosition({
    season,
    league,
    split,
    round: 'ALLROUNDS',
    matchup: 'ALLMATCHES',
    game: 'ALLGAMES',
    team,
    position,
    summonerName,
    champion: 'ALLCHAMPIONS',
  })
    .then(allPlayerData => {
      if (allPlayerData && allPlayerData.stats && allPlayerData.stats.champion) {
        const championList = [];
        const championPlayCounts = allPlayerData.stats.champion;
        Object.keys(championPlayCounts).forEach(championName => {
          championList.push({
            champion: championName,
            count: championPlayCounts[championName],
          });
        });
        allPlayerData.stats.champion = championList.sort((a, b) => b.count - a.count);
      }

      return allPlayerData;
    });
}

const getInfoForTeam = (season, league, split, team) => {
  return getPlayerStatsForPosition({
    season,
    league,
    split,
    team,
  }).then( teamData => {
    if (teamData && teamData.stats){
      return teamData;
    }
  })
}

export {
  getInfoForPlayerInPosition,
  getInfoForTeam,
  statsDictionary,
  statsDictionaryForTeams,
}