// REACT
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ThemeContext } from '../View/themes';
// 3RD PARTY
import { delay } from 'lodash';
// import moment from 'moment';
// ME
import ScreenTitle from '../../storybook-built/dist/components/Games/LJL/ScreenTitle';
import ScheduleComponent from '../../storybook-built/dist/components/Games/LJL/Schedule';
// import { preloadImages } from '../../util/loadImageUrl';

class Schedule extends Component {

    state = {
        played: false,
        status: 'closed',
        animation: 'hide',
        displayTable: false,
        displayTitle: false,
        callbackName: this.props.data.next ? 'scheduleNext' : 'schedule',
        resize: false,
        data: {},
    };

    bound = false;

    static propTypes = {
        active: PropTypes.bool,
        // action: PropTypes.string,
        data: PropTypes.any,
        statusCallback: PropTypes.func,
        isBottomContainerRender: PropTypes.bool,
        bottomContainerActions: PropTypes.object,
        sidePlayersContainerActions: PropTypes.object,
        resized: PropTypes.bool,
    }

    componentDidMount() {
        this.props.statusCallback(this.state.callbackName, 'ready');
    }

    componentWillUnmount() {
        console.log('unmount');
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.active) {
            if (this.state.status !== 'open') {
                this.setState({
                    status: 'open',
                    resize: nextProps.resized,
                });
                delay(() => this.checkSubscriptionData(nextProps.data), 100);
                this.props.isBottomContainerRender && this.props.sidePlayersContainerActions.show();
            } else {
                if (nextProps.resized) {
                    nextProps.sidePlayersContainerActions.show();
                    if (!this.state.resize) {
                        this.beginResizeAnimation();
                    }
                    this.setState({ resize: true });
                    if (!nextProps.isBottomContainerRender) {
                        nextProps.bottomContainerActions.show();
                    }
                } else {
                    if (this.state.resize) {
                        this.setState({ resize: false });
                    }
                }
            }
        } else {
            if (this.state.status === 'open') {
                this.clearPage();
                this.props.statusCallback(this.state.callbackName, 'closing');
                this.setState({status: 'closed'});
            }
        }
        this.processContainerRender(nextProps);
    }

    processContainerRender = nextProps => {
        if (nextProps.isBottomContainerRender && !this.state.resize) {
            this.setState({resize: true});
            this.props.sidePlayersContainerActions.show();
        }

        if (!nextProps.isBottomContainerRender && this.state.resize) {
            this.setState({resize: false});
            this.props.sidePlayersContainerActions.hide();
        }
    }

    checkSubscriptionData = async data => {
        if (!data) return;
        if (!this.state.played) {
            this.setState({ played: true, data }, async () => {
                this.beginAnimation();
            });
        } else {
            this.setState({data});
        }
    };

    beginResizeAnimation = () => this.setState({resize: true})

    clearResize = () => this.setState({resize: false})

    beginAnimation = () => {
        let theme = this.context && this.context.screens && this.context.screens.schedule;
        const interval = theme.speedPanel;
        let timer = interval * ((this.state.data.scheduledMatches && this.state.data.scheduledMatches.length) || 0);

        this.props.statusCallback(this.state.callbackName, 'opening');

        this.setState({displayTitle: true});
        delay(() => this.setState({ displayTable: true }), interval);
        delay(() => this.props.statusCallback(this.state.callbackName, 'open'), timer+=interval);
    };

    getDay = (dayNo) => {
        let answer = ' '
        // Sunday - Saturday : 0 - 6
        if (dayNo === 0) {
            answer = ' Sun'
        } else if (dayNo === 1) {
            answer = ' Mon'
        } else if (dayNo === 2) {
            answer = ' Tue'
        } else if (dayNo === 3) {
            answer = ' Wed'
        } else if (dayNo === 4) {
            answer = ' Thur'
        } else if (dayNo === 5) {
            answer = ' Fri'
        } else if (dayNo === 6) {
            answer = ' Sat'
        }
        return answer
    }


    clearPage = () => {
        let theme = this.context && this.context.screens && this.context.screens.schedule;
        const interval = theme.speedPanel;
        let timer = interval * ((this.state.data.scheduledMatches && this.state.data.scheduledMatches.length) || 0);

        this.props.statusCallback(this.state.callbackName, 'closing');
        this.setState({displayTable: false});
        // delay(() => this.setState({resize: false}), timer += interval);
        delay(() => this.setState({displayTitle: false}), timer += interval);
        delay(() => this.props.statusCallback(this.state.callbackName, 'closed'), timer += interval);
    };

    render() {
        const scheduledMatches = this.state.data.scheduledMatches;
        if (!scheduledMatches) {
            return (<div></div>);
        }
        let theme = this.context;
        // const matchDay = new Date(`${this.state.data.day.substring(0, this.state.data.day.length - 2)}, 2022`);
        // const day1 = matchDay.getDay();
        let bestof3 = false
        if (scheduledMatches.filter((match) => !match.hide).length === 2 && !scheduledMatches[0].hide && !scheduledMatches[1].hide && scheduledMatches[0].bestOf === 3 && scheduledMatches[1].bestOf === 3) {
            bestof3 = true
        }
        console.log(bestof3, scheduledMatches, 'shaun bestof3')
        // // worlds2022
        // const matchDay = new Date(`${this.state.data.day.substring(0, this.state.data.day.length - 2)}, 2022`);
        // const day1 = matchDay.getDay();
        return (
            <div className={`view-schedule view-schedule--${this.state.data.theme} view-schedule--animation-${this.state.animation} container`} style={{zIndex: 3}}>
                <ScreenTitle
                    scheduledMatches={scheduledMatches}
                    title={`${this.state.data.day} MATCHUP`}
                    // subtitle={`${this.getDay(day1)}`}
                    showTitle={this.state.displayTitle}
                    theme={theme.screens && theme.screens.schedule.screenTitle || {}}
                    displayUnderline
                />
                <ScheduleComponent
                    resize={this.state.resize}
                    isShowing={this.state.displayTable}
                    scheduledMatches={scheduledMatches}
                    theme={(theme.screens && theme.screens.schedule) || {}}
                />
            </div>
        );
    }
}

Schedule.contextType = ThemeContext;

export default Schedule
