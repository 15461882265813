import styled from 'styled-components';
// import { switchProp } from "styled-tools";

export const StyleMainContainer = styled.div`
    width: 90%;
    display: block;
    padding-bottom: 10px;
    margin: auto;
`;

export const StyleTeamGrid = styled.div`
    display: grid;
    width: 100%;
    grid-template-columns: repeat(2, 1fr);    
    text-align: center;
    h3 {
        color: #EFEFEF;
        font-weight: 700;
        font-size: 20px;
        padding: 5px 0;
    }
    
    input {
        margin: 20px;
    }
`;

export const StyleInputPrediction = styled.input`
    text-align: center;
    font-size: 27px;
    border-radius: 5px;
    margin: 20px 0;
`;

export const StyleActionContainer = styled.div`
    display: block;
    text-align: left;
    margin: 10px 20px;
    .button {
        text-align: center;
    }
`;

export const StyleSeparator = styled.div`
    width: 100%;
    height: 5px;
    border-radius: 50%;
    background-color: #ffffff;
    margin: 10px 0;
`;

export const StyleContainer = styled.div`
    display: flex;
    justify-content: center;
    padding: '10px';
    flex-direction: column;
    h1 {
        width: 100%;
        text-align: left;
        font-size: 19px;
        font-weight: bold;
        margin-left: -25px;
    }
`;
