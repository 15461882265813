const MainColors = {
  Primary1: '#56FFCC',
  Primary2: '#E8E8E8',
  Primary3: '#111111',
  Support1: '#FF4D65',
  Support2: '#FFD867',
  text: '#323232',
}
export default {
  id: `msi2022`,
  name: `MSI 2022`,
  screens: {
    cornerLogo: {
      cssCornerLabelText:
        'display:none;',
      startX: '-400px',
      endX: '-400px',
      startY: '0px',
      endY: '0px',
      hideCornerLogo: false, // hide the CornerLogo by default on all screens
    },
    scheduleV2: {
      speedPanel: 200,
      background: `https://cdn.dekki.com/assets/broadcast/games/league-of-legends/backgrounds/MSI-2022_BG.png`,
      StyleTeam: `
      font-family: TRY-Grtsk-Peta-Medium;
      font-size: 28px;
      margin-left: 35px;
      margin-right: 35px;
      `,
      StyleRes: `
      font-family: Akkurat-Mono-LL-bold;
      font-size: 21px;
      `,
      StyleScheduleScreenBackground: `
      margin-left: 230px;
      margin-right: 230px;
      li { border-bottom: 3px solid rgb(50, 50, 50, 0.2);       width: 1330px; }
      li:last-child { border-bottom: none; }
      border-bottom: 15px solid;  
      border-image: linear-gradient(to right, #6ae9a4, #ea8e5f) 1;
      `,
      mainColor: ` ${MainColors.Support1}`,
      secondaryColor: `${MainColors.text}`,
      StyleTeamDetailContainer: `margin-bottom: 82px; align-items: end;`,
      css5: `
      top: 313px;
      position: relative;
      display: grid;
      grid-template-columns: 3fr 1fr 3fr;
      grid-template-rows: 1fr 15px;
      align-items: flex-start;
      text-transform: uppercase;
      overflow: hidden;
      background-color: white;
      width: 1459px;
      height: 617px;
      left: 231px; 
      font-family: TRY-Grtsk-Peta-Medium;
      font-size: 52px;
      line-height: normal;
      font-weight: normal;
      color: ${MainColors.text};
      // border-bottom: 15px solid;  
      // border-image: linear-gradient(to right, #6ae9a4, #ea8e5f) 1;
      img {
        width: 250px;
        height: 330px;
      }
      `,
      cssVersus: `color: ${MainColors.Support1}
      // display: grid;
      // grid-template-rows: 1fr 3fr 1fr; 
      // height: 70%;
      margin: auto 0;
      text-align: center;
      align-items: center;
      h1 {
          line-height: normal;
          font-family: Akkurat-Mono-LL-bold;
          font-size: 40px;
          font-weight: normal;
          color: ${MainColors.Support1};
      }
      span {
        line-height: normal;
        font-family: Akkurat-Mono-LL-bold;
        font-size: 30px;
          color: ${MainColors.Support1};
      }
      `,
      bottom: `      border-bottom: 15px solid;  
      border-image: linear-gradient(to right, #6ae9a4, #ea8e5f) 1;`,
      cssName: `
      font-family: TRY-Grtsk-Peta-Medium;
      font-size: 52px;
      line-height: normal;
      font-weight: normal;
      width: 450px;
      color: ${MainColors.text};
      `,
      StyleGameDetail: `
        line-height: normal;
        font-family: Akkurat-Mono-LL-bold;
        font-size: 40px;
        font-weight: normal;
      `,
      StyleScoreDetailContainer: `
      line-height: normal;
      font-family: Akkurat-Mono-LL-bold;
      font-size: 40px;
      font-weight: normal;
      color: ${MainColors.Support1};
      `,
      Bo5x2ScheduleV2Wrap: ``,
      Bo5x2ScheduleV2Name: ``,
      Bo5x2ScheduleV2Logo: ``,
      Bo5x2ScheduleV2Score: ``,
      StyleWrapper: `
      grid-template-columns: 41% 5% 8% 5% 41%;
      `,
      StyleLogo: `
        height: 66px;
        width: 66px;
      `,
    },
    animatedBackground: {
      defaultBackground: "https://cdn.dekki.com/uploads/broadcast-projects/msi2022/videos/MSI22_LIVING_HOLD.mp4",
      // disableAnimationBackGround: true,
      show: true,
    },
    gameBreakdown: {
      teamStats: {
        cssTeamName: "font-family: `United`; font-weight:800; color: #ffffff;",
        cssTeamStats:
          "font-family: `United`; background-color: #593d25; color: #ffffff;",
        cssWinTag: `background-color: #000000aa; color: #ffffff;`,
        cssLossTag: `background-color: #00000033; color: #ffffffcc;`,
        cssBansPicks: `color: #ffffff;`,
      },
      key: {
        cssHeader: "font-family: `United`;",
        cssTitles: "font-family: `United`;",
      },
      graph: {
        cssHeader:
          "font-family: `United`; font-weight: 400; color: #0a0b0c; background-color: #ab8652; background-image: unset;",
        cssContent:
          "font-family: `United`; font-weight: 400; background-color: #1f1d1acc; background-image: unset;",
        cssKey: "font-family: `United`; font-weight: 400;",
      },
    },
    scheduleInfoSideBar: {
      logoSrc: `https://dekki-production.s3-ap-northeast-1.amazonaws.com/uploads/leagues/msi_2022/MSI-2022_logo_gray.svg`, // top left logo
      StyleLeagueLogo: `
        width: 125px;
        height: 125px;
        `,
      StyleSidebarSchedule: `
          background: linear-gradient(${MainColors.Primary2}, #c8c8c8); border-right: 22px solid;  
          border-image: 
          linear-gradient(
            to bottom, 
            #6ae9a4, 
            #ea8e5f
          ) 1 100%;
        `,
      StyleUpcomingGameText: `
        font-family: Akkurat-Mono-LL-bold;
        font-size: 22px;
        color: ${MainColors.text};
        padding-left: 15px;
        word-spacing: -4px;
        `,
      StyleTimer: `
        font-family: BT-Brik-Regular;
        font-size: 100px;
        letter-spacing: 1px;
        color: ${MainColors.text};
        // 125% Vertical Stretch
        // 80% Horizontal Stretch
        transform: scale(0.88, 1.25);
        font-weight: normal;
        `,
      // cssTimerText: `color: #54cc4f; font-size: 22px; margin-left:20px; font-family: aeonik-medium; padding: 0px; overflow-wrap: anywhere; margin-bottom: 10px; font-weight: 400; white-space: nowrap; letter-spacing: 1px;`,
      // cssTimer: `color: white; font-size: 100px;  margin-left:20px; font-family: titling-gothic-comp-medium; letter-spacing: 3px; font-weight: 400;`,
      // cssBackground: `background: linear-gradient(#000000, #2e2e2e); border-right-color: #54cc4f;border-right-width: 22px; border-right-style: solid; letter-spacing: 3px;`,
      StyleWeekTitle: `
        font-family: Akkurat-Mono-LL-bold;
        font-size: 30px;
        color: ${MainColors.text};
        `,
      // cssDayTitle: `color: white; font-size: 30px; font-family: aeonik-medium; font-weight: 400;`,
      // cssScheduleList: `color: white; font-size: 35px; font-family: titling-gothic-comp-medium; width:100%; font-weight: 400;`,
      // cssScheduleRow: `color: white; font-size: 35px; font-family: titling-gothic-comp-medium; font-weight: 400;`,
      StyleScheduleList: `
            background: rgba(0, 0, 0, 5%);
            width: 100%;
            li{border-bottom: 3px solid rgb(50, 50, 50, 0.2);} li:last-child {border-bottom: none;}
        `,
      Styleof5GridSingleGamesContainer: `
          li{border-bottom: 3px solid rgb(50, 50, 50, 0.2);} li:last-child {border-bottom: none;}
        `,
      StyleUpcomingGameInfo: `
        bottom: 0px;
        `,
      StyleNextGame: `
        font-family: BT-Brik-Regular;
        font-size: 35px;
        color: ${MainColors.Support1};
        // 125% Vertical Stretch
        // 80% Horizontal Stretch
        // transform: scale(0.88, 1.25);
        font-weight: normal;
        `,
      StyleNextGameBo5: `
        font-family: BT-Brik-Regular;
        font-size: 35px;
        color: ${MainColors.Support1};
        // 125% Vertical Stretch
        // 80% Horizontal Stretch
        // transform: scale(0.88, 1.25);
        font-weight: normal;
        `,
      cssWin: `
        font-family: BT-Brik-Regular;
        font-size: 35px;
        color: ${MainColors.text};
        // 125% Vertical Stretch
        // 80% Horizontal Stretch
        transform: scale(0.88, 1.25);
        font-weight: normal;
        `,
      StyleCenterContainer: `
        font-family: BT-Brik-Regular;
        font-size: 35px;
        color: ${MainColors.text};
        // 125% Vertical Stretch
        // 80% Horizontal Stretch
        transform: scale(0.80, 1.25);
        font-weight: normal;
        `,
      cssScore: `
        font-family: BT-Brik-Regular;
        font-size: 35px;
        color: ${MainColors.text};
        // 125% Vertical Stretch
        // 80% Horizontal Stretch
        transform: scale(0.88, 1.25);
        font-weight: normal;
        `,
      StyleBof5ScoreSingleMatch: `        
        font-family: BT-Brik-Regular;
        font-size: 100px;
        color: ${MainColors.text};
        // 125% Vertical Stretch
        // 80% Horizontal Stretch
        transform: scale(0.80, 1.25);`,
      StyleBof5ScoreSingleMatchWinner: `
        color: ${MainColors.Support1};
        font-weight: normal;
        `,
      StyleBof5GridSingleMatch: `    grid-template-columns: 2fr 2fr 2fr;`,
    },
    bottomBar: {
      borderColor: `teamMain`,
      borderColorBase: `#181818`,
      backgroundColor: `#181818`,
      cssTeamName: "font-family: `United`, sans-serif; color:#ffffff;",
      cssNextGameTitle: "font-family: `United`, sans-serif;",
      cssTimer: "font-family: `United`, sans-serif;",
    },
    screenTitle: {
      textColor: `#ffffff`,
      cssTitle:
        `font-family:United, sans-serif; font-weight: 400; text-shadow: unset;`,
    },
    screenTitleV2: {
      StyleTitleContainer: `
        // margin-left: 17px;
        grid-template-columns: 1.07fr auto 1fr;
      `,
      StyledLogo: `
      background:
      url('https://cdn.dekki.com/assets/broadcast/games/league-of-legends/backgrounds/MSI-2022_BG-Banner_logo.png') left center;
      background-repeat: no-repeat;
      `,
      StyledBar: `
      // background: ${MainColors.Primary2}
      background:
      url('https://cdn.dekki.com/assets/broadcast/games/league-of-legends/backgrounds/MSI-2022_BG-Banner.png');
      `,
      StyledBarResizeOverWrite: `
      background:
      url('https://cdn.dekki.com/assets/broadcast/games/league-of-legends/backgrounds/MSI-2022_BG-Banner_resized.png');
      `,
      StyleSubTitle: `
      font-family: Akkurat-Mono-LL-bold;
      font-size: 25px;
      color: ${MainColors.text}; 
      letter-spacing: 0px;
      margin-left: 0px;
      margin-right: 0px;
      `,
      StyleSubTitleLeft: `
      `,
      StyleSubTitleRight: `
      `,
      StyleTeam200: `
        // margin-left: 46px;
      `,
      StyleTeam100: `
      // margin-right: 46px;
      `,
      StyledTitle: `
      font-family: BT-Brik-Regular;
      font-weight: normal;
      font-size: 66px;
      line-height: 65px;
      color: ${MainColors.text}; 
      transform: scale(0.70, 1.25);
      // 125% Vertical Stretch
      // 70% Horizontal Stretch
      font-weight: normal;
      `,
    },
    standingsV2: {
      scoreOpacityChange: false,
      showSubtext: true,
      showHeaders: true,
      showPos: true,
      animationThreeAtOnce: true,
      standingsResizeOverWrite: `
      background-image: url(https://cdn.dekki.com/assets/broadcast/games/league-of-legends/backgrounds/MSI-2022_BG_resized.png) !important; 
      background-repeat: no-repeat;
      background-size: cover;
      `,
      mainColor: MainColors.Primary1,
      StyleStandingsContainerSingle: `
        padding: 40px 65px 40px 65px !important;
        li { 
          padding-right: 0px !important;
          padding-left: 0px !important;
        }
      `,
      StyleStandingRowHeader: `width: 98%;`,
      StyleStandingRowHeaderResize: `width: 97%;`,
      StyleStandingRowHeaderSingle: `color:${MainColors.Support1};  border-bottom: 3px solid rgb(50, 50, 50, 0.2); width: 1330px; height:100%;`,
      StyleStandingRowHeaderSingleResize: `width: 1080px; color:${MainColors.Support1};  border-bottom: 3px solid rgb(50, 50, 50, 0.2);height:100%;`,
      StyleStandingRowSingle: `
        border-bottom: 3px solid rgb(50, 50, 50, 0.2);  height:100%; width: 1330px; 
      `,
      StyleStandingRowSingleResize: `
      border-bottom: 3px solid rgb(50, 50, 50, 0.2);  height:100%; width: 1080px;
    `,
      css: `
      color: ${MainColors.text};  
      font-family: TRY-Grtsk-Peta-Medium;
      font-weight: normal;
      font-size: 21px;
      background: ${MainColors.Primary2};
      border-bottom: 15px solid;  
      border-image: linear-gradient(to right, #6ae9a4, #ea8e5f) 1;
      background-image: url(https://cdn.dekki.com/assets/broadcast/games/league-of-legends/backgrounds/MSI-2022_BG.png);
      background-repeat: no-repeat;
      background-size: cover;
      grid-gap: 0%;
      padding: 60px 50px 60px 50px;
      li { border-right: 3px solid rgb(50, 50, 50, 0.2); border-left: 3px solid rgb(50, 50, 50, 0.2); 

      padding-right: 50px; padding-left: 50px;
    }
      li:first-child { border: none; }
      li:last-child { border: none; }
      `,
      StyleStandingSingle: `
        // ol { border-bottom: 3px solid rgb(50, 50, 50, 0.2); padding-bottom: 50px; }
        // ol:last-child { border-bottom: none; }
      `,
      StyleTeamPosHeader: `
      font-size: 21px;
      font-family: Akkurat-Mono-LL-bold;
      // margin-left: 10px;
      width: 85px;
      `,
      StyleTeamPosHeaderSingle: `
      font-size: 21px;
      font-family: Akkurat-Mono-LL-bold;
      margin-left: 50px;
      margin-right: 20px;
      `,
      StyleTeamPosSingle: `margin-left: 50px; margin-right: 20px;`,
      StyleTeamPos: `width: 70px;`,
      // StyleTeamLogoHeader:`width: 100px;`,
      // StyleTeamLogo:`width: 70px;`,
      StyleTeamNameHeader: `font-size: 21px; font-family: Akkurat-Mono-LL-bold;`,
      StyleTeamName: `margin-left: 15px;`,
      StyleTeamNameSingle: `margin-left: 80px;`,
      StyleTeamNameHeaderSingle: `margin-left: 80px; font-size: 21px; font-family: Akkurat-Mono-LL-bold;`,
      StyleTeamNameHeaderSingleResize: `font-size: 21px; font-family: Akkurat-Mono-LL-bold;`,
      StyleTeamReccordHeader: `font-size: 21px;letter-spacing: -6px; font-family: Akkurat-Mono-LL-bold;`,
      StyleTeamReccordHeaderSingle: `font-size: 21px; font-family: Akkurat-Mono-LL-bold; margin-right: 80px; letter-spacing: -3px;`,
      StyleTeamReccordSingle: `font-size: 28px;  font-family: TRY-Grtsk-Peta-Medium; margin-right: 80px; letter-spacing: -2px;`,
      StyleTeamReccord: `letter-spacing: -3px;  font-family: TRY-Grtsk-Peta-Medium;`,
      groupTitle: `
      color: ${MainColors.Support1};
      font-family: Akkurat-Mono-LL-bold;
      font-size: 28px;
      margin-top: 35px;
      `,
    },
  },
};
