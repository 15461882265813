"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StyleTeamContainer = exports.StyleScore = exports.StyleNext = exports.StyleMiddleInfo = exports.StyleBo3MatchContainer = void 0;

var _styledComponents = _interopRequireDefault(require("styled-components"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var StyleBo3MatchContainer = _styledComponents.default.div.withConfig({
  displayName: "styles__StyleBo3MatchContainer",
  componentId: "sc-7osnjh-0"
})(["display:grid;grid-template-columns:1fr 1.5fr 1fr;opacity:", ";margin-top:10px;:last-of-type{}", ""], function (props) {
  return props.skip ? '0.3' : '1';
}, function (props) {
  return props.cssScheduleRow;
});

exports.StyleBo3MatchContainer = StyleBo3MatchContainer;

var StyleMiddleInfo = _styledComponents.default.div.withConfig({
  displayName: "styles__StyleMiddleInfo",
  componentId: "sc-7osnjh-1"
})(["display:flex;flex-direction:column;margin:auto;"]);

exports.StyleMiddleInfo = StyleMiddleInfo;

var StyleTeamContainer = _styledComponents.default.div.withConfig({
  displayName: "styles__StyleTeamContainer",
  componentId: "sc-7osnjh-2"
})(["display:flex;margin:auto;img{width:80px;height:80px;}opacity:", ";"], function (props) {
  return props.isFinished && !props.isWinner ? '0.3' : '1';
});

exports.StyleTeamContainer = StyleTeamContainer;

var StyleNext = _styledComponents.default.div.withConfig({
  displayName: "styles__StyleNext",
  componentId: "sc-7osnjh-3"
})(["", ""], function (props) {
  return props.cssNext;
});

exports.StyleNext = StyleNext;

var StyleScore = _styledComponents.default.div.withConfig({
  displayName: "styles__StyleScore",
  componentId: "sc-7osnjh-4"
})(["", " ", ""], function (props) {
  return props.cssScore;
}, function (props) {
  return props.StyleBof3Score;
});

exports.StyleScore = StyleScore;