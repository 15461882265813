import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import NextMatchInfoCountDown from '../../storybook-built/dist/components/Games/LJL/TopLevelComponents/NextMatchInfoCountDown';
import Timer from '../../storybook-built/dist/components/Games/LJL/TopLevelComponents/Timer';
import { ThemeContext } from '../View/themes';
// import { prop } from 'styled-tools';
import { preloadImages } from '../../util/loadImageUrl';

class NextMatchInfoBottomBar extends React.Component {

    static propTypes = {
        resize: PropTypes.bool.isRequired,
        display: PropTypes.bool.isRequired,
        data: PropTypes.object,
        timerActive: PropTypes.bool,
        statusCallback: PropTypes.func,
    }

    constructor(props) {
        super(props);
        this.state = {
            resize: this.props.resize,
            display: this.props.display,
            timer: '',
            imagesLoaded: false,
        }
        const urls = [];
        if (props.data.teamsFormatedForBottomBar) {
            props.data.teamsFormatedForBottomBar.forEach(team => {
                urls.push(team.logo);
                if (team.roster) {
                    _.forEach(team.roster, player => {
                        urls.push(player.image);
                    });
                }
            });
        }
        if (urls.length > 0) {
            preloadImages(urls).then(() => this.setState({ imagesLoaded: true }));
        } else {
            this.state.imagesLoaded = true;
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (this.state.resize !== nextProps.resize) {
            this.setState({resize: nextProps.resize})
        }

        if (this.state.display !== nextProps.display) {
            this.setState({display: nextProps.display})
        }
        if (this.state.timer !== nextProps.data && nextProps.data.timer && nextProps.data.timer.timer) {
            this.setState({timer: nextProps.data.timer.timer.targetTime})
        }
        if (this.props.timerActive !== nextProps.timerActive) {

            if (nextProps.timerActive) {
                this.setState({timer: nextProps.data.timer && nextProps.data.timer.timer ? nextProps.data.timer.timer.targetTime : ''});
                this.props.statusCallback('timer', 'open');
            } else {
                this.setState({timer: 0});
                this.props.statusCallback('timer', 'closed');
            }
        }
    }

    render() {
        const theme = this.context.screens && this.context.screens.bottomBar;
        if (Object.keys(this.props.data).length === 0 && this.props.data.constructor === Object) return <div />;
        return (
            this.state.imagesLoaded && this.state.display && this.props.data.teamsFormatedForBottomBar && this.props.data.teamsFormatedForBottomBar.length >= 2 ?
                <NextMatchInfoCountDown
                timerText={this.props.data.timerText}
                timer={this.state.timer && <Timer targetTime={this.state.timer}/>}
                isShowing={this.state.resize}
                nextMatchBestOf={this.props.data.nextMatchBestOf}
                match={{
                    teams: this.props.data.teamsFormatedForBottomBar,
                    number: this.props.data.nextGameNumber,
                }}
                theme={theme || {}}
                /> : <div></div>
        );
    }
}

NextMatchInfoBottomBar.contextType = ThemeContext;

export default NextMatchInfoBottomBar;

