import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types'
import { get, values } from 'lodash';
// INTERNAL
import { updateScreenStatus } from '../../../util/controllerUtils';
import { subscribe } from '../../../services/api';
import { i18n } from '../../i18n';
import validateDataStandings from '../../Data/DataStandings/validate';
import { validateDataScheduleHasRound } from '../../Data/DataSchedule/validate';
import { getNextMatchTeams } from '../../../util/nextMatch';
import { firebaseRealtimeDbSetValue } from '../../../util/firebase';
import Picker from '../../Picker';

import ControlContainer from '../../Controller/ControlContainer';
import Button from '../../Button';
// import moment from 'moment';
import { lol_positions } from '../../../util/const'

const StandingsV2Control = (props) => {
  const [playState, setPlayState] = useState('close');
  const [selectedGroupState, setSelectedGroupState] = useState('REGULAR')
  const [standingsGroupState, setStandingsGroupState] = useState([]);

  const onPlay = () => {
    setPlayState('open');
    if (validateDataStandings() && validateDataScheduleHasRound()) {
      if (window.MainData.standings && values(window.MainData.standings).length > 1) {
        if (!selectedGroupState || selectedGroupState === 'REGULAR') {
          setPlayState('invalid');
          return;
        }
      }

      const nextMatchTeams = getNextMatchTeams(window.MainData.schedule);
      const teamsInformation = getTeamsInformationAndFormat(nextMatchTeams);
      let formattedStandingsData = [];
      if (selectedGroupState === 'ALL') {
        let groups = Object.keys(window.MainData.standings);
        groups.forEach(group => formattedStandingsData.push(formatStandingsData(window.MainData.standings, group)))
      }
      else {
        formattedStandingsData = [formatStandingsData(window.MainData.standings, selectedGroupState)];
      }

      const sendData = {
        formattedStandingsData,
        hasTeams: window.MainData.teams && true,
        teamsInformation,
        roundName: get(window, `MainData.schedule.rounds[${window.MainData.schedule.round}].name`, ''),
        splitName: get(window, `MainData.schedule.splitName`, ''),
      };

      sendData.selectedGroup = selectedGroupState || 'REGULAR';
      if (selectedGroupState && selectedGroupState !== 'REGULAR' && selectedGroupState !== 'ALL') {
        sendData.groupNames = (window.MainData.standings_groups.find(item => item.id === selectedGroupState) || {}).name;
      }
      else if (selectedGroupState && selectedGroupState === 'ALL') {
        sendData.groupNames = Object.keys(window.MainData.standings)
          .map(groupID => {
            return (get(window, 'MainData.standings_groups', []).find(item => item.id === groupID) || {}).name || '';
          });
        if (get(window, 'MainData.schedule.splitName')) {
          sendData.titleExtra = window.MainData.schedule.splitName;
        }
      }
      updateScreenStatus('standingsV2', 'open', sendData, null, props.cgpc);
    } else {
      setPlayState('invalid')
    }
  };

  const onClose = async () => {
    setPlayState('close');
    await updateScreenStatus('standingsV2', 'close', null, null, props.cgpc);
  };

  const runSubscription = () => {
    subscribe('control-standingsV2', null, data => {
      if (data.target === 'control-standingsV2') {
        setPlayState(data.status);
      }
    }, props.cgpc);
  };

  useEffect(() => {
    runSubscription();
  }, [])

  // Standings Ready
  useEffect(() => {
    const groupsList = [];
    const standings = get(props, 'standings', [])
    for (const group of standings) {
      groupsList.push({
        name: group.id,

        value: group.id,
      })
    }
    setStandingsGroupState(groupsList)
  }, [props.standings])


  const formatStandingsData = (standingsData, group = 'REGULAR') => {
    const standingsDataArr = standingsData[group] || standingsData['REGULAR'];
    const standingDataFormated = [];
    standingsDataArr && standingsDataArr.forEach(team => {
      const teamInfo = {};
      teamInfo.score = {
        win: team.win,
        loss: team.loss,
      };
      teamInfo.rankLabel = team.rankLabel;
      teamInfo.rank = team.rank;
      teamInfo.name = team.name;
      teamInfo.color = window.MainData.teams[team.tricode].color;
      teamInfo.logo = window.MainData.teams[team.tricode].images && window.MainData.teams[team.tricode].images.logoFullColor;
      teamInfo.images = window.MainData.teams[team.tricode].images && window.MainData.teams[team.tricode].images;
      teamInfo.supertext = team.supertext;
      teamInfo.tricode = team.tricode;
      standingDataFormated.push(teamInfo);
    })
    return standingDataFormated;
  }

  const getTeamsInformationAndFormat = incomingTeams => {
    const teams = [];
    if (incomingTeams) {
      Object.keys(incomingTeams).forEach(teamTricode => {
        const teamInfo = incomingTeams[teamTricode];
        const team = {};
        team.tricode = teamTricode.toLowerCase();
        team.name = teamInfo.name;
        team.color = teamInfo.color;
        team.logo = teamInfo.images && teamInfo.images.logoFullColor;
        team.roster = {};
        if (teamInfo.roster) {
          Object.keys(teamInfo.roster).forEach(player => {
            lol_positions.forEach(position => {
              if (!team.roster[position] && (teamInfo.roster[player].position === position || (position === 'ad' && teamInfo.roster[player].position === 'adc'))) {
                const image = teamInfo.roster[player].images && teamInfo.roster[player].images.voting;
                team.roster[position] = {
                  player,
                  image: image,
                }
              }
            });
          });
        }
        teams.push(team);
      });
    }
    return teams;
  }

  const changeGroup = group => {
    if (selectedGroupState !== group) {
      setSelectedGroupState(group)
      firebaseRealtimeDbSetValue('control-standingsV2/data/selectedGroup', group);
    }
  }
  return (
    <ControlContainer style={{display: props.display ? 'none': 'block', overflow: 'visible'}} status={playState} >
      <div className="control__panel">
        <h2 className="control__title">
          <i className="fas fa-history"></i> {i18n().pages.standingsV2}
        </h2>
        <Button
        controllerSide={!props.screen1}
          action={onPlay}
          text={i18n().general.open}
          icon="fas fa-play-circle"
          disabled={playState === 'open'}
        />
        <Button
        controllerSide={!props.screen1}
          action={onClose}
          text={i18n().general.close}
          icon="fas fa-stop-circle"
          disabled={!(playState === 'open')}
        />
      </div>
      {
        standingsGroupState.length > 1 &&
        <div className="control-extra">
          <div className="control-extra__fieldset">
            {
              <Picker preselect={selectedGroupState}
                items={[
                  ...standingsGroupState,
                  {
                    value: 'ALL',
                    name: 'ALL',
                  }]
                }
                onUpdate={changeGroup}
              />
            }
          </div>
        </div>
      }
      {/* Just for rumble */}
      {/* {
        standingsGroupState.length > 0 && standingsGroupState[0].name === 'rumble' || 'Knockout' &&
        <div className="control-extra">
          <div className="control-extra__fieldset">
            {
              <Picker preselect={selectedGroupState}
                items={[
                  ...standingsGroupState,
                ]
                }
                onUpdate={changeGroup}
              />
            }
          </div>
        </div>
      } */}
    </ControlContainer>
  );
};

StandingsV2Control.propTypes = {
  standings: PropTypes.array,
    cgpc: PropTypes.string,
    display: PropTypes.bool,
    screen1: PropTypes.bool,
}


export default StandingsV2Control;


