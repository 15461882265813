// REACT
import React, { Component } from 'react';
import PropTypes from 'prop-types';
// ME
import StatInput from '../';
import StatPicker from '../../../../Picker/StatPicker';

class StatInputContainer extends Component {

  static propTypes = {
    onSet: PropTypes.func,
    stats: PropTypes.object,
    defaultType: PropTypes.string,
    typeIndex: PropTypes.number,
    onChangeType: PropTypes.func,
  };

  state = {
    type: null,
  }
  componentDidMount() {
    this.setState({ type: this.props.defaultType });
  }

  onPickStat (val) {
    this.setState({ type: val }, this.notifyChange);
  }

  notifyChange () {
    this.props.onChangeType({
      typeIndex: this.props.typeIndex,
      type: this.state.type,
    });
  }

  render () {
    const onPickStat = this.onPickStat.bind(this);
    const statsArr = (this.state.type && this.props.stats && this.props.stats[this.state.type]) || [];
    return (
      <div className="column">
        <h1 className="column__title column__title--center">
          <StatPicker stats={this.props.stats}
                      onChange={onPickStat}
                      defaultValue={this.props.defaultType}
          />
        </h1>
        <StatInput index={1} onSet={this.props.onSet} type={this.state.type} stat={statsArr[0]} />
        <StatInput index={2} onSet={this.props.onSet} type={this.state.type} stat={statsArr[1]} />
        <StatInput index={3} onSet={this.props.onSet} type={this.state.type} stat={statsArr[2]} />
        <StatInput index={4} onSet={this.props.onSet} type={this.state.type} stat={statsArr[3]} />
        <StatInput index={5} onSet={this.props.onSet} type={this.state.type} stat={statsArr[4]} />
      </div>
    );
  }
};

export default StatInputContainer