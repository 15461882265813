import styled from 'styled-components';

export const Base = styled.div`
    display: flex; 
    flex-direction: column;
`;

export const Teams = styled.div`
    h1 {
        display: flex;
        justify-content: center;
        padding: 10px;
        color: white;
    }
`;

export const StyleInhibitorContainer = styled.div`
    display: grid;
    // grid-template-columns: 45px 1fr 1fr;
    justify-content: center;
    text-align: center;
    label {
        // width: unset;
        margin: auto;
        display: flex;
    align-items: center;
    justify-content: center;
    }
    .button{
        min-width: unset;
    }
    .pill{
        // border: unset;
        // border-radius: unset;
        // margin-right: unset;
        // padding: 5px 0;
        display: flex;
        align-items: center;
    }
    span {
        padding: 0 5px;
    }
`;

export const Block = styled.div`
    h1 {
        display: flex;
        justify-content: center;
        padding: 10px;
        color: white;
    }
`;

export const Column = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
`;

export const SubBlock = styled.div`
    border-radius: 5px;
    border: 2px solid white;
    padding: 5px;
    margin: 5px;
    
    h1 {
        display: flex;
        padding: 5px;
        color: white;
    }
`;

export const StyleDragonsChoicesContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(${props => props.col || '4'},1fr);
    justify-content: center;
    text-align: center;


`

export const StyleFieldGridTimerContainer = styled.div`
    display: grid;
    grid-template-columns: 60px 1fr 4fr;
    justify-content: center;
    text-align: center;
    label {
        width: unset;
        margin: auto;
    }
    span {
        padding: 0 5px;
    }

    .button {
        min-width: 55px;
        padding: 0 5px;
    }
`;

export const StyleFieldGridLiveContainer = styled.div`
    display: grid;
    grid-template-columns: 60px 1fr;
    label {
        width: unset;
        margin: auto;
    }
    span {
        padding: 0 5px;
    }

    .button {
        min-width: 55px;
        padding: 0 5px;
    }
`;

export const StyleFieldGridBuffsContainer = styled.div`
    display: grid;
    grid-template-columns: 2fr 4fr;
    margin: 5px;
    padding: 5px 10px;
    background-color: ${props => props.color};
    justify-content: center;
    text-align: center;

    span {
        padding: 0 5px;
    }

    .button {
        min-width: 55px;
    }
`;

export const StyleBuffLabel = styled.div`
grid-column-start: 1;
    grid-column-end: span 2;
    text-align: center;
    width: 100%;
`;

export const StyleDragonTypeChoicesContainer = styled.div`
    display: flex;
    margin: 15px auto 0 auto;
    line-height: 24px;
    justify-content: center;
    cursor: pointer;
    color: ${props => props.isChecked ? '#0000FF' : '#666666'};
    font-size: ${props => props.isChecked ?'20px':'16px'};
    input {
        height: 20px;
        width: 20px;
    }
`
export const StyleSeparator = styled.div`
    width: 100%;
    height: 4px;
    border-radius: 50%;
    background-color: #ccc;
    margin: 15px 5px;
`;


export const StyleDragonsChoice = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    text-align: center;
    padding: 8px 10px;
    margin: auto;
    border: 1px solid #ccc;
    border-radius: 20%;
    cursor: pointer;
    color: ${props => props.isSelected ? 'white': '#666666'};
    background-color: ${props => props.isSelected ? 'blue' : 'transparent'};
    img {
        margin: 0 auto 5px auto;
        border-radius: 10px;
    }
`

export const StyleBaronVideoSponsor = styled.div`
    grid-column-start: 1;
    grid-column-end: span 2;
    text-align: left;
    font-size: 12px;
    width: 100%;
    color: #EFEFEF;
    display: flex;
    justify-content: center;
    align-items: center;
    span {
        line-height: 33px;
        vertical-align: middle;
        padding: 0 20px;
        color: #666666;
    }
`;