export default {
  id: 'pubg2019',
  name: 'PUBG 2019',
  screens: {
    cornerLogo: {
      cssCornerLabelText: 'display:none;',
    },
    animatedBackground: {
      show: true,
      defaultBackground:
        'https://cdn.dekki.com/uploads/broadcast-projects/pubgmjc/videos/pubgmjc-bg-video_waiting_20s.webm',
    },
    waitingScreen: {
      logoSrc:
        'https://cdn.dekki.com/uploads/broadcast-projects/pubgmjc/pubgmjc-logo_season2.svg',
      videoSrc:
        'https://cdn.dekki.com/uploads/broadcast-projects/pubgmjc/videos/pubgmjc-bg-video_waiting_20s.webm',
      backgroundSrc:
        'https://cdn.dekki.com/uploads/broadcast-projects/pubgmjc/pubgmjc-bg.png',
      cssLogoContainer: 'img{width:600px; margin-left: 100px;}',
      cssTextContainer: 'padding-right: 150px;',
      messages: {
        Waiting_Message_Round: [
          {
            text: '%CURRENT_ROUND%',
            css:
              'width: 100%; color: #f2a900; font-size:100px; font-family:AgencyFB; font-weight:bold; letter-spacing: 5px;',
          },
          {
            text: 'COMING UP NEXT',
            css:
              'color: #ffffff; font-size:80px; font-family:Roboto; font-weight:bold; letter-spacing: 5px; margin-top:30px;',
          },
          {
            text: '%CURRENT_MATCH% - ROUND %CURRENT_GAME%',
            css:
              'color: #ffffff; font-size:80px; font-family:Roboto; font-weight:bold; letter-spacing: 5px;',
          },
          {
            timer: true,
            css:
              'font-size:170px; font-family:AgencyFB; font-weight:bold; color: #f2a900; letter-spacing: 5px; margin-top:30px;',
          },
        ],
        Group_Stage_Day_2: [
          {
            text: 'TUNE IN TO',
            css:
              'width: 100%; color: #f2a900; font-size:130px; font-family:AgencyFB; font-weight:800; letter-spacing: 5px;',
          },
          {
            text: 'GROUP STAGE DAY 2',
            css:
              'width:115%; color: #ffffff; font-size:120px; font-family:AgencyFB; font-weight:800; margin-top:30px; letter-spacing: 5px;',
          },
          {
            text: 'SEPTEMBER 29TH AT 13:00',
            css:
              'width:100%; color: #f2a900; font-size:70px; font-family:AgencyFB; font-weight:800; letter-spacing: 2px;',
          },
        ],
        Semi_Finals_Day_1: [
          {
            text: 'TUNE IN TO',
            css:
              'width: 100%; color: #f2a900; font-size:130px; font-family:AgencyFB; font-weight:800; letter-spacing: 5px;',
          },
          {
            text: 'SEMI FINALS DAY 1',
            css:
              'width:110%; color: #ffffff; font-size:120px; font-family:AgencyFB; font-weight:800; margin-top:30px; letter-spacing: 5px;',
          },
          {
            text: 'OCTOBER 12TH AT 15:00',
            css:
              'width:100%; color: #f2a900; font-size:70px; font-family:AgencyFB; font-weight:800; letter-spacing: 2px;',
          },
        ],
        Semi_Finals_Day_2: [
          {
            text: 'TUNE IN TO',
            css:
              'width: 100%; color: #f2a900; font-size:130px; font-family:AgencyFB; font-weight:800; letter-spacing: 5px;',
          },
          {
            text: 'SEMI FINALS DAY 2',
            css:
              'width:110%; color: #ffffff; font-size:120px; font-family:AgencyFB; font-weight:800; margin-top:30px; letter-spacing: 5px;',
          },
          {
            text: 'OCTOBER 19TH AT 15:00',
            css:
              'width:100%; color: #f2a900; font-size:70px; font-family:AgencyFB; font-weight:800; letter-spacing: 2px;',
          },
        ],
        Final: [
          {
            text: 'TUNE IN TO',
            css:
              'width: 100%; color: #f2a900; font-size:130px; font-family:AgencyFB; font-weight:800; letter-spacing: 5px;',
          },
          {
            text: 'FINAL',
            css:
              'width:110%; color: #ffffff; font-size:120px; font-family:AgencyFB; font-weight:800; margin-top:30px; letter-spacing: 5px;',
          },
          {
            text: 'NOVEMBER 3RD 15:00',
            css:
              'width:100%; color: #f2a900; font-size:70px; font-family:AgencyFB; font-weight:800; letter-spacing: 2px;',
          },
        ],
        ThankYouMessage: [
          {
            text: 'THANK YOU FOR WATCHING',
            css:
              'width:100%; color: #ffffff; font-size:130px; font-family:AgencyFB; font-weight:800; margin-top:30px; letter-spacing: 5px;',
          },

        ],
      },
    },
    teamlessSchedule: {
      videoBGsrc:
        'https://cdn.dekki.com/uploads/broadcast-projects/pubgmjc/videos/pubgmjc-bg-video_schedule_20s.webm',
      baseBGsrc:
        'https://cdn.dekki.com/uploads/broadcast-projects/pubgmjc/pubgmjc-bg-schedule.png',
      logoSrc:
        'https://cdn.dekki.com/uploads/broadcast-projects/pubgmjc/pubgmjc-logo-black.svg',
      scheduleBGsrc:
        'https://cdn.dekki.com/uploads/broadcast-projects/pubgmjc/pubgmjc-bg-black-box.png',
      headerBGsrc:
        'https://cdn.dekki.com/uploads/broadcast-projects/pubgmjc/pubgmjc-header-bg-yellow.png',
      title: 'COMING UP',
      speedPanel: 200,
      cssRowMatch: '',
      cssRowMatchFeature: '',
      cssRowMatchPassed: '',
      cssDate: '',
      cssDateFeature: '',
      cssDateFeaturePassed: '',
      cssMatch: '',
      cssMatchFeature: '',
      cssMatchFeaturePassed: '',
      cssGame: '',
      cssGameFeature: '',
      cssGameFeaturePassed: '',
    },
    resultsList: {
      videoBGsrc:
        'https://cdn.dekki.com/uploads/broadcast-projects/pubgmjc/videos/pubgmjc-bg-video_results_20s.webm',
      baseBGsrc:
        'https://cdn.dekki.com/uploads/broadcast-projects/pubgmjc/pubgmjc-bg-schedule.png',
      baseResultsBGsrc:
        'https://cdn.dekki.com/uploads/broadcast-projects/pubgmjc/pubgmjc-bg-black-box.png',
      logoSrc:
        'https://cdn.dekki.com/uploads/broadcast-projects/pubgmjc/pubgmjc-logo-lockup_season2.png',
      headerMainBGsrc:
        'https://cdn.dekki.com/uploads/broadcast-projects/pubgmjc/pubgmjc-header-bg-blue-long.png',
      headerSubBGsrc:
        'https://cdn.dekki.com/uploads/broadcast-projects/pubgmjc/pubgmjc-header-bg-grey-long.png',
      cssLogo: '',
      cssTitleSub: 'color:#d3d5d6',
      cssTitleMain: '',
      cssTableHeader: '',
      cssRank: 'background-color:#307dec;',
      cssTeamName: 'background-color: #b3b1b1;',
      cssTotalPoints: '',
      cssScore: '',
      cssPoints: '',
    },
  },
};
