"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StyleMain = exports.StyleHeader = exports.StyleChampionsPool = exports.StyleChampion = void 0;

var _styledComponents = _interopRequireDefault(require("styled-components"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var StyleMain = _styledComponents.default.div.withConfig({
  displayName: "styles__StyleMain",
  componentId: "sc-ilfl1k-0"
})(["position:absolute;display:grid;bottom:120px;grid-template-rows:45px 150px;width:100%;grid-gap:15px;transition:transform 1s ease-in-out;", ""], function (props) {
  return props.show ? "transform: translateX(0);" : "transform: translateX(-100%);";
});

exports.StyleMain = StyleMain;

var StyleHeader = _styledComponents.default.div.withConfig({
  displayName: "styles__StyleHeader",
  componentId: "sc-ilfl1k-1"
})(["background:", ";font-family:futura-pt,sans-serif;font-weight:500;font-size:28px;line-height:45px;padding-left:40px;padding-right:40px;width:fit-content;color:#ffffff;span{font-weight:700;display:inline;}"], function (props) {
  return props.background || '';
});

exports.StyleHeader = StyleHeader;

var StyleChampionsPool = _styledComponents.default.div.withConfig({
  displayName: "styles__StyleChampionsPool",
  componentId: "sc-ilfl1k-2"
})(["border-right:#ffffff 10px solid;width:fit-content;"]);

exports.StyleChampionsPool = StyleChampionsPool;

var StyleChampion = _styledComponents.default.div.withConfig({
  displayName: "styles__StyleChampion",
  componentId: "sc-ilfl1k-3"
})(["width:300px;height:150px;background:", ";background-position:center 20%;background-size:210%;display:inline-block;"], function (props) {
  return "url(".concat(props.img, ")");
});

exports.StyleChampion = StyleChampion;