// REACT
import React, { Component } from 'react';
import PropTypes from 'prop-types';
//ME
import PickerBase from '../../Picker/PickerBase';
import StatPickerService from '../../../services/statPickerService';

class StatPicker extends Component {

  static propTypes = {
    onChange: PropTypes.func,
    defaultValue: PropTypes.string,
    showSelectOption: PropTypes.bool,
    statsList: PropTypes.array,
  };

  render() {
    return (
      <PickerBase onChange={this.props.onChange}
                  service={this.props.statsList || StatPickerService}
                  defaultValue={this.props.defaultValue}
                  showSelectOption={this.props.showSelectOption !== undefined? this.props.showSelectOption : true}
      />
    );
  };
}

export default StatPicker;
