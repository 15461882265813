import moment from 'moment';
import { previewGameInfo } from '../lib/allstats-sdk/api';
import {i18n} from '../components/i18n';
import champList from './championService';

const getPreviewGameInfo = (platform, gameID, getRawResponse) => {
  return previewGameInfo(platform, gameID)
    .then(res => {
      if (res.error) {
        throw res.error;
      }
      if (!res.gameIsComplete) {
        throw i18n().error.gameNotComplete;
      }
      if (!res.gameData || !res.timeline) {
        throw i18n().error.gameNotFound;
      }
      if (!champList) {
        throw i18n().error.noChampList;
      }
      if (!res.gameData.gameInfo
       || !res.gameData.gameInfo.bannedChampions
       || !res.gameData.participants
       || !res.gameData.gameStats
       || !res.gameData.gameStats.teamStats
      ) {
        throw i18n().error.dataMalformed;
      }

      if (getRawResponse) return res;

      const bans = res.gameData.gameInfo.bannedChampions.map(p => champList[p.championId] && champList[p.championId].id);
      const picks = res.gameData.participants.map(p => champList[p.championId] && champList[p.championId].id);
      const summonerNames = res.gameData.participants.map(p => p.summonerName);
      const winner = res.gameData.gameStats.teamStats[0].win === 'Win' ? 'team100' : 'team200';
      const date = moment(res.gameData.gameInfo.gameCreationTime);
      const gameCreatedAt = `${date.format('YYYY/MM/DD hh:mm:ss')} (${date.fromNow()})`;
      const previewData = {
        picks,
        bans,
        summonerNames,
        winner,
        gameCreatedAt,
      };
      return previewData;
    });
}

export default getPreviewGameInfo;
