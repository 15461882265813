import styled from 'styled-components';

export const StyleMythicItemContainer = styled.div`
  padding: 0 5px 5px 5px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  img {
    /* padding: 10px;
    max-width: 60%; */
  }
  textarea {
    /* margin-top: 10px; */
    width: 300px;
    min-height: 50px;
    font-size: 20px;
    resize: none;
    text-align: center;
  }
`;
