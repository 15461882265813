import { get } from 'lodash';
import { firebaseRealtimeGetValue, firebaseRealtimeDbSetValue } from './firebase';

const getAssetList = async () => {
    const assets = await firebaseRealtimeGetValue('view/assetsList');
    if(!Array.isArray(assets)){return []}
    return assets;
}

const setAssetList = async (assetsList) => {
    await firebaseRealtimeDbSetValue('view/assetsList', assetsList);
}

export const addAsset = async (newAsset) => {
    // console.log("addAsset");
    if(!newAsset) return;
    let currentList = await getAssetList();
    currentList.push(newAsset);
    await setAssetList(currentList);
}

export const removeChampionsAssets = async () => {
    // console.log("removeChampionsAssets");
    const currentList = await getAssetList();
    await setAssetList(currentList.filter((url)=> (!url.startsWith('https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions'))));
}

export const setMatchRosterAssets = async (round, matchIndex) => {
    // console.log("setMatchRosterAssets");

    let currentAssetList = await getAssetList();
    currentAssetList = currentAssetList.filter(url => !((url.startsWith('https://cdn.dekki.com/uploads/players/') || (url.startsWith('https://cdn.dekki.com/uploads/teams/')))));

    const match = get(window, `MainData.schedule.rounds[${round || 0}].matches[${matchIndex || 0}]`);

    const team100RosterMatchup =  Object.values(get(match, 'team100.roster',{})).map(player => get(player, 'images.matchup'));
    const team100RosterStarters =  Object.values(get(match, 'team100.roster',{})).map(player => get(player, 'images.results'));
    const team200RosterMatchup =  Object.values(get(match, 'team200.roster',{})).map(player => get(player, 'images.matchup'));
    const team200RosterStarters =  Object.values(get(match, 'team200.roster',{})).map(player => get(player, 'images.results'));

    const newAsset = [
        // team100
        get(match, 'team100.images.logoFullColor'),
        get(match, 'team100.images.logoSplash0'),
        get(match, 'team100.images.logoSplash1'),
        get(match, 'team100.images.logoSplash2'),
        get(match, 'team100.images.logoSplash3'),
        get(match, 'team100.images.logoSplash4'),
        ...team100RosterMatchup,
        ...team100RosterStarters,

        // team200
        get(match, 'team200.images.logoFullColor'),
        get(match, 'team200.images.logoSplash0'),
        get(match, 'team200.images.logoSplash1'),
        get(match, 'team200.images.logoSplash2'),
        get(match, 'team200.images.logoSplash3'),
        get(match, 'team200.images.logoSplash4'),
        ...team200RosterMatchup,
        ...team200RosterStarters,
    ];
    // console.log("currentAssetList", currentAssetList);
    // console.log("newAsset", newAsset);
    await setAssetList([...currentAssetList, ...newAsset]);
}

