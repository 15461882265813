// REACT
import React, { Component } from 'react';
import styled from 'styled-components';
import { cloneDeep, get } from 'lodash';

// internal
import {i18n} from '../../i18n';
import Button from '../../Button';
import DataContainer from '../DataContainer';
import { getStandingsTable } from '../../../services/standingsService';
import DataStandingsTable from './DataStandingsTable';

export const defaultStandingsGroup = { id: 'REGULAR', name: 'REGULAR'};

class DataStandings extends Component {

    state = {
        standings: [],
        sortedStandings: [],
        teams: window.MainData.teams || {},
    };

    async componentDidMount() {
        // load default standings to state
        const standings = {};
        const standings_groups = window.MainData.standings_groups || [defaultStandingsGroup];
        const teams = this.state.teams;
        standings_groups.map(groupData => {
            const group = groupData.id;
            standings[group] = {};
            let groupTeamNames = Object.keys(teams);
            if (groupData !== defaultStandingsGroup) {
                groupTeamNames = Object.keys(teams).filter(teamName => teams[teamName].group === group);
            }
            groupTeamNames.map((team) => {
                standings[group][team] = {win: 0, loss: 0, name: teams[team].name, tricode: teams[team].tricode.toUpperCase(), supertext: teams[team].supertext};
                return null;
            });
            return standings;
        });
        const sortedStandings = await this.getSortedStandingsArray(standings);
        this.setState({standings, sortedStandings});
        window.MainData.standings = sortedStandings;
    }

    updateResult = (tricode, winloss, modifier, roundQuery) => {
        const standings = cloneDeep(this.state.standings);
        if (standings[roundQuery] && standings[roundQuery][tricode]) {
            const newval = (standings[roundQuery][tricode][winloss] + modifier);
            standings[roundQuery][tricode.toUpperCase()][winloss] = newval > 0 ? newval : 0;
            this.setState({
                standings,
            }, async () => {
                    this.setState({
                        sortedStandings: await this.getSortedStandingsArray(this.state.standings),
                    }, () => (window.MainData.standings = this.state.sortedStandings))
            });
        } else {
            console.log('cant update standings for', tricode, winloss, modifier, roundQuery);
        }
    }

    compare = (a, b) => {
        if (a.win < b.win) {
            return 1;
        }
        else if(a.win > b.win) {
            return -1;
        }
        else if (a.loss > b.loss) {
            return 1;
        }
        else if (a.loss < b.loss) {
            return -1;
        }

        // if (a.gameDiff < b.gameDiff) {
        //     return 1;
        // }
        // if (a.gameDiff > b.gameDiff) {
        //     return -1;
        // }
        return 0;
    }

    getSortedStandingsArray = standings => {
        const sortedArray = {};
        Object.keys(standings).forEach(standings_group => {
            let sorted = Object.keys(standings[standings_group]).map(team => {
                const t = standings[standings_group][team];
                return {
                    tricode: t.tricode,
                    supertext: t.supertext,
                    name: t.name,
                    win: t.win,
                    sort: t.sort,
                    loss: t.loss,
                    winPerc: (100 * t.win || 0 / (t.win || 0 + t.loss || 0)) || 0,
                    gameDiff: t.win - t.loss,
                }
            })
            sorted.sort(this.compare);
            sorted = sorted.map((team, index) => {return {...team, rank: index+1, rankLabel: `${index+1}`}});
            sortedArray[standings_group] = sorted || [];
        });
        return sortedArray || {};
    }

    recalculateStandings = () => {
        const sortedStandings = this.getSortedStandingsArray(this.state.standings);
        this.setState({sortedStandings});
        window.MainData.standings = sortedStandings;
    }

    resetStandings = async () => {
        const newStandings = Object.assign({}, this.state.standings);
        Object.keys(newStandings).map(async standings_group => {
            Object.keys(newStandings[standings_group]).forEach(team => {
                newStandings[standings_group][team].win = 0;
                newStandings[standings_group][team].loss = 0;
            });
        });
        this.setState({sortedStandings: this.getSortedStandingsArray(newStandings)}, () => (window.MainData.standings = this.state.sortedStandings));
    }

    loadStandings = async () => {
        const season = window.MainData.schedule.season;
        const league = window.MainData.schedule.league;
        const split = window.MainData.schedule.split;
        const standings_groups = window.MainData.standings_groups || [defaultStandingsGroup];
        const standings = {};
        const sortedStandings = {};
        const teams = this.state.teams;
        standings_groups.map(async groupData => {
            const group = groupData.id;
            const importStandings = await getStandingsTable(season, league, split, group);

            if (importStandings.splitRecords) {
                sortedStandings[group] = importStandings.splitRecords.filter(team => get(this.state, `teams[${team.team}].name`)).map((team, i) => ({
                    loss: team.loses,
                    tricode: team.team,
                    supertext: team.supertext,
                    name:  this.state.teams[team.team] && this.state.teams[team.team].name,
                    win: team.wins,
                    sort: i,
                    gameDiff: (team.wins - team.loses),
                    winPerc: (100 * team.wins / (team.wins + team.loses)),
                }));
                let groupTeamNames = Object.keys(teams);
                if (groupData !== defaultStandingsGroup) {
                    groupTeamNames = Object.keys(teams).filter(teamName => teams[teamName].group === group);
                }
                groupTeamNames.forEach(groupTeam => {

                    if (!sortedStandings[group].some(team => team.tricode === groupTeam)) {
                        sortedStandings[group].push({
                            loss: 0,
                            tricode: groupTeam,
                            supertext: this.state.teams[groupTeam].supertext,
                            name: this.state.teams[groupTeam].name,
                            win: 0,
                            sort: 0,
                            gameDiff: 0,
                            winPerc: 50,
                        });
                    }
                })
                standings[group] = {};
                groupTeamNames.forEach(teamName => {
                    this.state.teams &&
                        this.state.teams[teamName] &&
                        (standings[group][teamName] = {
                        win: (sortedStandings[group].find(team => team.tricode === teamName) && sortedStandings[group].find(team => team.tricode === teamName).win) || 0,
                        loss: (sortedStandings[group].find(team => team.tricode === teamName) && sortedStandings[group].find(team => team.tricode === teamName).loss) || 0,
                        name: this.state.teams[teamName].name,
                        tricode: teamName,
                        })

                    });
                this.setState({sortedStandings: this.getSortedStandingsArray(sortedStandings), standings});
                window.MainData.standings = this.getSortedStandingsArray(sortedStandings);
            } else {
                console.error('failed loading standings due to invalid repsonse from API'); // eslint-disable-line no-console
            }
        });
    }

    updateRankLabel(teamIndex, rankLabel, group) {
        const sortedStandings = cloneDeep(this.state.sortedStandings);
        sortedStandings[group][teamIndex].rankLabel = rankLabel;
        this.setState({
            sortedStandings,
        }, () => {
            window.MainData.standings = this.state.sortedStandings
        });
    }

    moveTeam(teamIndex, group, direction) {
        const sortedStandings = cloneDeep(this.state.sortedStandings);
        if (direction === 'up') {
            const temp = sortedStandings[group][teamIndex];
            sortedStandings[group][teamIndex] = {
                ...sortedStandings[group][teamIndex - 1],
                rank: teamIndex + 1,
                rankLabel: `${teamIndex + 1}`,
            };
            sortedStandings[group][teamIndex - 1] = {
                ...temp,
                rank: teamIndex,
                rankLabel: `${teamIndex}`,
            };
        }
        else if (direction === 'down') {
            const temp = sortedStandings[group][teamIndex];
            sortedStandings[group][teamIndex] = {
                ...sortedStandings[group][teamIndex + 1],
                rank: teamIndex + 1,
                rankLabel: `${teamIndex + 1}`,
            };
            sortedStandings[group][teamIndex + 1] = {
                ...temp,
                rank: teamIndex + 2,
                rankLabel: `${teamIndex + 2}`,
            };
        }
        this.setState({ sortedStandings }, ()=>{window.MainData.standings = this.state.sortedStandings})
    }

    render() {
        return (
            <DataContainer componentSlug="standings" icon="fas fa-list-ul">
                <StyleDataStandingPanel className="data-panel__fieldset">
                    {
                        Object.keys(this.state.sortedStandings).sort().map(group =>
                            this.state.sortedStandings[group].length ?
                                <DataStandingsTable
                                    key={group}
                                    showGroup={(Object.keys(this.state.sortedStandings).length || 0) > 1}
                                    standings={this.state.sortedStandings[group]}
                                    group={group}
                                    updateResult={this.updateResult}
                                    teams={this.state.teams}
                                    roundQuery={group}
                                    moveUp={(teamIndex) => { this.moveTeam(teamIndex, group, 'up') }}
                                    moveDown={(teamIndex) => { this.moveTeam(teamIndex, group, 'down') }}
                                    onRankLabelChanged={(teamIndex, rankLabel) => {this.updateRankLabel(teamIndex, rankLabel, group)}}
                                />
                                :
                                <div
                                    key={`none-${group}`}
                                />
                        )
                    }
                </StyleDataStandingPanel>
                <div className="data-panel__fieldset">
                    <div className="row">
                        <div className="pill">
                            {/* <Button action={this.recalculateStandings} text={i18n().general.recalculate} /> */}
                            <Button action={this.resetStandings} text={'Reset'} />
                            <Button action={this.loadStandings} text={i18n().general.load} />
                        </div>
                    </div>
                </div>
            </DataContainer>
        );

    }

}

const StyleDataStandingPanel = styled.div`
    display: flex;
    flex-flow: wrap;
`;

export default DataStandings
