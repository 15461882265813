import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { ImageContainer, GradientContainer } from './styles';
//3RD PARTY
import styled from 'styled-components';
// import _ from 'lodash';

import { ThemeContext } from '../../../View/themes';
import { preloadImages } from '../../../../util/loadImageUrl';

const smallBrush = 'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/view/big-brush.png';

const MatchupBarPlayer = (props) => {
  const context = useContext(ThemeContext);
  const theme = context.screens.matchUp || {};

  const playerImage = props.player.imageUrlPlayerMatchup;
  // console.log('props', props.player);

  const champStyle = {
    backgroundImage: 'url(' + props.player.imageUrlChampCentered + ')',
    backgroundPosition: props.player.imageUrlPlayerMatchup ?
     props.side === 'left' ? '50px' : '-50px'
     :
     props.side === 'left' ? '200px' : '-200px',
     width: props.player.imageUrlPlayerMatchup ?'706px':"100%",
  };

  const [imageLoaded, setImageLoaded] = React.useState(false);
  React.useEffect(() => {
    if (!imageLoaded) {
      if (props.teamRoster) {
        const uris = [];
        if (props.player && props.player.imageUrlChampCentered) {
          uris.push(props.player.imageUrlChampCentered);
        }
        if (props.player && props.player.imageUrlPlayerMatchup) {
          uris.push(props.player.imageUrlPlayerMatchup);
        }
        if (uris.length > 0) {
          preloadImages(uris).then(() => setImageLoaded(true));
        }else{
          setImageLoaded(true);
        }
      }else{
        setImageLoaded(true);
      }
    }
  }, [imageLoaded]);
  React.useEffect(() => setImageLoaded(false), [props.player, props.teamRoster]);

  const StyleTopBar = styled.div`
    overflow: hidden;
    ${props => props.index === 0? props.cssTopBar:''}
  `;

  const StylePlayerId = styled.span`
    ${props => props.css}
  `;


  return (
    (props.teamRoster && imageLoaded) ?
      <StyleTopBar index={props.index} cssTopBar={theme.cssTopBar||''} className={`matchup__container__bar__player matchup__container__bar__player--${props.side} ${props.index === 0 ? 'matchup__container__bar__player--first' : ''}`}>
        {playerImage && <ImageContainer teamMainColor={props.teamMainColor} backgroundImage={smallBrush} isFullSize={playerImage == null}>
          <img alt='' src={playerImage} />
        </ImageContainer>}
        <GradientContainer teamMainColor={props.teamSubColor} side={props.side} isFullSize={playerImage == null}/>
        <div className={`matchup__container__bar__player__hero matchup__container__bar__player__hero--${props.side} champion`} style={champStyle}>
          <StylePlayerId css={theme.cssPlayerId||''} className="matchup__container__bar__player__hero__name">{props.player.playerId}</StylePlayerId>
          {props.player.realname && <span className="matchup__container__bar__player__hero__real-name">{props.player.realname}</span>}
        </div>
      </StyleTopBar>
      : <div />
  )
};

MatchupBarPlayer.propTypes = {
  player: PropTypes.object,
  side: PropTypes.string,
  teamRoster: PropTypes.any,
  teamMainColor: PropTypes.string,
  teamSubColor: PropTypes.string,
  index: PropTypes.number,
  cssTopBar: PropTypes.string,
  css: PropTypes.string,
};

export default MatchupBarPlayer
