import React from 'react'
import PropTypes from 'prop-types'
import { get, round, findKey } from 'lodash';

import { getInfoForTeam, statsDictionaryForTeams } from '../../../services/keyPlayerService';
import DataContainer from '../DataContainer';
import Picker from '../../Picker';
import Button from '../../Button';
import {
    Base,
    StylePlayerSelectionGrid,
    StyleStatsPickerContainer,
    StylePlayerContainer,
    StyleStatsSeparator,
    StyleStatsGrid,
    StatContainer,
} from './styles';


function DataGameScreenKeyTeams(props) {
    const [allStatsState, setAllStatsState] = React.useState({});

    const [teamsOptionState, setTeamsOptionState] = React.useState([]);
    const [statsOptionState, setStatsOptionState] = React.useState([]);

    const [modeState, setModeState] = React.useState('single');
    const [teamsSelectionState, setTeamsSelectionState] = React.useState(['','']);
    const [statsSelectionState, setStatsSelectionState] = React.useState(['kills', 'soloKills', 'teamBaronKills', 'kda', 'csPerGame']);
    const [statsValueState, setStatsValueState] = React.useState({teamLeft: [], teamRight: []});


    const getTeamStatsOptions = () => {
        const result = [];
        for(const key in statsDictionaryForTeams){
            result.push({
                name: key,
                value: statsDictionaryForTeams[key],
            })
        }
        return result;
    }

    React.useEffect(() => {
        setTeamsOptionState(Object.values(props.teams || {}).map(team => ({name: team.name, value: team.tricode})));
        setStatsOptionState(getTeamStatsOptions());
    }, [props.teams])

    const handleTeamSelection = async (team, index) => {
        setTeamsSelectionState(current => {
            const newTeamSelection = [...current];
            newTeamSelection[index] = team;
            return newTeamSelection;
        })
        if(!allStatsState[team]){
            const teamStats = await getInfoForTeam(
                props.schedule.season,
                props.schedule.league,
                props.schedule.split,
                team);
            setAllStatsState(current => {
                const newAllStats = {...current};
                newAllStats[team] = teamStats;
                return newAllStats;
            })
        }
    }

    const getStatValue = (teamIndex, statIndex) => {
        const teamStats = get(allStatsState, `[${teamsSelectionState[teamIndex]}].stats`, '');
        const statName = get(statsSelectionState, `[${statIndex}]`,'');
        let result = '0';
        switch(statName){
            case 'KP@15':
                if(teamStats){
                    result = Math.round(((Number(teamStats['assistsAt15']) + Number(teamStats['killsAt15'])) / Number(this.state.teamStats['killsAt15']))*100);
                }
                break;
            case 'K+A@15':
                result = (Number(teamStats['assistsAt15PerGame']) + Number(teamStats['killsAt15PerGame']));
                break;
            case 'VS/GAME':
                result = round(Number(teamStats['visionScore']) / teamStats['gameCount'], 1);
                break;
            case 'VS/MIN':
                result =  round(Number(teamStats['visionScore']) / (teamStats['timePlayed']/ 60), 1);
                break;
            default:
                result = get(teamStats, `${statName}`, 0);
                break;
        }
        if(get(statsValueState, `[${teamIndex === 0?'teamLeft': 'teamRight'}][${statIndex}]`,0) !== result){
            setStatsValueState(current => {
                const newStatsValue = {...current};
                newStatsValue[teamIndex === 0?'teamLeft': 'teamRight'][statIndex] = result;
                return newStatsValue;
            });
        }
        return result;
    }

    const handleStatSelection = (stat, index) => {
        setStatsSelectionState(current => {
            const newStatsSelection = [...current];
            newStatsSelection[index] = stat;
            return newStatsSelection;
        })
    }

    const saveInformation = () => {
        window.MainData.gameScreenTeamsStats = {
            teams: [0, 1].map(teamIndex => ({
                name:  get(props.teams, `[${teamsSelectionState[teamIndex].toUpperCase()}].name`, ''),
                        team: teamsSelectionState[teamIndex],
                        teamColors: get(props.teams, `[${teamsSelectionState[teamIndex].toUpperCase()}].color`, undefined),
                        teamBGImg: get(props.teams, `[${teamsSelectionState[teamIndex].toUpperCase()}].images.logoBGFull`, undefined),
                        teamBGVideoLeft: get(props.teams, `[${teamsSelectionState[teamIndex].toUpperCase()}].videos.keyPlayersLeft`, undefined),
                        teamBGVideoRight: get(props.teams, `[${teamsSelectionState[teamIndex].toUpperCase()}].videos.keyPlayersRight`, undefined),
                        stats: statsValueState[teamIndex === 0? 'teamLeft': 'teamRight'],
                        images: get(props.teams, `[${teamsSelectionState[teamIndex].toUpperCase()}].images`, undefined),
            })),
            stats: statsSelectionState.map(stat => findKey(statsDictionaryForTeams, val => val === stat) || stat),
            mode: modeState,
        };
    };

    return (
        <DataContainer componentSlug="keyTeamsGameScreen" icon="fas fa-star">
            <div className="data-panel__fieldset">
                <Base>
                    <div className="pill" style={{textAlign: 'center'}}>
                        {
                            modeState === 'single' ?
                            [
                                <span key="1a" className="pill__span--highlighted">{'Single'}</span>,
                                    <Button key="1b"  text={'Dual'} action={() => setModeState('dual')} />,
                            ] :
                            [
                                <Button key="2b" text={'Single'} action={() => setModeState('single')} />,
                                <span key="2a" className="pill__span--highlighted">{'Dual'}</span>,
                            ]
                        }
                    </div>
                    <StylePlayerSelectionGrid columns={modeState}>
        {/* Left Selector */}
                        <StylePlayerContainer>
                            {(modeState === 'dual')? <h3>Team Left</h3>: null}
                            <div>
                                <div className="pill">
                                    <span>Team</span>
                                    <Picker
                                        items={teamsOptionState}
                                        onUpdate={ (team) => { handleTeamSelection(team, 0) }}
                                        preselect={teamsSelectionState[0] || ''}
                                    />
                                </div>
                            </div>
                        </StylePlayerContainer>
        {/* Right Selector */}
                        {((modeState === 'dual') &&
                            <StylePlayerContainer>
                                <h3>Team Right</h3>
                                <div>
                                    <div className="pill">
                                        <span>Team</span>
                                        <Picker
                                        items={teamsOptionState}
                                        onUpdate={ (team) => { handleTeamSelection(team, 1) }}
                                        preselect={teamsSelectionState[1] || ''}
                                        />
                                    </div>
                                </div>
                            </StylePlayerContainer>)
                        || <div/>}
                        <StyleStatsSeparator/>
                    </StylePlayerSelectionGrid>
                <StyleStatsGrid>
    {/* Stats Selection */}
                <h3>{'Stats'}</h3>
                {
                    [0,1,2,3,4].map((statIndex) => (
                        <StyleStatsPickerContainer
                            key={`stat-choice-${statIndex}`}
                            className="pill"
                            disabled={((modeState === 'dual') && (statIndex > 2))}
                        >
                            <span>{`Stat ${statIndex + 1}`}</span>
                            <Picker
                                items={statsOptionState}
                                onUpdate={value => handleStatSelection(value, statIndex)}
                                preselect={statsSelectionState[statIndex] || ''}
                            />
                        </StyleStatsPickerContainer>
                    ))
                }
    {/* Team Left Stats */}
                <StyleStatsSeparator/>
                <h3>{'Team Left'}</h3>
                {   [0,1,2,3,4].map((statIndex) =>
                    <StatContainer
                        key={`stat-loaded-${statIndex}`}
                        className="pill"
                        disabled={((modeState === 'dual') && (statIndex > 2))}
                    >
                        {/* <span>{this.state.stats[index]}</span> */}
                        <p title={statsSelectionState[statIndex]}>{getStatValue(0, statIndex)}</p>
                    </StatContainer>
                )}
    {/* Team Right Stats */}
                {modeState === 'dual' &&
                    <React.Fragment>
                        <StyleStatsSeparator/>
                        <h3>{'Team Right'}</h3>
                        {   [0,1,2,3,4].map((statIndex) =>
                            <StatContainer
                                key={`stat-loaded-${statIndex}`}
                                className="pill"
                                disabled={((modeState === 'dual') && (statIndex > 2))}
                            >
                                {/* <span>{this.state.stats[index]}</span> */}
                                <p title={statsSelectionState[statIndex]}>{getStatValue(1, statIndex)}</p>
                            </StatContainer>
                        )}
                    </React.Fragment>
                }

            </StyleStatsGrid>
    {/* Action Buttons */}
            <div className="pill">
                <Button
                    disabled={false}
                    text={'Set Information'}
                    action={() => saveInformation()}
                />
            </div>
            </Base>
        </div>
    </DataContainer>
    )
}

DataGameScreenKeyTeams.propTypes = {
    teams: PropTypes.object,
    schedule: PropTypes.object,
}

export default DataGameScreenKeyTeams

