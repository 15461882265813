// REACT
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ThemeContext } from '../View/themes';

import CornerLogo from '../../storybook-built/dist/components/Games/LJL/CornerLogo';

class CornerLogoContainer extends Component {

    state = {

    };

    static propTypes = {
        showTitle: PropTypes.bool,
        title: PropTypes.string,
    }

    render() {
        let theme = this.context;
        return (
            <CornerLogo
                showTitle={this.props.showTitle}
                title={this.props.title}
                theme={(theme.screens && theme.screens.cornerLogo) || {}}
            />
        );
    }
}

CornerLogoContainer.contextType = ThemeContext;

export default CornerLogoContainer
