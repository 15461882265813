import styled from 'styled-components';

export const StyleMainContainer = styled.div`
    width: 100%;
    display: block;
    padding-bottom: 10px;
`;

export const StylePredictionsContainer = styled.div`
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    padding: 5px;
`;
export const StylePredictionContainer = styled.div`
    margin: 10px;
    width: 130px;
    border: 1px #C3C3C3 solid;
    border-radius: 5px;
    padding: 5px;
    padding-top: 10px;
    display: grid;
    p{
        text-align: center;
    }
    span{
        text-align: center;
        margin: 5px;
        font-size: 15px;
    }
    h4{
        text-align: center;
        font-style: italic;
        color: #FFFFFF;
    }
    .button {
        text-align: center;
        width: 100%;
    }
`;

export const StyleWinnerContainer = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    margin: 5px auto;
`;

export const StyleTeamLogo = styled.div`
    background-image: ${props => `url(${props.img})`};
    opacity: ${props => props.isWinner? '1' : '0.5' };
    border: ${props => props.isWinner? '2px green solid':'none'};
    background-size: cover;
    background-position: center;
    height: 50px;
    overflow: hidden;
`;

export const StyleScorePredictionContainer = styled.div`
    width: 90%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    margin: 5px auto;
    text-align: center;
    input {
        width: 90%;
        font-size: 15px;
        text-align: center;
    }
    span {
        text-align: center;
        margin: 3px auto;
        font-size: 12px;
    }
`;

export const StyleRemove = styled.div`
    position: absolute;
    top: 25px;
    opacity: 0;
    pointer-events: none;
    transition: opacity 500ms linear;
    ${StylePredictionContainer}:hover & {
        opacity: 1;
        pointer-events: all;
        cursor: pointer;
    }
    i {
        font-size: 30px;
        color: #C3C3C3;
    }

`;