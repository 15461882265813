export const getNextMatchTeams= schedule => {

    const isNextMatchInSameRound = (Number(schedule.match)) < schedule.rounds[schedule.round].matches.length;
    const isNextMatchInTheNextRound = (Number(schedule.round) + 1) < schedule.rounds.length;
    const gameBestOf = schedule.rounds[Number(schedule.round)].matches[Number(schedule.match)].bestOf;
    const isNextGameInTheNextMatch = getIsNextGameInTheNextMatch(schedule, gameBestOf);

    const nextMatchTeams = {};

    if (isNextMatchInSameRound) {
        if (isNextGameInTheNextMatch) {
            const nextMatchInfo = schedule.rounds[Number(schedule.round)].matches[Number(schedule.match) + 1];
            if (!nextMatchInfo) {
                //IN NEXT ROUND
                if (!schedule.rounds[Number(schedule.round) + 1]) return null;
                const nextRoundNextMatchInfo = schedule.rounds[Number(schedule.round) + 1].matches[0];
                if(!nextRoundNextMatchInfo.team100) return null;
                nextMatchTeams[nextRoundNextMatchInfo.team100.tricode] = nextRoundNextMatchInfo.team100;
                nextMatchTeams[nextRoundNextMatchInfo.team200.tricode] = nextRoundNextMatchInfo.team200;
                return nextMatchTeams;
            } else {
                if(!nextMatchInfo.team100) return null;
                nextMatchTeams[nextMatchInfo.team100.tricode] = nextMatchInfo.team100;
                nextMatchTeams[nextMatchInfo.team200.tricode] = nextMatchInfo.team200;
                return nextMatchTeams;
            }
        }
        const nextMatchInfo = schedule.rounds[Number(schedule.round)].matches[Number(schedule.match)];
        if(!nextMatchInfo.team100) return null;
        nextMatchTeams[nextMatchInfo.team100.tricode] = nextMatchInfo.team100;
        nextMatchTeams[nextMatchInfo.team200.tricode] = nextMatchInfo.team200;
        return nextMatchTeams;
    }

    if (isNextMatchInTheNextRound) {
        const nextMatchInfo = schedule.rounds[Number(schedule.round) + 1].matches[0];
        if(!nextMatchInfo.team100) return null;
        nextMatchTeams[nextMatchInfo.team100.tricode] = nextMatchInfo.team100;
        nextMatchTeams[nextMatchInfo.team200.tricode] = nextMatchInfo.team200;
        return nextMatchTeams;
    }
    return null;
}

const getIsNextGameInTheNextMatch = (schedule, gameBestOf) => {
    const games = schedule.rounds[Number(schedule.round)].matches[Number(schedule.match)].games;
    const winCounts = games.reduce((acc, game) => {
        if (game.winner) {
            acc[game.winner] = (acc[game.winner] || 0) + 1;
        }
        return acc;
    }, {});
    const keys = Object.keys(winCounts);
    let isNextGameInTheNextMatch = false;
    for (let i = 0; i < keys.length; i++){
        if (winCounts[keys[i]] > gameBestOf / 2) {
            isNextGameInTheNextMatch = true;
            break;
        }
    }
    return isNextGameInTheNextMatch;
}

export const getNextRoundWinnersInfo = schedule => {

    const teamWins = (games, teamTricode) => {
        return games.reduce((acc, game) => {
            if (game && game.winner && game.winner.toUpperCase() === teamTricode.toUpperCase()) {
                acc++;
            }
            return acc;
        }, 0)
    }

    const isNextMatchInSameRound = (Number(schedule.match)) < schedule.rounds[schedule.round].matches.length;
    const isNextMatchInTheNextRound = (Number(schedule.round) + 1) < schedule.rounds.length;
    const gameBestOf = schedule.rounds[Number(schedule.round)].matches[Number(schedule.match)].bestOf;
    const isNextGameInTheNextMatch = getIsNextGameInTheNextMatch(schedule, gameBestOf);

    const winners = {};

    if (isNextMatchInSameRound) {

        if (isNextGameInTheNextMatch) {
            const nextMatchInfo = schedule.rounds[Number(schedule.round)].matches[Number(schedule.match) + 1];
            if (!nextMatchInfo) {
                //IN NEXT ROUND
                if (!schedule.rounds[Number(schedule.round) + 1]) return null;
                const nextRoundNextMatchInfo = schedule.rounds[Number(schedule.round) + 1].matches[0];
                winners[nextRoundNextMatchInfo.team100.tricode] = nextRoundNextMatchInfo.games && teamWins(nextRoundNextMatchInfo.games, nextRoundNextMatchInfo.team100.tricode);
                winners[nextRoundNextMatchInfo.team200.tricode] = nextRoundNextMatchInfo.games && teamWins(nextRoundNextMatchInfo.games, nextRoundNextMatchInfo.team200.tricode);
                return winners;
            } else {
                winners[nextMatchInfo.team100.tricode] = nextMatchInfo.games && teamWins(nextMatchInfo.games, nextMatchInfo.team100.tricode);
                winners[nextMatchInfo.team200.tricode] = nextMatchInfo.games && teamWins(nextMatchInfo.games, nextMatchInfo.team200.tricode);
                return winners;
            }
        }
        const nextMatchInfo = schedule.rounds[Number(schedule.round)].matches[Number(schedule.match)];
        winners[nextMatchInfo.team100.tricode] = nextMatchInfo.games && teamWins(nextMatchInfo.games, nextMatchInfo.team100.tricode);
        winners[nextMatchInfo.team200.tricode] = nextMatchInfo.games && teamWins(nextMatchInfo.games, nextMatchInfo.team200.tricode);
        return winners;
    }
    if (isNextMatchInTheNextRound) {
        const nextMatchInfo = schedule.rounds[Number(schedule.round) + 1].matches[0];
        winners[nextMatchInfo.team100.tricode] = nextMatchInfo.games && teamWins(nextMatchInfo.games, nextMatchInfo.team100.tricode);
        winners[nextMatchInfo.team200.tricode] = nextMatchInfo.games && teamWins(nextMatchInfo.games, nextMatchInfo.team200.tricode);
        return winners;
    }
    return null;
}

export const getNextGameNumber = schedule => {
    const isNextMatchInSameRound = (Number(schedule.match)) < schedule.rounds[schedule.round].matches.length;
    const isNextMatchInTheNextRound = (Number(schedule.round) + 1) < schedule.rounds.length;
    const gameBestOf = schedule.rounds[Number(schedule.round)].matches[Number(schedule.match)].bestOf;
    const isNextGameInTheNextMatch = getIsNextGameInTheNextMatch(schedule, gameBestOf);

    if (isNextMatchInSameRound) {

        if (isNextGameInTheNextMatch) {
            return 1
        }
        const nextMatchInfo = schedule.rounds[Number(schedule.round)].matches[Number(schedule.match)];
        if (!nextMatchInfo) {
            if (!schedule.rounds[Number(schedule.round) + 1]) return null;
            const nextRoundNextMatchInfo = schedule.rounds[Number(schedule.round) + 1].matches[0];
            const finishedGamesNextRound = nextRoundNextMatchInfo.games ? nextRoundNextMatchInfo.games.reduce((acc, game) => {
                if (game.winner) {
                    acc++
                }
                return acc;
            }, 0) : 0;
            return finishedGamesNextRound + 1; //Next
        }
        const finishedGames = nextMatchInfo.games ? nextMatchInfo.games.reduce((acc, game) => {
            if (game.winner) {
                acc++
            }
            return acc;
        }, 0) : 0;
        return finishedGames + 1; //Next
    }
    if (isNextMatchInTheNextRound) {
        return 1;
    }
    return null;
}

export const getNextMatchBestOf = schedule => {
    const isNextMatchInSameRound = (Number(schedule.match)) < schedule.rounds[schedule.round].matches.length;
    const isNextMatchInTheNextRound = (Number(schedule.round) + 1) < schedule.rounds.length;
    const gameBestOf = schedule.rounds[Number(schedule.round)].matches[Number(schedule.match)].bestOf;
    const isNextGameInTheNextMatch = getIsNextGameInTheNextMatch(schedule, gameBestOf);

    if (isNextMatchInSameRound) {
        if (isNextGameInTheNextMatch) {
            const nextMatchInfo = schedule.rounds[Number(schedule.round)].matches[Number(schedule.match) + 1];
            if (!nextMatchInfo) {
                if (!schedule.rounds[Number(schedule.round) + 1]) return null;
                const nextRoundNextMatchInfo = schedule.rounds[Number(schedule.round) + 1].matches[0];
                return nextRoundNextMatchInfo.bestOf;
            }
            return nextMatchInfo.bestOf;
        }
        const nextMatchInfo = schedule.rounds[Number(schedule.round)].matches[Number(schedule.match)];
        if (!nextMatchInfo) {
            if (!schedule.rounds[Number(schedule.round) + 1]) return null;
            const nextRoundNextMatchInfo = schedule.rounds[Number(schedule.round) + 1].matches[0];
            return nextRoundNextMatchInfo.bestOf;
        }
        return nextMatchInfo.bestOf;
    }
    if (isNextMatchInTheNextRound) {
        const nextMatchInfo = schedule.rounds[Number(schedule.round) + 1].matches[0];
        return nextMatchInfo.bestOf;
    }
    return null;
}

export const getNextGameDateTime = schedule => {
    const isNextMatchInSameRound = (Number(schedule.match)) < schedule.rounds[schedule.round].matches.length;
    const isNextMatchInTheNextRound = (Number(schedule.round) + 1) < schedule.rounds.length;
    const gameBestOf = schedule.rounds[Number(schedule.round)].matches[Number(schedule.match)].bestOf;
    const isNextGameInTheNextMatch = getIsNextGameInTheNextMatch(schedule, gameBestOf);

    if (isNextMatchInSameRound) {
        if (isNextGameInTheNextMatch) {
            const nextMatchInfo = schedule.rounds[Number(schedule.round)].matches[Number(schedule.match) + 1];
            if (!nextMatchInfo) {
                if (!schedule.rounds[Number(schedule.round) + 1]) return null;
                const nextRoundNextMatchInfo = schedule.rounds[Number(schedule.round) + 1].matches[0];
                return nextRoundNextMatchInfo.datetime;
            }
            return nextMatchInfo.datetime;
        }
        const nextMatchInfo = schedule.rounds[Number(schedule.round)].matches[Number(schedule.match)];
        if (!nextMatchInfo) {
            if (!schedule.rounds[Number(schedule.round) + 1]) return null;
            const nextRoundNextMatchInfo = schedule.rounds[Number(schedule.round) + 1].matches[0];
            return nextRoundNextMatchInfo.datetime;
        }
        return nextMatchInfo.datetime;
    }
    if (isNextMatchInTheNextRound) {
        const nextMatchInfo = schedule.rounds[Number(schedule.round) + 1].matches[0];
        return nextMatchInfo.datetime;
    }
    return null;
}
