import React, { useState, useEffect } from 'react';
import { get } from 'lodash';
// INTERNAL
import { updateScreenStatus } from '../../../util/controllerUtils';
import { subscribe } from '../../../services/api';
import { i18n } from '../../i18n';
import ControlContainer from '../../Controller/ControlContainer';
import Button from '../../Button';
import { getTheme } from '../../View/themes';
import { firebaseRealtimeGetValue } from './../../../util/firebase';
import PropTypes from 'prop-types';
export function useIsMounted() {
  const isMounted = React.useRef(false);

  React.useEffect(() => {
    isMounted.current = true;
    return () => isMounted.current = false;
  }, []);
  return isMounted;
}
const BanPickV2Control = (props) => {
  const isMounted = useIsMounted();
  const [isPlayState, setIsPlayState] = useState('close');
  const [showState, setShowState] = useState(false);
  const [currentBanPickState, setCurrentBanPickState] = useState();

  const onPlay = () => {
    if (isMounted.current) setIsPlayState('open');
  };

  const onClose = async () => {
    if (isMounted.current) setIsPlayState('close');
    if (isMounted.current) setShowState(false);
    await updateScreenStatus('banPickV2', 'close', getSendData(true), null, props.cgpc);
  };

  const onShow = () => {
    if (isMounted.current) setShowState(true);
  };

  const onHide = () => {
    if (isMounted.current) setShowState(false);
  };

  const getTeamsData = () => {
    const result = {};
    const currentMatch = window.MainData.schedule.rounds[window.MainData.schedule.round].matches[window.MainData.schedule.match];
    const currentGame = currentMatch.games[window.MainData.schedule.game];
    const isFlipped = currentGame.teams[currentMatch.team100.tricode].side === '200';
    result.team100 = {
      ...currentMatch[isFlipped ? 'team200' : 'team100'],
      roster: currentGame.teams[isFlipped ? currentMatch.team200.tricode : currentMatch.team100.tricode].roster,
      gameRecord: getTeamRecord(isFlipped ? currentMatch.team200.tricode : currentMatch.team100.tricode, window.MainData.standings),
    };
    result.team200 = {
      ...currentMatch[isFlipped ? 'team100' : 'team200'],
      roster: currentGame.teams[isFlipped ? currentMatch.team100.tricode : currentMatch.team200.tricode].roster,
      gameRecord: getTeamRecord(isFlipped ? currentMatch.team100.tricode : currentMatch.team200.tricode, window.MainData.standings),
    }
    return result;
  }

  const getTeamRecord = (tricode, standing) => {
    const result = { win: 0, loss: 0 };
    const team = get(standing, 'REGULAR', []).find(team => team.tricode === tricode);
    if(team) {
      result.win = team.win || 0;
      result.loss = team.loss || 0;
    }
    return result;
  }

  const getWonMatches = (tricode, match) => {
    if (!match || (match && !match.games)) return 0;
    return match.games.reduce((acc, game) => {
      if (game.winner && game.winner === tricode) {
        return acc + 1;
      }
      return acc;
    }, 0)
  }

  async function getShow(team, pos) {
    const answer = await firebaseRealtimeGetValue(`control-banPickV2`)
    return get(answer, `data.championsStats[${team}][${pos}].show`, false)
  }

  async function getStats(team, pos) {
    const answer = await firebaseRealtimeGetValue(`control-banPickV2`)
    return get(answer, `data.championsStats[${team}][${pos}].stats`, {})
  }

  const getSendData = () => {
    const championsStats = {
      team100: {
        top: { show: getShow('team100', 'top'), stats: getStats('team100', 'top') },
        jungle: { show: getShow('team100', 'jungle'), stats: getStats('team100', 'jungle') },
        mid: { show: getShow('team100', 'mid'), stats: getStats('team100', 'mid') },
        adc: { show: getShow('team100', 'adc'), stats: getStats('team100', 'adc') },
        support: { show: getShow('team100', 'support'), stats: getStats('team100', 'support') },
      },
      team200: {
        top: { show: getShow('team200', 'top'), stats: getStats('team200', 'top') },
        jungle: { show: getShow('team200', 'jungle'), stats: getStats('team200', 'jungle') },
        mid: { show: getShow('team200', 'mid'), stats: getStats('team200', 'mid') },
        adc: { show: getShow('team200', 'adc'), stats: getStats('team200', 'adc') },
        support: { show: getShow('team200', 'support'), stats: getStats('team200', 'support') },
      },
    }
    const currentMatch = get(window.MainData, `schedule.rounds[${window.MainData.schedule.round}].matches[${window.MainData.schedule.match}]`, {});
    const gamesList = currentMatch.games || [];
    const isFlipped = get(gamesList, `[${window.MainData.schedule.game}].teams[${currentMatch.team100.tricode}].side`, '100') !== '100';
    const score100 = getWonMatches(currentMatch[isFlipped ? 'team200' : 'team100'].tricode, currentMatch);
    const score200 = getWonMatches(currentMatch[isFlipped ? 'team100' : 'team200'].tricode, currentMatch);
    // Displaying the WO:GO
    const roundId = window.MainData.schedule.round || 0;
    const round = window.MainData.schedule.rounds[roundId];
    const weekTitle = round.name;
    const gameTitle = window.MainData.schedule.match + '';
    const matchType = round.matches[window.MainData.schedule.match].bestOf
    return ({
      matchType: matchType,
      weekTitle: weekTitle,
      gameTitle: gameTitle,
      banPickV2: currentBanPickState,
      teams: getTeamsData(),
      banPickV2Visible: showState,
      ljlLogo: getTheme(window.MainData.theme).screens.banPickV2.leagueLogo,
      patch: window.MainData.settings.patch,
      score: `${score100} - ${score200}`,
      championsStats: championsStats,
      sponsors: get(window, 'MainData.sponsors', []),
    })
  }

  useEffect(() => {
    subscribe('banPick', null, response => {
      // if you ever find a way to use the storage firebase to show the champions, swapchampions, setchamp both use the old location.
      if (isMounted.current) setCurrentBanPickState(response);
    });
  }, []);

  useEffect(() => {
    if (isPlayState === 'open') {
      updateScreenStatus('banPickV2', isPlayState, getSendData(), null, props.cgpc);
    }
  }, [currentBanPickState, showState, isPlayState])

  return (
    <ControlContainer style={{display: props.display ? 'none': 'block',  overflow: 'visible'}} status={isPlayState} >
      <div className="control__panel">
        <h2 className="control__title">
          <i className="fas fa-history"></i> {i18n().pages.banPickV2}
        </h2>
        <Button
        controllerSide={!props.screen1}
          action={onPlay}
          text={i18n().general.open}
          icon="fas fa-play-circle"
          disabled={isPlayState === 'open'}
        />
        <Button
        controllerSide={!props.screen1}
          action={onClose}
          text={i18n().general.close}
          icon="fas fa-stop-circle"
          disabled={!(isPlayState === 'open')}
        />
      </div>

      {((isPlayState === 'open') && (
        <div className={'control-extra'}>
          <div className={'control-extra__fieldset'}>
            <div className="pill">
              {showState
                ? [
                  <span key="1a" className="pill__span--highlighted">
                    {i18n().controller.viewable.true}
                  </span>,
                  <Button controllerSide={!props.screen1} key="1b" text="Hide" action={onHide} />,
                ]
                : [
                  <span key="2a">{i18n().controller.viewable.false}</span>,
                  <Button controllerSide={!props.screen1} key="2b" text="Show" action={onShow} />,
                ]}
            </div>
          </div>
        </div>
      )) || null}
    </ControlContainer>
  );
};

BanPickV2Control.propTypes = {
  cgpc: PropTypes.string,
  display: PropTypes.bool,
  screen1: PropTypes.bool,
};

export default BanPickV2Control;


