// REACT
import React, { Component } from 'react';
import PropTypes from 'prop-types';
// import styled from 'styled-components';

// internal
import DataContainer from '../DataContainer';
import { firebaseRealtimeDbSetValue } from '../../../util/firebase';


class DataBackground extends Component {

    constructor(props) {
        super(props);
        this.state = {
            disableAnimationBackground: false,
            background: '',
        };
    }

    static propTypes = {
        theme: PropTypes.object,
        secondKeys: PropTypes.object,
      };

    onChangeBackground = event => {
        this.setAppBackground(event.target.value || null);
    }

    setAppBackground = backgroundImage => {
        this.setState({
            background:backgroundImage,
        }, () => {
            firebaseRealtimeDbSetValue('view/background', backgroundImage);
            firebaseRealtimeDbSetValue('view/background', backgroundImage, this.props.secondKeys[window.MainData.key]);
        })

    }

    handleAnimationBGChange = (disableAnimationBackGround) => {
        this.setState({
            disableAnimationBackground: disableAnimationBackGround,
        }, () => {
            firebaseRealtimeDbSetValue('view/disableAnimationBackground', disableAnimationBackGround);
            firebaseRealtimeDbSetValue('view/disableAnimationBackground', disableAnimationBackGround, this.props.secondKeys[window.MainData.key]);
        })
    }

    componentDidMount(){
        const theme = this.props.theme.screens || {};
        if( theme.animatedBackground && theme.animatedBackground.show && theme.animatedBackground.defaultBackground){
            this.setAppBackground(theme.animatedBackground.defaultBackground);
        } else{
            this.setAppBackground('');
        }

        if( theme.animatedBackground && theme.animatedBackground.disableAnimationBackGround){
            this.handleAnimationBGChange(theme.animatedBackground.disableAnimationBackGround);
        } else{
            this.handleAnimationBGChange(false);
        }
    }

    render () {
        const theme = this.props.theme.screens || {};

        const backgrounds = [
            { image: 'https://dekki-production.s3.ap-northeast-1.amazonaws.com/uploads/broadcast-projects/worlds/2023/videos/worlds2023_livingholds_grind.mp4', name: 'Worlds 2023 Grind'},
            { image: 'https://dekki-production.s3.ap-northeast-1.amazonaws.com/uploads/broadcast-projects/worlds/2023/videos/worlds2023_livingholds_grey.mp4', name: 'Worlds 2023 Grey'},
            { image: 'https://dekki-production.s3.ap-northeast-1.amazonaws.com/uploads/broadcast-projects/worlds/2023/videos/worlds2023_livingholds_glory.mp4', name: 'Worlds 2023 Glory'},
            { image: 'https://dekki-production.s3.ap-northeast-1.amazonaws.com/uploads/broadcast-projects/ljl2023/ljl2023_livingholds_finals_20mbs.mp4', name: 'LJL 2023 Finals 20Mbps'},
            { image: 'https://cdn.dekki.com/uploads/broadcast-projects/ljl2023/ljl2023_livingholds.mp4', name:'LJL 2023 Original' },
            { image: 'https://cdn.dekki.com/uploads/broadcast-projects/ljl2023/ljl2023_livingholds_60Mbps.mp4', name:'LJL 2023 60Mbps' },
            { image: 'https://cdn.dekki.com/uploads/broadcast-projects/msi2022/videos/MSI22_LIVING_HOLD.mp4', name:'MSI 2022 mp4' },
            {image: 'https://cdn.dekki.com/uploads/broadcast-projects/ljl2022/Team_Logos_grey.mp4', name: 'ljl2022.mp4' },
            {image: 'https://cdn.dekki.com/uploads/broadcast-projects/ljl2022/LJL2022_background.png', name: 'ljl2022.png' },
            { image: 'https://cdn.dekki.com/uploads/broadcast-projects/general/test-lol-game-screen-bg-2022.png', name:'TEST LoL Game Screen 2022+' },
            { image: 'https://cdn.dekki.com/uploads/broadcast-projects/academy2021/videos/academy2021_bg_video.webm', name:'Video - Academy 2021 webm' },
            { image: 'https://cdn.dekki.com/uploads/broadcast-projects/scoutinggrounds/LJL-2021-SGG_BG-Loop_Full-Screen.mp4', name:'Video - Scouting Grounds With Stickers 2021 mp4' },
            { image: 'https://cdn.dekki.com/uploads/broadcast-projects/scoutinggrounds/LJL-2021-SGG_BG-Loop_Waiting-Screen.mp4', name:'Video - Scouting Grounds Without Stickers 2021 mp4' },
            { image: 'https://cdn.dekki.com/uploads/broadcast-projects/worlds/2021/videos/BG-LOOP_4-MIN.mp4', name:'Video - worlds 2021 mp4' },
            { image: 'https://cdn.dekki.com/uploads/broadcast-projects/msi2021/videos/LIVING_HOLD.webm', name:'Video - MSI 2021 webm' },
            { image: 'https://cdn.dekki.com/uploads/broadcast-projects/ljl2021/videos/ljl2021_spr_bg_video.mp4', name:'Video - LJL 2021 Sum mp4' },
            { image: 'https://cdn.dekki.com/uploads/broadcast-projects/ljl2021/videos/ljl2021_spr_bg_video.webm', name:'Video - LJL 2021 Sum webm' },
            { image: 'https://cdn.dekki.com/uploads/broadcast-projects/scoutinggrounds/scoutinggrounds-bg.png', name:'LJL Scouting Grounds 2020' },
            { image: 'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/backgrounds/worlds2020_bg.png', name:'TEST Worlds2020' },
            { image: 'https://cdn.dekki.com/uploads/leagues/ljl_2019_summer/gameBreakdownBG.png', name:'LJL Final - Summer Split 2019' },
            // { image: 'https://cdn.dekki.com/uploads/broadcast-projects/ljl2019/ljl2019-bg.png', name:'LJL Spring Split 2019' },
            { image: 'https://cdn.dekki.com/uploads/broadcast-projects/ljlallstar/ljlallstar-bg.png', name:'LJL Allstar Spring 2019' },
            { image: 'https://cdn.dekki.com/uploads/broadcast-projects/general/test-lol-game-screen-bg.png', name:'TEST LoL Game Screen' },
            { image: 'https://cdn.dekki.com/uploads/broadcast-projects/pubgmjc/videos/pubgmjc-bg-video_waiting_20s.webm', name:'Video - PUBG' },
            // { image: 'https://cdn.dekki.com/uploads/broadcast-projects/msi2019/videos/msi-2019-bg-video.webm', name: 'Video - MSI2019' },
            // { image: 'https://cdn.dekki.com/uploads/broadcast-projects/worlds/2019/videos/worlds2019_20s.webm', name: 'Video - Worlds 2019' },
            { image: 'https://cdn.dekki.com/uploads/broadcast-projects/ljl2020/videos/ljl2020_bg_video.mp4', name: 'Video - LJL 2020 mp4' },
            { image: 'https://cdn.dekki.com/uploads/broadcast-projects/ljl2020/videos/ljl2020_bg_video.webm', name:'Video - LJL 2020 webm' },
            { image: 'https://cdn.dekki.com/uploads/broadcast-projects/ljl2020/videos/ljl2020_sum_bg_video.mp4', name:'Video - LJL 2020 Sum mp4' },
            { image: 'https://cdn.dekki.com/uploads/broadcast-projects/ljl2020/videos/ljl2020_sum_bg_video.webm', name:'Video - LJL 2020 Sum webm' },
        ]

        return (
            <DataContainer componentSlug="dataBackground" icon="far fa-image">
                <div className="data-panel__fieldset">
                    <label className="data-panel__fieldset__label">Background</label>
                    <div className="data-panel__fieldset__body">
                        <select onChange={this.onChangeBackground} value={this.state.background}>
                            {theme.animatedBackground &&
                                theme.animatedBackground.show &&
                                theme.animatedBackground.defaultBackground &&
                                !backgrounds.map(bg=>bg.image).includes(theme.animatedBackground.defaultBackground) &&
                                <option value={theme.animatedBackground.defaultBackground}>Theme Background</option>}
                            <option value="">None</option>
                            {
                                backgrounds.map((bg, i) => {
                                    return <option value={bg.image} key={i}>{bg.name}</option>
                                })
                            }
                        </select>
                        &nbsp;
                        <input
                            name="disableAnimationBackground"
                            type="checkbox"
                            checked={this.state.disableAnimationBackground}
                            onChange={() => { this.handleAnimationBGChange(!this.state.disableAnimationBackground) }}
                        />
                            Disable Animation Background
                    </div>
                </div>
            </DataContainer>
        );

    }

}
export default DataBackground
