
const createFromArray = (arr) => {
  return arr.map(val => {
    return {
      name: val,
      value: val,
    };
  });
};

const createFromMapKeys = (hash) => {
  return createFromArray(Object.keys(hash));
};

const createFromMapKeyValue = (hash) => {
  return Object.keys(hash).map(val => {
    return {
      name: val,
      value: hash[val],
    };
  });
};

export {
  createFromArray,
  createFromMapKeys,
  createFromMapKeyValue,
};
