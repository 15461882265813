import { delay } from 'lodash';

/**
 * This function adds one class to the given element.
 * @param {DOM node | string} el any node or class name of DOM node
 * @param {string} newClass class name to add
 */
const addClass = (el, newClass) => {
  el.classList.add(newClass);
};

/**
 * This function adds classes to the given element.
 * @param {DOM node | string} el any node or class name of DOM node
 * @param {array} classes class names to add
 */
const addClasses = (el, classes) => {
  if (typeof classes === 'string') return addClass(el, classes);
  classes.map(c => addClass(el, c));
};

/**
 * This function adds one class to the given element.
 * @param {DOM node | string} el any node or class name of DOM node
 * @param {string} newClass class name to add
 */
const removeClass = (el, oldClass) => {
  el.classList.remove(oldClass);
};

/**
 * This function removes classes from the given element.
 * @param {DOM node | string} el any node or class name of DOM node
 * @param {array} classes class names to remove
 */
const removeClasses = (el, classes) => {
  classes.map(c => removeClass(el, c));
};

const createAnimationTrigger = (element, callback) => {
  const el = typeof element === 'string' ? document.querySelectorAll(element)[0] : element;

  if (el) el.addEventListener('transitionend', callback);
};

const removeAnimationTrigger = (element, callback) => {
  const el = typeof element === 'string' ? document.querySelectorAll(element)[0] : element;

  if (el) el.removeEventListener('transitionend', callback);
};

const createAnimationSequence = (els = [], classes = []) => {
  els.map((el, i) => {
    if (!els[i + 1]) return true;
    return createAnimationTrigger(el, () => {
      addClasses(els[i + 1], classes);
    });
  });
};

const createAnimationSequenceWithDelay = (els = [], classes = [], timer) => {
  els.map((el, i) => {
    if (!els[i + 1]) return true;
    return createAnimationTrigger(el, () => {
      delay(() => {
        addClasses(els[i + 1], classes);
      }, timer);
    });
  });
};

const removeAnimationSequence = (els = []) => {
  els.map((el) => {
    const clone = el.cloneNode();
    while (el.firstChild) {
      clone.appendChild(el.firstChild);
    }
    return el.parentNode.replaceChild(clone, el);
  });
};

const reverseAnimationSequence = (els = [], classes = []) => {
  els.map((el, i) => {
    if (!els[i + 1]) return true;
    return createAnimationTrigger(el, () => {
      removeClasses(els[i + 1], classes);
    });
  });
};

const resetElement = (element, classes = []) => {
  const el = typeof element === 'string' ? document.querySelectorAll(element)[0] : element;
  el.classList.remove('animated');
  removeClasses(el, classes);
};

export default {
  addClass,
  addClasses,
  createAnimationSequence,
  createAnimationSequenceWithDelay,
  createAnimationTrigger,
  removeAnimationSequence,
  removeAnimationTrigger,
  removeClass,
  removeClasses,
  resetElement,
  reverseAnimationSequence,
}
