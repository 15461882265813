import styled from 'styled-components';

export const Base = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    height: 100%;
`;

export const StylePlayerSelection = styled.div`
    z-index: 2;
    padding: 10px 20px;
    z-index: 2;
    p {
        margin: 10px 30px;
        display: inline;
    }
`;

export const StylePreview = styled.div`
    margin: 20px auto;
    height: 280px;
    width: 100%;
    display: flex;
    transform: scale(0.7);
    margin-top: -70px;
    margin-left: -150px;
    .itemPurchaseHistoryTitle{ display: none;}
    
`;

export const StyleActionContainer = styled.div`
    text-align: left;
    margin-left: 20px;
`;

export const StyleWarningMessage = styled.div`
    color: red;
    padding: 10px 20px;
`;