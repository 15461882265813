"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StyleWhoWillWinContent = exports.StyleTeamLogoContainer = exports.StyleTeamLogo = exports.StyleScoreRight = exports.StyleScoreLeft = exports.StyleScoreBar = exports.StyleRosterChampions = exports.StyleRightAsset = exports.StyleMain = exports.StyleInfoBoxSubtitle = exports.StyleDataContainer = exports.StyleBarRight = exports.StyleBarLeft = void 0;

var _styledComponents = _interopRequireDefault(require("styled-components"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var StyleInfoBoxSubtitle = _styledComponents.default.span.withConfig({
  displayName: "styles__StyleInfoBoxSubtitle",
  componentId: "sc-1agp3ks-0"
})(["font-size:25px;font-weight:500;", ";"], function (props) {
  return props.StyleInfoBoxSubtitle ? props.StyleInfoBoxSubtitle : '';
});

exports.StyleInfoBoxSubtitle = StyleInfoBoxSubtitle;

var StyleMain = _styledComponents.default.div.withConfig({
  displayName: "styles__StyleMain",
  componentId: "sc-1agp3ks-1"
})(["display:grid;grid-template-columns:75% 25%;transform:translateX(10px);", ";"], function (props) {
  return props.StyleMain ? props.StyleMain : '';
});

exports.StyleMain = StyleMain;

var StyleDataContainer = _styledComponents.default.div.withConfig({
  displayName: "styles__StyleDataContainer",
  componentId: "sc-1agp3ks-2"
})(["display:grid;width:100%;height:180px;grid-gap:15px;", ";"], function (props) {
  return props.StyleDataContainer ? props.StyleDataContainer : '';
});

exports.StyleDataContainer = StyleDataContainer;

var StyleRosterChampions = _styledComponents.default.div.withConfig({
  displayName: "styles__StyleRosterChampions",
  componentId: "sc-1agp3ks-3"
})(["display:grid;grid-template-columns:repeat(5,45px) 120px repeat(5,45px);justify-self:center;margin-top:auto;height:50px;img{width:40px;margin:auto;}p{font-family:futura-pt,sans-serif;font-size:20px;font-weight:500;text-align:center;line-height:40px;margin:auto;}", ";"], function (props) {
  return props.StyleRosterChampions ? props.StyleRosterChampions : '';
});

exports.StyleRosterChampions = StyleRosterChampions;

var StyleTeamLogo = _styledComponents.default.img.withConfig({
  displayName: "styles__StyleTeamLogo",
  componentId: "sc-1agp3ks-4"
})(["height:120px;width:120px;", ";"], function (props) {
  return props.StyleTeamLogo ? props.StyleTeamLogo : '';
});

exports.StyleTeamLogo = StyleTeamLogo;

var StyleTeamLogoContainer = _styledComponents.default.div.withConfig({
  displayName: "styles__StyleTeamLogoContainer",
  componentId: "sc-1agp3ks-5"
})(["display:flex;margin:auto 20px;justify-content:", ";align-content:center;", ";"], function (props) {
  return props.side === 'left' ? 'flex-end' : 'flex-start';
}, function (props) {
  return props.StyleTeamLogoContainer ? props.StyleTeamLogoContainer : '';
});

exports.StyleTeamLogoContainer = StyleTeamLogoContainer;

var StyleWhoWillWinContent = _styledComponents.default.div.withConfig({
  displayName: "styles__StyleWhoWillWinContent",
  componentId: "sc-1agp3ks-6"
})(["display:grid;grid-template-columns:1fr 3fr 1fr;width:100%;height:120px;align-items:center;font-family:futura-pt,\"sans-serif\";", ";"], function (props) {
  return props.StyleWhoWillWinContent ? props.StyleWhoWillWinContent : '';
});

exports.StyleWhoWillWinContent = StyleWhoWillWinContent;

var StyleRightAsset = _styledComponents.default.div.withConfig({
  displayName: "styles__StyleRightAsset",
  componentId: "sc-1agp3ks-7"
})(["height:210px;transition:transform 1000ms linear;img{right:-50px;overflow:visible;position:absolute;bottom:-75px;width:410px;max-width:unset;transform:", ";}", ";"], function (props) {
  return props.show ? 'translateY(0)' : 'translateY(35px)';
}, function (props) {
  return props.StyleRightAsset ? props.StyleRightAsset : '';
});

exports.StyleRightAsset = StyleRightAsset;

var StyleScoreBar = _styledComponents.default.div.withConfig({
  displayName: "styles__StyleScoreBar",
  componentId: "sc-1agp3ks-8"
})(["color:white;flex:1 1;height:60px;margin:0 10px;position:relative;max-width:765px;overflow:hidden;", ";"], function (props) {
  return props.StyleScoreBar ? props.StyleScoreBar : '';
});

exports.StyleScoreBar = StyleScoreBar;

var StyleScore = _styledComponents.default.div.withConfig({
  displayName: "styles__StyleScore",
  componentId: "sc-1agp3ks-9"
})(["font-size:40px;font-weight:700;position:absolute;top:50%;-webkit-transform:translateY(-50%);transform:translateY(-50%);z-index:4;", ";"], function (props) {
  return props.StyleScore ? props.StyleScore : '';
});

var StyleScoreLeft = (0, _styledComponents.default)(StyleScore).withConfig({
  displayName: "styles__StyleScoreLeft",
  componentId: "sc-1agp3ks-10"
})(["left:20px;", ";"], function (props) {
  return props.StyleScoreLeft ? props.StyleScoreLeft : '';
});
exports.StyleScoreLeft = StyleScoreLeft;
var StyleScoreRight = (0, _styledComponents.default)(StyleScore).withConfig({
  displayName: "styles__StyleScoreRight",
  componentId: "sc-1agp3ks-11"
})(["right:20px;", ";"], function (props) {
  return props.StyleScoreRight ? props.StyleScoreRight : '';
});
exports.StyleScoreRight = StyleScoreRight;

var StyleBar = _styledComponents.default.div.withConfig({
  displayName: "styles__StyleBar",
  componentId: "sc-1agp3ks-12"
})(["align-items:center;display:flex;height:100%;position:absolute;top:0;", ";"], function (props) {
  return props.StyleBar ? props.StyleBar : '';
});

var StyleBarLeft = (0, _styledComponents.default)(StyleBar).withConfig({
  displayName: "styles__StyleBarLeft",
  componentId: "sc-1agp3ks-13"
})(["left:0;width:100%;justify-content:flex-start;transform-origin:left center;transform:", ";background-color:", ";", ";"], function (props) {
  return "scaleX(".concat(props.scaleX || '1', ")");
}, function (props) {
  return props.color;
}, function (props) {
  return props.StyleBarLeft ? props.StyleBarLeft : '';
});
exports.StyleBarLeft = StyleBarLeft;
var StyleBarRight = (0, _styledComponents.default)(StyleBar).withConfig({
  displayName: "styles__StyleBarRight",
  componentId: "sc-1agp3ks-14"
})(["left:unset;right:0;width:", "%;justify-content:flex-end;transform-origin:right center;clip-path:polygon(30px 0,100% 0,100% 100%,0% 100%);background-color:", ";", ";"], function (props) {
  return props.scaleX * 100;
}, function (props) {
  return props.color;
}, function (props) {
  return props.StyleBarRight ? props.StyleBarRight : '';
});
exports.StyleBarRight = StyleBarRight;