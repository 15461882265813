import https from 'https';

export const doHttpsGet = async (url, options, timeoutMs) => {
    return await new Promise((resolve, reject) => {
        let rawData = '';
        try {
            let timedOut = true;
            const req = https.get(
                url || options,
                res => {
                    timedOut = false;
                    if (res.statusCode >= 200 && res.statusCode < 300) {
                        res.setEncoding('utf8');
                        res.on('data', chunk => {
                            rawData += chunk;
                        });
                        res.on('end', () => {
                            let data;
                            try {
                                data = JSON.parse(rawData);
                            } catch (err) {
                                data = rawData;
                            }
                            resolve(data || rawData);
                        });
                    } else {
                        reject(
                            `error calling https get url for ${url || (options && options.host)}. Received status code: ${
                            res.statusCode
                            }`,
                        );
                    }
                },
            );
            if (timeoutMs) {
                // None of the built-in timeout functions/callbacks are working for the 'req' so making my own - stevenm 2020-02-03
                setTimeout(() => {
                    if (timedOut) {
                        req.abort();
                        reject(`error calling https get request for ${url || (options && options.host)}. Timed Out.`);
                    }
                }, timeoutMs);
            }
            req.end();
        } catch (error) {
            reject(`error calling https get request for ${url || (options && options.host)}.`);
        }
    });
}
