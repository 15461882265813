import React from 'react';
import ReactDOM from 'react-dom';
import RoutesWrapper from './router';
import registerServiceWorker from './registerServiceWorker';
import {
  defaultLanguage,
  defaultSeason,
  defaultLeague,
  defaultSplit,
  defaultTournamentRealm,
  defaultPatch,
} from './util/const';
import getSchedule from './services/scheduleService';

window.MainData = {
  key: 'cgpc1',
  currentGame: {
    game: 0,
    match: 0,
    round: 0,
  },
  settings: {
    language: defaultLanguage,
    showDummyVideo: false,
    patch: defaultPatch,
  },
  telops: {
    caster: 'eyes',
    analyst: 'Revol',
    interviewer: 'katsudion',
    teams: {},
    player: {
      dpm: 0,
      kda: 0,
      kp: 0,
      name: '',
      team: '',
    },
  },
  twitter: {
    mvp: {
      team: '',
      players: {},
      timer: '03:00',
      time: 0,
    },
    teams: {
        matchup: {},
        votes: {},
    },
    tweets: {
        queries: [],
        response: [],
        selected: [],
    },
  },
  schedule: {
    rounds: getSchedule,
    round: 1,
    match: 1,
    game: 1,
    league: defaultLeague,
    season: defaultSeason,
    split: defaultSplit,
    platformId: defaultTournamentRealm,
  },
  standings: [],
  winningTeam: '',
  timer: {
    targetTime: '',
  },
  teams: {
  },
  voteCount: [],
  leaderboard: {
    data: {
      KP: [],
      KILLS: [],
      ASSISTS: [],
    },
    chosenStats: [
      'KP',
      'KILLS',
      'ASSISTS',
    ],
  },
  keyPlayers: {
    player100: {
      name: '',
      stats: {},
      team: '',
    },
    player200: {
      name: '',
      stats: {},
      team: '',
    },
  },
  runesData: {
    team: '',
    player: '',
    primary: '',
    keystone: '',
    primaryRunes: [],
    secondary: '',
    secondaryRunes: [],
    secondaryOptions: [],
    secondaries: [],
  },
  gameBreakdown: {
    stats: {},
  },
  background: null,
  realtimeApi: {
    events: {},
    liveStatusDelay: 0,
  },
};

window.ljlCleanData = {...window.ljlMainData};
if(window.location.hash && window.location.hash.indexOf('#access_token') === -1){
  let path = window.location.hash.slice(1);

  if (path) {
    window.location.hash = '';
    window.history.replaceState({}, '', `/${path}`);
  }
}

ReactDOM.render(<RoutesWrapper />, document.getElementById('root'));
registerServiceWorker();
