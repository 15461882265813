import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import CastersPredictions from '../../storybook-built/dist/components/Games/LJL/CastersPredictionsOverlay';

function CastersPredictionsOverlay(props) {

    useEffect(()=>{
        console.log("CastersPredictions props", props);
    },[props.data])

    return (
        <CastersPredictions
            show={props.active}
            data={props.data.castersPredictions}
            indexPrediction={props.data.indexPrediction}
        />
    )
}

CastersPredictionsOverlay.propTypes = {
    data: PropTypes.object.isRequired,
    active: PropTypes.bool.isRequired,
}

export default CastersPredictionsOverlay

