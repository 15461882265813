// REACT
import React, { Component } from 'react';
// INTERNAL
import { updateScreenStatus } from '../../../util/controllerUtils';
import { subscribe } from '../../../services/api';
import ControlContainer from '../../Controller/ControlContainer';
import Button from '../../Button';
import {i18n} from '../../i18n';
import PropTypes from 'prop-types';
class BlankControl extends Component {

    state = {
        status: 'ready',
    }
    static propTypes = {
        cgpc: PropTypes.string,
        display: PropTypes.bool,
        screen1: PropTypes.bool,
      };

    componentDidMount() {
        this.runSubscription();
    }

    runSubscription = () => {
        subscribe('control-blankScreen', null, data => {
            if (data.target === 'control-blankScreen') {
                this.setState({status: data.status});
            }
        }, this.props.cgpc);
    };

    onPlay = () => {
        updateScreenStatus('blankScreen', 'open', true, null, this.props.cgpc);
    }

    onClose = () => {
        // send('event', { target: 'view', action: 'close', screen: 'timer' });
        updateScreenStatus('blankScreen', 'close', null, null, this.props.cgpc);
    }

    render() {
        return (
            <ControlContainer style={{display: this.props.display ? 'none': 'block'}} className="control--timer" status={this.state.status}>
                <div className="control__panel">
                    <h2 className="control__title">
                        <i className="far fa-rectangle-wide"></i> {i18n().general.blankScreen}
                    </h2>
                    <Button
                    controllerSide={!this.props.screen1}
                        action={this.onPlay}
                        text={i18n().general.open}
                        icon="fas fa-play-circle"
                        disabled={this.state.status === 'open'}
                    />
                    <Button
                    controllerSide={!this.props.screen1}
                        action={this.onClose}
                        text={i18n().general.close}
                        icon="fas fa-stop-circle"
                        disabled={this.state.status !== 'open'}
                    />
                </div>
            </ControlContainer>
        )
    }
}

export default BlankControl
