import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';

// import { loadImageUrlChampSquare } from '../../../../util/loadImageUrl';
// import { ThemeContext } from '../../../View/themes';
// import championsDict from '../../../../services/championService';
import positions from './../../../../storybook-built/dist/Constants/lol_positions';
import PlayerBarItem from '../GoldPerPlayer/PlayerBarItem';
import { StyleInfoBoxTitle, StyleInfoBoxBody, StyleBarTeamContainer} from '../styles';


function DamageDealt(props) {
    const [ maxValueState, setMaxValueState] = useState(0);

    useEffect(()=>{
        setMaxValueState(getMaxValue());
    },[])

    useEffect(()=>{
        console.log("Damage Dealt", props);
    },[])

    const getMaxValue = () => {
        let max = 0;
        if (props.data && props.data[props.team100Tricode] && props.data[props.team200Tricode]) {
            positions.forEach(pos => {
                let team100DamageDealtToChampions = get(props, `data[${props.team100Tricode}][${pos}].damageDealtToChampions`, 0);
                if (team100DamageDealtToChampions > max) max = team100DamageDealtToChampions;
                let team200DamageDealtToChampions = get(props, `data[${props.team200Tricode}][${pos}].damageDealtToChampions`, 0);
                if (team200DamageDealtToChampions > max) max = team200DamageDealtToChampions;
            });
        }
        return max;
    }


    return (
        <div>
            <StyleInfoBoxTitle StyleInfoBoxTitle={get(props, 'theme.StyleInfoBoxTitle', '')}>
               {(props.data && props.data.isEntireGame) ? 'DAMAGE DEALT TO CHAMPIONS: ENTIRE GAME':'DAMAGE DEALT TO CHAMPIONS: LAST TEAM FIGHT'}
            </StyleInfoBoxTitle>
            <StyleInfoBoxBody StyleInfoBoxBody={get(props, 'theme.StyleInfoBoxBody', '')}>
                <StyleBarTeamContainer
                    className="game-breakdown__content__graphs__item__damage-cont__team game-breakdown__content__graphs__item__damage-cont__team--blue"
                >
                    {positions.map((pos) => {
                        return (
                            <PlayerBarItem
                                theme={get(props, 'theme.PlayerBarItem', '')}
                                key={`${pos}-teamLeft`}
                                color={'#4883ab'}
                                isInfoBox
                                side={'left'}
                                champ={get(props, `team100Roster.${pos}.championId`, undefined)}
                                maxVal={maxValueState}
                                score={get(props, `data[${props.team100Tricode}][${pos}].damageDealtToChampions`, 0)}
                            />
                        );
                    })}
                </StyleBarTeamContainer>
                <StyleBarTeamContainer
                    className="game-breakdown__content__graphs__item__damage-cont__team game-breakdown__content__graphs__item__damage-cont__team--red"
                >
                    {positions.map((pos) => {
                        return (
                            <PlayerBarItem
                            theme={get(props, 'theme.PlayerBarItem', '')}
                                key={`${pos}-teamRight`}
                                color={'#dd4142'}
                                isInfoBox
                                side={'right'}
                                champ={get(props, `team200Roster.${pos}.championId`, undefined)}
                                maxVal={maxValueState}
                                score={get(props, `data[${props.team200Tricode}][${pos}].damageDealtToChampions`, 0)}
                            />
                        );
                    })}
                </StyleBarTeamContainer>
            </StyleInfoBoxBody>

        </div>
    );
}

DamageDealt.propTypes = {
    // team100Roster: PropTypes.object,
    // team200Roster: PropTypes.object,
    team200Tricode: PropTypes.string,
    team100Tricode: PropTypes.string,
    data: PropTypes.object,
}

export default DamageDealt