const validateDataSchedule = () => {
    // require round, match
    // require a valid match and game at the end point
    if (!window.MainData.schedule) return false;
    if (typeof window.MainData.schedule.round !== 'number') return false;
    if (typeof window.MainData.schedule.match !== 'number') return false;
    const roundId = window.MainData.schedule.round;
    const matchId = window.MainData.schedule.match;
    if (!window.MainData.schedule.rounds[roundId]) return false;
    if (!window.MainData.schedule.rounds[roundId].matches[matchId]) return false;
    return true;
}

const validateDataTelops = () => {
    if (!window.MainData) return false;
    if (!window.MainData.telops) return false;
    if (!window.MainData.schedule) return false;
    if (!window.MainData.teams) return false;
    if (typeof window.MainData.schedule.round !== 'number') return false;
    if (typeof window.MainData.schedule.match !== 'number') return false;
    const roundId = window.MainData.schedule.round;
    const matchId = window.MainData.schedule.match;
    if (!window.MainData.schedule.rounds[roundId]) return false;
    if (!window.MainData.schedule.rounds[roundId].matches[matchId]) return false;
    return true;
}

const validateDataScheduleHasRound = () => {
    if (!window.MainData.schedule) return false;
    if (typeof window.MainData.schedule.round !== 'number') return false;
    return true;
}

export {
    validateDataSchedule as default,
    validateDataTelops,
    validateDataScheduleHasRound,
}