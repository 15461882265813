"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StyleStarterImgPlayer = exports.StylePositionPlayer = exports.StylePositionIconPlayer = exports.StylePlayerNamePlayer = exports.StylePlayerNameContainerPlayer = exports.StylePlayerImagesContainerPlayer = exports.StyleMainContainerPlayer = void 0;

var _styledComponents = _interopRequireDefault(require("styled-components"));

var _reactSpring = require("react-spring");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var StyleMainContainerPlayer = (0, _styledComponents.default)(_reactSpring.animated.div).withConfig({
  displayName: "styles__StyleMainContainerPlayer",
  componentId: "sc-1d92yas-0"
})(["width:350px;margin:5px;", ""], function (props) {
  return props.StyleMainContainerPlayer ? props.StyleMainContainerPlayer : '';
});
exports.StyleMainContainerPlayer = StyleMainContainerPlayer;

var StylePlayerImagesContainerPlayer = _styledComponents.default.div.withConfig({
  displayName: "styles__StylePlayerImagesContainerPlayer",
  componentId: "sc-1d92yas-1"
})(["position:relative;overflow:hidden;height:625px;width:100%;background:url(", ") 100% 100% no-repeat;background-position:center;", ""], function (props) {
  return props.backgroundImage;
}, function (props) {
  return props.StylePlayerImagesContainerPlayer ? props.StylePlayerImagesContainerPlayer : '';
});

exports.StylePlayerImagesContainerPlayer = StylePlayerImagesContainerPlayer;

var StylePositionPlayer = _styledComponents.default.div.withConfig({
  displayName: "styles__StylePositionPlayer",
  componentId: "sc-1d92yas-2"
})([" ", ""], function (props) {
  return props.StylePositionPlayer ? props.StylePositionPlayer : '';
});

exports.StylePositionPlayer = StylePositionPlayer;

var StylePlayerNameContainerPlayer = _styledComponents.default.div.withConfig({
  displayName: "styles__StylePlayerNameContainerPlayer",
  componentId: "sc-1d92yas-3"
})(["height:75px;width:100%;margin-top:10px;background-color:#141720;display:flex;align-items:center;justify-content:center;text-transform:uppercase;", ""], function (props) {
  return props.StylePlayerNameContainerPlayer ? props.StylePlayerNameContainerPlayer : '';
});

exports.StylePlayerNameContainerPlayer = StylePlayerNameContainerPlayer;

var StylePlayerNamePlayer = _styledComponents.default.p.withConfig({
  displayName: "styles__StylePlayerNamePlayer",
  componentId: "sc-1d92yas-4"
})(["font-size:", ";color:#ffffff;font-weight:600;", " ", ""], function (props) {
  return props.isPlayerOfTheMatch ? '32px' : '38px';
}, function (props) {
  return props.isPlayerOfTheMatch && '::before { content: "#";}';
}, function (props) {
  return props.StylePlayerNamePlayer ? props.StylePlayerNamePlayer : '';
});

exports.StylePlayerNamePlayer = StylePlayerNamePlayer;

var StyleStarterImgPlayer = _styledComponents.default.img.withConfig({
  displayName: "styles__StyleStarterImgPlayer",
  componentId: "sc-1d92yas-5"
})(["position:absolute;height:100%;top:0;right:0;left:0;bottom:0;", ""], function (props) {
  return props.StyleStarterImgPlayer ? props.StyleStarterImgPlayer : '';
});

exports.StyleStarterImgPlayer = StyleStarterImgPlayer;

var StylePositionIconPlayer = _styledComponents.default.img.withConfig({
  displayName: "styles__StylePositionIconPlayer",
  componentId: "sc-1d92yas-6"
})(["position:absolute;top:0;left:0;width:80px;", ";", ";"], function (props) {
  return props.StylePositionIconPlayer ? props.StylePositionIconPlayer : '';
}, function (props) {
  return props.isSupport ? 'height: 55px; width: 55px' : '';
});

exports.StylePositionIconPlayer = StylePositionIconPlayer;