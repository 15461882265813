// REACT
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ThemeContext } from '../../View/themes';
// 3RD PARTY
import { delay, isEqual } from 'lodash';

import {
  StyleMatch,
  StyleTeam,
  StyleTeamName,
  // StyleTeamLogo,
  StyleVersus,
} from './styles';
class GameScreenVersus extends Component {

  state = {
    hasBeenOpened: false,
    opened: false,
  }

  static propTypes = {
    opened: PropTypes.bool,
    roundCount: PropTypes.string,
    // team100Logo: PropTypes.string,
    // team200Logo: PropTypes.string,
    // team100Tricode: PropTypes.string,
    // team200Tricode: PropTypes.string,
    gameTeam100BGLogoLeft: PropTypes.string,
    gameTeam200BGLogoRight: PropTypes.string,
    team100SubColor: PropTypes.string,
    team200SubColor: PropTypes.string,
    team100Color: PropTypes.string,
    team200Color: PropTypes.string,
    team100Name: PropTypes.string,
    team200Name: PropTypes.string,
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.opened !== this.props.opened) {
      if (nextProps.opened) {
        if (this.state.hasBeenOpened) {
          this.setState({hasBeenOpened: false});
          delay(() => {
            this.setState({hasBeenOpened: true, opened: true});
          }, 100);
        } else {
          this.setState({hasBeenOpened: true, opened: true});
        }
      } else {
          this.setState({opened: false});
      }
    }
  }

  getBorderColor = (side, borderColor) => {
    let col;
    switch(borderColor) {
      case 'teamMain':
        col = side === 'left' ? this.props.team100Color : this.props.team200Color;
        break;
      case 'teamSub':
        col = side === 'left' ? this.props.team100SubColor : this.props.team200SubColor;
        break;
      default:
        col = borderColor.startsWith('#') ? borderColor : '#000000';
    }
    return col;
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (nextProps.opened !== this.props.opened) || (!isEqual(this.state, nextState));
  }

  render() {
    const theme = this.context.screens.gameScreen.versus || {};
    // const team100Logo = this.props.team100Logo;
    // const team200Logo = this.props.team200Logo;
    // console.log('versus', this.props);

    return (
      <StyleMatch
        pose={
          this.state.opened ? 'open' :
          this.state.hasBeenOpened ? 'end' : 'start'
        }
        backgroundTexture={theme.backgroundTexture || ''}
        borderGradientColorLeft={this.getBorderColor('left', theme.borderColor)}
        borderGradientColorRight={this.getBorderColor('right', theme.borderColor)}
        borderGradientColorBase={theme.borderColorBase || '#000000'}
        css={theme.cssVersusOverlay || ''}
      >
        <StyleTeam
          side={'left'}
          backgroundColor={this.props.team100SubColor}
          backgroundImage={this.props.gameTeam100BGLogoLeft}

          align={theme.textAlign || 'center'}
        >
         {/*<StyleTeamLogo alt='' src={team100Logo} /> */}
          <StyleTeamName
            textColor={theme.textColorTeams}
            css={theme.cssTeamName||''}
          >{this.props.team100Name}</StyleTeamName>
        </StyleTeam>
        <StyleVersus textColor={theme.textColorVersus} css={theme.cssVersus||''}>
          {String(this.props.roundCount).length < 3 ? `ROUND ${this.props.roundCount}`: 'VS'}
        </StyleVersus>
        <StyleTeam
          side={'right'}
          backgroundColor={this.props.team200SubColor}
          align={theme.textAlign || 'center'}
          backgroundImage={this.props.gameTeam200BGLogoRight}
        >
          {/*<StyleTeamLogo alt='' src={team200Logo} /> */}
          <StyleTeamName
            textColor={theme.textColorTeams}
            css={theme.cssTeamName||''}
          >{this.props.team200Name}</StyleTeamName>
        </StyleTeam>
      </StyleMatch>
    )
  };
}


GameScreenVersus.contextType = ThemeContext;

export default GameScreenVersus;
