import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';

import { ThemeContext } from '../View/themes';
import DraftOrder from '../../storybook-built/dist/components/Games/LJL/DraftOrder';
import championsDict from '../../services/championService';



const DraftOrderComponent = props => {
  const theme = useContext(ThemeContext)
  return (
    <div className={'container'}>
      <DraftOrder
        data={get(props.data, 'draftOrder', {})}
        show={get(props.data, 'draftOrderVisible', false)}
        theme={theme.screens.draftOrder || {}}
        championsDict={championsDict}
      />
    </div>
  );
};

DraftOrderComponent.propTypes = {
  data: PropTypes.object,
  // active: PropTypes.bool,
};

export default DraftOrderComponent;
