// REACT
import React, { Component } from 'react';
import PropTypes from 'prop-types'
import { cloneDeep, get } from 'lodash';
// INTERNAL
import {
    StyleDrakeTokenTable,
    StyleIconActive,
    StyleSelectToken,
    StyleSelect,
    StyleReset,
    StyleInfoBoxContainer,
    StyleInfoBoxButton,
    StyleInfoBoxButtonIcon,
    StyleInvalidData,
} from './styles';
import { subscribe } from '../../../services/api';
import ControlContainer from '../../Controller/ControlContainer';
import Button from '../../Button';
import validateDataSchedule from '../../Data/DataSchedule/validate';
import {i18n} from '../../i18n';
import Picker from '../../Picker';
import DataGameTimers from '../../Data/DataGameTimers';
import DataInGameTips from '../../Data/DataInGameTip';
import DataContainer from '../../Data/DataContainer'
import {loadImageUrlTeamColorLogo, /* loadImageUrlPlayerVoting ,*/ loadImageUrlTeamLogo, loadImageUrlTeamScoreLogo} from '../../../util/loadImageUrl';
import { updateScreenStatus } from '../../../util/controllerUtils';


const initialDragonTokens = {
    teamLeft: {
        1: '',
        2: '',
        3: '',
        4: '',
        soul: '',
    },
    teamRight: {
        1: '',
        2: '',
        3: '',
        4: '',
        soul: '',
    },
}
class GameScreenControl extends Component {

    static propTypes = {
        schedule: PropTypes.object,
            cgpc: PropTypes.string,
            display: PropTypes.bool,
            screen1: PropTypes.bool,
    };

    state = {
        status: 'ready',
        infoBoxChannel: '',
        vsVisible: false,
        infoBoxVisible: false,
        headerVisible: false,
        sponsorVisible: true,
        scoreVisible: false,
        jungleRouteVisible: false,
        championsXPVisible: false,
        dragonTokens: cloneDeep(initialDragonTokens),
        goldDifference: 0,
        showPopupVideo: false,
        jungleRouteStatus: '',
        inGameTips: {},
        isGamePaused: false,
        topBar: 'new',
        gameTimeVisible: false,
    }

    onChangeTopBar = event => {
        this.setState({topBar: event.target.value})
        this.onUpdate('topBar', event.target.value)
    }

    componentDidMount() {
        this.runSubscription();
        if (validateDataSchedule()) {
            this.setState({status: 'ready'});
        } else {
            this.setState({status: 'invalid'});
        }
    }

    runSubscription = () => {
        subscribe('control-gameScreen', null, data => {
            if (data.target === 'control-gameScreen') {
                const resultState = {
                    ...data,
                    ...data.data,
                    gameTimeVisible: data.data && data.data.visible && data.data.visible.gameTime,
                    vsVisible: data.data && data.data.visible && data.data.visible.vs,
                    infoBoxVisible: data.data && data.data.visible && data.data.visible.infoBox,
                    headerVisible: data.data && data.data.visible && data.data.visible.header,
                    sponsorVisible: data.data && data.data.visible && data.data.visible.sponsor,
                    scoreVisible: data.data && data.data.visible && data.data.visible.score,
                    showPopupVideo: data.data && data.data.showPopupVideo,
                    jungleRouteVisible: data.data && data.data.visible && data.data.visible.jungleRoute,
                    championsXPVisible: data.data && data.data.visible && data.data.visible.championsXP,
                    inGameTips: data.data && data.data.inGameTips,
                };
                delete resultState.data;
                this.setState(resultState);
            }
        }, this.props.cgpc);
    };

    recursiveDataFetch = () => {
        if(((this.state.infoBoxVisible &&
            (this.state.infoBoxChannel === 'goldDifference' ||
                this.state.infoBoxChannel === 'goldPerPlayer' ||
                this.state.infoBoxChannel === 'visionControl'||
                this.state.infoBoxChannel === 'turretPlate')) ||
                this.state.infoBoxChannel === 'playersMythicItem' ||
                this.state.championsXPVisible) ||
            (this.state.topBar === 'new' && this.state.status === 'open')){
                const sendData = this.getSendData();
                updateScreenStatus('gameScreen', this.state.status, this.getSendData(), null, this.props.cgpc || 'open', sendData, this.getSendData(), null, this.props.cgpc);

                if (this.recursiveDataFetchTimeoutId !== null && this.recursiveDataFetchTimeoutId !== undefined) {
                    clearTimeout(this.recursiveDataFetchTimeoutId);
                }
                this.recursiveDataFetchTimeoutId = setTimeout(()=>{this.recursiveDataFetch()}, 2000);
        }
    }

    onPlay = () => {
        if (validateDataSchedule()) {
            // send('event', { target: 'view', action: 'open', screen: 'gameScreen', data: { ...this.getSendData() } });
            updateScreenStatus('gameScreen', 'open', this.getSendData(), null, this.props.cgpc);
            if(((this.state.infoBoxVisible &&
                (this.state.infoBoxChannel === 'goldDifference' ||
                    this.state.infoBoxChannel === 'goldPerPlayer' ||
                    this.state.infoBoxChannel === 'visionControl' ||
                    this.state.infoBoxChannel === 'turretPlate')) ||
                    this.state.infoBoxChannel === 'playersMythicItem' ||
                    this.state.championsXPVisible) ||
                (this.state.topBar === 'new' && this.state.status === 'open')){
                    this.recursiveDataFetch();
            }
        } else {
            this.setState({status: 'invalid'});
        }
    }

    onUpdate = (toUpdate, v, callback) => {
        if (toUpdate) {
            this.setState({ [toUpdate]: v }, () => {
                callback && callback();
                const sendData = this.getSendData();
                // send('event', { target: 'view', action: 'update', screen: 'gameScreen', data: { ...sendData, update: toUpdate, value: v } });
                updateScreenStatus('gameScreen', this.state.status, this.getSendData(), null, this.props.cgpc || 'open', sendData, this.getSendData(), null, this.props.cgpc);
                if(((this.state.infoBoxVisible &&
                    (this.state.infoBoxChannel === 'goldDifference' ||
                        this.state.infoBoxChannel === 'goldPerPlayer' ||
                        this.state.infoBoxChannel === 'visionControl'||
                        this.state.infoBoxChannel === 'turretPlate')) ||
                        this.state.infoBoxChannel === 'playersMythicItem' ||
                        this.state.championsXPVisible) ||
                    (this.state.topBar === 'new' && this.state.status === 'open')) {
                        this.recursiveDataFetch();
                }
            });
        }else {
            callback && callback();
        }
    }

    onUpdateMany = async (updateValueMap) => {
        if (updateValueMap) {
            await new Promise(resolve => this.setState({ ...updateValueMap }, resolve));
            const sendData = this.getSendData();
            // send('event', { target: 'view', action: 'update', screen: 'gameScreen', data: { ...sendData, update: toUpdate, value: v } });
            updateScreenStatus('gameScreen', this.state.status, this.getSendData(), null, this.props.cgpc || 'open', sendData, this.getSendData(), null, this.props.cgpc);
            if((this.state.infoBoxVisible &&
                (this.state.infoBoxChannel === 'goldDifference' ||
                    this.state.infoBoxChannel === 'goldPerPlayer' ||
                    this.state.infoBoxChannel === 'visionControl' ||
                    this.state.infoBoxChannel === 'turretPlate' ||
                    this.state.infoBoxChannel === 'playersMythicItem' ||
                    this.state.championsXPVisible)) ||
                (this.state.topBar === 'new' && this.state.status === 'open')) {
                    this.recursiveDataFetch();
            }
        }
    }

    onClose = () => {
        // send('event', { target: 'view', action: 'close', screen: 'gameScreen' });
        updateScreenStatus('gameScreen', 'close', null, null, this.props.cgpc);
        this.setState({
            infoBoxChannel: '',
            infoBoxVisible : false,
        })
    }

    getSendData = () => {
        const schedule = window.MainData.schedule;
        const league = schedule.league;
        const season = schedule.season;
        const split = schedule.split;
        const roundId = schedule.round;
        const matchId = schedule.match;
        const gameId = schedule.game;
        const match = schedule.rounds[roundId].matches[matchId];
        const flipped = match.games[gameId].teams[match.team100.tricode].side !== '100';
        const gameTeam100score = flipped ? match.team200.score : match.team100.score;
        const gameTeam200score = flipped ? match.team100.score : match.team200.score;
        const gameTeam100 = flipped ? match.team200.tricode : match.team100.tricode;
        const gameTeam200 = flipped ? match.team100.tricode : match.team200.tricode;
        const gameTeam100LogoUniColor = flipped ? loadImageUrlTeamColorLogo(match.team200, league, season, split): loadImageUrlTeamColorLogo(match.team100, league, season, split);
        const gameTeam100LogoFullColor = flipped ? loadImageUrlTeamLogo(match.team200, league, season, split) : loadImageUrlTeamLogo(match.team100, league, season, split);
        const gameTeam100LogoGameScreen = flipped ? loadImageUrlTeamScoreLogo(match.team200, league, season, split): loadImageUrlTeamScoreLogo(match.team100, league, season, split);
        const gameTeam200LogoUniColor = flipped ? loadImageUrlTeamColorLogo(match.team100, league, season, split) : loadImageUrlTeamColorLogo(match.team200, league, season, split);
        const gameTeam200LogoFullColor = flipped ? loadImageUrlTeamLogo(match.team100, league, season, split) : loadImageUrlTeamLogo(match.team200, league, season, split);
        const gameTeam200LogoGameScreen = flipped ? loadImageUrlTeamScoreLogo(match.team100, league, season, split): loadImageUrlTeamScoreLogo(match.team200, league, season, split);

        const gameTeam100BGLogo = flipped ? window.MainData.teams[match.team200.tricode].images.logoBGFull : window.MainData.teams[match.team100.tricode].images.logoBGFull;
        const gameTeam200BGLogo = flipped ? window.MainData.teams[match.team100.tricode].images.logoBGFull : window.MainData.teams[match.team200.tricode].images.logoBGFull;

        const gameTeam100BGLogoLeft = flipped ? window.MainData.teams[match.team200.tricode].images.logoBGFullLeft : window.MainData.teams[match.team100.tricode].images.logoBGFullLeft;
        const gameTeam100BGLogoRight = flipped ? window.MainData.teams[match.team200.tricode].images.logoBGFullRight : window.MainData.teams[match.team100.tricode].images.logoBGFullRight;
        const gameTeam200BGLogoLeft = flipped ? window.MainData.teams[match.team100.tricode].images.logoBGFullLeft : window.MainData.teams[match.team200.tricode].images.logoBGFullLeft;
        const gameTeam200BGLogoRight = flipped ? window.MainData.teams[match.team100.tricode].images.logoBGFullRight : window.MainData.teams[match.team200.tricode].images.logoBGFullRight;

        const gameTeam100Color = flipped ? match.team200.color.main : match.team100.color.main;
        const gameTeam100ColorSub = flipped ? match.team200.color.sub : match.team100.color.sub;
        const gameTeam200Color = flipped ? match.team100.color.main : match.team200.color.main;
        const gameTeam200ColorSub = flipped ? match.team100.color.sub : match.team200.color.sub;
        const gameTeam100SubColor = flipped ? match.team200.color.sub : match.team100.color.sub;
        const gameTeam200SubColor = flipped ? match.team100.color.sub : match.team200.color.sub;
        const gameTeam100Name = flipped ? match.team200.name : match.team100.name;
        const gameTeam200Name = flipped ? match.team100.name : match.team200.name;

        const visionData = window.MainData.dataApiListener.component.getCurrentVisionData();
        const gameTeam100VisionControl = (visionData && visionData.blue) || 0;
        const gameTeam200VisionControl = (visionData && visionData.red) || 0;

        const team100Standings = (window.MainData.standings && window.MainData.standings.REGULAR) ? window.MainData.standings.REGULAR.find(team => team.name === match.team100.name) : null;
        const team200Standings = (window.MainData.standings && window.MainData.standings.REGULAR) ? window.MainData.standings.REGULAR.find(team => team.name === match.team200.name) : null;

        const team100Config = window.MainData.teams[gameTeam100];
        const team200Config = window.MainData.teams[gameTeam200];

        const team100ScoreAsset = team100Config.images && team100Config.images.scoreAsset;
        const team200ScoreAsset = team200Config.images && team200Config.images.scoreAsset;

        const tweets = window.MainData.twitter.tweets;
        const tweetlist = [];
        tweets.response.map(tweet =>
            tweets.selected.includes(tweet.id) && tweetlist.push({text: tweet.text, name: tweet.user.name, screen_name: tweet.user.screen_name})
        );
        const playersRunesData = window.MainData.dataApiListener.component.getPlayerRunesData()
        const currentMatchTeams = window.MainData.schedule.rounds[window.MainData.schedule.round].matches[window.MainData.schedule.match].games[window.MainData.schedule.game].teams

        const teamsGoldGraphData = window.MainData.dataApiListener.component.getTeamGoldGraphData();
        const teamGoldCount = window.MainData.dataApiListener.component.getTeamGoldCount();
        const goldPerPlayerGraphData = window.MainData.dataApiListener.component.getPlayersGoldGraphData();
        const damageDealtToChampionsPerPlayers = window.MainData.damageDealtToChampionsData;
        const turretsStatus = window.MainData.dataApiListener.component.getTurretsStatus();

        // const damageDealtToTurretsPerPlayer = window.MainData.dataApiListener.component.getPlayersDamageDealttoTurret();
        // const totalGoldEarnedFromTurretPlateDestructionPerPlayer = window.MainData.dataApiListener.component.getTotalGoldEarnedFromTurretPlateDestruction();
        const whoWillWinAI = get(window, 'MainData.whoWillWinAI.prediction', {});
        const whoWillWinAIGraph = get(window, 'MainData.whoWillWinAI.collection',[]);
        const whoWillWinAIGraphBlitzcrank = get(window, 'MainData.whoWillWinAI.blitzcrankAsset','');
        const gameFlowPrediction = get(window, 'MainData.gameFlowPrediction.collection',[]);

        const jungleRouteData = window.MainData.jungleRouteData;
        const championsXP =  window.MainData.dataApiListener.component.getPlayersXPLevel();
        const junglerProximityData =  {
                                players:
                                [
                                    {
                                        name: get(currentMatchTeams, `${gameTeam100}.roster.jungle.name`,'no Name'),
                                        profileImg: get(team100Config, `roster.${get(currentMatchTeams, `${gameTeam100}.roster.jungle.name`)}.images.results`),
                                        teamBGVideoLeft: get(team100Config, `videos.keyPlayersLeft`),
                                        teamBGVideoRight: get(team100Config, `videos.keyPlayersRight`),
                                        stats: ['TOP', 'MID', 'BOT'].map(pos => get(window, `MainData.junglerProximityData.team100.${pos}`)),
                                        images: get(team100Config, `images`, {}),
                                        teamColors: get(team100Config, `color`, {}),
                                    },
                                    {
                                        name: get(currentMatchTeams, `${gameTeam200}.roster.jungle.name`,'no Name'),
                                        profileImg: get(team200Config, `roster.${get(currentMatchTeams, `${gameTeam200}.roster.jungle.name`)}.images.results`),
                                        teamBGVideoLeft: get(team200Config, `videos.keyPlayersLeft`),
                                        teamBGVideoRight: get(team200Config, `videos.keyPlayersRight`),
                                        stats: ['TOP', 'MID', 'BOT'].map(pos => get(window, `MainData.junglerProximityData.team200.${pos}`)),
                                        images: get(team200Config, `images`, {}),
                                        teamColors: get(team200Config, `color`, {}),
                                    },
                                ],
                                stats:['TOP', 'MID', 'BOT'],
                            }
        const itemsPurchaseHistory = get(window, 'MainData.dataItemsPurchaseHistory');
        const inGameTipInfobox= get(window, 'MainData.inGameTipInfobox');
        const mythicItemInfobox= get(window, 'MainData.mythicItemInfobox');
        const playersMythicItem = window.MainData.dataApiListener.component.getPlayersMythicItem();
        const whoWillWinAIGraphEvents = window.MainData.dataApiListener.component.getWhoWillWinAIGraphEvents();

        return {
            league: get(window, 'MainData.schedule.league', 'LJL'),
            season: get(window, 'MainData.schedule.season', '2020'),
            split: get(window, 'MainData.schedule.split', 'SPRING'),
            bestOf: get(match, 'bestOf', 1),
            standingsLeft: flipped ? team200Standings : team100Standings,
            standingsRight: flipped ? team100Standings : team200Standings,
            gameTeamLeftScore: match.bestOf > 1 ? (gameTeam100score || 0) : null,
            gameTeamRightScore: match.bestOf > 1 ? (gameTeam200score || 0) : null,
            gameTeamLeft: gameTeam100,  // Tricode 'DFM', 'AXZ', ...
            gameTeamRight: gameTeam200, // Tricode 'DFM', 'AXZ', ...
            gameTeamLeftScoreAsset: team100ScoreAsset,
            gameTeamRightScoreAsset: team200ScoreAsset,
            gameTeamLeftLogo: gameTeam100LogoUniColor,
            gameTeamLeftLogoFullColor: gameTeam100LogoFullColor,
            gameTeamRightLogo: gameTeam200LogoUniColor,
            gameTeamRightScoreLogo: ``,
            gameTeamRightLogoFullColor: gameTeam200LogoFullColor,
            gameTeamLeftLogoScore: gameTeam100LogoGameScreen,
            gameTeamRightLogoScore: gameTeam200LogoGameScreen,
            gameTeamLeftColor: gameTeam100Color,
            gameTeamLeftColorSub: gameTeam100ColorSub,
            gameTeamRightColor: gameTeam200Color,
            gameTeamRightColorSub: gameTeam200ColorSub,
            gameTeamLeftSubColor: gameTeam100SubColor,
            gameTeamRightSubCoolor: gameTeam200SubColor,
            gameTeamLeftName: gameTeam100Name,
            gameTeamRightName: gameTeam200Name,
            gameTeam100BGLogo,
            gameTeam200BGLogo,
            gameTeam100BGLogoLeft,
            gameTeam100BGLogoRight,
            gameTeam200BGLogoLeft,
            gameTeam200BGLogoRight,
            tweetlist,
            twitterVotes: window.MainData.twitter.teams.votes,
            roundCount: schedule.rounds[roundId].name,
            settings: window.MainData.settings,
            playersStats: window.MainData.gameScreenPlayerStats,
            teamsStats: window.MainData.gameScreenTeamsStats,
            playersRunes: playersRunesData,
            currentMatchTeams,
            visionData,
            visionControl: {
                team100 : gameTeam100VisionControl,
                team200 : gameTeam200VisionControl,
            },
            // gameScreenTimers: this.state.gameScreenTimers,
            gameScreenAssets: this.state.gameScreenAssets,
            dragonSelected: this.state.dragonSelected,
            dragonSubtypeSelected: this.state.dragonSubtypeSelected,
            dragonTokens: this.state.dragonTokens,
            goldDifference: this.state.goldDifference,
            infoBoxChannel: this.state.infoBoxChannel,
            timerData: this.state.timerData,
            topBar: this.state.topBar,
            visible: {
                gameTime: this.state.gameTimeVisible,
                vs: this.state.vsVisible,
                infoBox: this.state.infoBoxVisible,
                header: this.state.headerVisible,
                sponsor: this.state.sponsorVisible,
                score: this.state.scoreVisible,
                jungleRoute: this.state.jungleRouteVisible,
                championsXP: this.state.championsXPVisible,
            },
            preloadImages: [gameTeam100LogoUniColor, gameTeam100LogoFullColor, gameTeam200LogoUniColor, gameTeam200LogoFullColor,
                team100ScoreAsset, team200ScoreAsset],
            // goldDifferenceGraph: window.MainData.gameBreakdown.goldDiff,
            goldDifferenceGraph: teamsGoldGraphData,
            // goldPerPlayer: window.MainData.gameBreakdown.stats,
            goldPerPlayers: goldPerPlayerGraphData,
            goldCount: teamGoldCount,
            sponsors: get(window, 'MainData.sponsors', undefined),
            showPopupVideo: this.state.showPopupVideo,
            jungleRouteData,
            damageDealtToChampionsPerPlayers,
            killCount: this.state.killCount,
            turretsStatus,
            turretsDestroyed: this.state.turretsDestroyed,
            // damageDealtToTurretsPerPlayer,
            // totalGoldEarnedFromTurretPlateDestructionPerPlayer,
            whoWillWinAI,
            whoWillWinAIGraphBlitzcrank,
            whoWillWinAIGraph,
            whoWillWinAIGraphEvents,
            championsXP,
            junglerProximityData,
            itemsPurchaseHistory,
            inGameTips: this.state.inGameTips,
            inGameTipInfobox,
            mythicItemInfobox,
            playersMythicItem,
            gameFlowPrediction,
            isGamePaused: this.state.isGamePaused,
            isGameStarted: this.state.isGameStarted,
            initialGameTime: this.state.initialGameTime,
            initialSystemTime: this.state.initialSystemTime,
            liveStatusDelay: window.MainData.realtimeApi.liveStatusDelay,
        };
    }

    tokensItems = [
        { name: 'Infernal', value: 'Infernal', img: 'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/Drake-Token_Infernal.png' },
        { name: 'Mountain', value: 'Mountain', img: 'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/Drake-Token_Mountain.png' },
        { name: 'Cloud', value: 'Cloud', img: 'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/Drake-Token_Cloud.png' },
        { name: 'Ocean', value: 'Ocean', img: 'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/Drake-Token_Ocean.png' },
        { name: 'Chemtech', value: 'Chemtech', img: 'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/Drake-Token_Chemtech.png' },
        { name: 'Hextech', value: 'Hextech', img: 'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/Drake-Token_Hextech.png' },
    ];

    handleTokensChange = (token, teamSide, position) => {
        const tokens = cloneDeep(this.state.dragonTokens);
        tokens[teamSide][position] = token ||'';
        if (position === 4) {
            tokens[teamSide].soul = token || ''
        }
        this.onUpdate('dragonTokens', tokens);
    }

    handleInfoBoxChange = (newChannel) => {
        if (newChannel && newChannel !== this.state.infoBoxChannel) {
            if (this.state.infoBoxVisible) {
                this.onUpdate('infoBoxVisible', false);
                setTimeout(() => {
                    this.setState({
                        infoBoxChannel: newChannel,
                    }, ()=>{ this.onUpdate('infoBoxVisible', true)})
                }, 1000);
            }
            else {
                this.setState({
                    infoBoxChannel: newChannel,
                    infoBoxVisible: true,
                },  this.onUpdate('infoBoxVisible', true))
            }
        }
        else {
            this.onUpdate('infoBoxVisible', false);
            this.setState({
                infoBoxChannel: '',
                infoBoxVisible: false,
            });
        }
    }

    isTokensActive = () => {
        return (
            this.state.dragonTokens.teamLeft[1] ||
            this.state.dragonTokens.teamLeft[2] ||
            this.state.dragonTokens.teamLeft[3] ||
            this.state.dragonTokens.teamLeft[4] ||
            this.state.dragonTokens.teamRight[1] ||
            this.state.dragonTokens.teamRight[2] ||
            this.state.dragonTokens.teamRight[3] ||
            this.state.dragonTokens.teamRight[4]
        );
    }

    runningTimers = [];

    filterRunningTimers = (timersToRemove) => {
        this.runningTimers = this.runningTimers.filter(timer => !timersToRemove.includes(timer.timer));
    }

    render() {
        const gameScreenComponents = get(window, 'MainData.components.gameScreen', {});
        // console.log(gameScreenComponents)
        const dataComponents = get(window, 'MainData.components.data', {});
        return (
            <ControlContainer style={{display: this.props.display ? 'none': 'block'}} className="control--gamescreen" status={this.state.status}>
                <div className="control__panel">
                    <h2 className="control__title">
                        <i className="fas fa-external-link-square-alt"></i> {i18n().pages.gamescreen}
                    </h2>
                    <Button controllerSide={!this.props.screen1} action={this.onPlay} text={i18n().general.open} icon="fas fa-play-circle" disabled={
                        (this.state.status === 'open' || this.state.status === 'opening') && true }
                    />
                    <Button controllerSide={!this.props.screen1} action={this.onClose} text={i18n().general.close} icon="fas fa-stop-circle" disabled={
                        !(this.state.status === 'open' || this.state.status === 'opening') && true }
                    />
                </div>

                <div className="control-extra">
                    <StyleReset
                        style={{ display: 'block' }}
                        onClick={async () => {
                            console.log('reset game screen controller');
                            await this.onUpdateMany({
                                dragonTokens: cloneDeep(initialDragonTokens),
                                vsVisible: false,
                                headerVisible: false,
                                sponsorVisible: false,
                                scoreVisible: false,
                                jungleRouteVisible: false,
                                infoBoxVisible: false,
                                infoBoxChannel: '',

                                status: 'ready',
                                championsXPVisible: false,
                                goldDifference: 0,
                                showPopupVideo: false,
                                jungleRouteStatus: '',
                                inGameTips: {},
                                isGamePaused: false,
                                gameTimeVisible: false,
                            });
                            await window.MainData.dataGameTimers.component.setHideMany(['baronLive', 'heraldLive', 'dragonSelectedLive']);
                            await window.MainData.dataGameTimers.component.resetGameTime();
                            await window.MainData.dataGameTimers.component.resetAllDragonKills();
                            const timers = Object.keys(window.MainData.dataGameTimers.component.timers);
                            await window.MainData.dataGameTimers.component.setHideMany(timers);
                            await window.MainData.dataGameTimers.component.resetTimers(timers);
                            await window.MainData.dataGameTimers.component.resetMetadata();
                            await window.MainData.dataGameTimers.component.resetKillCount();
                            await window.MainData.dataGameTimers.component.resetTurretsDestroyed();
                            await window.MainData.dataGameTimers.component.resetIsGameStarted();
                            await window.MainData.dataGameTimers.component.resetInitialGameTime();
                            await window.MainData.dataGameTimers.component.resetInitialSystemTime();
                            console.log(this.runningTimers, 'top level')
                        }}
                    >Reset All</StyleReset>
                    <div className="control-extra__fieldset">
                        <label className="control-extra__fieldset__label">{i18n().controller.gamescreen.topBar}</label>
                        <StyleSelect onChange={this.onChangeTopBar} value={this.state.topBar}>
                            <option value="old">{i18n().general.old}</option>
                            <option value="new">{i18n().general.new}</option>
                        </StyleSelect>
                    </div>
                    {this.state.topBar === 'new' &&
                        <div className="control-extra__fieldset">
                            <label className="control-extra__fieldset__label">{i18n().controller.gamescreen.inGameTimer}</label>
                            <div className="pill">
                                {
                                    this.state.gameTimeVisible ?
                                        [
                                            <span key="1a" className="pill__span--highlighted">{i18n().controller.viewable.true}</span>,
                                            <Button controllerSide={!this.props.screen1} key="1b" text="Hide" action={() => this.onUpdate('gameTimeVisible', false)} />,
                                        ] :
                                        [
                                            <span key="2a">{i18n().controller.viewable.false}</span>,
                                            <Button controllerSide={!this.props.screen1} key="2b" text="Show" action={() => this.onUpdate('gameTimeVisible', true)} />,
                                        ]
                                }
                            </div>
                        </div>
                    }
                    {gameScreenComponents.vsOverlay && <div className="control-extra__fieldset">
                        <label className="control-extra__fieldset__label">{i18n().controller.gamescreen.vsOverlay}</label>
                        <div className="pill">
                            {
                                this.state.vsVisible ?
                                    [
                                        <span key="1a" className="pill__span--highlighted">{i18n().controller.viewable.true}</span>,
                                        <Button controllerSide={!this.props.screen1} key="1b" text="Hide" action={() => this.onUpdate('vsVisible', false)} />,
                                    ] :
                                    [
                                        <span key="2a">{i18n().controller.viewable.false}</span>,
                                        <Button controllerSide={!this.props.screen1} key="2b" text="Show" action={() => this.onUpdate('vsVisible', true)} />,
                                    ]
                            }
                        </div>
                    </div> }
                    {(gameScreenComponents.header && <div className="control-extra__fieldset">
                        <label className="control-extra__fieldset__label">Header</label>
                        <div className="pill">
                            {
                                this.state.headerVisible ?
                                    [
                                        <span key="1a" className="pill__span--highlighted">{i18n().controller.viewable.true}</span>,
                                        <Button controllerSide={!this.props.screen1} key="1b" text="Hide" action={() => this.onUpdate('headerVisible', false)} />,
                                    ] :
                                    [
                                        <span key="2a">{i18n().controller.viewable.false}</span>,
                                        <Button controllerSide={!this.props.screen1} key="2b" text="Show" action={() => this.onUpdate('headerVisible', true)} />,
                                    ]
                            }
                        </div>
                    </div>) || null}
                    {(gameScreenComponents.sponsors && <div className="control-extra__fieldset">
                        <label className="control-extra__fieldset__label">{i18n().controller.gamescreen.sponsor}</label>
                        <div className="pill">
                            {
                                this.state.sponsorVisible ?
                                    [
                                        <span key="1a" className="pill__span--highlighted">{i18n().controller.viewable.true}</span>,
                                        <Button controllerSide={!this.props.screen1} key="1b" text="Hide" action={() => this.onUpdate('sponsorVisible', false)} />,
                                    ] :
                                    [
                                        <span key="2a">{i18n().controller.viewable.false}</span>,
                                        <Button controllerSide={!this.props.screen1} key="2b" text="Show" action={() => this.onUpdate('sponsorVisible', true)} />,
                                    ]
                            }
                        </div>
                    </div>) || null}
                    {(gameScreenComponents.score && <div className="control-extra__fieldset">
                        <label className="control-extra__fieldset__label">{i18n().controller.gamescreen.score}</label>
                        <div className="pill">
                            {
                                this.state.scoreVisible ?
                                    [
                                        <span key="1a" className="pill__span--highlighted">{i18n().controller.viewable.true}</span>,
                                        <Button controllerSide={!this.props.screen1} key="1b" text="Hide" action={() => this.onUpdate('scoreVisible', false)} />,
                                    ] :
                                    [
                                        <span key="2a">{i18n().controller.viewable.false}</span>,
                                        <Button controllerSide={!this.props.screen1} key="2b" text="Show" action={() => this.onUpdate('scoreVisible', true)} />,
                                    ]
                            }
                        </div>
                    </div>) || null}
                    {(gameScreenComponents.jungleRoute && <div className="control-extra__fieldset">
                        <label className="control-extra__fieldset__label">{i18n().controller.gamescreen.jungleRoute}</label>
                        <div className="pill">
                            {
                                this.state.jungleRouteVisible ?
                                    [
                                        <span key="1a" className="pill__span--highlighted">{i18n().controller.viewable.true}</span>,
                                        <Button controllerSide={!this.props.screen1} key="1b" text="Hide" action={() => this.onUpdate('jungleRouteVisible', false)} />,
                                    ] :
                                    [
                                        <span key="2a">{i18n().controller.viewable.false}</span>,
                                        <Button controllerSide={!this.props.screen1} key="2b" text="Show" action={() => {
                                            if(window.MainData.jungleRouteData){
                                                this.onUpdate('jungleRouteVisible', true);
                                                this.setState({ jungleRouteStatus: ''});
                                            }else{
                                                this.setState({ jungleRouteStatus: 'invalid data'});
                                            }
                                        }}
                                        />,
                                    ]
                            }
                        </div>
                        {this.state.jungleRouteStatus && <StyleInvalidData>{this.state.jungleRouteStatus}</StyleInvalidData>}
                    </div>) || null}
                    {(gameScreenComponents.championsXP && <div className="control-extra__fieldset">
                        <label className="control-extra__fieldset__label">{i18n().controller.gamescreen.championsXP}</label>
                        <div className="pill">
                            {
                                this.state.championsXPVisible ?
                                    [
                                        <span key="1a" className="pill__span--highlighted">{i18n().controller.viewable.true}</span>,
                                        <Button controllerSide={!this.props.screen1} key="1b" text="Hide" action={() => this.onUpdate('championsXPVisible', false)} />,
                                    ] :
                                    [
                                        <span key="2a">{i18n().controller.viewable.false}</span>,
                                        <Button controllerSide={!this.props.screen1} key="2b" text="Show" action={() => {
                                                this.onUpdate('championsXPVisible', true);
                                            }}
                                        />,
                                    ]
                            }
                        </div>
                    </div>) || null}
                    <div className="control-extra__fieldset">
                        <label className="control-extra__fieldset__label">{i18n().controller.gamescreen.infoBox}:</label>
                        {/* <div className="pill">
                            <Picker items={this.infoBoxItems} onUpdate={this.handleInfoBoxChange} preselect={this.state.infoBoxChannel} />
                            {
                                this.state.infoBoxVisible ?
                                [
                                    <span key="1a" className="pill__span--highlighted">{i18n().controller.viewable.true}</span>,
                                    <Button controllerSide={!props.screen1} key="1b" text="Hide" action={() => this.onUpdate('infoBoxVisible', false)} />,
                                ] :
                                [
                                    <span key="2a">{i18n().controller.viewable.false}</span>,
                                    <Button controllerSide={!props.screen1} key="2b" text="Show" action={() => this.onUpdate('infoBoxVisible', true)} />,
                                ]
                            }

                        </div> */}
                        <StyleInfoBoxContainer>
                            {(gameScreenComponents.playersRunes &&
                            <StyleInfoBoxButton
                                controllerSide={!this.props.screen1}
                                active={this.state.infoBoxChannel === 'playersRunes'}
                                onClick={() => { (this.state.status!== 'close') && this.handleInfoBoxChange('playersRunes') }}
                            >
                                <StyleInfoBoxButtonIcon className={'fas fa-gem'} />
                                {i18n().controller.gamescreen.playersRunes}
                            </StyleInfoBoxButton>) || null}
                            {(gameScreenComponents.goldPerPlayer &&
                            <StyleInfoBoxButton
                            controllerSide={!this.props.screen1}
                                active={this.state.infoBoxChannel === 'goldPerPlayer'}
                                onClick={() => { (this.state.status!== 'close') && this.handleInfoBoxChange('goldPerPlayer') }}
                            >
                                <StyleInfoBoxButtonIcon className={'fas fa-chart-bar'} />
                                {i18n().controller.gamescreen.goldPerPlayer}
                            </StyleInfoBoxButton>) || null}
                            {(gameScreenComponents.goldDifference &&
                            <StyleInfoBoxButton
                            controllerSide={!this.props.screen1}
                                active={this.state.infoBoxChannel === 'goldDifference'}
                                onClick={() => { (this.state.status!== 'close') && this.handleInfoBoxChange('goldDifference') }}
                            >
                                <StyleInfoBoxButtonIcon className={'fas fa-chart-area'} />
                                {i18n().controller.gamescreen.goldDifference}
                            </StyleInfoBoxButton>) || null}
                            {(gameScreenComponents.visionControl &&
                            <StyleInfoBoxButton
                            controllerSide={!this.props.screen1}
                                active={this.state.infoBoxChannel === 'visionControl'}
                                onClick={() => { (this.state.status!== 'close') && this.handleInfoBoxChange('visionControl') }}
                            >
                                <StyleInfoBoxButtonIcon className={'fas fa-low-vision'} />
                                {i18n().controller.gamescreen.visionControl}
                            </StyleInfoBoxButton>) || null}
                            {(gameScreenComponents.whowillwin &&
                            <StyleInfoBoxButton
                            controllerSide={!this.props.screen1}
                                active={this.state.infoBoxChannel === 'whowillwin'}
                                onClick={() => { (this.state.status!== 'close') && this.handleInfoBoxChange('whowillwin') }}
                            >
                                <StyleInfoBoxButtonIcon className={'fas fa-trophy'} />
                                Who Will Win
                            </StyleInfoBoxButton>) || null}
                {/* WHO WILL WIN BP (AI) */}
                            {(gameScreenComponents.whoWillWinAI &&
                            <StyleInfoBoxButton
                            controllerSide={!this.props.screen1}
                                active={this.state.infoBoxChannel === 'whoWillWinAI'}
                                onClick={() => { (this.state.status!== 'close') && this.handleInfoBoxChange('whoWillWinAI') }}
                            >
                                <StyleInfoBoxButtonIcon className={'fas fa-mind-share'} />
                                Who Will Win (AI)
                            </StyleInfoBoxButton>) || null}
                {/* WHO WILL WIN GRAPH (AI) */}
                            {(gameScreenComponents.whoWillWinAIGraph &&
                            <StyleInfoBoxButton
                            controllerSide={!this.props.screen1}
                                active={this.state.infoBoxChannel === 'whoWillWinAIGraph'}
                                onClick={() => { (
                                    this.state.status!== 'close') &&
                                    // get(window, 'MainData.whoWillWinAI.collection',[]).length &&
                                    this.handleInfoBoxChange('whoWillWinAIGraph') }}
                            >
                                <StyleInfoBoxButtonIcon className={'far fa-chart-line'} />
                                {i18n().general.whoWillWinAIGraph}
                            </StyleInfoBoxButton>) || null}
                {/* GAME FLOW (FUTURE) PREDICTION */}
                            {(gameScreenComponents.gameFlowPrediction &&
                            <StyleInfoBoxButton
                            controllerSide={!this.props.screen1}
                                active={this.state.infoBoxChannel === 'gameFlowPrediction'}
                                onClick={() => { this.handleInfoBoxChange('gameFlowPrediction');}}
                            >
                                <StyleInfoBoxButtonIcon className={'far fa-flux-capacitor'} />
                                {i18n().general.gameFlowPrediction}
                            </StyleInfoBoxButton>) || null}
                            {(gameScreenComponents.fanTwitter &&
                            <StyleInfoBoxButton
                            controllerSide={!this.props.screen1}
                                active={this.state.infoBoxChannel === 'fantwitter'}
                                onClick={() => { (this.state.status!== 'close') && this.handleInfoBoxChange('fantwitter') }}
                            >
                                <StyleInfoBoxButtonIcon className={'fab fa-twitter'} />
                                Fan Twitter
                            </StyleInfoBoxButton>) || null}
                            {(gameScreenComponents.playerStats &&
                            <StyleInfoBoxButton
                            controllerSide={!this.props.screen1}
                                active={this.state.infoBoxChannel === 'playerStats'}
                                onClick={() => {(this.state.status!== 'close') && this.handleInfoBoxChange('playerStats') }}
                            >
                                <div>
                                    <StyleInfoBoxButtonIcon className={'fas fa-user'} />
                                    <StyleInfoBoxButtonIcon className={'fas fa-sort-numeric-down'} />
                                </div>
                                Players Stats
                            </StyleInfoBoxButton>) || null}
                            {(gameScreenComponents.teamStats &&
                            <StyleInfoBoxButton
                            controllerSide={!this.props.screen1}
                                active={this.state.infoBoxChannel === 'teamStats'}
                                onClick={() => {(this.state.status!== 'close') && this.handleInfoBoxChange('teamStats') }}
                            >
                                <div>
                                    <StyleInfoBoxButtonIcon className={'fas fa-users'} />
                                    <StyleInfoBoxButtonIcon className={'fas fa-sort-numeric-down'} />
                                </div>
                                Teams Stats
                            </StyleInfoBoxButton>) || null}
                            {(gameScreenComponents.damageDealt &&
                            <StyleInfoBoxButton
                            controllerSide={!this.props.screen1}
                                active={this.state.infoBoxChannel === 'damageDealt'}
                                onClick={() => { (this.state.status!== 'close') && this.handleInfoBoxChange('damageDealt') }}
                            >
                                <StyleInfoBoxButtonIcon className={'fas fa-swords'} />
                                {i18n().controller.gamescreen.damageDealt}
                            </StyleInfoBoxButton>) || null}
            {/* TOWER PLATE */}
                            {(gameScreenComponents.turretPlate &&
                            <StyleInfoBoxButton
                            controllerSide={!this.props.screen1}
                                active={this.state.infoBoxChannel === 'turretPlate'}
                                onClick={() => { (this.state.status!== 'close') && this.handleInfoBoxChange('turretPlate') }}
                            >
                                <StyleInfoBoxButtonIcon className={'fas fa-monument'} />
                                {i18n().controller.gamescreen.turretsPlate}
                            </StyleInfoBoxButton>) || null}
            {/* MYTHIC ITEM INFOBOX */}
                            {(gameScreenComponents.mythicItemInfobox &&
                            <StyleInfoBoxButton
                            controllerSide={!this.props.screen1}
                                active={this.state.infoBoxChannel === 'mythicItemInfobox'}
                                onClick={() => {
                                    if(
                                    (   (this.state.status!== 'close') &&
                                        (get(window, 'MainData.mythicItemInfobox.url') || Object.values(get(window, 'Maindata.mythicItemInfobox.custom',{})).length)) ||
                                        (this.state.infoBoxChannel === 'mythicItemInfobox')
                                    ) {
                                        console.log("mythicItemInfobox")
                                        this.handleInfoBoxChange('mythicItemInfobox');
                                    }
                                }}
                            >
                                <StyleInfoBoxButtonIcon className={'far fa-tag'} />
                                {i18n().general.mythicItemInfobox}
                            </StyleInfoBoxButton>) || null}
            {/* IN-GAME TIP INFOBOX */}
                            {(gameScreenComponents.inGameTipInfobox &&
                            <StyleInfoBoxButton
                            controllerSide={!this.props.screen1}
                                active={this.state.infoBoxChannel === 'inGameTipInfobox'}
                                onClick={() => {
                                    if(
                                        ((this.state.status!== 'close') &&
                                        (get(window, 'MainData.inGameTipInfobox.url') || Object.values(get(window, 'Maindata.inGameTipInfobox.custom',{})).length)) ||
                                        (this.state.infoBoxChannel === 'inGameTipInfobox')
                                    ) {
                                     this.handleInfoBoxChange('inGameTipInfobox');
                                    }
                                }}
                            >
                                <StyleInfoBoxButtonIcon className={'fal fa-info'} />
                                {i18n().general.inGameTipInfobox}
                            </StyleInfoBoxButton>) || null}
            {/* PLAYER MYTHIC ITEM */}
                            {(gameScreenComponents.playersMythicItem &&
                            <StyleInfoBoxButton
                            controllerSide={!this.props.screen1}
                                active={this.state.infoBoxChannel === 'playersMythicItem'}
                                onClick={() => { this.handleInfoBoxChange('playersMythicItem');}}
                            >
                                <StyleInfoBoxButtonIcon className={'far fa-bolt'} />
                                {i18n().general.playersMythicItem}
                            </StyleInfoBoxButton>) || null}
            {/* JUNGLE PROXIMITY */}
                            {(gameScreenComponents.junglerProximity &&
                            <StyleInfoBoxButton
                            controllerSide={!this.props.screen1}
                                active={this.state.infoBoxChannel === 'junglerProximity'}
                                onClick={() => {
                                    (this.state.status!== 'close') &&
                                    (window.MainData.junglerProximityData || this.state.infoBoxChannel === 'junglerProximity') &&
                                    this.handleInfoBoxChange('junglerProximity')
                                }}
                                disabled={!window.MainData.junglerProximityData || !this.state.junglerProximityReady}
                            >
                                <StyleInfoBoxButtonIcon className={'fad fa-people-arrows'} />
                                {i18n().controller.gamescreen.junglerProximity}
                            </StyleInfoBoxButton>) || null}
            {/* ITEM PURCHASE HISTORY */}
                            {(gameScreenComponents.itemsPurchaseHistory &&
                            <StyleInfoBoxButton
                            controllerSide={!this.props.screen1}
                                active={this.state.infoBoxChannel === 'itemsPurchaseHistory'}
                                onClick={() => { (this.state.status!== 'close') && this.handleInfoBoxChange('itemsPurchaseHistory') }}
                            >
                                <StyleInfoBoxButtonIcon className={'fad fa-shopping-cart'} />
                                {i18n().general.itemsPurchaseHistory}
                            </StyleInfoBoxButton>) || null}
                    </StyleInfoBoxContainer>
                    </div>

                    {(gameScreenComponents.drakeTokens && <div className="control-extra__fieldset">
                        {this.isTokensActive() && <div><StyleIconActive className={'fas fa-exclamation'} /></div>}
                        <DataContainer componentSlug="dataGameDrakeTokens" icon="fas fa-dragon">
                            <div className="data-panel__fieldset" style={{ flexDirection: 'column' }}>
                                <StyleDrakeTokenTable>
                                    <div>Drakes</div>
                                    <div>1st</div>
                                    <div>2nd</div>
                                    <div>3rd</div>
                                    <div>4th/Soul</div>

                                    <div>Team Left</div>
                                    <StyleSelectToken isActive={this.state.dragonTokens.teamLeft[1]}><Picker items={this.tokensItems} onUpdate={(drakeSelected) => { this.handleTokensChange(drakeSelected, 'teamLeft', 1) }} preselect={this.state.dragonTokens.teamLeft[1] || ''} /></StyleSelectToken>
                                    <StyleSelectToken isActive={this.state.dragonTokens.teamLeft[2]}><Picker items={this.tokensItems} onUpdate={(drakeSelected) => { this.handleTokensChange(drakeSelected, 'teamLeft', 2) }} preselect={this.state.dragonTokens.teamLeft[2] || ''} /></StyleSelectToken>
                                    <StyleSelectToken isActive={this.state.dragonTokens.teamLeft[3]}><Picker items={this.tokensItems} onUpdate={(drakeSelected) => { this.handleTokensChange(drakeSelected, 'teamLeft', 3) }} preselect={this.state.dragonTokens.teamLeft[3] || ''} /></StyleSelectToken>
                                    <StyleSelectToken isActive={this.state.dragonTokens.teamLeft[4]}><Picker items={this.tokensItems} onUpdate={(drakeSelected) => { this.handleTokensChange(drakeSelected, 'teamLeft', 4) }} preselect={this.state.dragonTokens.teamLeft[4] || ''} /></StyleSelectToken>

                                    <div>Team Right</div>
                                    <StyleSelectToken isActive={this.state.dragonTokens.teamRight[1]}><Picker items={this.tokensItems} onUpdate={(drakeSelected) => { this.handleTokensChange(drakeSelected, 'teamRight', 1) }} preselect={this.state.dragonTokens.teamRight[1] || ''} /></StyleSelectToken>
                                    <StyleSelectToken isActive={this.state.dragonTokens.teamRight[2]}><Picker items={this.tokensItems} onUpdate={(drakeSelected) => { this.handleTokensChange(drakeSelected, 'teamRight', 2) }} preselect={this.state.dragonTokens.teamRight[2] || ''} /></StyleSelectToken>
                                    <StyleSelectToken isActive={this.state.dragonTokens.teamRight[3]}><Picker items={this.tokensItems} onUpdate={(drakeSelected) => { this.handleTokensChange(drakeSelected, 'teamRight', 3) }} preselect={this.state.dragonTokens.teamRight[3] || ''} /></StyleSelectToken>
                                    <StyleSelectToken isActive={this.state.dragonTokens.teamRight[4]}><Picker items={this.tokensItems} onUpdate={(drakeSelected) => { this.handleTokensChange(drakeSelected, 'teamRight', 4) }} preselect={this.state.dragonTokens.teamRight[4] || ''} /></StyleSelectToken>
                                </StyleDrakeTokenTable>
                                <StyleReset onClick={() => { this.onUpdate('dragonTokens', cloneDeep(initialDragonTokens)) }}>reset</StyleReset>
                            </div>
                        </DataContainer>

                    </div>) || null}

                    {(gameScreenComponents.timers && <div className="control-extra__fieldset">
                        <DataGameTimers
                            controllerSide={!this.props.screen1}
                            onUpdate={(a, b, c) => this.onUpdate(a, b, c)}
                            visibility={this.state.gameScreenAssets}
                            dragonSelected={this.state.dragonSelected}
                            dragonSubtypeSelected={this.state.dragonSubtypeSelected}
                            status={this.state.status}
                            dragonTokens={this.state.dragonTokens}
                            showPopupVideo={this.state.showPopupVideo}
                            runningTimers = {this.runningTimers}
                            filterRunningTimers = {this.filterRunningTimers}
                        />
                    </div>) || null}
                    {(dataComponents.DataInGameTipsSide && <div className="control-extra__fieldset">
                        <DataInGameTips controllerSide={!this.props.screen1} schedule={this.props.schedule} gameScreenStatus={this.state.status} />
                    </div>) || null}
                </div>
            </ControlContainer>
        )
    }
}

export default GameScreenControl

