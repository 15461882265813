import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import CreatableSelect from 'react-select/creatable';

import DataContainer from '../DataContainer';
import Button from '../../Button';
import { telopsInputOptions1 } from '../DataTelopsV2';
import {
  StyleMainContainer,
  StylePredictionsContainer,
  StylePredictionContainer,
  StyleWinnerContainer,
  StyleTeamLogo,
  StyleScorePredictionContainer,
  StyleRemove,
} from './styles';

function DataCastersPredictions(props) {
  const [currentGameState, setCurrentGameState] = useState();

  const [newCasterState, setNewCasterState] = useState('');
  const [newTeamWinnerState, setNewTeamWinnerState] = useState([]);
  const [newScorePredictionState, setNewScorePredictionState] = useState([]);

  const [castersPredictionsState, setCastersPredictionsState] = useState([]);


  useEffect(() => {
    const currentGame = get(props, `schedule.rounds.${props.schedule.round}.matches.${props.schedule.match}.games.${props.schedule.game}`, undefined);
    if(Object.values(currentGame.teams).length !== 2) return; // Handling TBD teams

    if(currentGame){
        let team100 = {},
          team200 = {};
        let team100Name,
          team200Name = "";
        if (Object.values(currentGame.teams)[0].side === "100" && Object.values(currentGame.teams)[1].side === "200") {
          team100Name = Object.keys(currentGame.teams)[0];
          team200Name = Object.keys(currentGame.teams)[1];
        } else {
          team100Name = Object.keys(currentGame.teams)[1];
          team200Name = Object.keys(currentGame.teams)[0];
        }
        team100 = {
          ...get(window, `MainData.teams.${team100Name}`, {}),
          ...currentGame.teams[team100Name],
        };
        team200 = {
          ...get(window, `MainData.teams.${team200Name}`, {}),
          ...currentGame.teams[team200Name],
        };
        const bestOf = get(window, `MainData.schedule.rounds[${window.MainData.schedule.round}].matches[${window.MainData.schedule.match}].bestOf`, 1)

        setCastersPredictionsState([]);
        setCurrentGameState({ team100Name, team100, team200Name, team200, bestOf });
    }
  }, [props.schedule]);

  useEffect(()=>{
    window.MainData.castersPredictions = castersPredictionsState;
    resetNewPredictionFields();
  }, [castersPredictionsState])

  const resetNewPredictionFields = ()=>{
    setNewCasterState('');
    setNewTeamWinnerState([]);
    setNewScorePredictionState([]);
  }

  const savePrediction = () => {
    let newPrediction = {
      casterName: newCasterState,
      teamWinner: newTeamWinnerState[0],
      teamWinnerLogo: newTeamWinnerState[1],
      scorePrediction: newScorePredictionState.join(' - '),
    }
      setCastersPredictionsState(currentPredictions => {
       let newPredictions = [...currentPredictions];
       newPredictions.push(newPrediction);
        return newPredictions;
      });
  }

  const removePrediction = indexPredictionToRemove => {
    setCastersPredictionsState(currentPrediction => {
      return currentPrediction.filter((prediction, index)=>index !== indexPredictionToRemove);
    })
  }

  const isPredictionValid = () => {
    return (
      newCasterState &&
      newTeamWinnerState[0] &&  //  team tricode
      newTeamWinnerState[1] &&  //  img team logo
      (get(currentGameState, 'bestOf') === 1 || (newScorePredictionState[0] !== undefined &&
        newScorePredictionState[1] !== undefined))
     );
  }

  const getListOfRemainingCaster = (castersList, casterPredictions) => {
    return castersList.filter(
      caster => !casterPredictions.map(caster => caster.casterName).includes(caster.value));
  }

  return (
      <DataContainer
        componentSlug="castersPrediction"
        icon="far fa-users-class"
        style={{ overflow: "visible" }}
      >
        <div
          className="data-panel__fieldset"
          style={{ paddingTop: "10px", paddingBottom: "0" }}
        >
        <StyleMainContainer>
          <StylePredictionsContainer>
            {castersPredictionsState.map((prediction, index) =>
              <StylePredictionContainer key={index}>
                <h4>Caster:</h4>
                <p>{prediction.casterName}</p>
                <h4>Winner:</h4>
                <p>{prediction.teamWinner}</p>
                <StyleTeamLogo
                  img={prediction.teamWinnerLogo}
                  isWinner
                />
                {(get(currentGameState, 'bestOf') !== 1) && <h4>Score:</h4>}
                {(get(currentGameState, 'bestOf') !== 1) && <p>{prediction.scorePrediction}</p>}
                <StyleRemove
                  title={'Remove prediction'}
                  onClick={()=>{removePrediction(index)}}
                >
                  <i className="fas fa-times"></i>
                </StyleRemove>
              </StylePredictionContainer>
            )}

            {/* New Prwediction Form */}
            <StylePredictionContainer>
              <h4>Caster:</h4>
              <CreatableSelect
                options={getListOfRemainingCaster(telopsInputOptions1, castersPredictionsState)}
                value={{value: newCasterState, label: newCasterState}}
                onChange={(e)=>{setNewCasterState(e.value)}}
              />
              <h4>Winner:</h4>
              <StyleWinnerContainer>
                <StyleTeamLogo
                  img={get(currentGameState, 'team100.images.logoBGFull', '')}
                  isWinner={newTeamWinnerState[0] === get(currentGameState, 'team100Name','')}
                  onClick={()=>{
                    setNewTeamWinnerState([
                      get(currentGameState, 'team100Name',''),
                      get(currentGameState, 'team100.images.logoBGFull', ''),
                    ]);
                  }}
                />
                <StyleTeamLogo
                  img={get(currentGameState, 'team200.images.logoBGFull', '')}
                  isWinner={newTeamWinnerState[0] === get(currentGameState, 'team200Name','')}
                  onClick={()=>{
                    setNewTeamWinnerState([
                      get(currentGameState, 'team200Name',''),
                      get(currentGameState, 'team200.images.logoBGFull', ''),
                    ]);
                  }}
                />
              </StyleWinnerContainer>
                {(get(currentGameState, 'bestOf') !== 1) &&<h4>Score:</h4>}
                {(get(currentGameState, 'bestOf') !== 1) && <StyleScorePredictionContainer>
                  <span>{get(currentGameState, 'team100Name','')}</span>
                  <span>{' '}</span>
                  <span>{get(currentGameState, 'team200Name','')}</span>
                <input
                  type={'text'}
                  onChange={(e)=>{
                    e.persist();
                    setNewScorePredictionState(currentPrediction => {
                      const newPredictionScore = [...currentPrediction]
                      newPredictionScore[0] = e.target.value;
                      return newPredictionScore;
                    })
                  }}
                  value={newScorePredictionState[0] || ''}
                />
                <span>{' - '}</span>
                <input
                  type={'text'}
                  onChange={(e)=>{
                    e.persist();
                    setNewScorePredictionState(currentPrediction => {
                      const newPredictionScore = [...currentPrediction]
                      newPredictionScore[1] = e.target.value;
                      return newPredictionScore;
                    }
                  )}}
                  value={newScorePredictionState[1] || ''}
                />
              </StyleScorePredictionContainer>}
              <Button action={()=>{savePrediction()}} text={'Add Prediction'} disabled={!isPredictionValid()} />
            </StylePredictionContainer>

          </StylePredictionsContainer>
        </StyleMainContainer>

       </div>

        </DataContainer>
  )
}

DataCastersPredictions.propTypes = {
  schedule: PropTypes.object,
}

export default DataCastersPredictions

