// REACT
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {loadImageUrlChampSquare} from '../../../../util/loadImageUrl';

import {
  StyleDamageRow,
  StylePlayerImage,
  StylePlayerInfo,
  StylePlayerName,
  StylePlayerScore,
  StylePlayerScoreBar,
} from './styles';

class DamageItem extends Component {

  static propTypes = {
    mode: PropTypes.string,
    player: PropTypes.string,
    champ: PropTypes.string,
    isRed: PropTypes.bool,
    score: PropTypes.number,
    maxVal: PropTypes.number,
    color: PropTypes.string,
    textColor: PropTypes.string,
    rightSide: PropTypes.bool || false,
  };

  getPercent = () => {
    return (this.props.score / this.props.maxVal).toFixed(2);
  }

  getWidth = () => {
    return `scaleX(${this.getPercent()})`;
  }

  render() {
    const champStyle = this.props.champ ? {backgroundImage: 'url(' + loadImageUrlChampSquare(this.props.champ) + ')'} : {};
    return (
      this.props.champ ?
        this.props.mode === "new" ?
        <StyleDamageRow right={this.props.rightSide}>
          <StylePlayerImage right={this.props.rightSide} src={this.props.champ && loadImageUrlChampSquare(this.props.champ)} />
          <StylePlayerInfo right={this.props.rightSide}>
            <StylePlayerName>{this.props.player}</StylePlayerName>
            <StylePlayerScore>{((this.props.score)/1000).toFixed(1) + "K"}</StylePlayerScore>
            <StylePlayerScoreBar color={this.props.color} width={this.getWidth()} right={this.props.rightSide}></StylePlayerScoreBar>
          </StylePlayerInfo>
        </StyleDamageRow>
        :
        <div className={`game-screen__info-box__body__item__damage ${this.props.isRed ? 'game-screen__info-box__body__item__damage--red' : ''}`}>
          <div className="game-screen__info-box__body__item__damage__hero banned-hero" style={champStyle}></div>
            <div
              style={{color: this.props.textColor || '#141720', maxWidth: '80px'}}
              className="game-screen__info-box__body__item__damage__damage-count"
            >{this.props.score.toLocaleString()}</div>
          <div className="game-screen__info-box__body__item__damage__bar">
            <div
              className={`game-screen__info-box__body__item__damage__bar__scale ${this.props.isRed ? 'game-screen__info-box__body__item__damage__bar__scale--red' : ''}`}
              style={{
                transform: this.getWidth(),
                backgroundColor: this.props.color || (this.props.isRed ? '#dd4142' :'#4883ab'),
              }}
            />
          </div>
        </div>
      :
      <div></div>
    )
  }
};

export default DamageItem
