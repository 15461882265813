"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StyleVideoContainer = exports.StyleSliderBackground = exports.StyleRightVideo = exports.StyleRightImage = exports.StyleLeftVideo = exports.StyleLeftImage = void 0;

var _styledComponents = _interopRequireDefault(require("styled-components"));

var _reactSpring = require("react-spring");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var StyleSliderBackground = _styledComponents.default.div.withConfig({
  displayName: "styles__StyleSliderBackground",
  componentId: "sc-14211i3-0"
})(["display:flex;"]);

exports.StyleSliderBackground = StyleSliderBackground;

var StyleVideo = _styledComponents.default.video.withConfig({
  displayName: "styles__StyleVideo",
  componentId: "sc-14211i3-1"
})(["width:100%;height:1080px;object-fit:cover;"]);

var StyleVideoContainer = (0, _styledComponents.default)(_reactSpring.animated.div).withConfig({
  displayName: "styles__StyleVideoContainer",
  componentId: "sc-14211i3-2"
})(["width:50%;overflow:hidden;"]);
exports.StyleVideoContainer = StyleVideoContainer;
var StyleLeftVideo = (0, _styledComponents.default)(StyleVideo).withConfig({
  displayName: "styles__StyleLeftVideo",
  componentId: "sc-14211i3-3"
})(["object-position:right;"]);
exports.StyleLeftVideo = StyleLeftVideo;
var StyleRightVideo = (0, _styledComponents.default)(StyleVideo).withConfig({
  displayName: "styles__StyleRightVideo",
  componentId: "sc-14211i3-4"
})(["object-position:left;"]);
exports.StyleRightVideo = StyleRightVideo;

var StyleImage = _styledComponents.default.img.withConfig({
  displayName: "styles__StyleImage",
  componentId: "sc-14211i3-5"
})(["width:100%;height:1080px;object-fit:cover;"]);

var StyleLeftImage = (0, _styledComponents.default)(StyleImage).withConfig({
  displayName: "styles__StyleLeftImage",
  componentId: "sc-14211i3-6"
})(["object-position:right;"]);
exports.StyleLeftImage = StyleLeftImage;
var StyleRightImage = (0, _styledComponents.default)(StyleImage).withConfig({
  displayName: "styles__StyleRightImage",
  componentId: "sc-14211i3-7"
})(["object-position:left;"]);
exports.StyleRightImage = StyleRightImage;