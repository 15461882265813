export const createIntegerRangeSet = (intGroups = []) => {
    return {
        includes: (intValue) => {
            for (let i = 0; i < intGroups.length; i++) {
                if (intGroups[i][0] <= intValue && intGroups[i][1] >= intValue) {
                    return true;
                }
            }
        },
        add: (intValue) => {
            for (let i = 0; i < intGroups.length; i++) {
                if (intGroups[i][0] === intValue + 1) {
                    intGroups[i][0] = intValue;
                    return;
                }
                if (intGroups[i][1] === intValue - 1) {
                    intGroups[i][1] = intValue;
                    return;
                }
            }
            intGroups.push([intValue, intValue]);
        },
        getIntGroups: () => intGroups,
    };
};
