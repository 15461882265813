import styled from 'styled-components';

export const StyleDamageRow = styled.div`
    display: grid;
    grid-template-columns: ${props => props.right ? '1fr 65px' : '65px 1fr'};
    grid-column-gap: 20px;
`;

export const StylePlayerImage = styled.img`
    width: 65px;
    height: 65px;
    order: ${props => props.right ? '3' : '0'};
`;

export const StylePlayerInfo = styled.div`
    text-align: ${props => props.right ? 'right' : 'left'};
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;

export const StylePlayerName = styled.div`
    text-transform: uppercase;
    font-size: 17px;
    font-family: aktiv-grotesk-ex-medium;
    font-weight: 500;
    color: #F5F5F5;
    margin-top: -2px;
`;

export const StylePlayerScore = styled.div`
    font-size: 22px;
    font-family: aktiv-grotesk-ex-bold;
    font-weight: 700;
    color: #F5F5F5;
    margin-top: -5px;
`;

export const StylePlayerScoreBar = styled.div`
    transform-origin: ${props => props.right ? 'right center' : 'left center'};
    background-color: ${props => props.color ? props.color : 'transparent'};
    transform: ${props => props.width};
    height: 17px;
`;