// REACT
import React, { Component } from 'react';
import PropTypes from 'prop-types';
// 3RD PARTY
import { delay } from 'lodash';

class InfoBoxFanTweets extends Component {

    static propTypes = {
        tweets: PropTypes.array,
    };

    state = {
        tweetIndex: 0,
        visible: true,
    };

    componentDidMount() {
        delay(() => this.cycleTweets(), 5000);
    }

    cycleTweets = () => {
        let tweetIndex = this.state.tweetIndex + 1;
        if (tweetIndex >= this.props.tweets.length) {
            tweetIndex = 0;
        } else {
            this.setState({ visible: false });
            delay(() => {
                this.setState({ tweetIndex, visible: true });
            }, 200);
            delay(() => this.cycleTweets(), 5000);
        }
    }

    render() {
        return (
            <div>
                <div className="game-screen__info-box__title">
                    <i className="fab fa-twitter" aria-hidden="true"></i>TWEETS FROM FANS
        </div>
                <div className="game-screen__info-box__body game-screen__info-box__body--padded game-screen__info-box__body--block">
                    <div className={`animated animated--fast game-screen__info-box__body__scroll-cont ${this.state.visible ? 'game-screen__info-box__body__scroll-cont--visible' : ''}`}>
                        <div className="game-screen__info-box__body__tweet-name">@{this.props.tweets.length ? this.props.tweets[this.state.tweetIndex].screen_name : ''}</div>
                        <p className="game-screen__info-box__body__tweet-content">
                            {this.props.tweets.length ? this.props.tweets[this.state.tweetIndex].text : ''}
                        </p>
                    </div>
                </div>
            </div>
        );
    }

}

export default InfoBoxFanTweets