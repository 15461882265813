// REACT
import React, { Component } from 'react';
// INTERNAL
// import { send } from '../../../services/api';
import ControlContainer from '../../Controller/ControlContainer';
import Button from '../../Button';
import { firebaseRealtimeDbSetValue } from '../../../util/firebase';
import { subscribe } from '../../../services/api';
import PropTypes from 'prop-types';

class ViewCleanerControl extends Component {

    state = {
        status: '',
    }
    static propTypes = {
        cgpc: PropTypes.string,
        display: PropTypes.bool,
        screen1: PropTypes.bool,
      };

    componentDidMount() {
        this.runSubscription();
    }

    runSubscription = () => {
        subscribe('view', null, data => {
            if (data.target === 'view') {
                this.setState({status: data.displayLogo ? 'showing' : 'hidding'})
            }
        }, this.props.cgpc);
    };

    onShow = () => {
        firebaseRealtimeDbSetValue('view/displayLogo', true);
    }

    onClean = () => {
        firebaseRealtimeDbSetValue('view/displayLogo', false);
    }

    render() {
        return (
            <ControlContainer style={{display: this.props.display ? 'none': 'block'}} className="control--starters" status={this.state.status}>
                <div className="control__panel">
                    <h2 className="control__title">
                        <i className="fas fa-eye"></i> {'CLEAN LOGO'}
                    </h2>
                    <Button
                    controllerSide={!this.props.screen1}
                        action={this.onShow}
                        text={'Show'}
                        icon="fas fa-play-circle"
                        disabled={this.state.status === 'showing'}
                    />
                    <Button
                    controllerSide={!this.props.screen1}
                        action={this.onClean}
                        text={'Clean'}
                        icon="fas fa-stop-circle"
                        disabled={this.state.status === 'hidding'}
                    />
                </div>
            </ControlContainer>
        )
    }
}

export default ViewCleanerControl
