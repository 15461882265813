/* eslint-disable react/no-unused-prop-types */
// REACT
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
// ME
import InfoBoxFanTweets from './FanTweets';
import InfoBoxWhoWillWin from './WhoWillWin';
import PlayersRunes from '../../PlayersRunes';
import VisionControl from '../../../storybook-built/dist/components/Games/LJL/VisionControl';
import InfoBoxGoldDifference from './GoldDifference';
import InfoBoxGoldPerPlayer from './GoldPerPlayer';
import InfoBoxDamageDealt from './DamageDealt';
import InfoBoxTurretPlate from '../../../storybook-built/dist/components/Games/LJL/GameScreen/InfoBox/TurretsPlate';
import InfoBoxPlayerStatsSingle from '../../../storybook-built/dist/components/Games/LJL/GameScreen/InfoBox/PlayerStatsSingle';
import InfoBoxPlayerStatsDual from '../../../storybook-built/dist/components/Games/LJL/GameScreen/InfoBox/PlayerStatsDual';
import WhoWillWinAI from '../../../storybook-built/dist/components/Games/LJL/GameScreen/InfoBox/WhoWillWin-AI';
import WhoWillWinAIGraph from '../../../storybook-built/dist/components/Games/LJL/GameScreen/InfoBox/WhoWillWin-AI_Graph';
import InfoBoxItemsPurchaseHistory from '../../../storybook-built/dist/components/Games/LJL/GameScreen/InfoBox/ItemsPurchaseHistory';
import InGameInfobox from '../../../storybook-built/dist/components/Games/LJL/GameScreen/InfoBox/InGameInfobox';
import PlayersMythicItem from '../../../storybook-built/dist/components/Games/LJL/GameScreen/InfoBox/PlayersMythicItem';

import { StyleHeaderTitle, StyleVisionControlContent, StyleInfoBoxBody } from './styles'
import championsDict from '../../../services/championService';
class InfoBox extends Component {

    static propTypes = {
        theme: PropTypes.any,
        season: PropTypes.string,
        split: PropTypes.string,
        channel: PropTypes.string,
        tweets: PropTypes.array,
        votes: PropTypes.object,
        playersData: PropTypes.object,
        visible: PropTypes.bool,
        team100Logo: PropTypes.string,
        team200Logo: PropTypes.string,
        team100Tricode: PropTypes.string,
        team200Tricode: PropTypes.string,
        team100Color: PropTypes.string,
        team100ColorSub: PropTypes.string,
        team200Color: PropTypes.string,
        team200ColorSub: PropTypes.string,
        gameTeam200BGLogo: PropTypes.string,
        gameTeam100BGLogo: PropTypes.string,
        playersRunes: PropTypes.object,
        currentMatchTeams: PropTypes.object,
        visionControl: PropTypes.object,
        goldDifferenceGraph: PropTypes.object,
        goldPerPlayers: PropTypes.object,
        damageDealtToChampionsPerPlayers: PropTypes.object,
        // damageDealtToTurretsPerPlayers: PropTypes.object,
        // totalGoldEarnedFromTurretPlateDestructionPerPlayer: PropTypes.object,
        turretsStatus: PropTypes.object,
        whoWillWinAI: PropTypes.object,
        junglerProximity: PropTypes.object,
        itemsPurchaseHistory: PropTypes.object,
        mythicItemInfobox: PropTypes.object,
        inGameTipInfobox: PropTypes.object,
        whoWillWinAIGraph:PropTypes.array,
        whoWillWinAIGraphEvents:PropTypes.object,
        whoWillWinAIGraphBlitzcrank:PropTypes.string,
        teamsData: PropTypes.object,
    };

    state = {
        // mainData: window.MainData,
        showInfoBoxContainer: false,
    };

    UNSAFE_componentWillReceiveProps(nextProps){
        if(!nextProps.visible && this.props.visible){
            this.setState({showInfoBoxContainer: false});
        }
        else if(nextProps.channel !== 'playerStats' && nextProps.visible) {
            this.setState({showInfoBoxContainer: true});
        } else if (nextProps.visible) {
            // Shaun, I dunno but this works more then the one above.
            this.setState({showInfoBoxContainer: true});
        }
    }

    componentDidMount() {
        // console.log("InfoBox", this.props);
    }

    render() {
        return (
            <div className={`animated animated--fast game-screen__info-box ${this.state.showInfoBoxContainer ? 'game-screen__info-box--open' : ''}`}>
                {
                    this.props.channel === 'fantwitter' &&
                    this.props.visible &&
                    <InfoBoxFanTweets tweets={this.props.tweets} />
                }
                {
                    this.props.channel === 'whowillwin' &&
                    this.props.visible &&
                    <InfoBoxWhoWillWin
                        theme={this.props.theme.InfoBoxWhoWillWin}
                        team100Tricode={this.props.team100Tricode}
                        team200Tricode={this.props.team200Tricode}
                        team100Color={this.props.team100Color}
                        team100ColorSub={this.props.team100ColorSub}
                        team200Color={this.props.team200Color}
                        team200ColorSub={this.props.team200ColorSub}
                        logos={{
                            team100: this.props.team100Logo,
                            team200: this.props.team200Logo,
                        }}
                        votes={this.props.votes}
                    />
                }
                {
                    this.props.channel === 'playerStats' && (get(this, 'props.playersData.mode',0) === 1) &&
                    this.props.visible &&
                    <InfoBoxPlayerStatsSingle
                        theme={this.props.theme.InfoBoxPlayerStatsSingle}
                        data={this.props.playersData || null}
                        season={this.props.season}
                        split={this.props.split}
                        onAssetsLoaded={()=>{ this.setState({showInfoBoxContainer : true}) }}
                    />
                }
                {
                    this.props.channel === 'playerStats' && (get(this, 'props.playersData.mode', 0) === 2) &&
                    this.props.visible &&
                    <InfoBoxPlayerStatsDual
                        theme={this.props.theme.InfoBoxPlayerStatsDual}
                        season={this.props.season}
                        split={this.props.split}
                        data={this.props.playersData || null}
                        onAssetsLoaded={()=>{ this.setState({showInfoBoxContainer : true}) }}
                    />
                }
                {
                    this.props.channel === 'playersRunes' &&
                    this.props.visible &&
                    <div>
                        <div className="game-screen__info-box__title">
                            <StyleHeaderTitle StyleHeaderTitle={this.props.theme.PlayersRunes.StyleInfoBoxTitle}>
                                {`PLAYER RUNES`}
                            </StyleHeaderTitle>
                    </div>
                <StyleInfoBoxBody>

                <PlayersRunes
                    theme={this.props.theme.PlayersRunes}
                    team100={get(this.props.playersRunes, this.props.team100Tricode)}
                    team200={get(this.props.playersRunes, this.props.team200Tricode)}
                    team100Roster={get(this.props.currentMatchTeams, `${this.props.team100Tricode}.roster`)}
                    team200Roster={get(this.props.currentMatchTeams, `${this.props.team200Tricode}.roster`)}
                />
                </StyleInfoBoxBody>
                    </div>

                }
                {
                    this.props.channel === 'visionControl' &&
                    this.props.visible &&
                    <div>
                        <div className="game-screen__info-box__title">
                            <StyleHeaderTitle>
                                JUNGLE VISION CONTROL
                            </StyleHeaderTitle>
                        </div>
                        <StyleInfoBoxBody>
                            <StyleVisionControlContent>
                                <VisionControl
                                    team100={{
                                        teamBackground: this.props.gameTeam100BGLogo,
                                        percentage: this.props.visionControl.team100,
                                        color: {
                                            sub: this.props.team100ColorSub,
                                            main: this.props.team100Color,
                                        },
                                    }}
                                    team200={{
                                        teamBackground: this.props.gameTeam200BGLogo,
                                        percentage: this.props.visionControl.team200,
                                        color: {
                                            sub: this.props.team200ColorSub,
                                            main: this.props.team200Color,
                                        },
                                    }}
                                />
                            </StyleVisionControlContent>

                        </StyleInfoBoxBody>
                    </div>
                }
                {
                    this.props.channel === 'goldDifference' &&
                    this.props.visible &&
                    <InfoBoxGoldDifference
                    theme={this.props.theme.InfoBoxGoldDifference}
                        team100Tricode={this.props.team100Tricode}
                        team200Tricode={this.props.team200Tricode}
                        data={this.props.goldDifferenceGraph}
                    />
                }
                {
                    this.props.channel === 'goldPerPlayer' &&
                    this.props.visible &&
                    <InfoBoxGoldPerPlayer
                    theme={this.props.theme.InfoBoxGoldPerPlayer}
                        team100Tricode={this.props.team100Tricode}
                        team200Tricode={this.props.team200Tricode}
                        team100Roster={get(this.props.currentMatchTeams, `${this.props.team100Tricode}.roster`)}
                        team200Roster={get(this.props.currentMatchTeams, `${this.props.team200Tricode}.roster`)}
                        data={this.props.goldPerPlayers}
                    />
                }
                {
                    this.props.channel === 'damageDealt' &&
                    this.props.visible &&
                    <InfoBoxDamageDealt
                        team100Tricode={this.props.team100Tricode}
                        team200Tricode={this.props.team200Tricode}
                        team100Roster={get(this.props.currentMatchTeams, `${this.props.team100Tricode}.roster`)}
                        team200Roster={get(this.props.currentMatchTeams, `${this.props.team200Tricode}.roster`)}
                        data={this.props.damageDealtToChampionsPerPlayers}
                        theme={this.props.theme.InfoBoxDamageDealt}

                    />
                }
                {
                    this.props.channel === 'turretPlate' &&
                    this.props.visible &&
                    <div>
                            <InfoBoxTurretPlate
                                theme={this.props.theme.InfoBoxTurretPlate}
                                show={this.props.channel === 'turretPlate'}
                                team100Tricode={this.props.team100Tricode}
                                team200Tricode={this.props.team200Tricode}
                                team100Roster={get(this.props.currentMatchTeams, `${this.props.team100Tricode}.roster`)}
                                team200Roster={get(this.props.currentMatchTeams, `${this.props.team200Tricode}.roster`)}
                                dataTurretsStatus={this.props.turretsStatus}
                                // dataDamageDealtToTurrets={this.props.damageDealtToTurretsPerPlayers}
                                // totalGoldEarnedFromTurretPlateDestructionPerPlayer={this.props.totalGoldEarnedFromTurretPlateDestructionPerPlayer}
                                championsDict={championsDict}
                            />
                    </div>
                }
                {
                    this.props.channel === 'whoWillWinAI' &&
                    this.props.visible &&
                    <div>
                        <WhoWillWinAI
                            data={{
                                team100: {
                                    roster: get(this.props.currentMatchTeams, `${this.props.team100Tricode}.roster`),
                                    score: get(this, 'props.whoWillWinAI.team100', 50),
                                    logo: this.props.team100Logo,
                                },
                                team200: {
                                    roster: get(this.props.currentMatchTeams, `${this.props.team200Tricode}.roster`),
                                    score: get(this, 'props.whoWillWinAI.team200', 50),
                                    logo: this.props.team200Logo,
                                },
                                gameTime: get(this, 'props.whoWillWinAI.gameTime'),
                            }}
                            championsDict={championsDict}
                            show={(this.props.channel === 'whoWillWinAI') && this.props.visible}
                            theme={this.props.theme.WhoWillWinAI}
                        />
                    </div>
                }
                {
                    this.props.channel === 'junglerProximity' &&
                    this.props.visible &&
                    <div>
                        <InfoBoxPlayerStatsDual
                            theme={this.props.theme.InfoBoxPlayerStatsDual}
                            title={'JUNGLER PROXIMITY@15'}
                            data={this.props.junglerProximity}
                            onAssetsLoaded={()=>{ this.setState({showInfoBoxContainer : true}) }}
                        />
                    </div>
                }
                {
                    this.props.channel === 'itemsPurchaseHistory' &&
                    this.props.visible &&
                    <div>
                        <InfoBoxItemsPurchaseHistory
                            theme={this.props.theme.InfoBoxItemsPurchaseHistory}
                            data={get(this.props, 'itemsPurchaseHistory.data',[])}
                            playerName={get(this.props, 'itemsPurchaseHistory.playerName','')}
                            championId={get(this.props, 'itemsPurchaseHistory.championId')}
                            championIcon={get(this.props, 'itemsPurchaseHistory.championIcon','')}
                        />
                    </div>
                }
                {
                    this.props.channel === 'whoWillWinAIGraph' &&
                    this.props.visible &&
                    <div>
                        <WhoWillWinAIGraph
                            theme={this.props.theme.WhoWillWinAIGraph}
                            show={(this.props.channel === 'whoWillWinAIGraph') && this.state.showInfoBoxContainer}
                            data={{
                                team100: {
                                    tricode: this.props.team100Tricode,
                                    logo: this.props.team100Logo,
                                },
                                team200: {
                                    tricode: this.props.team200Tricode,
                                    logo: this.props.team200Logo,
                                },
                                eventsHistory: get(this.props, 'whoWillWinAIGraphEvents',[]),
                                predictions: get(this.props, 'whoWillWinAIGraph',[]),
                            }}
                            onAssetsLoaded={()=>{
                                console.log('gameFlowPrediction assets loaded')
                                this.setState({showInfoBoxContainer : true});
                            }}
                            blitzcrankAsset={get(this, 'props.whoWillWinAIGraphBlitzcrank')}
                        />
                    </div>
                }

                {
                    this.props.channel === 'mythicItemInfobox' &&
                    this.props.visible &&
                    <div>
                       <InGameInfobox
                            data={{
                                ...get(this, 'props.mythicItemInfobox', {}),
                            }}
                       />
                    </div>
                }
                {
                    this.props.channel === 'inGameTipInfobox' &&
                    this.props.visible &&
                    <div>
                        <InGameInfobox
                            data={{
                                ...get(this, 'props.inGameTipInfobox', {}),
                            }}
                        />
                    </div>
                }
                {
                    this.props.channel === 'playersMythicItem' &&
                    this.props.visible &&
                    <div>
                        <PlayersMythicItem
                            theme={this.props.theme.PlayersMythicItem}
                            data={{
                                playersMythicItem: get(this, 'props.playersMythicItem', {}),
                                team100Roster: get(this.props.currentMatchTeams, `${this.props.team100Tricode}.roster`),
                                team200Roster: get(this.props.currentMatchTeams, `${this.props.team200Tricode}.roster`),
                            }}
                            championsDict={championsDict}
                        />
                    </div>
                }
            {/* GAME FLOW / FUTURE PREDICTIONS */}
                  {
                    this.props.channel === 'gameFlowPrediction' &&
                    this.props.visible &&
                    <div>
                        <WhoWillWinAIGraph
                            theme={this.props.theme.WhoWillWinAIGraph}
                            show={(this.props.channel === 'gameFlowPrediction') && this.props.visible}
                            data={{
                                team100: {
                                    tricode: this.props.team100Tricode,
                                    logo: this.props.team100Logo,
                                },
                                team200: {
                                    tricode: this.props.team200Tricode,
                                    logo: this.props.team200Logo,
                                },
                                predictions: get(this, 'props.gameFlowPrediction',[]),
                            }}
                            title={'AI : POWER SPIKE'}
                            mode={'gameFlowPrediction'}
                        />
                    </div>
                }
            {/*  */}
            {
                this.props.channel === 'teamStats' && (get(this, 'props.teamsData.mode') === 'dual') &&
                this.props.visible &&
                <InfoBoxPlayerStatsDual
                    theme={this.props.theme.InfoBoxTeamStatsDual}
                    data={this.props.teamsData || null}
                    season={this.props.season}
                    split={this.props.split}
                    onAssetsLoaded={()=>{ this.setState({showInfoBoxContainer : true}) }}
                    mode={'teams'}
                />
            }
            {
                this.props.channel === 'teamStats' && (get(this, 'props.teamsData.mode') === 'single') &&
                this.props.visible &&
                <InfoBoxPlayerStatsSingle
                    theme={this.props.theme.InfoBoxTeamStatsSingle}
                    data={this.props.teamsData || null}
                    season={this.props.season}
                    split={this.props.split}
                    onAssetsLoaded={()=>{ this.setState({showInfoBoxContainer : true}) }}
                    mode={'teams'}
                />
            }
            </div>
        );
    }

}

export default InfoBox
