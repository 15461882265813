import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';

import { ThemeContext } from '../View/themes';
import BanPickV2Component from '../../storybook-built/dist/components/Games/LJL/BanPickV2';
import championsDict from '../../services/championService';



const BanPickV2 = props => {
  const theme = useContext(ThemeContext)
  const banPickSponsors = []
  const allSponsors = get(props.data, 'sponsors', [])
  for (let index = 0; index < allSponsors.length; index++) {
    const element = allSponsors[index];
    // these removable's should be moved into the theme file if it becomes a problem
    if (element.includes('01riot') || element.includes('01au')) {
      // remove them from the banPickSponsors
    } else {
      banPickSponsors.push(element)
    }
  }
  return (
    <div className={'container'}>
      <BanPickV2Component
        matchType={get(props.data, 'matchType', 1)}
        gameTitle={parseInt(get(props.data, 'gameTitle', 0)) + 1}
        weekTitle={get(props.data, 'weekTitle', '')}
        data={get(props.data, 'banPickV2', {})}
        teams={get(props.data, 'teams', {})}
        show={get(props.data, 'banPickV2Visible', false)}
        ljlLogo={get(props.data, 'ljlLogo', '')}
        patch={get(props.data, 'patch', '')}
        score={get(props.data, 'score', "0 - 0")}
        theme={theme.screens.banPickV2 || {}}
        championsStats={get(props.data, 'championsStats')}
        championsDict={championsDict}
        // sponsors={banPickSponsors}
        // sponsors={['https://cdn.dekki.com/uploads/eventSponsors/MouseComputer.png']}
      />
    </div>
  );
};

BanPickV2.propTypes = {
  data: PropTypes.object,
  // active: PropTypes.bool,
};

export default BanPickV2;
