import { teams, rounds, matches } from '../util/const';

const schedule = {
  [rounds.round1]: {
    [matches.match1]: {
      team100: teams.pgm,
      team200: teams.v3,
      datetime: new Date('2018-02-09T20:00'),
    },
    [matches.match2]: {
      team100: teams.usg,
      team200: teams.rj,
      datetime: new Date('2018-02-10T17:00'),
    },
    [matches.match3]: {
      team100: teams.dfm,
      team200: teams['7h'],
      datetime: new Date('2018-02-10T20:00'),
    },
  },
  [rounds.round2]: {
    [matches.match1]: {
      team100: teams.v3,
      team200: teams.rj,
      datetime: new Date('2018-02-16T20:00'),
    },
    [matches.match2]: {
      team100: teams['7h'],
      team200: teams.usg,
      datetime: new Date('2018-02-17T17:00'),
    },
    [matches.match3]: {
      team100: teams.pgm,
      team200: teams.dfm,
      datetime: new Date('2018-02-17T20:00'),
    },
  },
  [rounds.round3]: {
    [matches.match1]: {
      team100: teams['7h'],
      team200: teams.pgm,
      datetime: new Date('2018-02-23T20:00'),
    },
    [matches.match2]: {
      team100: teams.usg,
      team200: teams.v3,
      datetime: new Date('2018-02-24T17:00'),
    },
    [matches.match3]: {
      team100: teams.rj,
      team200: teams.dfm,
      datetime: new Date('2018-02-24T20:00'),
    },
  },
  // SUPER WEEK 1 Day 1
  [rounds.round4]: {
    [matches.match1]: {
      team100: teams.dfm,
      team200: teams.v3,
      datetime: new Date('2018-03-02T14:00'),
    },
    [matches.match2]: {
      team100: teams.pgm,
      team200: teams.usg,
      datetime: new Date('2018-03-02T17:00'),
    },
    [matches.match3]: {
      team100: teams['7h'],
      team200: teams.rj,
      datetime: new Date('2018-03-02T20:00'),
    },
  },
  // SUPER WEEK 1 Day 2
  [rounds.round5]: {
    [matches.match1]: {
      team100: teams.v3,
      team200: teams['7h'],
      datetime: new Date('2018-03-03T14:00'),
    },
    [matches.match2]: {
      team100: teams.rj,
      team200: teams.pgm,
      datetime: new Date('2018-03-03T17:00'),
    },
    [matches.match3]: {
      team100: teams.usg,
      team200: teams.dfm,
      datetime: new Date('2018-03-03T20:00'),
    },
  },
  [rounds.round6]: {
    [matches.match1]: {
      team100: teams.rj,
      team200: teams.usg,
      datetime: new Date('2018-03-09T20:00'),
    },
    [matches.match2]: {
      team100: teams['7h'],
      team200: teams.dfm,
      datetime: new Date('2018-03-10T17:00'),
    },
    [matches.match3]: {
      team100: teams.v3,
      team200: teams.pgm,
      datetime: new Date('2018-03-10T20:00'),
    },
  },
  // SUPER WEEK 2 Day 1
  [rounds.round7]: {
    [matches.match1]: {
      team100: teams.usg,
      team200: teams['7h'],
      datetime: new Date('2018-03-16T14:00'),
    },
    [matches.match2]: {
      team100: teams.rj,
      team200: teams.v3,
      datetime: new Date('2018-03-16T17:00'),
    },
    [matches.match3]: {
      team100: teams.dfm,
      team200: teams.pgm,
      datetime: new Date('2018-03-16T20:00'),
    },
  },
  // SUPER WEEK 2 Day 2
  [rounds.round8]: {
    [matches.match1]: {
      team100: teams.dfm,
      team200: teams.rj,
      datetime: new Date('2018-03-17T14:00'),
    },
    [matches.match2]: {
      team100: teams.pgm,
      team200: teams['7h'],
      datetime: new Date('2018-03-17T17:00'),
    },
    [matches.match3]: {
      team100: teams.v3,
      team200: teams.usg,
      datetime: new Date('2018-03-17T20:00'),
    },
  },
  [rounds.round9]: {
    [matches.match1]: {
      team100: teams.v3,
      team200: teams.dfm,
      datetime: new Date('2018-03-23T20:00'),
    },
    [matches.match2]: {
      team100: teams.usg,
      team200: teams.pgm,
      datetime: new Date('2018-03-24T17:00'),
    },
    [matches.match3]: {
      team100: teams.rj,
      team200: teams['7h'],
      datetime: new Date('2018-03-24T20:00'),
    },
  },
  [rounds.round10]: {
    [matches.match1]: {
      team100: teams.pgm,
      team200: teams.rj,
      datetime: new Date('2018-03-30T20:00'),
    },
    [matches.match2]: {
      team100: teams.dfm,
      team200: teams.usg,
      datetime: new Date('2018-03-31T17:00'),
    },
    [matches.match3]: {
      team100: teams['7h'],
      team200: teams.v3,
      datetime: new Date('2018-03-31T20:00'),
    },
  },
  [rounds.tiebreak]: {
    [matches.match1]: {
      team100: teams.pgm,
      team200: teams.usg,
      datetime: new Date('2018-04-01T17:00'),
    },
    [matches.match2]: {
      team100: teams.v3,
      team200: teams.rj,
      datetime: new Date('2018-04-01T20:00'),
    },
  },
  [rounds.promotion]: {
    [matches.match1]: {
      team100: teams['7h'],
      team200: teams.cga,
      datetime: new Date('2018-04-03T17:00'),
    },
    [matches.match2]: {
      team100: teams.rj,
      team200: teams.szb,
      datetime: new Date('2018-04-04T17:00'),
    },
  },
  [rounds.semifinal]: {
    [matches.match1]: {
      team100: teams.pgm,
      team200: teams.usg,
      datetime: new Date('2018-04-07T17:00'),
    },
  },
  [rounds.final]: {
    [matches.match1]: {
      team100: teams.dfm,
      team200: teams.pgm,
      datetime: new Date('2018-04-14T17:00'),
    },
  },
};

export default schedule;
