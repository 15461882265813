// Change me when adding a new component ! (This effects the controller on CGPC)

// REACT
import React, { useState } from 'react';
// import { cloneDeep } from 'lodash';
import styled from 'styled-components';
// ME
// import { getChampionsForLocale } from '../../lib/allstats-sdk/api';
import Data from '../Data';
// import ControlContainer from './ControlContainer';
// import {i18n} from '../i18n';
// import Button from '../Button';
// Menu
import SetupMenu from '../SetupMenu';
// General Controls
import TimerControl from '../Timer/Control';
import Timer2Control from '../Timer2/Control';
import ScheduleControl from '../Schedule/Control';
import ScheduleV2Control from '../ScheduleV2/Control';
import ScheduleControlNext from '../Schedule/ControlNext';
import LeaderboardControl from '../Leaderboard/Control';
import StandingsControl from '../Standings/Control';
import StandingsV2Control from '../StandingsV2/Control';
import ResultsControl from '../Results/Control';
import StartersControl from '../Starters/Control';
import StartersV2Control from '../StartersV2/Control';
import BanPickControl from '../BanPick/Control';
import BanPickV2Control from '../BanPickV2/Control';
import GameScreenControl from '../GameScreen/Control';
import MatchupControl from '../Matchup/Control';
import MatchupV2Control from '../MatchupV2/Control';
import MatchPlayerControl from '../MatchPlayer/Control';
import MatchPlayerV2Control from '../MatchPlayerV2/Control';
import NextMatchInfoBottomBarControl from '../NextMatchInfoBottomBar/Control';
import ScheduleInfoSideBarControl from '../ScheduleInfoSideBar/Control';
import TelopsControl from '../Telops/Control';
import TelopsV2Control from '../TelopsV2/Control';
import KeyPlayersControl from '../KeyPlayers/Control';
import KeyPlayersV2Control from '../KeyPlayersV2/Control';
import GameBreakdownControl from '../GameBreakdown/Control';
import ViewCleanerControl from '../ViewCleaner/Control';
import WaitingScreenControl from '../WaitingScreen/Control';
import ScheduleTeamlessControl from '../ScheduleTeamless/Control';
import ResultsListControl from '../ResultsList/Control';
import BlankControl from '../BlankScreen/Control';
import CastersPredictionsOverlayControl from '../CastersPredictionsOverlay/Control';
import CastersPredictionsControl from '../CastersPredictions/Control';
import ChampionPoolControl from '../ChampionPool/Control';
import DraftOrderControl from '../DraftOrder/Control';
import { get } from 'lodash';

// class Controller extends Component {
function Controller() {
    const [menuOpen, setMenuOpen] = useState(true)
    const [schedule, setSchedule] = useState(window.MainData.schedule)
    const [standings, setStandings] = useState([])
    const [controllerSwitch, setControllerSwitch] = useState(false)
    const updateControllerSwitch = () => {
        setControllerSwitch(!controllerSwitch)
    }

    const submitSetup = (data, leaguesInformation) => {
        const theme = data.theme;
        console.log(theme, 'theme checker')
        const league = data.league;
        const season = data.season;
        const split = data.split;
        const imageLarge = data.imageLarge;
        const standings_groups = data.standings_groups;
        window.MainData.leaguesInformation = leaguesInformation;
        window.MainData.teams = data.teams;
        window.MainData.standings_groups = standings_groups;
        window.MainData.schedule.league = league;
        window.MainData.schedule.season = season;
        window.MainData.schedule.split = split;
        window.MainData.schedule.imageLarge = imageLarge;
        window.MainData.schedule.rounds = buildRounds(data.schedule, data.teams);
        window.MainData.schedule.round = 0;
        window.MainData.schedule.match = 0;
        window.MainData.schedule.game = 0;
        window.MainData.theme = theme;
        setMenuOpen(false)
        setStandings(standings_groups)
    }

    const buildRounds = (schedule, teams) => {
        const s = schedule;
        s.rounds.map((round, i) => {
            const matches = s.rounds[i].matches;
            return matches.map((match, j) => {
                const team100 = schedule.rounds[i].matches[j].team100;
                const team200 = schedule.rounds[i].matches[j].team200;
                if (team100 || team200) {
                    s.rounds[i].matches[j].team100 = Object.assign({}, teams[team100]);
                    s.rounds[i].matches[j].team200 = Object.assign({}, teams[team200]);
                    s.rounds[i].matches[j].team100.tricode = team100;
                    s.rounds[i].matches[j].team200.tricode = team200;
                } else {
                    s.rounds[i].matches[j].teams = schedule.rounds[i].matches[j].teams.map(tricode => teams[tricode]);
                }
                return true;
            });
        });
        return s.rounds;
    }

    const openSplitMenu = () => {
        setMenuOpen(true)
    }

    const secondKeys = {
        'cgpc1': 'cgpc2',
        'academy1': 'academy2',
        'msi1': 'msi2',
        'pubgmjc1': 'pubgmjc2',
    }
    const arrayOfComponentsKey1 = [
        get(window.MainData.components, 'view.Timer', false) && <TimerControl key={'TimerControl'} screen1={true} cgpc={window.MainData.key} display={controllerSwitch} />,
        get(window.MainData.components, 'view.Timer2', false) && <Timer2Control key={'Timer2Control'} screen1={true} cgpc={window.MainData.key} display={controllerSwitch} />,
        get(window.MainData.components, 'view.ScheduleInfoSideBar', false) && <ScheduleInfoSideBarControl key={'ScheduleInfoSideBarControl'} screen1={true} cgpc={window.MainData.key} display={controllerSwitch} />,
        get(window.MainData.components, 'view.TimeNextMatchInfoBottomBarr2', false) && <NextMatchInfoBottomBarControl key={'NextMatchInfoBottomBarControl'} screen1={true} cgpc={window.MainData.key} display={controllerSwitch} />,
        get(window.MainData.components, 'view.Standings', false) && <StandingsControl key={'StandingsControl'} screen1={true} cgpc={window.MainData.key} display={controllerSwitch} />,
        get(window.MainData.components, 'view.StandingsV2', false) && <StandingsV2Control key={'StandingsV2Control'} standings={standings} screen1={true} cgpc={window.MainData.key} display={controllerSwitch} />,
        get(window.MainData.components, 'view.Leaderboard', false) && <LeaderboardControl key={'LeaderboardControl'} screen1={true} cgpc={window.MainData.key} display={controllerSwitch} />,
        get(window.MainData.components, 'view.Telops', false) && <TelopsControl key={'TelopsControl'} screen1={true} cgpc={window.MainData.key} display={controllerSwitch} />,
        get(window.MainData.components, 'view.TelopsV2', false) && <TelopsV2Control key={'TelopsV2Control'} screen1={true} cgpc={window.MainData.key} display={controllerSwitch} />,
        get(window.MainData.components, 'view.CastersPredictions', false) && <CastersPredictionsControl key={'CastersPredictionsControl'} screen1={true} cgpc={window.MainData.key} display={controllerSwitch} />,
        get(window.MainData.components, 'view.CastersPredictions', false) && <CastersPredictionsOverlayControl key={'CastersPredictionsOverlayControl'} screen1={true} cgpc={window.MainData.key} display={controllerSwitch} />,
        get(window.MainData.components, 'view.Schedule', false) && <ScheduleControl key={'ScheduleControl'} screen1={true} cgpc={window.MainData.key} display={controllerSwitch} />,
        get(window.MainData.components, 'view.ScheduleV2', false) && <ScheduleV2Control key={'ScheduleV2Control'} screen1={true} cgpc={window.MainData.key} display={controllerSwitch} />,
        get(window.MainData.components, 'view.ScheduleNext', false) && <ScheduleControlNext key={'ScheduleControlNext'} screen1={true} cgpc={window.MainData.key} display={controllerSwitch} />,
        get(window.MainData.components, 'view.Starters', false) && <StartersControl key={'StartersControl'} screen1={true} cgpc={window.MainData.key} display={controllerSwitch} />,
        get(window.MainData.components, 'view.Starters_V2', false) && <StartersV2Control key={'StartersV2Control'} screen1={true} cgpc={window.MainData.key} display={controllerSwitch} />,
        get(window.MainData.components, 'view.KeyPlayers', false) && <KeyPlayersControl key={'KeyPlayersControl'} screen1={true} cgpc={window.MainData.key} display={controllerSwitch} />,
        get(window.MainData.components, 'view.KeyPlayersV2', false) && <KeyPlayersV2Control key={'KeyPlayersV2Control'} screen1={true} cgpc={window.MainData.key} display={controllerSwitch} />,
        get(window.MainData.components, 'view.BanPick', false) && <BanPickControl key={'BanPickControl'} screen1={true} cgpc={window.MainData.key} display={controllerSwitch} />,
        get(window.MainData.components, 'view.BanPickV2', false) && <BanPickV2Control key={'BanPickV2Control'} screen1={true} cgpc={window.MainData.key} display={controllerSwitch} />,
        get(window.MainData.components, 'view.Matchup', false) && <MatchupControl key={'MatchupControl'} screen1={true} cgpc={window.MainData.key} display={controllerSwitch} />,
        get(window.MainData.components, 'view.MatchupV2', false) && <MatchupV2Control key={'MatchupV2Control'} screen1={true} cgpc={window.MainData.key} display={controllerSwitch} />,
        get(window.MainData.components, 'view.GameScreen', false) && <GameScreenControl key={'GameScreenControl'} schedule={schedule} screen1={true} cgpc={window.MainData.key} display={controllerSwitch} />,
        get(window.MainData.components, 'view.GameBreakdown', false) && <GameBreakdownControl key={'GameBreakdownControl'} screen1={true} cgpc={window.MainData.key} display={controllerSwitch} />,
        get(window.MainData.components, 'view.BlankScreen', false) && <BlankControl key={'BlankControl'} screen1={true} cgpc={window.MainData.key} display={controllerSwitch} />,
        get(window.MainData.components, 'view.ViewCleaner', false) && <ViewCleanerControl key={'ViewCleanerControl'} screen1={true} cgpc={window.MainData.key} display={controllerSwitch} />,
        get(window.MainData.components, 'view.MatchPlayer', false) && <MatchPlayerControl key={'MatchPlayerControl'} screen1={true} cgpc={window.MainData.key} display={controllerSwitch} />,
        get(window.MainData.components, 'view.MatchPlayerV2', false) && <MatchPlayerV2Control key={'MatchPlayerV2Control'} screen1={true} cgpc={window.MainData.key} display={controllerSwitch} />,
        get(window.MainData.components, 'view.Results', false) && <ResultsControl key={'ResultsControl'} screen1={true} cgpc={window.MainData.key} display={controllerSwitch} />,
        get(window.MainData.components, 'view.ResultsList', false) && <ResultsListControl key={'ResultsListControl'} screen1={true} cgpc={window.MainData.key} display={controllerSwitch} />,
        get(window.MainData.components, 'view.WaitingScreen', false) && <WaitingScreenControl key={'WaitingScreenControl'} screen1={true} cgpc={window.MainData.key} display={controllerSwitch} />,
        get(window.MainData.components, 'view.ScheduleTeamless', false) && <ScheduleTeamlessControl key={'ScheduleTeamlessControl'} screen1={true} cgpc={window.MainData.key} display={controllerSwitch} />,
        get(window.MainData.components, 'view.DraftOrder', false) && <DraftOrderControl key={'DraftOrderControl'} screen1={true} cgpc={window.MainData.key} display={controllerSwitch} />,
        get(window.MainData.components, 'view.ChampionPool', false) && <ChampionPoolControl key={'ChampionPoolControl'} screen1={true} cgpc={window.MainData.key} display={controllerSwitch} />,
    ]
    
    const arrayOfComponentsKey2 = [
        get(window.MainData.components, 'view.Timer', false) && <TimerControl key={'TimerControl'} cgpc={secondKeys[window.MainData.key]} display={!controllerSwitch} />,
        get(window.MainData.components, 'view.Timer2', false) && <Timer2Control key={'Timer2Control'} cgpc={secondKeys[window.MainData.key]} display={!controllerSwitch} />,
        get(window.MainData.components, 'view.ScheduleInfoSideBar', false) && <ScheduleInfoSideBarControl key={'ScheduleInfoSideBarControl'} cgpc={secondKeys[window.MainData.key]} display={!controllerSwitch} />,
        get(window.MainData.components, 'view.TimeNextMatchInfoBottomBarr2', false) && <NextMatchInfoBottomBarControl key={'NextMatchInfoBottomBarControl'} cgpc={secondKeys[window.MainData.key]} display={!controllerSwitch} />,
        get(window.MainData.components, 'view.Standings', false) && <StandingsControl key={'StandingsControl'} cgpc={secondKeys[window.MainData.key]} display={!controllerSwitch} />,
        get(window.MainData.components, 'view.StandingsV2', false) && <StandingsV2Control key={'StandingsV2Control'} standings={standings} cgpc={secondKeys[window.MainData.key]} display={!controllerSwitch} />,
        get(window.MainData.components, 'view.Leaderboard', false) && <LeaderboardControl key={'LeaderboardControl'} cgpc={secondKeys[window.MainData.key]} display={!controllerSwitch} />,
        get(window.MainData.components, 'view.Telops', false) && <TelopsControl key={'TelopsControl'} cgpc={secondKeys[window.MainData.key]} display={!controllerSwitch} />,
        get(window.MainData.components, 'view.TelopsV2', false) && <TelopsV2Control key={'TelopsV2Control'} cgpc={secondKeys[window.MainData.key]} display={!controllerSwitch} />,
        get(window.MainData.components, 'view.CastersPredictions', false) && <CastersPredictionsControl key={'CastersPredictionsControl'} cgpc={secondKeys[window.MainData.key]} display={!controllerSwitch} />,
        get(window.MainData.components, 'view.CastersPredictions', false) && <CastersPredictionsOverlayControl key={'CastersPredictionsOverlayControl'} cgpc={secondKeys[window.MainData.key]} display={!controllerSwitch} />,
        get(window.MainData.components, 'view.Schedule', false) && <ScheduleControl key={'ScheduleControl'} cgpc={secondKeys[window.MainData.key]} display={!controllerSwitch} />,
        get(window.MainData.components, 'view.ScheduleV2', false) && <ScheduleV2Control key={'ScheduleV2Control'} cgpc={secondKeys[window.MainData.key]} display={!controllerSwitch} />,
        get(window.MainData.components, 'view.ScheduleNext', false) && <ScheduleControlNext key={'ScheduleControlNext'} cgpc={secondKeys[window.MainData.key]} display={!controllerSwitch} />,
        get(window.MainData.components, 'view.Starters', false) && <StartersControl key={'StartersControl'} cgpc={secondKeys[window.MainData.key]} display={!controllerSwitch} />,
        get(window.MainData.components, 'view.Starters_V2', false) && <StartersV2Control key={'StartersV2Control'} cgpc={secondKeys[window.MainData.key]} display={!controllerSwitch} />,
        get(window.MainData.components, 'view.KeyPlayers', false) && <KeyPlayersControl key={'KeyPlayersControl'} cgpc={secondKeys[window.MainData.key]} display={!controllerSwitch} />,
        get(window.MainData.components, 'view.KeyPlayersV2', false) && <KeyPlayersV2Control key={'KeyPlayersV2Control'} cgpc={secondKeys[window.MainData.key]} display={!controllerSwitch} />,
        get(window.MainData.components, 'view.BanPick', false) && <BanPickControl key={'BanPickControl'} cgpc={secondKeys[window.MainData.key]} display={!controllerSwitch} />,
        get(window.MainData.components, 'view.BanPickV2', false) && <BanPickV2Control key={'BanPickV2Control'} cgpc={secondKeys[window.MainData.key]} display={!controllerSwitch} />,
        get(window.MainData.components, 'view.Matchup', false) && <MatchupControl key={'MatchupControl'} cgpc={secondKeys[window.MainData.key]} display={!controllerSwitch} />,
        get(window.MainData.components, 'view.MatchupV2', false) && <MatchupV2Control key={'MatchupV2Control'} cgpc={secondKeys[window.MainData.key]} display={!controllerSwitch} />,
        get(window.MainData.components, 'view.GameScreen', false) && <GameScreenControl key={'GameScreenControl'} schedule={schedule} cgpc={secondKeys[window.MainData.key]} display={!controllerSwitch} />,
        get(window.MainData.components, 'view.GameBreakdown', false) && <GameBreakdownControl key={'GameBreakdownControl'} cgpc={secondKeys[window.MainData.key]} display={!controllerSwitch} />,
        get(window.MainData.components, 'view.BlankScreen', false) && <BlankControl key={'BlankControl'} cgpc={secondKeys[window.MainData.key]} display={!controllerSwitch} />,
        get(window.MainData.components, 'view.ViewCleaner', false) && <ViewCleanerControl key={'ViewCleanerControl'} cgpc={secondKeys[window.MainData.key]} display={!controllerSwitch} />,
        get(window.MainData.components, 'view.MatchPlayer', false) && <MatchPlayerControl key={'MatchPlayerControl'} cgpc={secondKeys[window.MainData.key]} display={!controllerSwitch} />,
        get(window.MainData.components, 'view.MatchPlayerV2', false) && <MatchPlayerV2Control key={'MatchPlayerV2Control'} cgpc={secondKeys[window.MainData.key]} display={!controllerSwitch} />,
        get(window.MainData.components, 'view.Results', false) && <ResultsControl key={'ResultsControl'} cgpc={secondKeys[window.MainData.key]} display={!controllerSwitch} />,
        get(window.MainData.components, 'view.ResultsList', false) && <ResultsListControl key={'ResultsListControl'} cgpc={secondKeys[window.MainData.key]} display={!controllerSwitch} />,
        get(window.MainData.components, 'view.WaitingScreen', false) && <WaitingScreenControl key={'WaitingScreenControl'} cgpc={secondKeys[window.MainData.key]} display={!controllerSwitch} />,
        get(window.MainData.components, 'view.ScheduleTeamless', false) && <ScheduleTeamlessControl key={'ScheduleTeamlessControl'} cgpc={secondKeys[window.MainData.key]} display={!controllerSwitch} />,
        get(window.MainData.components, 'view.DraftOrder', false) && <DraftOrderControl key={'DraftOrderControl'} cgpc={secondKeys[window.MainData.key]} display={!controllerSwitch} />,
        get(window.MainData.components, 'view.ChampionPool', false) && <ChampionPoolControl key={'ChampionPoolControl'} cgpc={secondKeys[window.MainData.key]} display={!controllerSwitch} />,
    ]

    return (
        <div className="controller2">
            {
                menuOpen ?
                    <SetupMenu submitSetup={submitSetup} /> : [
                        <div key="controls" className="controls-container">
                            <StyledTabsWrap><StyledTabs screen1={true} controllerSwitch={!controllerSwitch} onClick={() => updateControllerSwitch()}>{window.MainData.key}</StyledTabs>
                            <StyledTabs controllerSwitch={controllerSwitch} onClick={() => updateControllerSwitch()}>{secondKeys[window.MainData.key]}</StyledTabs></StyledTabsWrap>
                            <StyledWrap controllerSwitch={controllerSwitch}>
                                {arrayOfComponentsKey1}
                                {arrayOfComponentsKey2}
                            </StyledWrap>
                        </div>,
                        <Data
                            key="data"
                            openSplitMenu={openSplitMenu}
                            onUpdateSchedule={(schedule) => {
                                setSchedule({ ...schedule })
                            }}
                            secondKeys={secondKeys}
                            cgpc={window.MainData.key}
                        />,
                    ]
            }
        </div>
    );
}

const StyledWrap = styled.div`
border: 2px solid ${props => props.controllerSwitch ? '#B026FF' : '#076FC1'};
`
const StyledTabs = styled.button`
width: 50%;
height: 40px;
color: white;
border-top-right-radius: 15px 10px;
border-top-left-radius: 15px 10px;
background: ${props => props.controllerSwitch ? props.screen1 ? '#076FC1' : '#B026FF' : 'RGBA(0,0,0,0.8)'};
position: -webkit-sticky; /* Safari */
position: sticky;
top: 0;
z-index: 20;
`

const StyledTabsWrap = styled.div`
width: 100%;
background: #666;
position: -webkit-sticky; /* Safari */
position: sticky;
top: 0;
z-index: 20;
`


export default Controller
