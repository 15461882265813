"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StyleTwitterName = exports.StyleTwitterIcon = exports.StyleTwitterContainer = exports.StyleTelopSingle = exports.StyleTelop = exports.StyleName = exports.StyleInfo = void 0;

var _styledComponents = _interopRequireDefault(require("styled-components"));

var _reactSpring = require("react-spring");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var StyleTelop = _styledComponents.default.ul.withConfig({
  displayName: "styles__StyleTelop",
  componentId: "sc-1jjm78z-0"
})(["display:grid;grid-template-columns:", ";position:absolute;grid-column-gap:", ";bottom:300px;right:50%;transform:translateX(50%);", ";"], function (props) {
  return props.gridStyle ? 'repeat(4, 390px)' : 'repeat(3, 390px)';
}, function (props) {
  return props.gridStyle ? '81px' : '175px';
}, function (props) {
  return props.StyleTelop ? props.StyleTelop : '';
});

exports.StyleTelop = StyleTelop;
var StyleTelopSingle = (0, _styledComponents.default)(_reactSpring.animated.li).withConfig({
  displayName: "styles__StyleTelopSingle",
  componentId: "sc-1jjm78z-1"
})(["position:relative;grid-column-start:", ";width:390px;height:110px;background-image:linear-gradient(0,#540035 0%,#f00020 100%);", ""], function (props) {
  return props.columnStart;
}, function (props) {
  return props.cssTelops || '';
});
exports.StyleTelopSingle = StyleTelopSingle;
var StyleInfo = (0, _styledComponents.default)(_reactSpring.animated.div).withConfig({
  displayName: "styles__StyleInfo",
  componentId: "sc-1jjm78z-2"
})(["position:absolute;top:0;bottom:0;left:10px;right:0;padding:25px 25px 0;display:flex;flex-direction:column;justify-content:space-evenly;background-color:#f6f6f7;padding-top:10px;justify-content:center;width:100%;", ";"], function (props) {
  return props.StyleInfo ? props.StyleInfo : '';
});
exports.StyleInfo = StyleInfo;

var StyleTwitterName = _styledComponents.default.p.withConfig({
  displayName: "styles__StyleTwitterName",
  componentId: "sc-1jjm78z-3"
})(["color:#141720;font-size:20px;font-family:\"futura-pt\",sans-serif;font-weight:500;letter-spacing:1px;", ";"], function (props) {
  return props.StyleTwitterName ? props.StyleTwitterName : '';
});

exports.StyleTwitterName = StyleTwitterName;

var StyleTwitterIcon = _styledComponents.default.i.withConfig({
  displayName: "styles__StyleTwitterIcon",
  componentId: "sc-1jjm78z-4"
})(["position:relative;margin-right:8px;color:#1da1f2;&&{width:16px;height:16px;}left:2px;", ";"], function (props) {
  return props.StyleTwitterIcon ? props.StyleTwitterIcon : '';
});

exports.StyleTwitterIcon = StyleTwitterIcon;

var StyleName = _styledComponents.default.p.withConfig({
  displayName: "styles__StyleName",
  componentId: "sc-1jjm78z-5"
})(["color:#141720;line-height:35px;position:relative;top:-8px;", ";", ";"], function (props) {
  return props.cssName || '';
}, function (props) {
  return props.isJapaneseCharacter && props.cssJapaneseName ? props.cssJapaneseName : '';
});

exports.StyleName = StyleName;

var StyleTwitterContainer = _styledComponents.default.div.withConfig({
  displayName: "styles__StyleTwitterContainer",
  componentId: "sc-1jjm78z-6"
})(["display:flex;align-items:center;margin-top:2px;position:relative;top:1px;", ""], function (props) {
  return props.cssTwitter || '';
});

exports.StyleTwitterContainer = StyleTwitterContainer;