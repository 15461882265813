/* eslint-disable no-unused-vars */

const get = key => {
    let result;
    try {
        result = localStorage.getItem(key);
    } catch (e) {

        // localStorage not available
    }
    return result;
};

const set = (key, value) => {
    try {
        localStorage.setItem(key, value);
    } catch (e) {

        // localStorage not available
    }
};

const remove = key => {
    try {
        localStorage.removeItem(key);
    } catch (e) {

        // localStorage not available
    }
};

const storage = {
    get,
    set,
    remove,
};

export default storage;
