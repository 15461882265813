"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StylePositionImgContainer = exports.StylePositionBox = void 0;

var _styledComponents = _interopRequireDefault(require("styled-components"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var StylePositionBox = _styledComponents.default.div.withConfig({
  displayName: "styles__StylePositionBox",
  componentId: "sc-1i54qxr-0"
})(["display:flex;justify-content:center;align-items:center;height:80px;width:287px;background-color:#141720;margin-left:10px;", ""], function (props) {
  return props.cssPosition;
});

exports.StylePositionBox = StylePositionBox;

var StylePositionImgContainer = _styledComponents.default.div.withConfig({
  displayName: "styles__StylePositionImgContainer",
  componentId: "sc-1i54qxr-1"
})([""]);

exports.StylePositionImgContainer = StylePositionImgContainer;