"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.sideColor = void 0;
var sideColor = {
  team100: '#0099e0',
  team200: '#e83d3d'
};
exports.sideColor = sideColor;