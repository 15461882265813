// REACT
import React from 'react';
import PropTypes from 'prop-types';

const Twitter = props => (
  <div className="animated animated--fast telops__twitter">
    <div className="telops__twitter__blue"></div>
    <div className="animated animated--fast telops__twitter__brush"></div>
    <i className="fab fa-twitter" aria-hidden="true"></i>
    <div className="animated animated--fast telops__twitter__text">
      <span className="telops__twitter__text__name">@{props.tweet ? props.tweet.name : ''}</span>
      <span className="telops__twitter__text__tweet">{props.tweet ? props.tweet.text : ''}</span>
    </div>
  </div>
);

Twitter.propTypes = {
  tweet: PropTypes.object,
};

export default Twitter