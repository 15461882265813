import { firebaseRealtimeListenToPath, firebaseRealtimeDbSetValue } from "../util/firebase";

// import openSocket from 'socket.io-client';

// const localURL = 'http://localhost:8080';
// const devURL = 'http://localhost:8080';
// const prodURL = 'https://ljl-broadcast.jp.leagueoflegends.com:8080';

// const isProd = process.env.NODE_ENV === 'production';

// let socket;
// const createSocket = (isLocal) => {
//   let baseURL = isLocal ? localURL : isProd ? prodURL : devURL;
//   socket = openSocket(baseURL);

//   // PREFER LOCAL OVER REMOTE, BUT FALLBACK TO REMOTE IF NEEDED
//   socket.on('connect_timeout', () => {
//     if (isProd) {
//       socket.disconnect();
//       createSocket(false);
//     }
//   });
//   socket.on('connect_error', () => {
//     if (isProd) {
//       socket.disconnect();
//       createSocket(false);
//     }
//   });
// };

// createSocket(true);

const subscribe = (target, action, cb, key) => {
  const newCb = data => {
    cb({
      ...data,
      target: data.target || target,
      action: data.action || action,
      type: data.type || 'event',
    });
  };
  if (!target) return;
  if (!action) {
    firebaseRealtimeListenToPath(`${target}`, newCb, key);
  } else {
    firebaseRealtimeListenToPath(`${target}/${action}`, newCb, key);
  }
};

// const subscribe = cb => {
//   socket.on('receive', data => {
//     cb(data);
//   });
// };

// const tempSubscribe = cb => {
//     socket.on('receive', cb);
// };

// const unsubscribe = cb => {
//   socket.removeListener('receive', cb);
// }

const send = (type = 'set', data, key) => {
  const logoSubtitle = window.MainData.schedule && window.MainData.schedule.rounds && window.MainData.schedule.rounds[window.MainData.schedule.round].name;
  if (!data.logoSubtitle) {
    data.logoSubtitle = logoSubtitle;
  }
  data.type = type;
  // socket.emit(type, data);
  const target = data.target;
  const action = data.action;
  if (!target) return;
  if (!action) {
    firebaseRealtimeDbSetValue(`${target}`, data, key);
  } else {
    firebaseRealtimeDbSetValue(`${target}/${action}`, data, key);
  }
};

export {
  send,
  subscribe,
  // tempSubscribe,
  // unsubscribe,
}
