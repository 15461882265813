// REACT
import React, { Component } from 'react';
import PropTypes from 'prop-types';
// ME
import {i18n} from '../../../i18n';

class StatInput extends Component {

  static propTypes = {
    index: PropTypes.number,
    onSet: PropTypes.func,
    stat: PropTypes.object,
    type: PropTypes.string,
  };

  state = {
    value: '',
    player: '',
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps && nextProps.stat && nextProps.stat.data) {
      this.setState({value: nextProps.stat.data});
    }
  }

  updateData = e => {
    this.setState({value: e.target.value}, this.setData);
  };

  updatePlayer = player => {
    this.setState({player}, this.setData);
  };

  setData = () => {
    const data = {player: this.state.player, data: this.state.value, index: this.props.index, type: this.props.type};
    this.props.onSet(data);
  };

  render() {
    return (
      <div className="row row--stripe">
        <div className="pill">
          <span>#{this.props.index} : {this.props.stat ? this.props.stat.player : ''} {this.props.stat ? `(${this.props.stat.team})` : ''}</span>
          <input className="input input--short" data-type={this.props.type} onChange={this.updateData} placeholder={i18n().general[this.props.type]} type="text" value={this.state.value} />
        </div>
      </div>
    );
  }

}

export default StatInput