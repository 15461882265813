import React, { useContext } from 'react';
import TelopsV2 from '../../storybook-built/dist/components/Games/LJL/TelopsV2';
import PropTypes from 'prop-types';
import { ThemeContext } from '../View/themes';

const TelopsV2Component = props => {

  const theme = useContext(ThemeContext)
  return (
    <div className={'container'}>
      <TelopsV2
        list={props.data.list || []}
        isShowing={props.active}
        theme={theme.screens.telopsV2 || {}}
      />
    </div>
  );
};

TelopsV2Component.propTypes = {
  data: PropTypes.object.isRequired,
  active: PropTypes.bool.isRequired,
};

export default TelopsV2Component;
