// REACT
import React, { Component } from 'react';
// internal
import { themes, ThemeContext } from '../../View/themes';
import PropTypes from 'prop-types';
import DataContainer from '../DataContainer';
// import { firebaseRealtimeDbSetValue } from '../../../util/firebase';

class DataWaitingMessage extends Component {
    state = {
        messageName: '',
    }
    static propTypes = {
        setDataNode: PropTypes.func,
      };

    onChangeWaitingMessage = event => {
        this.setWaitingMessage(event.target.value || null);
    }

    setWaitingMessage = waitingMessage => {
        this.props.setDataNode('waitingScreen', {message : waitingMessage});
    }

    componentDidMount(){
    }


    render () {
        const theme = themes[window.MainData.theme].screens.waitingScreen || themes.default;

        const messages = theme.messages || [];
        return (
            <DataContainer componentSlug="dataWaitingMessage" icon="fas fa-comment-alt">
                <div className="data-panel__fieldset">
                    <label className="data-panel__fieldset__label">Messages:</label>
                    <div className="data-panel__fieldset__body">
                        <select onChange={this.onChangeWaitingMessage}>
                            <option value='none'>None</option>
                            {
                                Object.keys(messages).map((message, i) => {
                                    if(this.state.messageName === message){
                                        return <option value={message} key={i} selected>{message}</option>
                                    }
                                    return <option value={message} key={i}>{message}</option>
                                })
                            }
                        </select>
                    </div>
                </div>
            </DataContainer>
        );

    }

}

DataWaitingMessage.contextType = ThemeContext;

export default DataWaitingMessage
