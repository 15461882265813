// Imports
import styled from 'styled-components';

export const StylePill = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    height: auto;

    > * {
      &:nth-child(n) {
        width: auto;
      }
      &:nth-child(3) {
        min-width: 80px;
      }
    }
`;