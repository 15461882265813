import { getStandings } from '../lib/allstats-sdk/api';

const getStandingsTable = (season, league, split, round) => {
  return getStandings(season, league, split, round)
    .then(standingsData => {
      // calculate top 5 for each stat
      return standingsData;
    });
}

export {
    getStandingsTable,
}