import { cloneDeep } from 'lodash';
import { firebaseRealtimeDbSetValue } from '../util/firebase';
import lol_positions from './../storybook-built/dist/Constants/lol_positions';

const BAN_ORDER = {
    phase_1: {
    // the key is the pick turn, the value is the index position
        1: { pos: 0, teamID: 'team100', step: 0},
        2: { pos: 0, teamID: 'team200', step: 1},
        3: { pos: 1, teamID: 'team100', step: 2},
        4: { pos: 1, teamID: 'team200', step: 3},
        5: { pos: 2, teamID: 'team100', step: 4},
        6: { pos: 2, teamID: 'team200', step: 5},
    },
    phase_2: {
        1: { pos: 3, teamID: 'team200', step: 12},
        2: { pos: 3, teamID: 'team100', step: 13},
        3: { pos: 4, teamID: 'team200', step: 14},
        4: { pos: 4, teamID: 'team100', step: 15},
    },
};

const PICK_ORDER = {
    phase_1:{
        1: { pos: 'top', posIndex: 0, teamID: 'team100', step: 6},
        2: { pos: 'top', posIndex: 0, teamID: 'team200', step: 7},
        3: { pos: 'jungle', posIndex: 1, teamID: 'team200', step: 8},
        4: { pos: 'jungle', posIndex: 1, teamID: 'team100', step: 9},
        5: { pos: 'mid', posIndex: 2, teamID: 'team100', step: 10},
        6: { pos: 'mid', posIndex: 2, teamID: 'team200', step: 11},
    },
    phase_2:{
        1: { pos: 'adc', posIndex: 3, teamID: 'team200', step: 16},
        2: { pos: 'adc', posIndex: 3, teamID: 'team100', step: 17},
        3: { pos: 'support', posIndex: 4, teamID: 'team100', step: 18},
        4: { pos: 'support', posIndex: 4, teamID: 'team200', step: 19},
    },
};

// const getPhaseNumber = (champ_selectEvent) => {
//         (get(champ_selectEvent, 'teamTwo[3].pickTurn') === 0)){
//             return '1';
//         }
//         else {
//             return '2';
//         }
// }

export const initialBanPick = {
    status: {
        type: 'ban',
        teamID: 'team100',
        pos: 0,
    },
    bans: {
        team100: new Array(5).fill(0),
        team200: new Array(5).fill(0),
    },
    picks: {
        team100: {
            ...Object.assign(...lol_positions.map(pos => ({ [pos]: {championID: '', pickMode: ''} }))),
        },
        team200: {
            ...Object.assign(...lol_positions.map(pos => ({ [pos]: {championID: '', pickMode: ''} }))),
        },
    },
}

const currentBanPick = cloneDeep(initialBanPick);

const getCurrentStep = liveStats => {
    const phase = liveStats.teamOne[0].pickTurn ? 'phase_1' : 'phase_2';
    if(liveStats.gameState === 'PRE_CHAMP_SELECT'){
        if(liveStats.bannedChampions[liveStats.bannedChampions.length-1].pickTurn !== liveStats.pickTurn){
            return ({
                type: 'ban',
                teamID: BAN_ORDER[phase][liveStats.pickTurn].teamID,
                step: BAN_ORDER[phase][liveStats.pickTurn].step,
                pos: BAN_ORDER[phase][liveStats.pickTurn].pos,
            })
        }
        else if(liveStats.pickTurn === 6){
            return ({teamID: 'team100', type: 'pick', step: 6, pos: 'top'});
        }else if((liveStats.pickTurn === 4) && (phase === 'phase2')){
            return ({teamID: 'team200', type: 'pick', step: 16, pos: 'adc'});
        }
        return ({
            type: 'ban',
            teamID: BAN_ORDER[phase][liveStats.pickTurn+1].teamID,
            step: BAN_ORDER[phase][liveStats.pickTurn+1].step,
            pos: BAN_ORDER[phase][liveStats.pickTurn+1].pos,
        });
    } else if(liveStats.gameState === 'CHAMP_SELECT'){
        const currentTeam = (PICK_ORDER[phase][liveStats.pickTurn].teamID === 'team100')? 'teamOne':'teamTwo';

        const currentPlayer = liveStats[currentTeam][PICK_ORDER[phase][liveStats.pickTurn].posIndex];
        // Current player is in picking process
        if(currentPlayer.pickMode === 1){
            return ({
                type: 'pick',
                teamID: PICK_ORDER[phase][liveStats.pickTurn].teamID,
                step: PICK_ORDER[phase][liveStats.pickTurn].step,
                pos: PICK_ORDER[phase][liveStats.pickTurn].pos,
            });
        }
        // end of phase 1 / pick => switch to phase 2 / ban
        if((liveStats.pickTurn === 6) && (liveStats.teamTwo[2].pickMode === 2)){
            return ({teamID: 'team200', type: 'ban', step: 12, pos: 3});
        }
        // end of phase 2 / pick => ban pick finished
        else if((phase === 'phase_2') && (liveStats.pickTurn === 4) && (liveStats.teamTwo[4].pickMode === 2)){
            return ({type: 'swap', step: 20});
        }
        // current turn already finalised need to check next turn
        return ({
            type: 'pick',
            teamID: PICK_ORDER[phase][liveStats.pickTurn+1].teamID,
            step: PICK_ORDER[phase][liveStats.pickTurn+1].step,
            pos: PICK_ORDER[phase][liveStats.pickTurn+1].pos,
        });
    }
}

const getBans = liveStats => {
    const bans = {team100: new Array(5).fill(0), team200: new Array(5).fill(0)}
    if(liveStats.bannedChampions.length){
        let phase = 1;
        liveStats.bannedChampions.forEach((ban, banIndex) => {
            if(banIndex && (liveStats.bannedChampions[banIndex].pickTurn < liveStats.bannedChampions[banIndex-1].pickTurn))
            phase = 2;
            bans[`team${ban.teamID}`][BAN_ORDER[`phase_${phase}`][ban.pickTurn].pos] = ban.championID;
        })
    }
    return bans;
}

export const updateBanPick = liveStats => {
    console.log("ABC updateBanPick", liveStats);
    if(liveStats.gameState === "POST_CHAMP_SELECT"){
        // Bans
        currentBanPick.bans = getBans(liveStats);
        currentBanPick.status = {type: 'swap', step: 20};
    }
    else {
        currentBanPick.status = getCurrentStep(liveStats);
        // Bans
        currentBanPick.bans = getBans(liveStats);
    }
    // Picks
    lol_positions.forEach((pos, index) => {
        currentBanPick.picks.team100[pos] = {
            pickMode: liveStats.teamOne[index].pickMode,
            championID: liveStats.teamOne[index].championID,
        }
        currentBanPick.picks.team200[pos] = {
            pickMode: liveStats.teamTwo[index].pickMode,
            championID: liveStats.teamTwo[index].championID,
        }
    })
    firebaseRealtimeDbSetValue(`banPick/`, currentBanPick);
}

