const defaultPatch = '13.19'; // sometimes this becomes 12.12B not just 12.12

const positions = {
  top:     'TOP',
  jungle:  'JUNGLE',
  mid:     'MID',
  adc:     'ADC',
  support: 'SUPPORT',
};

const lol_positions = [
  'top',
  'jungle',
  'mid',
  'adc',
  'support',
]

const positionsArr = ['TOP','JUNGLE','MID','ADC','SUPPORT'];

const positionsNameValue = [
  {name: 'TOP',     value: 'TOP'},
  {name: 'JUNGLE',  value: 'JUNGLE'},
  {name: 'MID',     value: 'MID'},
  {name: 'ADC',     value: 'ADC'},
  {name: 'SUPPORT', value: 'SUPPORT'},
];

const dragons = {
  WATER_DRAGON: 'ocean',
  AIR_DRAGON: 'cloud',
  EARTH_DRAGON: 'mountain',
  FIRE_DRAGON: 'infernal',
  CHEMTECH_DRAGON: 'chemtech',
  HEXTECH_DRAGON: 'hextech',
  ELDER_DRAGON: 'elder',
};

const teams = {
  pgm:  { tricode: 'PGM', name: 'PENTAGRAM', color: '#620015', coach: '34', seasonActive: true }, // formerly Rampage
  dfm:  { tricode: 'DFM', name: 'DetonatioN FocusMe', color: '#016282', coach: 'JOON', seasonActive: true },
  rj:   { tricode: 'RJ',  name: 'Rascal Jester', color: '#470d73', coach: 'Lillebelt', seasonActive: true },
  '7h': { tricode: '7h',  name: '7th heaven', color: '#8d0b0b', coach: 'Razer', seasonActive: true },
  v3:   { tricode: 'V3',  name: 'V3 Esports', color: '#3d5309', coach: '1onz', seasonActive: true },
  usg:  { tricode: 'USG', name: 'Unsold Stuff Gaming', color: '#650040', coach: 'Rahui', seasonActive: true },
  cga:  { tricode: 'CGA',  name: 'Crest Gaming Act', color: '#0b2a69', coach: 'Qoo'},
  szb:  { tricode: 'SZB',  name: 'SCARZ Burning Core', color: '#0c5213', coach: 'Hope of Nation'},
  rpg:  { tricode: 'RPG', name: 'Rampage', color: '#620015', isInactive: true },
  sz:   { tricode: 'SZ',  name: 'Scarz', color: '#000000', isInactive: true },
  dnr:  { tricode: 'DNR', name: 'Detonation Rising', color: '#620015', isInactive: true},
  bc:   { tricode: 'BC',  name: 'Burning Core', color: '#620015', isInactive: true},
  cg:   { tricode: 'CG',  name: '', color: '#620015', isInactive: true},
};

// const activeTeams = Object.values(teams).filter(t => !t.isInactive);

const colors = Object.keys(teams).reduce((hash, t) => {
  hash[t] = teams[t].color;
  return hash;
}, {});
colors.team100 = '#4883ab';
colors.team200 = '#dd4142';

const rounds = {
  round1: 1,
  round2: 2,
  round3: 3,
  round4: 4,
  round5: 5,
  round6: 6,
  round7: 7,
  round8: 8,
  round9: 9,
  round10: 10,
  semifinal: 'Semi Finals',
  final: 'Finals',
  promotion: 'Promotion Series',
  tiebreak: 'Tiebreaker',
  allstar: 'All Star',
  scoutinggrounds: 'Scouting Grounds',
};

const getRoundName = (round) => {
  const roundAsInt = Number.parseInt(round, 10);
  if (roundAsInt) {
    return `Round ${round}`;
  }
  return round;
};

const getMatchupName = (matchup) => {
  const team100 = matchup[0];
  const team200 = matchup[1];
  // Try to get tricode from shorthand name. Otherwise assume this is already the proper tricode
  const teamTricode100 = (teams[team100] ? teams[team100].tricode : null) || team100;
  const teamTricode200 = (teams[team200] ? teams[team200].tricode : null) || team200;

  return [teamTricode100, teamTricode200].sort().join('_');
};

// const getTeamFromSummonerName = (summonerName) => {
//   const listedPlayer = activePlayers.find(p => p.name === summonerName);
//   if (listedPlayer) {
//     return listedPlayer.team.tricode;
//   }
//   return summonerName.split(' ')[0];
// };

const matches = {
  match1: 1,
  match2: 2,
  match3: 3,
};

const tournamentRealms = [
  'ESPORTSTMNT01',
  'ESPORTSTMNT02',
  'ESPORTSTMNT03',
  'ESPORTSTMNT04',
  'ESPORTSTMNT05',
  'ESPORTSTMNT06',
  // NA
  'TRLH1',
  'TRLH2',
  // EU
  'TRLH3',
  'TRLH4',
  // Major international
  'TRLT1',
  'TRLT2',
  'TRLT3',
  'TRLT4',
  // NA
  'WMC2TMNT1',
  // TAIWAN
  'TRTW',
];

const leagues = [
  {value: 'ljl', name:'LJL'},
  {value: 'ljlcs', name:'LJL Challenger Series'},
  {value: 'ljlps', name:'LJL Promotion Series'},
  {value: 'msi', name: 'Mid Season Invitational'},
  {value: 'worlds', name: 'World Championship'},
  {value: 'allstars', name: 'All Star'},
  {value: 'ljlallstar', name: 'LJL All Star'},
  {value: 'scoutinggrounds', name: 'LJL Scouting Grounds'},
  {value: 'pubgmjc', name: 'PUBG Mobile Japan Championship'},
  {value: 'academy', name: 'Academy'},
];

const leagueName = leagueId => leagues.filter(league => leagueId === league.value)[0].name;

const splits = {
  ljl: [
    {value: 'spring', name: 'Spring Split'},
    {value: 'summer', name: 'Summer Split'},
  ],
  academy: [
    // {value: 'spring', name: 'Spring Split'},
    {value: 'summer', name: 'LJL Academy'},
  ],
  ljlcs: [
    {value: 'spring', name: 'Spring Split'},
    {value: 'summer', name: 'Summer Split'},
  ],
  ljlps: [
    {value: 'spring', name: 'Spring Split'},
    {value: 'summer', name: 'Summer Split'},
  ],
  ljlallstar: [
    {value: 'spring', name: 'Spring Split'},
    {value: 'summer', name: 'Summer Split'},
  ],
  ljlscoutinggrounds: [
    {value: 'spring', name: 'Spring Split'},
    {value: 'summer', name: 'Summer Split'},
  ],
  rr: [
    {value: 'rr', name: 'Rift Rivals'},
  ],
  msi: [
      {value: 'play_in_stage', name: 'Play-In Stage'},
      {value: 'play_in_groups', name: 'Play In Groups'},
      {value: 'play_in_knockout', name: 'Play In Knockout'},
      {value: 'groups', name: 'Group Stage(Main)' },
      {value: 'elim', name: 'Elimination(Main)'},
      {value: 'rumble', name: 'Rumble!'},
      {value: 'Knockout', name: 'Knockout!'},
      {value: 'bracket', name: 'Bracket Stage'},
  ],
  worlds: [
      {value: 'play_in_groups', name: 'Play In Groups'},
      {value: 'play_in_knockout', name: 'Play In Knockout'},
      {value: 'play_in', name: 'Play In'},
      {value: 'groups', name: 'Group Stage'},
      {value: 'elim', name: 'Elimination'},
      {value: 'swiss', name: 'Swiss'},
      {value: 'knockout', name: 'Knockouts'}
  ],
  scoutinggrounds: [
    {value: 'spring', name: 'Spring Final'},
    {value: 'summer', name: 'Summer Final'},
    {value: 'fall', name: 'Fall'},
  ],
  pubgmjc: [
    { value: 'spring', name: 'Spring' },
    { value: 'fall', name: 'Fall' },
  ],
}

const splitName = (leagueId, splitId) => splits[leagueId].filter(split => splitId === split.value)[0].name;

const seasons = [
  {value: '2017', name: '2017'},
  {value: '2018', name: '2018'},
  {value: '2019', name: '2019'},
  {value: '2020', name: '2020'},
  {value: '2021', name: '2021'},
  {value: '2022', name: '2022'},
  {value: '2023', name: '2023', default: true},
];

const games = [
  'ALLGAMES',
  'Game 1',
  'Game 2',
  'Game 3',
  'Game 4',
  'Game 5',
  'Game 6',
  'Game 7',
  'Game 8',
];

const defaultTournamentRealm = tournamentRealms[0];
const defaultSeason = seasons.find(season => season.default).value; // 2023
const defaultLeague = leagues[4].value; // 0 = LJL, 3 = MSI, 4 = World Championship, 9 = Academy
const defaultSplit = splits[defaultLeague][6].value; 
// console.log(defaultSplit, 'If error when loading a new split.')
const defaultGame = games[0];
const defaultLanguage = 'ja';

const BLITZCRANK_ASSET = [
  {
    name:  'Formal',
    value: 'https://dekki-production.s3-ap-northeast-1.amazonaws.com/assets/broadcast/games/league-of-legends/game-overlay/blitzcrank/ljl_ai-blitzcrank_formal.gif',
  },
  {
    name: 'Flip-Flop',
    value: 'https://dekki-production.s3-ap-northeast-1.amazonaws.com/assets/broadcast/games/league-of-legends/game-overlay/blitzcrank/ljl_ai-blitzcrank_flip-flop.gif',
  },
  {
    name:  'Surprise',
    value: 'https://dekki-production.s3-ap-northeast-1.amazonaws.com/assets/broadcast/games/league-of-legends/game-overlay/blitzcrank/ljl_ai-blitzcrank_surprise.gif',
  },
  {
    name:  'Thug Life',
    value: 'https://dekki-production.s3-ap-northeast-1.amazonaws.com/assets/broadcast/games/league-of-legends/game-overlay/blitzcrank/ljl_ai-blitzcrank_thug-life.gif',
  },
  {
    name:  'Mia',
    value: 'https://dekki-production.s3-ap-northeast-1.amazonaws.com/assets/broadcast/games/league-of-legends/game-overlay/blitzcrank/ljl_ai-blitzcrank_mia.gif',
  },
];

export const JSON_location = {
  ljl: {
      seasons: {
          '2019': [
              {
                  name: 'spring',
                  url: 'https://cdn.dekki.com/nocache/cgpcData/leagues/ljl/2019/spring.json',
              },
              {
                  name: 'summer',
                  url: 'https://cdn.dekki.com/nocache/cgpcData/leagues/ljl/2019/summer.json',
              },
          ],
          '2020': [
              {
                  name: 'spring',
                  url: 'https://cdn.dekki.com/nocache/cgpcData/leagues/ljl/2020/spring.json',
              },
              {
                  name: 'summer',
                  url: 'https://cdn.dekki.com/nocache/cgpcData/leagues/ljl/2020/summer.json',
              },
          ],
          '2021': [
              {
                  name: 'spring',
                  url: 'https://cdn.dekki.com/nocache/cgpcData/leagues/ljl/2021/spring.json',
              },
              {
                name: 'summer',
                url: 'https://cdn.dekki.com/nocache/cgpcData/leagues/ljl/2021/summer.json',
            },
          ],
          '2022': [
              {
                  name: 'spring',
                  url: 'https://cdn.dekki.com/nocache/cgpcData/leagues/ljl/2022/spring.json',
              },
              {
                name: 'summer',
                url: 'https://cdn.dekki.com/nocache/cgpcData/leagues/ljl/2022/summer.json',
            },
          ],
          '2023': [
            {
                name: 'spring',
                url: 'https://cdn.dekki.com/nocache/cgpcData/leagues/ljl/2023/spring.json',
            },
            {
              name: 'summer',
              url: 'https://cdn.dekki.com/nocache/cgpcData/leagues/ljl/2023/summer.json',
            },
        ],
      },
  },
  academy: {
    seasons: {
        '2021': [
            {
                name: 'spring',
                url: 'https://cdn.dekki.com/nocache/cgpcData/leagues/academyleague/2021/spring.json',
            },
            {
              name: 'summer',
              url: 'https://cdn.dekki.com/nocache/cgpcData/leagues/academyleague/2021/summer.json',
          },
        ],
        '2022': [
          // {
          //     name: 'spring',
          //     url: 'https://cdn.dekki.com/nocache/cgpcData/leagues/academyleague/2022/spring.json',
          // },
          {
            name: 'summer',
            url: 'https://cdn.dekki.com/nocache/cgpcData/leagues/academyleague/2022/summer.json',
          },
        ],
        '2023': [
          {
            name: 'summer',
            url: 'https://cdn.dekki.com/nocache/cgpcData/leagues/academyleague/2023/summer.json',
          },
        ],
    },
},
  ljlallstar: {
      seasons: {
          '2019': [{
              name: 'spring',
              url: 'https://cdn.dekki.com/nocache/cgpcData/leagues/ljlallstar/2019/spring.json',
          },
          {
              name: 'summer',
              url: 'https://cdn.dekki.com/nocache/cgpcData/leagues/ljlallstar/2019/summer.json',
          }],
          '2021': [{
            name: 'spring',
            url: 'https://cdn.dekki.com/nocache/cgpcData/leagues/ljlallstar/2021/spring.json',
        }],
      },

  },
  scoutinggrounds: {
      seasons: {
          '2019': [{
              name: 'spring',
              url: 'https://cdn.dekki.com/nocache/cgpcData/leagues/scoutinggrounds/2019/spring.json',
          },
          {
              name: 'summer',
              url: 'https://cdn.dekki.com/nocache/cgpcData/leagues/scoutinggrounds/2019/summer.json',
          }],
          '2020': [{
              name: 'fall',
              url: 'https://cdn.dekki.com/nocache/cgpcData/leagues/scoutinggrounds/2020/fall.json',
          }],
          '2021': [{
            name: 'fall',
            url: 'https://cdn.dekki.com/nocache/cgpcData/leagues/scoutinggrounds/2021/fall.json',
        }],
      },
  },
  msi: {
      seasons: {
        '2021': [
          {
              name: 'rumble',
              url: 'https://cdn.dekki.com/nocache/cgpcData/leagues/msi/2021/rumble.json',
          }, {
              name: 'groups',
              url: 'https://cdn.dekki.com/nocache/cgpcData/leagues/msi/2021/groups.json',
          }, {
            name: 'Knockout',
            url: 'https://cdn.dekki.com/nocache/cgpcData/leagues/msi/2021/play_in_knockout.json',
        },
      ],
          '2019': [
              {
                  name: 'play_in_groups',
                  url: 'https://cdn.dekki.com/nocache/cgpcData/leagues/msi/2019/play_in_groups.json',
              }, {
                  name: 'play_in_knockout',
                  url: 'https://cdn.dekki.com/nocache/cgpcData/leagues/msi/2019/play_in_knockout.json',
              }, {
                  name: 'groups',
                  url: 'https://cdn.dekki.com/nocache/cgpcData/leagues/msi/2019/groups.json',
              }, {
                  name: 'elim',
                  url: 'https://cdn.dekki.com/nocache/cgpcData/leagues/msi/2019/elim.json',
              },
          ],
          '2018': [
              {
                  name: 'play_in_groups',
                  url: 'https://cdn.dekki.com/nocache/cgpcData/leagues/msi/2018/play_in_groups.json',
              }, {
                  name: 'play_in_knockout',
                  url: 'https://cdn.dekki.com/nocache/cgpcData/leagues/msi/2018/play_in_knockout.json',
              }, {
                  name: 'groups',
                  url: 'https://cdn.dekki.com/nocache/cgpcData/leagues/msi/2018/groups.json',
              }, {
                  name: 'elim',
                  url: 'https://cdn.dekki.com/nocache/cgpcData/leagues/msi/2018/elim.json',
              },
          ],
          '2022': [
            {
              name: 'groups',
              url: 'https://cdn.dekki.com/nocache/cgpcData/leagues/msi/2022/groups.json',
            },
            {
                name: 'rumble',
                url: 'https://cdn.dekki.com/nocache/cgpcData/leagues/msi/2022/rumble.json',
            },
            {
              name: 'Knockout',
              url: 'https://cdn.dekki.com/nocache/cgpcData/leagues/msi/2022/play_in_knockout.json',
          },
        ],
        '2023': [
          {
            name: 'play_in_stage',
            url: 'https://cdn.dekki.com/nocache/cgpcData/leagues/msi/2023/play_in_stage.json',
          },
          {
            name: 'bracket',
            url: 'https://cdn.dekki.com/nocache/cgpcData/leagues/msi/2023/bracket.json',
          }
        ],
      },
  },
  worlds: {
      seasons: {
          '2019': [
              {
                  name: 'play_in_groups',
                  url: 'https://cdn.dekki.com/nocache/cgpcData/leagues/worlds/2019/play_in_groups.json',
              }, {
                  name: 'play_in_knockout',
                  url: 'https://cdn.dekki.com/nocache/cgpcData/leagues/worlds/2019/play_in_knockout.json',
              }, {
                  name: 'groups',
                  url: 'https://cdn.dekki.com/nocache/cgpcData/leagues/worlds/2019/groups.json',
              }, {
                  name: 'elim',
                  url: 'https://cdn.dekki.com/nocache/cgpcData/leagues/worlds/2019/elim.json',
              },
          ],
          '2020': [
              {
                  name: 'play_in_groups',
                  url: 'https://cdn.dekki.com/nocache/cgpcData/leagues/worlds/2020/play_in_groups.json',
              }, {
                  name: 'play_in_knockout',
                  url: 'https://cdn.dekki.com/nocache/cgpcData/leagues/worlds/2020/play_in_knockout.json',
              }, {
                  name: 'groups',
                  url: 'https://cdn.dekki.com/nocache/cgpcData/leagues/worlds/2020/groups.json',
              }, {
                  name: 'elim',
                  url: 'https://cdn.dekki.com/nocache/cgpcData/leagues/worlds/2020/elim.json',
              },
          ],
          '2021': [
            {
                name: 'play_in_groups',
                url: 'https://cdn.dekki.com/nocache/cgpcData/leagues/worlds/2021/play_in_groups.json',
            }, {
                name: 'play_in_knockout',
                url: 'https://cdn.dekki.com/nocache/cgpcData/leagues/worlds/2021/play_in_knockout.json',
            }, {
                name: 'groups',
                url: 'https://cdn.dekki.com/nocache/cgpcData/leagues/worlds/2021/groups.json',
            }, {
                name: 'elim',
                url: 'https://cdn.dekki.com/nocache/cgpcData/leagues/worlds/2021/elim.json',
            },
        ],
        '2022': [
            {
                name: 'play_in_groups',
                url: 'https://cdn.dekki.com/nocache/cgpcData/leagues/worlds/2022/play_in_groups.json',
            }, {
                name: 'play_in_knockout',
                url: 'https://cdn.dekki.com/nocache/cgpcData/leagues/worlds/2022/play_in_knockout.json',
            }, {
                name: 'groups',
                url: 'https://cdn.dekki.com/nocache/cgpcData/leagues/worlds/2022/groups.json',
            }, {
                name: 'elim',
                url: 'https://cdn.dekki.com/nocache/cgpcData/leagues/worlds/2022/elim.json',
            },
        ],
        '2023': [
          {
              name: 'play_in',
              url: 'https://cdn.dekki.com/nocache/cgpcData/leagues/worlds/2023/play_in.json',
          }, {
              name: 'swiss',
              url: 'https://cdn.dekki.com/nocache/cgpcData/leagues/worlds/2023/swiss.json',
          }, {
              name: 'knockout',
              url: 'https://cdn.dekki.com/nocache/cgpcData/leagues/worlds/2023/knockout.json',
          }
        ],
      },
  },
  pubgmjc: {
      seasons: {
          '2019': [
              {
              name: 'spring',
              url: 'https://cdn.dekki.com/nocache/cgpcData/leagues/pubgmjc/2019/spring.json',
              },
              {
              name: 'fall',
              url: 'https://cdn.dekki.com/nocache/cgpcData/leagues/pubgmjc/2019/fall.json',
              },

          ],

      },
  },
};


export {
  defaultPatch,
  positions,
  lol_positions,
  positionsArr,
  positionsNameValue,
  dragons,
  teams,
  // activeTeams,
  colors,
  // players,
  // playersArr,
  // activePlayers,
  rounds,
  getRoundName,
  getMatchupName,
  // getPlayerByValue,
  // getTeamFromSummonerName,
  matches,
  tournamentRealms,
  seasons,
  leagues,
  leagueName,
  splits,
  splitName,
  games,
  defaultLanguage,
  defaultTournamentRealm,
  defaultSeason,
  defaultLeague,
  defaultSplit,
  defaultGame,
  BLITZCRANK_ASSET,
};
