import React from 'react'
// import PropTypes from 'prop-types'
import { get } from 'lodash';

import { /* send, */ subscribe } from '../../../services/api';
import { i18n } from '../../i18n';
import { updateScreenStatus } from '../../../util/controllerUtils';

import ControlContainer from '../../Controller/ControlContainer';
import Button from '../../Button';
import PropTypes from 'prop-types';

function ChampionPoolControl(props) {

    const [status, setStatus] = React.useState('close');
    const [playState, setPlayState] = React.useState(false);
    const [showState, setShowState] = React.useState(false);
    const [championPoolState, setChampionPoolState] = React.useState([]);

    React.useEffect(() => {
        subscribe('control-championPool', null, response => {
            setStatus(response.status);
            if(response.status === 'open'){
              !playState && setPlayState(true)
            }
            if (response.data) {
                setShowState(response.data.championPoolVisible);
            };
          }, props.cgpc);
    }, []);

    React.useEffect(() => {
        if(get(championPoolState, '', []).length){
            setStatus('ready');
        }
    }, [ championPoolState ]);

    const getSendData = () => {
      const championPool = get(window, 'MainData.championPool.championPool', []);
      setChampionPoolState(championPool);
      return({
        championPool,
        teamTricode : get(window, 'MainData.championPool.teamTricode',''),
        teamColor : get(window, 'MainData.championPool.teamColor',''),
        playerName : get(window, 'MainData.championPool.playerName',''),
      });
    }

    const onPlay = () => {
        // console.log('onPlay');
        const sendData = getSendData();
        if(
          get(sendData, 'championPool', []).length &&
          get(sendData, 'teamColor.main') &&
          sendData.teamTricode &&
          sendData.playerName
        ){
          setPlayState(true);
          updateScreenStatus('championPool', 'open', {
              championPoolVisible: showState,
              ...sendData,
          }, null, props.cgpc);
        }
        else {
          setStatus('invalid');
          updateScreenStatus('championPool', 'invalid', undefined, null, props.cgpc);
        }
   }

   const onClose = () => {
    // console.log('onClose')
    showState && setShowState(false);
    setPlayState(false);
    setStatus('close')
    updateScreenStatus('championPool', 'close', null, null, props.cgpc);
   }

   const onShow = () => {
    // console.log('onShow')
    setShowState(true);
    const sendData = getSendData();
    if(
      get(sendData, 'championPool', []).length &&
      get(sendData, 'teamColor.main') &&
      sendData.teamTricode &&
      sendData.playerName
    ){
      updateScreenStatus('championPool', 'open', {
        championPoolVisible: true,
        ...sendData,
      }, null, props.cgpc);
    }
   }

   const onHide = () => {
    // console.log('onHide')
    setShowState(false);
    updateScreenStatus('championPool', 'open', {
        championPoolVisible: false,
        championPool: championPoolState,
    }, null, props.cgpc);
   }

    return (
        <ControlContainer style={{display: props.display ? 'none': 'block', overflow: 'visible'}} status={status} >
        <div className="control__panel">
          <h2 className="control__title">
            <i className="fas fa-swimming-pool"></i> {i18n().pages.championPool}
          </h2>
          <Button
          controllerSide={!props.screen1}
            action={onPlay}
            text={i18n().general.open}
            icon="fas fa-play-circle"
            disabled={status === 'open' && true}
          />
          <Button
          controllerSide={!props.screen1}
            action={onClose}
            text={i18n().general.close}
            icon="fas fa-stop-circle"
            disabled={!(status === 'open') && true}
          />
        </div>
        {(playState && <div className={'control-extra'} style={{zIndex: 6}}>
            <div className="pill">
              {showState
                ? [
                    <span key="1a" className="pill__span--highlighted">
                      {i18n().controller.viewable.true}
                    </span>,
                    <Button controllerSide={!props.screen1} key="1b" text="Hide" style={{height: '30px', borderRadius: '5px'}} action={onHide} />,
                  ]
                : [
                    <span key="2a">{i18n().controller.viewable.false}</span>,
                    <Button controllerSide={!props.screen1} key="2b" text="Show" style={{height: '30px', borderRadius: '5px'}} action={onShow} />,
                  ]}
            </div>
        </div>) || null}
      </ControlContainer>
    )
}

ChampionPoolControl.propTypes = {
    cgpc: PropTypes.string,
    display: PropTypes.bool,
    screen1: PropTypes.bool,
}

export default ChampionPoolControl

