export default {
  controller: {
    buttons: {
        update: '設定',
        toggle: '表示中/非表示',
        toggleVisibility: '表示中/非表示',
        set: 'セット',
        reset: 'リセット Firebase',
        fetch: 'Fetch Firebase',
        unlock: 'デバッグ非表示',
        lock: 'デバッグ表示',
    },
    countdown: {
        default: 'Default',
    },
    viewable: {
        true: '表示中',
        false: '非表示',
    },
    gamescreen: {
      topBar: 'トップバー',
      inGameTimer: 'インゲームタイマー',
      vsOverlay: 'VS画像（試合開始時）',
      sponsor: 'スポンサー',
      score: '勝敗スコア',
      jungleRoute: 'Jungleルート',
      playersRunes: 'ルーン',
      goldPerPlayer: 'プレイヤーゴールド',
      goldDifference: 'チームゴールド差',
      visionControl: '視界支配率',
      drakeTokens: 'ドラゴンマーク',
      damageDealt: '与ダメージ',
      turretsPlate: 'タワープレートゴールド',
      junglerProximity: 'Jungler Proximity',
      championsXP: 'チャンピオンズXPレベル',
      infoBox: 'インフォボックス',
    },
  },
  errors: {
    dataMalformed: 'データフォーマットが間違いです！',
    gameNotComplete: '試合はまだ終わっていない！',
    gameNotFound: '試合データが見つからない！',
    noChampList: 'チャンピオンリスト取れていない。インタネット接続を確認下さい',
    fujitsuAPIError: "Fujitsu API 接続エラー",
    RiotRealTimeAPIError: "Riot Realtime API 接続エラー",
    listEmpty: 'ゲームが見つかりません',
  },
  general: {
    FinishedGames: '終了したゲーム',
    additionalData: '追加データの取得',
    additionalDataLoad: 'データを取得する',
    additionalDataWarning: '※気軽くコミットしないでください。ゲームが追加されたら、永久に統計が保存される',
    additionalDataWarning2: '※処理は～２０秒ぐらいかかる',
    ads: 'adsJA',
    all: '全て',
    analyst: 'アナリスト',
    ASSISTS: 'ASSISTS',
    auto: '自動',
    autoLoad: 'を自動更新',
    autoLoadSchedule: 'スケジュールを読み込む',
    autoLoadPlayerStats: 'プレイヤーデータを読み込む',
    baron: 'バロン',
    ban: 'バン',
    banner: 'バナー',
    bans: 'バン',
    behind: 'behindJA',
    blankScreen: 'Blank Screen',
    blue: 'ブルー',
    blueSide: 'ブルーサイド',
    blueSideCoach: 'ブルーサイドコーチ名',
    blueSideRecord: 'ブルーサイドリーグ勝敗',
    blueSideScore: 'ブルーサイド勝利数',
    box: 'box',
    caster: 'キャスター',
    casters: 'キャスター',
    castersPrediction: "Caster's Predictions",
    center: '中央',
    centerHeader: '中央ボックスヘッダー',
    champion: 'チャンピオン',
    championPool: 'Champion Pool',
    champions: 'チャンピオン',
    clear: 'クリア',
    clearTwitter: 'Twitterをクリア',
    close: '閉じる',
    coach: 'コーチ',
    collectTweets: 'ツイートを集計',
    commitAndGetStats: 'データをセット',
    comparison: '比較',
    controller: 'コントローラー',
    contentEditable:'編集可能',
    count: 'count',
    countdown: 'カウントダウン',
    counts: 'countsJA',
    current: 'currentJA',
    currentController: '画面',
    currentMatch: '現在のマッチ',
    currentPage: '配信画面',
    currentSetting: '今の設定',
    damageDealt: 'チャンピオン与ダメージ',
    data: 'データ',
    dataWarning: '※現在はリアルタイムデータが接続されていませんので使用不可です',
    dataApiListener: 'APIコネクター',
    dataInGameTips: 'In-Game Tips (Side)',
    dataInGameTipsCustom: 'Custom In-Game Tips (Side)',
    dataGameTimer: 'ゲーム画面 ー タイマー',
    dataGameDrakeTokens: 'ドラゴンマーク',
    dataBackground: 'バックグラウンド',
    dataWaitingMessage: "Waiting Message",
    dataDraftOrder: "Draft Order",
    DPM: 'DPM',
    DisplayPhotos: "写真の表示",
    dummyVideo: 'テスト背景',
    enter: 'を記入',
    fan: 'ファン',
    fanTweetWarning: '※ツイートを取得、選択したあとに押してください',
    fight: 'fightJA',
    file: 'ファイル',
    final: 'ファイナル',
    flip: '入れ替え',
    flipMatchup: 'サイドの入れ替え',
    game: 'ゲーム',
    gameBreakdown: 'ゲームブレイクダウン',
    gameFlowPrediction: 'AI 未来予想',
    gameId: 'ゲームID',
    gameList: 'ゲームリスト',
    gameRealm: 'サーバー',
    games: 'ゲーム',
    gamesBehind: 'ゲーム差',
    gamePlayedAt: 'ゲーム開始時間',
    get: '取得',
    getFanTweets: 'ファン応援ツイートを取得',
    getVoteCounts: '勝利予想データ取得',
    getTweets: 'Tweetを集計',
    header: 'ヘッダー',
    hide: '隠す',
    hideCasters: 'キャスターテロップ非表示',
    hideFanTweets: 'twitter応援メッセージを非表示',
    hideInterviewer: 'インタビューアーテロップ非表示',
    hideStats: '選手データ非表示',
    information: '詳細',
    inGameTipInfobox: 'In-Game Tip (infobox)',
    interview: 'インタビュー',
    interviewee: '選手',
    interviewer: 'インタビューアー',
    invalidNumbers : '無効入力（数字のみを入力してください）',
    itemsPurchaseHistory: 'Items Purchase History',
    json: 'JSONファイルを選択',
    japaneseCharacters: '名前に日本語が含まれている場合はチェックを入れてください',
    jungleRoute: 'ジャングルルート',
    KDA: 'KDA',
    keyPlayers: 'キープレイヤー',
    keyPlayersGameScreen: 'ゲーム画面 - キープレイヤー',
    keyTeamsGameScreen: 'Game Screen - Key Teams',
    KILLS: 'KILLS',
    KP: 'KP',
    language: '言語',
    layout: 'レイアウト',
    leaderboard: 'リーダーボード',
    league: 'リーグ',
    left: '左',
    leftShort: '左',
    leftTeam: '左チーム',
    leftCaster: '実況',
    load: '更新',
    loadBanpick: 'バンピックをセット',
    loadControllerData: 'コントローラのデータをロード',
    loadDetails: '詳細をロード',
    loadGameBreakdown: 'ゲーム詳細データをセット',
    loadGameScreen: 'ゲーム画面をセット',
    loadHome: 'ホームをセット',
    loading: 'loading...',
    loadKeyPlayers: '注目選手をセット',
    loadLeaderboard: '個人成績をセット',
    loadMatchup: 'マッチアップをセット',
    loadNextMatch: '次のマッチをセット',
    loadNextRound: '次のラウンドをセット',
    loadPlayerMatch: 'プレイヤー・オブ・ザ・マッチをセット',
    loadResults: 'マッチ結果をセット',
    loadSchedule: 'スケジュールをセット',
    loadStandings: '順位表をセット',
    loadStarters: 'スターターをセット',
    loadTelops: '名前テロップをセット',
    loadCurrent: 'loadCurrentJA',
    loss: 'lossJA',
    lose: 'loseJA',
    loser: 'loserJA',
    match: 'マッチ',
    matches: 'マッチ',
    matchup: 'マッチアップ',
    menu: 'メニュー',
    mode: 'モード',
    mythicItemInfobox: 'Mythic Item Infobox',
    mythicItemSide: 'Mythic Item Side Popout',
    playersMythicItem: 'Players Mythic Item',
    mvpVote: 'MVP投票',
    name: '名前',
    new: '新しい',
    next: '次へ',
    nextMatch: '次のマッチ',
    nextMatchUp: '次のマッチアップ',
    nextRound: '次のラウンド',
    nextTweet: '次のツイートを表示',
    none: 'なし',
    ok: 'OK',
    old: '古い',
    one: 'oneJA',
    open: '開く',
    overlays: 'オーバーレイ',
    page: 'ページ',
    patch: 'パッチ',
    picks: 'ピック',
    platform: 'プラットフォームID',
    play: '配信アニメション開始',
    playBlueSide: 'ブルーサイド配信表示',
    playRedSide: 'レッドサイド配信表示',
    player: '選手',
    players: '選手',
    playerTweetsWarning: '※「Twitterのカウントを開始」ボタンを押すと、3分間各ハッシュタグのツイート数を収集します。ボタンを押す前のツイート数は収集されません。',
    preview: 'プレビューをロード',
    recalculate: '再計算する',
    record: 'マッチ WIN-LOSE',
    red: 'レッド',
    redSide: 'レッドサイド',
    redSideCoach: 'レッドサイドコーチ名',
    redSideRecord: 'レッドサイドリーグ勝敗',
    redSideScore: 'レッドサイド勝利数',
    reload: '配信画面を更新',
    reloadData: 'データをリロード',
    reset: 'リセット',
    result: '結果',
    results: '結果',
    resultsList: '結果 リスト',
    resync: '再同期',
    refresh: 'リフレッシュ',
    retry: 'リトライ',
    retryPlayerTweets: 'Twitterカウント更新',
    right: '右',
    rightShort: '右',
    rightTeam: '右チーム',
    rightCaster: '解説',
    role: 'ポジション',
    roleComparison: 'レーン毎リアルタイムデータ',
    round: 'ラウンド',
    roundMatches: 'ラウンドのマッチ',
    roundMatchesNext: '次のラウンドのマッチ',
    save: '保存',
    saveControllerData: 'コントローラのデータを保存',
    schedule: 'スケジュール',
    scheduleTeamless: 'スケジュール Teamless',
    score: 'マッチ結果',
    scores: 'スコア',
    searchFor: '検索ワード',
    season: 'シーズン',
    select: '選択してください',
    selectTeam: 'チームを選択',
    set: '設定',
    setAllData: '全データをセット',
    setFanTweets: 'ファン応援ツイートを設定',
    setInfoBoxRunes: '中央ボックスの情報を設定　ルーン',
    setInfoBoxTeamFight: '中央ボックスの情報を設定　チームファイト',
    setInfoBoxTwitter: '中央ボックスの情報を設定　twitter勝利予想',
    setGame: 'ゲーム情報',
    setMatch: 'LJLマッチ情報',
    setMatchInCGPC: 'マッチ情報を設定',
    setMatchScore: 'マッチ結果を設定',
    setMatchup: 'マッチアップを設定',
    setNextMatch: '次のマッチを設定',
    setNextRound: '次のラウンドを設定',
    setPatch: 'パッチ情報',
    setRound: 'LJLラウンド情報',
    setSchedule: 'スケジュールを 設定',
    setScope: 'set data scopeJA',
    setScore: '結果を設定',
    setScores: '結果を設定',
    setStarters: 'スターターを設定',
    setTerm: 'set termJA',
    setTime: 'カウントダウン設定',
    settings: '設定',
    setTweets: 'Tweetをセット',
    setup: 'セットアップ',
    show: '表示中',
    showCasters: 'キャスターテロップ表示',
    showChamps: 'チャンピオンを表示',
    showFanTweets: 'twitter応援メッセージを表示',
    showInterviewer: 'インタビューアーと選手テロップ表示',
    showInterviewerOnly: 'インタビューアーのみテロップ表示',
    showMatchupTwitter: 'twitter勝利予想テロップを表示',
    showTwitter: 'Twitterを表示',
    sidebarMode: 'サイドバーモード',
    split: 'スプリット',
    sponsors: 'スポンサーボックス',
    standings: 'スタンディング',
    starters: 'スターター',
    startTwitterCount: 'Twitterのカウントを開始',
    startTime: 'Start Time',
    stats: '選手データ表示',
    switchSides: 'サイドチェンジ',
    switchScreens: 'スクリーンチェンジ',
    team: 'チーム',
    team100: 'レッドサイド',
    team200: 'ブルーサイド',
    teamFight: '集団戦',
    telops: 'テロップ',
    testMode: 'テストモード',
    theme: 'テーマ',
    time: '時間',
    timeline: 'タイムライン',
    timerData: 'タイマー',
    timer: 'タイマー (ネクストマッチ)',
    timer2: 'タイマー (サイド)',
    timerCurrentSetting: 'Current Setting',
    timerCountdownFor: 'カウントダウン（この時間ぶん）',
    timerCountdownTo: 'カウントダウン（この時刻まで）',
    timerText: 'カウントダウンテキスト',
    title: '役職',
    toggle: '表示/非表示',
    toggleBaron: 'バロンパワープレイ結果ボックス表示/非表示',
    toggleFanTweets: 'twitter応援メッセージを表示/非表示',
    toggleInfoBox: '中央ボックスを表示/非表示',
    toggleHeader: '中央ボックスヘッダーを表示/非表示',
    toggleSponsors: 'スポンサーボックスを表示/非表示',
    toggleTime: 'カウントダウン表示/非表示',
    turretsPlate: 'タワープレートゴールド',
    twitter: 'Twitter',
    twitterPlayers: 'Twitter - 選手',
    twitterTeams: 'Twitter - チーム',
    twitterTerm: 'twitter termJA',
    twitterTweets: 'Twitter - ツイート',
    tweet: 'Tweet',
    tweets: 'Tweet',
    tweetDownload: 'ツイートを表示',
    tweetCount: '選択ツイート数',
    tweetEditNumber: 'ツイート数を変更',
    tweetSaveNewNumber: 'ツイート数を保存',
    two: 'two',
    useTimePeriod: '以下の時期を利用',
    versus: 'vs',
    version: 'バーション',
    vs: 'VS',
    vsBanner: 'ゲーム開始対戦テロップ',
    vote: '投票',
    votes: '投票',
    who: 'だれ',
    whoWillWin: '勝利予想',
    whoWillWinAI: 'Who will win (AI)',
    whoWillWinAIGraph: 'Who will win Graph (AI)',
    will: 'willJA',
    win: 'winJA',
    winLoss: '勝 - 敗',
    winner: '優勝者',
    winning: '勝者',
    winningTeam: 'マッチ勝利チーム',
  },
  pages: {
    banPickV2: "Ban & Pick (V2)",
    castersPrediction: "Caster's Predictions",
    castersPredictionOverlay: "Caster's Predictions Overlay",
    championPool: "Champion Pool",
    draftOrder: "Draft Order",
    home: 'ホーム',
    starters: 'スターター',
    starters_v2: 'スターター (V2)',
    standings: 'スタンディング',
    standingsV2: 'スタンディング (V2)',
    leaderboard: 'リーダーボード',
    schedule: 'スケジュール',
    ScheduleV2: 'スケジュール (V2)',
    scheduleNext: 'スケジュール - Next',
    scheduleTeamless: 'スケジュール Teamless',
    keyPlayers: 'キープレイヤー',
    keyPlayersV2: 'キープレイヤー (V2)',
    matchup: 'マッチアップ',
    matchupV2: 'マッチアップ (V2)',
    matchPlayer: 'プレイヤー・オブ・ザ・マッチ',
    matchPlayerV2: 'プレイヤー・オブ・ザ・マッチ (V2)',
    nextMatchInfo: '選手画像 - ネクストマッチ情報',
    nextRound: 'ネクストラウンド',
    nextMatch: 'ネクストマッチ',
    playermatch: 'プレイヤー・オブ・ザ・マッチ',
    results: 'リザルト',
    resultsList: 'リザルト・リスト',
    gamescreen: 'ゲーム画面',
    scheduleInfoSideBar: 'サイド バー - スケジュール インフォ',
    telops: '名前テロップ',
    banpick: 'バンピック',
    gameBreakdown: 'ブレイクダウン',
    reset: 'リセット',
    waitingScreen: 'ウェイティングスクリーン',
 },
  roles: {
    TOP: 'TOP',
    JUNGLE: 'JUNGLE',
    MID: 'MID',
    ADC: 'ADC',
    SUPPORT: 'SUPPORT',
  },

  runes: {
    title: 'ルーン',
    primary: 'メインパス',
    secondary: 'サブパス',
    keystone: 'キーストーン',
    rune: 'ルーン',
    runes: {
      precision: {
        title: '栄華',
        keystones: {
          presstheattack: 'プレスアタック',
          lethaltempo: 'リーサルテンポ',
          fleetfootwork: 'フリートフットワーク',
        },
        runes: {
          set1: {
            overheal: 'オーバーヒール',
            triumph: '凱旋',
            presenceofmind: '冷静沈着',
          },
          set2: {
            legendalacrity: 'レジェンド：迅速',
            legendtenacity: 'レジェンド：強靭',
            legendbloodline: 'レジェンド：血脈',
          },
          set3: {
            coupdegrace: '最期の慈悲',
            cutdown: '切り崩し',
            laststand: '背水の陣',
          },
          setAll: {
            overheal: 'オーバーヒール',
            triumph: '凱旋',
            presenceofmind: '冷静沈着',
            legendalacrity: 'レジェンド：迅速',
            legendtenacity: 'レジェンド：強靭',
            legendbloodline: 'レジェンド：血脈',
            coupdegrace: '最期の慈悲',
            cutdown: '切り崩し',
            laststand: '背水の陣',
          },
        },
      },
      domination: {
        title: '覇道',
        keystones: {
          electrocute: '電撃',
          predator: 'プレデター',
          darkharvest: '魂の収穫',
        },
        runes: {
          set1: {
            cheapshot: '追い討ち',
            tasteofblood: '血の味わい',
            suddenimpact: 'サドンインパクト',
          },
          set2: {
            zombieward: 'ソンビワード',
            ghostporo: 'ゴーストポロ',
            eyeballcollection: '目玉コレクター',
          },
          set3: {
            treasurehunter: '貪欲な賞金首狩り',
            ingenioushunter: '巧妙な賞金首狩り',
            relentlesshunter: '執拗な賞金首狩り',
          },
          setAll: {
            cheapshot: '追い討ち',
            tasteofblood: '血の味わい',
            suddenimpact: 'サドンインパクト',
            zombieward: 'ソンビワード',
            ghostporo: 'ゴーストポロ',
            eyeballcollection: '目玉コレクター',
            treasurehunter: '貪欲な賞金首狩り',
            ingenioushunter: '巧妙な賞金首狩り',
            relentlesshunter: '執拗な賞金首狩り',
          },
        },
      },
      sorcery: {
        title: '魔道',
        keystones: {
          summonaery: 'エアリー召喚',
          arcanecomet: '秘儀の彗星',
          phaserush: 'フェイズラッシュ',
        },
        runes: {
          set1: {
            nullifyingorb: '魔除けのオーブ',
            manaflowband: 'マナフローバンド',
            theultimatehat: 'アルティメットハット',
          },
          set2: {
            transcendence: '至高',
            celerity: '追い風',
            absolutefocus: '英気集中',
          },
          set3: {
            scorch: '追い火',
            waterwalking: '水走り',
            gatheringstorm: '強まる嵐',
          },
          setAll: {
            nullifyingorb: '魔除けのオーブ',
            manaflowband: 'マナフローバンド',
            theultimatehat: 'アルティメットハット',
            transcendence: '至高',
            celerity: '追い風',
            absolutefocus: '英気集中',
            scorch: '追い火',
            waterwalking: '水走り',
            gatheringstorm: '強まる嵐',
          },
        },
      },
      resolve: {
        title: '不滅',
        keystones: {
          graspoftheundying: '不死者の握撃',
          aftershock: 'アフターショック',
          guardian: 'ガーディアン',
        },
        runes: {
          set1: {
            unflinching: '気迫',
            demolish: '打ちこわし',
            fontoflife: '生命の泉',
          },
          set2: {
            ironskin: 'アイアンスキン',
            mirrorshell: 'ミラーシェル',
            conditioning: '心身調整',
          },
          set3: {
            overgrowth: '超成長',
            revitalize: '生気付与',
            secondwind: '息継ぎ',
          },
          setAll: {
            unflinching: '気迫',
            demolish: '打ちこわし',
            fontoflife: '生命の泉',
            ironskin: 'アイアンスキン',
            mirrorshell: 'ミラーシェル',
            conditioning: '心身調整',
            overgrowth: '超成長',
            revitalize: '生気付与',
            secondwind: '息継ぎ',
          },
        },
      },
      inspiration: {
        title: '天啓',
        keystones: {
          unsealedspellbook: '解放の魔道書',
          glacialaugment: 'グレイシャルオーグメント',
          kleptomancy: 'ねこばば',
        },
        runes: {
          set1: {
            hextechflashtraption: 'ヘクステックフラッシュネーター',
            biscuitdelivery: 'ビスケットデリバリー',
            perfecttiming: 'パーフェクトタイミング',
          },
          set2: {
            magicalfootwear: '魔法の靴',
            futuresmarket: "先行投資",
            miniondematerializer: 'ミニオン吸収装置',
          },
          set3: {
            cosmicinsight: '宇宙の英知',
            approachvelocity: '疾駆',
            celestialbody: '聖体',
          },
          setAll: {
            hextechflashtraption: 'ヘクステックフラッシュネーター',
            biscuitdelivery: 'ビスケットデリバリー',
            perfecttiming: 'パーフェクトタイミング',
            magicalfootwear: '魔法の靴',
            futuresmarket: "先行投資",
            miniondematerializer: 'ミニオン吸収装置',
            cosmicinsight: '宇宙の英知',
            approachvelocity: '疾駆',
            celestialbody: '聖体',
          },
        },
      },
    },
  },
}
