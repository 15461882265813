import en from './en';
import ja from './ja';

export const i18n = () => {
    const lang = checkLang();
    return (lang === 'en') ? en : ja;
}

const getCookie = (cname) => {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for(var i = 0; i <ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) === ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}

export const checkLang = () => {
    let lang;
    if (getCookie('lang')) {
        lang = getCookie('lang');
    } else {
        const language = navigator.userLanguage || navigator.language;
        lang = (language.substr(0,2) === 'ja') ? 'ja' : 'en';
        document.cookie = `lang=${lang}`;
    }
    return (lang === 'en') ? 'en' : 'ja';
}

export const setLang = lang => {
    document.cookie = `lang=${lang}`;
    return;
}