"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.truncateDecimal = exports.getCountdownUntil = void 0;

var _moment = _interopRequireDefault(require("moment"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var getCountdownUntil = function getCountdownUntil(targetTime) {
  if (targetTime === 0) return 0;
  if ((0, _moment.default)() > (0, _moment.default)(targetTime)) return 0;
  var until = Math.abs((0, _moment.default)().diff((0, _moment.default)(targetTime)) / 1000);
  var hours = Math.floor(until / (60 * 60));
  var minutes = Math.floor(until % (60 * 60) / 60);
  var seconds = Math.floor(until % (60 * 60) % 60);
  hours = hours < 10 ? "0".concat(hours) : hours.toString();
  minutes = minutes < 10 ? "0".concat(minutes) : minutes;
  seconds = seconds < 10 ? "0".concat(seconds) : seconds;

  if (hours === '00') {
    return "".concat(minutes, ":").concat(seconds);
  }

  return "".concat(hours, ":").concat(minutes, ":").concat(seconds);
};

exports.getCountdownUntil = getCountdownUntil;

var truncateDecimal = function truncateDecimal(myNum) {
  if (!myNum) return 0;
  var num = myNum.toString();

  if (num.indexOf('.') !== -1) {
    num = num.slice(0, num.indexOf(".") + 2);
    return Number(num);
  } else return Number(num);
};

exports.truncateDecimal = truncateDecimal;