import styled from 'styled-components';

export const StyleLayoutGamescreen = styled.div`
  width: 80%;
  height: 260px;
  border: 2px white solid;
  display: grid;
  padding: 20px 0 30px 0;
`;

export const StyleLayoutGamescreenPosition = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr 2fr;
  text-align: center;
  span {
    margin-bottom: 15px;
  }
`;

export const StyleLayoutGamescreenPositionButton = styled.div`
  border: ${props => props.isSelected? '4px red solid': '1px white solid'};

  img {
    width: 35px;
    padding:0;
  }
`;



