// REACT
import React from 'react';
import PropTypes from 'prop-types';

const Caster = props => (
  <div className={props.side === 'right' ? "animated animated--fast telops__caster telops__caster--right" : "animated animated--fast telops__caster"}>
    <div className="telops__caster__content">
      <div className="telops__caster__content__title">
        <div className="animated animated--fast telops__caster__content__text">{props.title}</div>
      </div>
      <div className="telops__caster__content__separator"></div>
      <div className="telops__caster__content__name">
        <div className="animated animated--fast telops__caster__content__text">{props.name}</div>
      </div>
    </div>
  </div>
);

Caster.propTypes = {
  name: PropTypes.string,
  side: PropTypes.string,
  title: PropTypes.string,
};

export default Caster