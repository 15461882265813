// REACT
import React from 'react';
import PropTypes from 'prop-types';

const Interviewer = props => (
  <div className="animated animated--fast telops__interviewer">
    <div className="telops__interviewer__content">
      <div className="telops__interviewer__content__name">
        <div className="animated animated--fast telops__interviewer__content__text">{props.name}</div>
      </div>
    </div>
  </div>
);

Interviewer.propTypes = {
  name: PropTypes.string,
};

export default Interviewer