import React from 'react';
import PropTypes from 'prop-types';
import { get, isEqual, findKey } from 'lodash';
import DataContainer from '../DataContainer';
import Picker from '../../Picker';
import Button from '../../Button';
import ChampSelect from '../../ChampSelect';
import ChampionsDict from '../../../services/championService';
import {getPlayerStats, validLeaderboardStats} from '../../../services/leaderboardService';
import { statsDictionary } from '../../../services/keyPlayerService';
import {
    Base,
    StylePlayerSelectionGrid,
    StylePlayerContainer,
    StyleStatsGrid,
    StyleStatsSeparator,
    StylePlayerImg,
    StyleChampionPicker,
    StyleStatsPickerContainer,
} from './styles';

const initialState = {
    mode: 1, // Number of players 1 or 2
    players: ['', ''],
    teams: ['', ''],
    stats: [],
    champion: {},
    playersImage: ['', ''],
    playerLeft: {},
    playerRight: {},
}
class DataGameScreenKeyPlayers extends React.Component {

    static propTypes = {
        teams: PropTypes.object,
        leaderboard: PropTypes.object,
        schedule: PropTypes.object,
    }

    constructor(props){
        super(props);
        this.state = {
            ...initialState,
            teamChoices: this.props.teams,
            leaderboard: this.props.leaderboard,
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        if(nextProps.teams !== this.state.teamChoices || nextProps.leaderboard !== this.state.leaderboard) {
            const state = {...this.state};
            state.leaderboard = nextProps.leaderboard;
            state.teamChoices = nextProps.teams;
            state.mode = '0';
            state.players = ['', ''];
            state.teams = ['', ''];
            state.stats = ['', '', '', ''];
            this.setState(state);
            return true;
        }
        if(nextState.mode !== this.state.mode) {
            const state = {
                ...this.state,
                ...initialState,
                mode: nextState.mode,
            };
            this.setState(state);
            return true;
        };
        if(nextState.mode !== this.state.mode) return true;
        if(nextState.teams !== this.state.teams) return true;
        if(nextState.players !== this.state.players) return true;
        if(nextState.stats !== this.state.stats) return true;
        if(nextState.playerLeft !== this.state.playerLeft) return true;
        if(nextState.playerRight !== this.state.playerRight) return true;
        if(!isEqual(nextState.champion, this.state.champion)) return true;
        return false;
    }

    changeMode = mode => {
        if ((mode === 1 || mode === 2) && mode !== this.state.mode) {
            this.setState({mode});
        } else {
            this.setState({mode: 1});
        }
    }

    updateTeam = (teamTricode, position) => {
        if (!teamTricode || !position) return;
        const teams = [...this.state.teams];
        teams[position] = `${teamTricode}`;
        const players = [...this.state.players];
        players[position] = '';
        const playersImage = [...this.state.playersImage];
        playersImage[position] = '';
        this.setState({
            teams,
            players,
            playersImage,
        });
    }

    updatePlayer = (playerName, position) => {
        if (!playerName || !position) return;
        const players = [...this.state.players];
        const playersImage = [...this.state.playersImage]
        players[position] = `${playerName}`;
        playersImage[position] = this.props.teams[this.state.teams[position]].roster[playerName].images.results;
        this.setState({players, playersImage});
    }

    updateChampion = (ref, championID) => {
        this.setState({
            champion: {
                id: championID,
                idName: get(ChampionsDict, `${championID}.id`, ''),
                name: get(ChampionsDict, `${championID}.englishName`, ''),
                icon: `https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/square/${get(ChampionsDict, `${championID}.id`,'')}.png`,
            },
        });
    }

    updateStats = (statName, position) => {
        if (!statName || position < 0 || position > 4) return;
        const stats = [...this.state.stats];
        stats[position] = `${statName}`;
        this.setState({stats});
    }

    teamsOptions = teams => teams ? Object.keys(teams).map(element => ({value: element, name: element})) : null;

    playersOptions = team => team ? Object.keys(team).map(playerName =>({value: playerName, name: playerName})) : null;

    statsOptions = () => {
        const keys = Object.keys(validLeaderboardStats);
        const values = Object.values(validLeaderboardStats);
        return keys.map((stat, index) => ({value: stat, name: values[index]}));
    }

    filterStats = stats => {
        if(!stats) return null;
        const keys = Object.keys(stats);
        const values = Object.values(stats);
        let filteredStats = [];
        keys.forEach((key, index) => {
            if(key === this.state.stats[0]) {
                filteredStats[0] = values[index];
            }
            if(key === this.state.stats[1]) {
                filteredStats[1] = values[index];
            }
            if(key === this.state.stats[2]) {
                filteredStats[2] = values[index];
            }
            if(key === this.state.stats[3]) {
                filteredStats[3] = values[index];
            }
        });
        return filteredStats;
    }

    checkForm = () => {
        if(!this.state.stats[0] || !this.state.stats[1] || !this.state.stats[2] ||
            !this.state.teams[0] || !this.state.players[0]) {
                return false;
            }
        if((this.state.mode === 1 && this.state.champion.name) ||
        (this.state.mode === 2 && this.state.teams[1] && this.state.players[1])){
            return true;
        }
        return false;
    }

    checkData = () => {
        return(
            (
                this.state.mode === 1 &&
                this.state.playerLeft.stats &&
                this.state.playerLeft.stats.filter(stat => stat).length
            )
            ||
            (
                this.state.mode === 2 &&
                this.state.playerLeft.stats &&
                this.state.playerLeft.stats.filter(stat => stat).length &&
                this.state.playerRight.stats &&
                this.state.playerRight.stats.filter(stat => stat).length
            )
        );
    }

    saveInformation = async () => {
        window.MainData.gameScreenPlayerStats = {
            stats: this.state.stats.map(stat => findKey(statsDictionary, val => val === stat) || stat),
            players: [this.state.playerLeft, this.state.playerRight],
            mode: this.state.mode,
        }
    }

    loadInformation = async () => {
        const schedule = this.props.schedule || null;
        if(!schedule) return null;
        const league = schedule.league;
        const season = schedule.season;
        const split = schedule.split;
        const playerLeft = {
            name: this.state.players[0],
            team: this.state.teams[0],
            images: get(this, `props.teams[${this.state.teams[0]}].images`, undefined),
            teamColors: get(this, `props.teams[${this.state.teams[0]}].color`, undefined),
            teamBGImg: get(this, `props.teams[${this.state.teams[0]}].images.logoBGFull`, undefined),
            teamBGVideoLeft: get(this, `props.teams[${this.state.teams[0]}].videos.keyPlayersLeft`, undefined),
            teamBGVideoRight: get(this, `props.teams[${this.state.teams[0]}].videos.keyPlayersRight`, undefined),
            championIcon: this.state.champion.icon || '',
            championName: this.state.champion.name || '',
            profileImg: this.state.playersImage[0],
            stats: this.filterStats(await getPlayerStats(season, league, split, this.state.players[0].toLowerCase(), this.state.champion.idName).catch(e => {console.log(e)})) || [2, 3, 5,9],
        }
        // here edit players
        let playerRight = {}
        if(this.state.mode === 2){
            playerRight = {
                name: this.state.players[1],
                team: this.state.teams[1],
                images: get(this, `props.teams[${this.state.teams[1]}].images`, undefined),
                teamColors: get(this, `props.teams[${this.state.teams[1]}].color`, undefined),
                teamBGImg: get(this, `props.teams[${this.state.teams[1]}].images.logoBGFull`, undefined),
                teamBGVideoLeft: get(this, `props.teams[${this.state.teams[1]}].videos.keyPlayersLeft`, undefined),
                teamBGVideoRight: get(this, `props.teams[${this.state.teams[1]}].videos.keyPlayersRight`, undefined),
                profileImg: this.state.playersImage[1],
                stats: this.filterStats(await getPlayerStats(season, league, split, this.state.players[1].toLowerCase())) || [2, 3, 5, 9],
            }
        }
        this.setState({playerLeft, playerRight});
    }

    render() {
        return (
            <DataContainer componentSlug="keyPlayersGameScreen" icon="fas fa-star">
                <div className="data-panel__fieldset">
                    <Base>
                    <div className="pill" style={{textAlign: 'center'}}>
                            {
                                this.state.mode === 1 ?
                                [
                                    <span key="1a" className="pill__span--highlighted">{'Single'}</span>,
                                        <Button key="1b"  text={'Dual'} action={() => this.changeMode(2)} />,
                                ] :
                                [
                                    <Button key="2b" text={'Single'} action={() => this.changeMode(1)} />,
                                    <span key="2a" className="pill__span--highlighted">{'Dual'}</span>,
                                ]
                            }
                        </div>
                    <StylePlayerSelectionGrid columns={this.state.mode}>
        {/* Player 1/Left Selector */}
                        <StylePlayerContainer>
                            {(this.state.mode === 2) && <h3>Player Left</h3>}
                            <div>
                                <div className="pill">
                                    <span>Team</span>
                                    <Picker
                                        items={this.teamsOptions(this.state.teamChoices)}
                                        onUpdate={value => this.updateTeam(value, '0')}
                                        preselect={this.state.teams[0] || ''}
                                    />
                                </div>
                                <div className="pill">
                                    <span>Player</span>
                                    <Picker
                                        disabled={this.state.teams[0]}
                                        items={this.playersOptions(get(this, `state.teamChoices[${this.state.teams[0]}].roster`, {}))}
                                        onUpdate={value => this.updatePlayer(value, '0')}
                                        preselect={this.state.players[0] || ''}
                                    />
                                </div>
                                {
                                    (this.state.mode === 1) &&
                                    <StyleChampionPicker className="pill">
                                        <span>Champion</span>
                                        <ChampSelect
                                            items={[]}
                                            assignRef={'championFilter'}
                                            onChampSelect={this.updateChampion}
                                            selected={this.state.champion.id}
                                            disabled={!this.state.teams[0]}
                                        />
                                    </StyleChampionPicker>
                                }
                            </div>
                            {
                                (this.state.playersImage[0] &&
                                <StylePlayerImg alt={'PlayerLeftProfileImg'} src={this.state.playersImage[0]} />) ||
                                <h3>{'No player selected'}</h3>
                            }
                        </StylePlayerContainer>
        {/* Player 2/Right Selector */}
                        {((this.state.mode === 2) &&
                        <StylePlayerContainer>
                            {(this.state.mode === 2) && <h3>Player Right</h3>}
                            <div>
                                <div className="pill">
                                    <span>Team</span>
                                    <Picker
                                        items={this.teamsOptions(this.state.teamChoices)}
                                        onUpdate={value => this.updateTeam(value, '1')}
                                        preselect={this.state.teams[1] || ''}
                                    />
                                </div>
                                <div className="pill">
                                    <span>Player</span>
                                    <Picker
                                        disabled={this.state.teams[1]}
                                        items={this.playersOptions(get(this, `state.teamChoices[${this.state.teams[1]}].roster`, {}))}
                                        onUpdate={value => this.updatePlayer(value, '1')}
                                        preselect={this.state.players[1] || ''}
                                    />
                                </div>
                            </div>
                            {
                                (this.state.playersImage[1] &&
                                <StylePlayerImg alt={'PlayerRightProfileImg'} src={this.state.playersImage[1]} />) ||
                                <h3>{'No player selected'}</h3>
                            }
                        </StylePlayerContainer>) || <div/>}
                        <StyleStatsSeparator/>
                    </StylePlayerSelectionGrid>
                <StyleStatsGrid>
    {/* Stats Selection */}
                    <h3>{'Stats'}</h3>
                    {
                        [0,1,2,3].map((stat, index) => (
                            <StyleStatsPickerContainer key={`stat-choice-${index}`} className="pill">
                                <span>{`Stat ${index + 1}`}</span>
                                <Picker
                                    items={this.statsOptions(this.state.leaderboard)}
                                    onUpdate={value => this.updateStats(value, index)}
                                    preselect={this.state.stats[index] || ''}
                                />
                            </StyleStatsPickerContainer>
                        ))
                    }
    {/* Player 1 Stats */}
                    {this.state.playerLeft && this.state.playerLeft.stats && <StyleStatsSeparator/>}
                    {this.state.playerLeft && this.state.playerLeft.stats && <h3>{'Player Left'}</h3>}
                    {
                        this.state.playerLeft && this.state.playerLeft.stats &&
                        this.state.playerLeft.stats.map((stat, index) => (
                            <div key={`stat-loaded-${index}`} className="pill">
                                {/* <span>{this.state.stats[index]}</span> */}
                                <p title={this.state.stats[index]}>{stat}</p>
                            </div>
                        ))
                    }
    {/* Player 2 Stats */}
                    {this.state.playerRight && this.state.playerRight.stats && <StyleStatsSeparator/>}
                    {this.state.playerRight && this.state.playerRight.stats && <h3>{'Player Right'}</h3>}
                    {
                        this.state.playerRight && this.state.playerRight.stats &&
                        this.state.playerRight.stats.map((stat, index) => (
                            <div key={`stat-loaded-${index}`} className="pill">
                                {/* <span>{this.state.stats[index]}</span> */}
                                <p title={this.state.stats[index]}>{stat}</p>
                            </div>
                        ))
                    }
                </StyleStatsGrid>
    {/* Action Buttons */}
                    <div className="pill">
                        <Button
                            disabled={!this.checkForm()}
                            text={"Load Stats"}
                            action={() => this.loadInformation()}
                        />
                    </div>

                    <div className="pill">
                        <Button
                            action={() => this.saveInformation()}
                            text={'Set Information'}
                            disabled={ !this.checkData()}
                        />
                    </div>
                        </Base>
                    </div>
            </DataContainer>
        )
    }
}

export default DataGameScreenKeyPlayers;