// REACT
import React, { Component } from 'react';
import PropTypes from 'prop-types';
// INTERNAL
import ChampSelect from '../../../ChampSelect';
import { i18n } from '../../../i18n';
import Button from '../../../Button';


class DataMatchBans extends Component {

    state = {
    };

    static propTypes = {
        side: PropTypes.string,
        team: PropTypes.any,
        teamTricode: PropTypes.string,
        onBanSet: PropTypes.func,
    };

    onBanSet = (banNum, champKey) => {
        if(champKey === ''){
            champKey = 0
        }
        this.props.onBanSet(champKey, banNum, this.props.side);
    }

    render() {
        return (
            <div className={`game-bans game-sides game-sides--${this.props.side}`}>
                <div className="game-sides__header">
                    {this.props.teamTricode} {i18n().general.bans}
                </div>
                {
                    this.props.team && this.props.team.bans && Object.keys(this.props.team.bans).map((ban, i) =>
                        i <= 4 &&
                        <div className="row" key={i}>
                            <div className="pill">
                                <span>{i18n().general.ban} #{i + 1}</span>
                                <ChampSelect onChampSelect={this.onBanSet} assignRef={ban} selected={this.props.team.bans[ban]} />
                                <Button action={() => this.onBanSet(ban, 0)} icon="fas fa-trash-alt" />
                            </div>
                        </div>
                    )
                }
            </div>
        );
    }

}

export default DataMatchBans
