import React from 'react';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import allChamps from '../../services/championService';
import { getRuneImageUrl, loadImageUrlChampSquare } from '../../util/loadImageUrl';

import {
  StylePlayersRunes,
  StyleLeftContainer,
  StyleRightContainer,
  StyleCenterContainer,
  StylePrimaryRunes,
  StyleSecondaryRunes,
  StylePositionColumn,
  StyleKeyStonesImg,
  StyleRuneImg,
  StyleChampionImg,
} from './styles';

const positions = ['top', 'jungle', 'mid', 'adc', 'support'];

const PlayersRunes = props => {
  return (
    <StylePlayersRunes>
        <StyleLeftContainer>
          {positions.map((position, index) => {
            const championId = get(props.team100Roster, `[${position}].championId` )
            return (
              (
                <StylePositionColumn key={`playersRunes${index}`}>
                  {/*Champion */}
                 {championId && <StyleChampionImg src={loadImageUrlChampSquare(allChamps[championId].id)}></StyleChampionImg> }
                  {/*KeyStones */}
                  <StyleKeyStonesImg src={getRuneImageUrl(get(props.team100, `[${position}]data[0].perkIds[0]`))} />
                  {/*Primary Runes */}
                  <StylePrimaryRunes>
                    <StyleRuneImg
                      src={getRuneImageUrl(get(props.team100, `[${position}]data[0].perkIds[1]`))}
                    ></StyleRuneImg>
                    <StyleRuneImg
                      src={getRuneImageUrl(get(props.team100, `[${position}]data[0].perkIds[2]`))}
                    ></StyleRuneImg>
                    <StyleRuneImg
                      src={getRuneImageUrl(get(props.team100, `[${position}]data[0].perkIds[3]`))}
                    ></StyleRuneImg>
                  </StylePrimaryRunes>
                  <StyleSecondaryRunes>
                    <StyleRuneImg
                      src={getRuneImageUrl(get(props.team100, `[${position}]data[0].perkIds[4]`))}
                    ></StyleRuneImg>
                    <StyleRuneImg
                      src={getRuneImageUrl(get(props.team100, `[${position}]data[0].perkIds[5]`))}
                    ></StyleRuneImg>
                  </StyleSecondaryRunes>
                </StylePositionColumn>
              )
)
          } )}
        </StyleLeftContainer>
        <StyleCenterContainer></StyleCenterContainer>
        <StyleRightContainer>
        {positions.map((position, index) => {
          const championId = get(props.team200Roster, `[${position}].championId` )
          return (
            (
              <StylePositionColumn key={`playersRunes${index}`}>
                {/*Champion */}
                {championId && <StyleChampionImg src={loadImageUrlChampSquare(allChamps[championId].id)}></StyleChampionImg>}
                {/*KeyStones */}
                <StyleKeyStonesImg src={getRuneImageUrl(get(props.team200, `[${position}]data[0].perkIds[0]`))} />
                {/*Primary Runes */}
                <StylePrimaryRunes>
                  <StyleRuneImg
                    src={getRuneImageUrl(get(props.team200, `[${position}]data[0].perkIds[1]`))}
                  ></StyleRuneImg>
                  <StyleRuneImg
                    src={getRuneImageUrl(get(props.team200, `[${position}]data[0].perkIds[2]`))}
                  ></StyleRuneImg>
                  <StyleRuneImg
                    src={getRuneImageUrl(get(props.team200, `[${position}]data[0].perkIds[3]`))}
                  ></StyleRuneImg>
                </StylePrimaryRunes>
                <StyleSecondaryRunes>
                  <StyleRuneImg
                    src={getRuneImageUrl(get(props.team200, `[${position}]data[0].perkIds[4]`))}
                  ></StyleRuneImg>
                  <StyleRuneImg
                    src={getRuneImageUrl(get(props.team200, `[${position}]data[0].perkIds[5]`))}
                  ></StyleRuneImg>
                </StyleSecondaryRunes>
              </StylePositionColumn>
            )
)
        } )}
        </StyleRightContainer>
    </StylePlayersRunes>
  );
};

PlayersRunes.propTypes = {
  team100: PropTypes.object,
  team100Roster: PropTypes.object,
  team200: PropTypes.object,
  team200Roster: PropTypes.object,
};

export default PlayersRunes;
