// REACT
import React, { Component } from 'react';
import PropTypes from 'prop-types';
//ME
import Picker from '../../Picker';
import {splits} from '../../../util/const';

class SplitPicker extends Component {

  static propTypes = {
    league: PropTypes.string,
    onUpdate: PropTypes.func,
    preselect: PropTypes.string,
    splits: PropTypes.object.isRequired,
    disableSubmitButton: PropTypes.func,
  };

  render() {
    const splitsInfo = [];
    for (let i = 0; i < Object.keys(this.props.splits).length; i++) {
      for (let k = 0; k < Object.keys(splits).length; k++) {
        if (this.props.league === Object.keys(splits)[k]) {
          for (let m = 0; m < splits[this.props.league].length; m++) {
            Object.keys(this.props.splits)[i] === splits[this.props.league][m].value && splitsInfo.push({value: splits[this.props.league][m].value, name: splits[this.props.league][m].name});
          }
        }
      }
    }

    splitsInfo.length <= 0 && this.props.disableSubmitButton('split');

    return (
      <Picker
        onUpdate={this.props.onUpdate}
        items={splitsInfo}
        preselect={this.props.preselect}
        showSelectOption={false}
      />
    );
  };
}

export default SplitPicker;