// REACT
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import styled from 'styled-components';
import Select from 'react-select';

// internal
// import { i18n, checkLang } from '../../i18n';
import championService from '../../../services/championService';
// import Picker from '../../Picker';
import { getCreatableStyles } from '../../Data/DataMatch/DataMatchLineup';
// import ChampSelect from '../../ChampSelect';
// import { getInfoForPlayerInPosition } from '../../../services/keyPlayerService';
import DataContainer from '../DataContainer';
import positions from './../../../storybook-built/dist/Constants/lol_positions';
import Button from '../../Button';

const initialDraftState = {
  team100: {
    roster: {top:{}, jungle: {}, mid: {}, adc: {}, support: {}},
    bans: [],
  },
  team200: {
    roster: {top:{}, jungle: {}, mid: {}, adc: {}, support: {}},
    bans: [],
  },
}

const DataDraftOrder = (props) => {
    const [gameOptionsState, setGameOptionState] = useState([]);
    const [draftTitleState, setDraftTitleState] = useState('');
    const [selectedGameState, setSelectedGameState] = useState({
      name: '',
      value: {
        round: props.schedule.round,
        match: props.schedule.match,
        game: props.schedule.game,
      },
    });

    const [currentDraftState, setCurrentDraftState] = useState(initialDraftState);

    const getOptions = () => {
      const result = [];
      const currentRound = get(props, `schedule.rounds[${props.schedule.round}]`, []);
      currentRound.matches.forEach((match, matchIndex) => {
        get(match, 'games', []).forEach((game, gameIndex) => {
          result.push({
            label: ` ${props.schedule.round.name ? props.schedule.round.name : `Round ${window.MainData.schedule.round}` } - ${match.team100.tricode}_${match.team200.tricode} - Game ${gameIndex+1}`,
              value: {
                round: props.schedule.round,
                match: matchIndex,
                game: gameIndex,
              },
          })
        })
      });
      return result;
    }

    const getChampionAsset = (championID) => {
      return `https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/square/${get(championService, `${championID}.id`,'dummy')}.png`;
    }

    const loadDraft = (round, match, game) => {
      const newDraft = get(window.MainData, `schedule.rounds[${round}].matches[${match}].games[${game}].draftOrder`, initialDraftState);
      const newTitle = get(window.MainData, `schedule.rounds[${round}].matches[${match}].games[1]`) ?
        `GAME ${game +1} DRAFT ORDER`
        :
        `${get(window.MainData, `schedule.rounds[${round}].name`, '')} GAME ${match +1} DRAFT ORDER`;
      setDraftTitleState(newTitle);
      setCurrentDraftState({
        title: newTitle,
        ...newDraft,
      });
    }

    React.useEffect(() => {
      setGameOptionState(getOptions());
    }, [props.schedule.round, props.schedule.match, props.schedule.game]);

    React.useEffect(()=>{
      loadDraft(selectedGameState.value.round, selectedGameState.value.match, selectedGameState.value.game);
    },[selectedGameState])


    React.useEffect(() => {
      if(currentDraftState){
        window.MainData.schedule.rounds[selectedGameState.value.round].matches[selectedGameState.value.match].games[selectedGameState.value.game].draftOrder = {
          ...window.MainData.schedule.rounds[selectedGameState.value.round].matches[selectedGameState.value.match].games[selectedGameState.value.game].draftOrder,
          ...currentDraftState,
          ...selectedGameState.value,
        };
        window.MainData.draftOrder = {
          ...currentDraftState,
          title: draftTitleState,
          ...selectedGameState.value,
        }
      }
    }, [currentDraftState, draftTitleState])

    // const handleChampionSelection = (teamId, type, pos, championID) => {
    //   console.log( 'handleChampionSelection', teamId, type, pos, championID)
    //   setCurrentDraftState( current => {
    //     const newCurrentDraft = cloneDeep(current);
    //     newCurrentDraft[teamId][type === 'pick'? 'roster': 'bans'][pos].championID = {
    //       ...get(newCurrentDraft, `[${teamId}][${type === 'pick'? 'roster': 'bans'}][${pos}].championID`, {}),
    //       ...championID,
    //     };
    //     return newCurrentDraft;
    //   })
    // }

  return (
    <DataContainer componentSlug="dataDraftOrder" icon="fas fa-history" style={{ overflow: 'visible' }}>
      <StyleSelectorsContainer className="data-panel__fieldset" style={{margin: '0 auto'}}>
      <div className="pill" style={{width: '80%'}}>
        <span style={{width:'20%'}}>{'Select Game'}</span>
        <Select
            styles={getCreatableStyles('80%')}
            options={gameOptionsState}
            value={selectedGameState}
            onChange={(e)=>{ setSelectedGameState(e)}}
            defaultValue={{value: '', label: ' - '}}
            isSearchable
        />
        <Button action={() => {loadDraft(selectedGameState.value.round, selectedGameState.value.match, selectedGameState.value.game)}} text={'load'}/>
      </div>
      </StyleSelectorsContainer>
      <StyleSelectorsContainer className="data-panel__fieldset" style={{margin: '0 auto'}}>
        <div className="pill" style={{width: '80%'}}>
          <span style={{width:'20%'}}>{'Draft Title'}</span>
          <input
            onChange={(e) => {setDraftTitleState(e.target.value)}}
            value={draftTitleState}
            style={{
              width: '80%',
              paddingLeft: '10px',
            }}
          />
        </div>
      </StyleSelectorsContainer>
      <div className="data-panel__fieldset" style={{ flexWrap: 'wrap', paddingTop: '10px', paddingBottom: '0' , margin: '0 auto'}}>
    {/* Right / Red Team */}
        <StyleGrid>
        <h3>{'Blue Team'}</h3>
    {/* Picks */}
          <StyleTitle>{'Picks'}</StyleTitle>
            {positions.map((pos) =>
              <img
                key={`Team100-${pos}-pick`}
                alt={`Team100-${pos}-pick`}
                src={getChampionAsset(get(currentDraftState, `team100.roster.[${pos}].championID`))}
              />
              // <ChampSelect
              //   key={`team100-pick-${index}`}
              //   onChampSelect={(ref, id) => {handleChampionSelection('team100', 'pick', pos, id)}}
              //   selected={get(currentDraftState, `team100.roster.[${pos}].championID`, '')}
              //   disabled={false}
              // />
            )}
    {/* Bans */}
          <StyleTitle>{'Bans'}</StyleTitle>
          {[0,1,2,3,4].map((banIndex) =>
              <img
                key={`Team100-${banIndex}-ban`}
                alt={`Team100-${banIndex}-ban`}
                src={getChampionAsset(get(currentDraftState, `team100.bans[${banIndex}].championID`))}
              />
              // <ChampSelect
              //   key={`team100-ban-${banIndex}`}
              //   onChampSelect={(ref, id) => {handleChampionSelection('team100', 'bans', banIndex, id)}}
              //   selected={get(currentDraftState, `team100.bans[${banIndex}].championID`,'')}
              //   disabled={false}
              // />
            )}
        </StyleGrid>
    {/* Right / Red Team */}
        <StyleGrid >
          <h3>{'Red Team'}</h3>
    {/* Picks */}
          <StyleTitle>{'Picks'}</StyleTitle>
          {positions.map((pos) =>
              // <ChampSelect
              //   key={`team200-pick-${index}`}
              //   onChampSelect={(ref, id) => {handleChampionSelection('team200', 'pick', pos, id)}}
              //   selected={get(currentDraftState, `team200.roster[${pos}].championID`, '')}
              //   disabled={false}
              // />
              <img
                key={`Team200-${pos}-pick`}
                alt={`Team200-${pos}-pick`}
                src={getChampionAsset(get(currentDraftState, `team200.roster[${pos}].championID`))}
              />
            )}
    {/* Bans */}
          <StyleTitle>{'Bans'}</StyleTitle>
          {[0,1,2,3,4].map((banIndex) =>
            <img
              key={`Team200-${banIndex}-ban`}
              alt={`Team200-${banIndex}-ban`}
              src={getChampionAsset(get(currentDraftState, `team200.bans[${banIndex}].championID`))}
            />
              // <ChampSelect
              //   key={`team200-ban-${banIndex}`}
              //   onChampSelect={(ref, id) => {handleChampionSelection('team200', 'bans', banIndex, id)}}
              //   selected={get(currentDraftState, `team200.bans[${banIndex}].championID`,'')}
              //   disabled={false}
              // />
            )}
        </StyleGrid>
      </div>
    </DataContainer>
  );
};

DataDraftOrder.propTypes = {
  schedule: PropTypes.object,
}

export default DataDraftOrder;

const StyleSelectorsContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 5px auto;
`;

const StyleTitle = styled.div`
  grid-column-start: 1;
  grid-column-end: 6;
  margin: 5px auto 2px auto;
`;

const StyleGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  width: 100%;
  justify-items: center;
  margin: 0 5px 20px 5px;
  padding-bottom: 20px; 
  border: 2px #efefef solid;

  h3 {
    grid-column-start: 1;
    grid-column-end: 6;
    font-size: 20px;
    color: #efefef;
    margin: 5px auto;
  }

  img {
    width: 50px;
    height: 50px;
    margin: auto;
  }
  span {
    height: 50px;
    font-size: 15px;
    line-height: 50px;
  }

`;
