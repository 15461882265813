export default {
  id: `msi2021`,
  name: `MSI 2021`,
  screens: {
    cornerLogo: {
      cssCornerLabelText:
          'display:none;',
      startX: '-400px',
      endX: '-400px',
      startY: '0px',
      endY: '0px',
      hideCornerLogo: false, // hide the CornerLogo by default on all screens
  },
    scheduleV2: {
      speedPanel: 200,
      background: `https://cdn.dekki.com/assets/broadcast/games/league-of-legends/backgrounds/StandingsV2_BG_MSI2021.png`,
      main: `letter-spacing: 3px;`,
    },
    animatedBackground: {
      defaultBackground: "https://cdn.dekki.com/uploads/broadcast-projects/msi2021/videos/LIVING_HOLD.webm",
      // disableAnimationBackGround: true,
      show: true,
    },
    gameBreakdown: {
      teamStats: {
        cssTeamName: "font-family: `United`; font-weight:800; color: #ffffff;",
        cssTeamStats:
          "font-family: `United`; background-color: #593d25; color: #ffffff;",
        cssWinTag: `background-color: #000000aa; color: #ffffff;`,
        cssLossTag: `background-color: #00000033; color: #ffffffcc;`,
        cssBansPicks: `color: #ffffff;`,
      },
      key: {
        cssHeader: "font-family: `United`;",
        cssTitles: "font-family: `United`;",
      },
      graph: {
        cssHeader:
          "font-family: `United`; font-weight: 400; color: #0a0b0c; background-color: #ab8652; background-image: unset;",
        cssContent:
          "font-family: `United`; font-weight: 400; background-color: #1f1d1acc; background-image: unset;",
        cssKey: "font-family: `United`; font-weight: 400;",
      },
    },
    scheduleInfoSideBar: {
        logoSrc: `https://dekki-production.s3-ap-northeast-1.amazonaws.com/uploads/leagues/MSI_2021/MSI-2021_logo_white.svg`,
        cssTimerText: `color: #54cc4f; font-size: 22px; margin-left:20px; font-family: aeonik-medium; padding: 0px; overflow-wrap: anywhere; margin-bottom: 10px; font-weight: 400; white-space: nowrap; letter-spacing: 1px;`,
        cssTimer: `color: white; font-size: 100px;  margin-left:20px; font-family: titling-gothic-comp-medium; letter-spacing: 3px; font-weight: 400;`,
        cssBackground: `background: linear-gradient(#000000, #2e2e2e); border-right-color: #54cc4f;border-right-width: 22px; border-right-style: solid; letter-spacing: 3px;`,
        cssDayTitle: `color: white; font-size: 30px; font-family: aeonik-medium; font-weight: 400;`,
        cssScheduleList: `color: white; font-size: 35px; font-family: titling-gothic-comp-medium; width:100%; font-weight: 400;`,
        cssScheduleRow: `color: white; font-size: 35px; font-family: titling-gothic-comp-medium; font-weight: 400;`,
        cssNext: `color: #54cc4f; font-size: 35px; font-family: titling-gothic-comp-medium; font-weight: 400;`,
        cssWinner: `color: white; font-size: 35px; font-family: titling-gothic-comp-medium; font-weight: 400;`,
        cssScore: `color: white; font-size: 80px; font-family: titling-gothic-comp-medium; font-weight: 400;`,
        cssStyleBof5GridSingleMatch: `color: white; font-size: 80px; font-family: titling-gothic-comp-medium; font-weight: 400;`,
    },
    schedule: {
      speedPanel: 200,
      cssRow: `font-family: titling-gothic-comp-bold; font-size: 28px; color:#ffffff;`,
      cssRowNext: `color: #54cc4f;`,
      cssRowFinished: ``,
      cssDate: ``,
      cssDateFinished: ``,
      cssDateNext: ``,
      cssLogo: ``,
      cssLogoNext: ``,
      cssLogoLoser: ``,
      cssLogoWinner: ``,
      cssName: `color: white; font-size: 52px; font-family: titling-gothic-comp-bold;`,
      cssNameNext: ``,
      cssNameLoser: `opacity: 15%;`,
      cssNameWinner: ``,
      cssVersus:
        `color: white; 
        font-size: 50px; 
        font-family: aeonik-medium; 
        grid-template-rows: none; 
        h1{
          font-weight: 400;
          color: white; 
          font-size: 50px; 
          font-family: aeonik-medium;
        }
          `,
      cssVersusNext: ``,
      cssScore: `color: #54cc4f; font-size: 50px; font-family: aeonik-medium;`,
      cssScoreLoser: ``,
      cssScoreWinner: ``,
      trimColorNext: ``,
      rightBorderColor: ``,
      rightBorderColorLoser: ``,
      VSTimeBo: `display:none;`,
      background: `background-image: url(https://cdn.dekki.com/assets/broadcast/games/league-of-legends/backgrounds/StandingsV2_BG_MSI2021.png); background-position: bottom 150px; background-repeat: no-repeat; background-size: cover; width: 1459px; left: 231px; position: absolute; background-size: 100% 100%; margin:0; max-height: 617px; top: 293px; padding: 100px;`,
      bottom:`display:none;`,
      TitleType: "ScreenTitleV2",
      container: "width: 100%; height:100%;",
    },
    bottomBar: {
      borderColor: `teamMain`,
      borderColorBase: `#181818`,
      backgroundColor: `#181818`,
      cssTeamName: "font-family: `United`, sans-serif; color:#ffffff;",
      cssNextGameTitle: "font-family: `United`, sans-serif;",
      cssTimer: "font-family: `United`, sans-serif;",
    },
    screenTitle: {
      textColor: `#ffffff`,
      cssTitle:
        `font-family:United, sans-serif; font-weight: 400; text-shadow: unset;`,
    },
    standings: {
      showSubtext: true,
      cssCell: {
        backgroundColor: `#151515`,
        color: `#ab8652`,
        fontFamily: `United, sans-serif`,
        fontWeight: `800`,
        verticalAlign: `sub`,
        paddingTop: `10px`,
      },
      cssRankCell: {},
      cssSubtextCell: {
        backgroundColor: `#ab8652`,
        color: `#151515`,
      },
      cssLogoCell: {
        backgroundColor: `#151515`,
      },
      cssNameCell: {},
      cssScoreCell: {
        backgroundColor: `#ab8652`,
        color: `#151515`,
      },
      cssHeaderCell: {
        color: `#ffffff`,
        fontFamily: `United, sans-serif`,
        fontWeight: `100`,
      },
    },
  },
};
