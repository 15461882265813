// REACT
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

// ME
import Button from '../../Button';
import { i18n } from '../../i18n';

export default function GameID(props) {
    // const example = {
    //     "value": "ESPORTSTMNT02_2006319",
    //     "name": "av|grv|g1|333221c8b3774ff19d53",
    //     "summonerNames": "AV Dragku",
    //     save: <Button style={{ color: 'white' }} action={() => updateAll('ESPORTSTMNT02_2006319')} text={i18n().general.select} />,
    // }
    const [paginationToken, setPaginationToken] = useState('')
    const [games, setGames] = useState([])
    const [showButtons, setShowButtons] = useState(false)

    function updateAll(value) {
        console.log(value)
        props.updateRealm(value.substr(0, 13))
        props.distributeGameId(value.substr(14, 7))
    }

    async function getGameID(props) {
        let fetchState = 'in_progress'
        if (props.finish) {
            fetchState = 'finished'
        }

        let location = `esportsGames/${fetchState}${props.paginationToken ? `&paginationToken=${props.paginationToken}` : ''}`;

            try {
                // <base_url>/esportsGames/paginated?state=<state>&paginationToken=<NEXT_PAGE> // path example
                // 102147201391959473 // token example
                let res = await fetch(`https://prod-rawstats.riotesports.com/lol/v2/${location}`,
                    {
                        method: 'GET',
                        cache: 'no-cache',
                        headers: {
                            'x-api-key': 'dS8QyFL1Xw69S9df14WTL7z5BRVWworJ5xFtAjwi',
                            'Content-Type': 'application/json',
                        },
                    })
                if(res.status === 404) {
                    setGames([{name: i18n().errors.gameNotFound + '404'}])
                }
                let data = await res.json()
                if (props.test) {
                    console.log(data, 'test options data.')
                }
                // Reply example
                // {
                //     "nextPageToken": "OPAQUE_PAGINATION_TOKEN" // Page token, omitted if this is the final page of games
                //     "esportGames": [ stuff ]
                //  }
                if (data) {
                    if (data.nextPageToken) {
                        setPaginationToken(data.nextPageToken)
                    }
                    if (data.esportGames) {
                        const arrayOfGameIDs = []
                        data.esportGames.map((game) => {
                            const theGame = game.platformGames[0]
                            let summonerNames = ''
                            theGame.participants.map((name, index) => {
                                summonerNames = summonerNames + ` ${index + 1}: ` + name.summonerName
                            })
                            const value = theGame.platformGameId
                            const name = theGame.gameName
                            arrayOfGameIDs.push({ value: value, name: name, summonerNames: summonerNames, save: <Button style={{ color: 'white' }} action={() => updateAll(value)} text={i18n().general.select} /> })
                        })
                        setGames(arrayOfGameIDs)
                    } else if(data) {
                        const arrayOfGameIDs = []
                        data.map(entry => {
                            let summonerNames = ''
                            entry.participants.map((name, index) => {
                                summonerNames = summonerNames + ` ${index + 1}: ` + name.summonerName
                            })
                            arrayOfGameIDs.push({ value: entry.platformGameId, name: entry.gameName, summonerNames: summonerNames, save: <Button style={{ color: 'white' }} action={() => updateAll(entry.platformGameId)} text={i18n().general.select} /> })
                        })
                        setGames(arrayOfGameIDs)
                    }
                }
                return data
            } catch (error) {
                console.log(error, 'Error getting GameIDs')
                setGames([{name: i18n().errors.gameNotFound}])
            }
        }

    return (<StyledWrapper>
        <div className="data-panel__fieldset row">
            <StyledLiveGamesListTitle>Live Games List:</StyledLiveGamesListTitle>
            <Button action={() => getGameID({
                paginationToken: '',
            })} text={i18n().general.refresh} style={{ marginRight: "30px" }}
            />
            <Button action={() => getGameID({
                paginationToken: paginationToken,
            })} text={i18n().general.next} style={{ marginRight: "30px" }}
            />
            {!showButtons && <Button action={() => setShowButtons(
                true
            )} text={i18n().controller.buttons.unlock}
                             />}
            {showButtons && <Button action={() => setShowButtons(
                false
            )} text={i18n().controller.buttons.lock}
                            />}
            {showButtons && <Button action={() => getGameID({
                paginationToken: paginationToken,
                test: true,
            })} text={i18n().general.additionalDataLoad}
                            />}
            {showButtons && <Button action={() => getGameID({
                paginationToken: '',
                finish: true,
            })} text={i18n().general.FinishedGames}
                            />}
            {showButtons && <Button action={() => getGameID({
                paginationToken: paginationToken,
                finish: true,
            })} text={i18n().general.FinishedGames + i18n().general.next}
                            />}
        </div>
        <div className="data-panel__fieldset">
            <StyledTableWrapper>
                {games.length > 0 ? <MakeTable data={games} /> : i18n().errors.listEmpty}
            </StyledTableWrapper>
        </div>
    </StyledWrapper>)

}

GameID.propTypes = {
    finish: PropTypes.bool,
    test: PropTypes.bool,
    distributeGameId: PropTypes.func,
    paginationToken: PropTypes.string,
    updateRealm: PropTypes.func,
};
const StyledWrapper = styled.div`
border-top: 2px white solid;
padding-top: 10px;
    margin-top: 10px;
`;
const StyledLiveGamesListTitle = styled.h1`
display: block;
font-size: 1em;
margin-top: 0.67em;
margin-bottom: 0.67em;
margin-left: 10px;
margin-right: 40px;
font-weight: bold;
`;
const StyledTable = styled.div`
    background: #ccc;
    height: 100%;
    width: 100%;
`;
const StyledTableWrapper = styled.div`
    height: 200px;
    width: 100%;
    overflow: auto;
`
const StyledTableTitles = styled.div`
text-transform: capitalize;
    background-color: #fff;
    width: 100%;
display: grid;
grid-template-columns: 1.6fr 2fr 1fr 0.5fr;
    div {
        color: #000;
        padding: 10px 0px;
        font-family: Helvetica, sans-serif;
        transition: background-color 0.2s;
    }
`

const StyledTableItems = styled.div`
width: 100%;
div {
    white-space: nowrap;
    text-overflow: ellipsis;
        color: #000;
        font-family: Helvetica, sans-serif;
        transition: background-color 0.2s;
    }
`
const StyledItem = styled.div`
display: flex;
width: 100%;
display: grid;
grid-template-columns: 1.6fr 2fr 1fr 0.4fr;
margin: auto;
padding: 1px;
background: #ccc;
    :hover {
      background-color: lightpink;
    }
`
const Span = styled.span`
  visibility: hidden;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  width: auto;
  position: absolute;
  z-index: 2;
  top: 20px;
  left: 0;
  display: flex;
  white-space: pre-wrap;
`;

const StyledItemValue = styled.div`
overflow: hidden;
white-space: nowrap;
text-overflow: ellipsis;
&:hover ${Span} {
    visibility: visible;
  }
`


const MakeTable = ({ data }) => {
    if (data.length > 0) {
        return <TableMarkup titles={Object.keys(data[0])} data={data} />
    } else {
        return <></>
    }
}

MakeTable.propTypes = {
    data: PropTypes.array,
};

const TableMarkup = ({ titles, data }) => {
    return (<StyledTable>
        <StyledTableTitles>
            {titles.map((title, index) => (
                <div key={index}>{title}</div>
            ))}
        </StyledTableTitles>
        <StyledTableItems>
            {data.map((item, index) => (
                <StyledItem key={index}>
                    {titles.map((title, index) => (
                        <StyledItemValue key={index}>{item[title]} {typeof item[title] === 'string' ? <Span>{item[title]}</Span> : <div />}</StyledItemValue>
                    ))}
                </StyledItem>
            ))}
        </StyledTableItems>
    </StyledTable>)
};

TableMarkup.propTypes = {
    titles: PropTypes.array,
    data: PropTypes.array,
};

