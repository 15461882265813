// REACT
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ThemeContext } from '../View/themes';
// 3RD PARTY
import { delay } from 'lodash';
// import moment from 'moment';
// ME
// import ScreenTitle from '../../storybook-built/dist/components/Games/LJL/ScreenTitle';
import ScheduleTeamlessComponent from '../../storybook-built/dist/components/Games/LJL/ScheduleTeamless';

// import ScheduleComponent from '../../storybook-built/dist/components/Games/LJL/Schedule';

class ScheduleTeamless extends Component {

    state = {
        played: false,
        status: 'closed',
        animation: 'hide',
        displayTable: false,
        // displayTitle: false,
        resize: false,
        data: {},
    };

    bound = false;

    static propTypes = {
        active: PropTypes.bool,
        // action: PropTypes.string,
        data: PropTypes.any,
        statusCallback: PropTypes.func,
        isBottomContainerRender: PropTypes.bool,
        bottomContainerActions: PropTypes.object,
        sidePlayersContainerActions: PropTypes.object,
        resized: PropTypes.bool,
    }

    componentDidMount() {
        this.props.statusCallback('scheduleTeamless', 'ready');
        setTimeout(() => this.doAction(this.props), 1000);
    }

    componentWillUnmount() {
        console.log('unmount scheduleTeamless');
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.active) {
            if (this.state.status !== 'open') {
                this.setState({
                    status: 'open',
                    resize: nextProps.resized,
                });
                delay(() => this.checkSubscriptionData(nextProps.data), 100);
                this.props.isBottomContainerRender && this.props.sidePlayersContainerActions.show();
            } else {
                if (nextProps.resized) {
                    nextProps.sidePlayersContainerActions.show();
                    if (!this.state.resize) {
                        this.beginResizeAnimation();
                    }
                    this.setState({ resize: true });
                    if (!nextProps.isBottomContainerRender) {
                        nextProps.bottomContainerActions.show();
                    }
                } else {
                    if (this.state.resize) {
                        this.setState({ resize: false });
                    }
                }
            }
        } else {
            if (this.state.status === 'open') {
                this.clearPage();
                // this.props.statusCallback('scheduleTeamless', 'closing');
                // this.setState({status: 'closed'});
            }
        }
        this.processContainerRender(nextProps);
        this.doAction(nextProps);
    }

    doAction = props => {
        if (props.active) {
            if (this.state.status !== 'open') {
                this.setState({ status: 'open' });
            }
        } else {
            if (this.state.status === 'open') {
                this.setState({status: 'closed'});
                setTimeout(() => this.props.statusCallback('scheduleTeamless', 'closed'), 2000);
            }
        }
    }

    processContainerRender = nextProps => {
        if (nextProps.isBottomContainerRender && !this.state.resize) {
            this.setState({resize: true});
            this.props.sidePlayersContainerActions.show();
        }

        if (!nextProps.isBottomContainerRender && this.state.resize) {
            this.setState({resize: false});
            this.props.sidePlayersContainerActions.hide();
        }
    }

    checkSubscriptionData = async data => {
        if (!data) return;
        if (!this.state.played) {
            this.setState({ played: true, data }, async () => {
                this.beginAnimation();
            });
        } else {
            this.setState({data});
        }
    };

    beginResizeAnimation = () => this.setState({resize: true})

    clearResize = () => this.setState({resize: false})

    beginAnimation = () => {
        let theme = this.context && this.context.screens && this.context.screens.schedule;
        const interval = theme.speedPanel;
        let timer = interval * ((this.state.data.scheduledMatches && this.state.data.scheduledMatches.length) || 0);

        this.props.statusCallback('scheduleTeamless', 'opening');

        // this.setState({displayTitle: true});
        delay(() => this.setState({ displayTable: true }), interval);
        delay(() => this.props.statusCallback('scheduleTeamless', 'open'), timer+=interval);
    };

    clearPage = () => {
        let theme = this.context && this.context.screens && this.context.screens.schedule;
        const interval = theme.speedPanel;
        let timer = interval * ((this.state.data.scheduledMatches && this.state.data.scheduledMatches.length) || 0);

        this.props.statusCallback('scheduleTeamless', 'closing');
        this.setState({displayTable: false});
        // delay(() => this.setState({resize: false}), timer += interval);
        delay(() => timer += interval);
        delay(() => this.props.statusCallback('scheduleTeamless', 'closed'), 2000);
    };

    render() {
        // console.log('SCHEDULE TEAMLESS DATA', this.state.data);
        if (!this.state.data.scheduledMatches) {
            return (<div></div>);
        }
        const theme = (this.context.screens && this.context.screens.teamlessSchedule) || {};
        // console.log("Context from Schedule teamless", this.context);
        return (
            <div className={`view-scheduleTeamless view-schedule--${this.state.data.theme} view-schedule--animation-${this.state.animation} container`} style={{zIndex: 3}}>
                 <ScheduleTeamlessComponent // TODO put new schedule component here.
                    resize={this.state.resize}
                    isShowing={this.state.displayTable}
                    data={this.state.data}
                    theme={theme || {}}
                 />
            </div>
        );
    }
}

ScheduleTeamless.contextType = ThemeContext;

export default ScheduleTeamless
