// REACT
import React, { Component } from 'react';
// import ImmutablePropTypes from 'react-immutable-proptypes';
import PropTypes from 'prop-types';
// 3RD PARTY
import {delay} from 'lodash';
// ME
import LeaderboardTile from '../../storybook-built/dist/components/Games/LJL/Leaderboard/Tile';
import ResizableCompWrapper from '../../storybook-built/dist/components/Games/LJL/HOC/ResizableCompWrapper';
import ScreenTitle from '../../storybook-built/dist/components/Games/LJL/ScreenTitle';
import { ThemeContext } from '../View/themes';


import {
    StyleLeaderboards,
    StyleViewLeaderboard,
} from './styles';

/* eslint-disable no-alert, no-unused-expressions */
/* eslint-disable no-alert, no-sequences */

class Leaderboard extends Component {

    static propTypes = {
        active: PropTypes.bool,
        // action: PropTypes.string,
        data: PropTypes.any,
        statusCallback: PropTypes.func,
        bottomContainerActions: PropTypes.object,
        isBottomContainerRender: PropTypes.bool,
        resized: PropTypes.bool,
        sidePlayersContainerActions: PropTypes.object,
        isSideContainerRender: PropTypes.bool,
    }

    state = {
        displayTiles: false,
        clearTiles: false,
        playBoardOne: false,
        playBoardTwo: false,
        playBoardThree: false,
        played: false,
        playNext: false,
        leaderData: {},
        chosenStats: [],
        loaded: false,
        status: 'closed',
        components: {
            header: {
                animation: 'closed',
            },
            leaderBoardTile: {
                animation: 'closed',
            },
        },
    };

    componentDidMount() {
        this.props.statusCallback('leaderboard', 'ready');
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.active) {
            if (this.state.status !== 'open' && this.state.status !== 'resize') {
                if (nextProps.resized) {
                    this.setState({ status: 'resize' }, () => {
                        this.props.sidePlayersContainerActions.hide();
                        delay(() => this.checkSubscriptionData(nextProps.data), 100);
                    });
                } else {
                    this.setState({ status: 'open' }, () => {
                        this.props.sidePlayersContainerActions.hide();
                        delay(() => this.checkSubscriptionData(nextProps.data), 100);
                        this.runUpdate(nextProps);
                    });
                }
            } else {
                this.runUpdate(nextProps);
            }
        } else {
            if (this.state.status === 'open') {
                this.setState({status: 'closing'});
                this.clearPage();
            }
            if (this.state.status === 'resize') {
                this.setState({ status: 'closing' }, () => {
                    if (!this.props.isBottomContainerRender) {
                        this.clearResize();
                        delay(() => this.clearPage(), 3500);
                    } else {
                        this.clearPage();
                    }
                });
            }
        }
    }

    runUpdate = props => {
        // console.log('PROPS', props);
        if (props.resized) {
            this.resize();
        } else {
            this.closeResize();
        }
    }

    resize = () => {
        if (this.state.status !== 'resize') {
            this.setState({ status: 'resize' }, () => {
                this.beginResizeAnimation();
            });
        }
    }

    closeResize = () => {
        if (this.state.status === 'resize') {
            this.clearResize();
            this.setState({status: 'open'});
        }
    }

    checkSubscriptionData = data => {
        if (!data) return;
        if (!this.state.played) {
            this.setState({
                leaderData: data.leaderboard.data,
                chosenStats: data.leaderboard.chosenStats,
                loaded: true,
                played: true,
            }, () => {
                this.beginAnimation();
                this.props.statusCallback('leaderboard', 'opening');
            });
        }
    };

    beginResizeAnimation = () => {
        const components = Object.assign({}, this.state.components);
        components.leaderBoardTile.animation = 'resize-1',
        this.setState({components});
        !this.props.isBottomContainerRender && delay(() => this.props.bottomContainerActions.show(), 600);
    }

    clearResize = () => {
        const components = Object.assign({}, this.state.components);
        components.leaderBoardTile.animation = '7';
        this.setState({components});
    }

    beginAnimation = () => {

        const components = Object.assign({}, this.state.components);
        components.header.animation = '1';
        this.setState({components}); // header base
        delay(() => {components.header.animation = '2', this.setState({components})}, 300); // header title
        delay(() => {components.header.animation = '3', this.setState({components})}, 600); // header round
        delay(() => {components.header.animation = 'open', this.setState({components})}, 900); // header round
        delay(() => {this.setState({displayTiles: true})}, 1100); //show Tiles
        !this.props.isBottomContainerRender && delay(() => {components.leaderBoardTile.animation = '7', this.setState({components})}, 1800);
        delay(() => this.props.statusCallback('leaderboard', 'open'), 1800);
    };

    clearPage = () => {
        const components = Object.assign({}, this.state.components);
        if (!this.props.isBottomContainerRender) {
            components.leaderBoardTile.animation = '7';
            this.setState({components});
        }

        this.setState({displayTiles: false}); //hide Tiles
        delay(() => {components.header.animation = '3', this.setState({components})}, 2400);
        delay(() => {components.header.animation = '2', this.setState({components})}, 2800);
        delay(() => {components.header.animation = '1', this.setState({components})}, 3000);
        delay(() => {components.header.animation = 'closed', this.setState({components})}, 3500);
        delay(() => this.props.statusCallback('leaderboard', 'closed'), 3500);
    };

    render() {
        const theme = this.context;

        const stat1 = (this.state.chosenStats && this.state.chosenStats[0]) || 'KP';
        const stat2 = (this.state.chosenStats && this.state.chosenStats[1]) || 'KILLS';
        const stat3 = (this.state.chosenStats && this.state.chosenStats[2]) || 'ASSISTS';

        const league = this.props.data.league;
        const showTopPlayer = league === 'msi' ? false : true;
        const showPlayerAvatar = league === 'msi' ? false : true;
        const applyStyleToLastRow = league === 'msi' ? true : false;


        return (
            <StyleViewLeaderboard className={`view-leaderboard ${league && `view-leaderboard--${league}`} view-leaderboard--animation-${this.state.components.header.animation} container`}>
                <ResizableCompWrapper
                                    isShowing={true}
                                    isResized={false} // resized for bottom bar made by animation in the current component
                                    isSideBarRender={this.props.isSideContainerRender}
                >
                    <ScreenTitle
                        title={'LEADERBOARD'}
                        showTitle={this.state.components.header.animation === 'open'}
                        theme={theme.screens.screenTitle || {}}
                        titleColor={theme.screens.screenTitle.textColorSub}
                        displayUnderline
                    />

                    {this.state.loaded ?
                        <StyleLeaderboards className={`leaderboards leaderboards--animation-${this.state.components.leaderBoardTile.animation} ${this.props.isBottomContainerRender ? 'resized' : ''}`}>
                            <div
                                className={`wrapper ${this.props.isBottomContainerRender ? 'wrapper--resized' : ''}`}
                                style={{ justifyContent: 'center', transform: ` ${this.props.isSideContainerRender ? 'scale(1) translateX(15px)' : 'scale(.85) translateX(0)'}` }}
                            >
                                <LeaderboardTile
                                                isShowing={this.state.displayTiles}
                                                clear={this.state.clearTiles}
                                                leaderData={this.state.leaderData[encodeURIComponent(stat1)]}
                                                onClearFinish={this.finishCleanup}
                                                onFinish={this.startSecondTile}
                                                play={this.state.playBoardOne}
                                                playNext={this.state.playNext}
                                                title={stat1}
                                                uniqueClass="kp"
                                                decimals={1}
                                                showTopPlayer={showTopPlayer}
                                                showPlayerAvatar={showPlayerAvatar}
                                                teamData={this.props.data && this.props.data.teams}
                                                applyStyleToLastRow={applyStyleToLastRow}
                                                leaderboard={this.props.data.leaderboard}
                                                theme={theme.screens.leaderBoard || {}}
                                />
                                <LeaderboardTile
                                                isShowing={this.state.displayTiles}
                                                clear={this.state.clearTiles}
                                                leaderData={this.state.leaderData[encodeURIComponent(stat2)]}
                                                onClearFinish={this.finishCleanup}
                                                onFinish={this.startThirdTile}
                                                play={this.state.playBoardTwo}
                                                playNext={this.state.playNext}
                                                title={stat2}
                                                uniqueClass="kills"
                                                decimals={1}
                                                showTopPlayer={showTopPlayer}
                                                showPlayerAvatar={showPlayerAvatar}
                                                teamData={this.props.data && this.props.data.teams}
                                                applyStyleToLastRow={applyStyleToLastRow}
                                                leaderboard={this.props.data.leaderboard}
                                                theme={theme.screens.leaderBoard || {}}
                                />
                                <LeaderboardTile
                                                isShowing={this.state.displayTiles}
                                                clear={this.state.clearTiles}
                                                leaderData={this.state.leaderData[encodeURIComponent(stat3)]}
                                                onClearFinish={this.finishCleanup}
                                                onFinish={this.startNext}
                                                play={this.state.playBoardThree}
                                                playNext={this.state.playNext}
                                                title={stat3}
                                                uniqueClass="assists"
                                                decimals={1}
                                                showTopPlayer={showTopPlayer}
                                                showPlayerAvatar={showPlayerAvatar}
                                                teamData={this.props.data && this.props.data.teams}
                                                applyStyleToLastRow={applyStyleToLastRow}
                                                leaderboard={this.props.data.leaderboard}
                                                theme={theme.screens.leaderBoard || {}}
                                />
                            </div>
                        </StyleLeaderboards>
                        :
                        ''}
                </ResizableCompWrapper>
            </StyleViewLeaderboard>
        );
    }

}

Leaderboard.contextType = ThemeContext;

export default Leaderboard
