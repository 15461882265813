// REACT
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';

import { StyleGoldItem, StyleChampionContainer, StyleScore, StyleBarContainer, StyleGoldBar, StyleInstantGold } from './styles';

import { loadImageUrlChampSquare } from '../../../../../util/loadImageUrl';
import championsDict from '../../../../../services/championService';

class PlayerBarItem extends Component {

  static propTypes = {
    champ: PropTypes.string,
    color: PropTypes.string,
    score: PropTypes.number,
    instantVal: PropTypes.number,
    maxVal: PropTypes.number,
    side: PropTypes.string,
    scoreExtraWide: PropTypes.bool,
  };

  getPercentGold = () => {
    return (this.props.score / this.props.maxVal).toFixed(2);
  }

  getPercentInstantGold = () => {
    return (this.props.instantVal / this.props.score).toFixed(2);
  }


  render() {
  console.log(get(this, 'props.theme.StyleScore', ''))
    return (
       <StyleGoldItem side={this.props.side}>
        {(this.props.champ && <StyleChampionContainer champ={loadImageUrlChampSquare(championsDict[this.props.champ].id)}></StyleChampionContainer>) || null}
          <StyleScore StyleScore={get(this, 'props.theme.StyleScore', '')} scoreExtraWide={this.props.scoreExtraWide}>
            {`${this.props.score.toLocaleString()} ${this.props.instantVal ? `(${this.props.instantVal})` : ''}`}
          </StyleScore>
        <StyleBarContainer>
            <StyleGoldBar scaling={this.getPercentGold()} color={this.props.color} side={this.props.side}>
              <StyleInstantGold scaling={this.getPercentInstantGold()} color={this.props.color} side={this.props.side}></StyleInstantGold>
            </StyleGoldBar>
        </StyleBarContainer>
      </StyleGoldItem>
    )
  }
};

export default PlayerBarItem;
