// REACT
import React, { Component } from 'react';
// INTERNAL
import { /* send, */ subscribe } from '../../../services/api';
import ControlContainer from '../../Controller/ControlContainer';
import Button from '../../Button';
import validateDataSchedule from '../../Data/DataSchedule/validate';
import { i18n } from '../../i18n';
import {loadImageUrlPlayerVoting} from '../../../util/loadImageUrl';
import { updateScreenStatus } from '../../../util/controllerUtils';
import PropTypes from 'prop-types';

class StartersControl extends Component {

    static propTypes = {
        cgpc: PropTypes.string,
        display: PropTypes.bool,
        screen1: PropTypes.bool,
      };

    state = {
        status: 'ready',
        playNext: false,
        animationfinished: true,
    }

    componentDidMount() {
        this.runSubscription();
        if (validateDataSchedule()) {
            this.setState({status: 'ready'});
        } else {
            this.setState({status: 'invalid'});
        }
    }

    runSubscription = () => {
        subscribe('control-starters', null, data => {
            if (data.target === 'control-starters') {
                this.setState({status: data.status});
            }
        }, this.props.cgpc);
    };

    onPlay = () => {
        // check data
        if (validateDataSchedule()) {
            const sendData = { startersData: this.getStartersData(), playNext: this.state.playNext };
            // Disable button while animation ongoing
            this.setState({ animationfinished: false , status: 'open'});
            updateScreenStatus('starters', 'open', sendData, null, this.props.cgpc);

             // Renable button when animation finish
            setTimeout(() => this.setState({ animationfinished: true }), 3500);
        } else {
            this.setState({status: 'invalid'});
        }
    }

    //When left/right team are switched
    onPlayNext = () => {
        if (this.state.status === 'open') {
            const sendData = { startersData: this.getStartersData(), playNext: this.state.playNext };
            updateScreenStatus('starters', 'open', sendData, null, this.props.cgpc);
            // Disable button while animation ongoing
            this.setState({ animationfinished: false });
            setTimeout(() => this.setState({ animationfinished: true }), 3500);
        }
    }

    onUpdate = () => {
        this.setState({ playNext: !this.state.playNext }, () => { this.onPlayNext() });
    }

    onClose = () => {
        updateScreenStatus('starters', 'close', null, null, this.props.cgpc);
    }

    getTeam = team => {
        const schedule = window.MainData.schedule;
        const match = schedule.rounds[schedule.round].matches[schedule.match];
        return match[team];
    };

    getRoster = team => {
        const schedule = window.MainData.schedule;
        const match = schedule.rounds[schedule.round].matches[schedule.match];
        const game = match.games[schedule.game] || null;
        return game.teams[this.getTeam(team).tricode].roster;
    };

    getStartersData = () => {
        const schedule = window.MainData.schedule;
        const league = schedule.league;
        const season = schedule.season;
        const split = schedule.split;
        const team100 = this.getTeam('team100');
        const roster100 = this.getRoster('team100');
        const team200 = this.getTeam('team200');
        const roster200 = this.getRoster('team200');
        const topTeam100Url = loadImageUrlPlayerVoting(roster100.top.name.toLowerCase(), team100, league, season, split);
        const jungleTeam100Url = loadImageUrlPlayerVoting(roster100.jungle.name.toLowerCase(), team100, league, season, split);
        const midTeam100Url = loadImageUrlPlayerVoting(roster100.mid.name.toLowerCase(), team100, league, season, split);
        const adcTeam100Url = loadImageUrlPlayerVoting(roster100.adc.name.toLowerCase(), team100, league, season, split);
        const supportTeam100Url = loadImageUrlPlayerVoting(roster100.support.name.toLowerCase(), team100, league, season, split);

        const topTeam200Url = loadImageUrlPlayerVoting(roster200.top.name.toLowerCase(), team200, league, season, split);
        const jungleTeam200Url = loadImageUrlPlayerVoting(roster200.jungle.name.toLowerCase(), team200, league, season, split);
        const midTeam200Url = loadImageUrlPlayerVoting(roster200.mid.name.toLowerCase(), team200, league, season, split);
        const adcTeam200Url = loadImageUrlPlayerVoting(roster200.adc.name.toLowerCase(), team200, league, season, split);
        const supportTeam200Url = loadImageUrlPlayerVoting(roster200.support.name.toLowerCase(), team200, league, season, split);

        return [
            {
                tricode: team100.tricode,
                name: team100.name,
                color: team100.color,
                roster: {
                    top: {
                        sortIndex: 0,
                        name: roster100.top.name,
                        image: topTeam100Url,
                    },
                    jungle: {
                        sortIndex: 1,
                        name: roster100.jungle.name,
                        image: jungleTeam100Url,
                    },
                    mid: {
                        sortIndex: 2,
                        name: roster100.mid.name,
                        image: midTeam100Url,
                    },
                    ad: {
                        sortIndex: 3,
                        name: roster100.adc.name,
                        image: adcTeam100Url,
                    },
                    support: {
                        sortIndex: 4,
                        name: roster100.support.name,
                        image: supportTeam100Url,
                    },
                },
            },
            {
                tricode: team200.tricode,
                name: team200.name,
                color: team200.color,
                roster: {
                    top: {
                        sortIndex: 0,
                        name: roster200.top.name,
                        image: topTeam200Url,
                    },
                    jungle: {
                        sortIndex: 1,
                        name: roster200.jungle.name,
                        image: jungleTeam200Url,
                    },
                    mid: {
                        sortIndex: 2,
                        name: roster200.mid.name,
                        image: midTeam200Url,
                    },
                    ad: {
                        sortIndex: 3,
                        name: roster200.adc.name,
                        image: adcTeam200Url,
                    },
                    support: {
                        sortIndex: 4,
                        name: roster200.support.name,
                        image: supportTeam200Url,
                    },
                },
            },
        ];
    }

    render() {
        return (
            <ControlContainer style={{display: this.props.display ? 'none': 'block'}} className="control--starters" status={this.state.status}>
                <div className="control__panel">
                    <h2 className="control__title">
                        <i className="fas fa-users"></i> {i18n().pages.starters}
                    </h2>
                    <Button controllerSide={!this.props.screen1} action={this.onPlay} text={i18n().general.open} icon="fas fa-play-circle" disabled={
                        (this.state.status === 'open' || this.state.status === 'opening' || this.state.status === 'open2' || this.state.status === 'opening2') && true }
                    />
                    {/* <Button controllerSide={!this.props.screen1} action={this.onPlay} text={`${i18n().general.open} ${i18n().general.red}`} icon="fas fa-play-circle" disabled={
                        !(this.state.status === 'open' || this.state.status === 'opening') && true }
                    /> */}
                    <Button controllerSide={!this.props.screen1} action={this.onClose} text={i18n().general.close} icon="fas fa-stop-circle" disabled={
                        !(this.state.status === 'open' || this.state.status === 'opening' || this.state.status === 'open2' || this.state.status === 'opening2') && true }
                    />
                </div>
                <div className="control-extra">
                    <div className="control-extra__fieldset">
                    <label className="control-extra__fieldset__label">Display team </label>
                        <div className="pill">
                            {
                                !this.state.playNext ?
                                [
                                    <span key="1a" className="pill__span--highlighted">{i18n().general.leftTeam}</span>,
                                        <Button controllerSide={!this.props.screen1} key="1b" disabled={!this.state.animationfinished} text={i18n().general.rightTeam} action={() => this.onUpdate()} />,
                                ] :
                                [
                                    <Button controllerSide={!this.props.screen1} key="2b" disabled={!this.state.animationfinished} text={i18n().general.leftTeam} action={() => this.onUpdate()} />,
                                    <span key="2a" className="pill__span--highlighted">{i18n().general.rightTeam}</span>,
                                ]
                            }
                        </div>
                    </div>
                </div>
            </ControlContainer>
        )
    }
}

export default StartersControl
