"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StyleVideo = exports.StyleTitle = exports.StyleTime = exports.StyleScheduleContainer = exports.StyleRowContainer = exports.StyleMatch = exports.StyleLogoContainer = exports.StyleGame = exports.StyleDay = exports.StyleDate = exports.StyleBase = void 0;

var _styledComponents = _interopRequireDefault(require("styled-components"));

var _reactPose = _interopRequireDefault(require("react-pose"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var newPoseAnimation = _reactPose.default.div({
  initialPose: 'hide',
  show: {
    opacity: 1,
    transition: {
      duration: 600
    }
  },
  hide: {
    opacity: 0,
    transition: {
      duration: 600
    },
    delay: 1200
  }
});

var StyleBase = (0, _styledComponents.default)(newPoseAnimation).withConfig({
  displayName: "styles__StyleBase",
  componentId: "sc-1ga07ru-0"
})(["position:relative;color:white;font-weight:100;display:flex;flex-direction:row;justify-content:center;align-items:center;font-family:inherit;font-size:40px;letter-spacing:2px;overflow:hidden;width:1920px;height:1080px;background-color:gray;background:url(", ");background-size:100% 100%;"], function (props) {
  return props.baseBG;
});
exports.StyleBase = StyleBase;

var StyleVideo = _styledComponents.default.video.withConfig({
  displayName: "styles__StyleVideo",
  componentId: "sc-1ga07ru-1"
})(["height:100%;width:100%;background-color:transparent;position:absolute;z-index:0;"]);

exports.StyleVideo = StyleVideo;

var ContainerAnimation = _reactPose.default.div({
  show: {
    y: function y(_ref) {
      var resize = _ref.resize;
      return resize ? -75 : 0;
    },
    scale: function scale(_ref2) {
      var resize = _ref2.resize;
      return resize ? 0.8 : 1;
    },
    transition: function transition(_ref3) {
      var speedPanel = _ref3.speedPanel;
      return {
        duration: speedPanel
      };
    },
    staggerChildren: function staggerChildren(_ref4) {
      var speedPanel = _ref4.speedPanel;
      return speedPanel;
    },
    staggerDirection: 1
  },
  hide: {
    y: function y(_ref5) {
      var resize = _ref5.resize;
      return resize ? -75 : 0;
    },
    scale: function scale(_ref6) {
      var resize = _ref6.resize;
      return resize ? 0.8 : 1;
    },
    transition: function transition(_ref7) {
      var speedPanel = _ref7.speedPanel;
      return {
        duration: speedPanel
      };
    },
    staggerChildren: function staggerChildren(_ref8) {
      var speedPanel = _ref8.speedPanel;
      return speedPanel;
    },
    staggerDirection: -1
  }
});

var StyleLogoContainer = (0, _styledComponents.default)(ContainerAnimation).withConfig({
  displayName: "styles__StyleLogoContainer",
  componentId: "sc-1ga07ru-2"
})(["position:absolute;left:0%;display:flex;flex-direction:column;align-items:center;justify-content:center;width:45%;height:100%;background:url(", ");background-size:100% 100%;img{width:650px;height:auto;}", ""], function (props) {
  return props.scheduleBG;
}, function (props) {
  return props.css;
});
exports.StyleLogoContainer = StyleLogoContainer;
var StyleScheduleContainer = (0, _styledComponents.default)(ContainerAnimation).withConfig({
  displayName: "styles__StyleScheduleContainer",
  componentId: "sc-1ga07ru-3"
})(["position:absolute;left:45%;display:flex;flex-direction:column;align-items:center;justify-content:center;width:50%;font-weight:700;height:100%;background:url(", ");background-size:100% 100%;", ""], function (props) {
  return props.scheduleBG;
}, function (props) {
  return props.css;
});
exports.StyleScheduleContainer = StyleScheduleContainer;

var RowContainerAnimation = _reactPose.default.div({
  initialPose: 'hide',
  hide: {
    y: -200,
    z: 1,
    scale: 1,
    opacity: 0,
    transition: {
      duration: 400
    },
    applyAtStart: {
      scale: 1
    }
  },
  show: {
    y: 0,
    z: 1,
    scale: function scale(_ref9) {
      var nextMatch = _ref9.nextMatch;
      return nextMatch ? 1.03 : 1;
    },
    opacity: 1,
    transition: {
      duration: 400
    },
    delay: 100,
    delayChildren: 400
  }
});

var StyleTitle = (0, _styledComponents.default)(RowContainerAnimation).withConfig({
  displayName: "styles__StyleTitle",
  componentId: "sc-1ga07ru-4"
})(["position:relative;top:2%;align-items:center;margin-bottom:50px;justify-content:center;width:90%;font-weight:700;font-size:80px;font-family:AgencyFB;font-weight:800;padding:20px;text-align:center;color:#000000;background:url(", ");background-size:100% 100%;", ""], function (props) {
  return props.headerBGsrc;
}, function (props) {
  return props.css;
});
exports.StyleTitle = StyleTitle;
var StyleRowContainer = (0, _styledComponents.default)(RowContainerAnimation).withConfig({
  displayName: "styles__StyleRowContainer",
  componentId: "sc-1ga07ru-5"
})(["position:relative;display:flex;flex-direction:row;justify-content:center;align-items:flex-start;margin-bottom:5px;padding-left:", ";padding-right:", ";z-index:", ";width:90%;flex:1 1 110px;max-height:120px;height:", ";", " color:", ";text-align:center;text-transform:uppercase;font-size:65px;font-family:AgencyFB;font-weight:800;overflow:hidden;", " ", ""], function (props) {
  return props.nextMatch ? '20px' : '0px';
}, function (props) {
  return props.nextMatch ? '20px' : '0px';
}, function (props) {
  return props.nextMatch ? 30 : 2;
}, function (props) {
  return props.nextMatch ? '105%' : '100%';
}, function (props) {
  return props.nextMatch ? 'background-color: #f2a900;' : '';
}, function (props) {
  return props.nextMatch ? '#942717' : props.isPassed ? '#333333' : '#f2a900';
}, function (props) {
  return props.css;
}, function (props) {
  return props.nextMatch ? props.cssFeature : props.isPassed && props.cssPassed;
});
exports.StyleRowContainer = StyleRowContainer;

var StyleDate = _styledComponents.default.div.withConfig({
  displayName: "styles__StyleDate",
  componentId: "sc-1ga07ru-6"
})(["position:relative;flex:1;align-items:center;justify-content:center;display:flex;flex-direction:column;height:100%;min-width:20%;text-transform:uppercase;line-height:40px;font-size:40px;z-index:4;color:", ";background-color:", ";", " ", ""], function (props) {
  return props.nextMatch ? '##942717' : props.isPassed ? '#333333' : '#f2a900';
}, function (props) {
  return props.nextMatch ? '#f2a900' : '#000000';
}, function (props) {
  return props.css;
}, function (props) {
  return props.nextMatch ? props.cssFeature : props.isPassed && props.cssPassed;
});

exports.StyleDate = StyleDate;

var TeamAnimation = _reactPose.default.div({
  initialPose: 'show',
  show: {
    transition: {
      duration: 100
    }
  },
  showNext: {
    transition: {
      duration: 400
    }
  }
});

var StyleMatch = (0, _styledComponents.default)(TeamAnimation).withConfig({
  displayName: "styles__StyleMatch",
  componentId: "sc-1ga07ru-7"
})(["flex:4;z-index:4;position:relative;display:flex;justify-content:center;align-items:center;height:100%;width:40%;margin-right:20px;background-color:", ";border-right:", ";", " ", ""], function (props) {
  return props.nextMatch ? 'transparent' : '#000000';
}, function (props) {
  return props.nextMatch ? '0px' : '10px solid transparent';
}, function (props) {
  return props.css;
}, function (props) {
  return props.nextMatch ? props.cssFeature : props.isPassed && props.cssPassed;
});
exports.StyleMatch = StyleMatch;
var StyleGame = (0, _styledComponents.default)(TeamAnimation).withConfig({
  displayName: "styles__StyleGame",
  componentId: "sc-1ga07ru-8"
})(["flex:4;z-index:4;position:relative;display:flex;justify-content:center;align-items:center;height:100%;border-right:", ";background-color:", ";", " ", ""], function (props) {
  return props.nextMatch ? '0px' : '10px solid transparent';
}, function (props) {
  return props.nextMatch ? 'transparent' : '#000000';
}, function (props) {
  return props.css;
}, function (props) {
  return props.nextMatch ? props.cssFeature : props.isPassed && props.cssPassed;
});
exports.StyleGame = StyleGame;

var StyleDay = _styledComponents.default.span.withConfig({
  displayName: "styles__StyleDay",
  componentId: "sc-1ga07ru-9"
})(["z-index:4;"]);

exports.StyleDay = StyleDay;

var StyleTime = _styledComponents.default.span.withConfig({
  displayName: "styles__StyleTime",
  componentId: "sc-1ga07ru-10"
})(["z-index:4;"]);

exports.StyleTime = StyleTime;