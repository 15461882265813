import React from 'react'
import PropTypes from 'prop-types'
// import { get } from 'lodash';
import { StyleCustomItem, StyleCustomItemText } from './styles';


function CustomItem(props) {
    return (
        <StyleCustomItem bg={props.url}>
            {(props.isDeletable && <i onClick={props.onClose} className="fas fa-times" /> )|| null}
            {props.pos && <span>{props.pos}</span>}
            <StyleCustomItemText side={props.side}>{props.text}</StyleCustomItemText>
      </StyleCustomItem>
    )
}

CustomItem.propTypes = {
    url: PropTypes.string,
    side: PropTypes.string,
    text: PropTypes.string,
    pos: PropTypes.string,
    onClose: PropTypes.func,
    isDeletable: PropTypes.bool,
}

export default CustomItem

