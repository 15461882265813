import React, { useContext, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';

import { ThemeContext } from '../View/themes';
import ScheduleV2Component from '../../storybook-built/dist/components/Games/LJL/ScheduleV2';
import ScreenTitleV2 from '../../storybook-built/dist/components/Games/LJL/ScreenTitleV2';

// Copy from other functional components!

const ScheduleV2 = props => {
const theme = useContext(ThemeContext)
const [show, setShow] = useState(false)
// const [resize, setResize] = useState(false)
const interval = theme.speedPanel

// Checks to see if this is the first load of the component
const isFirstRun = useRef(true);
useEffect (() => {
    if (isFirstRun.current) {
        // Sets as not the first run
        isFirstRun.current = false;
        // Show the animation AFTER the component is mounted to the DOM
        setTimeout(() => setShow(true), interval);
        return;
    }
    // If it is not the first run:
    if(show === true){
      setShow(false)
    } else if (show === false) {
      setTimeout(() => setShow(true), interval);
    }
    // console.log('active changed', props.active);
}, [props.active]);


  return (
    // The zIndex is so that OBS can record the CSS changes. They record the top layer of CGPC and not the whole window. Update: They record the first static serve and not the window, they switched to v-mix.
    <div className={'container'} style={{zIndex: 4}}>
      <ScreenTitleV2
        theme={theme.screens.screenTitleV2 || {}}
        showTitle={show}
        title={'SCHEDULE'}
        subTitleRight={((theme.screens.scheduleV2 && theme.screens.scheduleV2.swapSubtitlePositions) ? props.data.splitName : props.data.roundName) || ''}
        subTitleLeft={((theme.screens.scheduleV2 && theme.screens.scheduleV2.swapSubtitlePositions) ? props.data.roundName : props.data.splitName) || ''}
        isResized={get(props, `isSideContainerRender`, false)}
      />
      <ScheduleV2Component
        scheduledMatches={props.data.scheduledMatches}
        isShowing={show}
        theme={theme.screens.scheduleV2 || {}}
        isResized={get(props, `isSideContainerRender`, false)}
      />
    </div>
  );
};

ScheduleV2.propTypes = {
  data: PropTypes.object,
  active: PropTypes.bool,
  // resized: PropTypes.bool,
};

export default ScheduleV2;
