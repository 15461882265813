import moment from 'moment';

const validateDataTimer = (incomingTime) => {
    const time = incomingTime ? incomingTime : window.MainData.timer.targetTime;
    if (!time) return false;
    if (moment(time)) {
        return (moment(time) > moment()); // make sure time is in the future
    }
    return false;
}

export default validateDataTimer